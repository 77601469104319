import { Comment } from 'antd'
import styled from 'styled-components'

const CustomComment = styled(Comment)`
  width: 100%;
  display: flex;

  .ant-comment-inner {
    padding-top: 16px;
    padding-bottom: 0px;
    width: 100%;
  }
  .ant-comment-content-author-time {
    flex: 1;
  }
  .ant-comment-content-author {
    align-items: center;
  }
`

export default CustomComment
