import React from 'react'
import styled from 'styled-components'

import ColorTag from '../../../containers/color-tag'
import { UNIT_COLORS } from '../../../utils/constants/config'

const ExecutionViewPageTitle = ({ procedure }) => {
  if (!procedure) return null

  const color = UNIT_COLORS[procedure.unit]

  return (
    <FlexStyledTag color={color}>
      <InfoSpan id='procedure-header-info'>
        <DivCenter>
          <BoldSpanRight>
            {procedure.serie.name} {procedure.unit}
          </BoldSpanRight>
          <BoldSpan>
            {procedure.key} Rev.
            {procedure.rev}
          </BoldSpan>
        </DivCenter>
        <DivCenter id='title'>{procedure.title}</DivCenter>
      </InfoSpan>
    </FlexStyledTag>
  )
}

const BoldSpan = styled.span`
  font-weight: bold;
`

const BoldSpanRight = styled(BoldSpan)`
  margin-right: 100px;
`

const InfoSpan = styled.span`
  font-size: 16px;
  line-height: 19px;
  padding: 0 10px;
  text-align: center;
  display: inline-block;
  width: 100%;
  white-space: pre;
`

const DivCenter = styled.div`
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const FlexStyledTag = styled(ColorTag)`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export default ExecutionViewPageTitle
