import { getDatabase } from '../..'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _readLocalDbData } from '../../common/read'
import { shouldSyncByDate } from '../../common/sync'

const tableName = TABLE_NAMES.EXECUTIONS

export async function executionSyncQuery() {
  const db = getDatabase()

  return db[tableName].filter(shouldSyncByDate).toArray()
}

export const readLocalAbortedFinishedExecutions = async () => {
  const db = getDatabase()

  return db[tableName]
    .where('status')
    .anyOf(EXECUTION_STATUS.ABORTED, EXECUTION_STATUS.FINISHED)
    .toArray()
}

export async function readExecutions() {
  const { data } = await _readLocalDbData({
    tableName
  })

  return data
}
