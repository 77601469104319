import { Select } from 'antd'
import React, { useState } from 'react'
import { useDebounce } from 'react-use'
import styled from 'styled-components'


import { useGlobalProcedures } from '../../../hooks/data/useGlobalProcedures'
import { PROCEDURE_STATUS } from '../../../utils/constants/procedure'

import ProcedureSelectorView from './procedure-selector-view'

const { Option } = Select

const SelectGlobalProcedures = ({ value, onChange, placeholder }) => {
  const [search, setSearch] = useState()
  const [debouncedSearch, setDebouncedSearch] = useState()
  const { data: { items: procedures } = {}, isValidating: loadingProcedures } =
    useGlobalProcedures({
      disabled: !debouncedSearch,
      key: debouncedSearch,
      status: [PROCEDURE_STATUS.PUBLISHED],
      orderBy: 'key',
      orderDirection: 'ascend'
    })

  useDebounce(() => setDebouncedSearch(search), 300, [search])

  return (
    <DropDown
      id='procedure-input'
      showSearch
      value={value}
      placeholder={placeholder}
      showArrow={true}
      filterOption={false}
      onSearch={setSearch}
      onChange={(value) => {
        setSearch(null)
        setDebouncedSearch(null)
        onChange(value)
      }}
      loading={loadingProcedures}
    >
      {procedures?.map((p) => (
        <Option key={p.id} value={p.id}>
          <ProcedureSelectorView procedure={p} />
        </Option>
      ))}
    </DropDown>
  )
}

const DropDown = styled(Select)`
  width: 90%;
  margin-bottom: 20px;

  & > .ant-select-selector {
    height: 83px !important;
  }
`

export default SelectGlobalProcedures
