import Check from '../../../../components/icons/check'
import CloseIcon from '../../../../components/icons/close-icon'
import NotApplicable from '../../../../components/icons/not-applicable'
import SandClock from '../../../../components/icons/sand-clock'
import Sync from '../../../../components/icons/sync'

export const EXECUTION_COMPONENT_TYPES = {
  SIGNATURE: 'Signature',
  RECORDER: 'Recorder',
  NUMERICAL: 'Numerical',
  MULTICELL: 'MultiCell',
  OPTION_LIST: 'OptionList',
  FORMULA: 'Formula',
  JUMP: 'Jump',
  REFERENCE: 'Reference',
  FILE_REFERENCE: 'FileReference',
  CHECKBOX: 'CheckBox',
  DATE: 'Date',
  TIME: 'Time',
  TEXTAREA: 'TextArea',
  READOUT_RT_RECORDER: 'ReadoutRTRecorder',
  READOUT_RT_NUMERICAL: 'ReadoutRTNumerical'
}

export const EXECUTION_SIGNATURE_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  WAITING: 'WAITING',
  N_A: 'N_A',
  NOT_OK: 'NOT_OK',
  OK: 'OK'
}

export const EXECUTION_SIGNATURE_STATUS_ICON = {
  IN_PROGRESS: Sync,
  WAITING: SandClock,
  NOT_OK: CloseIcon,
  N_A: NotApplicable,
  OK: Check
}

export const EXECUTION_SIGNATURE_STATUS_IS_FINAL = {
  IN_PROGRESS: false,
  WAITING: false,
  N_A: true,
  NOT_OK: true,
  OK: true
}

// Para poder pulsar los botones en las pruebas automáticas
export const EXECUTION_SIGNATURE_BUTTON_ID = {
  IN_PROGRESS: 'in-progress-signature-button',
  WAITING: 'waiting-signature-button',
  N_A: 'n_a-signature-button',
  NOT_OK: 'not_ok-signature-button',
  OK: 'ok-signature-button'
}
