import React from 'react'

const NotApplicable = ({ size = '24', color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 21 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.68 4.9975V14.675H0V0.5H1.3475L5.3725 9.915V0.5H7.0175V14.675H5.7575L1.68 4.9975ZM13.7958 0.5L9.96333 14.675H8.35333L12.1683 0.5H13.7958ZM14.5325 14.675L15.215 11.105H18.1025L18.75 14.675H20.6225L17.595 0.5H15.6875L12.6775 14.675H14.5325ZM15.4775 9.6875H17.84L16.65 3.3L15.4775 9.6875Z'
      fill={color}
    />
  </svg>
)

export default NotApplicable
