import { groupBy } from 'lodash'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'

import MediaUploadingContainer from '../../containers/media-uploading'
import {
  getMediaUploadingExecutionComment,
  getMediaUploadingStep
} from '../../store/media-uploading/selectors'
import Translation from '../translations'
import UploadProgress from '../upload-progress'

function MediaUploading({
  showMedia,
  isNotification = false,
  size = 'small',
  onClickEyeAction
}) {
  const mediaUploadingStep = useSelector(getMediaUploadingStep, shallowEqual)
  const mediaUploadingExecution = useSelector(
    getMediaUploadingExecutionComment,
    shallowEqual
  )

  const mediaUploadingGroupedByStep = groupBy(mediaUploadingStep, 'stepIndex')

  return showMedia ? (
    <>
      {mediaUploadingStep.length > 0 ? (
        <>
          <Title>
            <Translation id='comment.step.level' />
          </Title>
          {Object.keys(mediaUploadingGroupedByStep).map((stepIndex) => (
            <section key={`media-${stepIndex}`}>
              <Text>Paso {stepIndex}</Text>
              <MediaUploadingContainer>
                {mediaUploadingGroupedByStep[stepIndex]?.map((media, index) => {
                  return (
                    <UploadProgress
                      key={`${index}-media-uploading`}
                      media={media}
                      size={size}
                      isNotification={isNotification}
                      onClickEyeAction={onClickEyeAction}
                    />
                  )
                })}
              </MediaUploadingContainer>
            </section>
          ))}
        </>
      ) : null}

      {mediaUploadingExecution.length > 0 ? (
        <section>
          <Title>
            <Translation id='comment.execution.level' />
          </Title>
          <MediaUploadingContainer>
            {mediaUploadingExecution.map((media, index) => {
              return (
                <UploadProgress
                  key={`${index}-media-uploading`}
                  media={media}
                  isNotification={isNotification}
                  size={size}
                  onClickEyeAction={onClickEyeAction}
                />
              )
            })}
          </MediaUploadingContainer>
        </section>
      ) : null}
    </>
  ) : null
}

const Title = styled.p`
  margin: 10px 0;
  padding: 0;
  font-size: '18px';
  font-weight: bold;
`

const Text = styled.p`
  margin: 10px 0;
  padding: 0;
  font-size: ${({ 'data-fontsize': fontsize }) => fontsize || '16px'};
`

export default MediaUploading
