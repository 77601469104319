import { CONNECTION_STATUS } from "../../../utils/constants/connection"
import { setConnectionStatus } from "../slice"

export function enableConnection() {
  return async (dispatch) => {
    dispatch(setConnectionStatus(CONNECTION_STATUS.CONNECTING))
  }
}

export function disableConnection() {
  return async (dispatch) => {
    dispatch(setConnectionStatus(CONNECTION_STATUS.DISABLED))
  }
}
