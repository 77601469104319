import { createSlice } from '@reduxjs/toolkit'

import { addToLocaldbQueue, deleteFromLocaldbQueue } from '../helpers'

export const executionCommentsSlice = createSlice({
  name: 'executionComments',
  initialState: {
    comments: [],
    lastSave: null,
    localdbQueue: []
  },
  reducers: {
    setExecutionComments: (state, action) => {
      const { comments } = action.payload
      state.comments = comments
      state.lastSave = comments ? new Date(Date.now() + 1000).toString() : null
    },
    addExecutionComment: (state, action) => {
      const { comment } = action.payload

      state.comments.push(comment)
    },
    addMediaToExecutionComment: (state, action) => {
      const { commentId, mediaId, now } = action.payload
      const comment = state.comments.find((c) => c.id === commentId)

      if (!comment?.mediaIds.includes(mediaId)) {
        comment.mediaIds.push(mediaId)
        comment.updatedAt = now
      }
    },
    deleteMediaExecutionComment: (state, action) => {
      const { commentId, mediaId, now } = action.payload
      const comment = state.comments.find((c) => c.id === commentId)

      comment.mediaIds = comment.mediaIds.filter((m) => m !== mediaId)
      comment.updatedAt = now
    },
    deleteExecutionComment: (state, action) => {
      const { commentId, deletedAt } = action.payload
      const comment = state.comments.find((c) => c.id === commentId)

      comment.deletedAt = deletedAt
    },
    updateLocaldbQueue: (state, action) => {
      const { comment, resetQueue } = action.payload

      if (resetQueue) {
        state.localdbQueue = []
      }

      if (Array.isArray(comment)) {
        comment.forEach((c) => addToLocaldbQueue(state, c))
        return
      }

      addToLocaldbQueue(state, comment)
    },
    editExecutionComment: (state, action) => {
      const { comment } = action.payload
      const commentToUpdate = state.comments.find(
        (commentState) => commentState.id === comment.id
      )
      commentToUpdate.comment = comment.comment
      commentToUpdate.updatedAt = comment.updatedAt
    },
    setSyncedAt: (state, action) => {
      const { entities, syncedAt } = action.payload

      state.comments
        ?.filter((c) => entities.some((e) => e.id === c.id))
        .forEach((c) => {
          c.syncedAt = syncedAt
        })
    },
    setLastSave: (state, action) => {
      state.lastSave = action.payload || new Date().valueOf()
    },
    removeLocaldbQueue: (state, action) => {
      const { entitiesToDelete } = action.payload
      deleteFromLocaldbQueue(state, entitiesToDelete)
    }
  }
})

export const {
  setExecutionComments,
  addExecutionComment,
  addMediaToExecutionComment,
  deleteMediaExecutionComment,
  deleteExecutionComment,
  editExecutionComment,
  setLastSave,
  setSyncedAt,
  updateLocaldbQueue,
  removeLocaldbQueue
} = executionCommentsSlice.actions

export default executionCommentsSlice.reducer
