export function sortByKey(array, key) {
  return array.sort((a, b) => {
    if (a[key] > b[key]) return 1
    if (a[key] < b[key]) return -1
    return 0
  })
}

export function sortProceduresByStepId(step1, step2) {
  let returnValue = 0

  const numStepId1 = step1.stepId
    .split('_')
    .slice(2)
    .map((value) => Number(value))
  const numStepId2 = step2.stepId
    .split('_')
    .slice(2)
    .map((value) => Number(value))

  for (const index in numStepId1) {
    if (numStepId1[index] > numStepId2[index]) {
      returnValue = 1
      break
    } else if (numStepId1[index] < numStepId2[index]) {
      returnValue = -1
      break
    }
  }

  return returnValue
}
