import React, { useState, useContext, useEffect } from 'react'


import Modal from '../../../views/modal'
import RadioGroup from '../../../views/radio-buttons/group'
import Translation from '../../../views/translations'

import { UsersFiltersContext } from '.'

export const FiltersModal = ({ visible, setVisible, roles }) => {
  const { filters, updateFilters, resetFilters } =
    useContext(UsersFiltersContext)
  const [role, setRole] = useState([...(filters.role || [])])

  useEffect(() => {
    setRole([...(filters.role || [])])
  }, [filters.role])

  const onOk = () => {
    setVisible(false)
    updateFilters({ role })
  }

  const onClose = () => {
    setVisible(false)
  }

  const onCancel = () => {
    setVisible(false)
    resetFilters()
  }

  return (
    <Modal
      visible={visible}
      width={600}
      title={<Translation id='filters' />}
      okButtonText={<Translation id='applyFilters' />}
      cancelButtonText={<Translation id='clearFilters' />}
      onOk={onOk}
      cancellable
      onClose={onClose}
      onCancel={onCancel}
    >
      <h4>
        <Translation id='role' />
      </h4>
      <RadioGroup
        multiple
        value={role}
        options={roles?.map(({ id, name }) => ({
          label: <span>{name}</span>,
          key: id
        }))}
        onChange={(roles) => {
          setRole(roles)
        }}
      />
    </Modal>
  )
}
