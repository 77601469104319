import React from 'react'

const Jump = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/jump'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <line
        x1='18.75'
        y1='3.75'
        x2='18.75'
        y2='20.25'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></line>
      <path
        d='M14.25,8.25 L18.22,3.97 C18.512,3.677 18.987,3.677 19.279,3.969 C19.279,3.969 19.28,3.97 19.28,3.97 L23.25,8.25'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M1.75,14.25 L8.75,14.25 C9.302,14.25 9.75,14.698 9.75,15.25 L9.75,22.25 C9.75,22.802 9.302,23.25 8.75,23.25 L1.75,23.25 C1.198,23.25 0.75,22.802 0.75,22.25 L0.75,15.25 C0.75,14.698 1.198,14.25 1.75,14.25 Z'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M2.25,0.75 C1.422,0.75 0.75,1.422 0.75,2.25'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M0.75,8.25 C0.75,9.078 1.422,9.75 2.25,9.75'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M8.25,0.75 C9.078,0.75 9.75,1.422 9.75,2.25'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M9.75,8.25 C9.75,9.078 9.078,9.75 8.25,9.75'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <line
        x1='4.5'
        y1='0.75'
        x2='6'
        y2='0.75'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></line>
      <line
        x1='4.5'
        y1='9.75'
        x2='6'
        y2='9.75'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></line>
      <line
        x1='0.75'
        y1='6'
        x2='0.75'
        y2='4.5'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></line>
      <line
        x1='9.75'
        y1='6'
        x2='9.75'
        y2='4.5'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></line>
    </g>
  </svg>
)

export default Jump
