import React from 'react'

const Sync = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/EP-alert'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id='Group'
        transform='translate(-2.000000, 2.000000)'
        stroke={color}
        strokeWidth='2'
      >
        <path
          d='M21.4223333,3.5 C18.0378333,-0.599666667 11.9711667,-1.17833333 7.87266667,2.205 C5.6595,4.032 4.37733333,6.7515 4.375,9.6215 L4.375,12.25'
          id='Path'
        ></path>
        <path
          d='M7,16.226 C10.6481667,20.0923333 16.7405,20.2685 20.6056667,16.6203333 C22.5318333,14.8026667 23.6238333,12.271 23.625,9.6215 L23.625,7.8715'
          id='Path'
        ></path>
        <polyline
          id='Path'
          points='0.875 8.7465 4.375 12.2465 7.875 8.7465'
        ></polyline>
        <polyline
          id='Path'
          points='27.125 11.3715 23.625 7.8715 20.125 11.3715'
        ></polyline>
      </g>
    </g>
  </svg>
)

export default Sync
