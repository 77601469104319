import { useRemoteData } from '../useRemoteData'

export const useGlobalDownloadedProcedures = ({
  disabled,
  select,
  skip,
  take,
  orderBy,
  orderDirection,
  deviceId = '*',
  status,
  key,
  title
} = {}) => {
  return useRemoteData({
    url: `/devices/${deviceId}/procedures`,
    disabled,
    select,
    skip,
    take,
    orderBy: orderBy || 'updatedAt',
    orderDirection: orderDirection || 'ascend',
    status,
    key,
    title
  })
}
