import { store } from '../../../store'
import { setSyncDate } from '../../../store/executionComments/actions'
import { getSyncQuery } from '../../../store/synchronization'
import {
  setModuleIsNotSyncing,
  setModuleIsSyncing
} from '../../../store/userInterface/slice'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { debug, erro } from '../../../utils/logger'
import { sendExecutionComments } from '../send'

const tableName = TABLE_NAMES.EXECUTION_COMMENTS

export async function syncExecutionComments(result) {
  const syncedAt = new Date().valueOf()

  const data = result ?? (await getSyncQuery(tableName)())

  if (data.length) {
    store.dispatch(setModuleIsSyncing({ moduleName: tableName }))
    debug(`Syncing ${tableName}`, data)
    try {
      await sendExecutionComments(data)

      store.dispatch(setSyncDate({ syncedAt, entities: data }))
    } catch (error) {
      erro(`Error syncing ${tableName}`, error)
      store.dispatch(setModuleIsNotSyncing({ moduleName: tableName }))
    }
  }
}
