import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getExecutionComponents } from '../store/executionComponents/selectors'
import { getProcedure } from '../store/procedure/selectors'
import { EXECUTION_COMPONENT_TYPES } from '../utils/constants/execution/components'
import {
  numberOfFilledCheckbox,
  numberOfFilledFormula,
  numberOfFilledMulticell,
  numberOfFilledNumerical,
  numberOfFilledOptionList,
  numberOfFilledRecorderTextAreaDateTime,
  numberOfFilledSignature
} from '../utils/helpers/statistics'

import useDisableSteps from './use-disable-steps'

export const useStatistic = (typeOfComponent) => {
  const procedure = useSelector(getProcedure)
  const executionComponents = useSelector(getExecutionComponents)

  const { disabledSteps } = useDisableSteps()

  const { itemsToFill, filledItems, dontMeetCriteria } = useMemo(() => {
    if (!executionComponents) {
      return { itemsToFill: null, filledItems: null }
    }

    const allItems = procedure.components.filter(
      (c) =>
        c.type.toUpperCase() === typeOfComponent.toUpperCase() &&
        !disabledSteps.includes(c.stepId)
    )

    if (allItems.length == 0) {
      return { itemsToFill: null, filledItems: null }
    }

    if (
      typeOfComponent.toUpperCase() ===
      EXECUTION_COMPONENT_TYPES.SIGNATURE.toUpperCase()
    ) {
      return numberOfFilledSignature(allItems, executionComponents)
    }

    if (
      typeOfComponent.toUpperCase() ===
      EXECUTION_COMPONENT_TYPES.MULTICELL.toUpperCase()
    ) {
      return numberOfFilledMulticell(allItems, executionComponents)
    }

    if (
      typeOfComponent.toUpperCase() ===
      EXECUTION_COMPONENT_TYPES.NUMERICAL.toUpperCase()
    ) {
      return numberOfFilledNumerical(allItems, executionComponents)
    }

    if (
      typeOfComponent.toUpperCase() ===
      EXECUTION_COMPONENT_TYPES.OPTION_LIST.toUpperCase()
    ) {
      return numberOfFilledOptionList(allItems, executionComponents)
    }

    if (
      typeOfComponent.toUpperCase() ===
      EXECUTION_COMPONENT_TYPES.FORMULA.toUpperCase()
    ) {
      return numberOfFilledFormula(
        allItems,
        executionComponents,
        procedure.components
      )
    }

    if (
      typeOfComponent.toUpperCase() ===
      EXECUTION_COMPONENT_TYPES.CHECKBOX.toUpperCase()
    ) {
      return numberOfFilledCheckbox(allItems, executionComponents)
    }

    if (
      typeOfComponent.toUpperCase() ===
        EXECUTION_COMPONENT_TYPES.RECORDER.toUpperCase() ||
      typeOfComponent.toUpperCase() ===
        EXECUTION_COMPONENT_TYPES.TEXTAREA.toUpperCase() ||
      typeOfComponent.toUpperCase() ===
        EXECUTION_COMPONENT_TYPES.TIME.toUpperCase() ||
      typeOfComponent.toUpperCase() ===
        EXECUTION_COMPONENT_TYPES.DATE.toUpperCase()
    ) {
      return numberOfFilledRecorderTextAreaDateTime(
        allItems,
        executionComponents
      )
    }

    return {
      itemsToFill: [],
      filledItems: [],
      dontMeetCriteria: []
    }
  }, [
    disabledSteps,
    executionComponents,
    procedure.components,
    typeOfComponent
  ])

  return { itemsToFill, filledItems, dontMeetCriteria }
}
