import {
  EXECUTION_STATUS,
  EXECUTION_STATUS_CAN_CHANGE_TO
} from '../../../utils/constants/execution'

export function canFinishOrAbortExecution(
  stepsInProgress,
  execution,
  isWorking,
  allUploadFinished,
  executionIsFinished,
  toStatus,
  isModeFlexible
) {
  if (isWorking) {
    return {
      disabled: true,
      message: 'stop.execution.disabled.is.working'
    }
  }

  if (!allUploadFinished) {
    return {
      disabled: true,
      message: 'stop.execution.disabled.media.uploading'
    }
  }

  if (toStatus === EXECUTION_STATUS.ABORTED && stepsInProgress > 0) {
    return {
      disabled: true,
      message: 'abort.execution.disabled.steps.progress'
    }
  }

  if (
    toStatus === EXECUTION_STATUS.FINISHED &&
    isModeFlexible &&
    stepsInProgress > 0
  ) {
    return {
      disabled: true,
      message: 'stop.execution.disabled.steps.progress'
    }
  }

  if (
    toStatus === EXECUTION_STATUS.FINISHED &&
    !isModeFlexible &&
    (stepsInProgress > 0 || !executionIsFinished)
  ) {
    return {
      disabled: true,
      message: 'stop.execution.disabled.not.finished'
    }
  }

  if (!execution) {
    return {
      disabled: true,
      message: 'stop.execution.disabled.no.execution'
    }
  }

  if (!EXECUTION_STATUS_CAN_CHANGE_TO[execution.status].includes(toStatus)) {
    return {
      disabled: true,
      message: 'stop.execution.disabled.can.not.status'
    }
  }

  return {
    disabled: false
  }
}
