import Dexie from 'dexie'
import { debounce } from 'lodash'
import moment from 'moment'

import { getTranslation } from '../../i18n/getTranslation'
import { getDatabase } from '../../localdb'
import { writeExecutions } from '../../localdb/execution/write'
import { writeExecutionComponents } from '../../localdb/execution-component/write'
import { writeExecutionConditionals } from '../../localdb/execution-conditionals/write'
import { writeExecutionSteps } from '../../localdb/execution-step/write'
import { deleteRemoteExecution } from '../../services/execution/http/delete'
import { history } from '../../utils/constants/browser-history'
import { EXECUTION_MODES } from '../../utils/constants/execution'
import { TABLE_NAMES } from '../../utils/constants/localdb'
import { notificationMessage } from '../../utils/helpers/notification-message'
import { erro } from '../../utils/logger'
import { deleteExecutions as deleteLocalExecutions } from '../execution/localDb'
import { writeExecutionComments } from '../executionComments/localDb'
import { writeExecutionManeuvers } from '../executionManeuvers/localDb'
import { writeExecutionWarnings } from '../executionWarnings/localDb'
import { setModuleIsNotSyncing } from '../userInterface/slice'

const modules = {
  execution: {
    mainStateName: 'execution',
    saveFn: writeExecutions,
    tableName: TABLE_NAMES.EXECUTIONS
  },
  executionComments: {
    mainStateName: 'comments',
    saveFn: writeExecutionComments,
    tableName: TABLE_NAMES.EXECUTION_COMMENTS
  },
  executionComponents: {
    mainStateName: 'components',
    saveFn: writeExecutionComponents,
    tableName: TABLE_NAMES.EXECUTION_COMPONENTS
  },
  executionManeuvers: {
    mainStateName: 'maneuvers',
    saveFn: writeExecutionManeuvers,
    tableName: TABLE_NAMES.EXECUTION_MANEUVERS
  },
  executionSteps: {
    mainStateName: 'steps',
    saveFn: writeExecutionSteps,
    tableName: TABLE_NAMES.EXECUTION_STEPS
  },
  executionWarnings: {
    mainStateName: 'warnings',
    saveFn: writeExecutionWarnings,
    tableName: TABLE_NAMES.EXECUTION_WARNINGS
  },
  executionConditionals: {
    mainStateName: 'conditionals',
    saveFn: writeExecutionConditionals,
    tableName: TABLE_NAMES.EXECUTION_CONDITIONALS
  }
}

async function save(moduleName, store) {
  const module = modules[moduleName]

  if (!module) return

  const { mainStateName, saveFn, tableName } = module

  const {
    [moduleName]: { [mainStateName]: state, lastSave }
  } = store.getState()

  if (!lastSave) return

  const dates = [state.updatedAt, state.createdAt, state.deletedAt]

  const shouldSave = dates.some((date) =>
    moment(new Date(date)).isSameOrAfter(new Date(lastSave))
  )

  const shouldSaveExecution =
    shouldSave &&
    state.status.toUpperCase() !== EXECUTION_MODES.SHARED.toUpperCase()

  if (shouldSaveExecution) {
    return saveFn([state])
      .then(() => {
        store.dispatch({
          type: `${moduleName}/setLastSave`,
          payload: new Date().valueOf()
        })
      })
      .finally(() => {
        store.dispatch(setModuleIsNotSyncing({ moduleName: tableName }))
      })
  } else {
    store.dispatch(setModuleIsNotSyncing({ moduleName: tableName }))
  }
}

async function saveExecutionChildren(moduleName, store) {
  const { saveFn, tableName } = modules[moduleName]
  const {
    [moduleName]: { localdbQueue }
  } = store.getState()

  if (localdbQueue.length === 0) return

  return saveFn(localdbQueue)
    .catch((error) => {
      erro(`Error in... ${moduleName}`)
      throw error
    })
    .finally(() => {
      store.dispatch(setModuleIsNotSyncing({ moduleName: tableName }))
    })
}

export const executionSaveMiddleware = (store) => (next) => async (action) => {
  const result = await next(action)
  const [slice] = action.type.split('/')
  const module = modules[slice]

  if (
    typeof action !== 'function' &&
    store.getState().execution.mode !== 'shared' &&
    module
  ) {
    // TODO: await?
    debouncedSave(store)
  }

  return result
}

const debouncedSave = debounce((store) => {
  return saveAllExecutionStore(store)
}, 2000)

export const saveAllExecutionStore = async (store) => {
  const db = getDatabase()

  return (
    db
      .transaction(
        'rw',
        db[TABLE_NAMES.EXECUTIONS],
        db[TABLE_NAMES.EXECUTION_COMMENTS],
        db[TABLE_NAMES.EXECUTION_COMPONENTS],
        db[TABLE_NAMES.EXECUTION_MANEUVERS],
        db[TABLE_NAMES.EXECUTION_STEPS],
        db[TABLE_NAMES.EXECUTION_WARNINGS],
        db[TABLE_NAMES.EXECUTION_CONDITIONALS],
        db[TABLE_NAMES.EXECUTION_SYNC_STATUS],
        async () =>
          Dexie.Promise.all(
            Object.keys(modules).map(async (moduleName) => {
              if (moduleName === 'execution') {
                return save(moduleName, store)
              } else {
                const result = await saveExecutionChildren(moduleName, store)
                return { moduleName, result }
              }
            })
          )
      )
      // If transaction success, delete elements in queue
      .then((data) => {
        data
          .filter((d) => d && d.moduleName && d.result)
          .forEach(({ moduleName, result }) => {
            const toRemove = result.map(({ id, updatedAt }) => ({
              id,
              updatedAt
            }))

            store.dispatch({
              type: `${moduleName}/removeLocaldbQueue`,
              payload: { entitiesToDelete: toRemove }
            })
          })
      })
      // Transaction fails, so if the execution is new must be deleted
      .catch((error) => {
        erro('Error saving all execution store in indexed db', error)

        notificationMessage({
          type: 'error',
          message: getTranslation('error.execution.save.data')
        })

        const { execution: _execution, ...childModules } = modules

        const {
          execution: { execution }
        } = store.getState()

        Promise.all(
          Object.keys(childModules).map(async (module) => {
            const { tableName } = modules[module]
            return db[tableName]
              .where({
                executionId: execution.id
              })
              .toArray()
          })
        )
          .then((res) => res)
          // Some db get data promise fails, so user may be have to delete the execution
          .catch((error) => {
            erro('Error getting data from indexed', error)
            notificationMessage({
              type: 'error',
              message: getTranslation(
                'error.execution.start.maybe.manual.delete'
              ),
              duration: null
            })

            return false
          })
          .then((results) => {
            if (!results) return false
            if (results.every((r) => r.length === 0)) {
              Object.keys(childModules).forEach((moduleName) => {
                store.dispatch({
                  type: `${moduleName}/removeLocaldbQueue`,
                  payload: {}
                })
              })

              // Try to delete remote execution created
              return deleteRemoteExecution(execution.id)
            }
            return false
          })
          .catch((error) => {
            erro(`Error deleting remote execution: ${error}`)
            return true
          })
          .then((res) => {
            if (!res) return null
            return deleteLocalExecutions([execution.id])
          })
          .then((res) => {
            if (!res) return
            history.push(`/procedures/${execution.procedureId}`)
            notificationMessage({
              type: 'error',
              message: getTranslation('error.execution.start'),
              duration: null
            })
          })
          .catch((error) => {
            erro(`Error deleting local execution: ${error}`)
            notificationMessage({
              type: 'error',
              message: getTranslation('error.execution.start.manual.delete'),
              duration: null
            })
          })
      })
      .catch((error) => {
        erro(error)
      })
      // Finally dispatchs
      .finally(() => {
        store.dispatch({
          type: `userInterface/setIsWorking`,
          payload: false
        })

        store.dispatch({
          type: `userInterface/setLoadingExecution`,
          payload: false
        })
      })
  )
}
