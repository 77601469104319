import { Divider as AntDivider } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'

import { BadgedButton } from '../../../../components/buttons/badged-button'
import AnnotationIcon from '../../../../components/icons/annotation-icon'
import CommentEdit from '../../../../components/icons/comment-edit'
import Conversation from '../../../../components/icons/conversation'
import ColorTag from '../../../../containers/color-tag'
import useExecutionGoal from '../../../../hooks/data/useExecutionGoal'
import useLocalPlantUnit from '../../../../hooks/use-local-plant-unit'
import { useTranslation } from '../../../../hooks/useTranslation'
import {
  getExecutionStatus,
  getIsApprove,
  getIsReview
} from '../../../../store/execution/selectors'
import {
  setApprovalComment,
  setRevisionComment,
  setStatusComment
} from '../../../../store/execution/slice'
import { getExecutionComments } from '../../../../store/executionComments/selectors'
import { getExecutionSteps } from '../../../../store/executionSteps/selectors'
import { DRAWERS } from '../../../../utils/constants/drawer'
import {
  EXECUTION_QA_STATUS,
  EXECUTION_STATUS,
} from '../../../../utils/constants/execution'
import {
  EXECUTION_STATUS_COLOR,
  EXECUTION_STATUS_TEXT_COLOR
} from '../../../../utils/constants/styles'
import ExecutionGoalModal from '../../../modal/execution-goal-modal'
import ExecutionInfoModal from '../../../modal/execution-info-modal'
import Translation from '../../../translations'

import EditableItem from './editable-item'
import QAFormItem from './qa-form-item'
import ExecutionStatistics from './statistics'

const getMergeComment = (execution, executionCodeDateTimeFormat) => {
  const [
    executionCode1,
    executionCreateAt1,
    executionCode2,
    executionCreateAt2
  ] = execution.merge?.comment?.split('|') ?? []

  return (
    <div>
      <p id='execution1'>
        {executionCode1}-
        {moment(executionCreateAt1).format(executionCodeDateTimeFormat)}
      </p>
      <p id='execution2'>
        {executionCode2}-
        {moment(executionCreateAt2).format(executionCodeDateTimeFormat)}
      </p>
    </div>
  )
}

const InfoTab = ({
  execution,
  procedure,
  openDrawer,
  closeDrawer,
  onSelectStep
}) => {
  const [currentAction, setCurrentAction] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [execGoalModalVisible, setExecGoalModalVisible] = useState(false)

  const isReview = useSelector(getIsReview)
  const isApprove = useSelector(getIsApprove)
  const executionSteps = useSelector(getExecutionSteps)
  const executionComments = useSelector(getExecutionComments, shallowEqual)
  const executionStatus = useSelector(getExecutionStatus)
  const { plantUnit } = useLocalPlantUnit(execution?.unitId)
  const { executionGoal } = useExecutionGoal(execution?.executionGoalId)
  const dateFormat = useTranslation('dateFormat')
  const timeFormat = useTranslation('timeFormat')
  const executionCodeDateTimeFormat = useTranslation(
    'executionCodeDateTimeFormat'
  )

  const isExecutionShared = EXECUTION_STATUS.SHARED === executionStatus

  const { title, key, unit, rev } = procedure
  const {
    status,
    qaStatus,
    createdAt,
    finishedAt,
    finishedBy,
    approvedBy,
    approvedAt,
    revisedBy,
    revisedAt,
    id,
    statusComment,
    otherExecutionGoal,
    revisionComment,
    approvalComment
  } = execution

  const QA_FORM_INFO = [
    {
      title: 'done',
      action: setStatusComment,
      date: finishedAt,
      name: finishedBy?.name,
      comment: statusComment,
      disabled: status !== EXECUTION_STATUS.EXECUTING
    },
    {
      title: 'reviewed',
      action: setRevisionComment,
      date: revisedAt,
      name: revisedBy?.name,
      comment: revisionComment,
      disabled:
        !isReview ||
        !(
          (status === EXECUTION_STATUS.FINISHED && !qaStatus) ||
          qaStatus === EXECUTION_QA_STATUS.REJECTED
        )
    },
    {
      title: 'approved',
      action: setApprovalComment,
      date: approvedAt,
      name: approvedBy?.name,
      comment: approvalComment,
      disabled: !isApprove
    }
  ]

  const onEdit = (action) => () => {
    setCurrentAction(action)
    setModalVisible(true)
  }

  const openCommentsDrawer = () => openDrawer(DRAWERS.STEPS_COMMENTS)

  const openExecutionCommentsDrawer = () =>
    openDrawer(DRAWERS.EXECUTION_COMMENTS)

  const getCountExecutionComments = () => {
    return (
      executionComments.filter((comment) => !comment.deletedAt)?.length ?? 0
    )
  }

  const getCountStepComments = () => {
    const commentSteps = executionSteps?.filter((step) => step.comments.length)
    const comments = commentSteps
      ?.map((step) => step.comments)
      .flat()
      .filter((comment) => !comment.deletedAt)
    return (comments && comments?.length) || 0
  }

  const getCountAnnotations = () => {
    const commentAnnotations = executionSteps?.filter((s) => s.annotation)
    return (commentAnnotations && Object.keys(commentAnnotations)?.length) ?? 0
  }

  return (
    <InfoForm id='info-execution'>
      <FormItem>
        <ItemTitle>
          <Translation id='executionProgress' />
        </ItemTitle>
        <ItemContent>
          <ExecutionStatistics
            onSelectStep={onSelectStep}
            closeDrawer={closeDrawer}
          />
        </ItemContent>
      </FormItem>
      <FormItem>
        <ItemTitle>
          <Translation id='procedure' />
        </ItemTitle>
        <ItemContent id='procedure-fullname-content'>
          {unit} &#47; {key} <Translation id='rev' /> {rev} &#47; {title}
        </ItemContent>
      </FormItem>
      <FormItem>
        <ItemTitle>
          <Translation id='execution.plant.unit' />
        </ItemTitle>
        <ItemContent>
          <Divider type='vertical' />

          {plantUnit ? (
            plantUnit
          ) : (
            <EmptyField>
              <Translation id='fieldNotSet' />
            </EmptyField>
          )}
        </ItemContent>
      </FormItem>

      <FormItem>
        <ItemTitle>
          <Translation id='executionDate' />
        </ItemTitle>
        <Divider type='vertical' />
        <SubTitle>
          <Translation id='start' />
        </SubTitle>{' '}
        {createdAt && (
          <span id='started-execution-at' style={{ marginRight: '32px' }}>
            {moment(createdAt).format(dateFormat) +
              ' - ' +
              moment(createdAt).format(timeFormat)}
          </span>
        )}
        <Divider type='vertical' />
        <SubTitle>
          <Translation id='final' />
        </SubTitle>{' '}
        {finishedAt ? (
          <span id='finished-execution-at'>
            {moment(finishedAt).format(dateFormat) +
              ' - ' +
              moment(finishedAt).format(timeFormat)}
          </span>
        ) : (
          <EmptyField id='finished-execution-at'>
            <Translation id='notYetFinished' />
          </EmptyField>
        )}
      </FormItem>

      <FormItem>
        <ItemTitle>
          <Translation id='executionCode' />
        </ItemTitle>
        <ItemContent id='execution-code-content'>
          {key}_REV{rev}-{moment(createdAt).format('DDMMYY-HHmmss')}
        </ItemContent>
      </FormItem>

      <FormItem>
        <ItemTitle>
          <Translation id='internalCode' />
        </ItemTitle>
        <ItemContent id='internal-code-content'>{id}</ItemContent>
      </FormItem>

      <FormItem>
        <ItemTitle>
          <Translation id='comments' />
        </ItemTitle>
        <FormItemContentContainer>
          <AlignedItemContent id='execution-comments-count-content'>
            <Translation id='execution' />
            <BadgedButton
              onClick={openExecutionCommentsDrawer}
              badgeProps={{ count: getCountExecutionComments() }}
            >
              <ExecutionCommentIcon />
            </BadgedButton>
          </AlignedItemContent>
          <Divider type='vertical' />
          <AlignedItemContent id='step-comments-count-content'>
            <Translation id='step' />
            <BadgedButton
              onClick={openCommentsDrawer}
              badgeProps={{ count: getCountStepComments() }}
            >
              <StepCommentIcon />
            </BadgedButton>
          </AlignedItemContent>
          {!isExecutionShared && (
            <>
              <Divider type='vertical' />
              <AlignedItemContent id='annotations-count-content'>
                <Translation id='annotations' />
                <BadgedButton
                  onClick={openCommentsDrawer}
                  badgeProps={{ count: getCountAnnotations() }}
                >
                  <AnnotationStyledIcon />
                </BadgedButton>
              </AlignedItemContent>
            </>
          )}
        </FormItemContentContainer>
      </FormItem>

      <EditableItem
        id='execution-goal-edit-text'
        comment={executionGoal ?? otherExecutionGoal}
        disabled={status !== EXECUTION_STATUS.EXECUTING}
        onClick={() => {
          setExecGoalModalVisible(true)
        }}
        title='executionGoal'
        marginBottom='16px'
      />

      {execution.merge?.comment && (
        <FormItem>
          <ItemTitle>
            <Translation id='mergedFrom' />
          </ItemTitle>
          <ItemContent id='merge-source'>
            {getMergeComment(execution, executionCodeDateTimeFormat)}
          </ItemContent>
        </FormItem>
      )}

      <FormItem>
        <ItemTitle>
          <Translation id='executionStatus' />
        </ItemTitle>
        <ItemContent>
          <ColorTag
            color={EXECUTION_STATUS_COLOR[status]}
            textColor={EXECUTION_STATUS_TEXT_COLOR[status]}
          >
            <span id='execution-status' style={{ fontWeight: 'bold' }}>
              <Translation id={`${status}.capitalized`} />
            </span>
          </ColorTag>
        </ItemContent>
      </FormItem>

      {QA_FORM_INFO.map(({ title, date, name, comment, disabled, action }) => (
        <QAFormItem
          key={title}
          title={title}
          date={date}
          name={name}
          comment={comment}
          disabled={disabled}
          onClick={onEdit(action)}
        />
      ))}
      <ExecutionInfoModal
        visible={modalVisible}
        setVisible={setModalVisible}
        setCurrentAction={setCurrentAction}
        currentAction={currentAction}
      />
      <ExecutionGoalModal
        visible={execGoalModalVisible}
        setVisible={setExecGoalModalVisible}
      />
    </InfoForm>
  )
}

const InfoForm = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 24px;
`

const FormItem = styled.div`
  border: 1px solid #d9d9d9;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 19px;
`
const FormItemContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const ItemTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  text-decoration: none;
  font-family: inherit;
  margin-top: 0;
  margin-bottom: 0.5em;
  text-align: left;
`

const ItemContent = styled.span`
  font-size: 16px;
  line-height: 19px;
`

const AlignedItemContent = styled(ItemContent)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const EmptyField = styled(ItemContent)`
  color: #979797;
`

const SubTitle = styled(ItemContent)`
  font-weight: 500;
  display: inline-block;
`

const Divider = styled(AntDivider)`
  width: 3px;
  background: #d9d9d9;
  border-radius: 2px;
  margin: 0 8px 0 0;
  height: 19px;
`
const IconSharedStyle = `
  width: 35px;
  height: 19px;
  padding: 0;
  margin-left: 24px;
`
const ExecutionCommentIcon = styled(CommentEdit)`
  ${IconSharedStyle}
`
const StepCommentIcon = styled(Conversation)`
  ${IconSharedStyle}
`

const AnnotationStyledIcon = styled(AnnotationIcon)`
  ${IconSharedStyle}
`

export default InfoTab
