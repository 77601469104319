import React from 'react'

const Recharge = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.5 22C8.57163 22 6.68657 21.4282 5.08319 20.3568C3.47981 19.2855 2.23013 17.7627 1.49217 15.9812C0.75422 14.1996 0.561138 12.2392 0.937344 10.3479C1.31355 8.45656 2.24215 6.71928 3.60571 5.35571C4.96927 3.99215 6.70655 3.06355 8.59787 2.68735C10.4892 2.31114 12.4496 2.50422 14.2312 3.24218C16.0127 3.98013 17.5355 5.22982 18.6068 6.83319C19.6782 8.43657 20.25 10.3216 20.25 12.25V13'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M23.25 9.99707L20.25 12.9971L17.25 9.99707'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Recharge
