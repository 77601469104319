import { EXECUTION_STATUS } from '../../utils/constants/execution'
import { TABLE_NAMES } from '../../utils/constants/localdb'
import { erro } from '../../utils/logger'
import { filterByUpdatedAt } from '../helpers'
import { setModuleIsNotSyncing } from '../userInterface/slice'

import { readExecutionWarnings, writeExecutionWarnings } from './localDb'
import {
  setExecutionWarnings,
  setSyncedAt,
  setWarningRead,
  updateLocaldbQueue
} from './slice'

export function saveExecutionWarnings(warnings) {
  return async (dispatch) => {
    dispatch(setExecutionWarnings(warnings))
    dispatch(updateLocaldbQueue({ warning: warnings, resetQueue: true }))
  }
}

export function loadExecutionWarnings(executionId) {
  return async (dispatch) => {
    if (!executionId) {
      dispatch(setExecutionWarnings(null))
    } else {
      const executionWarnings = await readExecutionWarnings(executionId)
      dispatch(setExecutionWarnings(executionWarnings))
    }
  }
}

export function setWarningReadAction({ id, isRead }) {
  return async (dispatch, getState) => {
    const state = getState()
    const { execution } = state
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()
    const warning = state.executionWarnings.warnings.find((w) => w.id === id)

    if (warning) {
      if (!isShared) {
        dispatch(
          updateLocaldbQueue({
            warning: { ...warning, isRead, updatedAt: now }
          })
        )
      }
      dispatch(setWarningRead({ id, isRead, now }))
    }
  }
}

export function setSyncDate({ entities, syncedAt }) {
  return async (dispatch, getState) => {
    const {
      execution,
      executionWarnings: { warnings }
    } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    const entitiesNotInState = entities.filter(
      (entity) => entity.executionId !== execution.execution?.id
    )

    if (entitiesNotInState.length > 0) {
      try {
        await writeExecutionWarnings(
          entitiesNotInState.map((e) => ({ ...e, syncedAt }))
        )
      } catch (error) {
        erro('Error writing execution warnings', error)
      }
    }

    const syncedWarnings = filterByUpdatedAt(entities, warnings)

    if (syncedWarnings?.length) {
      try {
        if (!isShared) {
          const warningsToQueue = syncedWarnings.map((_warning) => ({
            ..._warning,
            syncedAt
          }))
          dispatch(updateLocaldbQueue({ warning: warningsToQueue }))
        }
        dispatch(setSyncedAt({ entities: syncedWarnings, syncedAt }))
      } catch (error) {
        erro('Error syncing execution warnings', error)
      }
    } else {
      dispatch(
        setModuleIsNotSyncing({ moduleName: TABLE_NAMES.EXECUTION_WARNINGS })
      )
    }
  }
}
