import Dexie from 'dexie'

import { TABLE_NAMES } from '../../../utils/constants/localdb'

export async function getDeviceProcedures() {
  const databaseNames = await Dexie.getDatabaseNames()

  return (
    await Promise.all(
      databaseNames.map(async (databaseName) => {
        const db = new Dexie(databaseName)
        await db.open()

        const encryptedProcedures = await db
          .table(TABLE_NAMES.PROCEDURES)
          .toArray()

        return encryptedProcedures.map((encryptedProcedure) => {
          return {
            procedureId: encryptedProcedure.id,
            downloadedByEmail: databaseName
          }
        })
      })
    )
  ).flat()
}
