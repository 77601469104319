import { Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Button from '../../../components/buttons/button'
import Sync from '../../../components/icons/sync'
import { useTranslation } from '../../../hooks/useTranslation'
import { getTranslation } from '../../../i18n/getTranslation'
import { resetPassword } from '../../../services/user/password/http/put'
import { alert, confirm } from '../../../utils/helpers/dialogs'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { erro } from '../../../utils/logger'
import Modal from '../../../views/modal'
import RadioGroup from '../../../views/radio-buttons/group'
import Translation from '../../../views/translations'
import DisplayedPassword from '../../../views/users/password/displayed-password'

export const UserEditModal = ({
  title,
  okButtonText,
  visible,
  setVisible,
  loading,
  onFinish,
  initialValues = {},
  disabledInputs = [],
  roles
}) => {
  const [loadingResetPassword, setLoadingResetPassword] = useState(false)
  const [form] = Form.useForm()
  const [
    usernameTranslation,
    emailTranslation,
    roleTranslation,
    initialsTranslation
  ] = useTranslation(['username', 'email', 'role', 'initials'])

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues)
    }
  }, [visible, form, initialValues])

  const onCancel = async () => {
    // Solicitar confirmación si se modificó algún campo
    const currentFormValues = form.getFieldsValue()
    const changedFields = Object.keys(currentFormValues).filter(
      (key) => currentFormValues[key] !== initialValues[key]
    )
    if (changedFields.length > 0) {
      const result = await confirm({
        title: <Translation id='exitWithoutSaving' />,
        children: <Translation id='exitWithoutSavingDescription' />,
        modalProps: {
          okButtonText: <Translation id='exit' />,
          cancelButtonText: <Translation id='cancel' />
        }
      })

      if (result) {
        setVisible(false)
        form.resetFields()
      }
    } else {
      setVisible(false)
      form.resetFields()
    }
  }

  const onFinishForm = (finishValues) => {
    // Eliminamos los valores desactivados
    const values = Object.keys(finishValues).reduce((acc, key) => {
      if (!disabledInputs.includes(key)) {
        acc[key] = finishValues[key]
      }
      return acc
    }, {})

    values['email'] = values['email']?.toLowerCase()
    onFinish(values)
  }

  const onResetPassword = async () => {
    setLoadingResetPassword(true)

    try {
      const newPassword = await resetPassword(initialValues.id)

      setVisible(false)
      alert({
        title: getTranslation('userAddedTemporaryPassword'),
        children: <DisplayedPassword>{newPassword}</DisplayedPassword>,
        modalProps: {
          okButtonText: getTranslation('close')
        }
      })
    } catch (error) {
      erro(error)
      notificationMessage({
        type: 'error',
        message: getTranslation('error'),
        description: getTranslation('user.reset.password.error')
      })
    } finally {
      setLoadingResetPassword(false)
    }
  }

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title={title}
      closable={false}
      maskClosable={false}
      okButtonText={okButtonText}
      onCancel={onCancel}
      formId='addUserForm'
      loading={loading}
    >
      <Form
        id='addUserForm'
        name='addUserForm'
        layout='vertical'
        form={form}
        onFinish={onFinishForm}
        initialValues={initialValues}
      >
        <Form.Item
          name='name'
          label={usernameTranslation}
          rules={[{ required: true }]}
        >
          <Input disabled={disabledInputs.includes('name')} />
        </Form.Item>
        <Form.Item
          name='email'
          label={emailTranslation}
          rules={[
            {
              required: true
            },
            {
              type: 'email'
            }
          ]}
        >
          <Input disabled={disabledInputs.includes('email')} />
        </Form.Item>
        <Form.Item
          name='initials'
          label={initialsTranslation}
          rules={[{ required: true }]}
        >
          <Input disabled={disabledInputs.includes('initials')} maxLength={4} />
        </Form.Item>
        <Form.Item
          name='roleId'
          label={roleTranslation}
          rules={[{ required: true }]}
        >
          <RadioGroup
            disabled={disabledInputs.includes('roleId')}
            options={roles?.map(({ name, id }) => ({
              label: name,
              key: id
            }))}
          />
        </Form.Item>
      </Form>
      {initialValues.id ? (
        <>
          <Section>
            <SectionTitle>
              <Translation id='user.password.title' />
            </SectionTitle>
            <SectionText>
              <Translation id='user.password.text' />
            </SectionText>
            <Input
              disabled={true}
              type='password'
              value={initialValues.password}
            />
          </Section>
          <Section>
            <SectionTitle>
              <Translation id='user.reset.password.title' />
            </SectionTitle>
            <SectionText>
              <Translation id='user.reset.password.text' />
            </SectionText>
            <Button
              width='136px'
              icon={<Sync size={16} />}
              loading={loadingResetPassword}
              onClick={onResetPassword}
            >
              <Translation id='generateBtn' />
            </Button>
          </Section>
        </>
      ) : null}
    </Modal>
  )
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  top: 469px;

  margin-bottom: 24px;
`

const SectionTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`

const SectionText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #5b5b5b;
`
