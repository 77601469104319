import { axiosInstance } from '../../..'

export async function importProcedures(file) {
  return axiosInstance.post('/import', file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export async function exportProcedures(procedureIds) {
  return axiosInstance.post(
    '/export',
    { procedureIds },
    { responseType: 'blob' }
  )
}
