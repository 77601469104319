import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Modal from '..'
import { logout } from '../../../application/users/session'
import { useLocalExecutions } from '../../../hooks/data/useLocalExecutions'
import useInterruptMassiveExecution from '../../../hooks/executions/use-interrupt-massive-execution'
import { cleanExecutions } from '../../../store/execution/localDb'
import { cleanProcedures } from '../../../store/procedure/localDb'
import { getConnectionStatus } from '../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { PERMISSION_TYPES } from '../../../utils/constants/roles'
import { userHasPermission } from '../../../utils/helpers/permissions'
import { MassiveInterruption } from '../../massive-interruption'
import Translation from '../../translations'

import { AllSynced } from './all-synced'
import { SyncNeeded } from './sync-needed'

const LogoutModal = ({ visible, setVisible, onCancel }) => {
  const history = useHistory()
  const connectionStatus = useSelector(getConnectionStatus)

  const [executionsExecutingOrPausedSyncInfo] = useLocalExecutions({
    withProcedure: true,
    status: [EXECUTION_STATUS.EXECUTING, EXECUTION_STATUS.PAUSED]
  })

  const areAllExecutionsSynced = useMemo(
    () => executionsExecutingOrPausedSyncInfo?.every(({ synced }) => synced),
    [executionsExecutingOrPausedSyncInfo]
  )

  const [interrupt, loading] = useInterruptMassiveExecution(
    executionsExecutingOrPausedSyncInfo,
    logout
  )

  async function onConfirmLogout() {
    if (
      executionsExecutingOrPausedSyncInfo?.length > 0 &&
      areAllExecutionsSynced
    ) {
      await interrupt()
      return
    }

    if (!areAllExecutionsSynced) {
      setVisible(false)

      history.push({
        pathname: '/dashboard/executions'
      })
    } else {
      //Tecospr-1283: No ejecutar limpieza de procedimientos al salir y entrar en determinados roles
      //Tecospr-1416: No ejecutar limpieza de procedimientos al salir si estamos offline
      if (
        !userHasPermission(PERMISSION_TYPES.EXECUTION_KEEP_EXECUTIONS) &&
        connectionStatus !== CONNECTION_STATUS.OFFLINE
      ) {
        await cleanExecutions()
        await cleanProcedures()
      }
      logout()
    }
  }

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      okButtonText={
        executionsExecutingOrPausedSyncInfo?.length > 0 ? (
          <Translation id='interruptLogout' />
        ) : (
          <Translation id='okText' />
        )
      }
      onOk={onConfirmLogout}
      disableOkButton={
        !areAllExecutionsSynced &&
        executionsExecutingOrPausedSyncInfo?.length > 0
      }
      loading={loading}
      cancellable={
        areAllExecutionsSynced ||
        executionsExecutingOrPausedSyncInfo?.length > 0
      }
      onCancel={() => {
        onCancel()
        history.push({
          pathname: '/dashboard/executions'
        })
      }}
      cancelButtonText={
        executionsExecutingOrPausedSyncInfo?.length > 0 ? (
          <Translation id='reviseExecutions' />
        ) : (
          <Translation id='cancel' />
        )
      }
      title={
        executionsExecutingOrPausedSyncInfo?.length === 0 ? (
          <Translation id='beforeLoggingOut' />
        ) : (
          <Translation id='executionMassiveInterruption' />
        )
      }
    >
      {areAllExecutionsSynced &&
        executionsExecutingOrPausedSyncInfo?.length === 0 &&
        !loading && <AllSynced />}

      {!areAllExecutionsSynced &&
        executionsExecutingOrPausedSyncInfo?.length === 0 &&
        !loading && <SyncNeeded />}

      {(executionsExecutingOrPausedSyncInfo?.length > 0 || loading) && (
        <MassiveInterruption
          executions={executionsExecutingOrPausedSyncInfo}
          showWarning={!areAllExecutionsSynced}
          textId='executionsNeedsInterrupt'
          warningTextId='executionsOutOfSyncNotLogout'
        />
      )}
    </Modal>
  )
}

export default LogoutModal
