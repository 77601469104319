import { createSelector } from '@reduxjs/toolkit'

export const getExecutionManeuvers = createSelector(
  (state) => {
    return state?.executionManeuvers
  },
  (executionManeuvers) => {
    return executionManeuvers.maneuvers
  }
)
