import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData } from '../../common/write'

export async function writeExecutionComponents(components) {
  return _writeLocalDbData({
    tableName: TABLE_NAMES.EXECUTION_COMPONENTS,
    data: components,
    action: 'PUT'
  })
}
