import { useMemo } from 'react'

import { useIsOffline } from '../../../../../hooks/use-is-offline'

export function useIsButtonDisabled({ disabled }) {
  const noConnection = useIsOffline()

  const isButtonDisabled = useMemo(
    () => noConnection || disabled,
    [noConnection, disabled]
  )

  return {
    isButtonDisabled,
    noConnection
  }
}
