import React from 'react'

const UploadFile = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='atom/icon/upload file'>
      <g id='Group'>
        <path
          id='Path'
          d='M12.001 15.75V3.75'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Path_2'
          d='M7.50101 8.25V8.25L12.001 3.75'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Path Copy'
          d='M16.5 8.25V8.25L12 3.75'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <path
        id='Path_3'
        d='M23.251 15.75V17.25C23.251 18.9069 21.9079 20.25 20.251 20.25H3.75101C2.09415 20.25 0.751007 18.9069 0.751007 17.25V15.75'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
)

export default UploadFile
