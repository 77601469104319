import { getTranslation } from '../../../i18n/getTranslation'
import { getFinalReport } from '../../../services/execution/report/http/get'
import { downloadFile } from '../../../utils/helpers/files'
import { notificationMessage } from '../../../utils/helpers/notification-message'

export async function downloadFinalReport(execution) {
  try {
    const result = await getFinalReport(execution.id)

    const fileName = `${execution.procedure?.key}_${execution.procedure?.rev}_${execution?.id}.pdf`
    const mimeType = 'application/pdf'

    return downloadFile(result.data, mimeType, fileName)
  } catch (error) {
    notificationMessage({
      type: 'error',
      message: getTranslation('downloadReport'),
      description: getTranslation('downloadFailed')
    })
  }
}
