import { useCallback, useState } from 'react'

export const useFilters = (defaultFilters = {}) => {
  // Lo metemos a un useState para que ignore los cambios en los props
  const [defFilters] = useState(defaultFilters)
  const [filters, setFilters] = useState(defFilters)

  const updateFilters = useCallback((updatedFilters) => {
    setFilters((currentFilters) => {
      const newFilters = { ...currentFilters, ...updatedFilters }
      return newFilters
    })
  }, [])

  const resetFilters = useCallback(() => {
    setFilters((currentFilters) => {
      return Object.keys(currentFilters).reduce((acc, key) => {
        acc[key] = defFilters[key] || null
        return acc
      }, {})
    })
  }, [defFilters])

  return { filters, defaultFilters: defFilters, updateFilters, resetFilters }
}
