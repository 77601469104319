import { Spin } from 'antd'
import queryString from 'query-string'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'



import { downloadProcedure } from '../../../../application/reports/download'
import { useGlobalProcedures } from '../../../../hooks/data/useGlobalProcedures'
import { useLocalProcedures } from '../../../../hooks/data/useLocalProcedures'
import useMediaUploadingModal from '../../../../hooks/use-media-uploading-modal'
import { getIsReview } from '../../../../store/execution/selectors'
import {
  getConnectionStatus,
  getDownloadingProcedureIds,
  getIsWorking
} from '../../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../../utils/constants/connection'
import { PROCEDURE_STATUS } from '../../../../utils/constants/procedure'
import { notificationMessage } from '../../../../utils/helpers/notification-message'
import MediaUploadingModal from '../../../modal/media-uploading-modal'
import ConfirmPopover from '../../../popover/confirm'
import Translation from '../../../translations'

const ProcedureReference = ({ component, disabled }) => {
  const [visible, setVisible] = useState(false)

  const connectionStatus = useSelector(getConnectionStatus)
  const isReview = useSelector(getIsReview)
  const isWorking = useSelector(getIsWorking)
  const downloadingProcedureIds = useSelector(getDownloadingProcedureIds)
  const history = useHistory()
  const online = connectionStatus === CONNECTION_STATUS.ONLINE
  const procedureKey = component.options.toProcedureKey

  const [open, setOpen, shouldOpenMediaUploadingModal] =
    useMediaUploadingModal()

  const [localProcedures, loadingLocalProcedures] = useLocalProcedures({
    disabled: !visible,
    key: procedureKey,
    status: PROCEDURE_STATUS.PUBLISHED
  })
  const localProcedure = localProcedures?.[0]
  const isDownloaded = !!localProcedure

  const {
    data: { items: globalProcedures } = {},
    isValidating: loadingGlobalProcedures
  } = useGlobalProcedures({
    disabled: !visible,
    select: ['id', 'key'],
    key: procedureKey,
    status: PROCEDURE_STATUS.PUBLISHED
  })
  const globalProcedure = globalProcedures?.[0]

  const navigateToProcedure = () => {
    if (isWorking) return

    if (isDownloaded) {
      history.push({
        pathname: '/procedures/' + localProcedure.id,
        search: queryString.stringify({
          p: component.options.toParagraphPosition
        })
      })
    } else if (online) {
      if (!globalProcedure) {
        notificationMessage({
          type: 'error',
          message: (
            <Translation
              id='referencedProcedureNotFound'
              params={{ key: procedureKey }}
            />
          )
        })
        return
      }
      downloadProcedure(globalProcedure.id)
    } else {
      notificationMessage({
        type: 'warning',
        message: <Translation id='noConnectionTitle' />
      })
    }
  }

  const onConfirm = () => {
    if (shouldOpenMediaUploadingModal()) {
      setVisible(false)
      return
    }

    navigateToProcedure()
  }

  return (
    <>
      <ConfirmPopover
        confirmButtonProps={{
          loading:
            online &&
            globalProcedure &&
            downloadingProcedureIds.includes(globalProcedure.id),
          color: isDownloaded ? '#29BC4F' : '#4B98C6'
        }}
        confirmButtonText={
          isDownloaded ? (
            <Translation id='jump' />
          ) : (
            <Translation id='download' />
          )
        }
        onConfirm={onConfirm}
        onCancel={() => setVisible(false)}
        visible={visible}
        onVisibleChange={setVisible}
        content={
          loadingLocalProcedures || loadingGlobalProcedures ? (
            <Spin />
          ) : undefined
        }
      >
        <a
          style={{ color: disabled || isWorking ? '#4B98C6' : '#A709BC' }}
          disabled={isReview || disabled || isWorking}
          onClick={() => setVisible(true)}
        >
          {component.options.text}
        </a>
      </ConfirmPopover>
      <MediaUploadingModal
        open={open}
        setOpen={setOpen}
        navigate={navigateToProcedure}
      />
    </>
  )
}

export default ProcedureReference
