import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

export const deleteExecutionComponents = async (executionIds) => {
  const db = getDatabase()

  return db[TABLE_NAMES.EXECUTION_COMPONENTS]
    .where('executionId')
    .anyOf(executionIds)
    .delete()
}
