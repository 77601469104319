export class PlantNameConflict extends Error {
  constructor(message) {
    super(message)
    this.name = 'PlantNameConflict'
    this.descriptionId = 'plant.conflict.name.error'
    this.key = 'PLANT_NAME_CONFLICT'
  }
}

export class PlantHasNoName extends Error {
  constructor(message) {
    super(message)
    this.name = 'PlantHasNoName'
    this.descriptionId = 'plant.no.name.error'
    this.key = 'PLANT_HAS_NO_NAME'
  }
}
