import moment from 'moment'

import { getTranslation } from '../../../../i18n/getTranslation'

export function getDate(date, dateFormat) {
  if (!date) return getTranslation('notYetFinished')

  return moment(date).format(dateFormat)
}

export function getProcedureInfo(procedure) {
  return `${procedure.serie.name} ${procedure.unit} / ${procedure.key}_REV${procedure.rev} / 
    ${procedure.title} `
}

export function getExecutionGoal(execution) {
  if (!execution || (!execution.executionGoal && !execution.otherExecutionGoal))
    return getTranslation('noComments')

  return execution.executionGoal
    ? execution.executionGoal
    : execution.otherExecutionGoal
}

export function getEmptyInfo(title) {
  let noCommentsText = ''
  switch (title) {
    case 'done':
      noCommentsText = getTranslation('noCommentsFinishing')
      break
    case 'reviewed':
      noCommentsText = getTranslation('noCommentsReviewing')
      break
    case 'approved':
      noCommentsText = getTranslation('noCommentsApproving')
      break
  }

  return `<div class="Title">${getTranslation(title)}</div>
              <div class="Info">${getTranslation('signature')}</div>
              <div class="Info">${getTranslation('pending')}</div>
              <div class="Title">${getTranslation('comments')}</div>
              <div class="Info">${noCommentsText}</div>
              `
}

export function getFinished(execution) {
  if (!execution || !execution.finishedAt) return getEmptyInfo('done')

  let name = execution.finishedBy ? execution.finishedBy.name : ''

  return `<div class="Title">${getTranslation('done')}</div>
              <div class="Info">${name}</div>
              <div class="Info">${getDate(
                execution.finishedAt,
                getTranslation('dateTimeFormatShort')
              )}</div>
              <br>
              <div class="Title">${getTranslation('comments')}</div>
              <pre class="Comments">${
                execution.statusComment
                  ? execution.statusComment
                  : getTranslation('noCommentsFinishing')
              }
              </pre>`
}
export function getReviewed(execution) {
  if (!execution || !execution.revisedAt) return getEmptyInfo('reviewed')

  return `<div class="Title">${getTranslation('reviewed')}</div>
              <div class="Info">${execution.revisedBy?.name}</div>
              <div class="Info">${getDate(
                execution.revisedAt,
                getTranslation('dateTimeFormatShort')
              )}</div>
              <br>
              <div class="Title">${getTranslation('comments')}</div>
              <pre class="Comments">${
                execution.revisionComment
                  ? execution.revisionComment
                  : getTranslation('noCommentsReviewing')
              }
              </pre>`
}

export function getApproved(execution) {
  if (!execution || !execution.approvedAt) {
    return getEmptyInfo('approved')
  }

  return `<div class="Title">${getTranslation('approved')}</div>
              <div class="Info">${execution.approvedBy?.name}</div>
              <div class="Info">${getDate(
                execution.approvedAt,
                getTranslation('dateTimeFormatShort')
              )}</div>
              <br>
              <div class="Title">${getTranslation('comments')}</div>
              <pre class="Comments">${
                execution.approvalComment
                  ? execution.approvalComment
                  : getTranslation('noCommentsApproving')
              }
            </pre>`
}

export function getMergedSection(execution) {
  if (!execution || !execution.merge || !execution.merge.comment) {
    return ''
  }

  const [
    executionCode1,
    executionCreateAt1,
    executionCode2,
    executionCreateAt2
  ] = execution.merge.comment.split('|')

  return `<div class="Title">${getTranslation('mergedFrom')}</div>
                    <div class="Info">
                    ${executionCode1}-
                    ${moment(executionCreateAt1).format(
                      getTranslation('executionCodeDateTimeFormat')
                    )}
                    </div>
                    <div class="Info">
                      ${executionCode2}-
                      ${moment(executionCreateAt2).format(
                        getTranslation('executionCodeDateTimeFormat')
                      )}
                    </div>
                    <div class="SectionSeparator"></div>
                `
}

export function getInternalCode(procedure, execution) {
  let internalCode = `${procedure.key}_REV${procedure.rev}-${getDate(
    execution?.createdAt,
    getTranslation('executionCodeDateTimeFormat')
  )}`
  return internalCode
}
