import React from 'react'

import { IconContainer } from '../../../containers/icon'

const Conversation = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/comments'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path
        d='M11.25,18.75 C10.422,18.75 9.75,18.078 9.75,17.25 L9.75,9.75 C9.75,8.922 10.422,8.25 11.25,8.25 L21.75,8.25 C22.578,8.25 23.25,8.922 23.25,9.75 L23.25,17.25 C23.25,18.078 22.578,18.75 21.75,18.75 L20.25,18.75 L20.25,23.25 L15.75,18.75 L11.25,18.75 Z'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M6.75,12.75 L3.75,15.75 L3.75,11.25 L2.25,11.25 C1.422,11.25 0.75,10.578 0.75,9.75 L0.75,2.25 C0.75,1.422 1.422,0.75 2.25,0.75 L12.75,0.75 C13.578,0.75 14.25,1.422 14.25,2.25 L14.25,5.25'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
    </g>
  </svg>
)

export default Conversation
