import { readLocalUnitByName } from '../../../localdb/units/read'
import { UnitHasNoName, UnitNameConflict } from '../../../utils/errors/unit'

export async function checkIfUnitExists(unit) {
  const unitsWithSameName = await readLocalUnitByName(unit.name)
  const unitsWithSameNameInSamePlant = unitsWithSameName.filter(
    (unitSame) =>
      unitSame.plant?.id === unit.plant?.id && unitSame.id !== unit.id
  )

  if (unitsWithSameNameInSamePlant?.length > 0) {
    throw new UnitNameConflict()
  }
}

export async function validateUnit(unit) {
  if (!unit.name?.trim()) {
    throw new UnitHasNoName()
  }

  return checkIfUnitExists(unit)
}
