import CryptoJS from 'crypto-js'
import aes from 'crypto-js/aes'

export function encrypt(key, data) {
  return aes.encrypt(JSON.stringify(data), key).toString()
}

export function decrypt(key, data) {
  return JSON.parse(aes.decrypt(data, key).toString(CryptoJS.enc.Utf8))
}

export function encryptSHA256(data) {
  return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
}