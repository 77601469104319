import { createSelector } from '@reduxjs/toolkit'

export const getExecutionSteps = createSelector(
  (state) => {
    return state?.executionSteps
  },
  (executionSteps) => {
    return executionSteps.steps
  }
)

export const getExecutionStep = createSelector(
  [(state) => state.executionSteps, (_state, id) => id],
  ({ steps }, id) => steps?.find((es) => es.stepId === id)
)
