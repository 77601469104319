import { getHTML, prepareContent } from './prepare-html'
import { removeMsoComments } from './remove'

export async function prepareHTML(content, execution, procedure) {
  await prepareContent(execution, content)

  let doc = await getHTML(content, procedure, execution)

  doc = removeMsoComments(doc)

  return doc
}

export function createHtmlOfWarnings(template, warningsText) {
  var htmlDoc = new DOMParser().parseFromString(template, 'text/html')

  const [bodyHtml] = htmlDoc.getElementsByTagName('body')
  bodyHtml.innerHTML = ''

  let wrapperSections = document.createElement('div')
  wrapperSections.id = 'wrapperSections'
  wrapperSections.innerHTML = warningsText

  bodyHtml.appendChild(wrapperSections)

  return htmlDoc.firstElementChild.outerHTML
}
