import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import ArrowDown from '../../components/icons/arrow-down'
import ArrowUp from '../../components/icons/arrow-up'
import { getConnectionStatus } from '../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../utils/constants/connection'
import MediaUploading from '../media-uploading'
import Translation from '../translations'

const MediaUploadFinalNotification = () => {
  const [showMedia, setShowMedia] = useState(false)
  const connectionStatus = useSelector(getConnectionStatus)

  const offline = connectionStatus !== CONNECTION_STATUS.ONLINE

  return (
    <>
      {offline ? (
        <section>
          <OfflineText>
            <Translation id='media.upload.offline.text' />
          </OfflineText>
        </section>
      ) : null}
      <Container
        className='upload-video-notification-final'
        onClick={() => setShowMedia(!showMedia)}
      >
        <section>
          <Text>
            <Translation id='show.details' />
          </Text>
        </section>
        <section>
          {showMedia ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
        </section>
      </Container>
      <MediaUploading
        showMedia={showMedia}
        onClickEyeAction={() => setShowMedia(false)}
        isNotification
      />
    </>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid #b8bab6;

  padding: 10px 0;
`

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
`

const OfflineText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
`

export default MediaUploadFinalNotification
