import { EditOutlined } from '@ant-design/icons'
import { Form, Input, Modal, Button } from 'antd'
import React from 'react'

import CheckboxNoPointer from '../../../components/checkbox/no-pointer'
import SelectMultipleCheckbox from '../../../components/select/multiple-checkbox'
import EditModalButtonContainer from '../../../containers/edit-modal-button'
import useSeries from '../../../hooks/use-series'
import { useTranslation } from '../../../hooks/useTranslation'
import Translation from '../../translations'

import useForm from './hooks/use-form'


const PutDepartmentModal = ({
  open,
  closeModal,
  onConfirm,
  actionTranslation,
  elementToEdit
}) => {
  const { data: series } = useSeries()
  const [form] = Form.useForm()

  const namePlaceholder = useTranslation('name')

  const {
    selectedSeries,
    setSelectedSeries,
    handleConfirmForm,
    onFilterSelectOption
  } = useForm({
    form,
    elementToEdit,
    open,
    onConfirm,
    series
  })

  return (
    <Modal
      open={open}
      title={<Translation id='actionTranslation' />}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
      closable
    >
      <Form layout='vertical' form={form} onFinish={handleConfirmForm}>
        <Form.Item
          name='name'
          label={<Translation id='name' />}
          required
          rules={[
            {
              required: true,
              message: <Translation id='fieldRequired' />
            }
          ]}
        >
          <Input placeholder={namePlaceholder} />
        </Form.Item>
        <Form.Item label={<Translation id='config.series.title' />}>
          <SelectMultipleCheckbox
            showSearch
            setSelected={setSelectedSeries}
            options={series?.map(({ name, id }, index) => ({
              label: (
                <CheckboxNoPointer
                  checked={selectedSeries.includes(id)}
                  key={index}
                >
                  {name}
                </CheckboxNoPointer>
              ),
              value: id
            }))}
            filterOption={onFilterSelectOption}
            value={selectedSeries}
          />
        </Form.Item>

        <EditModalButtonContainer>
          <Button key='submit' type='primary' htmlType='submit'>
            <EditOutlined />
            <Translation id={actionTranslation} />
          </Button>
        </EditModalButtonContainer>
      </Form>
    </Modal>
  )
}

export default PutDepartmentModal
