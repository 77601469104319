import { Button as AntButton, Modal as AntModal, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'


import { useTranslation } from '../../../hooks/useTranslation'
import { putSendExecution } from '../../../services/execution/http/put'
import { deleteExecutions } from '../../../store/execution/localDb'
import { setMode } from '../../../store/execution/slice'
import {
  EXECUTION_QA_ACTION,
  EXECUTION_QA_STATUS
} from '../../../utils/constants/execution'
import {
  REJECT_ACTION_BASED_ON_STATUS,
  REJECT_COMMENT_TYPE
} from '../../../utils/constants/execution/reject-action'
import { erro } from '../../../utils/logger'
import Translation from '../../translations'

const RejectModal = ({ visible, setVisible, execution = {} }) => {
  const history = useHistory()
  const [reason, setReason] = useState('')
  const dispatch = useDispatch()

  const rejectReasonTranslation = useTranslation('rejectModal.rejectReason')

  const action =
    REJECT_ACTION_BASED_ON_STATUS[execution?.qaStatus || execution?.status]

  useEffect(() => {
    if (execution) {
      setReason(execution[REJECT_COMMENT_TYPE[action]])
    }
  }, [execution, action])

  const onReject = async () => {
    let updateData = {
      ...execution,
      qaStatus: EXECUTION_QA_STATUS.REJECTED,
      [REJECT_COMMENT_TYPE[action]]: reason,
      currentStep: null
    }

    if (action === EXECUTION_QA_ACTION.APPROVE) {
      updateData.revision = null
    }

    await putSendExecution([updateData])

    if (
      action === EXECUTION_QA_ACTION.APPROVE ||
      action === EXECUTION_QA_ACTION.REVIEW
    ) {
      await deleteExecutions([execution.id]).catch((error) => {
        erro(`Error deleting executions: ${error}`)
      })
    }

    //quito el modo review/approve porque vuelvo al menú principal
    dispatch(setMode(null))

    setVisible(false)
    history.push({
      pathname: `/dashboard/admin-executions`
    })
  }

  return (
    <Modal open={visible} closable={false} footer={null} centered>
      <ReasonContent>
        <Translation id='rejectModal.rejectMessage' />
      </ReasonContent>
      <Form
        name='reject'
        layout='vertical'
        initialValues={{ reason }}
        onFinish={onReject}
      >
        <Form.Item
          name='reason'
          rules={[
            {
              required: true,
              whitespace: true,
              message: <Translation id='fieldRequired' />
            }
          ]}
        >
          <TextArea
            placeholder={rejectReasonTranslation}
            autoSize={{ minRows: 7, maxRows: 10 }}
            value={reason}
            onChange={({ target: { value } }) => setReason(value)}
          />
        </Form.Item>
        <ButtonsContainer>
          <Button background='#000' onClick={() => setVisible(false)}>
            <Translation id='cancel' />
          </Button>
          <Button color='#F12E2E' background='#fff' htmlType='submit'>
            <Translation id='reject' />
          </Button>
        </ButtonsContainer>
      </Form>
    </Modal>
  )
}

const Modal = styled(AntModal)`
  background: #efeeeb;
  border-radius: 4px;
  padding: 0;
  > .ant-modal-content {
    background: #efeeeb;
    border-radius: 4px;
    box-shadow: none;
  }
  > .ant-modal-content .ant-modal-body {
    padding: 24px 32px;
  }
`

const ReasonContent = styled.p`
  font-size: 16px;
  margin-bottom: 9px;
`

const TextArea = styled(Input.TextArea)`
  background-color: #fff;
  border-radius: 0;
  margin-top: 15px;
  &:hover,
  &:focus {
    background-color: #fff;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Button = styled(AntButton)`
  border: 1px solid #b8bab6;
  color: ${({ color }) => color || '#fff'};
  background-color: ${({ background }) => background || 'transparent'};
  width: 175px;
  text-align: center;
  margin-right: 8px;
  border-radius: 2px;
  &:last-child {
    margin-right: 0;
  }
`

export default RejectModal
