import { Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Character = ({ value, onChangeValue, error, ...props }) => (
  <CodeCharacter
    color={error ? '#F10808' : 'black'}
    maxLength={1}
    size='small'
    value={value}
    autoComplete='false'
    onChange={({ target: { value } }) => onChangeValue(value)}
    {...props}
  />
)

const CodeCharacter = styled(Input)`
  display: flex;
  flex: 1;
  font-size: 32px;
  margin: 5px;
  color: ${(props) => props.color};
  border: 2px solid ${(props) => props.color};
  border-radius: 0px;
  text-align: center;
  text-transform: uppercase;

  &:focus,
  &:hover {
    outline: none;
    box-shadow: none;
    border: 2px solid ${(props) => props.color};
    border-color: ${({ color }) => `${color};`};
    border-right-width: 2px !important;
  }
`

export default Character
