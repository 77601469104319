import React from 'react'

import CloseIcon from '../../../../components/icons/close-icon'
import HeaderButton from '../../../buttons/header-button'

import ItemsMenu from './item'

const CommonExecutionViewDrawer = ({
  id,
  disabled,
  visible,
  setVisible,
  icon,
  onBack,
  title,
  color,
  children,
  itemAppend,
  badgeCount,
  placement,
  className
}) => {
  const badge = visible
    ? null
    : {
        count: badgeCount
      }

  return (
    <div>
      <HeaderButton
        id={`${id ? id : className}-header-button`}
        disabled={disabled}
        color={badge && badge.count > 0 ? color : null}
        onClick={() => setVisible(!visible)}
        badge={badge}
        style={{ borderRadius: '0', height: '50px', width: '50px' }}
      >
        {visible ? <CloseIcon disabled={disabled} /> : icon}
      </HeaderButton>
      <ItemsMenu
        id={id ? id : className}
        icon={icon}
        onBack={onBack}
        visible={visible}
        disabled={disabled}
        setVisible={setVisible}
        color={color}
        title={title}
        itemAppend={itemAppend}
        badgeCount={badgeCount}
        placement={placement}
        className={className}
      >
        {children}
      </ItemsMenu>
    </div>
  )
}

export default CommonExecutionViewDrawer
