import React from 'react'

const Play = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 17 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width={size} height={size} transform='translate(0 0.5)' fill='none' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.89197 2.67226V14.3323C2.89197 14.8843 3.3393 15.3323 3.89197 15.3323C4.05663 15.3323 4.2193 15.2916 4.36463 15.2136L15.2466 9.3836C15.7333 9.12293 15.9166 8.51626 15.6553 8.0296C15.562 7.85626 15.42 7.7136 15.2466 7.62093L4.36463 1.79093C3.87797 1.5296 3.27197 1.71293 3.01063 2.1996C2.93263 2.34493 2.89197 2.5076 2.89197 2.67226Z'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Play
