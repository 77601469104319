import styled from 'styled-components'

const ConfigurationTabContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
`

export default ConfigurationTabContainer
