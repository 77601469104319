import { TimePicker } from 'antd'
import moment from 'moment'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import SelectPickerContainer from '../../../../containers/select-picker'
import useHistoricalComponent from '../../../../hooks/use-historical-component'
import { useTranslation } from '../../../../hooks/useTranslation'
import { colorDisabled, colorEnabled } from '../../../../utils/constants/styles'
import { getWidthInPixels } from '../../../../utils/helpers/styles' 

const TimeInput = ({
  onComponentChange,
  component,
  executionComponent,
  disabled,
  className,
  isHistoricalMode
}) => {
  const { options = {}, procedureComponentId } = component
  const { size } = options
  const userTimeFormat = useTranslation('timeFormat')
  const selectTimeTranslation = useTranslation('selectTime')

  const widthInPixels = useMemo(() => getWidthInPixels(size, 15), [size])
  const {
    historicalModeEnabled,
    historicalComponentActive,
    onClickHistorical
  } = useHistoricalComponent({
    isHistoricalMode,
    showHistorical: options?.showHistorical,
    component
  })

  return (
    <SelectPickerContainer
      data-active={historicalComponentActive}
      className={
        historicalModeEnabled
          ? `historical-component-date-time`
          : `recorder-date-time`
      }
      data-input-size={size ? widthInPixels : '130px'}
      onClick={historicalModeEnabled ? onClickHistorical : null}
    >
      <StyledTimePicker
        placeholder={selectTimeTranslation}
        onChange={(value) =>
          onComponentChange({
            id: procedureComponentId,
            value: moment(value).valueOf()
          })
        }
        format={userTimeFormat}
        value={executionComponent?.value && moment(executionComponent?.value)}
        disabled={disabled}
        suffixIcon={null}
        inputReadOnly={true}
        data-size={size ? widthInPixels : '130px'}
        className={className}
        getPopupContainer={(trigger) => trigger.parentElement}
      />
    </SelectPickerContainer>
  )
}

const StyledTimePicker = styled(TimePicker)`
  width: ${(props) => props['data-size']};
  height: 40px;
  border-radius: 0px;

  .ant-picker-clear {
    background-color: #efefef;
  }
  .ant-picker-input > input[disabled] {
    ${({ disabled }) =>
      disabled
        ? `color:` + colorDisabled + `!important;`
        : `color:` + colorEnabled + `!important;`}
  }
`

export default TimeInput
