import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import DeleteIcon from '../../components/icons/delete-icon'
import EditIcon from '../../components/icons/edit-icon'
import Translation from '../translations'

const ListItem = ({ item, openEditModal, deleteListItem }) => {
  const [isDeleteOpen, setDeletePopover] = useState(false)

  const handleDeleteItem = async () => {
    await deleteListItem(item)
    setDeletePopover(false)
  }

  const DeletePopoverContent = (
    <DeletePopover>
      <div>
        <ExclamationCircleFilled />{' '}
        <Translation id={'configurationPanel.alert.deleteEntry'} />
      </div>
      <div>
        <Button
          style={{ marginRight: '8px' }}
          type='secondary'
          onClick={() => {
            setDeletePopover(false)
          }}
        >
          <Translation id='no' />
        </Button>
        <Button type='primary' onClick={handleDeleteItem}>
          <Translation id='yes' />
        </Button>
      </div>
    </DeletePopover>
  )

  return (
    <ItemContainer>
      <span style={{ lineHeight: '30px' }}>{item.name}</span>
      <div>
        <Popover
          open={isDeleteOpen}
          placement='topRight'
          content={DeletePopoverContent}
        >
          <ItemActionButton
            icon={<EditIcon size={14} />}
            onClick={() => {
              openEditModal(item)
            }}
          />{' '}
          <ItemActionButton
            icon={<DeleteIcon size={14} />}
            onClick={() => {
              setDeletePopover(true)
            }}
          />
        </Popover>{' '}
      </div>
    </ItemContainer>
  )
}

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding: 1em;
`
const DeletePopover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`

const ItemActionButton = styled(Button)`
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`

export default ListItem
