import { Popover } from 'antd'
import { memo, useEffect } from 'react'
import React, { useMemo } from 'react'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import WarningIcon from '../../../../components/icons/warning-icon'
import { getExecutionComponents } from '../../../../store/executionComponents/selectors'
import {
  calculateResult,
  getVariableValues
} from '../../../../utils/helpers/formulas'
import { getWidthInPixels } from '../../../../utils/helpers/styles'
import Translation from '../../../translations'

const Formula = memo(
  ({ component, executionComponent, procedure, onComponentChange }) => {
    const { options = {} } = component
    const {
      size,
      numberOfDecimals,
      variables: variableIdsMap,
      formula,
      validationFormula
    } = options
    const executionComponents = useSelector(getExecutionComponents)
    const onFormulaChangeRef = useRef()
    onFormulaChangeRef.current = onComponentChange

    const variableValuesMap = useMemo(
      () =>
        getVariableValues({
          variableIdsMap,
          executionComponents,
          procedureComponents: procedure.components
        }),
      [variableIdsMap, executionComponents, procedure.components]
    )

    const formulaResult = useMemo(
      () =>
        calculateResult({
          variableValuesMap,
          formula,
          numberOfDecimals
        }),
      [variableValuesMap, formula, numberOfDecimals]
    )

    const validationResult = useMemo(() => {
      if (validationFormula) {
        return calculateResult({
          variableValuesMap: {
            ...variableValuesMap,
            result: formulaResult
          },
          formula: validationFormula
        })
      }
    }, [formulaResult, validationFormula, variableValuesMap])

    const hasValue = formulaResult !== null && formulaResult !== undefined

    useEffect(() => {
      if (
        hasValue &&
        validationFormula &&
        !validationResult &&
        formulaResult !== executionComponent.value
      ) {
        onFormulaChangeRef.current({
          id: component.id,
          value: formulaResult,
          status: 'formula-not-valid'
        })
      }
    }, [
      hasValue,
      validationFormula,
      validationResult,
      component.id,
      executionComponent,
      formulaResult
    ])

    return (
      <InputDisabled data-size={size ? getWidthInPixels(size, 15) : '120px'}>
        {formulaResult != null ? formulaResult.toString() : '----'}
        {hasValue && validationFormula && !validationResult && (
          <Popover
            content={
              <Translation
                id={'validationNotPassed'}
                params={{ validationRule: validationFormula }}
              />
            }
          >
            <WarningIcon size={24} color='#ff0000' />
          </Popover>
        )}
      </InputDisabled>
    )
  }
)

const InputDisabled = styled.span`
  border-radius: 1px;
  width: ${(props) => props['data-size']};
  border: 2px;
  padding: 4px 11px;
  line-height: 22px;
  display: inline-flex;
  justify-content: space-between;
  background-color: #efefef;
  color: #5f5e5e;
  font-weight: bold;
`

export default Formula
