import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { executionChildrenSyncQuery } from '../../common/sync'

const tableName = TABLE_NAMES.EXECUTION_STEPS

export const executionStepSyncQuery = async () => {
  return executionChildrenSyncQuery(tableName)
}

export const readExecutionSteps = async (executionId) => {
  const db = getDatabase()

  const steps = await db[tableName].where({ executionId }).toArray()

  return steps
}
