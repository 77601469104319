import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'



import { useLocalExecutions } from '../../../hooks/data/useLocalExecutions'
import { useLocalProcedures } from '../../../hooks/data/useLocalProcedures'
import { useFilters } from '../../../hooks/useFilters'
import { PERMISSION_TYPES } from '../../../utils/constants/roles'
import { userHasPermission } from '../../../utils/helpers/permissions'
import BasePage from '../../../views/base-page'
import { GlobalDrawerButton } from '../../../views/global-drawer-button'
import BaseTable from '../../../views/tables/base-table'
import UnitTag from '../../../views/tags/unit'
import Translation from '../../../views/translations'
import { GlobalProceduresDrawer } from '../GlobalProceduresDrawer'

import { DeleteProcedureButton } from './DeleteProcedureButton'
import { Filters } from './Filters'

export const ProceduresFiltersContext = React.createContext()

const getColumns = (localExecutions) => {
  let columns = [
    {
      title: <Translation id='unit' />,
      dataIndex: 'unit',
      minWidth: 110,
      render: (text) => <UnitTag unit={text} />
    },
    {
      title: <Translation id='series' />,
      dataIndex: 'serieName',
      minWidth: 50,
      sorter: true
    },
    {
      title: <Translation id='key' />,
      dataIndex: 'key',
      minWidth: 90,
      defaultSortOrder: 'descend',
      sorter: true
    },
    {
      title: <Translation id='title' />,
      dataIndex: 'title',
      minWidth: 100,
      sorter: true
    },
    {
      title: <Translation id='rev' />,
      dataIndex: 'rev',
      width: 50,
      sorter: true
    },
    {
      title: <Translation id='status' />,
      dataIndex: 'status',
      width: 136,
      render: (text) => <Translation id={text} />
    },
    {
      title: <Translation id='status' />,
      dataIndex: 'status',
      render: (text) => <Translation id={text} />
    },
    {
      title: <Translation id='action' />,
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => {
        return (
          <DeleteProcedureButton
            procedure={record}
            localExecutions={localExecutions}
          />
        )
      },
      onCell: () => {
        return {
          onClick: (e) => e.stopPropagation()
        }
      }
    }
  ]

  if (userHasPermission(PERMISSION_TYPES.PROCEDURE_VERSION)) {
    columns.splice(5, 0, {
      title: <Translation id='buildNumberShort' />,
      dataIndex: 'buildNumber',
      sorter: true
    })
  }

  return columns
}

export const LocalProcedures = () => {
  const history = useHistory()
  const defaultSortColumn = getColumns().find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'key',
    order: defaultSortColumn?.defaultSortOrder || 'descend'
  })
  const [globalProcedureDrawerVisible, setGlobalProcedureDrawerVisible] =
    useState(false)
  const { filters, updateFilters, resetFilters } = useFilters()
  const [localExecutions] = useLocalExecutions()
  const [data, isLoading] = useLocalProcedures({
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    ...filters
  })

  const handleTableChange = (_pagination, _, sorter) => {
    setSorter({ ...sorter })
  }

  return (
    <BasePage
      title={<Translation id='proceduresOnDevice' />}
      extraHeaderContent={
        <GlobalDrawerButton
          drawerVisible={globalProcedureDrawerVisible}
          setDrawerVisible={setGlobalProcedureDrawerVisible}
          message={<Translation id='downloadProceduresTitle' />}
          id={'download-global-procedures-button'}
          hasPermission={userHasPermission(PERMISSION_TYPES.PROCEDURE_DOWNLOAD)}
        />
      }
      subHeaderContent={
        <>
          <ProceduresFiltersContext.Provider
            value={{ filters, updateFilters, resetFilters }}
          >
            <Filters />
          </ProceduresFiltersContext.Provider>
        </>
      }
      totalElements={data?.length}
    >
      <BaseTable
        id='local-procedures-table'
        loading={isLoading}
        onChange={handleTableChange}
        columns={getColumns(localExecutions)}
        dataSource={data}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push('/procedures/' + record.id)
            }
          }
        }}
      />
      <GlobalProceduresDrawer
        visible={globalProcedureDrawerVisible}
        setVisible={(value) => setGlobalProcedureDrawerVisible(value)}
      />
    </BasePage>
  )
}
