import React, { useState } from 'react'

import TextButton from '../../../components/buttons/text'
import UserAdd from '../../../components/icons/user-add'
import { getTranslation } from '../../../i18n/getTranslation'
import { createUser } from '../../../services/user/http/post'
import { alert } from '../../../utils/helpers/dialogs'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { erro } from '../../../utils/logger'
import Translation from '../../../views/translations'
import DisplayedPassword from '../../../views/users/password/displayed-password'

import { UserEditModal } from './UserEditModal'


export const AddUserButton = ({ reload, roles }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <TextButton icon={<UserAdd />} onClick={() => setModalVisible(true)}>
        <Translation id='addNewUser' />
      </TextButton>
      <UserEditModal
        title={<Translation id='addNewUser' />}
        okButtonText={<Translation id='addUser' />}
        visible={modalVisible}
        setVisible={setModalVisible}
        loading={loading}
        roles={roles}
        onFinish={(values) => {
          setLoading(true)
          createUser(values)
            .then((result) => {
              reload()
              setModalVisible(false)
              alert({
                title: getTranslation('userAddedTemporaryPassword'),
                children: (
                  <DisplayedPassword>{result.data.password}</DisplayedPassword>
                ),
                modalProps: {
                  okButtonText: getTranslation('close')
                }
              })
            })
            .catch((error) => {
              erro(error)
              if (error.response?.status === 409) {
                notificationMessage({
                  type: 'error',
                  message: getTranslation('emailTaken')
                })
              } else {
                notificationMessage({
                  type: 'error',
                  message: getTranslation('error'),
                  description: getTranslation('errorCreatingUser')
                })
              }
            })
            .finally(() => setLoading(false))
        }}
      />
    </>
  )
}
