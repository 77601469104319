import { setGlobal } from 'reactn'

import { renewToken as renewTokenRequest } from '../../../services/user/http/token/http/post'
import { store } from '../../../store'
import { getUser } from '../../../store/user-management/selectors'
import { setUser } from '../../../store/user-management/slice'

export async function renewToken() {
  const previousUser = getUser(store.getState())
  const {
    data: { token, user }
  } = await renewTokenRequest()

  setGlobal({ token, tokenGeneratedAt: new Date() })
  store.dispatch(setUser({ ...user, password: previousUser.password }))

  return token
}
