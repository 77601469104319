import React, { useState, useMemo, useEffect, useRef } from 'react'

import { useGlobalRoles } from '../../../hooks/data/useGlobalRoles'
import { useGlobalUsers } from '../../../hooks/data/useGlobalUsers'
import usePagination from '../../../hooks/metadata/use-pagination'
import { useFilters } from '../../../hooks/useFilters'
import BasePage from '../../../views/base-page'
import BaseTable from '../../../views/tables/base-table'
import Translation from '../../../views/translations'

import { AddUserButton } from './AddUserButton'
import { Filters } from './Filters'
import { UserEditButton } from './UserEditButton'


export const UsersFiltersContext = React.createContext()

function getColumns(reloadData, roles) {
  return [
    {
      title: <Translation id='name' />,
      dataIndex: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '30%'
    },
    {
      title: <Translation id='email' />,
      dataIndex: 'email',
      sorter: true,
      width: '40%'
    },
    {
      title: <Translation id='role' />,
      dataIndex: 'role',
      render: (role) => <span>{role.name}</span>,
      width: '30%'
    },
    {
      render: (_, user) => (
        <UserEditButton user={user} reloadData={reloadData} roles={roles} />
      ),
      width: '1%'
    }
  ]
}

export const UserManagement = () => {
  const defaultSortColumn = getColumns().find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'name',
    order: defaultSortColumn?.defaultSortOrder || 'ascend'
  })

  const [pagination, setPagination] = usePagination()
  const { filters, updateFilters, resetFilters } = useFilters()
  const roles = useGlobalRoles()

  const {
    data,
    isValidating,
    mutate: reload
  } = useGlobalUsers({
    skip: (pagination.current - 1) * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    ...filters
  })

  const { items, total } = data || {}

  const handleTableChange = (pagination, _filters, sorter) => {
    setPagination({ ...pagination, total })
    setSorter({ ...sorter })
  }

  const setPaginationRef = useRef(setPagination)

  useEffect(() => {
    setPaginationRef.current({ current: 1, pageSize: 10 })
  }, [filters])

  const columns = useMemo(() => getColumns(reload, roles), [reload, roles])

  return (
    <BasePage
      title={<Translation id='manageUsers' />}
      extraHeaderContent={<AddUserButton reload={reload} roles={roles} />}
      subHeaderContent={
        <UsersFiltersContext.Provider
          value={{ filters, updateFilters, resetFilters }}
        >
          <Filters roles={roles} />
        </UsersFiltersContext.Provider>
      }
      totalElements={total}
      topMenu
    >
      <BaseTable
        id='users-management-table'
        columns={columns}
        dataSource={items}
        pagination={{ ...pagination, total }}
        loading={isValidating}
        onChange={handleTableChange}
      />
    </BasePage>
  )
}
