import React from 'react'

const ProcedureManagementIcon = ({ size = '24', color = 'currentColor' }) => (
    <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_7115_77792)'>
      <path
        d='M17.25 18C18.0784 18 18.75 17.3285 18.75 16.5C18.75 15.6716 18.0784 15 17.25 15C16.4216 15 15.75 15.6716 15.75 16.5C15.75 17.3285 16.4216 18 17.25 18Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5241 10.6947L18.9661 12.1477C19.0391 12.3888 19.2009 12.5933 19.4188 12.7197C19.6368 12.8462 19.8946 12.8851 20.1401 12.8287L21.6121 12.4877C21.8911 12.4248 22.1829 12.4529 22.4448 12.5679C22.7067 12.6829 22.9248 12.8787 23.0674 13.1266C23.2099 13.3746 23.2693 13.6616 23.2369 13.9458C23.2044 14.2299 23.0819 14.4962 22.8871 14.7057L21.8561 15.8167C21.6841 16.0017 21.5884 16.245 21.5884 16.4977C21.5884 16.7504 21.6841 16.9937 21.8561 17.1787L22.8871 18.2867C23.0819 18.4961 23.2044 18.7624 23.2369 19.0466C23.2693 19.3307 23.2099 19.6178 23.0674 19.8658C22.9248 20.1137 22.7067 20.3095 22.4448 20.4245C22.1829 20.5395 21.8911 20.5676 21.6121 20.5047L20.1401 20.1667C19.8946 20.1103 19.6368 20.1492 19.4188 20.2756C19.2009 20.4021 19.0391 20.6065 18.9661 20.8477L18.5241 22.3007C18.442 22.5747 18.2737 22.815 18.0442 22.9858C17.8147 23.1566 17.5362 23.2488 17.2501 23.2488C16.964 23.2488 16.6856 23.1566 16.4561 22.9858C16.2266 22.815 16.0583 22.5747 15.9761 22.3007L15.5341 20.8477C15.4612 20.6065 15.2994 20.4021 15.0815 20.2756C14.8635 20.1492 14.6057 20.1103 14.3601 20.1667L12.8881 20.5077C12.6086 20.5715 12.3159 20.5439 12.0532 20.4289C11.7905 20.3139 11.5717 20.1176 11.4289 19.8689C11.2862 19.6202 11.227 19.3322 11.2602 19.0474C11.2933 18.7626 11.4171 18.4959 11.6131 18.2867L12.6441 17.1757C12.8162 16.9907 12.9118 16.7474 12.9118 16.4947C12.9118 16.242 12.8162 15.9987 12.6441 15.8137L11.6131 14.7027C11.4193 14.4931 11.2975 14.227 11.2655 13.9433C11.2336 13.6596 11.2931 13.3731 11.4355 13.1257C11.5779 12.8782 11.7956 12.6827 12.0569 12.5678C12.3183 12.4528 12.6095 12.4244 12.8881 12.4867L14.3601 12.8277C14.6057 12.8841 14.8635 12.8452 15.0815 12.7187C15.2994 12.5923 15.4612 12.3878 15.5341 12.1467L15.9761 10.6937C16.0584 10.4197 16.2268 10.1795 16.4564 10.0088C16.6859 9.83812 16.9644 9.74598 17.2505 9.74609C17.5366 9.74621 17.815 9.83856 18.0444 10.0094C18.2739 10.1803 18.4421 10.4206 18.5241 10.6947Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 10.5117H9.75'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 14.2617H7.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 18.0117H7.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.75 23.25H2.25C1.85218 23.25 1.47064 23.092 1.18934 22.8107C0.908035 22.5294 0.75 22.1478 0.75 21.75V6C0.75 5.60218 0.908035 5.22064 1.18934 4.93934C1.47064 4.65804 1.85218 4.5 2.25 4.5H6C6 3.50544 6.39509 2.55161 7.09835 1.84835C7.80161 1.14509 8.75544 0.75 9.75 0.75C10.7446 0.75 11.6984 1.14509 12.4017 1.84835C13.1049 2.55161 13.5 3.50544 13.5 4.5H17.25C17.6478 4.5 18.0294 4.65804 18.3107 4.93934C18.592 5.22064 18.75 5.60218 18.75 6V6.75'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.75 4.51172C9.54289 4.51172 9.375 4.34382 9.375 4.13672C9.375 3.92961 9.54289 3.76172 9.75 3.76172'
        stroke={color}
      />
      <path
        d='M9.75 4.51172C9.95711 4.51172 10.125 4.34382 10.125 4.13672C10.125 3.92961 9.95711 3.76172 9.75 3.76172'
        stroke={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_7115_77792'>
        <rect width={size} height={size} fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default ProcedureManagementIcon
