import React from 'react'

import { IconContainer } from '../../../containers/icon'

export const List = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/number-list'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path
        d='M3,0.748 C4.243,0.748 5.25,1.755 5.25,2.998 C5.25,4.241 4.243,5.248 3,5.248 C1.757,5.248 0.75,4.241 0.75,2.998 C0.75,1.755 1.757,0.748 3,0.748 Z'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M3,9.748 C4.243,9.748 5.25,10.755 5.25,11.998 C5.25,13.241 4.243,14.248 3,14.248 C1.757,14.248 0.75,13.241 0.75,11.998 C0.75,10.755 1.757,9.748 3,9.748 Z'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M3,18.748 C4.243,18.748 5.25,19.755 5.25,20.998 C5.25,22.241 4.243,23.248 3,23.248 C1.757,23.248 0.75,22.241 0.75,20.998 C0.75,19.755 1.757,18.748 3,18.748 Z'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <line
        x1='8.25'
        y1='3.748'
        x2='23.25'
        y2='3.748'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></line>
      <line
        x1='8.25'
        y1='12.748'
        x2='23.25'
        y2='12.748'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></line>
      <line
        x1='8.25'
        y1='21.748'
        x2='23.25'
        y2='21.748'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></line>
    </g>
  </svg>
)

export default List
