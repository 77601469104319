import { getDatabase } from '../..'
import { syncExecutions } from '../../../application/execution/sync'
import { syncExecutionComments } from '../../../application/execution-comments/sync'
import { syncExecutionComponents } from '../../../application/execution-components/sync'
import { syncExecutionConditionals } from '../../../application/execution-conditionals/sync'
import { syncExecutionManeuvers } from '../../../application/execution-maneuvers/sync'
import { syncExecutionSteps } from '../../../application/execution-steps/sync'
import { syncExecutionWarnings } from '../../../application/execution-warnings/sync'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { executionSyncQuery } from '../../execution/read'
import { executionCommentSyncQuery } from '../../execution-comment/read'
import { executionComponentSyncQuery } from '../../execution-component/read'
import { executionConditionalsSyncQuery } from '../../execution-conditionals/read'
import { executionManeuverSyncQuery } from '../../execution-maneuver/read'
import { executionStepSyncQuery } from '../../execution-step/read'
import { executionWarningSyncQuery } from '../../execution-warning/read'

export const EXECUTION_SYNC_CHILDREN = {
  [TABLE_NAMES.EXECUTIONS]: {
    syncFn: syncExecutions,
    syncQuery: executionSyncQuery
  },
  [TABLE_NAMES.EXECUTION_COMMENTS]: {
    syncFn: syncExecutionComments,
    syncQuery: executionCommentSyncQuery
  },
  [TABLE_NAMES.EXECUTION_COMPONENTS]: {
    syncFn: syncExecutionComponents,
    syncQuery: executionComponentSyncQuery
  },
  [TABLE_NAMES.EXECUTION_MANEUVERS]: {
    syncFn: syncExecutionManeuvers,
    syncQuery: executionManeuverSyncQuery
  },
  [TABLE_NAMES.EXECUTION_STEPS]: {
    syncFn: syncExecutionSteps,
    syncQuery: executionStepSyncQuery
  },
  [TABLE_NAMES.EXECUTION_WARNINGS]: {
    syncFn: syncExecutionWarnings,
    syncQuery: executionWarningSyncQuery
  },
  [TABLE_NAMES.EXECUTION_CONDITIONALS]: {
    syncFn: syncExecutionConditionals,
    syncQuery: executionConditionalsSyncQuery
  }
}

export const shouldSyncByDate = (queryResult) => {
  return (
    !queryResult.syncedAt ||
    queryResult.syncedAt < queryResult.updatedAt ||
    queryResult.syncedAt < queryResult.createdAt ||
    queryResult.syncedAt < queryResult.deletedAt
  )
}

export const shouldSyncByExecution = (execution) => {
  return execution && !shouldSyncByDate(execution)
}

export const executionChildrenSyncQuery = async (tableName) => {
  const db = getDatabase()

  const children = await db[tableName].with({ execution: 'executionId' })
  const result = children.filter(
    (_child) =>
      shouldSyncByDate(_child) && shouldSyncByExecution(_child.execution)
  )

  return result
}
