import React from 'react'

const ShrinkIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='shrink-icon'
  >
    <g clipPath='url(#clip0_8064_82825)'>
      <path
        d='M10.334 5.66927L15.6673 0.335938'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.333984 15.6693L5.66732 10.3359'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.6673 5.66927H10.334V2.33594'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.66732 13.6693V10.3359H2.33398'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.334 10.3359L15.6673 15.6693'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.333984 0.335938L5.66732 5.66927'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.334 13.6693V10.3359H13.6673'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.33398 5.66927H5.66732V2.33594'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8064_82825'>
        <rect width={size} height={size} fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default ShrinkIcon
