import React from 'react'

import AnnotationIcon from '../../../../../../components/icons/annotation-icon'
import StepMenuButton from '../../button'

const AnnotationButton = ({
  isAnnotationVisible,
  setIsAnnotationVisible,
  stepIsCurrent,
  isExecutionShared,
  isExecutionRunning,
  annotation
}) => {
  if (
    !stepIsCurrent ||
    isExecutionShared ||
    !isExecutionRunning ||
    annotation?.text
  ) {
    return null
  }

  return (
    <StepMenuButton
      id='annotations-button'
      onClick={() => {
        setIsAnnotationVisible(!isAnnotationVisible)
      }}
    >
      <AnnotationIcon />
    </StepMenuButton>
  )
}

export default AnnotationButton
