import { Button, Modal } from 'antd'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { store } from '../../../store'
import { getExecution } from '../../../store/execution/selectors'
import { enableConnection } from '../../../store/userInterface/actions'
import { getConnectionStatus } from '../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import Translation from '../../translations'

const buttonStatuses = {
  [CONNECTION_STATUS.DISABLED]: {
    textId: 'turn_on_sync',
    loading: false,
    onClick: () => store.dispatch(enableConnection())
  },
  [CONNECTION_STATUS.SYNCING]: {
    textId: 'trying_to_reconnect',
    loading: true
  },
  [CONNECTION_STATUS.CONNECTING]: {
    textId: 'trying_to_reconnect',
    loading: true
  },
  [CONNECTION_STATUS.OFFLINE]: {
    textId: 'trying_to_reconnect',
    loading: true
  },
  [CONNECTION_STATUS.ONLINE]: {
    textId: 'reconnect',
    loading: false
  }
}

const ConnectionModal = () => {
  const execution = useSelector(getExecution)
  const connectionStatus = useSelector(getConnectionStatus)
  const history = useHistory()

  const visible = useMemo(() => {
    return (
      connectionStatus !== CONNECTION_STATUS.ONLINE &&
      execution?.status === EXECUTION_STATUS.SHARED
    )
  }, [connectionStatus, execution?.status])

  return (
    <Modal
      open={visible}
      closable={false}
      maskClosable={false}
      title={<Translation id='noConnectionTitle' />}
      footer={[
        <Button
          key='exit'
          onClick={() => history.push('/dashboard/procedures')}
        >
          <Translation id='exit_execution' />
        </Button>,
        <Button
          key='reconnect'
          type='primary'
          loading={buttonStatuses[connectionStatus].loading}
          onClick={() => {
            buttonStatuses[connectionStatus].onClick?.()
          }}
        >
          <Translation id={buttonStatuses[connectionStatus].textId} />
        </Button>
      ]}
    >
      <Translation id='could_not_connect' />
    </Modal>
  )
}

export default ConnectionModal
