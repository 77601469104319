import React from 'react'
import { useDispatch } from 'react-redux'

import { deleteExecutions } from '../../../store/execution/actions'
import { clearExecutionMediaUploading } from '../../../store/media-uploading/slice'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import Translation from '../../../views/translations'
import { DeleteButton } from '../components/DeleteButton'

export const DeleteExecutionButton = ({ executionId }) => {
  const dispatch = useDispatch()
  return (
    <DeleteButton
      id='delete-execution-button'
      onClick={async () => {
        dispatch(clearExecutionMediaUploading(executionId))
        dispatch(deleteExecutions([executionId]))
        notificationMessage({
          message: <Translation id='deleteSuccess' />,
          description: <Translation id='deleteDescrExec' />
        })
      }}
      deleteQuestion={<Translation id='deleteExecutionQuestion' />}
    />
  )
}
