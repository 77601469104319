import React from 'react'

const Exit = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.4993 13.502V14.502C14.4993 15.054 14.0513 15.502 13.4993 15.502H10.4993'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.4993 3.50195H13.4993C14.0513 3.50195 14.4993 3.94995 14.4993 4.50195V5.50195'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.9286 16.4972L1.9286 15.6399C1.6826 15.6045 1.49927 15.3939 1.49927 15.1452V3.4832C1.49927 3.2472 1.66393 3.0432 1.8946 2.99387L7.8946 1.5132C8.1646 1.4552 8.4306 1.6272 8.48793 1.8972C8.49527 1.93187 8.49927 1.9672 8.49927 2.00254V16.0025C8.49927 16.2785 8.27527 16.5025 7.99927 16.5025C7.97527 16.5025 7.95193 16.5005 7.9286 16.4972Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.4993 7.50195L16.4993 9.50195H10.9993'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5 11.502L16.5 9.50195'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.24935 8.75264C6.11135 8.75264 5.99935 8.86464 5.99935 9.00264C5.99935 9.14064 6.11135 9.25264 6.24935 9.25264C6.38735 9.25264 6.49935 9.14064 6.49935 9.00264C6.49935 9.00197 6.49935 9.00064 6.49935 8.99997C6.50001 8.86197 6.38868 8.75064 6.25068 8.74997C6.25001 8.74997 6.25001 8.74997 6.24935 8.74997V8.74997'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Exit
