import { Tag } from 'antd'
import React from 'react'
import styled from 'styled-components'

import CheckboxChecked from '../../../components/icons/checkbox-checked'
import CheckboxUnchecked from '../../../components/icons/checkbox-unchecked'
import CloseIcon from '../../../components/icons/close-icon'
import DarkText from '../text/dark'
import LightText from '../text/light'

const FilterTag = styled(
  ({
    displayName,
    displayValue,
    checkable,
    checked,
    closable = !checkable,
    onClick,
    className
  }) => {
    const TagComponent = checkable ? Tag.CheckableTag : Tag
    const TextComponent = checkable && !checked ? DarkText : LightText
    return (
      <TagComponent
        onClose={onClick}
        onClick={onClick}
        color='#645377'
        className={className}
        checked={checked}
      >
        {checkable && (
          <>
            {checked ? (
              <CheckboxChecked size={11} color='#fff' />
            ) : (
              <CheckboxUnchecked size={11} color='#645377' />
            )}{' '}
          </>
        )}
        {displayName}: <TextComponent strong>{displayValue}</TextComponent>{' '}
        {closable && <CloseIcon color='#fff' size={10} />}
      </TagComponent>
    )
  }
)`
  border-radius: 2px !important;
  margin: 0;
  cursor: pointer;
`

export default FilterTag
