import { useEffect, useState } from 'react'

import { readLocalUnits } from '../../localdb/units/read'

export default function useExecutedFor() {
  const [executedForList, setExecutedForList] = useState([])

  const loadPlantsAndUnits = async () => {
    const localUnits = await readLocalUnits()

    const plantsAndUnitsToSave = localUnits.map((unit) => {
      return {
        id: unit.id,
        name: `${unit.plantName} ${unit.name}`,
        default: unit.default
      }
    })

    setExecutedForList(plantsAndUnitsToSave)
  }

  useEffect(() => {
    loadPlantsAndUnits()
  }, [])

  return [executedForList]
}
