import { createSelector } from '@reduxjs/toolkit'

export const getCurrentConflict = createSelector(
  (state) => {
    return state?.mergeExecution
  },
  (mergeExecution) => {
    return mergeExecution?.currentConflict
  }
)

export const getCurrentStepConflict = createSelector(
  (state) => {
    return state?.mergeExecution
  },
  (mergeExecution) => {
    return mergeExecution?.currentStepConflict
  }
)

export const getConflicts = createSelector(
  (state) => {
    return state?.mergeExecution
  },
  (mergeExecution) => {
    return mergeExecution?.conflicts
  }
)

export const getMergeExecution = createSelector(
  (state) => {
    return state?.mergeExecution
  },
  (mergeExecution) => {
    return mergeExecution?.merge
  }
)
