import styled from 'styled-components'

const StepHistory = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 48px !important;
  padding: 8px;
  border-radius: 30px;
  background-color: #efeeeb;
`

export default StepHistory
