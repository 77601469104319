export const DRAWERS = {
  EXECUTION_COMMENTS: 'execution-comments-drawer',
  EXECUTION_INFO: 'execution-info-drawer',
  SIGNATURE_IN_PROGRESS: 'signature-in-progress-drawer',
  SIGNATIRE_WAITING: 'signature-waiting-drawer',
  STEPS_COMMENTS: 'step-comments-drawer',
  PROCEDURE_RIGHT: 'procedure-right-drawer',
  PROCEDURE_LEFT: 'procedure-left-drawer',
  HISTORICAL_DRAWER: 'historical-drawer'
}
