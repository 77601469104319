import { UndoOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { downloadExecution } from '../../../../../application/execution'
import { downloadMergeExecution } from '../../../../../application/execution/merge'
import Approve from '../../../../../components/icons/approve'
import Copy from '../../../../../components/icons/copy'
import Eye from '../../../../../components/icons/eye'
import Review from '../../../../../components/icons/review'
import { getTranslation } from '../../../../../i18n/getTranslation'
import { putChangeStatusSourceMergeExecutions } from '../../../../../services/execution/merge/http/put'
import { putResumeExecution } from '../../../../../services/execution/resume/http/put'
import { getHasDelegatedSignaturesNotReviewed } from '../../../../../services/execution/signature-code/http/get'
import { validateSignaturesCodes } from '../../../../../services/execution/signature-code/http/post'
import { getUser } from '../../../../../store/user-management/selectors'
import {
  EXECUTION_QA_STATUS,
  EXECUTION_STATUS
} from '../../../../../utils/constants/execution'
import {
  PROCEDURE_PERMISSION_TYPE,
  PERMISSION_TYPES
} from '../../../../../utils/constants/roles'
import { handleDownloadExecutionError } from '../../../../../utils/errors'
import { confirm } from '../../../../../utils/helpers/dialogs'
import { notificationMessage } from '../../../../../utils/helpers/notification-message'
import {
  validationExactSelection,
  validationMultipleStatus,
  validationStatus,
  validationStatusMerge,
  validationUserHasPermission
} from '../../../../../utils/helpers/validations/table-footer'
import { erro } from '../../../../../utils/logger'
import InfoModal from '../../../../../views/modal/info-modal'
import TableFooter from '../../../../../views/tables/footer'
import Translation from '../../../../../views/translations'

const { REVIEWED, REJECTED } = EXECUTION_QA_STATUS
const { FINISHED, INTERRUPTED, ABORTED } = EXECUTION_STATUS

const validationSameProcedure = {
  check: ({ selection }) =>
    selection.every((item) => item.procedureId === selection[0].procedureId),
  message: () => <Translation id='validationSameProcedure' />
}

const validationNotReviewed = {
  check: ({ selection }) => selection.every((item) => !item.qaStatus),
  message: () => <Translation id='validationNotReviewed' />
}

const validationNotAlreadyReviewed = {
  check: ({ selection }) =>
    selection.every((e) => !e.qaStatus || e.qaStatus === REJECTED),
  message: () => <Translation id='validationNotAlreadyReviewed' />
}

const validationCanBeApproved = {
  check: ({ selection }) =>
    selection.every(
      (e) =>
        e.qaStatus === REVIEWED ||
        (e.simpleApprobation ?? e.procedure.simpleApprobation)
    ),
  message: () => <Translation id='validationCanBeApproved' />
}

const validationHasPermission = (permissionType) => ({
  check: ({ selection, user }) =>
    selection.every(
      (e) => e.procedure[permissionType] >= user.role.roleLevel.level
    ),
  message: () => <Translation id='validationHasPermission' />
})

const approve = async ({ selection, history, setOpenModal, setLoading }) => {
  const [{ id }] = selection

  setLoading(true)

  const { data: needRevision } = await getHasDelegatedSignaturesNotReviewed(id)

  if (needRevision) {
    setOpenModal(true)
    setLoading(false)
    return
  }

  await downloadExecution(id, false)
    .then(() => {
      setLoading(false)
      history.push({
        pathname: `/approve/${id}`
      })
    })
    .catch((error) => {
      setLoading(false)
      handleDownloadExecutionError(error)
    })
}

const review = async ({ selection, history, setLoading }) => {
  const [{ id }] = selection

  const result = await confirm({
    title: <Translation id='confirmRevisionQuestion' />
  })

  if (result) {
    try {
      setLoading(true)
      // TODO: mover a la vista de review
      await validateSignaturesCodes(id)
      await downloadExecution(id, false)

      setLoading(false)

      history.push({
        pathname: `/review/${id}`
      })
    } catch (error) {
      handleDownloadExecutionError(error)
    }
  }
}

const resume = async ({ selection, onChange, setLoading }) => {
  const [{ ...execution }] = selection

  setLoading(true)
  putResumeExecution(execution?.id)
    .then(() => {
      notificationMessage({
        message: getTranslation('successResume'),
        description: getTranslation('successResumeDescription')
      })
    })
    .catch((error) => {
      erro(error)
      notificationMessage({
        type: 'error',
        message: getTranslation('errorResume'),
        description: getTranslation('errorResumeDescription')
      })
    })
    .finally(() => {
      setLoading(false)
      onChange()
    })
}

const merge = async ({ selection, history }) => {
  const [{ id: id1 }, { id: id2 }] = selection

  try {
    const execution = await downloadMergeExecution(id1, id2)
    await putChangeStatusSourceMergeExecutions(id1, id2, false)

    history.push({
      pathname: `/merge/${execution.id}`
    })
  } catch (error) {
    handleDownloadExecutionError(error)
  }
}

const view = async ({ selection, history }) => {
  const [{ id }] = selection

  history.push({
    pathname: `/view/${id}`
  })
}

export const Footer = ({ selection, clearSelectedItems, onChange }) => {
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [loading, setLoading] = useState({
    approve: false,
    review: false,
    resume: false
  })
  const user = useSelector(getUser)
  const history = useHistory()

  const onLoading = (value, key) => {
    setLoading((prev) => ({ ...prev, [key]: value }))
  }

  const buttons = [
    {
      id: 'merge-button',
      translationId: 'merge',
      icon: <Copy />,
      onClick: merge,
      validations: [
        validationUserHasPermission(PERMISSION_TYPES.EXECUTION_MERGE),
        validationHasPermission(PROCEDURE_PERMISSION_TYPE.merge),
        validationExactSelection(2),
        validationSameProcedure,
        validationNotReviewed,
        validationStatusMerge([FINISHED, INTERRUPTED])
      ]
    },
    {
      id: 'review-button',
      translationId: 'review',
      icon: <Review />,
      loading: loading.review,
      onClick: (params) => {
        review({
          ...params,
          setLoading: (loading) => onLoading(loading, 'review')
        })
      },
      validations: [
        validationUserHasPermission(PERMISSION_TYPES.EXECUTION_REVIEW),
        validationHasPermission(PROCEDURE_PERMISSION_TYPE.revision),
        validationExactSelection(1),
        validationStatus(FINISHED),
        validationNotAlreadyReviewed
      ]
    },
    {
      id: 'approve-button',
      translationId: 'approve',
      icon: <Approve />,
      loading: loading.approve,
      onClick: (params) => {
        approve({
          ...params,
          setOpenModal: setOpenInfoModal,
          setLoading: (loading) => onLoading(loading, 'approve')
        })
      },
      validations: [
        validationUserHasPermission(PERMISSION_TYPES.EXECUTION_APPROVE),
        validationHasPermission(PROCEDURE_PERMISSION_TYPE.approve),
        validationExactSelection(1),
        validationStatus(FINISHED),
        validationCanBeApproved
      ]
    },
    {
      id: 'view-button',
      translationId: 'view',
      icon: <Eye />,
      onClick: view,
      validations: [
        validationUserHasPermission(PERMISSION_TYPES.EXECUTION_VIEW),
        validationExactSelection(1),
        validationStatus(FINISHED)
      ]
    },
    {
      id: 'resume-button',
      translationId: 'resumeExecution',
      icon: <UndoOutlined />,
      onClick: async (params) => {
        await resume({
          ...params,
          setLoading: (loading) => onLoading(loading, 'resume')
        })
        clearSelectedItems()
      },
      loading: loading.resume,
      validations: [
        validationUserHasPermission(PERMISSION_TYPES.EXECUTION_RESUME),
        validationExactSelection(1),
        validationMultipleStatus([FINISHED, ABORTED])
      ]
    }
  ]

  return (
    <>
      <TableFooter
        buttons={buttons}
        parameters={{
          selection,
          user,
          onChange,
          history
        }}
      />
      <InfoModal
        id='need-review'
        open={openInfoModal}
        setOpen={setOpenInfoModal}
        okButtonTextId='okText'
        titleId='execution.needs.review.title'
        textId='execution.needs.review.delegated.signatures.text'
      />
    </>
  )
}
