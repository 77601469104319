import { createSelector } from '@reduxjs/toolkit'

export const getComponent = createSelector(
  (state) => {
    return state?.historicalValues
  },
  (historicalValues) => {
    return historicalValues?.component
  }
)

export const getHistoricalCountStep = createSelector(
  (state) => {
    return state?.historicalValues
  },
  (historicalValues) => {
    return historicalValues?.historicalCountStep
  }
)
