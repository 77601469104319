import { Drawer, Table, Divider, Pagination } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import usePagination from '../../../hooks/use-pagination'
import useToggle from '../../../hooks/use-toggle'
import { getHistoricalCountStep } from '../../../store/historical-values/selectors'
import LocalizedMoment from '../../date/localized-moment'
import Translation from '../../translations'

import HistoricalDrawerHeaderBody from './body/header'
import HistoricalDrawerExtra from './extra'
import useHistoricalValues from './hooks/use-historical-values'
import HistoricalDrawerTitle from './title'



const HistoricalDrawer = () => {
  const historicalCount = useSelector(getHistoricalCountStep)

  const [expanded, toggleExpanded] = useToggle()
  const { historicalValues, loading, visible, componentName, closeDrawer } =
    useHistoricalValues()

  const {
    itemsToShow: historicalValuesToShow,
    total,
    pagination,
    onChangePage
  } = usePagination({
    items: historicalValues,
    paginationEnabled: expanded
  })

  return (
    <Drawer
      data-testid='historical-drawer'
      title={<HistoricalDrawerTitle historicalCount={historicalCount} />}
      placement='bottom'
      closable={false}
      open={visible}
      height={expanded ? '100%' : '408px'}
      destroyOnClose
      mask={false}
      maskClosable={false}
      extra={
        <HistoricalDrawerExtra
          expanded={expanded}
          closeDrawer={() => {
            closeDrawer()
            onChangePage(1)
          }}
          toggleExpanded={toggleExpanded}
        />
      }
      headerStyle={{
        background: '#fff'
      }}
      bodyStyle={{
        background: '#fff'
      }}
    >
      <HistoricalDrawerHeaderBody
        componentName={componentName}
        total={historicalValues?.length}
      />
      <Divider style={{ margin: 0 }} />
      <TableContainer data-expanded={expanded}>
        <Table
          loading={loading}
          pagination={false}
          columns={[
            {
              title: <Translation id='historical.table.value.column' />,
              dataIndex: 'value',
              key: 'value'
            },
            {
              title: <Translation id='historical.table.timestamp.column' />,
              dataIndex: 'timestamp',
              key: 'timestamp',
              render: (timestamp) => (
                <LocalizedMoment date={moment(timestamp)} />
              )
            },
            {
              title: <Translation id='historical.table.rev.column' />,
              dataIndex: 'rev',
              key: 'rev',
              render: (rev) => (
                <>
                  <Translation id='rev' /> {rev}
                </>
              )
            }
          ]}
          dataSource={historicalValuesToShow}
          scroll={expanded ? false : { y: 150 }}
        />
      </TableContainer>
      {expanded && (
        <PaginationContainer data-testid='historical-pagination'>
          <Pagination
            defaultCurrent={1}
            total={total}
            current={pagination.current}
            pageSize={pagination.pageSize}
            onChange={onChangePage}
          />
        </PaginationContainer>
      )}
    </Drawer>
  )
}

const PaginationContainer = styled.section`
  display: flex;
  justify-content: center;
`

const TableContainer = styled.section`
  height: ${({ 'data-expanded': expanded }) =>
    expanded ? 'calc(100% - 100px)' : 'calc(100% - 60px)'};
  overflow: hidden;
`

export default HistoricalDrawer
