export function getSelectPropertiesFromDataExecution(
  columns,
  additionalProperties = []
) {
  const selectProperties = columns
    .map((column) => column.dataIndex)
    .filter((c) => c && c !== 'procedure.serie.name')
  return [
    ...selectProperties,
    ...additionalProperties,
    'serie.id',
    'serie.name'
  ]
}
