export const calculateContentHeight = (textArea, scanAmount) => {
  const origHeight = textArea.style.height
  const scrollHeight = textArea.scrollHeight
  const overflow = textArea.style.overflow
  let height = textArea.offsetHeight

  /// only bother if the textArea is bigger than content
  if (height >= scrollHeight) {
    /// check that our browser supports changing dimension
    /// calculations mid-way through a function call...
    textArea.style.height = height + scanAmount + 'px'
    /// because the scrollbar can cause calculation problems
    textArea.style.overflow = 'hidden'
    /// by checking that scrollHeight has updated
    if (scrollHeight < textArea.scrollHeight) {
      /// now try and scan the textArea's height downwards
      /// until scrollHeight becomes larger than height
      while (textArea.offsetHeight >= textArea.scrollHeight) {
        textArea.style.height = (height -= scanAmount) + 'px'
      }
      /// be more specific to get the exact height
      while (textArea.offsetHeight < textArea.scrollHeight) {
        textArea.style.height = height++ + 'px'
      }
      /// reset the textArea back to it's original height
      textArea.style.height = origHeight
      /// put the overflow back
      textArea.style.overflow = overflow
      return Math.ceil(origHeight / scanAmount) - 1
    } else {
      return Math.floor(height / scanAmount) - 1
    }
  } else {
    return Math.ceil(scrollHeight / scanAmount) - 1
  }
}
