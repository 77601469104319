import { useRemoteData } from '../useRemoteData'

export const useGlobalSeries = ({ disabled, search } = {}) => {
  return useRemoteData({
    url: '/serie/search',
    disabled,
    search,
    take: 5
  })
}
