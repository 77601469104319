import React from 'react'
import styled from 'styled-components'

import packageJson from '../../../package.json'
import Translation from '../translations'

const VersionInfo = () => {
  return (
    <VersionWrapper>
      <Version>
        <Translation id='proceedVersion' /> {packageJson.version}
      </Version>
    </VersionWrapper>
  )
}

const VersionWrapper = styled.div`
  display: flex;
  background: #eff0ee;
  padding: 5px 0;
  cursor: default;
  align-items: baseline;
  justify-content: center;
  border-radius: 0px 0px 4px 4px;
`

const Version = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #979797;
  font-weight: 500;
`

export default VersionInfo
