import { getTranslation } from '../../../i18n/getTranslation'
import { updatePlantName } from '../../../localdb/units/plants/update'
import { editCustomerSetting } from '../../../services/customer-settings'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { notifyError } from '../../../utils/notifications'
import { validateCustomerSetting } from '../validation'

export async function modifyPlant(customerSetting, reload) {
  try {
    await validateCustomerSetting(customerSetting, true)
    const modifiedPlant = await editCustomerSetting(customerSetting)
    await updatePlantName(modifiedPlant.id, modifiedPlant.name)

    reload()
    notificationMessage({
      message: getTranslation('plant.edit.successMsg')
    })
  } catch (error) {
    notifyError(error)
  }
}
