import { CancelToken } from 'axios'
import { getGlobal } from 'reactn'

import { store } from '../../../store'
import { getUser } from '../../../store/user-management/selectors'
import { CONNECTION_STATUS } from '../../constants/connection'
import { debug } from '../../logger'

export function transformAxiosRequest(request) {
  debug('Call to Server:', request.method, request.url, request.data)

  const connectionStatus = store.getState().userInterface.connectionStatus
  const user = getUser(store.getState())

  if (
    connectionStatus === CONNECTION_STATUS.DISABLED ||
    connectionStatus === CONNECTION_STATUS.OFFLINE
  ) {
    return {
      ...request,
      cancelToken: new CancelToken((cancel) => {
        cancel('Connection is disabled')
      })
    }
  }

  return user
    ? {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${getGlobal().token}`
        }
      }
    : request
}

export function transformAxiosResponse(response) {
  if (!response.data) {
    return response
  }

  if (response.config.url) {
    debug(
      'Response from Server:',
      response.config.method,
      response.config.url,
      response.data
    )
  }

  return response.data.data
    ? {
        ...response,
        data: response.data.data
      }
    : response
}
