import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

const defaultColor = '#edece8'

const RoundButton = styled(
  ({ backgroundColor, textColor, disabled, ...otherProps }) => {
    return (
      <Button
        style={{
          backgroundColor: backgroundColor || defaultColor,
          color: textColor || '#000000',
          opacity: disabled ? '50%' : '100%'
        }}
        {...otherProps}
        disabled={disabled}
      />
    )
  }
)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &[disabled] .icon {
    opacity: 30%;
  }
`

export default RoundButton
