import { jwtDecode } from 'jwt-decode'

import { renewToken } from '../../../../application/users/token'
import {
  ENCRYPTED_TABLES,
  TABLE_NAMES
} from '../../../../utils/constants/localdb'
import { decrypt, encrypt } from '../../../../utils/helpers/crypto'

export function loadVersion3(db, encryptionKey) {
  return db
    .version(3)
    .stores({
      [TABLE_NAMES.PROCEDURES]:
        'id, unit, serieName, key, rev, title, buildNumber, status, [id+unit+serieName+key+rev+title+buildNumber+status]',
      [TABLE_NAMES.EXECUTIONS]: `id, qaStatus, status, createdAt, updatedAt, syncedAt, procedureId -> ${TABLE_NAMES.PROCEDURES}.id`,
      [TABLE_NAMES.EXECUTION_COMPONENTS]: `id, createdAt, updatedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
      [TABLE_NAMES.EXECUTION_STEPS]: `id, createdAt, updatedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
      [TABLE_NAMES.EXECUTION_MANEUVERS]: `id, createdAt, updatedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
      [TABLE_NAMES.EXECUTION_WARNINGS]: `id, createdAt, updatedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
      [TABLE_NAMES.EXECUTION_COMMENTS]: `id, createdAt, updatedAt, deletedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
      [TABLE_NAMES.EXECUTION_CONDITIONALS]: `id, createdAt, updatedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
      [TABLE_NAMES.FILES]: 'id',
      [TABLE_NAMES.FILES_CONTENT]: 'id',
      [TABLE_NAMES.MEDIA]: 'id',
      [TABLE_NAMES.USERS]: 'id',
      [TABLE_NAMES.PLACEKEEPING_HISTORY]: 'id, executionId, stepId, createdAt',
      [TABLE_NAMES.EXECUTION_SYNC_STATUS]: `id, ${TABLE_NAMES.EXECUTIONS}, ${TABLE_NAMES.EXECUTION_COMPONENTS}, ${TABLE_NAMES.EXECUTION_STEPS}, ${TABLE_NAMES.EXECUTION_MANEUVERS}, ${TABLE_NAMES.EXECUTION_WARNINGS}, ${TABLE_NAMES.EXECUTION_COMMENTS}, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
      [TABLE_NAMES.EXECUTION_GOALS]: 'id, name, createdAt, updatedAt',
      [TABLE_NAMES.UNITS]:
        'id, name, default, plantId, plantName, createdAt, updatedAt',
      [TABLE_NAMES.HISTORICAL_VALUES]: 'name, key, [key+name]'
    })
    .upgrade(async (trans) => {
      const tables = Object.values(ENCRYPTED_TABLES)

      const newToken = await renewToken()
      const { iek } = jwtDecode(newToken)

      for (const table of tables) {
        const data = await trans.table(table).toArray()

        // Decrypt the data using the old encryption key
        const decryptedData = data.map((item) => decrypt(encryptionKey, item))

        // Re-encrypt the data with the new encryption key
        const reEncryptedData = decryptedData.map((item) => encrypt(iek, item))

        // Clear the table
        await trans.table(table).clear()

        // Reinsert the re-encrypted data back into the table
        for (const item of reEncryptedData) {
          await trans.table(table).put(item)
        }
      }
    })
}
