import React from 'react'


import useReadoutRecorder from '../../../../hooks/use-readout/recorder'
import { POOR_QUALITY } from '../../../../utils/constants/config'
import ReadoutRT from '../readout-rt'

export const ReadoutRTRecorder = ({ executionComponent, ...props }) => {
  const { value = '', quality = '' } = executionComponent?.liveValue || {}
  const isPoor = quality === POOR_QUALITY
  const { error } = useReadoutRecorder({
    isPoor,
    value
  })

  return (
    <ReadoutRT
      {...props}
      executionComponent={executionComponent}
      error={error}
    />
  )
}

export default ReadoutRTRecorder
