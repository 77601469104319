import { exportProcedures as exportProceduresRequest } from '../../services/procedures/http/post'
import { downloadFile } from '../../utils/helpers/files'

export async function exportProcedures(procedureIds) {
  const zipNode = await exportProceduresRequest(procedureIds)

  const contentDispositionHeader = zipNode.headers['content-disposition']
  const filename = contentDispositionHeader
    .split('filename=')[1]
    .replaceAll('"', '')

  return downloadFile(zipNode.data, 'application/zip', filename)
}
