import { groupBy } from 'lodash'

import { putExecutionComponents } from '../../../services/execution-components/http/put'

export const sendExecutionComponents = async (executionComponents) => {
  const grouped = groupBy(executionComponents, 'executionId')

  return Promise.all(
    Object.keys(grouped).map((executionId) =>
      putExecutionComponents(executionId, grouped[executionId])
    )
  )
}
