import { createSelector } from '@reduxjs/toolkit'

export const getExecutionWarnings = createSelector(
  (state) => {
    return state?.executionWarnings
  },
  (executionWarnings) => {
    return executionWarnings.warnings
  }
)

export const getExecutionWarning = createSelector(
  [(state) => state.executionWarnings, (_state, id) => id],
  ({ warnings }, notificationId) =>
    warnings.find((w) => w.notificationId === notificationId)
)
