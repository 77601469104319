import { store } from '../../../../store'
import { updateUpdatedAtExecution } from '../../../../store/execution/slice'
import { editExecutionCommentAction } from '../../../../store/executionComments/actions'
import { setIsWorking } from '../../../../store/userInterface/slice'

export async function editComment(comment) {
  store.dispatch(setIsWorking(true))
  store.dispatch(editExecutionCommentAction({ comment }))
  store.dispatch(updateUpdatedAtExecution({ updatedAt: new Date().valueOf() }))
}
