import { notification as antdNotification } from 'antd'
import React from 'react'

import CloseIcon from '../../components/icons/close-icon'
import { BACKEND_ERRORS } from '../constants/errors'

import { getCompleteTexts } from './translations'

const notificationStyle = {
  borderColor: '#a9a9a9',
  borderWidth: '3px',
  borderStyle: 'solid'
}

function notify(
  type,
  {
    key,
    messageId,
    messageParams,
    descriptionId,
    descriptionParams,
    description,
    placement,
    duration = 4.5,
    closeIcon,
    icon,
    onClose
  }
) {
  const { completeDescription, completeMessage } = getCompleteTexts(
    messageId,
    messageParams,
    descriptionId,
    descriptionParams,
    description
  )

  antdNotification.close(key)

  antdNotification[type]({
    message: completeMessage,
    description: completeDescription,
    duration,
    key,
    placement: placement || 'bottomLeft',
    style: notificationStyle,
    closeIcon: closeIcon || <CloseIcon size={16} />,
    icon: icon || null,
    onClose: onClose || null
  })
}

export const notification = {
  success: ({
    key,
    messageId,
    descriptionId,
    descriptionParams,
    messageParams,
    description,
    placement,
    duration,
    closeIcon,
    icon,
    onClose
  }) => {
    notify('success', {
      key,
      messageId,
      descriptionId,
      descriptionParams,
      messageParams,
      description,
      placement,
      duration,
      closeIcon,
      icon,
      onClose
    })
  },
  error: ({
    key,
    messageId,
    descriptionId,
    descriptionParams,
    messageParams,
    description,
    placement,
    duration,
    closeIcon,
    icon,
    onClose
  }) => {
    notify('error', {
      key,
      messageId,
      descriptionId,
      descriptionParams,
      messageParams,
      description,
      placement,
      duration,
      closeIcon,
      icon,
      onClose
    })
  },
  warning: ({
    key,
    messageId,
    descriptionId,
    descriptionParams,
    messageParams,
    description,
    placement,
    duration,
    closeIcon,
    icon,
    onClose
  }) => {
    notify('warning', {
      key,
      messageId,
      descriptionId,
      descriptionParams,
      messageParams,
      description,
      placement,
      duration,
      closeIcon,
      icon,
      onClose
    })
  }
}

export function notifyError(error) {
  if (error?.response?.data.message) {
    const key = error.response.data.message
    const backendError = BACKEND_ERRORS[key] ?? null

    return notification.error({
      messageId: backendError?.messageId ?? 'error',
      descriptionId: backendError?.descriptionId ?? 'error.generic',
      key
    })
  }

  if (error.descriptionId) {
    return notification.error({
      messageId: error.messageId ?? 'error',
      descriptionId: error.descriptionId,
      key: error.key
    })
  }

  notification.error({
    messageId: 'error',
    key: 'GENERIC_ERROR',
    descriptionId: 'error.generic'
  })
}
