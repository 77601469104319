import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData } from '../../common/write'

const tableName = TABLE_NAMES.PLACEKEEPING_HISTORY

/**
 * Stores in the local DB placekeeping data
 * @param {*} data - Placekeeping data entry.
 * @returns the dexie transaction response.
 */
export async function writePlacekeepingHistory(data) {
  return _writeLocalDbData({
    tableName,
    data,
    action: 'PUT'
  })
}
