import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Collapse as AntdCollapse, Popover, List } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import Approve from '../../../../components/icons/approve'
import DeleteIcon from '../../../../components/icons/delete-icon'
import EditIcon from '../../../../components/icons/edit-icon'
import { getTranslation } from '../../../../i18n/getTranslation'
import { deleteRole } from '../../../../services/roles/http/delete'
import { putRole } from '../../../../services/roles/http/put'
import { notificationMessage } from '../../../../utils/helpers/notification-message'
import PutRoleModal from '../../../modal/put-role-modal'
import Translation from '../../../translations'

const { Panel } = AntdCollapse

const RolesConfigurationList = ({ roles, refetch }) => {
  const [deletePopover, setDeletePopover] = useState(false)
  const [openPutRole, setOpenPutRole] = useState(false)
  const [roleToPut, setRoleToPut] = useState()

  const handleEditRole = async (role) => {
    try {
      const {
        roleScreenPermissions: _oldRoleScreenPermissions,
        roleLevel: _roleLevel,
        selectedPermissions,
        ...restRole
      } = role
      await putRole({
        role: restRole,
        roleScreenPermissions: selectedPermissions
      })
      refetch()
      notificationMessage({
        message: getTranslation('roleEditSuccessMsg')
      })
      setOpenPutRole(false)
    } catch (error) {
      notificationMessage('roleEditErrorMsg', error)
      setOpenPutRole(false)
    }
  }

  const handleDeleteRole = async (id) => {
    try {
      await deleteRole(id)
      refetch()
      notificationMessage({
        message: getTranslation('roleDeleteSuccessMsg')
      })
    } catch (error) {
      if (error?.response?.data.message === 'ROLE_IS_ASSIGNED_TO_USER') {
        notificationMessage({
          type: 'error',
          message: getTranslation('role.delete.error.conflict.user')
        })
      } else {
        notificationMessage({
          type: 'error',
          message: getTranslation('roleDeleteErrorMsg')
        })
      }
      setDeletePopover(false)
    }
  }

  const DeletePopoverContent = ({ role }) => (
    <DeletePopover>
      <div>
        <ExclamationCircleFilled /> <Translation id='deleteRole' />
      </div>
      <div>
        <Button
          style={{ marginRight: '8px' }}
          type='secondary'
          onClick={() => {
            setDeletePopover(false)
          }}
        >
          <Translation id='no' />
        </Button>
        <Button type='primary' onClick={() => handleDeleteRole(role.id)}>
          <Translation id='yes' />
        </Button>
      </div>
    </DeletePopover>
  )

  const genExtraStuff = (role) => (
    <div onClick={(e) => e.stopPropagation()}>
      <RoleActionButton
        icon={<EditIcon size={14} />}
        onClick={() => {
          setOpenPutRole('edit')
          setRoleToPut(role)
        }}
        disabled={role.admin}
      />{' '}
      <Popover
        open={() => deletePopover === role.id}
        placement='topRight'
        content={
          <>
            <DeletePopoverContent role={role} />
          </>
        }
      >
        <RoleActionButton
          icon={<DeleteIcon size={14} />}
          onClick={() => {
            setDeletePopover(role.id)
          }}
          disabled={role.admin}
        />
      </Popover>
    </div>
  )

  const HeaderName = ({ name, roleLevel }) => (
    <>
      <span>{name}</span>
      <span style={{ marginLeft: '18px', color: '#A3A3A3' }}>
        <Translation id={roleLevel?.title} /> - <Translation id='level' />{' '}
        {roleLevel?.level}
      </span>
    </>
  )

  return (
    <RolesConfigContainer>
      <Collapse>
        {roles.map((role) => (
          <Panel
            header={<HeaderName name={role.name} roleLevel={role.roleLevel} />}
            key={role.id}
            extra={genExtraStuff(role)}
          >
            <List
              dataSource={role.roleScreenPermissions}
              renderItem={({ screenPermission, roleActionPermissions }) => (
                <ListItemContainer>
                  <LisItemTitle>
                    <Approve size={14} color='#8CC63F' />
                    <Translation id={screenPermission.title} />
                  </LisItemTitle>
                  <ul>
                    {roleActionPermissions.length !== 0 &&
                      roleActionPermissions.map(({ actionPermission }) => (
                        <li key={actionPermission?.id}>
                          <Translation id={actionPermission?.title} />
                        </li>
                      ))}
                  </ul>
                </ListItemContainer>
              )}
            />
          </Panel>
        ))}
        <PutRoleModal
          open={openPutRole}
          closeModal={() => setOpenPutRole(false)}
          onConfirm={handleEditRole}
          actionTranslation={'editRole'}
          elementToEdit={roleToPut}
        />
      </Collapse>
    </RolesConfigContainer>
  )
}

const RolesConfigContainer = styled.div`
  margin: 1em 0;
  width: 100%;
`
const Collapse = styled(AntdCollapse)`
  .ant-collapse-expand-icon {
    line-height: 32px;
  }

  .ant-collapse-header-text {
    line-height: 32px;
  }
`
const ListItemContainer = styled(List.Item)`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`
const LisItemTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: flex-start;
`

const RoleActionButton = styled(Button)`
  background: #ffffff;
  border-radius: 2px;
  border: none;
`

const DeletePopover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

export default RolesConfigurationList
