import { createSelector } from '@reduxjs/toolkit'

export const getExecutionComponents = createSelector(
  (state) => {
    return state?.executionComponents
  },
  (executionComponents) => {
    return executionComponents?.components
  }
)

export const getExecutionComponent = createSelector(
  [(state) => state.executionComponents, (_state, id) => id],
  ({ components }, id) =>
    components?.find((ec) => ec.procedureComponentId === id)
)
