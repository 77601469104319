{
  "error": "Error",
  "error.generic": "An error has occurred during the process, please try again.",
  "dateTimeFormat": "MM/DD/YYYY hh:mm A",
  "dateTimeFormatShort": "MM/DD/YY-HH:mm",
  "dateFormat": "MM/DD/YYYY",
  "timeFormat": "HH:mm A",
  "dateFormatShort": "MM/DD/YY",
  "executionCodeDateTimeFormat": "MMDDYY-HHmmss",
  "procedures": "Procedures",
  "executions": "Executions",
  "execution": "Execution",
  "executionId": "Execution ID",
  "executionStartDate": "Start Date",
  "step": "Step",
  "proceduresOnDevice": "Procedures on device",
  "executionsOnDevice": "Executions on device",
  "downloadedProcedures": "Downloaded procedures",
  "unit": "Unit",
  "series": "Series",
  "series.lower": "series",
  "units.lower": "units",
  "unit.by.default": "Unit {unitName} by default",
  "key": "Key",
  "rev": "Rev.",
  "title": "Title",
  "buildNumberShort": "BN",
  "lastMod": "Last Mod.",
  "startDate": "Start date",
  "status": "Status",
  "owner": "Owner",
  "action": "Action",
  "progress": "Progress",
  "sync": "Sync",
  "syncExecutions": "Sync",
  "range": "Range",
  "clean": "Clean",
  "apply": "Apply",
  "okText": "OK",
  "confirm": "Confirm",
  "cancel": "Cancel",
  "close": "Close",
  "abort": "Abort",
  "finish": "Finish",
  "single": "Single",
  "shared": "Shared",
  "initToExecute": "Press Start to run the procedure",
  "language": "Language",
  "help": "Help",
  "management": "Management",
  "confirmLogout": "Are you sure you want to log out?",
  "userInfo": "User info",
  "logout": "Log out",
  "beforeLoggingOut": "Before logging out...",
  "executionMassiveInterruption": "Execution massive interruption",
  "executionMassiveInterruptionText": "These are the executions that you can interrupt as a whole. Once you interrupt them they will be deleted from your device and uploaded to the server.",
  "executionMassiveInterruptionTextEmpty": "Looks like you don't have any execution to interrupt as a whole.",
  "proceduresWorking": "Executions working",
  "reviseExecutions": "Revise executions",
  "executionsOutOfSync": "There are some executions out of sync. Sync pending executions before logout",
  "executionsOutOfSyncNotLogout": "There are some executions out of sync. Sync pending executions to massively interrupt executions.",
  "executionsInterrupted": "All the executions in progress have been interrupted",
  "interruptLogout": "Interrupt and logout",
  "interruptButton": "Interrupt executions",
  "executionsNeedsInterrupt": "You cannot log out as you have local executions running. Please, revise them and change them to proper status",
  "executionsNeedsSync": "Go to a connection point to sync executions.",
  "workNeedsSync": "There are unsynchronized executions. Please find a connection point and sync the work.",
  "downloadComplete": "Download complete",
  "downloadFailed": "Download failed",
  "downloadedProcedure": "Downloaded procedure:",
  "downloadedExecution": "Downloaded execution",
  "downloadedReferencedProcedures": "Along with the following referenced procedures:",
  "downloadReferencedFilesFailed": "Some of the referenced documents couldn't be downloaded",
  "downloadReferencedProceduresFailed": "Failed to download referenced procedures",
  "downloadReferencedProcedureIdFailed": "The procedure(s) with KEYs: {procedureKey} could not be downloaded",
  "delete": "Delete",
  "deleteSuccess": "Deleted successfully",
  "deleteError": "Delete error",
  "deleteDescrProc": "The Procedure has been deleted",
  "deleteDescrExec": "The Execution has been deleted",
  "loadProcedureError": "A problem has been found and couldn't load the procedure",
  "loadProcedureErrorSubtitle": "It may be poorly generated or created in a previous version that is no longer supported",
  "index": "Index",
  "nStepsToSignPlural": "{count} steps to sign",
  "nStepsToSignSingular": "{count} step to sign",
  "documents": "Documents",
  "jumps": "Jumps",
  "return": "Return",
  "fileRefError": "The referenced file is not found",
  "jump": "Jump",
  "add": "Add",
  "edit": "Edit",
  "goBack": "Go back",
  "pageNotFound": "Sorry, the page you're looking for doesn't exist",
  "noConnectionTitle": "No connection",
  "noConnectionDescription": "Information only available if the device is connected",
  "commentsDrawerStep": "Comments in step ",
  "nonCompliance": "Non Compliance",
  "commentsDrawerAll": "All steps with comments",
  "addComment": "Add Comment",
  "editComment": "Edit Comment",
  "requiredNonCompliantComment": "It is necessary to fill in a reason for the non-compliancy of the component to continue the execution",
  "recommendedNonCompliantComment": "It is recommended to fill in a reason for the non-compliancy of the component to continue the execution",
  "nonCompliantInitialValue": "The component {componentType} is not compliant for the following reasons: ",
  "loadingLocalExecutions": "Loading local executions",
  "noLocalExecutions": "No local executions are saved in this device",
  "stepsInProgress": "Steps in progress",
  "stepsOnHold": "Steps on hold",
  "procedureComments": "Change proposal and execution's attachments",
  "performingManeuver": "Performing maneuver",
  "cancelManeuver": "Cancel maneuver",
  "select": "Select",
  "sign": "Sign",
  "selectdate": "Select date",
  "invalidNumber": "The value is not a valid number",
  "outOfRange": "The value is out of range: {min} ≤ x ≤ {max}",
  "validationNotPassed": "Out of limit: {validationRule}",
  "signalQualityPoor": "Poor signal quality",
  "comment": "Comment",
  "comments": "Comments",
  "noComments": "No comments",
  "fieldRequired": "This field is required",
  "finishExecutionQuestion": "Are you sure you want to finish the execution?",
  "finishStepsOnHoldProgress": "You cannot finish an execution that has steps in progress or on hold",
  "abortStepsOnProgress": "You cannot abort an execution that has steps in progress",
  "annexed": "Comments annexed",
  "downloadProceduresModalTitle": "Select the referenced procedures to download",
  "download": "Download",
  "procedureNotDownloaded": "Procedure not downloaded",
  "downloadProcedureBeforeJump": "Please, download the procedure before jumping in",
  "error_uploading_files": "Some files have not been uploaded",
  "only_upload_images": "Only images can be uploaded",
  "only_upload_images_and_videos": "Only images and videos can be uploaded",
  "search": "Search",
  "reset": "Reset",
  "initial_precautions": "Initial precautions",
  "initial_warnings": "Initial warnings",
  "maneuver_warnings": "Maneuver warnings",
  "connectionLostTitle": "Disconnected",
  "connectionLostDescription": "Connection to the server was lost",
  "connectedTitle": "Connected",
  "connectedDescription": "Connected to the server",
  "connection_needed": "A server connection is required to perform this action",
  "username": "Username",
  "password": "Password",
  "newPassword": "New password",
  "initials": "Initials",
  "loginButton": "Log in",
  "errorOnOfflineLogin": "Error while trying to retrieve local data",
  "insertRemotePassword": "Insert server password",
  "insertLocalPassword": "Insert local password",
  "remotePasswordWrong": "Your password has changed in another device. Please insert your new password to update it in this device.",
  "localPasswordWrong": "Your password has changed in another device. Please insert the last password you used in this device.",
  "loginOnlineFirst": "You are offline. A connection to the server is required to log in for the first time.",
  "failedAuthAttempt": "The password or email are invalid",
  "unknownError": "Unknown error",
  "other": "Other",
  "qa": "QA",
  "qaStatus": "QA",
  "myWorkForToday": "My work for today",
  "userDashboardExecutionstypes": "Interrupted executions and shared executions in progress",
  "assignedProcedures": "Assigned procedures",
  "manageExecutions": "Manage executions",
  "review": "Review",
  "approve": "Approve",
  "merge": "Merge",
  "mergeModalTitle": "Merge Parameters",
  "mergedExecutionsTitle": "Merged executions",
  "done": "Done",
  "approved": "Approved",
  "reviewed": "Reviewed",
  "rejected": "Rejected",
  "reject": "Reject",
  "pending": "Pending",
  "signatures": "Signatures",
  "signature": "Signature",
  "solveConflicts": "Conflict resolution",
  "noConflicts": "There are no conflicts",
  "EXECUTING": "EXECUTING",
  "PAUSED": "PAUSED",
  "ABORTED": "ABORTED",
  "INTERRUPTED": "INTERRUPTED",
  "FINISHED": "FINISHED",
  "SHARED": "SHARED",
  "EXECUTING.capitalized": "Executing",
  "PAUSED.capitalized": "Paused",
  "ABORTED.capitalized": "Aborted",
  "INTERRUPTED.capitalized": "Interrupted",
  "FINISHED.capitalized": "Finished",
  "SHARED.capitalized": "Shared",
  "APPROVED": "APPROVED",
  "REVIEWED": "REVIEWED",
  "REJECTED": "REJECTED",
  "DRAFT": "DRAFT",
  "REVIEW": "REVIEW",
  "PUBLISHED": "PUBLISHED",
  "DEPRECATED": "OBSOLETE",
  "isOffline": "You are offline",
  "noPermission": "You do not have permission for this operation. Contact your administrator.",
  "noPermissionForSpecificRole": "You must have '{role}' role level or higher and the permission to perform this operation. Contact the administrator",
  "rejectModal.infoMessage": "The execution was rejected due to the following reasons:",
  "rejectModal.rejectMessage": "You are going to reject the execution, this action cannot be undone. Please indicate the reason to continue.",
  "rejectModal.rejectReason": "Reject reason",
  "confirmRevisionQuestion": "All changes on review will be saved automatically.",
  "incorrectSignatureWarning": "Attention: invalid signatures have been found.",
  "deprecatedProcedureWarning": "Attention: this procedure is obsolete",
  "cannotFinishReview": "The review cannot be completed due to incorrect signatures.",
  "componentChangedFromTo": "{type} component value has changed from {oldValue} to {newValue} at {date} by {user}.",
  "empty": "empty",
  "turn_on_sync": "Turn on sync",
  "trying_to_reconnect": "Trying to reconnect",
  "reconnect": "Reconnect",
  "could_not_connect": "Connection with the server wasn´t possible. Shared executions need internet connection to work.",
  "exit": "Exit",
  "exit_execution": "Exit execution",
  "me": "Me",
  "deleteProcedureQuestion": "Are you sure you want to delete the procedure?",
  "deleteExecutionQuestion": "Are you sure you want to delete the execution?",
  "executionInformation": "Execution information",
  "procedure": "Procedure",
  "executionDate": "Execution Date",
  "executionCode": "Execution Code",
  "executionGoal": "Execution Goal",
  "editExecutionGoal": "Edit Execution Goal",
  "executionGoalRequired": "Please insert an Execution Goal",
  "executionGoals": "Execution Goals",
  "executionGoalsEdit": "Modify Execution Goal",
  "executionGoalDelete": "Are you sure you want to delete this execution goal?",
  "executionGoalPlaceholder": "Add an execution goal",
  "executionStatus": "Execution Status",
  "internalCode": "Internal Code",
  "date": "Date",
  "hour": "Hour",
  "start": "Start",
  "final": "Final",
  "notYetFinished": "not yet finished",
  "saveChanges": "Save changes",
  "writeHere": "Write here...",
  "TextFieldEditing": "Text field editing",
  "proceduresDownload": "Procedures to download",
  "executionsDownload": "Executions to download",
  "checkIn": "Check-in",
  "pause": "Pause",
  "checkInOk": "The execution has been interrupted successfully",
  "proceduresExecTab": "Procedures",
  "execution.start.modal.title": "Execution start",
  "execution.goals": "Execution goals",
  "execution.plant.unit": "Executed for",
  "execution.client.department": "Department",
  "execution.client.department.placeholder": "Departments",
  "execution.plant.unit.placeholder": "Choose an option",
  "execution.client.settings.errMsg": "Please enter a plant and unit!",
  "execution.client.settings.SuccessMsg": "Settings saved correctly!",
  "execution.clientSettings.delete.successMsg": "Setting removed correctly!",
  "execution.clientSettings.delete.failedMsg": "Error while saving!",
  "execution.goal.successMsg": "Execution Goal added correctly!",
  "execution.goal.edit.successMsg": "Execution Goal edited correctly!",
  "execution.goal.delete.successMsg": "Execution Goal removed correctly!",
  "execution.goal.delete.failMsg": "An error occurred when removing the Execution Goal!",
  "execution.unit": "Unit",
  "execution.client.settings": "Write to add a client or plant to the list",
  "execution.customer.settings.add.SuccessMsg": "Customer or plant saved correctly!",
  "execution.unit.plant": "Add a unit to the customer or plant",
  "continue": "Continue",
  "observations": "Observations",
  "next": "Next",
  "previous": "Previous",
  "seniorRoleRequired": "Senior role required to sign",
  "chooseSeniorRole": "The signature of this step must be performed by a higher role than yours. Please select from the list below the partner with a higher role who will provide you a signature code:",
  "chooseSeniorRolePlaceholder": "Choose a partner with a higher role than yours",
  "enterSignatureCode": "Enter the provided signature code",
  "codeInvalid": "The code entered is invalid",
  "noMergeDifferentProcedure": "Two executions from different procedure can´t be merged",
  "codeGenerator": "Code generator",
  "singleUseCodeTitle": "Single-use security code generator",
  "singleUseCodText": "Single-use security codes are only used to perform actions through other users, such as making a signature.",
  "singleUseCodeList1": "You can generate as many codes as you want.",
  "singleUseCodeList2": "The use of a code is allowed only once.",
  "singleUseCodeList3": "Once the code generator is closed, the generated code will be lost.",
  "generateBtn": "Generate",
  "admin": "Admin",
  "shift_manager": "Shift Manager",
  "supervisor": "Supervisor",
  "room_operator": "Room Operator",
  "plant_operator": "Plant Operator",
  "guest": "Guest",
  "digitalizer": "Digitalizer",
  "view": "View",
  "downloadProceduresTitle": "Download procedures",
  "downloadExecutionsTitle": "Download executions",
  "cloneExecution": "Clone",
  "resumeExecution": "Resume",
  "errorResume": "Resuming error",
  "errorResumeDescription": "An error occurred while resuming execution",
  "successResume": "Resuming finished",
  "successResumeDescription": "The resuming has been a success",
  "disconectModalBody": "Are you sure you want to log out?",
  "proceedVersion": "Proceed version",
  "userJoinedExecution": "User connected",
  "userLeftExecution": "User disconnected",
  "userJoinedExecutionDescription": "{user} joined the execution in '{mode}' mode",
  "userLeftExecutionDescription": "{user} left the execution",
  "enterUserName": "Please insert your username!",
  "enterPassword": "Please insert your password!",
  "executionNoSync": "The execution is not synchronized",
  "procedureNotFound": "The procedure you requested does not exist or is not downloaded",
  "executionNotFound": "The execution you requested does not exist or is not downloaded",
  "referencedProcedureNotFound": "The referenced procedure with key '{key}' does not exist",
  "onlineUsers": "Online users",
  "clickedStepIsDisabled": "The step is disabled by a maneuver or conditional",
  "clickedStepIsOccupied": "The step is occupied by another user",
  "interactExecutionNotRunning": "The procedure is not running",
  "interactExecutionDuringRevisionNotRunning": "The procedure is not running, changes are being applied from the review process",
  "cannotMakeChangesInMerge": "You cannot make changes while merging executions",
  "reviewerCantMakeChanges": "The execution has already been reviewed, so no more changes are allowed",
  "legalPolicies": "Legal policies",
  "legalPoliciesIdentity": "1) IDENTITY OF THE WEB USER",
  "legalPoliciesIdentityText": "TECNATOM, S.A. is a company with address in Avenida Montes de Oca, 1 CP. 28703, San Sebastián de los Reyes (Madrid- España), with id A28074078 e inscrita en el Registro Mercantil de Madrid el 4 de septiembre de 1957, Tomo 1208 general 692 sección 3ª del Libro de Sociedades, Folio 22 Hoja 4239. Teléfono: 916598845 · FAX: 916598677 web: ",
  "legalPoliciesConditions": "2) CONDITIONS OF USE AND ACCEPTANCE",
  "legalPoliciesConditionsText": "These Conditions of Use regulate the use of the services that TECNATOM, S.A. makes available to Proceed Users. The use of the website by a third party attributes the condition of User and implies full acceptance by said User of each and every one of the conditions that are incorporated in this Legal Notice. TECNATOM, S.A. can offer through the Web, services or products that may be subject to its own particular conditions that, depending on the case, replace, complete and/or modify these Conditions, and about which the User will be informed in each specific case.",
  "counterOf": "{index} of {total}",
  "selectTime": "Select time",
  "manageProcedures": "Manage procedures",
  "import": "Import",
  "important": "Important",
  "export": "Export",
  "importSuccess": "File imported successfully",
  "procedureAlreadyExists": "The procedure already exists: {id}",
  "fileAlreadyExists": "The file already exists: {id}",
  "attachmentRepeated": "This attachment has been already added!",
  "unknownImportError": "Unknown error while importing the file",
  "controlPanel": "Control Panel",
  "configurationPanel": "Configuration",
  "configurationPanelClients": "Customer or Site",
  "configurationPanelUnits": "Unit",
  "configurationPanelUsers": "Users",
  "configurationPanelRoles": "Roles and permissions",
  "configurationPanel.alert.deleteEntry": "Are you sure you want to delete this entry?",
  "roleAddSuccessMsg": "Role created successfully",
  "roleAddErrorMsg": "Error adding role",
  "roleEditSuccessMsg": "Role modified successfully",
  "roleEditErrorMsg": "Error modifying role",
  "roleDeleteSuccessMsg": "Role removed successfully",
  "roleDeleteErrorMsg": "Error deleting role",
  "role.resetPermissions": "Reset permissions",
  "role.resetPermissionsTooltip.text": "If you reset the permissions you will lose the ones you have selected",
  "role.resetPermissionsTooltip.button.discard": "Discard",
  "role.resetPermissionsTooltip.button.reset": "Reset",
  "role.create.error.conflict.exists": "The role could not be created because there is another role with the same name",
  "role.delete.error.conflict.user": "The role could not be deleted because it is assigned to one or more users",
  "role.level.select.description.part1": "The levels are shown in order of ranks, with level 1 being the highest rank.",
  "role.level.select.description.part2": "Each level encompasses the capabilities of the lower ranks.",
  "role.level.admin.title": "Admin",
  "role.level.admin.description": "Management of the application and its users",
  "role.level.digitalizer.title": "Digitalizer",
  "role.level.digitalizer.description": "Digitization and management of procedures",
  "role.level.approver.title": "Approver",
  "role.level.approver.description": "Approval and review of the executions carried out",
  "role.level.reviewer.title": "Reviewer",
  "role.level.reviewer.description": "Review of executions performed",
  "role.level.procedure.executor.title": "Procedure executor",
  "role.level.procedure.executor.description": "Execution and completion of executions",
  "role.level.field.staff.title": "Field staff",
  "role.level.field.staff.description": "Carrying out of field executions",
  "role.level.guest.title": "Guest",
  "role.level.guest.description": "User with viewing permissions",
  "addRole": "Add New Role",
  "deleteRole": "Are you sure to delete this role?",
  "editRole": "Modify role",
  "roleName": "Role name",
  "roleNamePlaceholder": "Write name...",
  "roleLevel": "Role level",
  "roleLevelPlaceholder": "Select level",
  "level": "Level",
  "permissions": "Permissions",
  "executionHistory": "Execution history",
  "manageUsers": "Manage users",
  "name": "Name",
  "email": "Email",
  "role": "Role",
  "filters": "Filters",
  "applyFilters": "Apply filters",
  "clearFilters": "Clear filters",
  "modifiedAfter": "Modified after",
  "modifiedBefore": "Modified before",
  "searchByTerms": "Search by terms",
  "addNewUser": "Add new user",
  "addUser": "Add user",
  "editUserInfo": "Edit user info",
  "emailTaken": "The email is already taken",
  "errorCreatingUser": "Error creating user",
  "errorUpdatingUser": "Error updating user",
  "exitWithoutSaving": "Exit without saving",
  "exitWithoutSavingDescription": "If you exit without saving changes, they will be lost.",
  "changingPasswordDisabledForAdmin": "Only users can change their password.",
  "passwordWillBeChangedOnFirstLogin": "The user will be prompted to change the password on the first login.",
  "changingEmailDisabledForAdmin": "User's email addresses cannot be modified because it is their unique identifier.",
  "userAddedTemporaryPassword": "The user has been added successfully. A temporary one-time password has been generated, please copy it and send it to the user.",
  "copy": "Copy",
  "passwordCopiedToClipboard": "Password copied to clipboard",
  "passwordCopyError": "Could not copy password to clipboard. Please copy it manually.",
  "mergedFrom": "MERGED FROM: ",
  "noDownloadExecutionPermission": "You cannot download this execution because you don't have permissions to execute it",
  "sharedAlreadyConnected": "You're already connected to this shared execution from another device",
  "sharedNotConnected": "You're not connected to any shared execution",
  "sharedUnknownError": "An unknown error has occurred in the connection with the server",
  "deleteProcedureStatusError": "A procedure can only be deleted if its status is DRAFT, REVIEWED or APPROVED",
  "deleteProcedureWithExecutionsAdvice": "This procedure has executions, if the procedure is deleted, its executions will be deleted. Do you wish to continue?",
  "deleteProcedureAdvice": "Are you sure to delete the procedure?",
  "deleteProcedureConflictDeviceError": "It is not possible to delete the procedure due it was downloaded in other devices:",
  "deleteProcedureError": "There was an error deleting the procedure",
  "deleteProcedureSuccess": "The procedure has been deleted successfully",
  "changeStatus": "Change status",
  "changePassword": "Change password",
  "changePasswordText": "Enter your current password and set a new one to use when you log in to TecOS Proceed.",
  "currentPassword": "Current password",
  "repeatNewPassword": "Repeat new password",
  "passwordChangedOK": "The password has been changed successfully",
  "newPasswordNotEqualError": "New passwords do not match",
  "newPasswordEqualOldPasswordError": "The new password must be different from the old one",
  "welcomeToTecOSProceed": "Welcome to TecOS Proceed",
  "welcomeText": "You have used a temporary password to log in for the first time, now you have to set up your personal password with which you will log in from now on.",
  "saveAndContinue": "Save and continue",
  "skipAndContinue": "Skip and continue",
  "yesDelete": "Yes, delete",
  "yes": "Yes",
  "no": "No",
  "previousDataLostTitle": "Previous data will be lost",
  "previousDataLostWarn": "If you continue without entering your previous password, all previous data will be lost. Are you sure you want to continue?",
  "version": "Version",
  "clearAll": "Clear all",
  "reportGeneration": "Final report generation",
  "downloadReport": "Download report",
  "exportToPDFFailed": "Error during pdf report generation",
  "generateReport": "Re-generate pdf report",
  "reportErrorExecutionNotFound": "Execution not found",
  "reportErrorReportNotFound": "Execution report not found",
  "reportErrorFileNotFound": "Execution report file not found",
  "reportErrorExecutionNotFoundDescription": "The execution you have in your device is no longer available in the server",
  "reportErrorReportNotFoundDescription": "It is possible that the execution report was not generated correctly",
  "reportErrorFileNotFoundDescription": "It is possible that the report file was not generated correctly",
  "existOtherProcedurePublished": "There is already a published version of this procedure",
  "descriptionWarningPublishingProcedure": "You must mark as obsolete the currently published procedure with same key before publishing a new one",
  "errorUpdatingProcedure": "Error updating procedure",
  "errorClonation": "Clonation error",
  "errorClonationDescription": "Error during the clonation of the execution",
  "successClonation": "Clonation finished",
  "successClonationDescription": "The clonation process was successful",
  "uploadManualExecution": "Upload manual execution",
  "addManualExecution": "Add manual execution",
  "uploadManualExecutionText": "Fill and attach the data to the PDF file containing the manually performed execution that you want to include in the executions administrator.",
  "executionProcedure": "Execution procedure",
  "user": "User",
  "executionTimes": "Execution dates",
  "uploadExecution": "Upload execution",
  "onlyPDF": "Only PDF files are accepted",
  "proceduresSearchPlaceholder": "Search procedure by title or key",
  "usersSearchPlaceholder": "Search user by name",
  "findProcedure": "Find procedure",
  "findUser": "Find user",
  "recentProcedures": "There is a newer version for some of your local procedures. Do you want to update them?",
  "executionProgress": "Execution progress",
  "signaturesCompleted": "Completed Signatures",
  "recordersNumericalsFilled": "Filled numerical recorders",
  "radioButtonsSelected": "Filled radio buttons",
  "checkboxFilled": "Filled checkbox",
  "optionListsFilled": "Option lists filled",
  "buttonSave": "Save",
  "errorSyncingExecutionTitle": "Error syncing execution",
  "errorSyncingExecutionContent": "There was an unknown error syncing one of your local executions.",
  "errorSyncingExecutionProcedureNotFoundTitle": "Deleted procedure",
  "errorSyncingExecutionProcedureNotFoundContent": "Cannot sync an execution whose procedure has been deleted in the server. The execution and its procedure will be deleted.",
  "errorSyncingExecutionDownloadedAfterLastDownloadTitle": "Execution continued in another device",
  "errorSyncingExecutionDownloadedAfterLastDownloadContent": "One of the executions that are pending to sync has been downloaded in another device. The local execution will be deleted.",
  "minimumThreeCharacters": "Minimum three characters",
  "goToComment": "Go to comment",
  "backFromComment": "Go back",
  "copyToCustomFolderFailed": "Error during copy of report. Perhaps the custom folder doesn't exist or there is alredy a report with the same name",
  "insertDeviceName": "Insert device name",
  "device": "Device",
  "selectDevice": "Select device",
  "downloadedBy": "Downloaded by",
  "lastSync": "Last sync",
  "changeProposal": "Change proposal",
  "attachBefore": "Attach at the beginning",
  "attachAfter": "Attach at the end",
  "confirmDeleteComment": "Are you sure you want to remove the comment?",
  "confirmDeleteNonCompliantComment": "This is a Non-compliant comment, are you sure you want to remove it?",
  "attachmentNotFound": "A pdf file has to be attached",
  "fieldNotSet": "Field not set",
  "invalidAttachment": "The file was not attached",
  "noCommentsFinishing": "No comments to finished execution",
  "noCommentsReviewing": "No comments during review proccess",
  "noCommentsApproving": "No comments during Approval proccess",
  "advanced": "Advanced",
  "selectExecutionType": "Change execution parameters",
  "simpleApprobation": "Simple approbation",
  "customSettingsDetected": "Attention, custom execution",
  "descriptionModalAdvanced": "Execution options can be modified",
  "annotations": "Annotations",
  "textAreaMaxRowsError": "Exceeded maximum number of rows ({maxRows})",
  "placeHolderAnnotation": "Execution annotations. These annotations are considered temporary and will be deleted at the end of the execution",
  "validationExactSelection": "You must select {num} element/s",
  "validationMinimumSelection": "You must select at least {num} element/s",
  "validationStatus": "You must select elements with status {status}",
  "validationStatusNot": "You cannot select elements with status {status}",
  "validationSameProcedure": "All executions must start from the same procedure",
  "validationNotReviewed": "You cannot select executions that has not been reviewed",
  "validationNotAlreadyReviewed": "The execution is already reviewed",
  "validationCanBeApproved": "The execution must be previously reviewed",
  "validationHasPermission": "You don't have permission to perform this action",
  "validationNoRealExecution": "This execution wasn't performed in PROCEED. This action is not allowed",
  "validationStatusMerge": "You must select elements with status INTERRUPTED or FINISHED",
  "joinSharedExecution": "Joining a shared execution",
  "chooseExecutionMode": "Choose the mode you want to join the execution",
  "executionModeREAD": "Supervisor",
  "executionModeWRITE": "Execution",
  "confirmationDownloadExecutions": "Warning! If you download this execution, the one that is running on the owner's device will be marked as obsolete.",
  "errorGeneratingHTMLReportTitle": "Error during approbation process",
  "errorGeneratingHTMLReportContent": "The final report file was not properly sent. The execution was not approved. Please check your internet connection and try it again.",
  "preparingToPrinting": "Preparing procedure to print...",
  "generatingReport": "Generating execution report...",
  "NONE": "Flexible",
  "STRICT": "Step-by-step and all signatures required",
  "MIX": "Flexible and all signatures required",
  "Signature": "signature",
  "Recorder": "single record",
  "Numerical": "numerical record",
  "RecorderTitle": "recorder",
  "MultiCell": "radio button",
  "OptionList": "option list",
  "Formula": "formula",
  "Jump": "internal link",
  "Reference": "reference",
  "FileReference": "file reference",
  "CheckBox": "checkbox",
  "Date": "date",
  "Time": "time",
  "TextArea": "text area",
  "checkbox.title": "Checkbox",
  "formula.title": "Formula",
  "multicell.title": "Radio button",
  "numerical.title": "Numerical",
  "option.list.title": "Option list",
  "recorder.title": "Recorder single",
  "signature.title": "Signature",
  "textarea.title": "Text area",
  "time.title": "Time",
  "date.title": "Date",
  "completed": "Completed",
  "dont.meet.criteria": "Don't meet criteria",
  "printingError": "It's not possible to print the procedure",
  "procedureBadDigitized": "It was an error during preparing procedure to print.  This is often due to a bad ditilized process",
  "printingErrorDuringApproveReason": "It was an error during preparing procedure to print",
  "printingErrorDuringApproveAction": "The execution was not approved, since the Final Report can't be generated. \nPlease, check the procedure was properly digitized.",
  "clear.selection": "Clear selection",
  "total.elements": "Total elements",
  "total.elements.selected": "Selected",
  "delete.media.confirm.title": "Delete comment file",
  "delete.media.confirm.text": "You will delete the {name} file, but the comment will not be deleted. Are you sure?",
  "failed": "Failed",
  "media.uploading": "Uploading videos",
  "media.uploading.cancel.all": "Cancel uploads and perform action",
  "media.uploading.finish": "Completed upload",
  "media.uploading.error": "Failed upload",
  "media.uploading.offline": "No connection",
  "media.uploading.text": "There are videos uploading, touch the notification to see more details.",
  "media.uploading.warning.title": "There is media uploading",
  "media.uploading.warning.text": "Wait until the media is uploaded to the server before exit the execution",
  "media.upload.success": "Videos uploaded successfully",
  "media.upload.error": "Error uploading videos",
  "media.upload.offline": "No connection",
  "media.upload.offline.text": "You need to be connected to the internet to upload videos",
  "media.upload.cancel.title": "Cancel upload",
  "media.upload.cancel.text": "Are you sure you want to cancel the upload of the video {name}? The video will not be attached to the comment you have added.",
  "media.upload.retry.title": "Retry upload",
  "media.upload.retry.text": "There was an error uploading the video {name}. You can try again or cancel the upload. If you cancel, the video will not be attached to the comment.",
  "media.size.too.large.title": "File size too large",
  "media.size.too.large.text": "The {name} file has a size of {fileSize}MB which exceeds the maximum allowed size of {maxFileSize}MB, so it cannot be attached to the comment.",
  "modal.editField": "Edit Field",
  "media.pdf.tab.title": "Open pdf in new tab",
  "media.pdf.tab.text": "Another tab will open to view the pdf, once finished, you will have to come back to continue. Do you want to continue?",
  "error.change.procedures.status.title": "Error updating procedure status",
  "error.change.procedures.status.text": "An error has occurred changing the status of the procedures you have selected.",
  "error.change.status.same.keys.to.published": "There can only be one procedure with the same key in published status",
  "change.status.modal.text": "Selects the new state to which the selected procedures will transition to.",
  "change.status.modal.not.complatible": "The selected procedures do not have a common state to transition to.",
  "select.placeholder": "Select an option...",
  "show.details": "Show details",
  "comment.step.level": "Step comments",
  "comment.execution.level": "Execution comments",
  "retry": "Retry",
  "not.support.video.tag": "Your browser does not support this video.",
  "execution.needs.review.title": "Execution needs to be reviewed",
  "execution.needs.review.delegated.signatures.text": "The execution contains delegated signatures, so it must be reviewed before approval.",
  "user.reset.password.title": "Reset password",
  "user.reset.password.text": "You can reset a new password for users who have forgotten their password.",
  "user.reset.password.error": "A new password could not be generated for the user.",
  "user.password.title": "User password",
  "user.password.text": "Once the password is set for the first time, only the user can change it.",
  "login.forgot.password": "Forgot password?",
  "login.forgot.password.tooltip": "Contact your administrator by call or email to reset your password.",
  "abort.execution.disabled.steps.progress": "An execution with steps in progress or on hold cannot be aborted.",
  "stop.execution.disabled.steps.progress": "An execution with steps in progress or on hold cannot be finished.",
  "stop.execution.disabled.not.finished": "An execution with unsigned, in progress or on hold steps cannot be finished.",
  "stop.execution.disabled.no.execution": "There is not execution to finish",
  "stop.execution.disabled.can.not.status": "The execution cannot be changed to this state",
  "stop.execution.disabled.is.working": "The execution cannot be finished as it is saved",
  "stop.execution.disabled.media.uploading": "The execution cannot be finished until all videos are uploaded",
  "permission.route.home.title": "My work for today",
  "permission.route.home.description": "Visualize your and your team tasks due for today",
  "permission.route.procedure.title": "Procedures on device",
  "permission.route.procedure.description": "Download and visualize procedures on your device",
  "permission.route.procedure.download.title": "Download procedures",
  "permission.route.procedure.download.description": "Download available procedures",
  "permission.route.procedure.showStatuses.title": "Show procedures status",
  "permission.route.procedure.showStatuses.description": "Visualize procedures in any status",
  "permission.route.procedure.version.title": "Show procedure version",
  "permission.route.procedure.version.description": "Visualize procedure version",
  "permission.route.execution.title": "Executions on device",
  "permission.route.execution.description": "Download and perform procedure executions on your device",
  "permission.route.execution.download.title": "Download other users' executions",
  "permission.route.execution.download.description": "Download executions performed by other users",
  "permission.route.execution.keepExecutions.title": "Keep executions",
  "permission.route.execution.keepExecutions.description": "Keep local finished or aborted executions",
  "permission.route.execution.management.title": "Manage Executions",
  "permission.route.execution.management.description": "Manage different actions on finished or interrupted executions",
  "permission.route.execution.management.merge.title": "Merge",
  "permission.route.execution.management.merge.description": "Merge 2 finished or interrupted executions",
  "permission.route.execution.management.review.title": "Review",
  "permission.route.execution.management.review.description": "Review finished executions",
  "permission.route.execution.management.approve.title": "Approve",
  "permission.route.execution.management.approve.description": "Approve finished exeuctions",
  "permission.route.execution.management.view.title": "View",
  "permission.route.execution.management.view.description": "Visualize finished executions",
  "permission.route.execution.management.resume.title": "Resume",
  "permission.route.execution.management.resume.description": "Resume rejected executions",
  "permission.route.execution.management.upload.title": "Upload manual execution",
  "permission.route.execution.management.upload.description": "Upload executions performed out of PROCEED",
  "permission.route.executionHistory.title": "Executions History",
  "permission.route.executionHistory.description": "List of approved historical executions",
  "permission.route.executionHistory.view.title": "View",
  "permission.route.executionHistory.view.description": "Visualize approved execution",
  "permission.route.executionHistory.regenerate.title": "Regenerate report",
  "permission.route.executionHistory.regenerate.description": "Regenerate report for an approved execution",
  "permission.route.adminProcedures.title": "Manage Procedures",
  "permission.route.adminProcedures.description": "Manage digitalized procedures inside PROCEED and their status",
  "permission.route.adminProcedures.export.title": "Export procedures",
  "permission.route.adminProcedures.export.description": "Export procedures from the server",
  "permission.route.adminProcedures.import.title": "Import procedures",
  "permission.route.adminProcedures.import.description": "Import procedures to the server",
  "permission.route.adminProcedures.delete.title": "Delete Procedures",
  "permission.route.adminProcedures.delete.description": "Delete Procedures from the server",
  "permission.route.adminProcedures.changeStatus.title": "Change procedure state",
  "permission.route.adminProcedures.changeStatus.description": "Change the state of each procedure",
  "permission.route.controlPanel.title": "Control Panel",
  "permission.route.controlPanel.description": "Manage tool configuration, downloaded procedures and users",
  "permission.route.executionView.title": "Execution View",
  "permission.route.executionView.description": "Visualize procedure before its execution",
  "permission.route.executionView.changeParams.title": "Change execution parameters",
  "permission.route.executionView.changeParams.description": "Change an eecution carry out mode",
  "permission.route.executionView.start.title": "Start Procedure Execution",
  "permission.route.executionView.start.description": "Initiate a new execution of a procedure",
  "button.add.comment.disabled": "Add text to be able to add the comment",
  "button.add.comment.execution.disabled": "Add text and select a comment type to be able to add the comment",
  "error.execution.start": "An error occurred at the start of execution. Please try again and if the error persists report it",
  "error.execution.start.manual.delete": "An error occurred at the start of execution. Please manually delete the execution you just created and try again. If the error persists report it.",
  "error.execution.start.maybe.manual.delete": "An error has occurred in starting the execution. Please, if the execution has just been created delete it manually and try again. If the error persists report it.",
  "error.execution.save.data": "An error has occurred saving the data. Please try again and if the error persists report it or delete the problematic execution and reload the application to try again.",
  "error.cancel.merge.title": "Error canceling merge",
  "error.cancel.merge.description": "An error occurred canceling merge, please try again.",
  "print.needs.connection": "Printing requires connection",
  "config.tab.roles.permissions": "Roles and permissions",
  "config.tab.series.departments": "Series and departments",
  "config.tab.execution.goals": "Execution goals",
  "config.tab.customer.settings.unit": "Customer or Site",
  "config.series.title": "Series",
  "config.department.button": "Create department",
  "config.department.delete.popover": "Are you sure to delete this department?",
  "config.department.edit.modal.action": "Edit department",
  "config.departments.title": "Departaments",
  "config.department.title": "Departament",
  "config.plant.delete.popover": "Are you sure to delete this customer or plant?",
  "config.unit.delete.popover": "Are you sure to delete this unit?",
  "config.unit.column.name": "Unit name",
  "config.unit.column.default": "By default",
  "config.unit.column.options": "Options",
  "departments.no.serie": "No series assigned. Do you want to assign them now?",
  "series.delete.error": "An error occurred deleting the series",
  "series.delete.error.conflict.procedure": "The series could not be deleted because it is assigned to one or more procedures",
  "series.delete.success": "Success deleting the series",
  "series.create.success": "Success creating the series",
  "series.create.error": "An error occured creating the series",
  "series.edit.success": "Success editing the series",
  "series.edit.error": "An error occured editing the series",
  "series.conflict.name.error": "The series name already exists. Please choose a different one.",
  "series.no.name.error": "The series name is required",
  "department.successMsg": "Department added correctly!",
  "department.errorMsg": "An error occurred when adding the department!",
  "department.edit.successMsg": "Department edited correctly!",
  "department.edit.errorMsg": "An error occurred when editing the department!",
  "department.delete.successMsg": "Department removed correctly!",
  "department.delete.failMsg": "An error occurred when removing the Department!",
  "department.conflict.name.error": "The department name already exists. Please choose a different one.",
  "unit.add.successMsg": "Unit added correctly!",
  "unit.add.errorMsg": "An error occurred when adding the unit!",
  "unit.edit.successMsg": "Unit edited correctly!",
  "unit.edit.errorMsg": "An error occurred when editing the unit!",
  "unit.delete.successMsg": "Unit removed correctly!",
  "unit.delete.failMsg": "An error occurred when removing the Unit!",
  "unit.conflict.name.error": "The unit name already exists. Please choose a different one.",
  "unit.delete.error.conflict.procedure": "The unit is assigned to a procedure. It's not allowed to remove it.",
  "unit.delete.error.conflict.default": "It's not allowed to remove the unit by default.",
  "unit.no.name.error": "The unit name is required",
  "plant.add.successMsg": "Customer or plant added correctly!",
  "plant.add.errorMsg": "An error occurred when adding the customer or plant!",
  "plant.conflict.name.error": "The customer or plant name already exists. Please choose a different one.",
  "plant.edit.successMsg": "Customer or plant edited correctly!",
  "plant.edit.errorMsg": "An error occurred when editing the customer or plant!",
  "plant.delete.failMsg": "An error occurred when removing the customer or plant!",
  "plant.delete.sucessMsg": "Custormer or plant revomed correctly!",
  "plants.no.default.unit": "No default unit",
  "plant.no.units": "(no units)",
  "plant.add.error.conflict": "The customer or plant name already exists. Please choose a different one.",
  "plant.no.name.error": "The customer or plant name is required",
  "plant.delete.error.conflict.default": "It's not allowed to remove the plant containing the unit by default.",
  "plant.delete.error.conflict.procedure": "The customer or plant has a unit assigned to a procedure. It's not allowed to remove it.",
  "forcible.conditionals.tab.title": "Enable steps by condition",
  "forcible.conditionals.tab.text": "The following steps of the procedure are affected by a conditional. Activate or deactivate the conditional according to your needs.",
  "forcible.conditionals.step.type.title.positive": "Enabled if conditional enabled",
  "forcible.conditionals.step.type.title.negative": "Enabled if conditional disabled",
  "execution.info.tab.title": "Information",
  "conditional": "Conditional",
  "force.contional.true.title": "Conditional enabled",
  "force.contional.false.title": "Conditional disabled",
  "force.contional.null.title": "Default",
  "readout.menu.editMode.text": "Select a Readout component to edit",
  "readout.editMode.message": "Please edit the Readout fields",
  "readout.menu.lockValueMode.text": "Lock Readout values",
  "readout.menu.lockValueMode.message": "Click on the Readout components to lock or unlock",
  "readout.editedBy.text": "Edited by",
  "error.login.unknown": "An unknown error occurred while trying to log in. Please try again and if it persists, please report it.",
  "historical.title": "Historical",
  "elements.count": "elements",
  "element.count": "element",
  "historical.table.value.column": "Value",
  "historical.table.timestamp.column": "Last modification",
  "historical.table.rev.column": "Rev.",
  "save.historical.values.error": "An error occurred while saving the historical values of the procedure"
}