import React from 'react'

const ProcedureDeprecated = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.24 7.5C20.24 7.23478 20.1346 6.98043 19.9471 6.79289C19.7596 6.60536 19.5052 6.5 19.24 6.5H12.24C12.1087 6.5 11.9786 6.52587 11.8573 6.57612C11.736 6.62638 11.6257 6.70003 11.5329 6.79289C11.44 6.88575 11.3664 6.99599 11.3161 7.11732C11.2659 7.23864 11.24 7.36868 11.24 7.5C11.24 7.63132 11.2659 7.76136 11.3161 7.88268C11.3664 8.00401 11.44 8.11425 11.5329 8.20711C11.6257 8.29997 11.736 8.37362 11.8573 8.42388C11.9786 8.47413 12.1087 8.5 12.24 8.5H19.24C19.5052 8.5 19.7596 8.39464 19.9471 8.20711C20.1346 8.01957 20.24 7.76522 20.24 7.5Z'
      fill={color}
    />
    <path
      d='M23.66 3L21.24 0.580001C20.865 0.20808 20.3581 -0.000420824 19.83 6.37713e-07H8.23999C7.70956 6.37713e-07 7.20085 0.210714 6.82578 0.585787C6.4507 0.96086 6.23999 1.46957 6.23999 2V9.27C6.23999 9.3363 6.26633 9.39989 6.31321 9.44678C6.3601 9.49366 6.42369 9.52 6.48999 9.52C6.84999 9.52 7.48999 9.52 7.96999 9.6C8.00479 9.60443 8.04014 9.60117 8.07354 9.59047C8.10695 9.57976 8.1376 9.56186 8.16334 9.53802C8.18909 9.51419 8.20929 9.485 8.22253 9.45252C8.23577 9.42003 8.24173 9.38504 8.23999 9.35V2.5C8.23999 2.36739 8.29267 2.24022 8.38644 2.14645C8.48021 2.05268 8.60738 2 8.73999 2H19.62C19.7535 1.99871 19.8824 2.04884 19.98 2.14L22.12 4.26C22.2111 4.35757 22.2613 4.48648 22.26 4.62V18C22.26 18.1326 22.2073 18.2598 22.1135 18.3536C22.0198 18.4473 21.8926 18.5 21.76 18.5H14.89C14.8306 18.4972 14.7724 18.5175 14.7277 18.5567C14.6829 18.5958 14.6551 18.6508 14.65 18.71C14.5715 19.1906 14.4476 19.6627 14.28 20.12C14.2655 20.1576 14.2603 20.1982 14.2648 20.2383C14.2694 20.2783 14.2836 20.3167 14.3061 20.3501C14.3287 20.3835 14.3591 20.4109 14.3946 20.4301C14.4301 20.4492 14.4697 20.4595 14.51 20.46H22.24C22.7704 20.46 23.2791 20.2493 23.6542 19.8742C24.0293 19.4991 24.24 18.9904 24.24 18.46V4.41C24.2404 3.88186 24.0319 3.37498 23.66 3Z'
      fill={color}
    />
    <path
      d='M12.74 11C12.5086 11.0047 12.2859 11.0895 12.11 11.24C12.0642 11.2867 12.0385 11.3496 12.0385 11.415C12.0385 11.4804 12.0642 11.5433 12.11 11.59C12.5401 11.9796 12.9258 12.4156 13.26 12.89C13.2816 12.9235 13.3112 12.9511 13.3461 12.9703C13.381 12.9895 13.4202 12.9997 13.46 13H16.72C16.9852 13 17.2396 12.8946 17.4271 12.7071C17.6146 12.5196 17.72 12.2652 17.72 12C17.72 11.7348 17.6146 11.4804 17.4271 11.2929C17.2396 11.1054 16.9852 11 16.72 11H12.74Z'
      fill={color}
    />
    <path
      d='M6.74 10.7402C5.40583 10.7422 4.10216 11.1395 2.99367 11.882C1.88517 12.6244 1.02155 13.6788 0.511903 14.9117C0.00225157 16.1447 -0.130578 17.5011 0.130191 18.8095C0.39096 20.118 1.03363 21.3198 1.97703 22.2632C2.92043 23.2066 4.12225 23.8493 5.43069 24.11C6.73913 24.3708 8.0955 24.238 9.32849 23.7283C10.5615 23.2187 11.6158 22.3551 12.3582 21.2466C13.1007 20.1381 13.498 18.8344 13.5 17.5002C13.5013 16.6121 13.3274 15.7325 12.9881 14.9117C12.6488 14.091 12.151 13.3453 11.523 12.7173C10.895 12.0893 10.1492 11.5914 9.32849 11.2521C8.50774 10.9129 7.6281 10.7389 6.74 10.7402ZM6.74 22.2402C5.80054 22.2383 4.88276 21.9577 4.10273 21.4342C3.3227 20.9106 2.71545 20.1675 2.35776 19.2988C2.00007 18.43 1.90802 17.4748 2.09324 16.5538C2.27846 15.6327 2.73263 14.7873 3.39833 14.1244C4.06402 13.4615 4.91134 13.0109 5.83313 12.8296C6.75493 12.6482 7.70979 12.7443 8.57698 13.1057C9.44417 13.467 10.1847 14.0774 10.705 14.8596C11.2253 15.6418 11.502 16.5608 11.5 17.5002C11.4987 18.124 11.3745 18.7414 11.1346 19.3172C10.8947 19.893 10.5437 20.4159 10.1017 20.8561C9.65968 21.2962 9.1353 21.645 8.5585 21.8825C7.9817 22.12 7.36377 22.2416 6.74 22.2402Z'
      fill={color}
    />
    <path
      d='M8.73999 16.5H7.92999C7.90375 16.5016 7.87746 16.4975 7.85299 16.4879C7.82852 16.4782 7.80645 16.4634 7.78834 16.4443C7.77023 16.4253 7.75651 16.4025 7.74816 16.3775C7.73981 16.3526 7.73702 16.3261 7.73999 16.3V15C7.73999 14.7348 7.63463 14.4804 7.4471 14.2929C7.25956 14.1054 7.00521 14 6.73999 14C6.47477 14 6.22042 14.1054 6.03288 14.2929C5.84535 14.4804 5.73999 14.7348 5.73999 15V17.5C5.73999 17.7652 5.84535 18.0196 6.03288 18.2071C6.22042 18.3946 6.47477 18.5 6.73999 18.5H8.73999C9.00521 18.5 9.25956 18.3946 9.4471 18.2071C9.63463 18.0196 9.73999 17.7652 9.73999 17.5C9.73999 17.2348 9.63463 16.9804 9.4471 16.7929C9.25956 16.6054 9.00521 16.5 8.73999 16.5Z'
      fill={color}
    />
  </svg>
)

export default ProcedureDeprecated
