import React from 'react'

import Recharge from '../../../components/icons/recharge'
import { IconContainer } from '../../../containers/icon'

const RechargeIconButtonView = (props) => (
  <IconContainer {...props} style={{ cursor: 'pointer' }} icon={Recharge} />
)

export default RechargeIconButtonView
