export function convertAntdToApiSorterDirection(sorterDirection) {
  if (sorterDirection === 'ascend') {
    return 'ASC'
  }
  if (sorterDirection === 'descend') {
    return 'DESC'
  }
  return sorterDirection
}

export const getElipsisText = (text, characters) => {
  if (text && text.length >= characters) {
    return text.substring(0, characters - 3) + '...'
  } else if (text) {
    return text
  }
  return ''
}
