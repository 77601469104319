import { EditOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { usePermisisons } from '../../../hooks/use-permissions'
import useRoleLevel from '../../../hooks/use-role-level'
import { useTranslation } from '../../../hooks/useTranslation'
import { getUser } from '../../../store/user-management/selectors'
import { mapScreenPermissionValues } from '../../../utils/helpers/permissions'
import ResetPermissionsPopover from '../../popover/stop/reset-permissions'
import SelectPermissions from '../../select-permissions'
import Translation from '../../translations'

const PutRoleModal = ({
  open,
  closeModal,
  onConfirm,
  actionTranslation,
  elementToEdit
}) => {
  const [form] = Form.useForm()
  const roleLevelValue = Form.useWatch('level', form)
  const { name, roleLevel } = elementToEdit || {}
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [defaultPermissions, setDefaultPermissions] = useState([])
  const [roleEditingHimself, setRoleEditingHimself] = useState(false)
  const [isResetOpen, setReset] = useState(false)
  const [_open, _setOpen] = useState(false)
  const user = useSelector(getUser)

  const roleNamePlaceholder = useTranslation('roleNamePlaceholder')
  const { data: permissions = [] } = usePermisisons()
  const { data: roleLevels = [] } = useRoleLevel()

  useEffect(() => {
    /**
     * Esta doble condición que verifique a open hace falta porque Antd no termina de detectar cuando el componente
     * está desmontado y no detecta el componente form
     */
    if (open) {
      if (elementToEdit) {
        form.setFieldsValue({
          name,
          level: roleLevel.id
        })
        setSelectedPermissions(elementToEdit.roleScreenPermissions)
        setDefaultPermissions(
          roleLevels.find((rl) => rl.id === roleLevel.id)
            .defaultScreenPermissions
        )
      } else {
        setSelectedPermissions([])
        form.resetFields()
      }
    }
  }, [form, roleLevel, roleLevels, name, open, elementToEdit])

  useEffect(() => {
    setRoleEditingHimself(elementToEdit?.id === user?.role.id)
  }, [elementToEdit?.id, user?.role.id])

  // Ensure reset to Default Permissions is not open when modal is closed

  useEffect(() => {
    if (!open) {
      setReset(false)
    }
    _setOpen(open)
  }, [open])

  // Ensure reset to Default Permissions is not open when modal is closed

  useEffect(() => {
    if (!open) {
      setReset(false)
    }
    _setOpen(open)
  }, [open])

  const handleEditForm = async ({ name, level }) => {
    await onConfirm({
      ...elementToEdit,
      name,
      roleLevelId: level,
      createdAt: elementToEdit?.createdAt ?? new Date().valueOf(),
      selectedPermissions
    })
    setSelectedPermissions([])
    closeModal()
    form.resetFields()
  }

  const handleEditSelectedPermissions = (selectedPermissions) => {
    setSelectedPermissions(selectedPermissions)
  }

  const confirmSetDefaultPermissions = () => {
    const mappedDefaultScreenPermissions = mapScreenPermissionValues(
      defaultPermissions,
      elementToEdit?.roleScreenPermissions
    )
    setReset(true)
    setSelectedPermissions(mappedDefaultScreenPermissions)
    setReset(false)
  }

  const handleSetRoleLevel = (defaultScreenPermissions) => {
    const mappedDefaultScreenPermissions = mapScreenPermissionValues(
      defaultScreenPermissions,
      elementToEdit?.roleScreenPermissions
    )
    setDefaultPermissions(mappedDefaultScreenPermissions)
    if (actionTranslation === 'addRole') {
      setSelectedPermissions(mappedDefaultScreenPermissions)
    }
  }

  return (
    <Modal
      open={_open}
      title={<Translation id={actionTranslation} />}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
      closable
      width={775}
    >
      <Form layout='vertical' form={form} onFinish={handleEditForm}>
        <Form.Item
          name='name'
          label={<Translation id='roleName' />}
          required
          rules={[
            {
              required: true,
              message: <Translation id='fieldRequired' />
            }
          ]}
        >
          <Input
            placeholder={roleNamePlaceholder}
            disabled={elementToEdit?.admin || roleEditingHimself}
          />
        </Form.Item>
        <Form.Item
          name='level'
          label={<Translation id='roleLevel' />}
          required
          rules={[
            {
              required: true,
              message: <Translation id='fieldRequired' />
            }
          ]}
        >
          <Select
            showSearch
            options={roleLevels.map(
              ({
                title,
                id,
                level,
                description,
                defaultScreenPermissions
              }) => ({
                label: (
                  <>
                    <span>
                      <Translation id={title} /> - <Translation id='level' />{' '}
                      {level}
                    </span>
                    <span style={{ marginLeft: '18px', color: '#A3A3A3' }}>
                      <Translation id={description} />
                    </span>
                  </>
                ),
                name: title,
                value: id,
                defaultScreenPermissions
              })
            )}
            filterOption={(input, option) =>
              (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(_, { defaultScreenPermissions }) =>
              handleSetRoleLevel(defaultScreenPermissions)
            }
            placeholder={<Translation id='roleLevelPlaceholder' />}
          />
        </Form.Item>
        <RoleLevelHelpMessage>
          <Translation id='role.level.select.description.part1' />
          <Translation id='role.level.select.description.part2' />
        </RoleLevelHelpMessage>
        <Form.Item label={<Translation id='permissions' />}>
          <SelectPermissions
            permissions={permissions}
            selectedPermissions={selectedPermissions}
            disabledPermissions={!roleLevelValue}
            updateSelectedPermissions={handleEditSelectedPermissions}
          />
        </Form.Item>
        <ResetPermissionsContainer>
          <ResetPermissionsPopover
            isResetOpen={isResetOpen}
            isModalOpen={open}
            disabled={!roleLevelValue}
            setReset={setReset}
            confirmSetDefaultPermissions={confirmSetDefaultPermissions}
          />
        </ResetPermissionsContainer>
        <ButtonContainer>
          <Button key='submit' type='primary' htmlType='submit'>
            <EditOutlined />
            <Translation id={actionTranslation} />
          </Button>
        </ButtonContainer>
      </Form>
    </Modal>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`
const ResetPermissionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -14px;
  margin-bottom: 24px;
`
const RoleLevelHelpMessage = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: -24px;
  margin-bottom: 24px;
  color: #a3a3a3;
`
export default PutRoleModal
