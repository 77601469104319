import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TextButton from '../../../components/buttons/text'
import Pencil from '../../../components/icons/pencil'
import { editUser } from '../../../services/user/http/put'
import { getUser } from '../../../store/user-management/selectors'
import { setUser } from '../../../store/user-management/slice'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { erro } from '../../../utils/logger'
import Translation from '../../../views/translations'

import { UserEditModal } from './UserEditModal'

export const UserEditButton = ({ user, reloadData, roles }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const currentUser = useSelector(getUser)

  const handleEditUser = (editedUserData) => {
    setLoading(true)
    editUser({
      ...user,
      ...editedUserData,
      updatedAt: new Date().valueOf()
    })
      .then(({ data: user }) => {
        if (user?.id === currentUser?.id) {
          dispatch(setUser(user))
        }
        reloadData()
        setModalVisible(false)
      })
      .catch((error) => {
        erro(error)
        notificationMessage({
          type: 'error',
          message: <Translation id='error' />,
          description: <Translation id='errorUpdatingUser' />
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <TextButton
        id={`edit-user-button-${user.name}`}
        icon={<Pencil />}
        onClick={() => setModalVisible(true)}
      />
      <UserEditModal
        id='edit-user-modal'
        title={<Translation id='editUserInfo' />}
        okButtonText={<Translation id='saveChanges' />}
        visible={modalVisible}
        setVisible={setModalVisible}
        loading={loading}
        roles={roles}
        onFinish={(editedUserData) => {
          handleEditUser(editedUserData)
        }}
        initialValues={{
          ...user,
          // Contraseña de pega que se mostrará con ****** en el input
          password: 'laculpaesdeladdin'
        }}
        disabledInputs={[
          'email',
          // No permitir que el administrador se quite su propio rol
          ...(currentUser.id === user.id ? ['roleId'] : [])
        ]}
      />
    </>
  )
}
