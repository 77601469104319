import { Modal } from 'antd'

import { getTranslation } from '../../i18n/getTranslation'
import { deleteExecution } from '../../localdb/execution/delete'
import { writeExecutionsAndSetId } from '../../localdb/execution/write'
import { writeExecutionComponents } from '../../localdb/execution-component/write'
import { readProcedure } from '../../localdb/procedures/read'
import { deleteRemoteExecution } from '../../services/execution/http/delete'
import { getExecution } from '../../services/execution/http/get'
import { putSendExecution } from '../../services/execution/http/put'
import { store } from '../../store'
import {
  deleteExecutions,
  writeFullExecution
} from '../../store/execution/localDb'
import { deleteProcedures } from '../../store/procedure/localDb'
import { getUser } from '../../store/user-management/selectors'
import { EXECUTION_STATUS } from '../../utils/constants/execution'
import { isOnline } from '../../utils/helpers/connection'
import { erro } from '../../utils/logger'
import { deleteDeviceProcedures } from '../device/delete-procedures'
import { downloadFiles } from '../files/download'
import { downloadProcedure } from '../reports/download'

export async function saveExecutionLocally(execution) {
  if (execution.status !== EXECUTION_STATUS.SHARED) {
    if (execution.components) {
      await writeExecutionComponents(execution.components)
    }
    const { components, ...restExecution } = execution

    await writeExecutionsAndSetId([restExecution])
  }
}

export async function sendExecution(execution) {
  // Solo enviamos los pasos que no estén sincronizados
  const stepsToSend = execution.steps?.filter((step) => !step.synced) || []

  const executionToSend = {
    ...execution,
    steps: stepsToSend
  }

  const { data: result } = await putSendExecution([executionToSend])

  stepsToSend.forEach((step) => {
    step.synced = true
  })

  return result
}

export async function deleteLocalAndRemoteExecution(execution) {
  await deleteExecution(execution.id).catch((error) => {
    erro(`Error deleting executions: ${error}`)
  })
  await deleteRemoteExecution(execution.id).catch((error) => {
    erro(`Error removing executions: ${error}`)
  })
}

export async function downloadExecution(executionId, mergeInfo) {
  const {
    comments,
    components,
    maneuvers,
    steps,
    warnings,
    conditionals,
    ...execution
  } = await getExecution(executionId, {
    downloading: true,
    merge: mergeInfo ? true : false
  }).then((res) => res.data)

  if (!execution) {
    throw new Error('Global execution not found with ID ' + executionId)
  }

  // Read executions from local db to find procedure
  let procedure = await readProcedure(execution.procedureId)

  const downloadedProcedures = procedure
    ? { procedure }
    : await downloadProcedure(execution.procedureId)

  if (execution.status === EXECUTION_STATUS.INTERRUPTED) {
    const user = { ...getUser(store.getState()) }
    delete user.password
    execution.status = EXECUTION_STATUS.PAUSED
    execution.user = user
    execution.userId = execution.user.id
    execution.updatedAt = new Date().valueOf()
    await putSendExecution([execution])
  }

  await writeFullExecution(
    {
      comments,
      components,
      maneuvers,
      steps,
      warnings,
      execution,
      conditionals: conditionals || []
    },
    true
  )

  const executionMediaIds = [
    ...(steps?.map((step) => step.comments)?.flat() || []),
    ...comments
  ]
    .map((comment) => comment.mediaIds)
    .filter((mediaId) => !!mediaId)
    .flat()

  await downloadFiles(executionMediaIds)

  return {
    execution,
    ...downloadedProcedures
  }
}

export async function saveExecution(
  exec,
  { ignoreConnectionStatus, saveLocally = true } = {}
) {
  let execution = { ...exec }

  if (ignoreConnectionStatus || isOnline()) {
    try {
      const { data } = await putSendExecution([execution])
      execution = data[0]
    } catch (err) {
      sendExecutionError(err, execution)
    }
  }

  await deleteIfInterrumpedOrSharedOrSaveLocally(execution, saveLocally)

  return execution
}

function sendExecutionError(err, execution) {
  let modalData = {
    title: 'errorSyncingExecutionTitle',
    content: 'errorSyncingExecutionContent'
  }

  switch (err.response?.data?.message) {
    case 'PROCEDURE_NOT_FOUND':
      modalData = {
        title: 'errorSyncingExecutionProcedureNotFoundTitle',
        content: 'errorSyncingExecutionProcedureNotFoundContent',
        onOk: () => {
          deleteExecutions([execution.id]).catch((error) => {
            erro(`Error deleting executions: ${error}`)
          })
          deleteProcedures([{ id: execution.procedureId }]).catch((error) => {
            erro(`Error deleting procedures: ${error}`)
          })
          deleteDeviceProcedures([{ id: execution.procedureId }]).catch(
            (error) => {
              erro(`Error deleting device procedures: ${error}`)
            }
          )
        }
      }
      break
    case 'EXECUTION_DOWNLOADED_AFTER_LAST_DOWNLOAD':
      modalData = {
        title: 'errorSyncingExecutionDownloadedAfterLastDownloadTitle',
        content: 'errorSyncingExecutionDownloadedAfterLastDownloadContent',
        onOk: () => {
          deleteExecutions([execution.id]).catch((error) => {
            erro(`Error deleting executions: ${error}`)
          })
        }
      }
      break
  }
  Modal.error({
    ...modalData,
    title: getTranslation(modalData.title),
    content: getTranslation(modalData.content)
  })

  throw err
}

async function deleteIfInterrumpedOrSharedOrSaveLocally(
  execution,
  saveLocally
) {
  if (
    execution.status === EXECUTION_STATUS.INTERRUPTED ||
    execution.status === EXECUTION_STATUS.SHARED
  ) {
    return deleteExecutions([execution.id]).catch((error) => {
      erro(`Error deleting executions: ${error}`)
    })
  } else if (saveLocally) {
    return saveExecutionLocally(execution)
  }
}
