import { Badge } from 'antd'
import React from 'react'

import Translation from '../../../translations'

const UnitsBadge = ({ numUnits }) => {
  return (
    <span>
      {numUnits > 0 ? (
        <Badge
          className='badge-primary'
          count={
            <>
              {numUnits} <Translation id='units.lower' />
            </>
          }
        />
      ) : (
        <Translation id='plant.no.units' />
      )}{' '}
    </span>
  )
}

export default UnitsBadge
