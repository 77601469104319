import { getDatabase } from '../../localdb'
import { _writeLocalDbData } from '../../localdb/common/write'
import { TABLE_NAMES } from '../../utils/constants/localdb'

const tableName = TABLE_NAMES.EXECUTION_WARNINGS

export const readExecutionWarnings = async (executionId) => {
  const db = getDatabase()

  const warnings = await db[tableName].where({ executionId }).toArray()

  return warnings
}

export const writeExecutionWarnings = async (executionWarnings) => {
  return _writeLocalDbData({
    tableName,
    data: executionWarnings,
    action: 'PUT'
  })
}

export const deleteExecutionWarnings = async (executionIds) => {
  const db = getDatabase()

  return db[tableName].where('executionId').anyOf(executionIds).delete()
}
