export const EXECUTION_STATUS = {
  EXECUTING: 'EXECUTING',
  SHARED: 'SHARED',
  PAUSED: 'PAUSED',
  INTERRUPTED: 'INTERRUPTED',
  FINISHED: 'FINISHED',
  ABORTED: 'ABORTED'
}

export const EXECUTION_MODES = {
  SHARED: 'shared',
  REVIEW: 'review',
  APPROVE: 'approve',
  VIEW: 'view',
  MERGE: 'merge'
}

const es = EXECUTION_STATUS
export const EXECUTION_STATUS_CAN_CHANGE_TO = {
  EXECUTING: [es.PAUSED, es.INTERRUPTED, es.ABORTED, es.FINISHED],
  SHARED: [es.INTERRUPTED, es.ABORTED, es.FINISHED],
  PAUSED: [es.EXECUTING, es.SHARED, es.ABORTED, es.FINISHED],
  INTERRUPTED: [],
  FINISHED: [],
  ABORTED: []
}

export const EXECUTION_STATUS_IS_FINAL = {
  EXECUTING: false,
  SHARED: false,
  PAUSED: false,
  INTERRUPTED: false,
  FINISHED: true,
  ABORTED: true
}

export const EXECUTION_STATUS_IS_RUNNING = {
  EXECUTING: true,
  SHARED: true,
  PAUSED: false,
  INTERRUPTED: false,
  FINISHED: false,
  ABORTED: false
}

export const EXECUTION_STATUS_IS_LOCAL = {
  EXECUTING: true,
  PAUSED: true,
  SHARED: false,
  INTERRUPTED: false,
  FINISHED: false,
  ABORTED: false
}

export const EXECUTION_QA_STATUS_CAN_EDIT = {
  REVIEWED: false,
  APPROVED: false,
  REJECTED: true
}

export const EXECUTION_QA_STATUS = {
  REVIEWED: 'REVIEWED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}

export const EXECUTION_QA_ACTION = {
  REVIEW: 'review',
  APPROVE: 'approve',
  MERGE: 'merge'
}

export const EXECUTION_ROUTER_MODE = {
  NEW: 'new',
  RUNNING: 'running'
}

export const EXECUTION_EVENTS = {
  CHANGE_CURRENT_STEP: 'EXECUTION_CHANGE_CURRENT_STEP'
}

export const EXECUTION_INTERACTION_MODE = {
  READ: 'READ',
  WRITE: 'WRITE'
}

export const SHARED_EXECUTION_UPDATE_TYPES = {
  // Not from Redux
  SYNC: 'SYNC',
  JOIN_EXECUTION: 'JOIN_EXECUTION',
  LEAVE_EXECUTION: 'LEAVE_EXECUTION',
  // From Redux
  SET_CURRENT_STEP: 'execution/setCurrentStep',
  SET_STATUS: 'execution/setStatus',
  SET_STATUS_COMMENT: 'execution/setStatusComment',
  PREPARE_EXECUTION_TO_FINISH: 'execution/prepareExecutionToFinish',
  UPDATE_UPDATED_AT_EXECUTION: 'execution/updateUpdatedAtExecution',
  SET_MANEUVER_STATE: 'executionManeuvers/setManeuverState',
  MARK_STEP: 'executionSteps/markStep',
  UNMARK_STEP: 'executionSteps/unmarkStep',
  ADD_STEP_COMMENT: 'executionSteps/addStepComment',
  ADD_MEDIA_TO_STEP_COMMENT: 'executionSteps/addMediaToStepComment',
  DELETE_MEDIA_STEP_COMMENT: 'executionSteps/deleteMediaStepComment',
  DELETE_STEP_COMMENT: 'executionSteps/deleteStepComment',
  EDIT_STEP_COMMENT: 'executionSteps/editStepComment',
  ADD_EXECUTION_COMMENT: 'executionComments/addExecutionComment',
  ADD_MEDIA_TO_EXECUTION_COMMENT:
    'executionComments/addMediaToExecutionComment',
  DELETE_MEDIA_EXECUTION_COMMENT:
    'executionComments/deleteMediaExecutionComment',
  DELETE_EXECUTION_COMMENT: 'executionComments/deleteExecutionComment',
  EDIT_EXECUTION_COMMENT: 'executionComments/editExecutionComment',
  SET_COMPONENT_VALUE: 'executionComponents/setComponentValue',
  ADD_CONNECTED_USER: 'sharedExecution/addConnectedUser',
  REMOVE_CONNECTED_USER: 'sharedExecution/removeConnectedUser',
  SET_USER_CURRENT_STEP: 'sharedExecution/setUserCurrentStep',
  SET_CONDITIONAL: 'executionConditionals/setConditional'
}

export const SEQUENTIAL_MODE = {
  NONE: 'NONE',
  STRICT: 'STRICT',
  MIX: 'MIX'
}
