import React from 'react'

const Help = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7 6.99933C7 5.89467 7.896 4.99933 9.00067 5C10.1053 5 11.0007 5.896 11 7.00067C11 7.848 10.4653 8.60333 9.66667 8.886C9.26667 9.02733 9 9.40533 9 9.82933V10.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.00002 12.5013C8.86202 12.5013 8.75002 12.6133 8.75002 12.7513C8.75002 12.8893 8.86202 13.0013 9.00002 13.0013C9.13802 13.0013 9.25002 12.8893 9.25002 12.7513C9.25002 12.6133 9.13802 12.5013 9.00002 12.5013V12.5013'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 1.5C13.142 1.5 16.5 4.858 16.5 9C16.5 13.142 13.142 16.5 9 16.5C4.858 16.5 1.5 13.142 1.5 9C1.5 4.858 4.858 1.5 9 1.5Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Help
