import React from 'react'

const HistoricalIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg
    data-testid='historical-icon'
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_8016_80158)'>
      <path
        d='M8.66602 4.66406V8.33073H11.9993'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.333984 5.99219L2.33398 8.99219L4.66732 6.32552'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.66732 14.3307C9.91993 14.3307 11.1444 13.9593 12.1859 13.2634C13.2274 12.5675 14.0392 11.5783 14.5186 10.4211C14.9979 9.26379 15.1233 7.99037 14.879 6.76183C14.6346 5.53328 14.0314 4.40479 13.1457 3.51906C12.2599 2.63332 11.1314 2.03013 9.90289 1.78576C8.67434 1.54139 7.40092 1.66681 6.24366 2.14616C5.08639 2.62552 4.09726 3.43728 3.40134 4.47879C2.70543 5.5203 2.33398 6.74478 2.33398 7.9974V8.9934'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8016_80158'>
        <rect width={size} height={size} fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default HistoricalIcon
