import React from 'react'

const ControlPanelIcon = ({ size = '24', color = 'currentColor' }) => (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.5 10.25H5.5C4.24184 10.2447 3.03672 9.7426 2.14706 8.85294C1.2574 7.96328 0.755264 6.75816 0.75 5.5C0.755264 4.24184 1.2574 3.03672 2.14706 2.14706C3.03672 1.2574 4.24184 0.755264 5.5 0.75H18.5C19.7582 0.755264 20.9633 1.2574 21.8529 2.14706C22.7426 3.03672 23.2447 4.24184 23.25 5.5C23.2447 6.75816 22.7426 7.96328 21.8529 8.85294C20.9633 9.7426 19.7582 10.2447 18.5 10.25Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.75 7.5C6.85457 7.5 7.75 6.60457 7.75 5.5C7.75 4.39543 6.85457 3.5 5.75 3.5C4.64543 3.5 3.75 4.39543 3.75 5.5C3.75 6.60457 4.64543 7.5 5.75 7.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.5 23.25H18.5C19.7582 23.2447 20.9633 22.7426 21.8529 21.8529C22.7426 20.9633 23.2447 19.7582 23.25 18.5C23.2447 17.2418 22.7426 16.0367 21.8529 15.1471C20.9633 14.2574 19.7582 13.7553 18.5 13.75H5.5C4.24184 13.7553 3.03672 14.2574 2.14706 15.1471C1.2574 16.0367 0.755264 17.2418 0.75 18.5C0.755264 19.7582 1.2574 20.9633 2.14706 21.8529C3.03672 22.7426 4.24184 23.2447 5.5 23.25Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.25 20.5C19.3546 20.5 20.25 19.6046 20.25 18.5C20.25 17.3954 19.3546 16.5 18.25 16.5C17.1454 16.5 16.25 17.3954 16.25 18.5C16.25 19.6046 17.1454 20.5 18.25 20.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
)

export default ControlPanelIcon
