import { Button, Divider } from 'antd'
import isEqual from 'lodash.isequal'
import React, { useContext } from 'react'
import styled from 'styled-components'

import MessageContainer from '../../../../containers/message-container'
import StepContext from '../../../../context/step'
import useAnnotation from '../../../../hooks/use-annotation'
import { createPortalStepMenu } from '../../../../utils/helpers/dom-handlers'
import CommentButton from '../../../buttons/comment-button'
import HistoricalButton from '../../../buttons/historical'
import ReadoutActionsMenu from '../../../readout-actions-menu'
import Translation from '../../../translations'

import Annotation from './annotations'
import AnnotationButton from './annotations/button'
import ManeuverButton from './maneuvers/button'


const StepMenuPortal = React.memo(
  (props) => {
    const stepId = props.stepId
    return createPortalStepMenu(<StepMenuContent {...props} />, stepId)
  },
  (oldProps, newProps) => {
    return isEqual(
      removePropsThatWontChange(oldProps),
      removePropsThatWontChange(newProps)
    )
  }
)

const StepMenuContent = ({
  stepId,
  commentCount,
  maneuverId,
  isManeuverActive,
  isManeuverParent,
  procedure,
  annotation,
  isCurrent,
  isExecutionShared,
  isExecutionRunning,
  hasReadouts,
  hasHistorical
}) => {
  const {
    isExpanded,
    isAnnotationVisible,
    annotationRef,
    onChangeAnnotation,
    setIsAnnotationVisible,
    setIsExpanded
  } = useAnnotation({
    annotation,
    stepId,
    isExecutionShared,
    isCurrentStep: isCurrent,
    isExecutionRunning
  })

  const {
    isReadoutEditMode,
    setReadoutEdit,
    isReadoutLockMode,
    setReadoutLock
  } = useContext(StepContext)

  return (
    <StepMenuContainer>
      <div>
        {hasReadouts &&
          isCurrent &&
          (isReadoutEditMode ? (
            <MessageContainer type='info' mode='light'>
              <Translation id='readout.editMode.message' />
              <Button
                type='primary'
                onClick={() => setReadoutEdit(false)}
                style={{ marginLeft: '8px' }}
              >
                <Translation id='close' />
              </Button>
            </MessageContainer>
          ) : (
            isReadoutLockMode && (
              <MessageContainer type='info' mode='light'>
                <Translation id='readout.menu.lockValueMode.message' />
                <Button
                  type='primary'
                  onClick={() => setReadoutLock(false)}
                  style={{ marginLeft: '8px' }}
                >
                  <Translation id='close' />
                </Button>
              </MessageContainer>
            )
          ))}
      </div>
      <StepButtonsContainer>
        <CommentButton
          stepId={stepId}
          isCurrent={isCurrent}
          procedure={procedure}
          commentCount={commentCount}
        />
        <ManeuverButton
          maneuverId={maneuverId}
          isManeuverActive={isManeuverActive}
          visible={isManeuverParent && isCurrent}
          isExecutionRunning={isExecutionRunning}
        />
        <AnnotationButton
          isAnnotationVisible={isAnnotationVisible}
          setIsAnnotationVisible={setIsAnnotationVisible}
          stepIsCurrent={isCurrent}
          isExecutionShared={isExecutionShared}
          isExecutionRunning={isExecutionRunning}
          annotation={annotation}
          annotationRef={annotationRef}
        />
        {(hasHistorical || hasReadouts) && isCurrent && (
          <ButtonsDivider type='vertical' />
        )}
        {hasHistorical && isCurrent && <HistoricalButton stepId={stepId} />}
        {hasReadouts && isCurrent && (
          <ReadoutActionsMenu
            setEditReadout={setReadoutEdit}
            setLockReadout={setReadoutLock}
          />
        )}
      </StepButtonsContainer>
      <Annotation
        annotation={annotation}
        isCurrent={isCurrent}
        isExecutionRunning={isExecutionRunning}
        isExecutionShared={isExecutionShared}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isAnnotationVisible={isAnnotationVisible}
        onChangeAnnotation={onChangeAnnotation}
        annotationRef={annotationRef}
      />
    </StepMenuContainer>
  )
}

function removePropsThatWontChange(props) {
  const { procedure, ...changingProps } = props

  return changingProps
}

const StepMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StepButtonsContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

const ButtonsDivider = styled(Divider)`
  margin: 0;
`

export default StepMenuPortal
