import { getTranslation } from '../../../i18n/getTranslation'
import { deletePlant } from '../../../localdb/units/plants/delete'
import { deleteCustomerSettings } from '../../../services/customer-settings'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { notifyError } from '../../../utils/notifications'

export const removePlant = async (plantId, reload) => {
  try {
    await deleteCustomerSettings(plantId)
    await deletePlant(plantId)
    notificationMessage({
      message: getTranslation('plant.delete.sucessMsg')
    })

    reload()
  } catch (error) {
    notifyError(error)
  }
}
