import { v4 as uuidv4 } from 'uuid'

import { putSendExecution } from '../../../services/execution/http/put'
import { store } from '../../../store'
import { createExecution } from '../../../store/execution/actions'
import { deleteExecutions } from '../../../store/execution/localDb'
import { setExecution } from '../../../store/execution/slice'
import { saveAllExecutionStore } from '../../../store/middleware/executionSaveMiddleware'
import { getUser } from '../../../store/user-management/selectors'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { NOTIFICATION_TYPES } from '../../../utils/constants/notification'
import { erro } from '../../../utils/logger'
import { sendExecutionComments } from '../../execution-comments/send'
import { sendExecutionComponents } from '../../execution-components/send'
import { sendExecutionConditionals } from '../../execution-conditionals/send'
import { sendExecutionManeuvers } from '../../execution-maneuvers/send'
import { sendExecutionSteps } from '../../execution-steps/send'
import { sendExecutionWarnings } from '../../execution-warnings/send'

export async function playExecution({
  execution,
  status,
  procedure,
  executionComponents,
  executionSteps,
  executionManeuvers,
  executionWarnings,
  executionComments,
  executionConditionals,
  executionGoalId,
  otherExecutionGoal,
  sequentialMode,
  simpleApprobation,
  unitId,
  procedureSequentialMode,
  user
}) {
  const executionId = uuidv4().toUpperCase()
  const isNew = !execution
  const { id: userId } = getUser(store.getState())

  let finalSequentialMode = sequentialMode
  if (finalSequentialMode === undefined) {
    finalSequentialMode = execution?.sequentialMode || procedureSequentialMode
  }

  const now = new Date().valueOf()

  const executionToSave = !isNew
    ? {
        ...execution,
        user: execution.user ?? user,
        status: status,
        executionGoalId: executionGoalId !== '' ? executionGoalId : null,
        otherExecutionGoal:
          otherExecutionGoal !== '' ? otherExecutionGoal : null,
        sequentialMode: finalSequentialMode,
        unitId: unitId !== '' ? unitId : null,
        simpleApprobation,
        updatedAt: now
      }
    : {
        id: executionId,
        procedureId: procedure.id,
        status: status,
        progress: 0,
        userId,
        executionGoalId: executionGoalId !== '' ? executionGoalId : null,
        otherExecutionGoal: otherExecutionGoal,
        sequentialMode: finalSequentialMode,
        unitId: unitId !== '' ? unitId : null,
        simpleApprobation,
        createdAt: now,
        updatedAt: now,
        user
      }

  if (isNew) {
    const { components, steps, maneuvers, warnings, conditionals } =
      generateNewExecutionParts(executionId, procedure)
    await startExecution({
      status,
      executionToSave,
      components,
      steps,
      maneuvers,
      warnings,
      conditionals
    })
  } else {
    await startExecution({
      status,
      executionToSave,
      components: executionComponents,
      steps: executionSteps,
      maneuvers: executionManeuvers,
      warnings: executionWarnings,
      comments: executionComments,
      conditionals: executionConditionals
    })
  }
  // TODO Con el refactor de las rutas y del efecto en useExecution no debería hacer falta cargar aquí el redux

  store.dispatch(setExecution({ execution: executionToSave }))
  return executionToSave.id
}

async function startExecution({
  status,
  executionToSave,
  components,
  steps,
  maneuvers,
  warnings,
  conditionals,
  comments = []
}) {
  await saveAllExecutionStore(store)
  if (status === EXECUTION_STATUS.SHARED) {
    await putSendExecution([executionToSave])
    await sendExecutionComponents(components)
    await sendExecutionSteps(steps)
    await sendExecutionManeuvers(maneuvers)
    await sendExecutionWarnings(warnings)
    await sendExecutionComments(comments)
    await sendExecutionConditionals(conditionals)
    await deleteExecutions([executionToSave.id]).catch((error) => {
      erro(`Error deleting executions: ${error}`)
    })
  } else {
    store.dispatch(
      createExecution({
        execution: executionToSave,
        components,
        steps,
        maneuvers,
        warnings,
        comments,
        conditionals
      })
    )
  }
}

function generateNewExecutionParts(executionId, procedure) {
  const now = new Date().valueOf()

  const components = procedure.components.map((pc) => ({
    id: uuidv4().toUpperCase(),
    executionId: executionId,
    procedureComponentId: pc.id,
    createdAt: now
  }))

  const steps = procedure.steps.map((s) => ({
    id: uuidv4().toUpperCase(),
    executionId,
    stepId: s.stepId,
    comments: [],
    synced: false,
    createdAt: now
  }))

  const maneuvers = procedure.maneuvers.map((m) => ({
    id: uuidv4().toUpperCase(),
    executionId: executionId,
    active: false,
    stepId: m.parentStep,
    createdAt: now
  }))

  const warnings = procedure.notifications
    .filter((n) => n.type === NOTIFICATION_TYPES.WARNING)
    .map((w) => {
      return {
        id: uuidv4().toUpperCase(),
        notificationId: w.id,
        executionId,
        isRead: false,
        createdAt: now
      }
    })

  const conditionals = procedure.conditionals.map((c) => ({
    id: uuidv4().toUpperCase(),
    executionId,
    conditionalId: c.id,
    forcedValue: null,
    createdAt: now,
    procedureConditional: c
  }))

  return { components, steps, maneuvers, warnings, conditionals }
}
