import React from 'react'

const CloseFilled = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 8C0 3.582 3.582 0 8 0C12.416 0.00533333 15.9947 3.584 16 8C16 12.418 12.418 16 8 16C3.582 16 0 12.418 0 8ZM11.6767 11.66C11.932 11.3953 11.9247 10.9733 11.66 10.7173L9.05933 8.118L9.05867 8.11733C8.994 8.05267 8.99467 7.94733 9.05933 7.88267L11.6593 5.28267C11.6733 5.27 11.686 5.25667 11.6987 5.24333C11.948 4.97267 11.9307 4.55067 11.66 4.30133C11.3893 4.052 10.9673 4.06933 10.718 4.34L8.118 6.94C8.05267 7.00533 7.94733 7.00533 7.882 6.94L5.282 4.34C5.022 4.07933 4.6 4.07933 4.33933 4.33933C4.07867 4.59933 4.07867 5.02133 4.33867 5.282L6.93867 7.882L6.93933 7.88267C7.004 7.94733 7.00333 8.05267 6.93867 8.11733L4.33867 10.7173C4.07867 10.9773 4.07867 11.4 4.33867 11.66C4.60067 11.916 5.01933 11.916 5.28133 11.66L7.88133 9.06C7.94667 8.99533 8.052 8.99533 8.11733 9.06L10.7173 11.66C10.7209 11.664 10.7248 11.6677 10.7286 11.6714C10.7304 11.6732 10.7322 11.6749 10.734 11.6767C10.9987 11.932 11.4207 11.9247 11.6767 11.66Z'
      fill={color}
    />
  </svg>
)

export default CloseFilled
