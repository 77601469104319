import { Select } from 'antd'
import React, { useState } from 'react'
import { useDebounce } from 'react-use'

import { useGlobalSeries } from '../../../hooks/metadata/useGlobalSeries'

const SelectGlobalSeries = ({ value, onChange, id }) => {
  const [search, setSearch] = useState()
  const [debouncedSearch, setDebouncedSearch] = useState()
  const { data: series, isValidating: loadingSeries } = useGlobalSeries({
    disabled: !debouncedSearch,
    search: debouncedSearch
  })

  useDebounce(() => setDebouncedSearch(search), 300, [search])

  return (
    <Select
      id={id}
      showSearch
      value={value || []}
      onChange={(value) => {
        setSearch(null)
        setDebouncedSearch(null)
        onChange(value)
      }}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      mode='multiple'
      allowClear
      onSearch={setSearch}
      loading={loadingSeries}
      options={series?.map((serie) => ({
        label: serie.name,
        value: serie.name
      }))}
    />
  )
}

export default SelectGlobalSeries
