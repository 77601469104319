import React from 'react'

import Modal, { showComponentModal } from '..'
import Eye from '../../../components/icons/eye'
import Pencil from '../../../components/icons/pencil'
import { history } from '../../../utils/constants/browser-history'
import { EXECUTION_INTERACTION_MODE } from '../../../utils/constants/execution'
import Translation from '../../translations'

const JoinSharedExecutionModal = ({ executionId, visible, onClose }) => {
  const onOk = () => {
    history.push(`/shared/${executionId}`)
    onClose()
  }

  const onCancel = () => {
    history.push(`/view/${executionId}`)
    onClose()
  }

  return (
    <Modal
      visible={visible}
      title={<Translation id='joinSharedExecution' />}
      okButtonText={
        <Translation id={`executionMode${EXECUTION_INTERACTION_MODE.WRITE}`} />
      }
      okButtonIcon={<Pencil />}
      cancelButtonText={
        <Translation id={`executionMode${EXECUTION_INTERACTION_MODE.READ}`} />
      }
      cancelButtonIcon={<Eye />}
      onOk={onOk}
      onCancel={onCancel}
      onClose={onClose}
      cancellable
    >
      <Translation id='chooseExecutionMode' />
    </Modal>
  )
}

export function showJoinSharedExecutionModal(executionId) {
  showComponentModal(JoinSharedExecutionModal, { executionId })
}
