import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import ExecutionViewFlex from '../../../../containers/execution-view/flex'
import { getExecution } from '../../../../store/execution/selectors'
import Translation from '../../../translations'
import NoContent from '../../no-content'
import FooterSignature from '../signature'


const ApproveContent = () => {
  const execution = useSelector(getExecution)

  if (!execution) {
    return null
  }

  const {
    approvedBy,
    approvedAt,
    revisedBy,
    revisedAt,
    finishedAt,
    finishedBy
  } = execution

  return (
    <>
      <ExecutionViewFlex id='finished-section'>
        <Title>
          <Translation id='done' />
        </Title>
        <FooterSignature
          id='finished-by'
          name={finishedBy?.name}
          date={finishedAt}
        />
      </ExecutionViewFlex>
      <ExecutionViewFlex id='reviewed-section'>
        <Title>
          <Translation id='reviewed' />
        </Title>
        {revisedBy && revisedAt ? (
          <FooterSignature
            id='reviewed-by'
            name={revisedBy.name}
            date={revisedAt}
          />
        ) : (
          <NoContent />
        )}
      </ExecutionViewFlex>
      <ExecutionViewFlex id='approved-section'>
        <Title>
          <Translation id='approved' />
        </Title>
        {approvedBy && approvedAt ? (
          <FooterSignature
            id='approved-by'
            name={approvedBy.name}
            date={approvedAt}
          />
        ) : (
          <NoContent />
        )}
      </ExecutionViewFlex>
    </>
  )
}

const Title = styled.h4`
  padding: 3px 0;
  background: #e1e1db;
  border-radius: 8px 8px 0px 0px;
  font-weight: bold;
  width: 100%;
  margin: 0;
  text-align: center;
  font-family: inherit;
`

export default ApproveContent
