import moment from 'moment'
import React from 'react'

import { useTranslation } from '../../../hooks/useTranslation'

const LocalizedMoment = ({ date }) => {
  const dateFormat = useTranslation('dateTimeFormat')

  return <span>{date.isValid() ? moment(date).format(dateFormat) : '-'}</span>
}

export default LocalizedMoment
