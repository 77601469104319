import { Input } from 'antd'
import styled from 'styled-components'

const { TextArea } = Input

const CommentArea = styled(TextArea)`
  margin-bottom: 0;
  width: initial;
`

export default CommentArea
