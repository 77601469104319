import React, { useState } from 'react'

import { downloadExecution } from '../../../application/execution'
import Download from '../../../components/icons/download'
import Play from '../../../components/icons/play'
import { writeSyncStatus } from '../../../localdb/execution-sync-status/write'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { PERMISSION_TYPES } from '../../../utils/constants/roles'
import { handleDownloadExecutionError } from '../../../utils/errors'
import { confirm } from '../../../utils/helpers/dialogs'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { userHasPermission } from '../../../utils/helpers/permissions'
import DownloadButton from '../../../views/buttons/download-button'
import { showJoinSharedExecutionModal } from '../../../views/modal/join-shared-execution-modal'
import DownloadNotificationContent from '../../../views/notifications/download'
import Translation from '../../../views/translations'

export const DownloadExecutionButton = ({
  selectedExecution,
  localExecutions,
  loading,
  currentUser
}) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const isDownloaded = localExecutions?.some(
    (i) => i.id === selectedExecution.id
  )

  const onDownloadExecution = async (ev) => {
    ev.stopPropagation()
    try {
      if (selectedExecution.status === EXECUTION_STATUS.SHARED) {
        showJoinSharedExecutionModal(selectedExecution.id)
      } else {
        if (selectedExecution.status === EXECUTION_STATUS.EXECUTING) {
          const confirmationDownload = await confirm({
            title: <Translation id={'confirmationDownloadExecutions'} />
          })
          if (!confirmationDownload) return
        }
        setIsDownloading(true)
        const { execution } = await downloadExecution(
          selectedExecution.id,
          false
        )
        if (execution) {
          await writeSyncStatus(execution.id)
          notificationMessage({
            message: <Translation id='downloadComplete' />,
            description: <DownloadNotificationContent execution={execution} />
          })
        }
      }
    } catch (error) {
      handleDownloadExecutionError(error)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <DownloadButton
      id={`download-${selectedExecution.id}`}
      disabled={
        isDownloaded ||
        (selectedExecution.status !== EXECUTION_STATUS.SHARED &&
          selectedExecution.user?.id !== currentUser.id &&
          !userHasPermission(PERMISSION_TYPES.EXECUTION_DOWNLOAD_OTHER_USER))
      }
      loading={isDownloading || loading}
      icon={
        selectedExecution.status === EXECUTION_STATUS.SHARED ? (
          <Play />
        ) : (
          <Download />
        )
      }
      onClick={onDownloadExecution}
    ></DownloadButton>
  )
}
