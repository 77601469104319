import { notification } from 'antd'
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { useSelector, Provider, shallowEqual } from 'react-redux'

import { store } from '../store'
import { getMediaUploading } from '../store/media-uploading/selectors'
import { clearMediaUploading } from '../store/media-uploading/slice'
import { getConnectionStatus } from '../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../utils/constants/connection'
import { notificationMessage } from '../utils/helpers/notification-message'
import MediaUploadFinalNotification from '../views/media-upload-final-notification'
import MediaUploadNotification from '../views/media-upload-notification'
import Translation from '../views/translations'

const NOTIFICATION_KEY = 'media-uploading-notification'
const NOTIFICATION_KEY_SUCCESS = 'media-uploading-notification-success'
const NOTIFICATION_KEY_ERROR = 'media-uploading-notification-error'

function useUploadVideoNotification() {
  const [isNotificated, setIsNotificated] = useState(false)

  const connectionStatus = useSelector(getConnectionStatus)
  const mediaUploading = useSelector(getMediaUploading, shallowEqual)

  const mediaUploadComplete = useMemo(
    () =>
      mediaUploading.filter(({ completed, error }) => completed && !error)
        .length,
    [mediaUploading]
  )
  const mediaWithError = useMemo(
    () =>
      mediaUploading.filter(({ error, cancelByUser }) => error && !cancelByUser)
        .length,
    [mediaUploading]
  )

  const offline = connectionStatus !== CONNECTION_STATUS.ONLINE
  const timeout = useRef()

  const onSetNotNotificated = useCallback(() => {
    store.dispatch(clearMediaUploading())
    setIsNotificated(false)
  }, [])

  const closeTimeout = () => {
    timeout.current = setTimeout(() => {
      notification.close(NOTIFICATION_KEY)
      setIsNotificated(false)
    }, 2000)
  }

  const clearAll = () => {
    clearTimeout(timeout.current)

    notification.close(NOTIFICATION_KEY_SUCCESS)
    notification.close(NOTIFICATION_KEY_ERROR)
    notification.close(NOTIFICATION_KEY)
  }

  useEffect(() => {
    return () => {
      clearAll()
      store.dispatch(clearMediaUploading())
    }
  }, [])

  useEffect(() => {
    if (mediaUploading.length === 0) {
      setIsNotificated(false)
      clearAll()
    }

    if (isNotificated) return

    if (
      mediaUploading.length > 0 &&
      mediaUploadComplete !== mediaUploading &&
      mediaWithError + mediaUploadComplete !== mediaUploading.length &&
      !offline
    ) {
      clearAll()
      notificationMessage({
        type: 'open',
        message: (
          <Provider store={store}>
            <MediaUploadNotification showProgress />
          </Provider>
        ),
        placement: 'bottomRight',
        duration: null,
        key: NOTIFICATION_KEY,
        closeIcon: <></>
      })

      setIsNotificated(true)
    }
  }, [
    mediaUploading,
    isNotificated,
    mediaUploadComplete,
    mediaWithError,
    offline
  ])

  useEffect(() => {
    if (
      mediaUploading.length > 0 &&
      mediaUploadComplete === mediaUploading.length &&
      !mediaWithError &&
      !offline
    ) {
      closeTimeout()

      notificationMessage({
        type: 'success',
        message: <Translation id='media.upload.success' />,
        description: (
          <Provider store={store}>
            <MediaUploadFinalNotification />
          </Provider>
        ),
        placement: 'bottomRight',
        duration: null,
        onClose: onSetNotNotificated,
        key: NOTIFICATION_KEY_SUCCESS
      })

      return
    }

    if (
      (mediaUploading.length > 0 &&
        mediaUploadComplete + mediaWithError === mediaUploading.length) ||
      (mediaUploading.length > 0 && offline)
    ) {
      closeTimeout()

      notificationMessage({
        type: 'error',
        message: (
          <Translation id={`media.upload.${offline ? 'offline' : 'error'}`} />
        ),
        description: (
          <Provider store={store}>
            <MediaUploadFinalNotification />
          </Provider>
        ),
        placement: 'bottomRight',
        duration: null,
        onClose: onSetNotNotificated,
        key: NOTIFICATION_KEY_ERROR
      })
    }
  }, [
    mediaUploadComplete,
    mediaWithError,
    mediaUploading,
    offline,
    onSetNotNotificated
  ])
}

export default useUploadVideoNotification
