import { useRemoteData } from '../useRemoteData'

export const useGlobalProcedures = ({
  disabled,
  select,
  skip,
  take,
  orderBy,
  orderDirection,
  modifiedBefore,
  modifiedAfter,
  unit,
  key,
  series,
  status,
  id,
  title
} = {}) => {
  return useRemoteData({
    url: '/procedures',
    disabled,
    select,
    skip,
    take,
    orderBy: orderBy || 'updatedAt',
    orderDirection: orderDirection || 'ascend',
    unit,
    key,
    serie: series,
    status,
    id,
    title,
    modifiedBefore: modifiedBefore?.toISOString(),
    modifiedAfter: modifiedAfter?.toISOString()
  })
}
