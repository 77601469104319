import { v4 as uuidv4 } from 'uuid'

import { EXECUTION_STATUS } from '../../utils/constants/execution'
import { TABLE_NAMES } from '../../utils/constants/localdb'
import { formatStepCommentOnReview } from '../../utils/helpers/comments/step-comments'
import { erro } from '../../utils/logger'
import { addStepCommentAction } from '../executionSteps/actions'
import { filterByUpdatedAt } from '../helpers'
import { setModuleIsNotSyncing } from '../userInterface/slice'

import { readExecutionComments, writeExecutionComments } from './localDb'
import {
  addExecutionComment,
  setExecutionComments,
  updateLocaldbQueue,
  deleteExecutionComment,
  editExecutionComment,
  setSyncedAt,
  addMediaToExecutionComment,
  deleteMediaExecutionComment
} from './slice'

export function saveExecutionComments(comments) {
  return async (dispatch) => {
    dispatch(setExecutionComments({ comments }))
    dispatch(updateLocaldbQueue({ comment: comments, resetQueue: true }))
  }
}

export function loadExecutionComments(executionId) {
  return async (dispatch) => {
    if (!executionId) {
      dispatch(setExecutionComments({ comments: [] }))
    } else {
      const executionComments = await readExecutionComments(executionId)
      dispatch(setExecutionComments({ comments: executionComments }))
    }
  }
}

export function setReviewComment({ stepId, oldValue, newValue, type, user }) {
  return async (dispatch, getState) => {
    const state = getState()

    const executionStep = state.executionSteps?.steps?.find(
      (step) => step.stepId === stepId
    )

    const newTextComment = formatStepCommentOnReview(
      oldValue,
      newValue,
      type,
      user
    )

    const newComment = {
      id: uuidv4().toUpperCase(),
      user: user,
      comment: newTextComment,
      stepId: executionStep.id,
      bookmark: executionStep.stepId,
      createdAt: new Date().valueOf(),
      updatedAt: new Date().valueOf(),
      isReviewStepComment: true
    }

    dispatch(addStepCommentAction({ stepId, newComment }))
  }
}

export function createExecutionComment({ comment }) {
  return async (dispatch, getState) => {
    const { execution } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    const newComment = {
      ...comment,
      createdAt: new Date().valueOf()
    }

    if (!isShared) dispatch(updateLocaldbQueue({ comment: newComment }))

    dispatch(addExecutionComment({ comment: newComment }))
  }
}

export function addMediaToExecutionCommentAction({ commentId, mediaId }) {
  return async (dispatch, getState) => {
    const { execution, executionComments } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()

    if (!isShared) {
      const comment = executionComments.comments?.find(
        (comment) => comment.id === commentId
      )
      const commentUpdated = {
        ...comment,
        mediaIds: [...comment.mediaIds.filter((m) => m !== mediaId), mediaId],
        updatedAt: now
      }
      dispatch(updateLocaldbQueue({ comment: commentUpdated }))
    }

    dispatch(addMediaToExecutionComment({ commentId, mediaId, now }))
  }
}

export function deleteMediaFromExecutionCommentAction({ commentId, mediaId }) {
  return (dispatch, getState) => {
    const { execution, executionComments } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()

    if (!isShared) {
      const comment = executionComments.comments?.find(
        (comment) => comment.id === commentId
      )
      const commentUpdated = {
        ...comment,
        mediaIds: comment.mediaIds.filter((m) => m !== mediaId),
        updatedAt: now
      }
      dispatch(updateLocaldbQueue({ comment: commentUpdated }))
    }
    dispatch(deleteMediaExecutionComment({ commentId, mediaId, now }))
  }
}

export function removeExecutionComment({ commentId }) {
  return async (dispatch, getState) => {
    const { execution, executionComments } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    const deletedAt = new Date().valueOf()

    if (!isShared) {
      const comment = executionComments.comments?.find(
        (c) => c.id === commentId
      )

      if (!comment) return

      const commentDeleted = {
        ...comment,
        deletedAt
      }
      dispatch(updateLocaldbQueue({ comment: commentDeleted }))
    }

    dispatch(deleteExecutionComment({ commentId, deletedAt }))
  }
}

export function editExecutionCommentAction({ comment }) {
  return async (dispatch, getState) => {
    const { execution } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    const commentUpdated = {
      ...comment,
      updatedAt: new Date().valueOf()
    }

    if (!isShared) dispatch(updateLocaldbQueue({ comment: commentUpdated }))

    dispatch(editExecutionComment({ comment: commentUpdated }))
  }
}

export function setSyncDate({ entities, syncedAt }) {
  return async (dispatch, getState) => {
    const {
      execution,
      executionComments: { comments }
    } = getState()

    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    const entitiesNotInState = entities.filter(
      (entity) => entity.executionId !== execution.execution?.id
    )

    if (entitiesNotInState.length > 0) {
      try {
        await writeExecutionComments(
          entitiesNotInState.map((entity) => ({ ...entity, syncedAt }))
        )
      } catch (error) {
        erro('Error writing execution comments', error)
      }
    }

    const syncedComments = filterByUpdatedAt(entities, comments)

    if (syncedComments?.length) {
      try {
        if (!isShared) {
          const commentsToQueue = syncedComments.map((_comment) => ({
            ..._comment,
            syncedAt
          }))

          dispatch(updateLocaldbQueue({ comment: commentsToQueue }))
        }

        dispatch(setSyncedAt({ entities: syncedComments, syncedAt }))
      } catch (error) {
        erro('Error writing execution comments', error)
      }
    } else {
      dispatch(
        setModuleIsNotSyncing({ moduleName: TABLE_NAMES.EXECUTION_COMMENTS })
      )
    }
  }
}
