import styled from 'styled-components'

const CommentAuthor = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  & span {
    margin-right: 5px;
    margin-bottom: 4px;
    font-size: 19px;
    font-weight: 600;
    line-height: 22px;
    color: black;
  }
`

export default CommentAuthor
