import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.FILES_CONTENT

export const readFilesContent = async (executionsIds) => {
  const db = getDatabase()

  const table = db[tableName]
  return executionsIds
    ? await table.where('id').anyOf(executionsIds).toArray()
    : await table.toArray()
}

export async function loadFilesOfExecutionCommments(executionComments) {
  if (!executionComments) return []

  const allMediaIds = [...executionComments]
    .map((comment) => comment.mediaIds)
    .flat()

  if (allMediaIds.length === 0) return []

  const allMedias = await readFilesContent(allMediaIds)
  const allMediasData = allMedias.map((media) => media.data)

  return allMediasData
}

export async function loadFilesOfStepComments(executionSteps) {
  if (!executionSteps) return []

  const steptsWithComments = [
    ...(executionSteps.map((step) => step.comments)?.flat() || [])
  ]

  let allMediaIds = []

  steptsWithComments.forEach((comment) => {
    if (comment.mediaIds && comment.mediaIds.length > 0) {
      allMediaIds.push(comment.mediaIds)
    }
  })

  if (allMediaIds.length === 0) return []

  const allMedias = await readFilesContent(allMediaIds.flat())
  const allMediasData = allMedias.map((media) => media.data)
  return allMediasData
}
