import React from 'react'

const Stop = ({ size = 24, color = 'currentColor', fillColor }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 17 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.5 3H3.5C2.94772 3 2.5 3.44772 2.5 4V13C2.5 13.5523 2.94772 14 3.5 14H12.5C13.0523 14 13.5 13.5523 13.5 13V4C13.5 3.44772 13.0523 3 12.5 3Z'
      fill={fillColor}
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Stop
