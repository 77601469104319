import { getTranslation } from '../../../../i18n/getTranslation'

export function uncommentSectionTags(doc) {
  // OPEN SECTION TAGS : Van en el inicio de cada sección, para delimitar el <div class ="WordSection1">
  const openCommentBeginSectionTag = new RegExp(
    /<!--OPEN_COMMENT_BEGIN_SECTION/,
    'g'
  )
  doc = doc.replaceAll(openCommentBeginSectionTag, '')

  const closeCommentBeginSectionTag = new RegExp(
    /CLOSE_COMMENT_BEGIN_SECTION-->/,
    'g'
  )
  doc = doc.replaceAll(closeCommentBeginSectionTag, '')

  // CLOSE SECTION TAGS: van en el cierre del div de la sección

  const openCommentEndSectionTag = new RegExp(
    /<!--OPEN_COMMENT_END_SECTION/,
    'g'
  )
  doc = doc.replaceAll(openCommentEndSectionTag, '<!--close the section-->')

  const closeCommentEndSectionTag = new RegExp(
    /CLOSE_COMMENT_END_SECTION-->/,
    'g'
  )
  doc = doc.replaceAll(closeCommentEndSectionTag, '')

  return doc
}

export function createCommentTag(DOMNode, tagType) {
  const commentTypeNode = document.createElement('span')
  commentTypeNode.className = 'step-comment-tag'

  commentTypeNode.classList.add(tagType)
  commentTypeNode.textContent = getTranslation(tagType)
  DOMNode.appendChild(commentTypeNode)
}
