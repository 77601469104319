import format from 'string-template'

import en_US from '../../languages/en_US.json'
import es_ES from '../../languages/es_ES.json'
import { UI_LANGUAGE } from '../utils/constants/config'
import { getUserLocalStorageItem } from '../utils/helpers/user'

export const languages = { 'en-US': en_US, 'es-ES': es_ES }

export const getTranslation = (id, { lang, params } = {}) => {
  const language = lang || getUserLocalStorageItem('language') || UI_LANGUAGE
  const languageStrings = languages[language] || languages['en-US']

  const translation = languageStrings[id]

  if (!id) {
    return ''
  }

  if (params) {
    return format(translation, params)
  }

  return translation || `_${id}_`
}
