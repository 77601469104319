import { createSlice } from '@reduxjs/toolkit'
import { set } from 'lodash'

import { CONNECTION_STATUS } from '../../../utils/constants/connection'

export const userInterfaceSlice = createSlice({
  name: 'userInterface',
  initialState: {
    isWorking: false,
    currentDrawer: null,
    isNonCompliantComment: false,
    currentComponentType: null,
    loadingExecution: false,
    syncingModules: {},
    executionJumpsWithReturn: {},
    currentStepDrawerComments: null,
    headerTitleInfo: null,
    downloadingProcedureIds: [],
    connectionStatus: CONNECTION_STATUS.CONNECTING
  },
  reducers: {
    setIsWorking: (state, action) => {
      state.isWorking = action.payload
    },
    setLoadingExecution: (state, action) => {
      state.loadingExecution = action.payload
    },
    setModuleIsSyncing: (state, action) => {
      const { moduleName } = action.payload

      set(state.syncingModules, moduleName, true)
    },
    setModuleIsNotSyncing: (state, action) => {
      const { moduleName } = action.payload

      delete state.syncingModules[moduleName]
    },
    setCurrentDrawer: (state, action) => {
      state.currentDrawer = action.payload
    },
    setIsNonCompliantComment: (state, action) => {
      state.isNonCompliantComment = action.payload
    },
    setCurrentComponentType: (state, action) => {
      state.currentComponentType = action.payload
    },
    setExecutionJumpsWithReturnByExecutionId: (state, action) => {
      const { executionId, jumps } = action.payload

      state.executionJumpsWithReturn[executionId] = jumps
    },
    setCurrentStepDrawerComments: (state, action) => {
      state.currentStepDrawerComments = action.payload
    },
    setHeaderTitleInfo: (state, action) => {
      state.headerTitleInfo = action.payload
    },
    setDownloadingProcedureIds: (state, action) => {
      state.downloadingProcedureIds = action.payload
    },
    addDownloadingProcedureIds: (state, action) => {
      state.downloadingProcedureIds.push(...action.payload)
    },
    deleteDownloadingProcedureIds: (state, action) => {
      state.downloadingProcedureIds = state.downloadingProcedureIds.filter(
        (id) => !action.payload.includes(id)
      )
    },
    setConnectionStatus: (state, action) => {
      state.connectionStatus = action.payload
    }
  }
})

export const {
  setIsWorking,
  setCurrentDrawer,
  setLoadingExecution,
  setModuleIsNotSyncing,
  setModuleIsSyncing,
  setIsNonCompliantComment,
  setCurrentComponentType,
  setExecutionJumpsWithReturnByExecutionId,
  setCurrentStepDrawerComments,
  setHeaderTitleInfo,
  setDownloadingProcedureIds,
  addDownloadingProcedureIds,
  deleteDownloadingProcedureIds,
  setConnectionStatus
} = userInterfaceSlice.actions

export default userInterfaceSlice.reducer
