import { getTranslation } from '../../../i18n/getTranslation'
import { updateLocalUnit } from '../../../localdb/units/update'
import { putUnit } from '../../../services/unit/http/put'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { notifyError } from '../../../utils/notifications'
import { validateUnit } from '../validation'

export async function modifyUnit(unit, reload, showSuccessMessage) {
  try {
    await validateUnit(unit)
    const { plant, ...restUnit } = await putUnit(unit)
    await updateLocalUnit({
      ...restUnit,
      plantId: plant?.id,
      plantName: plant?.name
    })
    reload()

    if (showSuccessMessage)
      notificationMessage({
        message: getTranslation('unit.edit.successMsg')
      })
  } catch (error) {
    notifyError(error)
  }
}
