import { Tooltip } from 'antd'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Play from '../../../../../../components/icons/play'
import { getExecution } from '../../../../../../store/execution/selectors'
import { getProcedure } from '../../../../../../store/procedure/selectors'
import { EXECUTION_STATUS } from '../../../../../../utils/constants/execution'
import { PROCEDURE_STATUS } from '../../../../../../utils/constants/procedure'
import { PROCEDURE_PERMISSION_TYPE } from '../../../../../../utils/constants/roles'
import Translation from '../../../../../translations'
import { ActionButton } from '../../button'
import PlayPopover from '../popover'

const PlayButton = () => {
  const execution = useSelector(getExecution)
  const procedure = useSelector(getProcedure)
  const [visible, setVisible] = useState(false)
  const [executionGoalVisible, setExecutionGoalVisible] = useState(false)

  const isPopupVisible = useMemo(() => {
    !execution ||
      execution?.status === EXECUTION_STATUS.PAUSED ||
      execution?.status === EXECUTION_STATUS.INTERRUPTED
  }, [execution])

  return (
    <PlayPopover
      visible={visible}
      setVisible={setVisible}
      executionGoalVisible={executionGoalVisible}
      setExecutionGoalVisible={setExecutionGoalVisible}
    >
      <Tooltip
        open={!visible && !executionGoalVisible && isPopupVisible}
        placement='bottomLeft'
        title={<Translation id='initToExecute' />}
        zIndex={1020}
      >
        <ActionButton
          id='start-execution-button'
          icon={Play}
          statuses={[EXECUTION_STATUS.EXECUTING, EXECUTION_STATUS.SHARED]}
          keepEnabled={
            !execution && procedure.status !== PROCEDURE_STATUS.DEPRECATED
          }
          activeStatus={execution?.status}
          neededPermissionLevel={PROCEDURE_PERMISSION_TYPE.creation}
        />
      </Tooltip>
    </PlayPopover>
  )
}

export default PlayButton
