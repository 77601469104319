import { createSlice } from '@reduxjs/toolkit'

import { deleteFromLocaldbQueue, addToLocaldbQueue } from '../helpers'

export const executionComponentsSlice = createSlice({
  name: 'executionComponents',
  initialState: {
    components: [],
    lastSave: null,
    localdbQueue: []
  },
  reducers: {
    setExecutionComponents: (state, action) => {
      const { components } = action.payload
      state.components = components
      state.lastSave = components ? new Date(Date.now() + 1000).toString() : []
    },
    setComponentValue: (state, action) => {
      const { id, value, userId, updatedAt } = action.payload

      const onUpdate = (idToUpdate) => {
        const component = state.components.find(
          (ec) => ec.procedureComponentId === idToUpdate
        )
        if (!component) return
        component.value = value
        component.userId = userId
        component.updatedAt = updatedAt
      }

      if (Array.isArray(id)) {
        id.forEach((id) => onUpdate(id))
      } else {
        onUpdate(id)
      }
    },
    setLiveValue: (state, action) => {
      const { components: readoutComponents, clear } = action.payload

      const onUpdate = () => {
        const componentsToSave = state.components.reduce((acc, curr) => {
          const readoutComponent = readoutComponents.find(
            (rc) => rc.componentId === curr.procedureComponentId
          )
          if (readoutComponent && !clear && !(curr.isLocked || curr.isEdited))
            acc.push({
              ...curr,
              liveValue: readoutComponent
            })
          else if (
            readoutComponent &&
            clear &&
            !(curr.isLocked || curr.isEdited)
          ) {
            curr.liveValue = null
            acc.push(curr)
          } else acc.push(curr)
          return acc
        }, [])

        state.components = componentsToSave
      }
      if (!readoutComponents) return
      onUpdate()
    },
    setEditedLiveValue: (state, action) => {
      const { componentId, liveValue } = action.payload
      const componentIdx = state.components.findIndex(
        (ec) => ec.procedureComponentId === componentId
      )
      if (componentIdx === -1) return
      state.components[componentIdx].liveValue = liveValue
    },
    setIsLiveValueLocked: (state, action) => {
      const { componentId, isLocked } = action.payload
      const componentIdx = state.components.findIndex(
        (ec) => ec.procedureComponentId === componentId
      )
      if (componentIdx === -1) return
      state.components[componentIdx] = {
        ...state.components[componentIdx],
        isLocked: isLocked
      }
    },
    setIsLiveValueEdited: (state, action) => {
      const { componentId, isEdited } = action.payload
      const componentIdx = state.components.findIndex(
        (ec) => ec.procedureComponentId === componentId
      )
      if (componentIdx === -1) return
      state.components[componentIdx] = {
        ...state.components[componentIdx],
        isEdited: isEdited
      }
    },
    updateLocaldbQueue: (state, action) => {
      const { component, resetQueue } = action.payload

      if (resetQueue) {
        state.localdbQueue = []
      }

      if (Array.isArray(component)) {
        component.forEach((c) => addToLocaldbQueue(state, c))
        return
      }

      addToLocaldbQueue(state, component)
    },
    setLastSave: (state, action) => {
      state.lastSave = action.payload || new Date().valueOf()
    },
    setSyncedAt: (state, action) => {
      const { entities, syncedAt } = action.payload

      state.components
        ?.filter((c) => entities.some((e) => e.id === c.id))
        .forEach((c) => {
          c.syncedAt = syncedAt
        })
    },
    removeLocaldbQueue: (state, action) => {
      const { entitiesToDelete } = action.payload
      deleteFromLocaldbQueue(state, entitiesToDelete)
    }
  }
})

export const {
  setExecutionComponents,
  setComponentValue,
  setLiveValue,
  setEditedLiveValue,
  setIsLiveValueLocked,
  setIsLiveValueEdited,
  setLastSave,
  setSyncedAt,
  removeLocaldbQueue,
  updateLocaldbQueue
} = executionComponentsSlice.actions

export default executionComponentsSlice.reducer
