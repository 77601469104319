import { axiosInstance } from '../../../..'
import { EXECUTION_COMPONENT_TYPES } from '../../../../../utils/constants/execution/components'

export async function getReferencedProcedureKeys(procedureId) {
  const result = await axiosInstance.get(
    `procedures/${procedureId}/components`,
    { params: { type: EXECUTION_COMPONENT_TYPES.REFERENCE } }
  )

  return result.data?.map((d) => d.options.toProcedureKey)
}
