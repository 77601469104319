import { useEffect, useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getDatabase } from '../localdb'
import {
  subscribeReadout,
  unsubscribeReadout
} from '../services/execution-components/readout/websocket'
import { loadExecution } from '../store/execution/actions'
import { getExecution } from '../store/execution/selectors'
import { setIsExecution, setMode } from '../store/execution/slice'
import { clearMergeInfo, setMergeInfo } from '../store/mergeExecution/slice'
import { loadProcedure } from '../store/procedure/actions'
import { getProcedure } from '../store/procedure/selectors'
import { setIsProcedure } from '../store/procedure/slice'
import { getIsReadoutSubscribed } from '../store/socket/readout/selectors'
import { getConnectionStatus, getLoadingExecution } from '../store/userInterface/selectors'
import { setLoadingExecution } from '../store/userInterface/slice'
import { CONNECTION_STATUS } from '../utils/constants/connection'
import {
  EXECUTION_ROUTER_MODE,
  EXECUTION_MODES,
  EXECUTION_STATUS
} from '../utils/constants/execution'

import { useLiveExecution } from './useLiveExecution'

export const useExecution = (id, entity, mode) => {
  const isShared = mode === EXECUTION_MODES.SHARED,
    isView = mode === EXECUTION_MODES.VIEW,
    isMerge = mode === EXECUTION_MODES.MERGE,
    isProcedure = entity === 'procedure',
    isExecution = entity === 'execution'

  const history = useHistory()
  const dispatch = useDispatch()
  const loading = useSelector(getLoadingExecution)
  const execution = useSelector(getExecution)
  const procedure = useSelector(getProcedure)
  const isReadoutSubscribed = useSelector(getIsReadoutSubscribed)
  const executionId = isExecution ? id : null
  const procedureId = isProcedure ? id : execution?.procedureId
  const connectionStatus = useSelector(getConnectionStatus)
  const [isPaused, setIsPaused] = useState(
    execution?.status === EXECUTION_STATUS.PAUSED
  )

  const executionExists = !!execution

  const startReadoutSubscription = useCallback(() => {
    if (location.pathname.includes('running') && isPaused) return
    if (
      !isReadoutSubscribed &&
      (location.pathname.includes('executions') ||
        location.pathname.includes('shared') ||
        location.pathname.includes('view')) &&
      (execution?.status === EXECUTION_STATUS.EXECUTING ||
        execution?.status === EXECUTION_STATUS.SHARED) &&
      connectionStatus === CONNECTION_STATUS.ONLINE &&
      procedureId &&
      procedure?.hasReadouts
    ) {
      subscribeReadout({ procedureId, executionId })
    }
  }, [
    connectionStatus,
    execution?.status,
    executionId,
    isPaused,
    isReadoutSubscribed,
    procedure?.hasReadouts,
    procedureId
  ])

  useLiveExecution({
    isView,
    executionId,
    enabled: isShared || isView
  })

  useEffect(() => {
    if (
      isReadoutSubscribed &&
      execution?.status !== EXECUTION_STATUS.EXECUTING &&
      execution?.status !== EXECUTION_STATUS.SHARED &&
      connectionStatus === CONNECTION_STATUS.ONLINE &&
      procedureId &&
      procedure?.hasReadouts
    ) {
      unsubscribeReadout({ procedureId, executionId })
    }
  }, [
    connectionStatus,
    execution?.status,
    executionId,
    isReadoutSubscribed,
    procedure?.hasReadouts,
    procedureId
  ])

  useEffect(() => {
    if (execution?.status === EXECUTION_STATUS.PAUSED) setIsPaused(true)
    else setIsPaused(false)
  }, [execution?.status])

  // Guardar si es ejecución o procedimiento
  useEffect(() => {
    dispatch(setIsProcedure(isProcedure))
    dispatch(setIsExecution(isExecution))
  }, [dispatch, isExecution, isProcedure])

  // Guardar el modo de ejecución en el state de redux
  useEffect(() => {
    if (executionExists) {
      startReadoutSubscription()
      dispatch(setMode(mode))
    } else {
      dispatch(setMode(null))
    }
    return () => dispatch(setMode(null))
  }, [dispatch, executionExists, mode, startReadoutSubscription])

  // Comprueba si ya hay ejecuciones para el procedimiento solicitado y redirige
  useEffect(() => {
    const fn = async () => {
      if (executionId) {
        return
      }
      dispatch(setLoadingExecution(true))

      const runningExecutionId = await getProcedureRunningExecution(procedureId)

      if (runningExecutionId) {
        history.push({
          pathname: `/executions/${runningExecutionId}/${EXECUTION_ROUTER_MODE.RUNNING}`
        })
      }

      dispatch(setLoadingExecution(false))
    }

    fn()
  }, [dispatch, executionId, history, procedureId])

  //Carga la ejecución y sus componentes
  useEffect(() => {
    if (
      !isShared &&
      !isView &&
      ((isExecution && mode !== EXECUTION_ROUTER_MODE.NEW) || !executionId)
    ) {
      dispatch(setLoadingExecution(true))
      dispatch(loadExecution(executionId))
    }
  }, [dispatch, executionId, isShared, isView, mode, isExecution])

  // Si la ejecución es de merge, carga el merge en el state
  useEffect(() => {
    if (execution) {
      if (isMerge) {
        if (execution.merge?.conflicts) {
          const {
            merge: { conflicts, ...mergeInfo }
          } = execution

          dispatch(
            setMergeInfo({
              merge: mergeInfo,
              conflicts: conflicts
            })
          )
        }
      } else {
        dispatch(clearMergeInfo())
      }
    }
  }, [dispatch, execution, isMerge])

  // Carga el procedimiento
  useEffect(() => {
    // el loadingExecution es para no cargar el procedimiento antes de verificar si ya hay una ejecución ejecutando de este procedimiento
    if (procedureId) {
      if (procedureId !== procedure?.id) {
        dispatch(loadProcedure(procedureId))
      }
    }
  }, [dispatch, procedure?.id, procedureId])

  const loaded = useMemo(() => {
    if (loading) {
      return false
    }

    if (isProcedure) {
      return procedure && procedure.id === procedureId
    }

    if (isExecution) {
      return (
        procedure &&
        execution &&
        execution.id === executionId &&
        procedure.id === procedureId &&
        execution.procedureId === procedure.id
      )
    }
  }, [
    execution,
    executionId,
    isExecution,
    isProcedure,
    loading,
    procedure,
    procedureId
  ])

  return {
    loaded
  }
}

async function getProcedureRunningExecution(procedureId) {
  const db = getDatabase()

  const procedureExecutions = await db.executions
    .where({
      procedureId
    })
    .toArray()

  const runningExecution = procedureExecutions.find(
    (e) =>
      e.procedureId === procedureId &&
      [EXECUTION_STATUS.EXECUTING, EXECUTION_STATUS.PAUSED].includes(e.status)
  )

  return runningExecution?.id
}
