import { sum } from 'lodash'

export function isSynced(syncStatus) {
  if (!syncStatus) return false
  const { executionId, id, ...children } = syncStatus
  return sum(Object.values(children)) === 0
}

export function areAllSynced(syncStatuses) {
  if (syncStatuses.length === 0) return true
  return syncStatuses.filter((syncStatus) => isSynced(syncStatus)).length > 0
}
