import { groupBy, isFinite } from 'lodash'

import { EXECUTION_SIGNATURE_STATUS } from '../../constants/execution/components'
import { getVariableValues, calculateResult } from '../formulas'

export function numberOfFilled(allItems, executionComponents) {
  const itemsToFill = allItems.filter((item) =>
    executionComponents.some(
      (ec) => ec.procedureComponentId === item.id && !ec.value
    )
  )

  const filledItems = allItems.filter((item) =>
    executionComponents.some(
      (ec) => ec.procedureComponentId === item.id && ec.value
    )
  )
  return {
    itemsToFill,
    filledItems,
    dontMeetCriteria: itemsToFill
  }
}

export function numberOfFilledRecorderTextAreaDateTime(
  allItems,
  executionComponents
) {
  const { filledItems, itemsToFill } = numberOfFilled(
    allItems,
    executionComponents
  )

  const dontMeetCriteria = itemsToFill.filter((item) => {
    const { options } = item

    return options?.dontMeetCriteriaIfEmpty
  })

  return {
    itemsToFill,
    filledItems,
    dontMeetCriteria
  }
}

export function numberOfFilledOptionList(allItems, executionComponents) {
  const { filledItems, itemsToFill } = numberOfFilled(
    allItems,
    executionComponents
  )

  const components = executionComponents.filter((ec) =>
    filledItems.some((fi) => fi.id === ec.procedureComponentId)
  )

  const dontMeetCriteria = filledItems.filter((item) => {
    const {
      options: { list }
    } = item

    const component = components.find((c) => c.procedureComponentId === item.id)

    const option = list.find((item) => {
      return item.option
        ? (Array.isArray(component.value) &&
            component.value.includes(item.option)) ||
            item.option === component.value
        : (Array.isArray(component.value) && component.value.includes(item)) ||
            item === component.value
    })

    return option.dontMeetCriteria
  })

  return {
    filledItems,
    itemsToFill,
    dontMeetCriteria
  }
}

export function numberOfFilledCheckbox(allItems, executionComponents) {
  const { filledItems, itemsToFill } = numberOfFilled(
    allItems,
    executionComponents
  )

  const dontMeetCriteriaChecked = filledItems.filter((item) => {
    const { options } = item
    return (
      executionComponents.filter(
        (ec) =>
          ec.value &&
          ec.procedureComponentId === item.id &&
          options &&
          options.dontMeetCriteria
      ).length > 0
    )
  })

  const dontMeetCriteriaNotChecked = itemsToFill.filter((item) => {
    const { options } = item
    return (
      executionComponents.filter(
        (ec) =>
          !ec.value &&
          ec.procedureComponentId === item.id &&
          ((options && !options.dontMeetCriteria) || !options)
      ).length > 0
    )
  })

  return {
    filledItems,
    itemsToFill,
    dontMeetCriteria: [
      ...dontMeetCriteriaChecked,
      ...dontMeetCriteriaNotChecked
    ]
  }
}

export function numberOfFilledSignature(allItems, executionComponents) {
  const itemsToFill = allItems.filter((s) =>
    executionComponents.some(
      (ec) => ec.procedureComponentId === s.id && !ec.value?.status
    )
  )

  const filledItems = allItems.filter((s) =>
    executionComponents.some(
      (ec) => ec.procedureComponentId === s.id && ec.value?.status
    )
  )

  const dontMeetCriteria = filledItems.filter(
    (item) =>
      executionComponents.filter(
        (ec) =>
          ec.procedureComponentId === item.id &&
          ec.value?.status === EXECUTION_SIGNATURE_STATUS.NOT_OK
      ).length > 0
  )

  return {
    itemsToFill,
    filledItems,
    dontMeetCriteria: [...dontMeetCriteria, ...itemsToFill]
  }
}

export function numberOfFilledMulticell(allItems, executionComponents) {
  const groupsOfRadioButtons = Object.values(groupBy(allItems, 'options.group'))

  const itemsToFill = groupsOfRadioButtons.filter(
    (radBtnGroup) =>
      radBtnGroup.filter((radBtn) =>
        executionComponents.some(
          (ec) => ec.procedureComponentId === radBtn.id && !ec.value
        )
      ).length > 0
  )

  const filledItems = groupsOfRadioButtons.filter(
    (radBtnGroup) =>
      radBtnGroup.filter((radBtn) =>
        executionComponents.some(
          (ec) => ec.procedureComponentId === radBtn.id && ec.value
        )
      ).length > 0
  )

  const dontMeetCriteria = []

  groupsOfRadioButtons.filter((radBtnGroup) =>
    radBtnGroup.forEach((radBtn) => {
      if (
        executionComponents.some(
          (ec) =>
            ec.procedureComponentId === radBtn.id &&
            ec.value &&
            radBtn.options.dontMeetCriteria &&
            ec.value === radBtn.options.label
        )
      ) {
        // We only need the step id
        dontMeetCriteria.push({
          stepId: radBtn.stepId
        })
      }
    })
  )

  return {
    itemsToFill,
    filledItems,
    dontMeetCriteria
  }
}

export function numberOfFilledNumerical(allItems, executionComponents) {
  const itemsToFill = allItems.filter((checkbox) =>
    executionComponents.some(
      (ec) => ec.procedureComponentId === checkbox.id && !ec.value
    )
  )

  const filledItems = allItems.filter((s) =>
    executionComponents.some(
      (ec) => ec.procedureComponentId === s.id && isFinite(ec.value)
    )
  )

  const dontMeetCriteria = filledItems.filter((item) => {
    const {
      options: { maxRange, minRange, validationFormula }
    } = item

    if (!maxRange && !minRange && !validationFormula) return false

    const component = executionComponents.find(
      (ec) => ec.procedureComponentId === item.id && isFinite(ec.value)
    )

    if (validationFormula) {
      const result = calculateResult({
        formula: validationFormula,
        variableValuesMap: {
          result: parseFloat(component.value)
        }
      })
      return !result
    }

    return component.value > maxRange || component.value < minRange
  })

  return {
    itemsToFill,
    filledItems,
    dontMeetCriteria
  }
}

export function numberOfFilledFormula(
  allItems,
  executionComponents,
  procedureComponents
) {
  const itemsToFill = allItems.filter((formula) => {
    const { variables } = formula.options

    const params = executionComponents.filter((ec) =>
      Object.values(variables).includes(ec.procedureComponentId)
    )
    const isNotFilled = params.some((param) => !param.value)

    return isNotFilled
  })

  const filledItems = allItems.filter((formula) => {
    const { variables } = formula.options

    const params = executionComponents.filter((ec) =>
      Object.values(variables).includes(ec.procedureComponentId)
    )
    const isFilled = params.every((param) => param.value)

    return isFilled
  })

  const dontMeetCriteria = filledItems.filter((item) => {
    const {
      numberOfDecimals,
      variables: variableIdsMap,
      formula,
      validationFormula
    } = item.options

    if (!validationFormula) return false

    const variableValuesMap = getVariableValues({
      executionComponents,
      procedureComponents,
      variableIdsMap
    })

    const formulaResult = calculateResult({
      variableValuesMap,
      formula,
      numberOfDecimals
    })

    const validationFormulaResult = calculateResult({
      variableValuesMap: {
        ...variableValuesMap,
        result: formulaResult
      },
      formula: validationFormula
    })

    return !validationFormulaResult
  })

  return {
    itemsToFill,
    filledItems,
    dontMeetCriteria
  }
}
