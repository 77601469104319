import { createSlice } from '@reduxjs/toolkit'

import { EXECUTION_QA_STATUS } from '../../utils/constants/execution'

export const executionSlice = createSlice({
  name: 'execution',
  initialState: {
    execution: null,
    loading: 0,
    lastSave: null,
    mode: null,
    isExecution: null
  },
  reducers: {
    setExecution: (state, action) => {
      const { execution } = action.payload
      state.execution = execution
      state.lastSave = execution ? new Date(Date.now() + 1000).toString() : null
    },

    setIsExecution: (state, action) => {
      state.isExecution = action.payload
    },

    setExecutionLoading: (state, action) => {
      state.loading += action.payload ? 1 : -1
    },

    // TODO: Refactorizar esta acción para no tener tanta información replicada
    prepareExecutionToFinish: (state, action) => {
      const { user } = action.payload
      state.execution.userId = user.id
      state.execution.finishedBy = user
      state.execution.finishedAt = new Date().valueOf()
      state.execution.currentStep = null
      state.execution.updatedAt = new Date().valueOf()
      state.execution.user = {
        name: user.name
      }
    },

    setCurrentStep: (state, action) => {
      state.execution.currentStep = action.payload
      state.execution.updatedAt = new Date().valueOf()
    },

    setStatus: (state, action) => {
      const { status } = action.payload
      state.execution.status = status
      state.execution.updatedAt = new Date().valueOf()
    },

    setStatusComment: (state, action) => {
      state.execution.statusComment = action.payload.comment
      state.execution.updatedAt = new Date().valueOf()
    },

    setRevisionComment: (state, action) => {
      state.execution.revisionComment = action.payload
      state.execution.updatedAt = new Date().valueOf()
    },

    setApprovalComment: (state, action) => {
      state.execution.approvalComment = action.payload
      state.execution.updatedAt = new Date().valueOf()
    },

    setExecutionGoal: (state, action) => {
      if (action.payload.executionGoal === 'other') {
        state.execution.otherExecutionGoal = action.payload.otherExecutionGoal
        state.execution.executionGoalId = null
      } else {
        state.execution.executionGoalId = action.payload.executionGoal
        state.execution.otherExecutionGoal = null
      }
      state.execution.updatedAt = new Date().valueOf()
    },

    setSequentialMode: (state, action) => {
      state.execution.sequentialMode = action.payload
      state.execution.updatedAt = new Date().valueOf()
    },

    setMode: (state, action) => {
      state.mode = action.payload
    },

    approveExecution: (state, action) => {
      const currentUser = action.payload

      if (!state.execution.qaStatus) {
        state.execution.currentStep = null
        state.execution.revisedBy = currentUser
        state.execution.revisedById = currentUser.id
        state.execution.revisedAt = new Date().valueOf()
      } else if (!state.execution.revisedBy) {
        state.execution.currentStep = null
        state.execution.revisedBy = currentUser
        state.execution.revisedById = currentUser.id
        state.execution.revisedAt = new Date().valueOf()
      }

      state.execution.qaStatus = EXECUTION_QA_STATUS.APPROVED
      state.execution.approvedBy = currentUser
      state.execution.approvedAt = new Date().valueOf()
      state.execution.updatedAt = new Date().valueOf()
    },

    updateUpdatedAtExecution: (state, action) => {
      const { updatedAt } = action.payload
      state.execution.updatedAt = updatedAt
    },

    reviewExecution: (state, action) => {
      const currentUser = action.payload

      state.execution.currentStep = null
      state.execution.qaStatus = EXECUTION_QA_STATUS.REVIEWED
      state.execution.revisedBy = currentUser
      state.execution.revisedById = currentUser.id
      state.execution.revisedAt = new Date().valueOf()
      state.execution.updatedAt = new Date().valueOf()
    },

    setLastSave: (state, action) => {
      state.lastSave = action.payload
    },

    finishMergeExecution: (state) => {
      state.execution.merge.finished = true
      state.execution.updatedAt = new Date().valueOf()
    },

    setSyncedAt: (state, action) => {
      const { syncedAt, entities } = action.payload

      if (
        state.execution &&
        entities.some((e) => e.id === state.execution.id)
      ) {
        state.execution.syncedAt = syncedAt
      }
    }
  }
})

export const {
  setExecutionLoading,
  setExecution,
  setIsExecution,
  setCurrentStep,
  setStatus,
  prepareExecutionToFinish,
  setStatusComment,
  setRevisionComment,
  setApprovalComment,
  setExecutionGoal,
  setSequentialMode,
  setMode,
  approveExecution,
  reviewExecution,
  setLastSave,
  updateUpdatedAtExecution,
  finishMergeExecution,
  setSyncedAt
} = executionSlice.actions

export default executionSlice.reducer
