import { createSlice } from '@reduxjs/toolkit'

import { sortBookmarks } from '../../utils/helpers/steps'

export const mergeExecutionSlice = createSlice({
  name: 'mergeExecution',
  initialState: {
    merge: null,
    conflicts: null,
    currentConflict: null,
    currentStepConflict: null
  },
  reducers: {
    setMergeInfo: (state, action) => {
      const { merge, conflicts } = action.payload

      state.merge = merge
      state.conflicts = [...conflicts]?.sort((conflict1, conflict2) =>
        sortBookmarks(conflict1.componentStep, conflict2.componentStep)
      )
    },
    clearMergeInfo: (state) => {
      state.merge = null
      state.conflicts = null
      state.currentConflict = null
    },

    setCurrentConflict: (state, action) => {
      state.currentConflict = action.payload
    },

    setCurrentStepConflict: (state, action) => {
      state.currentStepConflict = action.payload
    },

    solveMergeConflict: (state, action) => {
      const { conflictId, value } = action.payload

      const conflict = state.conflicts.find(
        (mc) =>
          mc.executionComponent1Id === conflictId ||
          mc.executionComponent2Id === conflictId
      )

      if (conflict) {
        conflict.solved = true
        conflict.value = value
      }
    },

    finishMergeExecution: (state) => {
      state.merge.finished = true
    }
  }
})

export const {
  setMergeInfo,
  clearMergeInfo,
  setMergeConflicts,
  setCurrentConflict,
  solveMergeConflict,
  finishMergeExecution,
  setCurrentStepConflict
} = mergeExecutionSlice.actions

export default mergeExecutionSlice.reducer
