import { Button, Popconfirm } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Alert from '../../../../../components/icons/alert'
import CheckIcon from '../../../../../components/icons/check'
import DeleteIcon from '../../../../../components/icons/delete-icon'
import EditIcon from '../../../../../components/icons/edit-icon'
import useDeletePopover from '../../../../../hooks/use-delete-popover'
import useEditListItem from '../../../../../hooks/use-edit-list-item'
import Translation from '../../../../translations'

const ExtraStuff = ({
  element,
  setElementToEdit,
  elementToEdit,
  deletePopoverTextId,
  handleDelete,
  handleClickEdit
}) => {
  const {
    deletePopover,
    closeDeletePopover,
    openDeletePopover,
    handleDeleteElement
  } = useDeletePopover({
    elementId: element?.id,
    handleDelete
  })

  const { onClickEdit } = useEditListItem({
    element,
    elementToEdit,
    setElementToEdit,
    handleClickEdit
  })

  return (
    <section onClick={(e) => e.stopPropagation()}>
      <ActionButton
        className='edition-button'
        data-testid={`edition-button-${element.id}`}
        data-icon={
          elementToEdit?.id === element.id ? 'icon-check' : 'icon-edit'
        }
        icon={
          elementToEdit?.id === element.id ? (
            <CheckIcon size={14} />
          ) : (
            <EditIcon size={14} />
          )
        }
        onClick={onClickEdit}
      />{' '}
      <Popconfirm
        placement='topRight'
        showArrow={true}
        icon={<Alert size={16} />}
        open={deletePopover}
        title={<Translation id={deletePopoverTextId} />}
        onCancel={closeDeletePopover}
        onConfirm={handleDeleteElement}
        okText={<Translation id='yes' />}
        cancelText={<Translation id='no' />}
        okButtonProps={{
          style: { borderRadius: '2px' }
        }}
        cancelButtonProps={{
          style: { borderRadius: '2px' }
        }}
      >
        <ActionButton
          className='delete-button'
          data-testid={`delete-button-${element.id}`}
          icon={<DeleteIcon size={14} />}
          onClick={openDeletePopover}
        />
      </Popconfirm>
    </section>
  )
}

const ActionButton = styled(Button)`
  background: #ffffff;
  border-radius: 2px;
  border: none;
`

export default ExtraStuff
