import { Tooltip } from 'antd'
import React, { useState } from 'react'

import { downloadFinalReport } from '../../../../application/execution/report'
import Download from '../../../../components/icons/download'
import { getTranslation } from '../../../../i18n/getTranslation'
import { notificationMessage } from '../../../../utils/helpers/notification-message'
import DownloadButton from '../../../../views/buttons/download-button'
import Translation from '../../../../views/translations'

export const DownloadReportButton = ({ execution }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Tooltip title={<Translation id='downloadReport' />}>
      <DownloadButton
        id='download-report-button'
        onClick={() => downloadReport(execution, setIsLoading)}
        icon={<Download />}
        loading={isLoading}
      ></DownloadButton>
    </Tooltip>
  )
}

function downloadReport(execution, setIsLoading) {
  setIsLoading(true)
  downloadFinalReport(execution)
    .catch((err) => {
      let message = 'error'
      let description = 'errorDownloadingReport'

      if (err.response && err.response.status === 404) {
        message = {
          EXECUTION_NOT_FOUND: 'reportErrorExecutionNotFound',
          EXECUTION_REPORT_NOT_FOUND: 'reportErrorReportNotFound',
          EXECUTION_REPORT_FILE_NOT_FOUND: 'reportErrorFileNotFound'
        }[err.response.data.message]

        description = {
          EXECUTION_NOT_FOUND: 'reportErrorExecutionNotFoundDescription',
          EXECUTION_REPORT_NOT_FOUND: 'reportErrorReportNotFoundDescription',
          EXECUTION_REPORT_FILE_NOT_FOUND: 'reportErrorFileNotFoundDescription'
        }[err.response.data.message]
      }
      notificationMessage({
        type: 'error',
        message: getTranslation(message),
        description: getTranslation(description)
      })
    })
    .finally(() => setIsLoading(false))
}
