import { createSlice } from '@reduxjs/toolkit'

export const mediaUploadingSlice = createSlice({
  name: 'mediaUploading',
  initialState: {
    uploading: []
  },
  reducers: {
    addMediaUploading: (state, action) => {
      const media = action.payload

      state.uploading = [...state.uploading, media]
    },
    removeMediaByNameAndComment: (state, action) => {
      const { name, commentId } = action.payload

      state.uploading = state.uploading.filter(
        (m) => m.name !== name && m.commentId !== commentId
      )
    },
    removeMediaUploading: (state, action) => {
      const { id } = action.payload

      state.uploading = state.uploading.filter((m) => m.id !== id)
    },
    removeMediaUploadings: (state, action) => {
      const { ids } = action.payload

      state.uploading = state.uploading.filter((m) => !ids.includes(m.id))
    },
    cancelUploadByUser: (state, action) => {
      const { id } = action.payload

      const media = state.uploading.find((m) => m.id === id)
      if (media) media.cancelByUser = true
    },
    clearMediaUploading: (state) => {
      state.uploading = state.uploading.filter(
        (m) => !m.completed && !m.cancelByUser
      )
    },
    clearExecutionMediaUploading: (state, action) => {
      const { executionId } = action.payload

      state.uploading = state.uploading.filter(
        (m) => m.executionId !== executionId
      )
    },
    updateProgress: (state, action) => {
      const { id, progress } = action.payload

      const media = state.uploading.find((m) => m.id === id)

      if (media) {
        media.progress = progress
      }
    },
    completeProgress: (state, action) => {
      const { id } = action.payload

      const media = state.uploading.find((m) => m.id === id)

      if (media) {
        media.progress = 100
        media.completed = true
      }
    },
    errorUpload: (state, action) => {
      const { id } = action.payload

      const media = state.uploading.find((m) => m.id === id)

      if (media) {
        media.error = true
      }
    }
  }
})

export const {
  addMediaUploading,
  cancelUploadByUser,
  clearMediaUploading,
  clearExecutionMediaUploading,
  removeMediaUploading,
  removeMediaUploadings,
  removeMediaByNameAndComment,
  updateProgress,
  completeProgress,
  errorUpload
} = mediaUploadingSlice.actions

export default mediaUploadingSlice.reducer
