import { useState } from 'react'

const useSelectItemsTable = () => {
  const [selectedItems, setSelectedItems] = useState([])

  const onSelectChange = (record, isSelected) => {
    const rowsSelected = [...selectedItems]
    if (isSelected) {
      rowsSelected.push(record)
    } else {
      const index = rowsSelected.findIndex((item) => item.id === record.id)
      rowsSelected.splice(index, 1)
    }
    setSelectedItems(rowsSelected)
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  return {
    selectedItems,
    onSelectChange,
    clearSelectedItems
  }
}

export default useSelectItemsTable
