import { deleteFilesAndContentLocally } from '../../../../localdb/files/delete'
import { store } from '../../../../store'
import { updateUpdatedAtExecution } from '../../../../store/execution/slice'
import {
  deleteMediaFromStepCommentAction,
  deleteStepCommentAction
} from '../../../../store/executionSteps/actions'
import { removeMediaUploadings } from '../../../../store/media-uploading/slice'
import { setIsWorking } from '../../../../store/userInterface/slice'


export async function deleteComment(stepId, commentId, mediaIds) {
  if (mediaIds.length > 0) {
    await deleteFilesAndContentLocally(mediaIds)
  }

  store.dispatch(setIsWorking(true))
  store.dispatch(removeMediaUploadings({ ids: mediaIds }))
  store.dispatch(deleteStepCommentAction({ stepId, commentId }))
  store.dispatch(updateUpdatedAtExecution({ updatedAt: new Date().valueOf() }))
}

export async function deleteMediaFromComment(commentId, mediaId, stepId) {
  await deleteFilesAndContentLocally([mediaId])
  store.dispatch(setIsWorking(true))
  store.dispatch(
    deleteMediaFromStepCommentAction({ stepId, commentId, mediaId })
  )
  store.dispatch(updateUpdatedAtExecution({ updatedAt: new Date().valueOf() }))
}
