import React from 'react'

const RadioChecked = ({
  size = 24,
  color = 'currentColor',
  fillColor = color
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 32 32'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke={color} strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(0.941176, 0.941176)'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.41176471'
      >
        <path d='M0.265120133,15.1118476 C0.265120133,23.3114687 6.91222642,29.958575 15.1118476,29.958575 C23.3114687,29.958575 29.958575,23.3114687 29.958575,15.1118476 C29.958575,6.91222642 23.3114687,0.265120133 15.1118476,0.265120133 C6.91222642,0.265120133 0.265120133,6.91222642 0.265120133,15.1118476 Z'></path>
      </g>
      <circle fill={fillColor} cx='16' cy='16' r='11.2941176'></circle>
    </g>
  </svg>
)

export default RadioChecked
