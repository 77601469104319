import React from 'react'

import CloseIcon from '../../../components/icons/close-icon'
import DeleteIcon from '../../../components/icons/delete-icon'
import EditIcon from '../../../components/icons/edit-icon'
import IconButtonContainer from '../../../containers/icon-button'
import IconsCommentContainer from '../../../containers/icons-comment'
import { COMMENT_TO_PROCEDURE_TYPES } from '../../../utils/constants/comment'
import { confirm } from '../../../utils/helpers/dialogs'
import Translation from '../../translations'

const CommentActions = ({
  item,
  commentEditing,
  deleteComment,
  setCommentEditing,
  setTextAreaValue,
  setCommentType,
  canEdit,
  canDelete = true
}) => {
  return (
    <IconsCommentContainer>
      <IconButtonContainer
        color={'#000'}
        size='16px'
        className='delete-comment'
        disabled={!canDelete}
        onClick={async () => {
          if (!canDelete) return
          const result = await confirm({
            title: item.isNonCompliantComment ? (
              <Translation id='confirmDeleteNonCompliantComment' />
            ) : (
              <Translation id='confirmDeleteComment' />
            )
          })

          if (result) {
            if (item) {
              deleteComment()
            }
          }
        }}
        icon={DeleteIcon}
      />
      {canEdit ? (
        commentEditing && commentEditing.id === item.id ? (
          <IconButtonContainer
            color={'#000'}
            size='16px'
            className='close-comment'
            onClick={() => {
              setCommentEditing(null)
              setTextAreaValue('')
              setCommentType && setCommentType(null)
            }}
            style={{ cursor: 'pointer' }}
            icon={CloseIcon}
          />
        ) : (
          <IconButtonContainer
            color={'#000'}
            size='16px'
            className='edit-comment'
            onClick={() => {
              if (item) {
                setCommentEditing(item)
                setTextAreaValue(item.comment)
                setCommentType &&
                  setCommentType(COMMENT_TO_PROCEDURE_TYPES[item.type])
              }
            }}
            icon={EditIcon}
          />
        )
      ) : null}
    </IconsCommentContainer>
  )
}

export default CommentActions
