import { notification } from 'antd'
import React from 'react'

import CloseIcon from '../../../components/icons/close-icon'

export function notificationMessage({
  message,
  description,
  type,
  placement,
  duration = 4.5,
  key,
  closeIcon,
  icon,
  onClose
}) {
  const notificationStyle = {
    borderColor: '#a9a9a9',
    borderWidth: '3px',
    borderStyle: 'solid'
  }

  return notification[type || 'success']({
    key: key || null,
    message: message,
    description: description || '',
    placement: placement || 'bottomLeft',
    style: notificationStyle,
    duration,
    closeIcon: closeIcon || <CloseIcon size={16} />,
    icon: icon || null,
    onClose: onClose || null
  })
}
