import { Menu } from 'antd'
import groupBy from 'lodash.groupby'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { addComment } from '../../../../application/step-comments/actions/add'
import {
  deleteComment,
  deleteMediaFromComment
} from '../../../../application/step-comments/actions/delete'
import { editComment } from '../../../../application/step-comments/actions/edit'
import BlankButton from '../../../../components/buttons/blank-button'
import Caret from '../../../../components/icons/caret'
import Conversation from '../../../../components/icons/conversation'
import useExecutionDrawer from '../../../../hooks/use-execution-drawer'
import { changeCurrentStep } from '../../../../store/execution/actions'
import {
  getExecutionId,
  getExecutionStatus,
  getIsReview
} from '../../../../store/execution/selectors'
import { getExecutionSteps } from '../../../../store/executionSteps/selectors'
import { getProcedure } from '../../../../store/procedure/selectors'
import { getUser } from '../../../../store/user-management/selectors'
import { getCurrentStepDrawerComments } from '../../../../store/userInterface/selectors'
import { setCurrentStepDrawerComments } from '../../../../store/userInterface/slice'
import { SHOULD_CHECK_COMPLIANCIES } from '../../../../utils/constants/config'
import { DRAWERS } from '../../../../utils/constants/drawer'
import { EXECUTION_STATUS } from '../../../../utils/constants/execution'
import { getStepsInfoFromComments } from '../../../../utils/helpers/comments/step-comments'
import { getWrapperByBookmark } from '../../../../utils/helpers/dom-handlers'
import { scrollProcedureTo } from '../../../../utils/helpers/procedure'
import {
  flattenTree,
  sortBookmarks
} from '../../../../utils/helpers/steps'
import Translation from '../../../translations'
import CommonExecutionViewDrawer from '../common'

import StepAnnotations from './annotations'
import StepComments from './comments'

const StepCommentsDrawer = ({ onSelectStep }) => {
  const executionStatus = useSelector(getExecutionStatus)
  const executionId = useSelector(getExecutionId)
  const procedure = useSelector(getProcedure)
  const executionSteps = useSelector(getExecutionSteps)
  const currentStepDrawerComments = useSelector(getCurrentStepDrawerComments)
  const dispatch = useDispatch()

  const {
    currentDrawer,
    isNonCompliantComment,
    currentComponentType,
    openDrawer,
    closeDrawer,
    setIsNonCompliantCommentFn,
    setCurrentComponentTypeFn
  } = useExecutionDrawer()

  const isExecutionShared = EXECUTION_STATUS.SHARED === executionStatus
  const isReviewMode = useSelector(getIsReview)

  const visible = currentDrawer === DRAWERS.STEPS_COMMENTS
  const setVisible = useCallback(
    (visible) => {
      visible ? openDrawer(DRAWERS.STEPS_COMMENTS) : closeDrawer()
    },
    [openDrawer, closeDrawer]
  )

  const procedureSteps = flattenTree({ parent: procedure.index })
  const step = useMemo(
    () =>
      executionSteps?.find((s) => s.stepId === currentStepDrawerComments?.id),
    [currentStepDrawerComments, executionSteps]
  )
  const stepIndex = useMemo(
    () => procedureSteps?.find((s) => s.id === step?.stepId)?.stepIndex,
    [procedureSteps, step]
  )

  const executionStepsComments = executionSteps
    ?.filter((s) => s.comments)
    .map((s) => s.comments)
    ?.flat()
    .sort((itemA, itemB) => {
      return (
        new Date(itemA.createdAt).getTime() -
        new Date(itemB.createdAt).getTime()
      )
    })
  const commentSteps = groupBy(
    executionStepsComments || [],
    (comment) => comment?.bookmark
  )

  const items = getStepsInfoFromComments(
    commentSteps,
    procedureSteps,
    onSelectStep
  )
  const currentUser = useSelector(getUser)
  const fullAnnotations = executionSteps
    ?.filter((s) => s.annotation)
    .sort((a, b) => sortBookmarks(a.stepId, b.stepId))
    .map((s) => {
      const step = procedureSteps?.find((ps) => ps.id === s.stepId)
      return {
        annotation: s.annotation,
        stepIndex: step?.stepIndex,
        title: step?.title,
        handleClick: () => {
          const wrapper = getWrapperByBookmark(s.stepId)
          scrollProcedureTo(wrapper)
          dispatch(changeCurrentStep(wrapper.dataset.id))
        }
      }
    })

  const badgeCounter = () => {
    const commentsStepsCount = Object.keys(commentSteps).filter(
      (stepId) => !commentSteps[stepId].every((comment) => comment.deletedAt)
    ).length
    if (!visible) {
      return (
        commentsStepsCount +
        (isExecutionShared ? 0 : fullAnnotations?.length || 0)
      )
    } else if (
      visible &&
      !commentSteps[currentStepDrawerComments?.id]?.length
    ) {
      return commentsStepsCount || 0
    } else {
      return (
        commentSteps[currentStepDrawerComments?.id]?.filter(
          (comment) => !comment.deletedAt
        ).length || 0
      )
    }
  }

  const itemAppend = (item) => {
    const { handleClick: _handleClick, ...restItem } = item
    return (
      <BlankButton
        onClick={() => {
          dispatch(setCurrentStepDrawerComments(restItem))
        }}
        color='transparent'
        data-active-color='transparent'
      >
        <Caret rotation={180} />
      </BlankButton>
    )
  }

  return (
    <CommonExecutionViewDrawer
      id='step-comment-drawer'
      icon={<Conversation />}
      color='#4B98C6'
      visible={visible}
      disabled={isNonCompliantComment && SHOULD_CHECK_COMPLIANCIES}
      setVisible={(isVisible) => {
        if (!isVisible) {
          dispatch(setCurrentStepDrawerComments(null))
        }
        setVisible(isVisible)
        isNonCompliantComment && setIsNonCompliantCommentFn(false)
        setCurrentComponentTypeFn(null)
      }}
      onBack={
        currentStepDrawerComments
          ? () => {
              dispatch(setCurrentStepDrawerComments(null))
            }
          : null
      }
      title={
        currentStepDrawerComments ? (
          <span>
            <Translation id='commentsDrawerStep' />
            {currentStepDrawerComments.stepIndex}
          </span>
        ) : (
          <Translation id='commentsDrawerAll' />
        )
      }
      itemAppend={itemAppend}
      badgeCount={badgeCounter()}
    >
      <>
        <DrawerContent>
          {currentStepDrawerComments ? (
            <StepComments
              comments={
                currentStepDrawerComments &&
                commentSteps[currentStepDrawerComments.id]
                  ? commentSteps[currentStepDrawerComments.id].filter(
                      (comment) => !comment.deletedAt
                    )
                  : []
              }
              addComment={(text, media, isCritical, isNonCompliantComment) => {
                addComment(
                  currentUser,
                  text,
                  currentStepDrawerComments.id,
                  media,
                  isCritical,
                  isNonCompliantComment,
                  executionId,
                  step,
                  stepIndex,
                  isReviewMode
                )
              }}
              deleteComment={(commentId, mediaIds) =>
                deleteComment(currentStepDrawerComments.id, commentId, mediaIds)
              }
              editComment={(comment) =>
                editComment(currentStepDrawerComments.id, comment)
              }
              deleteMediaFromComment={deleteMediaFromComment}
              isExecution={false}
              isNonCompliantComment={isNonCompliantComment}
              componentType={currentComponentType}
              setIsNonCompliant={setIsNonCompliantCommentFn}
            />
          ) : (
            <Menu
              id='panel-step-comments'
              className='drawer-comments'
              items={items?.map((item, i) => ({
                key: i,
                label: (
                  <MenuItem>
                    <ItemText onClick={item.handleClick}>
                      {item.stepIndex} {item.title}
                    </ItemText>
                    {itemAppend && itemAppend(item)}
                  </MenuItem>
                )
              }))}
            />
          )}
        </DrawerContent>
        {!isExecutionShared && (
          <DrawerContent>
            <StepAnnotations
              annotations={fullAnnotations}
              itemAppend={itemAppend}
            />
          </DrawerContent>
        )}
      </>
    </CommonExecutionViewDrawer>
  )
}

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ItemText = styled.a`
  width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const DrawerContent = styled.div`
  flex-grow: 1;
`

export default StepCommentsDrawer
