import { groupBy } from 'lodash'

import { deleteExecutionComments } from '../../../services/execution-comments/http/delete'
import { postExecutionComments } from '../../../services/execution-comments/http/post'
import { putExecutionComments } from '../../../services/execution-comments/http/put'

export const sendExecutionComments = async (executionComments) => {
  const grouped = groupBy(executionComments, 'executionId')
  return Promise.all(
    Object.keys(grouped).map((executionId) => {
      const comments = grouped[executionId]

      const toDelete = comments.filter((comment) => comment.deletedAt)
      const toCreate = comments.filter(
        (comment) => !comment.deletedAt && !comment.updatedAt
      )
      const toUpdate = comments.filter(
        (comment) => !comment.deletedAt && comment.updatedAt
      )

      const promises = []

      if (toCreate.length > 0) {
        promises.push(postExecutionComments(executionId, toCreate))
      }

      if (toUpdate.length > 0) {
        promises.push(putExecutionComments(executionId, toUpdate))
      }

      if (toDelete.length > 0) {
        promises.push(deleteExecutionComments(executionId, toDelete))
      }

      return Promise.all(promises)
    })
  )
}
