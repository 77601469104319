import { createCookie, readCookie } from '../../../utils/helpers/cookies'
import { DEVICE_ID_COOKIE } from '../../constants/cookie'

export function getDeviceId() {
  return readCookie(DEVICE_ID_COOKIE)
}

export function saveDeviceIdCookie(deviceId) {
  if (deviceId) createCookie(DEVICE_ID_COOKIE, deviceId, 7300)
}
