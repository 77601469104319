import cloneDeep from 'lodash.clonedeep'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import Play from '../../../../../components/icons/play'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { changeComponentValue } from '../../../../../store/executionComponents/actions'
import {
  getConflicts,
  getCurrentStepConflict
} from '../../../../../store/mergeExecution/selectors'
import {
  setCurrentConflict,
  setCurrentStepConflict
} from '../../../../../store/mergeExecution/slice'
import { getProcedure } from '../../../../../store/procedure/selectors'
import { setIsWorking } from '../../../../../store/userInterface/slice'
import { EXECUTION_COMPONENT_TYPES } from '../../../../../utils/constants/execution/components'
import Checkbox from '../../../../procedure/components/checkbox'
import DateInput from '../../../../procedure/components/date-input'
import DropDown from '../../../../procedure/components/dropdown'
import FileReference from '../../../../procedure/components/file-reference'
import Formula from '../../../../procedure/components/formula'
import Input from '../../../../procedure/components/input'
import Jump from '../../../../procedure/components/jump'
import ProcedureReference from '../../../../procedure/components/procedure-reference'
import RadioCellGroup from '../../../../procedure/components/radio-cell-group'
import RangeInput from '../../../../procedure/components/range-input'
import Signature from '../../../../procedure/components/signature'
import TextArea from '../../../../procedure/components/textarea'
import TimeInput from '../../../../procedure/components/time-input'

const MergeComponents = ({
  execution,
  executionComponents,
  conflictIndex,
  setConflictIndex,
  onSolveMergeConflict,
  mergeConflicts,
  onSelectStep
}) => {
  const dispatch = useDispatch()
  const conflicts = useSelector(getConflicts)
  const procedure = useSelector(getProcedure)
  const [choosenValue, setChoosenValue] = useState(null)
  const dateTimeFormat = useTranslation('dateTimeFormat')
  const actualStep = useSelector(getCurrentStepConflict)

  const mergeComponents = mergeConflicts[conflictIndex % mergeConflicts.length]
  const component1 = mergeComponents.executionComponent1
  const component2 = mergeComponents.executionComponent2
  const components = {
    [EXECUTION_COMPONENT_TYPES.SIGNATURE]: Signature,
    [EXECUTION_COMPONENT_TYPES.RECORDER]: Input,
    [EXECUTION_COMPONENT_TYPES.NUMERICAL]: RangeInput,
    [EXECUTION_COMPONENT_TYPES.MULTICELL]: RadioCellGroup,
    [EXECUTION_COMPONENT_TYPES.OPTION_LIST]: DropDown,
    [EXECUTION_COMPONENT_TYPES.FORMULA]: Formula,
    [EXECUTION_COMPONENT_TYPES.JUMP]: Jump,
    [EXECUTION_COMPONENT_TYPES.REFERENCE]: ProcedureReference,
    [EXECUTION_COMPONENT_TYPES.FILE_REFERENCE]: FileReference,
    [EXECUTION_COMPONENT_TYPES.CHECKBOX]: Checkbox,
    [EXECUTION_COMPONENT_TYPES.DATE]: DateInput,
    [EXECUTION_COMPONENT_TYPES.TIME]: TimeInput,
    [EXECUTION_COMPONENT_TYPES.TEXTAREA]: TextArea
  }

  const Component = components[mergeComponents.componentType]

  useEffect(() => {
    const conflict = conflicts.find(
      (mc) =>
        mc.executionComponent1.procedureComponentId ===
          component1.procedureComponentId ||
        mc.executionComponent2.procedureComponentId ===
          component2.procedureComponentId
    )

    setChoosenValue(conflict?.value)
  }, [
    mergeComponents,
    conflicts,
    component1.procedureComponentId,
    component2.procedureComponentId
  ])

  useEffect(() => {
    dispatch(setCurrentConflict(component1.procedureComponentId))
  }, [component1.procedureComponentId, dispatch])

  const onSaveComponent = (component) => {
    dispatch(setIsWorking(true))
    onSolveMergeConflict(component.id, component.value)

    const executionComponent = executionComponents?.find(
      (c) => c.procedureComponentId === component.procedureComponentId
    )

    let comp = cloneDeep(component)
    comp = {
      ...comp,
      id: executionComponent?.id || uuidv4().toUpperCase(),
      executionId: execution.id
    }

    dispatch(
      changeComponentValue({
        id: comp.procedureComponentId,
        value: comp.value
      })
    )
  }

  const getComponent = (componentType, component) => {
    switch (componentType) {
      case EXECUTION_COMPONENT_TYPES.MULTICELL:
        return <span>{component.value}</span>
      case EXECUTION_COMPONENT_TYPES.TEXTAREA:
        return (
          <TextareaComponent>
            {component.value.length >= 50
              ? component.value.substring(0, 50) + '...'
              : component.value}
          </TextareaComponent>
        )
      default:
        return (
          <Component
            className='merge-second-component'
            component={component}
            executionComponent={component}
            disabled={true}
            onComponentChange={() => {}}
            stepComponents={[]}
            stepId={''}
            procedure={procedure}
          />
        )
    }
  }

  const onClickArrowButton = (isForward) => {
    const to = isForward ? 1 : -1
    const newConflictIndex = conflictIndex + to

    const newMergeComponents =
      mergeConflicts[newConflictIndex % mergeConflicts.length]

    setConflictIndex(newConflictIndex)
    setChoosenValue(null)

    if (actualStep !== newMergeComponents.componentStep) {
      onSelectStep([newMergeComponents.componentStep])
      dispatch(setCurrentStepConflict(newMergeComponents.componentStep))
    }
  }

  return (
    <Wrapper>
      <ArrowButton
        id='previous-conflict'
        onClick={() => onClickArrowButton(false)}
        disabled={conflictIndex === 0}
      >
        <Play style={{ transform: 'rotate(180deg)' }} />
      </ArrowButton>
      <ComponentsContainer>
        <ComponentWrapper
          id='merge-first-component'
          onClick={() => onSaveComponent(component1)}
          highlight={choosenValue === component1.value}
        >
          <>
            <UnhoverDiv />
            <div style={{ position: 'absolute' }}>
              {getComponent(mergeComponents.componentType, component1)}
              <ComponentMetadata
                id='first-user-name'
                style={{ marginTop: '15px' }}
              >
                {component1.user?.name}
              </ComponentMetadata>
              <ComponentMetadata>
                {moment(component1.updatedAt).format(dateTimeFormat)}
              </ComponentMetadata>
            </div>
          </>
        </ComponentWrapper>
        <ComponentWrapper
          id='merge-second-component'
          onClick={() => onSaveComponent(component2)}
          highlight={choosenValue === component2.value}
        >
          <>
            <UnhoverDiv />
            <div style={{ position: 'absolute' }}>
              {getComponent(mergeComponents.componentType, component2)}
              <ComponentMetadata
                id='second-user-name'
                style={{ marginTop: '15px' }}
              >
                {component2.user?.name}
              </ComponentMetadata>
              <ComponentMetadata>
                {moment(component2.updatedAt).format(dateTimeFormat)}
              </ComponentMetadata>
            </div>
          </>
        </ComponentWrapper>
      </ComponentsContainer>
      <ArrowButton
        id='next-conflict'
        onClick={() => onClickArrowButton(true)}
        disabled={conflictIndex === mergeConflicts?.length - 1}
      >
        <Play />
      </ArrowButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`

const ArrowButton = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;

  &:hover {
    background-color: #efefef;
  }

  &:disabled,
  &:disabled:hover {
    opacity: 50%;
    cursor: not-allowed;
    background-color: transparent;
  }
`

const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex: 17;
  border: 1px solid #eff0ee;
`

const ComponentWrapper = styled.div`
  display: flex;
  padding: 20px;
  z-index: 10;
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 130px;
  margin: 2px;

  border: ${({ highlight }) => (highlight ? '2px solid #FFA300' : 'none')};
  background-color: ${({ highlight }) =>
    highlight ? 'rgba(255, 163, 0, 0.05)' : 'transparent'};
`

const UnhoverDiv = styled.div`
  position: relative;
  height: 85px;
  width: 30%;
  z-index: 10;
`
const ComponentMetadata = styled.p`
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0px;
  color: #666666;
`

const TextareaComponent = styled.div`
  width: 100%;
  height: 75px;
`

export default MergeComponents
