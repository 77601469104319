import { Modal, Button } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import { getUserCode } from '../../../application/users/code'
import Translation from '../../translations'

const SignatureCodeGeneratorModal = ({ visible, onClose }) => {
  const [code, setCode] = useState('')

  const generateUserCode = () => {
    const codeObject = getUserCode()
    setCode(codeObject.code)
  }

  return (
    <CodeModal
      bodyStyle={{
        backgroundColor: '#efeeeb',
        borderRadius: '0px',
        paddingBottom: '0px !important'
      }}
      closable={false}
      title={
        <span style={{ fontSize: '22px' }}>
          <Translation id='singleUseCodeTitle' />
        </span>
      }
      open={visible}
      footer={[
        <ModalButton
          key='close'
          onClick={() => {
            setCode(null)
            onClose()
          }}
        >
          <Translation id='close' />
        </ModalButton>
      ]}
    >
      <p>
        <Translation id='singleUseCodText' />
      </p>
      <ul>
        <li>
          <Translation id='singleUseCodeList1' />
        </li>
        <li>
          <Translation id='singleUseCodeList2' />
        </li>
        <li>
          <Translation id='singleUseCodeList3' />
        </li>
      </ul>

      <CodeWrapper>
        <CodeInput id='generated-code'>{code}</CodeInput>
        <GenerateButton id='generate-button' onClick={generateUserCode}>
          <Translation id='generateBtn' />
        </GenerateButton>
      </CodeWrapper>
    </CodeModal>
  )
}

const CodeModal = styled(Modal)`
  .ant-modal-header,
  .ant-modal-footer {
    background-color: #efeeeb;
    border-radius: 0px;
  }
`

const CodeWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding: 5px 18px;
  display: flex;
  flex-wrap: nowrap;
  min-height: 57px;
`

const CodeInput = styled.span`
  border: none;
  background-color: #fff;
  font-size: 30px;
  flex: 5;

  &:hover,
  &:active,
  &:focus {
    background-color: #fff;
    border: none;
    outline: none;
    box-shadow: none;
  }
`

const GenerateButton = styled(Button)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  box-shadow: none;
  font-size: 22px;
  flex: 1;
  align-self: baseline;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }
`

const ModalButton = styled(Button)`
  border-radius: 2px;
  padding: 10px 82px;
  height: 47px;
  background-color: #645377;
  color: #ffffff;
  width: 99%;

  &:hover,
  &:active,
  &:focus {
    background-color: #645377;
    color: #ffffff;
  }
`

export default SignatureCodeGeneratorModal
