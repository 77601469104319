import { EXECUTION_STATUS_COLOR } from '../../../../utils/constants/styles'
import { debug } from '../../../logger'
import { buildCoverPage } from '../../report/builder'
import { applyReadDoneStyles } from '../add'
import { getCommentsAnnexed, markCommentElement } from '../comments'
import { transformSizeAttrsToInlineStyles } from '../images'
import { formatComponents, simplifyComponents } from '../process'
import {
  removeDocTitle,
  removeEmptyNodesByTagName,
  removeIconsInSteps,
  removeMarginBottomParagraphInTable,
  removeMarks,
  removeNodes,
  removeNodesByTagName,
  removeNotifications,
  removePlaceKeeping,
  removeSectionBreaks,
  replaceBreaksByBlockElements,
  uncommentSectionTags,
  removeUselessElements
} from '../remove'
import {
  changeWindotextColorToBlack,
  getComponentStyles,
  getPrintStyles,
  getReportStyles,
  removePageStyles,
  setAligmentToTables
} from '../styles'


// En el html importamos los estilos de los styled components y los redefinimos, si es necesario en
// el fichero de estilos stylesReport.
// Los de Proceed (importados desde styles) o antd, en principio, no son necesarios (meten demasiada
// información que no se usa en el informe) si necesitemos algún estilo, los definiremos en stylesReport.
// El objetivo es simplificar lo máximo posible el html, de cara a que algún de post-procesado lo acepte
// sin problemas (a DocRaptor p.e. no le sentaban bien los de Proceed)
export async function getHTML(content, procedure, execution) {
  const reportStyles = await getReportStyles()

  let coverPage = ''
  if (execution && execution.steps) {
    coverPage = buildCoverPage(procedure, execution)
  }

  return `
    <html>
      <head>
          <meta charset="UTF-8">
          <style id='styledCompStyle'> 
            ${getComponentStyles()}
          </style>
        
          <style id='reportStyles'>
            ${reportStyles} 
          </style> 
          
          <style id='executionStatusStyle'>
            div.StatusInfo
            {
              background-color:${EXECUTION_STATUS_COLOR[execution?.status]};
              width: fit-content;
              margin-left: 30px;
            }    
          </style>

          ${getPrintStyles(procedure, execution)}
      </head>
      <body>
          <div id="root">
              <section class="ant-layout full-height">
              <main class="ant-layout-content" id="procedure-scroll">
              ${coverPage}          
                <div id="procedure-content">
                  <div id="procedure-data">
                    ${content.innerHTML}
                  </div>
                </div> 
              </main>
            </section>
          </div>
      </body>
    </html>`
}

export async function prepareContent(execution, content) {
  debug('Cleaning document...')

  // Borramos el <title> que viene en algunos procedimientos, del nombre que trae el documento de Word
  removeDocTitle(content)
  // Quita los iconos de comentarios, notas que aparecen en los pasos
  removeIconsInSteps(content)

  //Eliminamos saltos de línea para algunos componentes
  formatComponents(content)

  simplifyComponents(content)

  if (execution) {
    // Marca los pasos que tienen un comentario
    await markCommentElement(execution, content)

    // Inserta el anexo con los comentarios al paso y a la ejecución

    await getCommentsAnnexed(execution, content)
  }

  removeMarginBottomParagraphInTable(content)
  //Eliminamos el placekeeping
  removePlaceKeeping(content)

  //Quitamos las notas  y warnings de los pasos
  removeNotifications(content)

  // Eliminamos el resaltado del texto del botón "Buscar"
  removeMarks(content)

  // Eliminamos los wrappers (no se usan para nada en el informe)
  removeNodes(content, '.wrapper')
  // Eliminamos también los wrapper-revised (en procedimientos antiguos)
  removeNodes(content, '.wrapper-revised')

  // Antes de borrar los div de los puntos del documento, tenemos que copiar el estilo
  // que marca que un paso está leído o hecho  (círculo, raya)

  applyReadDoneStyles(content)

  // Borramos los divs de los puntos
  removeNodes(content, '[id^="_BMK"]')

  // Borramos el span que sale justo antes del procedure-id (a veces mete una página en blanco)

  removeNodes(content, '[id^="procedure-id"]')

  replaceBreaksByBlockElements(content)

  removeSectionBreaks(content)

  // Remove all o:smarttagtype nodes as they add a blank page on print
  removeNodesByTagName(content, 'o:smarttagtype')

  // Remove all p nodes that are empty and which in general causes double vertical size in table cells
  removeEmptyNodesByTagName(content, 'p')

  // Remove all content control (useless in final report and sometimes avoid
  // the execution of a jump to next page.

  removeNodes(content, '[class="contentcontrol"')

  transformSizeAttrsToInlineStyles(content)

  removePageStyles(content)

  changeWindotextColorToBlack(content)

  uncommentSectionTags(content)

  // Una vez recuperadas las secciones, comprobamos si existen elementos fuera de ellas.  En algunos informes
  // aparecen elementos <b> o <span> vacíos que provocan un salto de página, o que el siguiente salto no se haga
  removeUselessElements(content)

  // Define el attributo align de las tablas pertenecientes a alguna clase Mso<xxx> ,
  // si éstas no tienen uno por defecto.
  // Estas tablas pertenecen a clases que define Office, cuyos estilos no conocemos porque no se exportan
  // al exportar el HTML.
  // Se ha detectado que este tipo de tablas no salen correctamente renderizadas en Weasyprint
  // (aunque sí se sitúan correctamente cuando cargamos el html desde Chrome.)
  // Además de forzar en el backend a usar durante la impresión, los presentional hints, asignamos a las
  // tablas su align.

  setAligmentToTables(content, 'table[class*="Mso"]')
}
