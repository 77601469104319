import { List } from 'antd'
import styled from 'styled-components'

const ScrollList = styled(List)`
  overflow: hidden;
  overflow-y: auto;
  border-radius: 0;
  background-color: #efeeeb;

  & li:last-of-type {
    border-bottom: none !important;
  }
`

export default ScrollList
