import { getDatabase } from '../..'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { isSynced } from '../../../utils/helpers/sync-executions'
import { shouldSyncByDate } from '../../common/sync'

const tableName = TABLE_NAMES.EXECUTION_SYNC_STATUS

export const shouldFinishExecutionsQuery = async () => {
  const db = getDatabase()

  const executionStatuses = await db[tableName].with({
    execution: 'executionId'
  })
  const result = executionStatuses.filter((queryResult) => {
    const childrensAreSynced = isSynced(queryResult)
    return (
      queryResult.execution.status === EXECUTION_STATUS.PREFINISH &&
      childrensAreSynced &&
      !shouldSyncByDate(queryResult.execution)
    )
  })

  return result
}

export const readExecutionSyncStatus = async (executionId) => {
  const db = getDatabase()

  const executionStatus = await db[tableName]
    .where('executionId')
    .equals(executionId)
    .first()
  return executionStatus
}

export const readAllExecutionSyncStatus = async () => {
  const db = getDatabase()

  return db[tableName].toArray()
}
