import React from 'react'

import { IconContainer } from '../../../containers/icon'

export const WarningIcon = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 33 32'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='Procedimiento'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Artboard'
        transform='translate(-435.000000, -469.000000)'
        fillRule='nonzero'
      >
        <g id='warning-icon' transform='translate(435.192773, 469.069309)'>
          <path
            d='M31.296381,26.3585519 L18.2756845,1.49288026 L18.2756845,1.49288026 C17.794719,0.575065767 16.8441237,-3.30402372e-13 15.8079233,-3.30402372e-13 C14.7717229,-3.30402372e-13 13.8211276,0.575065767 13.3401621,1.49288026 L0.318072569,26.3585519 C-0.134339074,27.2222634 -0.102314302,28.2596366 0.402512391,29.0937972 C0.907339085,29.9279577 1.81150523,30.4373579 2.78653028,30.4373579 L28.8279233,30.4373579 C29.8029484,30.4373579 30.7071145,29.9279577 31.2119412,29.0937972 C31.7167679,28.2596366 31.7487927,27.2222634 31.296381,26.3585519 Z'
            id='Path'
            fill={color}
          />
          <path
            d='M15.773794,22.0861141 C16.9150936,22.087475 17.851935,22.9892771 17.896779,24.1296962 C17.9186061,24.6825095 17.7165825,25.220767 17.3364119,25.6226991 C16.9562413,26.0246311 16.4300503,26.2562723 15.8768785,26.2652186 L15.8378736,26.2652186 C14.696387,26.2615587 13.7609976,25.3581274 13.7176746,24.2174574 C13.6973908,23.6659322 13.8996801,23.1294071 14.2790415,22.7285625 C14.6584029,22.3277179 15.1829833,22.0962108 15.734789,22.0861141 L15.773794,22.0861141 Z M15.8072268,8.8383529 C16.5765787,8.8383529 17.2002616,9.46203584 17.2002616,10.2313877 L17.2002616,18.5895967 C17.2002616,19.3589486 16.5765787,19.9826315 15.8072268,19.9826315 C15.0378749,19.9826315 14.414192,19.3589486 14.414192,18.5895967 L14.414192,10.2313877 C14.414192,9.46203584 15.0378749,8.8383529 15.8072268,8.8383529 Z'
            id='Combined-Shape'
            fill='#fff'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default WarningIcon
