import moment from 'moment'

import { getTranslation } from '../../../../i18n/getTranslation'
import { store } from '../../../../store'
import { changeCurrentStep } from '../../../../store/execution/actions'
import { sortBookmarks } from '../../../../utils/helpers/steps'
import { EXECUTION_COMPONENT_TYPES } from '../../../constants/execution/components'

export function getStepsInfoFromComments(commentSteps, steps, onSelectStep) {
  return Object.keys(commentSteps)
    .sort(sortBookmarks)
    .map((commentStep) => {
      if (commentSteps[commentStep].every((comment) => comment.deletedAt)) {
        return null
      }
      const node = steps.find((step) => step.id === commentStep)
      if (!node)
        throw new Error('There are not any nodes with ID:' + commentStep)

      return {
        id: node.id,
        stepIndex: node.stepIndex,
        title: node.title,
        handleClick: () => {
          onSelectStep([node.id])
          store.dispatch(changeCurrentStep(node.id))
        }
      }
    })
    .filter((data) => data)
}

export const formatStepCommentOnReview = (
  oldComponentValue,
  componentValue,
  type,
  user
) => {
  const dateFormat = getTranslation('dateFormat')
  const timeFormat = getTranslation('timeFormat')

  let oldValue = oldComponentValue
  let newValue = componentValue

  switch (type) {
    case EXECUTION_COMPONENT_TYPES.SIGNATURE:
      oldValue = oldComponentValue?.status
      newValue = componentValue?.status
      break
    case EXECUTION_COMPONENT_TYPES.DATE:
      oldValue =
        oldComponentValue && moment(oldComponentValue).format(dateFormat)
      newValue = componentValue && moment(componentValue).format(dateFormat)
      break
    case EXECUTION_COMPONENT_TYPES.TIME:
      oldValue =
        oldComponentValue && moment(oldComponentValue).format(timeFormat)
      newValue = componentValue && moment(componentValue).format(timeFormat)
      break
  }

  return getTranslation('componentChangedFromTo', {
    params: {
      type: getTranslation(type),
      oldValue: oldValue || getTranslation('empty'),
      newValue,
      date: `${moment(new Date()).format(dateFormat)} ${moment(
        new Date()
      ).format(timeFormat)}`,
      user: user?.name ?? 'unknown'
    }
  })
}
