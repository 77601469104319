import { EXECUTION_STATUS } from '../../utils/constants/execution'
import { TABLE_NAMES } from '../../utils/constants/localdb'
import { erro } from '../../utils/logger'
import { filterByUpdatedAt } from '../helpers'
import { setModuleIsNotSyncing } from '../userInterface/slice'

import { readExecutionManeuvers, writeExecutionManeuvers } from './localDb'
import {
  setExecutionManeuvers,
  updateLocaldbQueue,
  setManeuverState,
  setSyncedAt
} from './slice'

export function saveExecutionManeuvers(maneuvers) {
  return async (dispatch) => {
    dispatch(setExecutionManeuvers({ maneuvers }))
    dispatch(updateLocaldbQueue({ maneuver: maneuvers, resetQueue: true }))
  }
}

export function loadExecutionManeuvers(executionId) {
  return async (dispatch) => {
    if (!executionId) {
      dispatch(setExecutionManeuvers({ maneuvers: null }))
    } else {
      const executionManeuvers = await readExecutionManeuvers(executionId)
      dispatch(setExecutionManeuvers({ maneuvers: executionManeuvers }))
    }
  }
}

export function changeManeuverState({ maneuverId, active }) {
  return async (dispatch, getState) => {
    const { executionManeuvers, execution } = getState()
    const { maneuvers } = executionManeuvers

    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    const now = new Date().valueOf()

    if (!isShared) {
      const maneuver = maneuvers.find((m) => m.id === maneuverId)

      if (!maneuver) return

      const newManeuver = {
        ...maneuver,
        active,
        updatedAt: now
      }
      dispatch(updateLocaldbQueue({ maneuver: newManeuver }))
    }

    dispatch(setManeuverState({ id: maneuverId, active, now }))
  }
}

export function setSyncDate({ entities, syncedAt }) {
  return async (dispatch, getState) => {
    const {
      execution,
      executionManeuvers: { maneuvers }
    } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    const entitiesNotInState = entities.filter(
      (entity) => entity.executionId !== execution.execution?.id
    )

    if (entitiesNotInState.length > 0) {
      try {
        await writeExecutionManeuvers(
          entitiesNotInState.map((e) => ({ ...e, syncedAt }))
        )
      } catch (error) {
        erro('Error writing execution maneuvers', error)
      }
    }

    const syncedManeuvers = filterByUpdatedAt(entities, maneuvers)

    if (syncedManeuvers?.length) {
      try {
        if (!isShared) {
          const maneuversToQueue = syncedManeuvers.map((_maneuver) => ({
            ..._maneuver,
            syncedAt
          }))
          dispatch(updateLocaldbQueue({ maneuver: maneuversToQueue }))
        }
        dispatch(setSyncedAt({ entities: syncedManeuvers, syncedAt }))
      } catch (error) {
        erro('Error syncing execution maneuvers', error)
      }
    } else {
      dispatch(
        setModuleIsNotSyncing({ moduleName: TABLE_NAMES.EXECUTION_MANEUVERS })
      )
    }
  }
}
