import { Button, Popover } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { throttle } from 'throttle-debounce'

import Lock from '../../../../components/icons/lock'
import { useTranslation } from '../../../../hooks/useTranslation'
import { getIsReview } from '../../../../store/execution/selectors'
import { getProcedure } from '../../../../store/procedure/selectors'
import { getUser } from '../../../../store/user-management/selectors'
import { SIGNATURE_HOUR_VISIBLE } from '../../../../utils/constants/config'
import {
  EXECUTION_SIGNATURE_STATUS,
  EXECUTION_SIGNATURE_STATUS_ICON
} from '../../../../utils/constants/execution/components'
import { EXECUTION_SIGNATURE_STATUS_COLOR } from '../../../../utils/constants/styles'
import TimeIconView from '../../../icons/time'
import UserSignatureCodeModal from '../../../modal/user-signature-code-modal'
import Translation from '../../../translations'

import SignatureStatusButton from './signature-status-button'

const throttleHidePopover = throttle(100, (setVisible, mounted) => {
  if (mounted) setVisible(false)
})

const Signature = ({
  component,
  executionComponent,
  onComponentChange,
  disabled,
  className
}) => {
  const [visible, setVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [signatureCodeUser, setSignatureCodeUser] = useState(null)
  const dateFormat = useTranslation('dateFormat')
  const timeFormat = useTranslation('timeFormat')
  const procedure = useSelector(getProcedure)
  const isReview = useSelector(getIsReview)
  const user = useSelector(getUser)
  const blockedSignature =
    user.role?.roleLevel.level > component.options?.roleLevel
  const SelectedStatusIcon =
    EXECUTION_SIGNATURE_STATUS_ICON[executionComponent?.value?.status]

  useEffect(() => {
    let mounted = true
    // Cerramos el popover de las firmas cuando el usuario hace scroll para evitar que se queden flotando

    document
      .querySelector('#procedure-scroll')
      .addEventListener('scroll', () => {
        throttleHidePopover(setVisible, mounted)
      })
    return () => {
      mounted = false
      document
        .querySelector('#procedure-content')
        ?.removeEventListener('scroll', throttleHidePopover)
    }
  }, [visible])

  const getComponentValue = () => {
    if (executionComponent?.value?.supervisorInitials) {
      const {
        supervisorInitials: _si,
        supervisor: _s,
        code: _c,
        supervisorEmail: _se,
        ...restComponentValue
      } = executionComponent?.value ?? {}

      return restComponentValue
    }

    return executionComponent?.value
  }

  const getDataReviewOrBlocked = (supervisorData) => {
    if (isReview && !blockedSignature) {
      return {
        isCorrect: true
      }
    }

    if (isReview && blockedSignature) {
      return {
        isCorrect: true,
        ...supervisorData
      }
    }

    if (!isReview && blockedSignature) {
      return {
        ...supervisorData
      }
    }

    return {}
  }

  const onSignatureStatusButtonChange = ({ id, value }) => {
    setVisible(false)

    const componentValue = getComponentValue()
    const {
      supervisorInitials,
      supervisor,
      code,
      supervisorEmail,
      ...restValue
    } = value

    const newValues = getDataReviewOrBlocked({
      code,
      supervisor,
      supervisorEmail,
      supervisorInitials
    })

    onComponentChange({
      id,
      value: {
        ...componentValue,
        ...restValue,
        ...newValues,
        date: new Date().valueOf()
      }
    })
  }

  function onCodeModalOk(supervisor, code, email, initials) {
    setModalVisible(false)
    setVisible(true)
    setSignatureCodeUser({ email, initials, supervisor, code })
  }

  return (
    <>
      {blockedSignature && (
        <UserSignatureCodeModal
          visible={modalVisible}
          onOk={onCodeModalOk}
          onCancel={() => {
            setModalVisible(false)
          }}
          signatureRoleLevel={component.options?.roleLevel}
          signatureId={component.id}
        />
      )}
      <Popover
        placement='left'
        open={!disabled && visible}
        onOpenChange={(visible) => {
          if (disabled) return

          if (blockedSignature) {
            setModalVisible(true)
            setVisible(false)
          } else {
            setVisible(visible)
          }
        }}
        trigger='click'
        getPopupContainer={() => document.querySelector('#procedure-content')}
        content={
          <ButtonsContainer>
            {Object.values(EXECUTION_SIGNATURE_STATUS).map((status) => {
              return (
                <SignatureStatusButton
                  key={status}
                  status={status}
                  onComponentChange={onSignatureStatusButtonChange}
                  component={component}
                  doubleCheckSignature={procedure?.doubleCheckSignature}
                  signatureCodeUser={signatureCodeUser}
                  setSignatureCodeUser={setSignatureCodeUser}
                />
              )
            })}
            {executionComponent?.value?.status && (
              <>
                <SeparatorLine />
                <SignatureStatusButton
                  key='clean'
                  status={null}
                  signatureValue={executionComponent?.value}
                  onComponentChange={(...args) => {
                    setVisible(false)
                    onComponentChange(...args)
                  }}
                  component={component}
                  doubleCheckSignature={procedure?.doubleCheckSignature}
                  signatureCodeUser={signatureCodeUser}
                  setSignatureCodeUser={setSignatureCodeUser}
                />
              </>
            )}
          </ButtonsContainer>
        }
      >
        <SignatureMainButton
          disabled={disabled}
          className={className}
          data-incorrectsignature={
            executionComponent?.value?.isCorrect === false
          }
          data-notvalidated={
            executionComponent?.value?.isCorrect === undefined &&
            executionComponent?.value?.code !== undefined
          }
        >
          {executionComponent?.value?.status ? (
            <Flex>
              <SelectedSignatureIcon
                data-sign-status={executionComponent?.value?.status}
                color={
                  EXECUTION_SIGNATURE_STATUS_COLOR[
                    executionComponent?.value?.status
                  ]
                }
              >
                <IconContainer>
                  <SelectedStatusIcon size='16px' />
                </IconContainer>
              </SelectedSignatureIcon>
              <SignedInfoContainer className='signed-info'>
                <InfoContainer>
                  <InitialsMainUser className='signed-initial'>
                    {executionComponent?.value.supervisorInitials ??
                      executionComponent?.value.initials}
                  </InitialsMainUser>
                  {executionComponent?.value?.supervisorInitials && (
                    <InitialsSubUser className='signed-initial'>
                      {executionComponent?.value?.initials}
                    </InitialsSubUser>
                  )}
                </InfoContainer>
                <DateTimeContainer
                  data-issupervised={
                    !!executionComponent?.value.supervisorInitials
                  }
                >
                  {executionComponent?.value?.supervisorInitials &&
                    executionComponent?.value?.isCorrect === undefined && (
                      <p style={{ margin: 0 }}>
                        <TimeIconView size='12' color='black' />
                      </p>
                    )}
                  <CustomSection>
                    <TextDate className='signed-date'>
                      {moment(new Date(executionComponent.value.date)).format(
                        dateFormat
                      )}
                    </TextDate>
                    {SIGNATURE_HOUR_VISIBLE && (
                      <TextDate className='signed-hour'>
                        {moment(new Date(executionComponent.value.date)).format(
                          timeFormat
                        )}
                      </TextDate>
                    )}
                  </CustomSection>
                </DateTimeContainer>
              </SignedInfoContainer>
            </Flex>
          ) : blockedSignature ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Lock size={15} style={{ marginRight: '10px' }} />
              <Translation id='sign' />
            </div>
          ) : (
            <Translation id='sign' />
          )}
        </SignatureMainButton>
      </Popover>
    </>
  )
}

const CustomSection = styled.section`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2px;
  flex-wrap: nowrap;

  @media print {
    flex-wrap: wrap;
    height: 20px !important;
  }
`

const SignatureMainButton = styled(Button)`
  border-radius: 2px;
  border-width: 2px;
  border-color: ${({
    'data-incorrectsignature': incorrectSignature,
    'data-notvalidated': notValidated
  }) =>
    incorrectSignature
      ? '#FF4545 !important'
      : `#EDECE8 #EDECE8 ${
          notValidated ? '#EDECE8' : '#000'
        } #EDECE8 !important`};
  border-style: solid;
  width: 120px;
  min-height: 62px;
  background-color: ${({ incorrectSignature }) =>
    incorrectSignature ? '#ffe8e8 !important;' : '#FAFAFA !important;'};
  /* Necesario para que se vea el color negro aunque el botón esté desactivado */
  color: #000 !important;
  padding: 0 5px !important;
  &:hover {
    border: 2px solid #bc2ac9 !important;
  }
  &:focus {
    border-bottom: none;
    border: 2px solid #bc2ac9 !important;
  }

  @media print {
    min-height: 0px !important;
    height: 20px !important;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  @media print {
    height: 20px !important;
  }
`

const InitialsMainUser = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin: 0;

  @media print {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
  }
`

const InitialsSubUser = styled.p`
  padding: 0px 4px;
  margin: 0;

  background: #efeeeb;
  border-radius: 6px;

  font-size: 12px;
`

const SelectedSignatureIcon = styled.div`
  background-color: ${({ color }) => color || 'transparent'};
  padding: 6px;
  border-radius: 50%;

  @media print {
    width: 13px !important;
    height: 13px !important;
    padding: 0px !important;
    margin-right: 2px;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    width: 13px !important;
    height: 13px !important;

    & > svg {
      width: 8px !important;
      height: 8px !important;
    }
  }
`

const TextDate = styled.p`
  font-size: 8px;
  line-height: 0;
  margin: 0;
`

const SeparatorLine = styled.div`
  width: 2px;
  height: 40px;
  background-color: #d9d9d9;
  margin: 0px 0px 0px 10px;
`

const SignedInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media print {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    width: 100%;
  }
`

const DateTimeContainer = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;

  @media print {
    width: 50%;
  }
`

const InfoContainer = styled.section`
  display: flex;
  gap: 2px;
  align-items: center;

  @media print {
    width: 50%;
  }
`

export default Signature
