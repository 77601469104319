import { axiosInstance } from '../../..'
import { getDeviceId } from '../../../../utils/helpers/device'

export async function requestProcedure(
  id,
  referencedProceduresIds,
  sendDeviceId
) {
  const deviceId = sendDeviceId ? getDeviceId() : undefined

  return axiosInstance.get('procedures/' + id, {
    params: { refIds: referencedProceduresIds, deviceId }
  })
}

export async function requestProcedures({
  skip,
  // TODO: quitar valor por defecto
  take = Number.MAX_SAFE_INTEGER,
  orderBy,
  orderDirection,
  modifiedAfter,
  key,
  keys,
  id,
  status,
  publishedVersionOnly,
  select
} = {}) {
  return axiosInstance.get('/procedures', {
    params: {
      skip,
      take,
      orderBy,
      orderDirection,
      modifiedAfter,
      key,
      keys,
      id,
      status,
      publishedVersionOnly,
      select
    }
  })
}
