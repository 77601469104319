import { axiosInstance } from '../../../..'

export async function changePassword(email, password, newPassword) {
  const passwordData = { email, password, newPassword }

  try {
    await axiosInstance.put('/users/changePassword', passwordData)
    return
  } catch (e) {
    throw Error(e)
  }
}

export async function resetPassword(userId) {
  const response = await axiosInstance.put(`/users/resetPassword/${userId}`)

  return response.data
}
