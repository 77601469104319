import styled from 'styled-components'

const IconsComment = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20.5px;
`

export default IconsComment
