import { message } from 'antd'
import Mark from 'mark.js'
import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useDeferredValue,
  useContext
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGlobal } from 'reactn'
import styled from 'styled-components'

import StepContext from '../../../../context/step'
import { useIsVisible } from '../../../../hooks/use-is-visible'
import { getTranslation } from '../../../../i18n/getTranslation'
import { store } from '../../../../store'
import { changeCurrentStep } from '../../../../store/execution/actions'
import {
  getCurrentStep,
  getExecutionQaStatus,
  getExecutionStatus,
  getIsMerge,
  getIsReview
} from '../../../../store/execution/selectors'
import { getExecutionComponents } from '../../../../store/executionComponents/selectors'
import {
  markStepAction,
  unmarkStepAction
} from '../../../../store/executionSteps/actions'
import { getExecutionStep } from '../../../../store/executionSteps/selectors'
import { setHistoricalCountStep } from '../../../../store/historical-values/slice'
import {
  getCurrentConflict,
  getCurrentStepConflict
} from '../../../../store/mergeExecution/selectors'
import { getUser } from '../../../../store/user-management/selectors'
import { setIsWorking } from '../../../../store/userInterface/slice'
import { DOUBLE_CLICK_TO_COMPLETE_STEP } from '../../../../utils/constants/config'
import {
  EXECUTION_QA_STATUS_CAN_EDIT,
  EXECUTION_STATUS,
  EXECUTION_STATUS_IS_RUNNING
} from '../../../../utils/constants/execution'
import {
  EXECUTION_COMPONENT_TYPES,
  EXECUTION_SIGNATURE_STATUS_IS_FINAL
} from '../../../../utils/constants/execution/components'
import { getColorFromSeed } from '../../../../utils/helpers/styles'
import ManeuverTag from '../../../tags/maneuver'
import ProcedureComponentPortal from '../../components/portal'
import StepMenuPortal from '../menu/index'

const markStep = (id, isDone, isRead) => {
  const { id: userId } = getUser(store.getState())

  store.dispatch(setIsWorking(true))
  if (isDone) {
    store.dispatch(unmarkStepAction({ stepId: id, userId }))
  } else if (isRead) {
    store.dispatch(markStepAction({ stepId: id, userId, action: 'completed' }))
  } else {
    store.dispatch(markStepAction({ stepId: id, userId, action: 'read' }))
  }
}

const StepContent = ({
  procedureStep,
  usedBy,
  components,
  procedure,
  disabled,
  isManeuverParent,
  isManeuverChild,
  isManeuverActive,
  maneuverId,
  componentsThatAffectUsersInConditionals,
  loadingPrint,
  onSelectStep
}) => {
  const ref = useRef()
  const executionComponents = useSelector(getExecutionComponents)
  const executionStatus = useSelector(getExecutionStatus)
  const executionQaStatus = useSelector(getExecutionQaStatus)
  const isReview = useSelector(getIsReview)
  const isMerge = useSelector(getIsMerge)
  const currentConflict = useSelector(getCurrentConflict)
  const currentStep = useSelector(getCurrentStep)
  const actualStepConflict = useSelector(getCurrentStepConflict)
  const currentUser = useSelector(getUser)
  const dispatch = useDispatch()
  const [searchHighlight] = useGlobal('searchHighlight')

  const visible = useIsVisible(ref)

  const canRender = useDeferredValue(visible)

  const { stepId, html, hasReadouts, historicalCount } = procedureStep || {}

  const executionStep = useSelector((store) => getExecutionStep(store, stepId))

  const { readAt, completedAt, annotation, comments } = executionStep || {}

  const isExecutionRunning = useMemo(
    () => EXECUTION_STATUS_IS_RUNNING[executionStatus],
    [executionStatus]
  )
  const isExecutionShared = useMemo(
    () => EXECUTION_STATUS.SHARED === executionStatus,
    [executionStatus]
  )
  const isCurrent = useMemo(
    () =>
      isExecutionShared
        ? currentStep === stepId && usedBy && usedBy.user.id === currentUser?.id
        : currentStep === stepId,
    [currentStep, isExecutionShared, stepId, usedBy, currentUser.id]
  )
  const commentCount = useMemo(
    () => comments?.filter((comment) => !comment.deletedAt).length || 0,
    [comments]
  )
  const isActualStepConflict = useMemo(() => {
    return stepId === actualStepConflict
  }, [actualStepConflict, stepId])
  const borderColor = useMemo(
    () => (isCurrent ? '#007EC3' : getColorFromSeed(usedBy?.user.id)),
    [isCurrent, usedBy?.user.id]
  )
  const reviewerCanMakeChanges = useMemo(
    () =>
      isReview &&
      (EXECUTION_QA_STATUS_CAN_EDIT[executionQaStatus] ??
        EXECUTION_STATUS[executionStatus] === EXECUTION_STATUS.FINISHED),
    [executionQaStatus, executionStatus, isReview]
  )
  const enableInteraction = useMemo(
    () => (isReview ? reviewerCanMakeChanges : isExecutionRunning),
    [isReview, isExecutionRunning, reviewerCanMakeChanges]
  )

  const { toggleHistoricalMode } = useContext(StepContext)

  useEffect(() => {
    if (ref.current) {
      const instance = new Mark(ref.current)
      if (searchHighlight) {
        instance.unmark()
        instance.mark(searchHighlight)
      } else {
        instance.unmark(searchHighlight)
      }
    }
  }, [ref, searchHighlight])

  // Función para cambiar de paso o marcarlo como read/done.
  // Solo se puede marcar como read/done si el paso es el actual haciendo
  // click sobre él, o doble click si DOUBLE_CLICK_TO_COMPLETE_STEP es true
  const onClickStep = useCallback(
    (event) => {
      const isDoubleClick = event.detail === 2

      if (!isExecutionRunning && !isReview) {
        message.warn(getTranslation('interactExecutionNotRunning'))
        return
      }

      if (isMerge) {
        message.warn(getTranslation('cannotMakeChangesInMerge'))
        return
      }

      if (isReview && !reviewerCanMakeChanges) {
        message.warn(getTranslation('reviewerCantMakeChanges'))
        return
      }

      if (isReview && reviewerCanMakeChanges) {
        message.warn(
          getTranslation('interactExecutionDuringRevisionNotRunning')
        )
      }

      if (disabled) {
        message.warn(getTranslation('clickedStepIsDisabled'))
        return
      }

      if (usedBy && usedBy.user.id !== currentUser?.id) {
        message.warn(getTranslation('clickedStepIsOccupied'))
        return
      }

      if (!isCurrent) {
        toggleHistoricalMode(null)
        dispatch(
          setHistoricalCountStep({ historicalCountStep: historicalCount })
        )
        dispatch(changeCurrentStep(stepId))
        return
      }

      if (DOUBLE_CLICK_TO_COMPLETE_STEP && isDoubleClick) {
        markStep(stepId, completedAt, readAt)
        return
      }

      if (!DOUBLE_CLICK_TO_COMPLETE_STEP && !isDoubleClick) {
        markStep(stepId, completedAt, readAt)
        return
      }
    },
    [
      toggleHistoricalMode,
      disabled,
      dispatch,
      stepId,
      isCurrent,
      completedAt,
      isExecutionRunning,
      isMerge,
      readAt,
      isReview,
      reviewerCanMakeChanges,
      usedBy,
      historicalCount,
      currentUser.id
    ]
  )

  // Return whether any signature is final or not
  const anySignatureIsFinal = useMemo(() => {
    if (
      components.some((stpComp) => stpComp.stepId === stepId) &&
      executionComponents
    ) {
      const signaturesInStep = components.filter(
        (c) => c.type === EXECUTION_COMPONENT_TYPES.SIGNATURE
      )

      const signaturesInStepWithValue = signaturesInStep.map((signStep) => {
        const executionComponent = executionComponents.find(
          (execComp) =>
            execComp.procedureComponentId === signStep.procedureComponentId
        )
        return { ...signStep, value: executionComponent?.value }
      })

      return (
        signaturesInStepWithValue.length &&
        signaturesInStepWithValue.every((component) => {
          return (
            component.value &&
            EXECUTION_SIGNATURE_STATUS_IS_FINAL[component.value?.status]
          )
        })
      )
    }
  }, [components, executionComponents, stepId])

  let className = ''
  if (readAt) {
    className += ' is-read'
  }

  if (completedAt) {
    className += ' is-done'
  }

  return (
    <>
      <Step
        id={stepId}
        ref={ref}
        className={className}
        data-iscurrent={isCurrent}
        data-iscurrentconflict={isActualStepConflict}
        data-bordercolor={!disabled && borderColor}
        dangerouslySetInnerHTML={{ __html: html }}
        onClick={onClickStep}
        disabled={disabled}
      />
      {canRender || loadingPrint
        ? components.map((c) => {
            return (
              <ProcedureComponentPortal
                key={c.id}
                stepId={stepId}
                component={c}
                disabled={
                  disabled ||
                  !enableInteraction ||
                  !isCurrent ||
                  componentsThatAffectUsersInConditionals.includes(c.id)
                }
                anySignatureIsFinal={anySignatureIsFinal}
                procedure={procedure}
                highlighted={currentConflict === c.procedureComponentId}
                onSelectStep={onSelectStep}
              />
            )
          })
        : null}
      {canRender || loadingPrint ? (
        <>
          <StepMenuPortal
            stepId={stepId}
            isManeuverActive={isManeuverActive}
            maneuverId={maneuverId}
            isManeuverParent={isManeuverParent}
            procedure={procedure}
            commentCount={commentCount}
            annotation={annotation}
            isCurrent={isCurrent}
            isExecutionShared={isExecutionShared}
            isExecutionRunning={isExecutionRunning}
            hasReadouts={hasReadouts}
            hasHistorical={historicalCount > 0}
          />
          {isManeuverParent || (isManeuverChild && !disabled) ? (
            <ManeuverTag stepId={stepId} />
          ) : null}
        </>
      ) : null}
    </>
  )
}

const Step = styled.div`
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 8px;

  & .step-circle {
    text-indent: initial;
    position: relative;
    display: inline-block;
    text-align: center;
    min-width: 40px;
    border-radius: 99999px;
  }

  & .step-circle .step-line {
    position: absolute;
    top: 0;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    transform: rotate(45deg);
  }

  & .step-circle .step-line::before {
    content: ' ';
    display: inline-block;
    width: 100%;
    height: 0px;
    border-top: 1px solid transparent;
  }

  ${({ 'data-bordercolor': borderColor, 'data-iscurrent': isCurrent }) =>
    borderColor
      ? `
    box-shadow: 0px 0px 0px ${
      isCurrent ? '2px' : '3px'
    } #f0f2f5, 0px 0px 0px 4px ${borderColor};
  `
      : ''}

  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0.5;
  `
      : ''}

${({ 'data-iscurrentconflict': isCurrentConflict }) =>
    isCurrentConflict
      ? `
    background: #FFFAF5;

    border: 1px solid #FAC8AA;
    border-radius: 4px;
  `
      : ''}

@media print {
    box-shadow: none;
  }
`

export default StepContent
