import isEqual from 'lodash.isequal'
import uniqWith from 'lodash.uniqwith'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLocalExecutions } from '../../../hooks/data/useLocalExecutions'
import { readFiles } from '../../../localdb/files/read'
import { getExecution } from '../../../store/execution/selectors'
import { getProcedure } from '../../../store/procedure/selectors'
import { getExecutionJumpsWithReturn } from '../../../store/userInterface/selectors'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { EXECUTION_COMPONENT_TYPES } from '../../../utils/constants/execution/components'
import Tabs from '../../tabs/execution'
import Translation from '../../translations'

import Documents from './documents'
import Executions from './executions'
import Jumps from './jumps'

const ExecutionTabs = ({ onClose, onSelectStep }) => {
  const [executions] = useLocalExecutions({ withProcedure: true })
  const execution = useSelector(getExecution)
  const jumpsWithReturn = useSelector(getExecutionJumpsWithReturn)

  const filteredExecutions = executions?.filter(
    (e) =>
      e.id !== execution?.id &&
      [EXECUTION_STATUS.EXECUTING, EXECUTION_STATUS.PAUSED].includes(e.status)
  )

  const procedure = useSelector(getProcedure)
  const [files, setFiles] = useState(null)

  useEffect(() => {
    const fn = async () => {
      const components = procedure?.components?.filter(
        (c) => c.type === EXECUTION_COMPONENT_TYPES.FILE_REFERENCE
      )
      const fileReferenceComponents = uniqWith(components, isEqual)
      const files = await readFiles(
        fileReferenceComponents.map((f) => f.options.fileId)
      )
      setFiles(
        files
          .filter((f) => f !== undefined)
          .map((f) => ({ id: f.id, name: f.originalName }))
      )
    }

    fn()
  }, [procedure])

  const currentJumps = execution ? jumpsWithReturn[execution.id] || [] : []

  return (
    <Tabs
      tabs={[
        {
          buttonContent: <Translation id='executions' />,
          content: (
            <Executions
              filteredExecutions={filteredExecutions}
              executions={executions}
            />
          ),
          counter: filteredExecutions?.length
        },
        {
          buttonContent: <Translation id='documents' />,
          content: <Documents files={files} />,
          counter: files?.length
        },
        {
          buttonContent: <Translation id='jumps' />,
          content: (
            <Jumps
              onClose={onClose}
              currentJumps={currentJumps}
              executionId={execution?.id}
              onSelectStep={onSelectStep}
            />
          ),
          counter: currentJumps?.length
        }
      ]}
    />
  )
}

export default ExecutionTabs
