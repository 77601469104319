import React from 'react'
import { useSelector } from 'react-redux'

import { getConnectionStatus } from '../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import BasePage from '../../../views/base-page'
import Translation from '../../../views/translations'
import { NoConnection } from '../../NoConnection'

import { ExecutionsTable } from './ExecutionsTable'

export const DashboardMainView = () => {
  const connectionStatus = useSelector(getConnectionStatus)

  return connectionStatus !== CONNECTION_STATUS.ONLINE ? (
    <NoConnection />
  ) : (
    <BasePage title={<Translation id='myWorkForToday' />}>
      <ExecutionsTable />
    </BasePage>
  )
}
