import { SearchOutlined } from '@ant-design/icons'
import { Divider, Form, Input, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'







import { cancelMerge } from '../../application/execution/merge'
import ExecutionDataCard from '../../containers/execution-data-card'
import useExecutedFor from '../../hooks/use-executed-for'
import { getTranslation } from '../../i18n/getTranslation'
import { readExecutionGoals } from '../../localdb/execution-goal/read'
import { getExecution } from '../../store/execution/selectors'
import { setExecution } from '../../store/execution/slice'
import { notifyError } from '../../utils/notifications'
import Modal from '../modal'
import Translation from '../translations'

const { TextArea } = Input

const ExecutionMergeModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const execution = useSelector(getExecution)
  const { unitId, executionGoalId, otherExecutionGoal } = execution

  const [isOtherExecutionGoal, setIsOtherGoal] = useState(otherExecutionGoal)
  const [executionGoals, setExecutionGoals] = useState([])
  const [loading, setIsLoading] = useState(false)

  const [form] = Form.useForm()
  const unitFormValue = Form.useWatch('executionUnit', form)

  const executionGoalFormValue = Form.useWatch('executionGoal', form)

  const {
    executionOneId,
    executionTwoId,
    executionOnePlant,
    executionTwoPlant,
    executionOneUnit,
    executionTwoUnit,
    executionOneExecutionGoal,
    executionTwoExecutionGoal,
    executionOneCreatedAt,
    executionTwoCreatedAt
  } = execution?.merge ?? {}

  const areMergeGoalsEmpty =
    !executionOneExecutionGoal && !executionTwoExecutionGoal

  const areUnitsEmpty = !executionOneUnit && !executionTwoUnit

  const loadExecutionGoals = async () => {
    const executionGoalsData = await readExecutionGoals()
    executionGoalsData.push({ name: getTranslation('other'), id: 'other' })
    setExecutionGoals(executionGoalsData)
  }

  useEffect(() => {
    loadExecutionGoals()
  }, [])

  const [executedForList] = useExecutedFor()

  const onChangeExecutionGoal = (value) => {
    if (value === 'other') {
      setIsOtherGoal(true)
    } else {
      setIsOtherGoal(false)
    }
  }

  const handleSaveMergeModal = async (values) => {
    const mergeExecution = {
      ...execution,
      executionGoalId: isOtherExecutionGoal ? null : values.executionGoal,
      unitId: values.executionUnit,
      otherExecutionGoal: isOtherExecutionGoal
        ? values.otherExecutionGoal
        : null
    }
    dispatch(setExecution({ execution: mergeExecution }))
    setVisible(false)
  }

  const handleCancelMergeModal = async () => {
    setIsLoading(true)
    return cancelMerge({
      executionId: execution.id,
      executionOneId,
      executionTwoId
    })
      .then(() => {
        setVisible(false)
        setIsLoading(false)
        history.replace('/dashboard/admin-executions')
      })
      .catch((error) => {
        setIsLoading(false)
        notifyError(error)
      })
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancelMergeModal}
      formId='execution-merge-form'
      okButtonText={<Translation id='buttonSave' />}
      cancelButtonText={<Translation id='cancel' />}
      keyboard={false}
      maskClosable={false}
      loadingCancel={loading}
    >
      <Form
        form={form}
        name='execution-merge-form'
        layout='vertical'
        onFinish={handleSaveMergeModal}
        initialValues={{
          executionGoal: isOtherExecutionGoal ? 'other' : executionGoalId,
          otherExecutionGoal: otherExecutionGoal,
          executionUnit: unitId
        }}
      >
        <h3 style={{ fontFamily: 'inherit' }}>
          <Translation id='mergeModalTitle' />
        </h3>
        <Form.Item
          label={<Translation id='execution.plant.unit' />}
          name='executionUnit'
          validateStatus={
            unitId || unitFormValue || areUnitsEmpty ? null : 'error'
          }
          rules={[
            {
              required: true,
              message: <Translation id='fieldRequired' />
            }
          ]}
        >
          <Select
            showSearch
            placeholder={
              <>
                <SearchOutlined />{' '}
                <Translation id='execution.plant.unit.placeholder' />
              </>
            }
            allowClear
            options={executedForList.map((executedFor) => ({
              label: executedFor.name,
              value: executedFor.id
            }))}
            filterOption={(inputValue, option) =>
              option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />
        </Form.Item>

        <Form.Item
          label={<Translation id='execution.goals' />}
          name='executionGoal'
          validateStatus={
            executionGoalId ||
            executionGoalFormValue ||
            form.isFieldTouched('executionGoal') ||
            areMergeGoalsEmpty
              ? null
              : 'error'
          }
        >
          <Select
            showSearch
            placeholder={
              <>
                <SearchOutlined /> <Translation id='execution.goals' />
              </>
            }
            allowClear
            options={executionGoals.map((executionGoal) => ({
              label: executionGoal.name,
              value: executionGoal.id
            }))}
            filterOption={(inputValue, option) =>
              option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            onChange={onChangeExecutionGoal}
          />
        </Form.Item>
        {isOtherExecutionGoal && (
          <Form.Item name='otherExecutionGoal'>
            <TextArea rows={5} />
          </Form.Item>
        )}
      </Form>
      <h4 style={{ fontFamily: 'inherit' }}>
        <Translation id='mergedExecutionsTitle' />
      </h4>
      <ExecutionsInfo>
        <ExecutionDataCard
          id={executionOneId}
          startDate={executionOneCreatedAt}
          executedFor={`${executionOnePlant} ${executionOneUnit}`}
          goal={executionOneExecutionGoal}
        />
        <Divider />
        <ExecutionDataCard
          id={executionTwoId}
          startDate={executionTwoCreatedAt}
          executedFor={`${executionTwoPlant} ${executionTwoUnit}`}
          goal={executionTwoExecutionGoal}
        />
      </ExecutionsInfo>
    </Modal>
  )
}

const ExecutionsInfo = styled.div`
  background: #fafafa;
  padding: 1em;
`

export default ExecutionMergeModal
