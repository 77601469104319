import { useRemoteData } from '../useRemoteData'

export const useGlobalUnits = ({ disabled, search, take = 5 } = {}) => {
  return useRemoteData({
    url: '/procedures/units',
    disabled,
    search,
    take
  })
}
