import React from 'react'

import ColorTag from '../../../containers/color-tag'
import { createPortalManeuverTag } from '../../../utils/helpers/dom-handlers'

const tagElement = <ColorTag color='gray' style={{ marginRight: '5px' }} />

const ManeuverTag = ({ stepId }) => {
  return createPortalManeuverTag(tagElement, stepId)
}

export default ManeuverTag
