import { Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

import UnitsBadge from '../units-badge'

const EditableHeader = ({
  element,
  elementInEdition,
  setEdition,
  showBadge,
  badgeInfo,
  showAditionalInfo,
  additionalInfo
}) => {
  return (
    <>
      <HeaderContainer data-testid={`editable-header-${element.id}`}>
        <span>
          {elementInEdition?.id === element.id ? (
            <Input
              onClick={(e) => e.stopPropagation()}
              value={elementInEdition?.name}
              onChange={(e) =>
                setEdition({ ...elementInEdition, name: e.target.value })
              }
            />
          ) : (
            element.name
          )}
        </span>
        {showBadge ? <UnitsBadge numUnits={badgeInfo} /> : ''}
      </HeaderContainer>
      {showAditionalInfo ? <section>{additionalInfo}</section> : ''}
    </>
  )
}

const HeaderContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`

export default EditableHeader
