import React from 'react'

const LockReload = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 18 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.64 3.33435C12.83 2.42819 11.7639 1.78939 10.5828 1.50252C9.40173 1.21566 8.16138 1.29425 7.02595 1.72789C5.89053 2.16154 4.91357 2.92979 4.22441 3.93094C3.53526 4.93209 3.1664 6.11893 3.16667 7.33435V7.83435'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.69334 12.668C5.50335 13.5741 6.56946 14.2129 7.75054 14.4998C8.93162 14.7867 10.172 14.7081 11.3074 14.2744C12.4428 13.8408 13.4198 13.0725 14.1089 12.0714C14.7981 11.0702 15.167 9.88339 15.1667 8.66797V8.16797'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.6667 9.66797L15.1667 8.16797L16.6667 9.66797'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.66667 6.33398L3.16667 7.83398L1.66667 6.33398'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6667 6.83398H7.66667C7.11439 6.83398 6.66667 7.2817 6.66667 7.83398V9.83398C6.66667 10.3863 7.11439 10.834 7.66667 10.834H10.6667C11.219 10.834 11.6667 10.3863 11.6667 9.83398V7.83398C11.6667 7.2817 11.219 6.83398 10.6667 6.83398Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.66667 6.83464V5.66797C7.66667 5.27014 7.82471 4.88861 8.10601 4.60731C8.38732 4.326 8.76885 4.16797 9.16667 4.16797C9.5645 4.16797 9.94603 4.326 10.2273 4.60731C10.5086 4.88861 10.6667 5.27014 10.6667 5.66797V6.83464'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default LockReload
