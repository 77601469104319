import { axiosInstance } from '..'

export async function fetchExecutionGoals() {
  const response = await axiosInstance.get('/execution-goal')
  const executionGoalsData = response.data
  return executionGoalsData
}

export async function fetchExecutionGoal(goalId) {
  const response = await axiosInstance.get(`/execution-goal/${goalId}`)
  const executionGoalData = response.data
  return executionGoalData
}

export async function addNewExecutionGoal(executionGoalData) {
  return await axiosInstance.post('/execution-goal', executionGoalData)
}

export async function editExecutionGoal(executionGoalData) {
  return await axiosInstance.put(
    `/execution-goal/${executionGoalData.id}`,
    executionGoalData
  )
}

export async function deleteExecutionGoal(goalId) {
  return await axiosInstance.delete(`/execution-goal/${goalId}`)
}
