import { Drawer } from 'antd'
import React from 'react'

import ExecutionTabs from '../../../execution-view/tabs'

const ExecutionViewRightDrawer = ({ visible, onClose, onSelectStep }) => {
  return (
    <Drawer
      className='more-options-panel'
      width={400}
      placement='right'
      style={{
        transform: 'translateY(112px)',
        overflow: 'hidden'
      }}
      closable={false}
      onClose={onClose}
      open={visible}
    >
      <ExecutionTabs onClose={onClose} onSelectStep={onSelectStep} />
    </Drawer>
  )
}

export default ExecutionViewRightDrawer
