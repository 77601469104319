import React from 'react'

const UserWorkIcon = ({ size = '24', color = 'currentColor' }) => (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.8681 8.94609L12.0001 0.746094L1.12212 8.94609C0.897235 9.15361 0.762012 9.44056 0.745117 9.74609V22.2681C0.745908 22.5272 0.849202 22.7755 1.03244 22.9588C1.21568 23.142 1.46398 23.2453 1.72312 23.2461H8.24512V17.9991C8.24512 17.0045 8.64021 16.0507 9.34347 15.3474C10.0467 14.6442 11.0006 14.2491 11.9951 14.2491C12.9897 14.2491 13.9435 14.6442 14.6468 15.3474C15.35 16.0507 15.7451 17.0045 15.7451 17.9991V23.2491H22.2661C22.5253 23.2483 22.7737 23.145 22.9571 22.9618C23.1405 22.7786 23.2441 22.5303 23.2451 22.2711V9.74609C23.2278 9.44066 23.0927 9.15387 22.8681 8.94609Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
)

export default UserWorkIcon
