import { Button } from 'antd'
import styled from 'styled-components'

const PopoverButton = styled(Button)`
  width: 100px;
  height: 40px;
  border-radius: 2px;
  font-weight: bold;
  ${({ color }) => color && `background-color: ${color};`}

  &:hover {
    color: #fff !important;
    ${({ color }) => color && `background-color: ${color};`}
  }
`

export default PopoverButton
