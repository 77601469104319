import { store } from '../../../store'
import { getUser } from '../../../store/user-management/selectors'
import { decrypt, encrypt } from '../crypto'

function getUserLocalStorage(email, password) {
  const user = getUser(store.getState())
  const useEmail = email || user.email
  const usePassword = password || user.password

  const encryptedUserStorage = localStorage.getItem(useEmail)
  return encryptedUserStorage
    ? decrypt(usePassword, encryptedUserStorage)
    : null
}

export function setUserLocalStorage(localStorageObject, email, password) {
  const user = getUser(store.getState())
  const useEmail = email || user.email
  const usePassword = password || user.password

  const encryptedLocalStorage = encrypt(usePassword, localStorageObject)
  localStorage.setItem(useEmail, encryptedLocalStorage)
}

export function getUserLocalStorageItem(key, email, password) {
  try {
    const userStorage = getUserLocalStorage(email, password)
    if (!userStorage) return null

    return userStorage[key]
  } catch (e) {
    return null
  }
}

export function setUserLocalStorageItem(key, value, email, password) {
  const userStorage = getUserLocalStorage(email, password) || {}

  userStorage[key] = value

  setUserLocalStorage(userStorage, email, password)
}

export function userExists(email) {
  return localStorage.getItem(email) !== null
}

export function getUserLoginInfo(email, password) {
  const { user, encryptionKey } = getUserLocalStorage(email, password)
  return { user, encryptionKey }
}

export function reencryptUserData(email, oldPassword, newPassword) {
  const userStorage = getUserLocalStorage(email, oldPassword)

  setUserLocalStorage(userStorage, email, newPassword)
}

// Devuelve las iniciales de un string con palabras que estén separadas por espacios
// Ejemplo: Nombre Primerapellido Segundoapellido
// Resultado: NPS
export function getInitials(name) {
  const autoInitials = name.match(/\b\w/g) || []
  return autoInitials.join('').toUpperCase()
}

// A partir de un email obtenemos el nombre
export const getNameFromEmail = (email) => {
  return email.split('@')[0].replace(/[._]/g, ' ')
}
