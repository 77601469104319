import { useEffect, useMemo, useState } from 'react'

export default function usePagination({
  items,
  itemsPerPage = 10,
  paginationEnabled = true
}) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: itemsPerPage
  })
  const [itemsToShow, setItemsToShow] = useState([])

  const total = useMemo(() => items?.length || 0, [items])

  useEffect(() => {
    if (!paginationEnabled) {
      setPagination((prev) => ({ ...prev, current: 1 }))
    }
  }, [paginationEnabled])

  useEffect(() => {
    if (!paginationEnabled) {
      setItemsToShow(items)
      return
    }
    const start = (pagination.current - 1) * pagination.pageSize
    const end = start + pagination.pageSize

    setItemsToShow(items?.slice(start, end) || [])
  }, [items, pagination, paginationEnabled])

  const onChangePage = (page, pageSize) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize ?? pagination.pageSize
    })
  }

  return { itemsToShow, pagination, onChangePage, total }
}
