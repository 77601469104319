import React from 'react'

const Document = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='atom/icon/document'>
      <path
        id='Path'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.5 21.75C22.5 22.578 21.828 23.25 21 23.25H3C2.172 23.25 1.5 22.578 1.5 21.75V2.25C1.5 1.422 2.172 0.75 3 0.75H18.045C18.437 0.75 18.813 0.903 19.093 1.176L22.047 4.059C22.337 4.341 22.5 4.729 22.5 5.133V21.75V21.75Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Path_2'
        d='M6.045 8.25H18.045'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Path_3'
        d='M6.045 12.75H18.045'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Path_4'
        d='M6.045 17.25H12.045'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
)

export default Document
