import React from 'react'
import { useDispatch } from 'react-redux'

import { deleteDeviceProcedures } from '../../../application/device/delete-procedures'
import { deleteProcedures } from '../../../store/procedure/localDb'
import { setProcedure } from '../../../store/procedure/slice'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import Translation from '../../../views/translations'
import { DeleteButton } from '../components/DeleteButton'



export const DeleteProcedureButton = ({ procedure, localExecutions }) => {
  const dispatch = useDispatch()
  const handleDelete = async () => {
    try {
      await deleteProcedures([procedure])
      await deleteDeviceProcedures([procedure])
      dispatch(setProcedure(null))
      notificationMessage({
        message: <Translation id='deleteSuccess' />,
        description: <Translation id='deleteDescrProc' />
      })
    } catch (e) {
      notificationMessage({
        message: <Translation id='deleteError' />,
        description: <Translation id='deleteProcedureError' />
      })
    }
  }
  return (
    <DeleteButton
      disabled={!!localExecutions?.find((e) => e.procedureId === procedure.id)}
      onClick={handleDelete}
      deleteQuestion={<Translation id='deleteProcedureQuestion' />}
    />
  )
}
