import React from 'react'

const CheckboxChecked = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 32 32'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='Procedimiento'
      stroke={color}
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Artboard'
        transform='translate(-435.000000, -629.000000)'
        strokeWidth='1.5'
      >
        <g
          id='checkbox-complete-icon'
          transform='translate(435.000000, 628.998578)'
        >
          <path
            d='M7.46666667,17.7408 L10.9511111,22.6858667 C11.2192781,23.0864412 11.6644676,23.3327607 12.146304,23.3471565 C12.6281404,23.3615523 13.0872412,23.1422503 13.3788444,22.7584 L24.5333333,8.64568889'
            id='Path'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M30,0.75 L2,0.75 C1.65482203,0.75 1.34232203,0.889911016 1.11611652,1.11611652 C0.889911016,1.34232203 0.75,1.65482203 0.75,2 L0.75,30 C0.75,30.345178 0.889911016,30.657678 1.11611652,30.8838835 C1.34232203,31.110089 1.65482203,31.25 2,31.25 L30,31.25 C30.345178,31.25 30.657678,31.110089 30.8838835,30.8838835 C31.110089,30.657678 31.25,30.345178 31.25,30 L31.25,2 C31.25,1.65482203 31.110089,1.34232203 30.8838835,1.11611652 C30.657678,0.889911016 30.345178,0.75 30,0.75 Z'
            id='Path'
            stroke={color}
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default CheckboxChecked
