import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.EXECUTION_GOALS

export const readExecutionGoals = async () => {
  const db = getDatabase()

  const executionGoals = await db[tableName].toArray()
  return executionGoals
}

export const readExecutionGoal = async (id) => {
  const db = getDatabase()

  const executionGoal = await db[tableName].get(id)
  return executionGoal
}
