import React from 'react'
import styled from 'styled-components'

const StepHistoryActual = ({ children, disabled, onClick, ...props }) => (
  <StyledDiv
    data-disabled={disabled}
    onClick={disabled ? null : onClick}
    {...props}
  >
    {children}
  </StyledDiv>
)

const StyledDiv = styled.div`
  display: grid;
  place-items: center;
  width: 100px;
  height: 31px;
  border-radius: 24px;
  border: 2px solid #007ec3;
  color: '#000000';
  cursor: ${({ 'data-disabled': disabled }) =>
    disabled ? 'default' : 'pointer'};
  user-select: none;
  overflow: hidden;
  text-align: center;
`

export default StepHistoryActual
