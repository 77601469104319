import Dexie from 'dexie'
import { jwtDecode } from 'jwt-decode'

import { LS_ENCRYPTION_KEY, LS_USER_KEY } from '../../utils/constants/localdb'
import { setUserLocalStorage } from '../../utils/helpers/user'

export async function deleteUserData(userEmail) {
  localStorage.removeItem(userEmail)
  Dexie.delete(userEmail)
}

export function generateUserData(user, token) {
  const localStorageObject = {
    [LS_ENCRYPTION_KEY]: jwtDecode(token).iek,
    [LS_USER_KEY]: user
  }

  setUserLocalStorage(localStorageObject, user.email, user.password)

  return localStorageObject
}
