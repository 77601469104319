import { Button } from 'antd'
import styled from 'styled-components'

const AddComment = styled(Button)`
  border-radius: 2px;
  flex: 1;
  width: 100%;
  ${({ 'data-critical': critical }) => {
    if (critical) {
      return `
        background-color: #ED1C24;
        color: #fff;
      `
    } else {
      return `
        background-color: #645377;
        color: #fff;
      `
    }
  }}
`

export default AddComment
