import React from 'react'
import styled from 'styled-components'

import { ITEM_LIST_FOR_STATISTIC } from '../../../../../utils/constants/statistics'

import Statistic from './statistic'


const ExecutionStatistics = ({ onSelectStep, closeDrawer }) => {
  return (
    <ExecutionStatisticsContainer id='executionStatistics'>
      {ITEM_LIST_FOR_STATISTIC.map((itemForStatistic, inx) => {
        return (
          <Statistic
            key={'statistic' + inx}
            description={itemForStatistic.description}
            type={itemForStatistic.type}
            onSelectStep={onSelectStep}
            closeDrawer={closeDrawer}
          />
        )
      })}
    </ExecutionStatisticsContainer>
  )
}

const ExecutionStatisticsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px 0px;
`

export default ExecutionStatistics
