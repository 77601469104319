import { Input, Form } from 'antd'
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect
} from 'react'
import { createRoot } from 'react-dom/client'

import Modal from '../../../views/modal'

export function prompt(config) {
  return showDialog(Prompt, config)
}
export function confirm(config) {
  return showDialog(Confirm, config)
}
export function alert(config) {
  return showDialog(Alert, config)
}

const PromptForm = forwardRef(
  (
    {
      rules,
      placeholder,
      onPressEnter,
      value,
      autoFocus,
      inputComponent = <Input />
    },
    ref
  ) => {
    const [formInstance] = Form.useForm()

    useEffect(() => {
      formInstance.setFieldsValue({ input: value })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useImperativeHandle(ref, () => ({
      validate: () => {
        return formInstance.validateFields().then((res) => res.input)
      }
    }))

    return (
      <Form form={formInstance}>
        <Form.Item name='input' rules={rules}>
          {React.cloneElement(inputComponent, {
            placeholder,
            onPressEnter,
            autoFocus,
            value
          })}
        </Form.Item>
      </Form>
    )
  }
)

function Alert({ title, children, visible, submit, afterClose, modalProps }) {
  useEffect(() => {
    return () => {
      const div = document.getElementById('dialog-custom-component')
      if (div) {
        const root = createRoot(div)
        root.unmount()
      }
    }
  }, [])

  return Confirm({
    title,
    children,
    visible,
    submit,
    afterClose,
    modalProps: {
      cancellable: false,
      closable: false,
      maskClosable: false,
      ...modalProps
    }
  })
}

function Confirm({
  title,
  children,
  modalProps,
  submit,
  close,
  visible,
  afterClose
}) {
  useEffect(() => {
    return () => {
      const div = document.getElementById('dialog-custom-component')
      if (div) {
        const root = createRoot(div)
        root.unmount()
      }
    }
  }, [])

  const handleOk = async () => {
    try {
      submit(true)
    } catch (e) {
      // noop
    }
  }
  return (
    <Modal
      {...modalProps}
      visible={visible}
      onOk={handleOk}
      onCancel={() => submit(false)}
      onClose={() => close()}
      title={title}
      formId='prompt-form'
      afterClose={afterClose}
    >
      {children}
    </Modal>
  )
}

function Prompt({
  rules,
  placeholder,
  visible,
  submit,
  close,
  title,
  value,
  autoFocus,
  modalProps = {},
  afterClose,
  inputComponent: InputComponent
}) {
  const formRef = useRef(null)

  useEffect(() => {
    return () => {
      const div = document.getElementById('dialog-custom-component')
      if (div) {
        const root = createRoot(div)
        root.unmount()
      }
    }
  }, [])

  const handleOk = async () => {
    try {
      const value = await formRef.current?.validate()
      submit(value)
    } catch (e) {
      // noop
    }
  }
  return (
    <Modal
      {...modalProps}
      visible={visible}
      onOk={handleOk}
      onCancel={close}
      onClose={close}
      title={title}
      formId='prompt-form'
      afterClose={afterClose}
    >
      <PromptForm
        id='prompt-form'
        name='prompt-form'
        ref={formRef}
        rules={rules}
        value={value}
        placeholder={placeholder}
        onPressEnter={handleOk}
        autoFocus={autoFocus}
        inputComponent={InputComponent}
      />
    </Modal>
  )
}

function showDialog(Component, config) {
  return new Promise((resolve, reject) => {
    const div = document.createElement('div')
    div.id = 'dialog-custom-component'
    document.body.appendChild(div)
    const { onOk, ...others } = config

    let currentConfig = {
      ...others,
      submit,
      close,
      visible: true
    }

    const root = createRoot(div)

    const destroy = (value) => {
      if (div.parentNode) {
        div.parentNode.removeChild(div)
      }
      if (value !== undefined) {
        resolve(value)
      } else {
        reject(value)
      }
    }

    function close(value) {
      currentConfig = {
        ...currentConfig,
        visible: false,
        afterClose: () => destroy(value)
      }
      render(currentConfig)
    }
    async function submit(value) {
      if (onOk) {
        const isClose = await onOk(value)
        if (isClose || isClose === undefined) {
          close(value)
        }
      } else {
        close(value)
      }
    }

    function render(props) {
      root.render(<Component {...props} />)
    }

    render(currentConfig)
  })
}
