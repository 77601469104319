import React, { useContext } from 'react'
import styled from 'styled-components'

import FilterTags from '../../../views/filters/filter-tags'
import Translation from '../../../views/translations'


import { UsersFiltersContext } from '.'

export const UserFilterTags = ({ roles }) => {
  const { filters, updateFilters, resetFilters } =
    useContext(UsersFiltersContext)

  const getRoleName = (value) =>
    roles?.find((_role) => _role.id === value)?.name ?? ''

  return (
    <StyledFilterTags
      value={filters}
      onChange={updateFilters}
      onClear={resetFilters}
      getDisplayName={(key) => {
        return <Translation id={key} />
      }}
      getDisplayValue={(key, value) => {
        const values = {
          role: () => getRoleName(value),
          search: () => value
        }

        return values[key]?.() || value
      }}
    />
  )
}

const StyledFilterTags = styled(FilterTags)`
  margin-top: 1rem;
`
