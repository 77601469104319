import { useState } from 'react'

export function useElementToEditModal({ handleEdit }) {
  const [elementToEdit, setElementToEdit] = useState(null)

  function openModalToEdit(element) {
    setElementToEdit(element)
  }

  function closeModal() {
    setElementToEdit(null)
  }

  function handleEditElement(element) {
    handleEdit(element, closeModal)
  }

  return {
    modalOpen: elementToEdit !== null,
    elementToEdit,
    openModalToEdit,
    closeModal,
    handleEditElement
  }
}
