import { debounce } from 'lodash'
import { useState, useEffect } from 'react'

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const debouncedSet = debounce((value) => {
      requestAnimationFrame(() => setIntersecting(value))
    }, 500)

    const observer = new IntersectionObserver(([entry]) => {
      debouncedSet(entry.isIntersecting)
    })

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}
