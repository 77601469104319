import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.UNITS

export const deleteLocalUnit = async (unitId) => {
  const db = getDatabase()

  return db[tableName].where('id').anyOf([unitId]).delete()
}
