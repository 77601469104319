import React, { useState, useEffect, useRef } from 'react'



import { useGlobalDownloadedProcedures } from '../../../hooks/data/useGlobalDownloadedProcedures'
import usePagination from '../../../hooks/metadata/use-pagination'
import { useFilters } from '../../../hooks/useFilters'
import { COLUMN_TYPES } from '../../../utils/constants/table'
import BasePage from '../../../views/base-page'
import BaseTable from '../../../views/tables/base-table'
import Translation from '../../../views/translations'

import { Filters } from './Filters'
import { ProcedureFilterTags } from './ProcedureFilterTags'

export const DownloadedProceduresFiltersContext = React.createContext()

const columns = [
  {
    title: <Translation id='key' />,
    dataIndex: 'procedure.key',
    minWidth: 88,
    sorter: true
  },
  {
    title: <Translation id='rev' />,
    dataIndex: 'procedure.rev',
    width: 79,
    sorter: true
  },
  {
    title: <Translation id='version' />,
    dataIndex: 'procedure.buildNumber',
    width: 90,
    sorter: true
  },
  {
    title: <Translation id='title' />,
    dataIndex: 'procedure.title',
    minWidth: 100,
    sorter: true
  },
  {
    title: <Translation id='status' />,
    dataIndex: 'procedure.status',
    sorter: true,
    width: 90,
    render: (text) => <Translation id={text} />
  },
  {
    title: <Translation id='device' />,
    sorter: true,
    width: 125,
    dataIndex: 'device.name'
  },
  {
    title: <Translation id='downloadedBy' />,
    dataIndex: 'downloadedBy.name',
    width: 131,
    sorter: true
  },
  {
    title: <Translation id='lastSync' />,
    dataIndex: 'device.lastSync',
    width: 70,
    defaultSortOrder: 'ascend',
    sorter: true,
    type: COLUMN_TYPES.DATE
  }
]

export const DownloadedProcedureManagement = () => {
  const defaultSortColumn = columns.find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'device.lastSync',
    order: defaultSortColumn?.defaultSortOrder || 'ascend'
  })

  const [pagination, setPagination] = usePagination()
  const { filters, updateFilters, resetFilters } = useFilters()

  const setPaginationRef = useRef(setPagination)

  useEffect(() => {
    setPaginationRef.current({ current: 1, pageSize: 10 })
  }, [filters])

  const { data, isValidating } = useGlobalDownloadedProcedures({
    // Seleccionamos solo las propiedades que se muestran en la tabla
    select: ['id', ...columns.map((column) => column.dataIndex)],
    skip: (pagination.current - 1) * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    ...filters,
    deviceId: filters.device?.id
  })

  const { items, total } = data || {}

  const handleTableChange = (pagination, _, sorter) => {
    setPagination({ ...pagination, total })
    setSorter({ ...sorter })
  }

  return (
    <DownloadedProceduresFiltersContext.Provider
      value={{ filters, updateFilters, resetFilters }}
    >
      <BasePage
        title={<Translation id='downloadedProcedures' />}
        extraHeaderContent={<Filters />}
        subHeaderContent={
          Object.keys(filters).length > 0 ? <ProcedureFilterTags /> : undefined
        }
        totalElements={total}
        topMenu
      >
        <BaseTable
          id='downloaded-procedures-table'
          columns={columns}
          dataSource={items}
          pagination={{ ...pagination, total }}
          loading={isValidating}
          onChange={handleTableChange}
        />
      </BasePage>
    </DownloadedProceduresFiltersContext.Provider>
  )
}
