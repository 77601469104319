import { getTranslation } from '../../../i18n/getTranslation'
import { deleteDepartment } from '../../../services/departments/http/delete'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { notifyError } from '../../../utils/notifications'

export async function removeDepartment(departmentId, reload) {
  try {
    await deleteDepartment(departmentId)
    reload()
    notificationMessage({
      message: getTranslation('department.delete.successMsg')
    })
  } catch (error) {
    notifyError(error)
  }
}
