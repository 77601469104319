import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import esES from 'antd/lib/locale/es_ES'
import moment from 'moment'
import React, { useContext, useEffect } from 'react'
import 'moment/locale/es'

import LanguageContext from '../../context/language'

const antdTranslation = {
  'es-ES': esES,
  'en-US': enUS
}

const TranslationProvider = ({ children }) => {
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    moment.locale(language.substring(0, 2))
  }, [language])

  return (
    <ConfigProvider locale={antdTranslation[language]}>
      {children}
    </ConfigProvider>
  )
}

export default TranslationProvider
