import { useCallback, useEffect } from 'react'
import { useState } from 'react'

import { readLocalUnits } from '../../localdb/units/read'

export default function useLocalPlantUnit(unitId) {
  const [plantUnit, setPlantUnit] = useState()

  const loadPlant = useCallback(async () => {
    if (!unitId) {
      setPlantUnit('')
      return
    }

    const localUnits = await readLocalUnits()
    const unit = localUnits.find((unit) => unit.id === unitId)

    setPlantUnit(unit ? `${unit.plantName} ${unit.name}` : '')
  }, [unitId])

  useEffect(() => {
    loadPlant()
  }, [loadPlant])

  return {
    plantUnit
  }
}
