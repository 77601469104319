import { debug } from '../../../logger'

export function removeIconsInSteps(content) {
  const stepMenus = content.querySelectorAll('div.insert_stepMenu') || []
  stepMenus.forEach((element) => element.remove())
}

// Quita el margen inferior a los párrafos que se encuentren dentro de una tabla.
export function removeMarginBottomParagraphInTable(content) {
  const tables = content.querySelectorAll('table')
  Array.from(tables).forEach((table) => {
    const msoNormalParagraphs = table.querySelectorAll('.MsoNormal')

    Array.from(msoNormalParagraphs).forEach((paragraph) => {
      paragraph.style.marginBottom = '0px'
    })
  })
}

// Algunas veces el documento de Word viene con un título que se rellena en la pestaña General
// Queremos que el título lo coja de los metadatos, así que eliminamos este elemento.
export function removeDocTitle(content) {
  const titleElement = content.querySelector('title')
  if (titleElement) titleElement.remove()
}

// Reemplaza los elementos <br> que contienen saltos de página por elementos <p> con las mismas marcas de salto.
// Los saltos de página sólo son visibles en algunos navegadores cuando se insertan en elementos de bloque (p.e. un paragraph),
// y no en elementos en línea, como los br

export function replaceBreaksByBlockElements(content) {
  // Line breaks (Satos de página)
  const aRegexBrWithLineBreak =
    /<br clear="all" style="mso-special-character:(\n)?line-break;(\n)?page-break-before:(\n)?always">/gm

  // Capturamos todos los saltos de página presentes en el documento.
  const brLineBreaks = Array.prototype.slice
    .call(content.querySelectorAll('br'))
    .filter((el) => el.outerHTML.match(aRegexBrWithLineBreak))

  debug('Line breaks found: ', brLineBreaks?.length)

  brLineBreaks.forEach((lineBreak) => {
    lineBreak.outerHTML = `<p clear="all" class="lineBreak" style="mso-special-character:line-break;page-break-before:always"></p>`
  })
}
// Las declaraciones @page asociadas a las secciones ya hacen los saltos de sección, por lo que el salto extra
// que inserta el usuario provoca una página en blaco. Borramos estos saltos.
export function removeSectionBreaks(content) {
  // Section breaks

  const aRegexBrWithSectionBreak =
    /<br clear="all" style="page-break-before:always;(\n)?mso-break-type:section-break">/gm

  // Capturamos todos los saltos de página presentes en el documento.
  const brSectionBreaks = Array.prototype.slice
    .call(content.querySelectorAll('br'))
    .filter((el) => el.outerHTML.match(aRegexBrWithSectionBreak))

  brSectionBreaks.forEach((sectionBreak) => {
    sectionBreak.remove()
  })
  debug('Section breaks found (and removed): ', brSectionBreaks?.length)
}

export function removeMsoComments(stringWhereReplace) {
  const regexOutlookVersion = /<!--\[if gte mso [\d]*][\s\S]*?]-->/g
  stringWhereReplace = stringWhereReplace.replaceAll(regexOutlookVersion, '')

  const regexNoOutlookVersion = /<!--\[if !mso][\s\S]*?]-->/g
  stringWhereReplace = stringWhereReplace.replaceAll(regexNoOutlookVersion, '')

  return stringWhereReplace
}

// Borra el resaltado del texto del botón "Buscar"
export function removeMarks(content) {
  const marks = content.querySelectorAll('mark')

  for (let i = marks.length - 1; i >= 0; i--) {
    if (marks[i].parentElement) {
      marks[i].parentElement.innerHTML = marks[i].parentElement?.innerText
    }

    marks[i].remove()
  }
}

export function removePlaceKeeping(content) {
  let elementWithPlaceKeeping = content.querySelector('[data-iscurrent="true"]')
  if (elementWithPlaceKeeping) {
    elementWithPlaceKeeping.style.setProperty('box-shadow', 'none')
  }
}

export function removeNotifications(content) {
  let allNotitications = content.querySelectorAll(
    '[id="note-frame"]',
    '[id="caution-frame"]'
  )
  for (let i = allNotitications.length - 1; i >= 0; i--) {
    allNotitications[i].remove()
  }
}

// Elimina el nodo, copiando su contenido (se usa para quitar los niveles de wrapper y bookmark en el informe)
export function removeNodes(content, selector) {
  const nodes = content.querySelectorAll(selector) || []

  Array.from(nodes).forEach((node) => {
    node.outerHTML = node.innerHTML
  })
}

/**
 * Remove nodes by tag name
 * @param {Element | null} container Container which contains the nodes
 * @param {string} tagName Tag name to find and remove
 */
export function removeNodesByTagName(container, tagName) {
  const nodes = container.getElementsByTagName(tagName) || []

  // Could be nested nodes so we do not want to remove all the content
  Array.from(nodes).forEach((node) => {
    const parent = node.parentNode
    if (parent) {
      while (node.firstChild) {
        parent.insertBefore(node.firstChild, node)
      }
      parent.removeChild(node)
    }
  })
}

/**
 * Remove empty nodes by tag name
 * @param {Element | null} container Container which contains the nodes
 * @param {string} tagName Tag name to find and remove
 */
export function removeEmptyNodesByTagName(container, tagName) {
  const emptyNodes = container.querySelectorAll(`${tagName}:empty`) || []

  Array.from(emptyNodes).forEach((node) => {
    if (node.className !== 'lineBreak') {
      node.remove()
    }
  })
}

export function uncommentSectionTags(content) {
  const aRegexCommentSectionTag = new RegExp(
    //   /<!--OPEN_COMMENT_BEGIN_SECTION<div class="WordSection(?<numberSection>.*)">CLOSE_COMMENT_BEGIN_SECTION-->/,
    /<!--OPEN_COMMENT_BEGIN_SECTION<div class="WordSection(?<numberSection>\d*)">CLOSE_COMMENT_BEGIN_SECTION-->/,
    'gm'
  )
  const matches = content.outerHTML.match(aRegexCommentSectionTag)
  debug('Number of sections found: ', matches?.length)

  // OPEN SECTION TAGS : Van en el inicio de cada sección, para delimitar el <div class ="WordSection1">
  const openCommentBeginSectionTag = new RegExp(
    /<!--OPEN_COMMENT_BEGIN_SECTION/,
    'g'
  )

  let uncommentedSectionHTML = content.innerHTML

  uncommentedSectionHTML = uncommentedSectionHTML.replaceAll(
    openCommentBeginSectionTag,
    ''
  )

  const closeCommentBeginSectionTag = new RegExp(
    /CLOSE_COMMENT_BEGIN_SECTION-->/,
    'g'
  )
  uncommentedSectionHTML = uncommentedSectionHTML.replaceAll(
    closeCommentBeginSectionTag,
    ''
  )

  // CLOSE SECTION TAGS: van en el cierre del div de la sección

  const openCommentEndSectionTag = new RegExp(
    /<!--OPEN_COMMENT_END_SECTION/,
    'g'
  )
  uncommentedSectionHTML = uncommentedSectionHTML.replaceAll(
    openCommentEndSectionTag,
    '<!--close the section-->'
  )

  const closeCommentEndSectionTag = new RegExp(
    /CLOSE_COMMENT_END_SECTION-->/,
    'g'
  )
  uncommentedSectionHTML = uncommentedSectionHTML.replaceAll(
    closeCommentEndSectionTag,
    ''
  )

  content.innerHTML = uncommentedSectionHTML
}

function checkSectionsAreSiblings(dom) {
  const wordSections = dom.querySelectorAll('[class^="WordSection"')

  if (!wordSections || wordSections.length === 0) return true
  const parent = wordSections[0].parentElement
  wordSections.forEach((ws) => {
    if (ws.parentElement !== parent) {
      debug('Sections are not properly built')
      return false
    }
  })
  return true
}

/**
 * Remove elements apparently useless (e.g. empty elements <b> or <span>) and outside of any
 * WordSection. The effect of this kind of elements is a blank page.
 * @param {Element | null} content DOM document  root
 *
 */
export function removeUselessElements(content) {
  if (checkSectionsAreSiblings(content)) {
    const wordSections = content.querySelectorAll('[class^="WordSection"')
    const uselessElements = content.querySelectorAll('#procedure-data  b,span')

    const parentSections = wordSections[0].parentElement

    uselessElements.forEach((ue) => {
      // Comprobamos si el elemento es "hermano" de alguna sección.  Lo que significaría que está fuera de todas
      // ellas
      if (ue.parentElement === parentSections) {
        debug('Removing element: ', ue.outerHTML)
        ue.remove()
      }
    })
  }
}
