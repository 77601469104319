export function validateUserCode(code) {
  if (!code) return false

  const num = code.substring(0, code.length - 1)
  const word = code.substring(code.length - 1, code.length).toUpperCase()

  const char = 'TRWAGMYFPDXBNJZSQVHLCKET'
  const n = num % 23
  const correctWord = char[n]

  return correctWord === word
}
