import React from 'react'
import styled from 'styled-components'

import Jump from '../../../../../components/icons/jump'

const JumpItem = ({ id, text, disabled, handleClick }) => {
  return (
    <Container
      id='jump-div'
      data-id={id}
      data-isdisabled={disabled}
      onClick={() => handleClick()}
    >
      <Jump />
      <TextContainer>
        <span style={{ lineHeight: '16px' }}>
          <TruncatedDiv id='jump-text'> {text}</TruncatedDiv>
        </span>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  ${({ 'data-isdisabled': isdisabled }) =>
    isdisabled ? `color: #979797 !important;` : ''}

  &:hover {
    ${({ 'data-isdisabled': isdisabled }) =>
      isdisabled ? `cursor: not-allowed;` : 'cursor: pointer;'}
  }
`

const TextContainer = styled.span`
  margin-left: 16px;
  width: 230px;
`

const TruncatedDiv = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export default JumpItem
