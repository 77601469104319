import { getTranslation } from '../../../i18n/getTranslation'
import { editDepartment } from '../../../services/departments/http/put'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { notifyError } from '../../../utils/notifications'

export async function modifyDepartment(department, reload, closeModal) {
  try {
    await editDepartment(department)
    notificationMessage({
      message: getTranslation('department.edit.successMsg')
    })
    if (closeModal) closeModal()
    reload()
  } catch (error) {
    notifyError(error)
  }
}
