import React from 'react'
import styled from 'styled-components'

import ProcedureIcon from '../../../../../components/icons/procedure'
import ColorTag from '../../../../../containers/color-tag'
import {
  EXECUTION_STATUS,
  EXECUTION_ROUTER_MODE
} from '../../../../../utils/constants/execution'
import { EXECUTION_STATUS_COLOR } from '../../../../../utils/constants/styles'
import Translation from '../../../../translations'

const ExecutionItem = ({
  execution,
  procedure,
  index,
  history,
  shouldOpenMediaUploadingModal,
  setRedirect,
  disabled
}) => {
  return (
    <Container
      id={`execution-entry-${index}`}
      style={{ cursor: 'pointer' }}
      onClick={async () => {
        if (disabled) return
        if (shouldOpenMediaUploadingModal()) {
          setRedirect(`/executions/${execution.id}`)
          return
        }
        history.push(
          `/executions/${execution.id}/${EXECUTION_ROUTER_MODE.RUNNING}`
        )
      }}
      data-isdisabled={disabled}
    >
      <ProcedureIcon />
      <TextContainer>
        <span style={{ lineHeight: '16px' }}>
          <div id={`procedure-key-div-${index}`} style={{ fontWeight: 'bold' }}>
            {procedure?.key}
          </div>
          <div id={`procedure-title-div-${index}`}>{procedure?.title}</div>
        </span>
        <div id={`procedure-status-div-${index}`} style={{ marginTop: '10px' }}>
          <ColorTag
            color={EXECUTION_STATUS_COLOR[execution.status]}
            style={{
              fontWeight: 'bold'
            }}
          >
            <Translation id={EXECUTION_STATUS[execution.status]}></Translation>
          </ColorTag>
        </div>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  margin-bottom: 32px;
  ${({ 'data-isdisabled': isdisabled }) =>
    isdisabled ? `color: #979797 !important;` : ''}

  &:hover {
    ${({ 'data-isdisabled': isdisabled }) =>
      isdisabled ? `cursor: not-allowed;` : 'cursor: pointer;'}
  }
`

const TextContainer = styled.span`
  margin-left: 16px;
`

export default ExecutionItem
