import { SearchOutlined } from '@ant-design/icons'
import { Input, Select, Form, Tooltip } from 'antd'
import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'


import Modal from '..'
import useExecutedFor from '../../../hooks/use-executed-for'
import { getTranslation } from '../../../i18n/getTranslation'
import { readExecutionGoals } from '../../../localdb/execution-goal/read'
import { getExecution } from '../../../store/execution/selectors'
import { getProcedure } from '../../../store/procedure/selectors'
import { SEQUENTIAL_MODE } from '../../../utils/constants/execution'
import { PERMISSION_TYPES } from '../../../utils/constants/roles'
import { userHasPermission } from '../../../utils/helpers/permissions'
import Translation from '../../translations'

const { TextArea } = Input
const { Option } = Select

const ExecutionStartModal = ({ visible, onConfirm, setExecutionGoalModal }) => {
  const execution = useSelector(getExecution)
  const procedure = useSelector(getProcedure)
  const [executionType, setExecutionType] = useState()
  const [form] = Form.useForm()
  const [executionGoals, setExecutionGoals] = useState([])
  const [isOtherExecutionGoal, setIsOtherGoal] = useState(
    !!execution?.otherExecutionGoal
  )
  const [notUnitError, setNotUnitError] = useState(false)
  const [executedForList] = useExecutedFor()

  const loadExecutionGoals = async () => {
    const executionGoalsData = await readExecutionGoals()
    executionGoalsData.push({ name: getTranslation('other'), id: 'other' })
    setExecutionGoals(executionGoalsData)
  }

  const setInitialUnitId = (id) => {
    if (!id) {
      const defaultUnit = executedForList.find(
        (plantAndUnit) => plantAndUnit.default
      )
      return defaultUnit ? defaultUnit.id : null
    }

    const initialPlantAndUnit = executedForList.find(
      (plantAndUnit) => plantAndUnit.id === id
    )
    return initialPlantAndUnit ? initialPlantAndUnit.id : null
  }

  useEffect(() => {
    loadExecutionGoals()
    setExecutionType(execution?.sequentialMode ?? procedure.sequentialMode)
  }, [execution?.sequentialMode, procedure.sequentialMode])

  async function handleOk() {
    const executionPlantAndUnit =
      (await form.getFieldValue('executionPlantAndUnit')) ?? ''

    if (!executionPlantAndUnit) {
      setNotUnitError(true)
      return
    }

    setNotUnitError(false)

    const executionGoalValue = !isOtherExecutionGoal
      ? (await form.getFieldValue('executionGoal')) ?? ''
      : null
    const otherExecutionGoalValue = isOtherExecutionGoal
      ? await form.getFieldValue('otherExecutionGoal')
      : null

    onConfirm({
      executionGoalId: executionGoalValue,
      otherExecutionGoal: otherExecutionGoalValue,
      sequentialMode: executionType,
      unitId: executionPlantAndUnit
    })
  }

  const hasAccessToChangeExecutionType = userHasPermission(
    PERMISSION_TYPES.EXECUTION_CHANGE_TYPE
  )

  const areCustomSettings = useMemo(() => {
    if (executionType) {
      return (
        executionType !== procedure?.sequentialMode
        /* 
        //Si se vuelve a mostrar en el código el control de simpleApprobation deberá descomentarse este código y quitar la linea de arriba
        advancedSettings?.sequentialMode !== procedure?.sequentialMode ||
        advancedSettings?.simpleApprobation !== procedure?.simpleApprobation
        */
      )
    } else {
      return (
        !!execution?.sequentialMode &&
        execution?.sequentialMode !== procedure?.sequentialMode
        /* 
        //Si se vuelve a mostrar en el código el control de simpleApprobation deberá descomentarse este código y quitar la linea de arriba
        !!execution?.sequentialMode &&
        !!execution?.simpleApprobation &&
        (execution?.sequentialMode !== procedure?.sequentialMode ||
          execution?.simpleApprobation !== procedure?.simpleApprobation)
        */
      )
    }
  }, [execution, procedure, executionType])

  const onChangeExecutionGoal = (value) => {
    if (value === 'other') {
      setIsOtherGoal(true)
    } else {
      setIsOtherGoal(false)
    }
  }

  const handleOnCancel = () => {
    setNotUnitError(false)
    setExecutionGoalModal(false)
    form.resetFields()
    execution?.otherExecutionGoal ? setIsOtherGoal(true) : setIsOtherGoal(false)
    setExecutionType(execution?.sequentialMode ?? procedure.sequentialMode)
  }

  return (
    <>
      <Modal
        closable={false}
        visible={visible}
        onOk={handleOk}
        onCancel={handleOnCancel}
        okButtonText={<Translation id='continue' />}
        cancelButtonText={<Translation id='cancel' />}
        okButtonProps={{
          id: 'ok-play-button'
        }}
      >
        <Form
          form={form}
          name='executionGoal'
          layout='vertical'
          initialValues={{
            executionGoal: execution?.otherExecutionGoal
              ? 'other'
              : execution?.executionGoalId ?? '',
            executionPlantAndUnit: setInitialUnitId(execution?.unitId),
            otherExecutionGoal: execution?.otherExecutionGoal ?? ''
          }}
        >
          <h3>
            <Translation id='execution.start.modal.title' />
          </h3>
          <Form.Item
            label={<Translation id='execution.plant.unit' />}
            name='executionPlantAndUnit'
            rules={[
              { required: true, message: <Translation id='fieldRequired' /> }
            ]}
            validateStatus={notUnitError ? 'error' : ''}
            help={notUnitError ? <Translation id='fieldRequired' /> : ''}
          >
            <Select
              showSearch
              placeholder={
                <>
                  <SearchOutlined />{' '}
                  <Translation id='execution.plant.unit.placeholder' />
                </>
              }
              allowClear
              options={executedForList.map((executedFor) => ({
                label: executedFor.name,
                value: executedFor.id
              }))}
              filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </Form.Item>
          <Form.Item
            label={<Translation id='execution.goals' />}
            name='executionGoal'
          >
            <Select
              showSearch
              placeholder={
                <>
                  <SearchOutlined /> <Translation id='execution.goals' />
                </>
              }
              allowClear
              options={executionGoals.map((executionGoal) => ({
                label: executionGoal.name,
                value: executionGoal.id
              }))}
              filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              onChange={onChangeExecutionGoal}
            />
          </Form.Item>
          {isOtherExecutionGoal && (
            <Form.Item name='otherExecutionGoal'>
              <TextArea rows={5} />
            </Form.Item>
          )}

          <Form.Item label={<Translation id='selectExecutionType' />}>
            <Tooltip
              title={
                !hasAccessToChangeExecutionType ? (
                  <Translation id='validationHasPermission' />
                ) : null
              }
            >
              <Select
                style={{ width: 300 }}
                disabled={!hasAccessToChangeExecutionType}
                value={executionType}
                onChange={(value) => setExecutionType(value)}
              >
                {Object.values(SEQUENTIAL_MODE).map((mode) => (
                  <Option key={`option${mode}`} value={mode}>
                    <Translation id={mode} />
                  </Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>
          {areCustomSettings && (
            <span style={{ color: 'red' }}>
              <Translation id='customSettingsDetected' />
            </span>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default ExecutionStartModal
