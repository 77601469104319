import React from 'react'

const AlertCircle = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.81463 2.81451C4.1899 1.43924 6.05516 0.666626 8.00008 0.666626C9.945 0.666626 11.8103 1.43924 13.1855 2.81451C14.5608 4.18978 15.3334 6.05504 15.3334 7.99996C15.3334 8.96299 15.1437 9.91658 14.7752 10.8063C14.4067 11.696 13.8665 12.5044 13.1855 13.1854C12.5046 13.8664 11.6961 14.4065 10.8064 14.7751C9.91671 15.1436 8.96311 15.3333 8.00008 15.3333C7.03705 15.3333 6.08346 15.1436 5.19374 14.7751C4.30401 14.4065 3.49559 13.8664 2.81463 13.1854C2.13367 12.5044 1.5935 11.696 1.22496 10.8063C0.85643 9.91658 0.666748 8.96299 0.666748 7.99996C0.666748 6.05504 1.43936 4.18978 2.81463 2.81451ZM8.00008 4.37033C8.36827 4.37033 8.66675 4.66881 8.66675 5.037V7.99996C8.66675 8.36815 8.36827 8.66663 8.00008 8.66663C7.63189 8.66663 7.33341 8.36815 7.33341 7.99996V5.037C7.33341 4.66881 7.63189 4.37033 8.00008 4.37033ZM7.33341 10.9629C7.33341 10.5947 7.63189 10.2963 8.00008 10.2963H8.00749C8.37568 10.2963 8.67416 10.5947 8.67416 10.9629C8.67416 11.3311 8.37568 11.6296 8.00749 11.6296H8.00008C7.63189 11.6296 7.33341 11.3311 7.33341 10.9629Z'
      fill={color}
    />
  </svg>
)

export default AlertCircle
