import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.EXECUTION_GOALS

export const updateLocalExecutionGoal = async (executionGoal) => {
  const db = getDatabase()

  return db[tableName].update(executionGoal.id, {
    name: executionGoal.name,
    updatedAt: new Date().valueOf()
  })
}
