import React from 'react'

const SandClock = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id='Group'
        transform='translate(4.000000, 0.000000)'
        stroke={color}
        strokeWidth='2'
      >
        <path d='M1.92,0.72 C1.12512,0.72 0.48,1.36512 0.48,2.16 L0.48,4.43232 C0.48,8.40864 3.70368,11.63232 7.68,11.63232 C11.65632,11.63232 14.88,8.40864 14.88,4.43232 L14.88,2.16 C14.88,1.36512 14.23488,0.72 13.44,0.72 L1.92,0.72 Z'></path>
        <path d='M1.92,23.28 C1.12512,23.28 0.48,22.63488 0.48,21.84 L0.48,19.56768 C0.48,15.59136 3.70368,12.36768 7.68,12.36768 C11.65632,12.36768 14.88,15.59136 14.88,19.56768 L14.88,21.84 C14.88,22.63488 14.23488,23.28 13.44,23.28 L1.92,23.28 Z'></path>
        <line x1='1.41792' y1='7.92' x2='13.94208' y2='7.92'></line>
        <path d='M13.96608,23.18112 C12.73344,20.59296 10.38048,18.24 7.68,18.24 C4.97952,18.24 2.62656,20.59296 1.39392,23.18112'></path>
      </g>
    </g>
  </svg>
)

export default SandClock
