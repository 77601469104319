import { store } from '../../../store'
import { setSyncDate } from '../../../store/executionManeuvers/actions'
import { getSyncQuery } from '../../../store/synchronization'
import {
  setModuleIsNotSyncing,
  setModuleIsSyncing
} from '../../../store/userInterface/slice'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { debug, erro } from '../../../utils/logger'
import { sendExecutionManeuvers } from '../send'

const tableName = TABLE_NAMES.EXECUTION_MANEUVERS

export async function syncExecutionManeuvers(result) {
  const syncedAt = new Date().valueOf()

  const data = result ?? (await getSyncQuery(tableName)())

  if (data.length) {
    store.dispatch(setModuleIsSyncing({ moduleName: tableName }))
    debug(`Syncing ${tableName}`, data)

    try {
      await sendExecutionManeuvers(data)

      store.dispatch(setSyncDate({ syncedAt, entities: data }))
    } catch (error) {
      store.dispatch(setModuleIsNotSyncing({ moduleName: tableName }))
      erro(`Error syncing ${tableName}`, error)
    }
  }
}
