import styled from 'styled-components'

const ExtraHeaderContent = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: ${({ disabled }) =>
    disabled ? 'not-allowed' : 'pointer'} !important;
`

export default ExtraHeaderContent
