import { useSelector } from 'react-redux'

import { getConnectionStatus } from '../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../utils/constants/connection'

export function useIsOffline() {
  const connectionStatus = useSelector(getConnectionStatus)

  return connectionStatus !== CONNECTION_STATUS.ONLINE
}
