import React from 'react'

import ConfigurationTabContainer from '../../../containers/configuration-tab'
import Translation from '../../translations'
import CustomerSettings from '../customer-settings-configuration'
import ExecutionGoals from '../execution-goals-configuration'
import RolesConfiguration from '../roles-configuration'
import SeriesDepartmentConfig from '../series-department-config'

export const configTabs = [
  {
    label: <Translation id='config.tab.roles.permissions' />,
    key: 'config.tab.roles.permissions',
    children: (
      <ConfigurationTabContainer>
        <RolesConfiguration />
      </ConfigurationTabContainer>
    ),
    admin: true
  },
  {
    label: <Translation id='config.tab.series.departments' />,
    key: 'config.tab.series.departments',
    children: (
      <ConfigurationTabContainer>
        <SeriesDepartmentConfig />
      </ConfigurationTabContainer>
    )
  },
  {
    label: <Translation id='config.tab.execution.goals' />,
    key: 'config.tab.execution.goals',
    children: (
      <ConfigurationTabContainer>
        <ExecutionGoals />
      </ConfigurationTabContainer>
    )
  },
  {
    label: <Translation id='config.tab.customer.settings.unit' />,
    key: 'config.tab.customer.settings.unit',
    children: (
      <ConfigurationTabContainer>
        <CustomerSettings />
      </ConfigurationTabContainer>
    )
  }
]
