import React from 'react'

import { IconContainer } from '../../../containers/icon'

export const Search = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path
        d='M6.268,1.471 C10.875,-0.487 16.196,1.661 18.154,6.267 C20.112,10.873 17.964,16.195 13.358,18.153 C8.752,20.111 3.43,17.963 1.472,13.357 C-0.486,8.751 1.662,3.429 6.268,1.471 Z'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <line
        x1='16.221'
        y1='16.22'
        x2='23.25'
        y2='23.25'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></line>
    </g>
  </svg>
)

export default Search
