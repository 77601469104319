import { axiosInstance } from '../../..'

export async function serverIsUp() {
  try {
    const response = await axiosInstance.get('/status')

    return response.data === 'WORKING'
  } catch (error) {
    return false
  }
}
