import { Tooltip } from 'antd'
import React from 'react'

import Print from '../../../components/icons/print'
import Translation from '../../translations'
import HeaderButton from '../header-button'

import { useIsButtonDisabled } from './hooks/use-is-disabled'
import { usePrint } from './hooks/use-print'

const PrintButton = ({ id, loadPrint, disabled }) => {
  const { loading, onPrint } = usePrint({ loadPrint })
  const { isButtonDisabled, noConnection } = useIsButtonDisabled({ disabled })

  return (
    <Tooltip
      placement='bottom'
      title={noConnection && <Translation id='print.needs.connection' />}
    >
      <section>
        <HeaderButton
          id={id}
          onClick={onPrint}
          loading={loading}
          disabledIcon={isButtonDisabled}
        >
          <Print disabled={isButtonDisabled} />
        </HeaderButton>
      </section>
    </Tooltip>
  )
}

export default PrintButton
