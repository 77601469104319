import { useState, useCallback, useEffect } from 'react'

import { readExecutionGoal } from '../../localdb/execution-goal/read'

const useExecutionGoal = (executionGoalId) => {
  const [executionGoal, setExecutionGoal] = useState()

  const loadExecutionGoal = useCallback(async () => {
    const executionGoal = executionGoalId
      ? await readExecutionGoal(executionGoalId)
      : null
    setExecutionGoal(executionGoal ? executionGoal.name : null)
  }, [executionGoalId])

  useEffect(() => {
    loadExecutionGoal()
  }, [loadExecutionGoal])

  return {
    executionGoal
  }
}

export default useExecutionGoal
