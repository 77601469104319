import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Pencil from '../../../../../components/icons/pencil'
import Translation from '../../../../translations'

const EditableItem = ({
  id,
  comment,
  disabled,
  onClick,
  title = 'comments',
  marginBottom
}) => (
  <EditableContent data-marginbottom={marginBottom}>
    <ContentWrapper>
      <ItemTitle>
        <Translation id={title} />
      </ItemTitle>
      <ItemContent id={`${id}-comment`}>
        {comment || (
          <EmptyField>
            <Translation id='noComments' />
          </EmptyField>
        )}
      </ItemContent>
    </ContentWrapper>
    <EditIcon id={id} disabled={disabled} onClick={onClick}>
      <Pencil disabled={disabled} />
    </EditIcon>
  </EditableContent>
)

const ContentWrapper = styled.div`
  width: 100%;
`

const FormItem = styled.div`
  border: 1px solid #d9d9d9;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 19px;
`

const ItemTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  font-family: inherit;
  text-decoration: none;
  text-align: left;
`

const ItemContent = styled.pre`
  font-family: inherit;
  font-size: 16px;
  line-height: 19px;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-y: hidden;
`

const EditableContent = styled(FormItem)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-grow: 1;
  margin-bottom: ${({ 'data-marginbottom': marginBottom }) =>
    marginBottom || 0};
`

const EmptyField = styled(ItemContent)`
  color: #979797;
`

const EditIcon = styled(Button)`
  align-self: center;
  margin-left: 24px;
  width: 22px;
  height: 22px;
  border: none;
  padding: 0;
  background-color: 'transparent';
  display: flex;
  align-items: center;
  justify-content: center;
  &[disabled] {
    background-color: transparent;
    &:hover,
    &:active {
      background-color: transparent;
      border: none;
    }
  }
`

export default EditableItem
