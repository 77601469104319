import { Modal } from 'antd'
import { shallowEqual } from 'react-redux'

import {
  generatePDFReport,
  uploadFinalReport
} from '../../../services/execution/report/http/post'
import { store } from '../../../store'
import { getExecution } from '../../../store/execution/selectors'
import { getExecutionComments } from '../../../store/executionComments/selectors'
import { getExecutionSteps } from '../../../store/executionSteps/selectors'
import { getProcedure } from '../../../store/procedure/selectors'
import { isOnline } from '../../../utils/helpers/connection'
import { generateReport } from '../../../utils/helpers/report'

export async function generateAndSendFinalReport(
  approvedAt,
  approvedBy,
  plantUnit,
  executionGoal
) {
  const state = store.getState()
  const execution = {
    ...getExecution(state),
    executedFor: plantUnit,
    executionGoal
  }
  const procedure = getProcedure(state)
  const executionSteps = getExecutionSteps(state)
  const executionComments = getExecutionComments(state, shallowEqual)

  execution.steps = executionSteps
  execution.comments = executionComments

  execution.approvedAt = approvedAt
  execution.approvedBy = approvedBy
  if (!execution.revisedBy) {
    execution.revisedBy = approvedBy
    execution.revisedAt = approvedAt
  }

  const procedureData = document.querySelector('#procedure-data')

  const file = await generateReport(procedureData, execution, procedure)

  if (!isOnline()) return false

  return uploadFinalReport(execution, file)
    .then(() => generatePDFReport(execution).then(() => true))
    .catch(() => {
      Modal.error({
        title: 'errorGeneratingHTMLReportTitle',
        content: 'errorGeneratingHTMLReportContent'
      })
      return false
    })
}
