import { Badge } from 'antd'
import React from 'react'

export const BadgeCounter = ({ children, ...rest }) => {
  //default props
  const props = {
    style: rest?.style ?? {
      backgroundColor: '#EFF0EE',
      color: '#000000'
    },
    size: rest?.size ?? 'small',
    ...rest
  }
  return <Badge {...props}>{children}</Badge>
}
