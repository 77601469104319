import PubSub from 'pubsub-js'

import { store } from '..'
import { getTranslation } from '../../i18n/getTranslation'
import { writeExecutions } from '../../localdb/execution/write'
import { history } from '../../utils/constants/browser-history'
import {
  EXECUTION_STATUS,
  EXECUTION_EVENTS,
  EXECUTION_QA_ACTION,
  EXECUTION_MODES
} from '../../utils/constants/execution'
import { notificationMessage } from '../../utils/helpers/notification-message'
import { erro } from '../../utils/logger'
import {
  loadExecutionConditionals,
  saveExecutionConditionals
} from '../execution-conditionals/actions'
import {
  loadExecutionComments,
  saveExecutionComments
} from '../executionComments/actions'
import {
  loadExecutionComponents,
  saveExecutionComponents
} from '../executionComponents/actions'
import {
  loadExecutionManeuvers,
  saveExecutionManeuvers
} from '../executionManeuvers/actions'
import {
  clearAnnotationsAction,
  loadExecutionSteps,
  saveExecutionSteps
} from '../executionSteps/actions'
import {
  loadExecutionWarnings,
  saveExecutionWarnings
} from '../executionWarnings/actions'
import { clearConnectedUsers } from '../sharedExecution/slice'
import { setIsWorking, setLoadingExecution } from '../userInterface/slice'

import {
  readExecution,
  deleteExecutions as deleteLocalExecutions
} from './localDb'
import {
  prepareExecutionToFinish,
  setExecution,
  setStatus,
  setStatusComment,
  setCurrentStep
} from './slice'

const EXECUTION_CHILDREN = [
  'executionComments',
  'executionComponents',
  'executionManeuvers',
  'executionSteps',
  'executionWarnings'
]

export function createExecution({
  execution,
  comments,
  components,
  maneuvers,
  steps,
  warnings,
  conditionals
}) {
  return async (dispatch) => {
    await writeExecutions([execution])

    dispatch(setIsWorking(true))
    dispatch(setLoadingExecution(true))

    dispatch(saveExecutionComments(comments))
    dispatch(saveExecutionComponents(components))
    dispatch(saveExecutionManeuvers(maneuvers))
    dispatch(saveExecutionSteps(steps))
    dispatch(saveExecutionWarnings(warnings))
    dispatch(saveExecutionConditionals(conditionals))
  }
}

export function deleteExecutions(executionIds) {
  return async (dispatch) => {
    deleteLocalExecutions(executionIds)
      .then(() => {
        dispatch(clearAllQueues(executionIds))
      })
      .catch((error) => {
        erro(`Error deleting local executions: ${error}`)
      })
  }
}

export function loadExecution(executionId) {
  return async (dispatch) => {
    if (!executionId) {
      dispatch(setExecution({ execution: null }))
      dispatch(clearConnectedUsers())
    } else {
      const execution = await readExecution(executionId)
      dispatch(setExecution({ execution }))
    }

    dispatch(loadExecutionComponents(executionId))
    dispatch(loadExecutionSteps(executionId))
    dispatch(loadExecutionManeuvers(executionId))
    dispatch(loadExecutionWarnings(executionId))
    dispatch(loadExecutionComments(executionId))
    dispatch(loadExecutionConditionals(executionId))
  }
}

export function interruptExecution(doRedirect = true) {
  return async (dispatch) => {
    dispatch(setStatus({ status: EXECUTION_STATUS.INTERRUPTED }))
    dispatch(clearConnectedUsers())
    if (doRedirect) {
      history.push({
        pathname: '/dashboard/procedures'
      })
      notificationMessage({
        message: getTranslation('checkIn'),
        description: getTranslation('checkInOk')
      })
    }
  }
}

export function finishExecution(statusComment) {
  return async (dispatch) => {
    const user = store.getState().userManagement.user
    dispatch(setStatus({ status: EXECUTION_STATUS.FINISHED }))
    dispatch(setStatusComment({ comment: statusComment }))
    dispatch(prepareExecutionToFinish({ user }))
    dispatch(clearAnnotationsAction())
  }
}

export function abortExecution() {
  return async (dispatch) => {
    dispatch(setStatus({ status: EXECUTION_STATUS.ABORTED }))
    dispatch(clearAnnotationsAction())
  }
}

export function pauseExecution() {
  return async (dispatch) => {
    dispatch(setStatus({ status: EXECUTION_STATUS.PAUSED }))
  }
}

export function changeCurrentStep(stepId) {
  return async (dispatch, getState) => {
    const { sharedExecution, execution: executionStore } = getState()
    const { connectedUsers = [] } = sharedExecution
    const {
      execution: { currentStep },
      mode
    } = executionStore

    const isStepFree =
      !connectedUsers?.length ||
      connectedUsers.every((u) => u.currentStep !== stepId)

    const isIndividualExecution =
      executionStore?.execution.status === EXECUTION_STATUS.EXECUTING

    const isSharedExecutionAndICan =
      executionStore?.execution.status === EXECUTION_STATUS.SHARED &&
      mode !== EXECUTION_MODES.VIEW

    const isReview = mode === EXECUTION_QA_ACTION.REVIEW

    // TODO: Desacoplar lógica
    if (
      isStepFree &&
      (isIndividualExecution || isSharedExecutionAndICan || isReview)
    ) {
      dispatch(setIsWorking(true))
      dispatch(setCurrentStep(stepId))

      PubSub.publish(EXECUTION_EVENTS.CHANGE_CURRENT_STEP, {
        step: stepId,
        previousStep: currentStep
      })
    }
  }
}

function clearAllQueues(executionIds) {
  return async (dispatch, getState) => {
    const {
      execution: { execution }
    } = getState()

    // Only clean queues if owner is some of the executions to delete
    if (!execution || !executionIds.includes(execution.id)) return

    EXECUTION_CHILDREN.forEach((moduleName) => {
      dispatch({
        type: `${moduleName}/removeLocaldbQueue`,
        payload: {}
      })
    })
  }
}
