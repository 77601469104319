import React from 'react'

const EditIcon = ({size = 24, color='currentColor'}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M22.19 1.81001C21.8494 1.47082 21.4449 1.20248 20.9999 1.0205C20.555 0.838516 20.0784 0.746493 19.5977 0.749748C19.117 0.753002 18.6417 0.851469 18.1993 1.03946C17.7568 1.22745 17.356 1.50124 17.02 1.84501L2.52 16.345L0.75 23.25L7.655 21.479L22.155 6.97901C22.4989 6.64315 22.7727 6.24245 22.9608 5.80009C23.1488 5.35773 23.2473 4.88248 23.2506 4.40181C23.2539 3.92115 23.1618 3.44461 22.9798 2.99974C22.7977 2.55487 22.5293 2.15051 22.19 1.81001V1.81001Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.606 2.26001L21.74 7.39401'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.521 16.344L7.66 21.474'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default EditIcon
