import { SearchOutlined } from '@ant-design/icons'
import { Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Modal from '..'
import { getTranslation } from '../../../i18n/getTranslation'
import { readExecutionGoals } from '../../../localdb/execution-goal/read'
import { getExecution } from '../../../store/execution/selectors'
import { setExecutionGoal } from '../../../store/execution/slice'
import { setIsWorking } from '../../../store/userInterface/slice'
import Translation from '../../translations'


const { TextArea } = Input

const ExecutionGoalModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch()
  const execution = useSelector(getExecution)
  const [executionGoals, setExecutionGoals] = useState([])
  const [isOtherExecutionGoal, setIsOtherGoal] = useState(
    !!execution.otherExecutionGoal ?? false
  )
  const [form] = Form.useForm()

  const closeEditModal = async () => {
    setVisible(false)
    form.resetFields()
    const otherExecutionGoal = await form.getFieldValue('otherExecutionGoal')
    otherExecutionGoal ? setIsOtherGoal(true) : setIsOtherGoal(false)
  }

  const loadExecutionGoals = async () => {
    const executionGoalsData = await readExecutionGoals()
    executionGoalsData.push({ name: getTranslation('other'), id: 'other' })
    setExecutionGoals(executionGoalsData)
  }

  const onChangeExecutionGoal = async (value) => {
    if (value === 'other') {
      setIsOtherGoal(true)
    } else {
      setIsOtherGoal(false)
    }
  }

  useEffect(() => {
    loadExecutionGoals()
  }, [])

  useEffect(() => {
    setIsOtherGoal(!!execution.otherExecutionGoal)
  }, [execution.otherExecutionGoal])

  const handleEditExecutionGoal = async (values) => {
    values.executionGoal = values.executionGoal ?? null
    values.otherExecutionGoal = values.otherExecutionGoal ?? null
    dispatch(setIsWorking(true))
    dispatch(setExecutionGoal(values))
    closeEditModal()
  }

  return (
    <Modal
      open={visible}
      closable={false}
      centered
      destroyOnClose
      title={<Translation id='executionGoalsEdit' />}
      onCancel={closeEditModal}
      formId={'executionGoals'}
    >
      <Form
        form={form}
        name='executionGoals'
        layout='vertical'
        initialValues={{
          executionGoal: execution?.otherExecutionGoal
            ? 'other'
            : execution?.executionGoalId ?? '',
          otherExecutionGoal: execution?.otherExecutionGoal ?? ''
        }}
        onFinish={handleEditExecutionGoal}
      >
        <Form.Item
          label={<Translation id='execution.goals' />}
          name='executionGoal'
        >
          <Select
            showSearch
            placeholder={
              <>
                <SearchOutlined /> <Translation id='execution.goals' />
              </>
            }
            allowClear
            options={executionGoals.map((executionGoal) => ({
              label: executionGoal.name,
              value: executionGoal.id
            }))}
            filterOption={(inputValue, option) =>
              option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            onChange={onChangeExecutionGoal}
          />
        </Form.Item>
        {isOtherExecutionGoal && (
          <Form.Item name='otherExecutionGoal'>
            <TextArea rows={5} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default ExecutionGoalModal
