import { getTranslation } from '../../../i18n/getTranslation'
import { deleteLocalUnit } from '../../../localdb/units/delete'
import { deleteUnitById } from '../../../services/unit/http/delete'
import { notificationMessage } from '../../../utils/helpers/notification-message'

export const removeUnit = async (id, reload) => {
  try {
    await deleteUnitById(id)
    await deleteLocalUnit(id)
    reload()
    notificationMessage({
      message: getTranslation('unit.delete.successMsg')
    })
  } catch (error) {
    let messageInfo = ''
    switch (error?.response?.data.message) {
      case 'UNIT_IS_ASSIGNED_TO_PROCEDURE':
        messageInfo = getTranslation('unit.delete.error.conflict.procedure')
        break
      case 'UNIT_IS_DEFAULT':
        messageInfo = getTranslation('unit.delete.error.conflict.default')
        break
      default:
        messageInfo = getTranslation('unit.delete.failMsg')
    }
    notificationMessage({
      type: 'error',
      message: messageInfo
    })
  }
}
