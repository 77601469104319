import { v4 as uuidv4 } from 'uuid'

import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData } from '../../common/write'

const tableName = TABLE_NAMES.EXECUTIONS

export const writeExecutions = (executions) => {
  return _writeLocalDbData({
    tableName,
    data: executions,
    action: 'PUT'
  })
}

export async function writeExecutionsAndSetId(executions) {
  const execs = executions.map((exec) => {
    if (!exec.id) {
      exec.id = uuidv4().toUpperCase()
    }
    return exec
  })

  if (execs.length) {
    return _writeLocalDbData({
      tableName,
      data: execs,
      action: 'PUT'
    })
  }
}
