import React from 'react'

import EyeIcon from '../../../components/icons/eye'
import { IconContainer } from '../../../containers/icon'

const EyeButtonView = (props) => (
  <IconContainer {...props} style={{ cursor: 'pointer' }} icon={EyeIcon} />
)

export default EyeButtonView
