import { axiosInstance } from '..'

export async function loadCustomersSettings() {
  const response = await axiosInstance.get('/plant')
  const customersData = response.data
  return customersData
}

export async function addNewCustomerSetting(customerSettingsData) {
  return await axiosInstance.post('/plant', customerSettingsData)
}

export async function editCustomerSetting(customerSettingsData) {
  const response = await axiosInstance.put(`plant/${customerSettingsData.id}`, {
    name: customerSettingsData.name
  })

  return response.data
}

export async function deleteCustomerSettings(customerSettingsId) {
  return await axiosInstance.delete(`plant/${customerSettingsId}`)
}
