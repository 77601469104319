import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.HISTORICAL_VALUES

export async function readHistoricalValue(componentName, procedureKey) {
  const db = getDatabase()

  return db[tableName]
    .where('[key+name]')
    .anyOf([[procedureKey, componentName]])
    .first()
}
