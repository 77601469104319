import React from 'react'

const SmallArrowLeft = ({size = 24, color='currentColor'}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.5 16.5L4.35337 9.35333C4.15804 9.15867 4.15804 8.842 4.3527 8.64733L4.35337 8.64667L11.5 1.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SmallArrowLeft
