export const COMMENT_TO_STEP_TYPES = {
  COMMENT: 'COMMENT',
  CRITICAL: 'CRITICAL'
}

export const COMMENT_TYPES = {
  COMMENT_TO_STEP: 'COMMENT_TO_STEP',
  COMMENT_TO_PROCEDURE: 'COMMENT_TO_PROCEDURE'
}

export const COMMENT_TO_PROCEDURE_TYPES = {
  CHANGE_PROPOSAL: {
    id: 'CHANGE_PROPOSAL',
    name: 'changeProposal',
    allowedFileExt: ['jpg', 'png', 'pdf']
  },
  ATTACH_TO_BEGINNING: {
    id: 'ATTACH_TO_BEGINNING',
    name: 'attachBefore',
    allowedFileExt: ['pdf']
  },
  ATTACH_TO_END: {
    id: 'ATTACH_TO_END',
    name: 'attachAfter',
    allowedFileExt: ['pdf']
  }
}
