import styled from 'styled-components'

const CommentListContainer = styled.div`
  padding: 15px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`

export default CommentListContainer
