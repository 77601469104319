import { Form as FormAntd } from 'antd'

export default function useForm({ plant, handleAddUnit }) {
  const [form] = FormAntd.useForm()

  const handleSubmitItem = async ({ unitName }) => {
    const newUnit = {
      name: unitName,
      createdAt: new Date().valueOf(),
      plant,
      plantId: plant?.id
    }
    await handleAddUnit(newUnit)
    form.resetFields()
  }

  return {
    form,
    handleSubmitItem
  }
}
