export default function useEditListItem({
  element,
  elementToEdit,
  setElementToEdit,
  handleClickEdit
}) {
  const onClickEdit = () => {
    if (!elementToEdit || elementToEdit.id !== element.id) {
      setElementToEdit(element)
    } else {
      handleClickEdit(elementToEdit)
      setElementToEdit(null)
    }
  }

  return {
    onClickEdit
  }
}
