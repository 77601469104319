import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'

import LanguageContext from '../../context/language'

export const HelpPage = () => {
  const [html, setHtml] = useState(null)
  const { language } = useContext(LanguageContext)

  // TODO delete html+word fetch and refactor to react
  useEffect(() => {
    const fn = async () => {
      const response = await fetch(`/public/help/helpPage_${language}.html`)
      const data = await response.text()
      setHtml(data)
    }

    fn()
  }, [language])

  return <Container dangerouslySetInnerHTML={{ __html: html }} />
}

const Container = styled.div`
  padding: 40px 80px;
  overflow: auto;
`
