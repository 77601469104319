import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

export const deleteExecutionConditionals = async (executionIds) => {
  const db = getDatabase()

  return db[TABLE_NAMES.EXECUTION_CONDITIONALS]
    .where('executionId')
    .anyOf(executionIds)
    .delete()
}
