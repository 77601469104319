import { readExecutionSteps } from '../../localdb/execution-step/read'
import { writeExecutionSteps } from '../../localdb/execution-step/write'
import { EXECUTION_STATUS } from '../../utils/constants/execution'
import { TABLE_NAMES } from '../../utils/constants/localdb'
import { erro } from '../../utils/logger'
import { filterByUpdatedAt } from '../helpers'
import { setModuleIsNotSyncing } from '../userInterface/slice'

import {
  updateLocaldbQueue,
  setExecutionSteps,
  markStep,
  unmarkStep,
  setStepAnnotation,
  clearAnnotations,
  addStepComment,
  addMediaToStepComment,
  editStepComment,
  setSyncedAt,
  deleteStepComment,
  deleteMediaStepComment
} from './slice'


export function saveExecutionSteps(steps) {
  return async (dispatch) => {
    dispatch(setExecutionSteps({ steps }))
    dispatch(updateLocaldbQueue({ step: steps, resetQueue: true }))
  }
}

export function loadExecutionSteps(executionId) {
  return async (dispatch) => {
    if (!executionId) {
      dispatch(setExecutionSteps({ steps: null }))
    } else {
      const executionSteps = await readExecutionSteps(executionId)
      dispatch(setExecutionSteps({ steps: executionSteps }))
    }
  }
}

export function markStepAction({ stepId, userId, action }) {
  return async (dispatch, getState) => {
    const state = getState()
    const { execution } = state
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()

    if (!isShared) {
      const step = state.executionSteps?.steps?.find((s) => s.stepId === stepId)
      const stepToQueue = {
        ...step,
        [action + 'At']: now,
        userId: userId,
        updatedAt: now
      }
      delete stepToQueue.user

      dispatch(
        updateLocaldbQueue({
          step: stepToQueue
        })
      )
    }
    dispatch(markStep({ stepId, userId, action, now }))
  }
}

export function unmarkStepAction({ stepId, userId }) {
  return async (dispatch, getState) => {
    const state = getState()
    const { execution } = state
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()

    if (!isShared) {
      const step = state.executionSteps?.steps?.find((s) => s.stepId === stepId)

      dispatch(
        updateLocaldbQueue({
          step: {
            ...step,
            readAt: null,
            completedAt: null,
            userId: userId,
            updatedAt: now
          }
        })
      )
    }
    dispatch(unmarkStep({ stepId, userId, now }))
  }
}

export function setStepAnnotationAction({ stepId, annotation }) {
  return async (dispatch, getState) => {
    const state = getState()
    const { execution } = state
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    const now = new Date().valueOf()

    if (!isShared) {
      const step = state.executionSteps?.steps?.find((s) => s.stepId === stepId)

      dispatch(
        updateLocaldbQueue({ step: { ...step, annotation, updatedAt: now } })
      )
    }
    dispatch(setStepAnnotation({ stepId, annotation, now }))
  }
}

export function clearAnnotationsAction() {
  return async (dispatch, getState) => {
    const {
      executionSteps: { steps }
    } = getState()

    const now = new Date().valueOf()

    steps
      .filter((_step) => _step.annotation)
      .forEach((_step) => {
        dispatch(
          updateLocaldbQueue({
            step: {
              ..._step,
              annotation: null,
              updatedAt: now
            }
          })
        )
      })
    dispatch(clearAnnotations({ now }))
  }
}

export function addStepCommentAction({ stepId, newComment }) {
  return async (dispatch, getState) => {
    const state = getState()
    const { execution } = state
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()

    if (!isShared) {
      const step = state.executionSteps?.steps?.find((s) => s.stepId === stepId)
      dispatch(
        updateLocaldbQueue({
          step: {
            ...step,
            comments: [...step.comments, newComment],
            updatedAt: now
          }
        })
      )
    }
    dispatch(addStepComment({ stepId, newComment, now }))
  }
}

export function addMediaToStepCommentAction({ stepId, commentId, mediaId }) {
  return async (dispatch, getState) => {
    const state = getState()
    const { execution } = state
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()

    if (!isShared) {
      const step = state.executionSteps?.steps?.find((s) => s.id === stepId)
      dispatch(
        updateLocaldbQueue({
          step: {
            ...step,
            comments: step.comments.map((c) => {
              if (c.id === commentId) {
                return {
                  ...c,
                  mediaIds: [...c.mediaIds, mediaId],
                  updatedAt: now
                }
              }
              return c
            }),
            updatedAt: now
          }
        })
      )
    }
    dispatch(addMediaToStepComment({ stepId, commentId, mediaId, now }))
  }
}

export function deleteMediaFromStepCommentAction({
  stepId,
  commentId,
  mediaId
}) {
  return async (dispatch, getState) => {
    const state = getState()
    const { execution } = state
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()

    if (!isShared) {
      const step = state.executionSteps?.steps?.find((s) => s.id === stepId)
      dispatch(
        updateLocaldbQueue({
          step: {
            ...step,
            comments: step.comments.map((c) => {
              if (c.id === commentId) {
                return {
                  ...c,
                  mediaIds: c.mediaIds.filter((m) => m !== mediaId),
                  updatedAt: now
                }
              }
              return c
            }),
            updatedAt: now
          }
        })
      )
    }
    dispatch(deleteMediaStepComment({ stepId, commentId, mediaId, now }))
  }
}

export function deleteStepCommentAction({ stepId, commentId }) {
  return async (dispatch, getState) => {
    const state = getState()
    const { execution } = state
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()

    if (!isShared) {
      const step = state.executionSteps?.steps?.find((s) => s.stepId === stepId)
      dispatch(
        updateLocaldbQueue({
          step: {
            ...step,
            comments: step.comments.map((c) => {
              if (c.id === commentId) {
                return {
                  ...c,
                  deletedAt: now
                }
              }
              return c
            }),
            updatedAt: now
          }
        })
      )
    }
    dispatch(deleteStepComment({ stepId, commentId, now }))
  }
}

export function editStepCommentAction({ stepId, comment }) {
  return async (dispatch, getState) => {
    const state = getState()
    const { execution } = state
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()

    if (!isShared) {
      const step = state.executionSteps?.steps?.find((s) => s.stepId === stepId)
      dispatch(
        updateLocaldbQueue({
          step: {
            ...step,
            comments: step.comments.map((c) => {
              if (c.id === comment.id) {
                return {
                  ...c,
                  comment: comment.comment,
                  updatedAt: now
                }
              }
              return c
            }),
            updatedAt: now
          }
        })
      )
    }
    dispatch(editStepComment({ stepId, comment, now }))
  }
}

export function setSyncDate({ entities, syncedAt }) {
  return async (dispatch, getState) => {
    const {
      execution,
      executionSteps: { steps }
    } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    const entitiesNotInState = entities.filter(
      (entity) => entity.executionId !== execution.execution?.id
    )

    if (entitiesNotInState.length > 0) {
      try {
        await writeExecutionSteps(
          entitiesNotInState.map((entity) => ({ ...entity, syncedAt }))
        )
      } catch (error) {
        erro('Error writing execution steps', error)
      }
    }

    const syncedSteps = filterByUpdatedAt(entities, steps)

    if (syncedSteps?.length) {
      try {
        if (!isShared) {
          const stepsToQueue = syncedSteps.map((_step) => ({
            ..._step,
            syncedAt
          }))
          dispatch(updateLocaldbQueue({ step: stepsToQueue }))
        }
        dispatch(setSyncedAt({ entities: syncedSteps, syncedAt }))
      } catch (error) {
        erro('Error syncing execution steps', error)
      }
    } else {
      dispatch(
        setModuleIsNotSyncing({ moduleName: TABLE_NAMES.EXECUTION_STEPS })
      )
    }
  }
}
