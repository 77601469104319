import { io } from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'

import { WS_URL as WEB_SOCKET_URL } from '../../../utils/constants/config'
import { WS_UPDATE } from '../../../utils/constants/websocket'
import { debug } from '../../../utils/logger'

let ws = null
let wsSubscribers = []

function updateSubscribers({ type, payload, from }) {
  wsSubscribers
    .filter((s) => s.updateTypes.includes(type))
    .forEach((s) => s.callback({ type, payload, from }))
}

export let sendUpdate = null

export function onUpdate(updateTypes, callback) {
  const id = uuidv4()
  const types = Array.isArray(updateTypes) ? updateTypes : [updateTypes]
  wsSubscribers.push({ updateTypes: types, callback, id })
  return id
}

export function removeSubscription(id) {
  wsSubscribers = wsSubscribers.filter((s) => s.id !== id)
}

export function removeSubscriptionByType(type) {
  wsSubscribers = wsSubscribers.filter((s) => !s.updateTypes.includes(type))
}

export async function connect(token) {
  if (ws) {
    ws.close()
  }

  ws = io(WEB_SOCKET_URL, { auth: { token }, path: '/socket.io' })

  ws.on('connect_error', (ev) => {
    debug(':::::: Websocket connection error', ev)
    updateSubscribers({ type: WS_UPDATE.DISCONNECTED })
  })

  ws.on('connect', (...params) => {
    sendUpdate = ({ type, payload }) => {
      try {
        ws.emit(type, payload)

        debug(':::::: Websocket sent update', { type, payload })
      } catch (error) {
        console.error(error)
      }
    }

    debug(':::::: Websocket connected', params)
    updateSubscribers({ type: WS_UPDATE.CONNECTED })
  })

  ws.on(WS_UPDATE.EXECUTION_UPDATE, (update) => {
    const { payload, from } = update

    debug(':::::: Websocket received update', update)

    updateSubscribers({ type: update.type, payload, from })
  })

  ws.on(WS_UPDATE.SOCKET_ERROR, (update) => {
    debug(':::::: Websocket received error', update)
    updateSubscribers({ type: WS_UPDATE.SOCKET_ERROR, payload: update })
  })

  ws.on('disconnect', (...params) => {
    debug(':::::: Websocket disconnected', params)
    updateSubscribers({ type: WS_UPDATE.DISCONNECTED })
  })
}

export function disconnect() {
  if (ws) {
    ws.close()
  }
}
