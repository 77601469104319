import React, { useState } from 'react'


import useReadoutNumerical from '../../../../hooks/use-readout/numerical'
import { POOR_QUALITY } from '../../../../utils/constants/config'
import ReadoutRT from '../readout-rt'

export const ReadoutRTNumerical = ({
  component,
  executionComponent,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')

  const { options = {} } = component || {}
  const { minRange, maxRange, validationFormula } = options
  const { value = '', quality = '' } = executionComponent?.liveValue || {}
  const isPoor = quality === POOR_QUALITY
  const { error, isValid } = useReadoutNumerical({
    isPoor,
    maxRange,
    minRange,
    validationFormula,
    value,
    inputValue
  })

  return (
    <ReadoutRT
      {...props}
      component={component}
      executionComponent={executionComponent}
      checkReadoutValue={isValid}
      setInputValue={setInputValue}
      error={error}
    />
  )
}

export default ReadoutRTNumerical
