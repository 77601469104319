import { Button } from 'antd'
import styled from 'styled-components'

const BlankButton = styled(Button)`
  border: none;
  height: initial;
  background-color: ${({ color, active, ...props }) =>
    active ? props['data-active-color'] || '#b8bab6' : color || '#edece8'};

  ${({ shape }) => (shape !== 'circle' ? 'border-radius: 2px;' : '')}

  &:hover, &:active, &:focus {
    background-color: ${(props) => props['data-active-color'] || '#b8bab6'};
    color: #000;
  }
  &[disabled],
  &[disabled]:hover {
    background: none;
  }
`

export default BlankButton
