import { createSelector } from '@reduxjs/toolkit'

import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import { EXECUTION_QA_ACTION } from '../../../utils/constants/execution'
import {
  getIsExecution,
  getExecutionMode,
  getIsShared
} from '../../execution/selectors'
import { getIsProcedure } from '../../procedure/selectors'

const getUserInterfaceStore = (state) => state.userInterface

export const getIsWorking = createSelector(
  getIsExecution,
  getExecutionMode,
  getIsProcedure,
  getIsShared,
  getUserInterfaceStore,
  (isExecution, executionMode, isProcedure, isShared, store) => {
    if (isProcedure || isShared || !isExecution) return false

    const result =
      executionMode === EXECUTION_QA_ACTION.REVIEW ||
      executionMode === EXECUTION_QA_ACTION.APPROVE
        ? false
        : store.isWorking

    return result
  }
)

export const getCurrentDrawer = createSelector(
  getUserInterfaceStore,
  (store) => store.currentDrawer
)

export const getIsNonCompliantComment = createSelector(
  getUserInterfaceStore,
  (store) => store.isNonCompliantComment
)

export const getCurrentComponentType = createSelector(
  getUserInterfaceStore,
  (store) => store.currentComponentType
)

export const getLoadingExecution = createSelector(
  getUserInterfaceStore,
  (store) => store.loadingExecution
)

export const areModulesSyncing = createSelector(
  getUserInterfaceStore,
  (store) => Object.keys(store.syncingModules).length > 0
)

export const getExecutionJumpsWithReturn = createSelector(
  getIsExecution,
  getUserInterfaceStore,
  (isExecution, store) => {
    if (!isExecution) return {}

    return store.executionJumpsWithReturn
  }
)

export const getCurrentStepDrawerComments = createSelector(
  getUserInterfaceStore,
  (store) => store.currentStepDrawerComments
)

export const getHeaderTitleInfo = createSelector(
  getUserInterfaceStore,
  (store) => store.headerTitleInfo
)

export const getDownloadingProcedureIds = createSelector(
  getUserInterfaceStore,
  (store) => store.downloadingProcedureIds
)

export const getConnectionStatus = createSelector(
  getUserInterfaceStore,
  (store) => store.connectionStatus
)

export const isOnline = createSelector(
  getUserInterfaceStore,
  (store) => store.connectionStatus === CONNECTION_STATUS.ONLINE
)
