import { Checkbox, Divider as AntdDivider } from 'antd'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Translation from '../translations'

const SelectPermissions = ({
  permissions,
  selectedPermissions,
  disabledPermissions,
  updateSelectedPermissions
}) => {
  const [storedPermissions, setStoredPermissions] = useState([])

  useEffect(() => {
    selectedPermissions && setStoredPermissions(selectedPermissions)
  }, [selectedPermissions])

  const handleChangePermissions = ({ target: { value, checked } }) => {
    const newPermissions = cloneDeep(storedPermissions)
    const permissionIndex = storedPermissions.findIndex(
      (storedPermission) => storedPermission.screenPermission.id === value
    )

    if (permissionIndex !== -1 && !checked) {
      newPermissions.splice(permissionIndex, 1)
    } else {
      newPermissions.push({
        screenPermissionId: value,
        screenPermission: { id: value },
        createdAt: new Date().valueOf()
      })
    }
    setStoredPermissions(newPermissions)
    updateSelectedPermissions(newPermissions)
  }

  const handleChangeActionPermissions = (
    { target: { value, checked } },
    parentId
  ) => {
    const newPermissions = cloneDeep(storedPermissions)
    const permissionIndex = storedPermissions.findIndex(
      (storedPermission) => storedPermission.screenPermission.id === parentId
    )

    const actionPermissionIndex = storedPermissions[
      permissionIndex
    ]?.roleActionPermissions?.findIndex(
      (_ap) => _ap.actionPermission.id === value
    )

    if (actionPermissionIndex !== -1 && !checked) {
      newPermissions[permissionIndex].roleActionPermissions.splice(
        actionPermissionIndex,
        1
      )
    } else {
      newPermissions[permissionIndex].roleActionPermissions
        ? newPermissions[permissionIndex].roleActionPermissions.push({
            actionPermission: { id: value },
            actionPermissionId: value,
            createdAt: new Date().valueOf()
          })
        : (newPermissions[permissionIndex].roleActionPermissions = [
            {
              actionPermission: { id: value },
              actionPermissionId: value,
              createdAt: new Date().valueOf()
            }
          ])
    }
    setStoredPermissions(newPermissions)
    updateSelectedPermissions(newPermissions)
  }

  const isIndeterminate = (actionPermissions, checkboxValue) => {
    if (actionPermissions.length === 0) return false
    const storedActionPermissions = storedPermissions.find(
      (storedPermission) =>
        storedPermission.screenPermission?.id === checkboxValue
    )

    return (
      actionPermissions &&
      storedActionPermissions &&
      actionPermissions.length !==
        storedActionPermissions.roleActionPermissions?.length
    )
  }

  return (
    <SelectPermissionsContainer>
      <CheckboxesContainer>
        {permissions.map(
          (screenPermission) =>
            screenPermission.assignable && (
              <div key={screenPermission.title} style={{ width: '100%' }}>
                <Checkbox
                  name={screenPermission.title}
                  value={screenPermission.id}
                  indeterminate={isIndeterminate(
                    screenPermission.actionPermissions,
                    screenPermission.id
                  )}
                  checked={storedPermissions.some(
                    (storedPermission) =>
                      storedPermission.screenPermission.id ===
                      screenPermission.id
                  )}
                  disabled={disabledPermissions}
                  onChange={(e) => handleChangePermissions(e)}
                >
                  <span>
                    <Translation id={screenPermission.title} />
                  </span>
                  {screenPermission.description && (
                    <span style={{ marginLeft: '18px', color: '#A3A3A3' }}>
                      <Translation id={screenPermission.description} />
                    </span>
                  )}
                </Checkbox>
                {screenPermission.actionPermissions &&
                  screenPermission.actionPermissions.length !== 0 && (
                    <>
                      <Divider />
                      <CheckboxesContainer data-isactiongroup='true'>
                        {screenPermission.actionPermissions.map(
                          (actionPermission) => (
                            <Checkbox
                              key={actionPermission.title}
                              name={actionPermission.title}
                              value={actionPermission.id}
                              checked={storedPermissions.some(
                                (storedPermission) =>
                                  storedPermission.roleActionPermissions?.some(
                                    (storedPermission) =>
                                      storedPermission.actionPermission.id ===
                                      actionPermission.id
                                  )
                              )}
                              disabled={
                                !storedPermissions.some(
                                  (permission) =>
                                    permission.screenPermission.id ===
                                    screenPermission.id
                                ) || disabledPermissions
                              }
                              onChange={(e) =>
                                handleChangeActionPermissions(
                                  e,
                                  screenPermission.id
                                )
                              }
                            >
                              <span>
                                <Translation id={actionPermission.title} />
                              </span>
                              <span
                                style={{ marginLeft: '18px', color: '#A3A3A3' }}
                              >
                                <Translation
                                  id={actionPermission.description}
                                />
                              </span>
                            </Checkbox>
                          )
                        )}
                      </CheckboxesContainer>
                    </>
                  )}
              </div>
            )
        )}
      </CheckboxesContainer>
    </SelectPermissionsContainer>
  )
}

const SelectPermissionsContainer = styled.div`
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  height: 424px;
  overflow-y: auto;

  border-radius: 2px;
  border: 1px solid #d9d9d9;
`
const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-left: ${(props) => (props['data-isactiongroup'] ? '24px' : '0')};

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`
const Divider = styled(AntdDivider)`
  margin: 8px 0;

  background: #fff;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
`

export default SelectPermissions
