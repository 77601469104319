import { readFiles } from '../../../localdb/files/read'
import { readFilesContent } from '../../../localdb/filesContent/read'
import { erro } from '../../../utils/logger'

export async function loadFile(fileId) {
  try {
    const fileInfo = await readFiles([fileId])
    const fileContent = await readFilesContent([fileId])

    return { ...fileInfo[0], file: fileContent[0].data }
  } catch (error) {
    erro(`Cannot load files with ID: ${fileId}. Error: ${error.message}`)
    return null
  }
}
