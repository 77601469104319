import { Form, Modal as AntModal, Input, Button as AntButton } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'


import { useTranslation } from '../../../hooks/useTranslation'
import { getExecution } from '../../../store/execution/selectors'
import { setIsWorking } from '../../../store/userInterface/slice'
import Translation from '../../translations'

const MODAL_CONTENT = {
  'execution/setApprovalComment': 'approvalComment',
  'execution/setRevisionComment': 'revisionComment',
  'execution/setExecutionGoal': 'executionGoal',
  'execution/setStatusComment': 'statusComment'
}

const ExecutionInfoModal = ({
  visible,
  setVisible,
  setCurrentAction,
  currentAction
}) => {
  const dispatch = useDispatch()
  const placeholderTranslation = useTranslation('writeHere')
  const execution = useSelector(getExecution)
  const [comment, setComment] = useState(null)

  useEffect(() => {
    if (execution && currentAction) {
      const shownProperty = MODAL_CONTENT[currentAction.type]
      setComment(execution[shownProperty])
    }
  }, [execution, currentAction])

  const reset = () => {
    setCurrentAction(null)
    setVisible(false)
    setComment('')
  }

  const onSaveChanges = () => {
    if (currentAction) {
      dispatch(setIsWorking(true))
      dispatch({
        ...currentAction,
        payload: comment
      })
    }
    reset()
  }

  return (
    <Modal
      open={visible}
      closable={false}
      footer={null}
      centered
      destroyOnClose
    >
      <Title>
        <Translation id='TextFieldEditing' />
      </Title>
      <Form name='comment' layout='vertical' initialValues={{ comment }}>
        <Form.Item name='comment'>
          <TextArea
            placeholder={placeholderTranslation}
            autoSize={{ minRows: 7, maxRows: 10 }}
            bordered={false}
            value={comment}
            onChange={({ target: { value } }) => setComment(value)}
          />
        </Form.Item>
        <ButtonsContainer>
          <Button color='#252524' onClick={reset}>
            <Translation id='cancel' />
          </Button>
          <Button background='#645377' onClick={onSaveChanges}>
            <Translation id='saveChanges' />
          </Button>
        </ButtonsContainer>
      </Form>
    </Modal>
  )
}

const Modal = styled(AntModal)`
  background: #efeeeb;
  border-radius: 4px;
  padding: 0;
  > .ant-modal-content {
    background: #efeeeb;
    border-radius: 4px;
    box-shadow: none;
  }
  > .ant-modal-content .ant-modal-body {
    padding: 24px 32px;
  }
`

const Title = styled.h4`
  font-size: 19px;
  line-height: 22px;
  margin-bottom: 9px;
`

const TextArea = styled(Input.TextArea)`
  background-color: #fff;
  border-radius: 0;
  margin-top: 15px;
  &:hover,
  &:focus {
    background-color: #fff;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Button = styled(AntButton)`
  border-width: 1px;
  border-color: ${({ background }) => background || '#b8bab6'};
  color: ${({ color }) => color || '#fff'};
  background-color: ${({ background }) => background || 'transparent'};
  width: 175px;
  text-align: center;
  margin-right: 8px;
  border-radius: 2px;
  &:last-child {
    margin-right: 0;
  }
`

export default ExecutionInfoModal
