import { Popover, Button } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'

import Recharge from '../../../../components/icons/recharge'
import WarningDiamondIcon from '../../../../components/icons/warning-icon-diamond'
import Translation from '../../../translations'


const ResetPermissionsPopover = ({
  isResetOpen,
  isModalOpen,
  disabled,
  setReset,
  confirmSetDefaultPermissions
}) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(isResetOpen && isModalOpen)
  }, [isResetOpen, isModalOpen])

  return (
    <Popover
      open={open}
      placement='topLeft'
      width={354}
      content={
        <PopoverContent>
          <PopoverBody>
            <WarningDiamondIcon size='32' />
            <Translation id='role.resetPermissionsTooltip.text' />
          </PopoverBody>
          <ButtonContainer>
            <Button type='ghost' size='small' onClick={() => setReset(false)}>
              <Translation id='role.resetPermissionsTooltip.button.discard' />
            </Button>
            <Button
              type='primary'
              size='small'
              onClick={confirmSetDefaultPermissions}
            >
              <Translation id='role.resetPermissionsTooltip.button.reset' />
            </Button>
          </ButtonContainer>
        </PopoverContent>
      }
    >
      <ResetPermissionsButton
        type='ghost'
        size='small'
        disabled={disabled}
        onClick={() => setReset(true)}
      >
        <Recharge size={14} />
        <Translation id={'role.resetPermissions'} />
      </ResetPermissionsButton>
    </Popover>
  )
}

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;

  width: 322px;
`
const PopoverBody = styled.div`
  display: flex;
  align-items: end;
  gap: 8px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`
const ResetPermissionsButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
`
export default ResetPermissionsPopover
