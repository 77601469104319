import React from 'react'
import styled from 'styled-components'

import CheckboxChecked from '../../components/icons/checkbox-checked'
import CheckboxUnchecked from '../../components/icons/checkbox-unchecked'

const Checkbox = ({
  value,
  onChange,
  label,
  disabled,
  className,
  color = '#000',
  historicalModeEnabled,
  onClickHistorical,
  active,
  checkedIcon = CheckboxChecked,
  uncheckedIcon = CheckboxUnchecked,
  ...props
}) => {
  const Icon = value || historicalModeEnabled ? checkedIcon : uncheckedIcon

  return (
    <Container
      data-active={active}
      disabled={disabled}
      onClick={
        historicalModeEnabled
          ? onClickHistorical
          : () => {
              if (!disabled) {
                onChange(!value)
              }
            }
      }
    >
      <Icon
        {...props}
        disabled={disabled}
        color={historicalModeEnabled ? '#D9D9D9' : color}
        fillColor={historicalModeEnabled ? '#FAC8AA' : undefined}
        className={`${className} print-${
          value ? 'checkboxChecked' : 'checkboxUnchecked'
        }`}
      />
      {label && <label className={className}>{label}</label>}
    </Container>
  )
}

const Container = styled.span`
  display: inline-flex;
  padding: 4px;

  label {
    margin-left: 8px;
  }

  ${({ 'data-active': active }) =>
    active && 'border: 2px solid #6F5B82 !important;'}
  ${({ disabled }) =>
    disabled
      ? `
        opacity: 60%;
        pointer-events: none;
        `
      : `
        cursor: pointer;
        `}

  @media print {
    display: inline;
  }
`

export default Checkbox
