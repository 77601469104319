import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { useGlobalExecutions } from '../../../../hooks/data/useGlobalExecutions'
import usePagination from '../../../../hooks/metadata/use-pagination'
import useSelectItemsTable from '../../../../hooks/use-select-items-table'
import { useFilters } from '../../../../hooks/useFilters'
import { getConnectionStatus } from '../../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../../utils/constants/connection'
import {
  EXECUTION_QA_STATUS,
  EXECUTION_STATUS
} from '../../../../utils/constants/execution'
import { COLUMN_TYPES } from '../../../../utils/constants/table'
import { getElipsisText } from '../../../../utils/helpers/strings'
import { getSelectPropertiesFromDataExecution } from '../../../../utils/helpers/tables/executions'
import BasePage from '../../../../views/base-page'
import BaseTable from '../../../../views/tables/base-table'
import ExecutionStatusTag from '../../../../views/tags/execution-status'
import UnitTag from '../../../../views/tags/unit'
import Translation from '../../../../views/translations'
import { NoConnection } from '../../../NoConnection'

import { DownloadReportButton } from './DownloadReportButton'
import { ExecutionHistoryFilterTags } from './ExecutionHistoryFilterTags'
import { Filters } from './Filters'
import { Footer } from './Footer'


export const ExecutionHistoryFiltersContext = React.createContext({})

const columns = [
  {
    title: <Translation id='unit' />,
    dataIndex: 'procedure.unit',
    minWidth: 90,
    render: (text) => <UnitTag unit={text} />
  },
  {
    title: <Translation id='series' />,
    dataIndex: 'procedure.serie.name',
    minWidth: 70,
    sorter: true
  },
  {
    title: <Translation id='key' />,
    dataIndex: 'procedure.key',
    minWidth: 90,
    sorter: true
  },
  {
    title: <Translation id='title' />,
    dataIndex: 'procedure.title',
    minWidth: 100,
    sorter: true
  },
  {
    title: <Translation id='rev' />,
    dataIndex: 'procedure.rev',
    width: 50,
    sorter: true
  },
  {
    title: <Translation id='executionGoal' />,
    dataIndex: 'executionGoal.name',
    ellipsis: true,
    minWidth: 120,
    render: (text) => <p>{getElipsisText(text, 23)}</p>
  },
  {
    title: <Translation id='lastMod' />,
    dataIndex: 'updatedAt',
    sorter: true,
    defaultSortOrder: 'descend',
    width: 70,
    type: COLUMN_TYPES.DATE
  },
  {
    title: <Translation id='startDate' />,
    dataIndex: 'createdAt',
    sorter: true,
    width: 70,
    type: COLUMN_TYPES.DATE
  },
  {
    title: <Translation id='owner' />,
    dataIndex: 'user.name',
    sorter: true,
    width: 120
  },
  {
    title: <Translation id='status' />,
    dataIndex: 'status',
    fixed: 'right',
    width: 100,
    render: (text) => <ExecutionStatusTag status={text} />
  },
  {
    title: <Translation id='qa' />,
    dataIndex: 'qaStatus',
    sorter: true,
    fixed: 'right',
    minWidth: 40,
    render: (text) => <ExecutionStatusTag qa status={text} />
  },
  {
    render: (_, exec) => <DownloadReportButton execution={exec} />,
    fixed: 'right',
    minWidth: 40
  }
]

const selectProperties = getSelectPropertiesFromDataExecution(columns, [
  'id',
  'procedure.id',
  'procedure.roleRevisionLevel',
  'procedure.roleApproveLevel',
  'manualExecution'
])

export const ExecutionHistory = () => {
  const defaultSortColumn = columns.find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'updatedAt',
    order: defaultSortColumn?.defaultSortOrder || 'descend'
  })

  const [pagination, setPagination] = usePagination()
  const connectionStatus = useSelector(getConnectionStatus)
  const { selectedItems, onSelectChange, clearSelectedItems } =
    useSelectItemsTable()
  const { filters, updateFilters, resetFilters } = useFilters()

  const clearSelectedItemsRef = useRef(clearSelectedItems)
  const setPaginationRef = useRef(setPagination)

  useEffect(() => {
    setPaginationRef.current((prev) => ({ ...prev, current: 1 }))
    clearSelectedItemsRef.current()
  }, [filters])

  const {
    data,
    isValidating,
    mutate: refetch
  } = useGlobalExecutions({
    select: selectProperties,
    skip: (pagination.current - 1) * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    status: EXECUTION_STATUS.FINISHED,
    qaStatus: EXECUTION_QA_STATUS.APPROVED,
    ...filters,
    user: filters.user?.id
  })

  const { items, total } = data || {}

  const handleTableChange = (pagination, _, sorter) => {
    setPagination({ ...pagination })
    setSorter({ ...sorter })
  }

  return connectionStatus !== CONNECTION_STATUS.ONLINE &&
    location.pathname.includes('admin') ? (
    <NoConnection />
  ) : (
    <ExecutionHistoryFiltersContext.Provider
      value={{ filters, updateFilters, resetFilters }}
    >
      <BasePage
        title={<Translation id='executionHistory' />}
        extraHeaderContent={<Filters />}
        subHeaderContent={
          Object.keys(filters).length > 0 ? (
            <ExecutionHistoryFilterTags />
          ) : undefined
        }
        totalElements={total}
        selectedElements={selectedItems.length}
        clearSelection={clearSelectedItems}
      >
        <BaseTable
          id='execution-history-table'
          columns={columns}
          dataSource={items}
          pagination={{ ...pagination, total }}
          loading={isValidating}
          onChange={handleTableChange}
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys: selectedItems.map((item) => item.id),
            onSelect: onSelectChange
          }}
        />
        <Footer selection={selectedItems} onChange={() => refetch()} />
      </BasePage>
    </ExecutionHistoryFiltersContext.Provider>
  )
}
