import { axiosInstance } from '../../..'

export async function editDepartment(departmentData) {
  const { data } = await axiosInstance.put(
    `/department/${departmentData.id}`,
    departmentData
  )

  return data
}
