import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getExecutionComponents } from '../../store/executionComponents/selectors'
import { getProcedure } from '../../store/procedure/selectors'
import { EXECUTION_COMPONENT_TYPES } from '../../utils/constants/execution/components'
import { getParents } from '../../utils/helpers/steps'
import useDisableSteps from '../use-disable-steps'

const useStepsToSign = () => {
  const procedure = useSelector(getProcedure)
  const executionComponents = useSelector(getExecutionComponents)

  const { disabledSteps } = useDisableSteps()

  const stepsToSign = useMemo(() => {
    if (!executionComponents) {
      return null
    }

    const signatures = procedure.components.filter(
      (c) => c.type === EXECUTION_COMPONENT_TYPES.SIGNATURE
    )
    const missingSignatures = signatures.filter((s) =>
      executionComponents.some(
        (ec) => ec.procedureComponentId === s.id && !ec.value?.status
      )
    )

    return [
      ...new Set(
        missingSignatures
          .map((s) => s.stepId)
          .filter((s) => !disabledSteps.includes(s))
      )
    ]
  }, [disabledSteps, executionComponents, procedure.components])

  const parentSteps = useMemo(() => {
    return stepsToSign
      ?.map((stepToSign) => getParents(procedure.index, stepToSign))
      .flat()
      .map((s) => s.id)
  }, [procedure.index, stepsToSign])

  return { stepsToSign, parentSteps }
}

export default useStepsToSign
