import { PlusOutlined } from '@ant-design/icons'
import { Button, Form as FormAntd, Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { useTranslation } from '../../hooks/useTranslation'
import Translation from '../translations'


const AddListItem = ({ submitListItem, itemTitle }) => {
  const [form] = FormAntd.useForm()
  const inputPlaceholder = useTranslation(itemTitle)

  const handleSubmitItem = async ({ element }) => {
    const itemData = {
      name: element,
      createdAt: new Date().valueOf()
    }
    await submitListItem(itemData)
    form.resetFields()
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmitItem}
      initialValues={{ element: '' }}
    >
      <Input.Group compact>
        <Form.Item
          name='element'
          style={{ width: 'calc(100% - 95px)' }}
          rules={[
            {
              required: true,
              message: <Translation id='fieldRequired' />
            }
          ]}
        >
          <Input placeholder={inputPlaceholder} />
        </Form.Item>
        <Button type='primary' htmlType='submit'>
          <PlusOutlined />
          <Translation id='add' />
        </Button>
      </Input.Group>
    </Form>
  )
}

const Form = styled(FormAntd)`
  width: 100%;
`

export default AddListItem
