import Dexie from 'dexie'

import { getDatabase } from '../../localdb'
import { readExecutions } from '../../localdb/execution/read'
import { writeExecutions } from '../../localdb/execution/write'
import { deleteExecutionComponents } from '../../localdb/execution-component/delete'
import { writeExecutionComponents } from '../../localdb/execution-component/write'
import { deleteExecutionConditionals } from '../../localdb/execution-conditionals/delete'
import { writeExecutionConditionals } from '../../localdb/execution-conditionals/write'
import { deleteExecutionSteps } from '../../localdb/execution-step/delete'
import { writeExecutionSteps } from '../../localdb/execution-step/write'
import { deleteExecutionSyncStatus } from '../../localdb/execution-sync-status/delete'
import { cleanFiles } from '../../localdb/files/delete'
import { cleanFilesContent } from '../../localdb/filesContent/delete'
import { deletePlacekeepingHistory } from '../../localdb/placekeeping/delete'
import { EXECUTION_STATUS_IS_FINAL } from '../../utils/constants/execution'
import { TABLE_NAMES } from '../../utils/constants/localdb'
import {
  deleteExecutionComments,
  writeExecutionComments
} from '../executionComments/localDb'
import {
  deleteExecutionManeuvers,
  writeExecutionManeuvers
} from '../executionManeuvers/localDb'
import {
  deleteExecutionWarnings,
  writeExecutionWarnings
} from '../executionWarnings/localDb'



const tableName = TABLE_NAMES.EXECUTIONS

export const readExecution = async (executionId) => {
  const db = getDatabase()

  const execution = await db[tableName].get(executionId)

  return execution
}

export const deleteExecutions = async (executionsIds) => {
  const db = getDatabase()

  await cleanFiles(executionsIds)
  await cleanFilesContent(executionsIds)
  await deleteExecutionSyncStatus(executionsIds)
  await deleteExecutionComments(executionsIds)
  await deleteExecutionComponents(executionsIds)
  await deleteExecutionSteps(executionsIds)
  await deleteExecutionManeuvers(executionsIds)
  await deleteExecutionWarnings(executionsIds)
  await deleteExecutionConditionals(executionsIds)
  await deletePlacekeepingHistory(executionsIds)

  return db[tableName].where('id').anyOf(executionsIds).delete()
}

export const cleanExecutions = async () => {
  const localExecutions = await readExecutions()
  const executionsToDelete = localExecutions.filter(
    (execution) =>
      execution.syncedAt > execution.updatedAt &&
      EXECUTION_STATUS_IS_FINAL[execution.status]
  )
  if (executionsToDelete.length) {
    return deleteExecutions(executionsToDelete.map((e) => e.id))
  }
}

export const writeFullExecution = async (
  { comments, components, maneuvers, steps, warnings, execution, conditionals },
  syncOverride = false
) => {
  const db = getDatabase()

  // esta función sirve para escribir la ejecución entera en BD, pero si recibe el parámetro
  // syncOverride, anula la sincronización al igualar la fecha de syncedAt a ahora
  const overrideSync = (element) => {
    const override = (object) =>
      syncOverride ? { syncedAt: new Date().valueOf(), ...object } : object
    if (Array.isArray(element)) {
      return element.map((_element) => override(_element))
    }
    return element && override(element)
  }

  return db.transaction(
    'rw',
    db[TABLE_NAMES.EXECUTIONS],
    db[TABLE_NAMES.EXECUTION_COMMENTS],
    db[TABLE_NAMES.EXECUTION_COMPONENTS],
    db[TABLE_NAMES.EXECUTION_MANEUVERS],
    db[TABLE_NAMES.EXECUTION_STEPS],
    db[TABLE_NAMES.EXECUTION_WARNINGS],
    db[TABLE_NAMES.EXECUTION_CONDITIONALS],
    db[TABLE_NAMES.EXECUTION_SYNC_STATUS],
    async () => {
      await Dexie.Promise.all([
        writeExecutions([overrideSync(execution)]),
        writeExecutionComments(overrideSync(comments)),
        writeExecutionComponents(overrideSync(components)),
        writeExecutionManeuvers(overrideSync(maneuvers)),
        writeExecutionSteps(overrideSync(steps)),
        writeExecutionWarnings(overrideSync(warnings)),
        writeExecutionConditionals(overrideSync(conditionals))
      ])
    }
  )
}
