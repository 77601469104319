import { useEffect } from 'react'

export function useCheckMassiveScroll() {
  useEffect(() => {
    const scrollContainer = document.querySelector('#procedure-scroll')
    let startY = 0
    const scrollSpeed = 1 // Velocidad de desplazamiento
    let requestId

    function scrollUpdate() {
      requestId = null
      scrollContainer.scrollTop += (scrollContainer.scrollTop % 1) * 0.1 // Ajuste para un desplazamiento más suave
      if (Math.abs(scrollContainer.scrollTop % 1) > 0) {
        requestId = requestAnimationFrame(scrollUpdate)
      }
    }

    const handleTouchStart = (event) => {
      startY = event.touches[0].clientY
    }

    const handleTouchMove = (event) => {
      event.preventDefault()

      const currentY = event.touches[0].clientY
      const deltaY = currentY - startY

      scrollContainer.scrollTop -= deltaY * scrollSpeed

      startY = currentY

      if (!requestId) {
        requestId = requestAnimationFrame(scrollUpdate)
      }
    }

    const handleTouchEnd = () => {
      startY = 0
    }

    scrollContainer.addEventListener('touchstart', handleTouchStart)
    scrollContainer.addEventListener('touchmove', handleTouchMove)
    scrollContainer.addEventListener('touchend', handleTouchEnd)

    return () => {
      scrollContainer.removeEventListener('touchstart', handleTouchStart)
      scrollContainer.removeEventListener('touchmove', handleTouchMove)
      scrollContainer.removeEventListener('touchend', handleTouchEnd)
    }
  }, [])
}
