import { writeProcedures } from '../../../localdb/procedures/write'
import { requestProcedure } from '../../../services/procedures/http/get'
import { store } from '../../../store'
import {
  addDownloadingProcedureIds,
  deleteDownloadingProcedureIds
} from '../../../store/userInterface/slice'
import { isOnline } from '../../../utils/helpers/connection'
import { notifyError } from '../../../utils/notifications'
import { downloadProceduresFiles } from '../../files/download'
import { saveHistoricalComponents } from '../../historical-components/save'

export async function downloadProcedure(
  procedureId,
  referencedProceduresIds = []
) {
  if (!isOnline()) {
    return
  }

  const nowDownloadingIds = [procedureId, ...referencedProceduresIds]

  //Añadimos el procedimiento actual al listado de procedimientos en descarga.
  store.dispatch(addDownloadingProcedureIds(nowDownloadingIds))

  try {
    //pide el procedimiento solicitado y sus referenciados todo en una peticion.
    const { data: result } = await requestProcedure(
      procedureId,
      referencedProceduresIds,
      true
    )

    const {
      procedure,
      referencedProcedures = [],
      historicalComponents = {}
    } = result

    //Compruebo que se ha descargado correctamente
    if (!procedure)
      throw new Error('Global procedure not found with ID ' + procedureId)

    //Descargo ficheros de todos los procedimientos descargados
    const downloadFileError = await downloadProceduresFiles([
      procedure,
      ...referencedProcedures
    ])

    await writeProcedures([procedure, ...referencedProcedures])
    await saveHistoricalComponents(historicalComponents)

    return {
      procedure,
      referencedProcedures,
      failedFiles: downloadFileError
    }
  } catch (error) {
    notifyError(error)
  } finally {
    //Quitamos el spinner de los procedimientos descargados.
    store.dispatch(deleteDownloadingProcedureIds(nowDownloadingIds))
  }
}
