import { Typography } from 'antd'
import styled from 'styled-components'

const { Text } = Typography

const LightText = styled(Text)`
  color: #fff;
`

export default LightText
