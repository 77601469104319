import { setGlobal } from 'reactn'

import { loadDatabase } from '../../../localdb'
import { generateUserData } from '../../../localdb/user'
import { login as loginRequest } from '../../../services/user'
import { store } from '../../../store'
import { setupSyncStatus } from '../../../store/synchronization'
import { getUser } from '../../../store/user-management/selectors'
import { setUser } from '../../../store/user-management/slice'
import { history } from '../../../utils/constants/browser-history'
import { STATUS_CODES } from '../../../utils/constants/http'
import { LOGIN_CASES } from '../../../utils/constants/login'
import { getUserLoginInfo, userExists } from '../../../utils/helpers/user'
import { encryptSHA256 } from '../../../utils/helpers/crypto'
import { erro } from '../../../utils/logger'

export async function login(email, password) {
  let onlineStatus, offlineStatus
  const user = getUser(store.getState())

  if (user) {
    await logout()
  }

  const [onlineResult, offlineResult] = await Promise.allSettled([
    requestLogin(email, password),
    readLogin(email, password)
  ])

  onlineStatus =
    onlineResult.status === 'fulfilled'
      ? onlineResult.value.status
      : STATUS_CODES.ERROR

  offlineStatus =
    offlineResult.status === 'fulfilled'
      ? offlineResult.value.status
      : STATUS_CODES.ERROR

  const {
    isLoginOk,
    isOnline,
    isFirstLogin,
    showError,
    askPassword,
    changeFirstPassword
  } = LOGIN_CASES[`${onlineStatus},${offlineStatus}`] ?? {
    showError: 'error.login.unknown'
  }

  if (isLoginOk) {
    let encryptionKey = null
    let user = null
    let token = null

    if (isOnline) {
      const onlineData = onlineResult.value.data
      token = onlineData.token
      user = onlineData.user
    } else {
      user = offlineResult.value.data.user
    }

    user = { ...user, password: encryptSHA256(password) }

    if (isFirstLogin) {
      encryptionKey = generateUserData(user, token).encryptionKey
    } else {
      encryptionKey = offlineResult.value.data.encryptionKey
    }

    await loadDatabase(email, encryptionKey)

    setGlobal({
      encryptionKey,
      token,
      tokenGeneratedAt: token ? new Date() : null
    })

    store.dispatch(setUser(user))
    setupSyncStatus()
  }

  return {
    showError,
    askPassword,
    isLoginOk,
    changeFirstPassword
  }
}

export async function readLogin(username, password) {
  if (!userExists(username)) {
    return { status: STATUS_CODES.NOT_FOUND }
  }

  try {
    return {
      status: STATUS_CODES.OK,
      data: getUserLoginInfo(username, encryptSHA256(password))
    }
  } catch (err) {
    return { status: STATUS_CODES.UNAUTHORIZED }
  }
}

export async function requestLogin(email, password) {
  return loginRequest(email, password)
    .then((res) => res)
    .catch((error) => {
      erro(error)
      return {
        status: error?.response?.status || STATUS_CODES.ERROR,
        error: error?.response?.data || error
      }
    })
}

export async function relogin() {
  const { email, password } = getUser(store.getState())
  const response = await requestLogin(email, password)

  if (response.status >= 400) {
    throw new Error(response.error)
  }

  const {
    data: { user, token }
  } = response

  user.password = password
  setGlobal({
    token,
    tokenGeneratedAt: new Date()
  })
  store.dispatch(setUser(user))

  return token
}

export async function logout() {
  const user = getUser(store.getState())

  if (user) {
    setGlobal({
      token: null,
      encryptionKey: null
    })
    store.dispatch(setUser(null))
  }

  await loadDatabase(null)
  // eslint-disable-next-line no-console
  console.clear()
  history.push({ pathname: '/' })
  location.reload()
}
