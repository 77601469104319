import { getDatabase } from '../../localdb'
import { _writeLocalDbData } from '../../localdb/common/write'
import { TABLE_NAMES } from '../../utils/constants/localdb'

const tableName = TABLE_NAMES.EXECUTION_MANEUVERS

export const readExecutionManeuvers = async (executionId) => {
  const db = getDatabase()

  const maneuvers = await db[tableName].where({ executionId }).toArray()

  return maneuvers
}

export const writeExecutionManeuvers = async (executionManeuvers) => {
  return _writeLocalDbData({
    tableName,
    data: executionManeuvers,
    action: 'PUT'
  })
}

export const deleteExecutionManeuvers = async (executionIds) => {
  const db = getDatabase()

  return db[tableName].where('executionId').anyOf(executionIds).delete()
}
