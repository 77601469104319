import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { loadFile } from '../../../../../application/files/load'
import Document from '../../../../../components/icons/document'
import Download from '../../../../../components/icons/download'
import { getLinkToBlob } from '../../../../../utils/helpers/files'
import { notificationMessage } from '../../../../../utils/helpers/notification-message'
import Translation from '../../../../translations'

const DocumentItem = ({ file, rowIndex }) => {
  return (
    <Container id={`document-entry-${rowIndex}`}>
      <DocumentWrapper>
        <div>
          <Document />
          <TextContainer id={`document-name-${rowIndex}`}>
            {file.name}
          </TextContainer>
        </div>
        <StyledButton
          onClick={async () => {
            const loadedFile = await loadFile(file.id)

            if (loadedFile) {
              getLinkToBlob(loadedFile.originalName, loadedFile.file)
            } else {
              notificationMessage({
                message: 'Error',
                description: <Translation id='fileRefError' />
              })
            }
          }}
        >
          <Download color='#fff' />
        </StyledButton>
      </DocumentWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: 'row';
  flex-wrap: 'nowrap';
  margin-bottom: 32px;
`

const DocumentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const TextContainer = styled.span`
  margin-left: 16px;
  line-height: 16px;
  font-weight: bold;
`

const StyledButton = styled(Button)`
  width: 34px;
  height: 30px !important;
  padding: 0;
  border: none;
  border-radius: 0px;
  height: initial;
  background-color: #272928;

  &:hover,
  &:active,
  &:focus {
    background-color: #b8bab6;
    background: #b8bab6;
  }
`

export default DocumentItem
