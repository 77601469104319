import { createSlice } from '@reduxjs/toolkit'

export const historicalValuesSlice = createSlice({
  name: 'historicalValues',
  initialState: {
    component: null,
    historicalCountStep: 0
  },
  reducers: {
    setComponent: (state, action) => {
      const { component } = action.payload
      state.component = component
    },
    setHistoricalCountStep: (state, action) => {
      const { historicalCountStep } = action.payload
      state.historicalCountStep = historicalCountStep
    }
  }
})

export const { setComponent, setHistoricalCountStep } =
  historicalValuesSlice.actions

export default historicalValuesSlice.reducer
