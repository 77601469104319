import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'


import useDisableSteps from '../../../hooks/use-disable-steps'
import { getExecution } from '../../../store/execution/selectors'
import { getExecutionComponents } from '../../../store/executionComponents/selectors'
import { getProcedure } from '../../../store/procedure/selectors'

import Step from './step'

const ProcedureSteps = ({
  steps,
  loadingPrint,
  procedureReady,
  onSelectStep
}) => {
  const procedure = useSelector(getProcedure)
  const execution = useSelector(getExecution)
  const executionComponents = useSelector(getExecutionComponents)

  // get an array of maneuver parent steps
  const maneuverParentSteps = useMemo(() => {
    if (!procedure?.maneuvers) return []
    const maneuvers = procedure?.maneuvers.map(
      (maneuver) => maneuver.parentStep
    )
    return [...new Set(maneuvers)]
  }, [procedure])

  const { disabledStepsByConditionals, disabledStepsByManeuvers } =
    useDisableSteps()

  if (!procedureReady) return null

  return steps.map((step) => (
    <Step
      key={step.stepId}
      step={step}
      loadingPrint={loadingPrint}
      maneuverParentSteps={maneuverParentSteps}
      maneuverDisabledSteps={disabledStepsByManeuvers}
      conditionalDisabledSteps={disabledStepsByConditionals}
      executionComponents={executionComponents}
      executionSequentialMode={
        execution?.sequentialMode || procedure.sequentialMode
      }
      onSelectStep={onSelectStep}
    />
  ))
}

export default ProcedureSteps
