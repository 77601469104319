import React from 'react'
import styled from 'styled-components'

export const IconContainer = ({
  size = '24px',
  color = 'currentColor',
  fillColor = color,
  disabled = false,
  rotation = 0,
  icon: Icon,
  ...otherProps
}) => {
  return (
    <StyledSpan
      data-size={size}
      data-disabled={disabled}
      data-rotation={rotation}
      {...otherProps}
    >
      <Icon size={size} color={color} fillColor={fillColor} />
    </StyledSpan>
  )
}

const StyledSpan = styled.span`
  display: inline-flex;
  transform: rotate(${({ 'data-rotation': rotation }) => rotation}deg);
  width: ${({ 'data-size': size }) => size};
  height: ${({ 'data-size': size }) => size};
  opacity: ${({ 'data-disabled': disabled }) => (disabled ? 0.2 : 1)};
  align-items: center;
  justify-content: center;
`
