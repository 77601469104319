import React, { useContext } from 'react'
import styled from 'styled-components'

import FilterTags from '../../../views/filters/filter-tags'
import Translation from '../../../views/translations'


import { DownloadedProceduresFiltersContext } from '.'

export const ProcedureFilterTags = () => {
  const { filters, updateFilters, resetFilters } = useContext(
    DownloadedProceduresFiltersContext
  )

  return (
    <StyledFilterTags
      value={filters}
      onChange={(filters) => {
        updateFilters(filters)
      }}
      onClear={resetFilters}
      getDisplayName={(key) => {
        return <Translation id={key} />
      }}
      getDisplayValue={(key, value) => {
        const values = {
          status: () => <Translation id={value} />,
          device: () => value.name
        }

        return values[key]?.() || value
      }}
    />
  )
}

const StyledFilterTags = styled(FilterTags)`
  margin-top: 1rem;
`
