import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import StepContext from '..'
import { setComponent } from '../../../store/historical-values/slice'
import { setCurrentDrawer } from '../../../store/userInterface/slice'

const StepContextProvider = ({ children }) => {
  const [isReadoutEditMode, setReadoutEdit] = useState(false)
  const [isReadoutLockMode, setReadoutLock] = useState(false)
  const [historicalModeStep, setHistoricalModeStep] = useState(null)

  const dispatch = useDispatch()

  const toggleHistoricalMode = useCallback(
    (stepId) => {
      dispatch(setCurrentDrawer(null))
      dispatch(setComponent({ component: null }))
      setHistoricalModeStep(stepId)
    },
    [dispatch]
  )

  return (
    <StepContext.Provider
      value={{
        isReadoutEditMode,
        setReadoutEdit,
        isReadoutLockMode,
        setReadoutLock,
        historicalModeStep,
        toggleHistoricalMode
      }}
    >
      {children}
    </StepContext.Provider>
  )
}

export default StepContextProvider
