import { cloneDeep } from 'lodash'

import { store } from '../../../store'
import { getUser } from '../../../store/user-management/selectors'

export function mapScreenPermissionValues(
  defaultPermissionsList,
  rolePermissions
) {
  const mappedPermissionsList = defaultPermissionsList.map((sP) => ({
    id: rolePermissions?.find(
      (_rP) => _rP.screenPermissionId === sP.screenPermission.id
    )?.id,
    screenPermissionId: sP.screenPermission.id,
    screenPermission: { ...sP.screenPermission },
    createdAt: new Date().valueOf(),
    roleActionPermissions: sP.roleActionPermissions.map((aP) => ({
      actionPermissionId: aP.actionPermission.id,
      actionPermission: { ...aP.actionPermission },
      createdAt: new Date().valueOf(),
      roleScreenPermissionId: rolePermissions?.find(
        (_rP) => _rP.screenPermissionId === sP.screenPermission.id
      )?.id
    }))
  }))
  return cloneDeep(mappedPermissionsList)
}

export function userHasPermission(permission) {
  const user = getUser(store.getState())
  const {
    role: { roleScreenPermissions = [] },
    role
  } = user

  return (
    (permission &&
      roleScreenPermissions.some((screenPermission) =>
        screenPermission.roleActionPermissions?.some(
          (aP) => permission === aP.actionPermission.name
        )
      )) ||
    !!role.admin
  )
}
