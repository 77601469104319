import moment from 'moment'

import { getTranslation } from '../../../../i18n/getTranslation'
import { PAGE_SIZE_SCALE_FACTOR } from '../../../../utils/constants/config'
import { PAGE_A4_SIZE_IN_MM } from '../../../constants/report'

export function getPrintStyles(procedure, execution) {
  const date = moment(new Date()).format(getTranslation('dateFormatShort'))
  const time = moment(new Date()).format(getTranslation('timeFormat'))
  const executionCode =
    procedure.key +
    '_REV' +
    procedure.rev +
    '-' +
    moment(execution?.timestampable?.createdAt).format(
      getTranslation('executionCodeDateTimeFormat')
    )

  const width = PAGE_A4_SIZE_IN_MM.width * PAGE_SIZE_SCALE_FACTOR
  const height = PAGE_A4_SIZE_IN_MM.height * PAGE_SIZE_SCALE_FACTOR

  return `
        <style id='printStyles'>
            @page {
                size: ${width}mm ${height}mm;
                margin: 5mm 0mm;
                counter-increment: page;

                @top-left {
                    margin-right:2mm;
                    margin-left:2mm;
                    content:  "${procedure.serie.name} ${procedure.unit}";
                    white-space: pre;
                    color: grey;
                    font-size: 0.5rem;
                }

                @top-center {
                  margin-right:2mm;
                  margin-left:2mm;
                  content:   "${procedure.key}   Rev.${procedure.rev}";
                  white-space: pre;
                  color: grey;
                  font-size: 0.5rem;
              }

                @top-right {
                    margin-right:2mm;
                    margin-left:2mm;
                    content:  "${date} ${time}";
                    white-space: pre;
                    color: grey;
                    font-size: 0.5rem;
                }

                @bottom-left 
                {
                  margin-right:2mm;
                  margin-left:2mm;
                  content:  "${executionCode}";
                  white-space: pre;
                  color: grey;
                  font-size: 0.5rem;
                }

                @bottom-right
                {
                  margin-right:2mm;
                  margin-left:2mm;
                  content:  counter(page) "/" counter(pages) ;
                  white-space: pre;
                  color: grey;
                  font-size: 0.5rem;
                }
            }

            @media print {
                body {
                    margin: 0 !important;
                    padding: 0 !important;
                }
            }
        </style>
    `
}

export function getComponentStyles() {
  return (
    document.querySelector('[data-styled-version]').innerText ||
    Array.from(document.querySelector('[data-styled-version]').sheet.rules)
      .map((r) => r.cssText)
      .join('')
  )
}

export async function getReportStyles() {
  return fetch(document.querySelector('link[rel="stylesReport"]').href)
    .then((res) => {
      if (!res.ok) {
        return ''
      }
      return res.text()
    })
    .catch(() => '')
}

/**
 * Remove main page CSS rule and keep only size property por page word sections rules
 * @param {Element | null} container Container which contains the nodes
 */
export function removePageStyles(container) {
  let styleTag = container.querySelector('#wordStyles')

  if (!styleTag) {
    styleTag = container.querySelector('style')
  }

  let styleContent = styleTag.textContent

  const pageRules = styleContent.match(/@page[^{]*{[^}]*}/g)

  if (!pageRules) return

  const cssRules = getCssRules(pageRules)

  styleContent = styleContent.replace(/@page[^{]*{[^}]*}/g, '')

  const wordSectionStyles = formatWordSectionStyles(cssRules)

  styleContent += wordSectionStyles

  styleTag.textContent = styleContent
}

/**
 * Change windowtext color to black present in the whole document
 * @param {Element | null} container Container which contains the nodes
 */
export function changeWindotextColorToBlack(container) {
  const elements = container.querySelectorAll('[style*="windowtext"]')

  elements.forEach((element) => {
    const style = element.getAttribute('style')
    const newStyle = style.replace(/windowtext/g, 'black')
    element.setAttribute('style', newStyle)
  })
}

function getCssRules(pageRules) {
  const cssRules = {}

  pageRules.forEach(function (pageRule) {
    const parts = pageRule.split('{')
    const selector = parts[0].trim()
    const properties = parts[1].trim().slice(0, -1)

    const propertyObj = {}
    properties.split(';').forEach(function (property) {
      const propParts = property.split(':')
      if (propParts.length === 2) {
        propertyObj[propParts[0].trim()] = propParts[1].trim()
      }
    })

    cssRules[selector] = propertyObj
  })

  return cssRules
}

function formatWordSectionStyles(cssRules) {
  let styleContent = ''

  const { '@page': _pageRule, ...restOfPageRules } = cssRules

  for (const selector in restOfPageRules) {
    const properties = restOfPageRules[selector]

    const propertySize = properties.size
    const propertyMargin = properties.margin

    const [width, height] = propertySize.split(' ')
    const widthNotScaled = Number(width.replace('pt', '').replace('mm', ''))
    const heightNotScaled = Number(height.replace('pt', '').replace('mm', ''))

    let widthScaled = 0
    let heightScaled = 0

    if (widthNotScaled > heightNotScaled) {
      widthScaled = PAGE_A4_SIZE_IN_MM.height * PAGE_SIZE_SCALE_FACTOR
      heightScaled = PAGE_A4_SIZE_IN_MM.width * PAGE_SIZE_SCALE_FACTOR
    } else {
      widthScaled = PAGE_A4_SIZE_IN_MM.width * PAGE_SIZE_SCALE_FACTOR
      heightScaled = PAGE_A4_SIZE_IN_MM.height * PAGE_SIZE_SCALE_FACTOR
    }

    const propertySizeString = `size: ${widthScaled}mm ${heightScaled}mm;`
    const propertyMarginString = `margin: ${propertyMargin};`

    const allProperties = `
      ${propertySizeString}
      ${propertyMarginString}
    `

    styleContent += selector + ' { ' + allProperties + ' }\n'
  }

  return styleContent
}

/**
 * Set align attribute of the parent to a particular tables (if they haven't anyone) .
 * This is necessary only to print in Weasyprint.  For any reason this kind of tables doesn't fit
 * in the location when the DOM is printed by WeasyPrint.
 * To
 * @param {Element | null} container Container which contains the nodes
 * @param {String} tableSelecor Selector to specify the type of tables to treat
 */
export function setAligmentToTables(container, tableSelector) {
  const tables = container.querySelectorAll(tableSelector)

  if (!tables) return

  tables.forEach((table) => {
    const style = table.getAttribute('style')
    const isAligmentDefined =
      style?.includes('text-align') || table.align?.length > 0

    if (!isAligmentDefined) {
      const parentAlignment = table.parentElement?.align
      if (parentAlignment !== '') {
        table.align = parentAlignment
      }
    }
  })
}
