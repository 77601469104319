import React from 'react'




import { addPlant } from '../../../application/plants/create'
import { removePlant } from '../../../application/plants/delete'
import { modifyPlant } from '../../../application/plants/put'
import { addUnit } from '../../../application/units/create'
import { removeUnit } from '../../../application/units/delete'
import { modifyUnit } from '../../../application/units/put'
import ConfigSection from '../../../containers/config-section'
import SectionTitle from '../../../containers/section-title'
import usePlants from '../../../hooks/use-plants'
import useUnits from '../../../hooks/use-units'
import AddListItem from '../../editable-list/add-list-item'
import Translation from '../../translations'

import useActions from './hooks/use-actions'
import PlanUnitConfigList from './plant-unit-list'

const CustomerSettings = () => {
  const { data: plants = [], mutate: reloadPlants } = usePlants()
  const { data: units = [], mutate: reloadUnits } = useUnits()

  const {
    addCustomerSettingAction,
    modifyCustomerSettingAction,
    removeCustomerSettingAction,
    addUnitAction,
    modifyUnitAction,
    removeUnitAction
  } = useActions({
    addPlant,
    modifyPlant,
    removePlant,
    reloadCustomerSettings: reloadPlants,
    addUnit,
    modifyUnit,
    removeUnit,
    reloadUnits: reloadUnits
  })

  return (
    <ConfigSection>
      <SectionTitle>
        <Translation id='configurationPanelClients' />
      </SectionTitle>
      <AddListItem
        submitListItem={addCustomerSettingAction}
        itemTitle='execution.client.settings'
      />

      <PlanUnitConfigList
        plants={plants}
        units={units}
        deletePopoverTextId={'config.unit.delete.popover'}
        handleDeleteCustomerSetting={removeCustomerSettingAction}
        handleModifyCustomerSetting={modifyCustomerSettingAction}
        handleAddUnit={addUnitAction}
        handleModifyUnit={modifyUnitAction}
        handleDeleteUnit={removeUnitAction}
      />
    </ConfigSection>
  )
}

export default CustomerSettings
