import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import { store } from '../../../../store'
import { changeCurrentStep } from '../../../../store/execution/actions'
import { getExecution } from '../../../../store/execution/selectors'
import { getExecutionJumpsWithReturn } from '../../../../store/userInterface/selectors'
import { setExecutionJumpsWithReturnByExecutionId } from '../../../../store/userInterface/slice'
import { EXECUTION_STATUS_IS_RUNNING } from '../../../../utils/constants/execution'
import { getWrapperByBookmark } from '../../../../utils/helpers/dom-handlers'
import ConfirmPopover from '../../../popover/confirm'

const Jump = ({ component, onSelectStep }) => {
  const execution = useSelector(getExecution)
  const jumpsWithReturn = useSelector(getExecutionJumpsWithReturn)
  const dispatch = useDispatch()

  const [visible, setVisible] = useState(false)
  const currentJumps = execution ? jumpsWithReturn[execution.id] || [] : []
  const disabled = !EXECUTION_STATUS_IS_RUNNING[execution?.status]

  function setJumps(jumps) {
    if (!execution) {
      return
    }

    dispatch(
      setExecutionJumpsWithReturnByExecutionId({
        executionId: execution.id,
        jumps
      })
    )
  }

  function scroll(options, jumpsWithReturn, setJumpsWithReturn) {
    const { fromParagraph, toParagraph, isReturnable } = options

    store.dispatch(changeCurrentStep(toParagraph))
    onSelectStep([toParagraph])

    if (isReturnable) {
      addJumpWithReturn(fromParagraph, jumpsWithReturn, setJumpsWithReturn)
    }
  }

  function addJumpWithReturn(
    fromParagraph,
    jumpsWithReturn,
    setJumpsWithReturn
  ) {
    const wrapper = getWrapperByBookmark(fromParagraph)

    // Si el salto ya existe en el drawer de saltos, no se añade otra vez
    if (jumpsWithReturn.find((j) => j.paragraphNumber === fromParagraph)) return

    const jump = {
      paragraphNumber: fromParagraph,
      text: wrapper.textContent
    }

    setJumpsWithReturn([...jumpsWithReturn, jump])
  }

  const onConfirm = () => {
    scroll(component.options, currentJumps, setJumps)
    setVisible(false)
  }
  return (
    <ConfirmPopover
      onConfirm={onConfirm}
      onCancel={() => setVisible(false)}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <a
        style={{ color: disabled ? '#4B98C6' : '#A709BC' }}
        disabled={disabled}
        onClick={() => setVisible(true)}
      >
        {component.pattern}
      </a>
    </ConfirmPopover>
  )
}

export default Jump
