import { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { formatValues } from '../../../../../application/historical-components/format'
import StepContext from '../../../../../context/step'
import useExecutionDrawer from '../../../../../hooks/use-execution-drawer'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { readHistoricalValue } from '../../../../../localdb/historical-values/read'
import { getComponent } from '../../../../../store/historical-values/selectors'
import { getProcedureKey } from '../../../../../store/procedure/selectors'
import { DRAWERS } from '../../../../../utils/constants/drawer'

export default function useHistoricalValues() {
  const [historicalValues, setHistoricalValues] = useState([])
  const [loading, setLoading] = useState(false)

  const procedureKey = useSelector(getProcedureKey)
  const component = useSelector(getComponent)

  const { currentDrawer, closeDrawer } = useExecutionDrawer()
  const dateFormat = useTranslation('dateFormat')
  const timeFormat = useTranslation('timeFormat')

  const { toggleHistoricalMode } = useContext(StepContext)

  const visible = useMemo(
    () => currentDrawer === DRAWERS.HISTORICAL_DRAWER,
    [currentDrawer]
  )

  useEffect(() => {
    const getValues = async () => {
      if (visible) {
        setLoading(true)
        const historicalValues = await readHistoricalValue(
          component?.name,
          procedureKey
        )

        const formattedValues = formatValues({
          values: historicalValues?.components,
          dateFormat,
          timeFormat
        })

        setHistoricalValues(formattedValues ?? [])
        setLoading(false)
      }
    }

    getValues()

    return () => {
      setHistoricalValues([])
      setLoading(false)
    }
  }, [procedureKey, component, visible, dateFormat, timeFormat])

  const handleCloseDrawer = () => {
    closeDrawer()
    toggleHistoricalMode(null)
  }

  return {
    historicalValues,
    loading,
    visible,
    componentName: component?.name,
    closeDrawer: handleCloseDrawer
  }
}
