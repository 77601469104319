import { uploadFiles } from '../../../services/files'
import { ALLOWED_VIDEO_EXTENSIONS, FILE_TYPES } from '../../../utils/constants/files'
import { getFilenameExtension } from '../files'

export function getFilesAndVideos(media) {
  const filesToUpload = media?.filter(
    (file) =>
      !ALLOWED_VIDEO_EXTENSIONS.includes(`.${getFilenameExtension(file.name)}`)
  )
  const videosToUpload = media?.filter((file) =>
    ALLOWED_VIDEO_EXTENSIONS.includes(`.${getFilenameExtension(file.name)}`)
  )

  return { filesToUpload, videosToUpload }
}

export async function uploadFilesAndSeparateVideos(media) {
  let files = []

  const { filesToUpload, videosToUpload } = getFilesAndVideos(media)

  if (filesToUpload?.length) {
    files = await uploadFiles(filesToUpload, FILE_TYPES.MEDIA_FILE)
  }

  return { files, videosToUpload }
}
