import React from 'react'
import styled from 'styled-components'

const ColorTag = ({ id, children, color, textColor, style, className }) => {
  return (
    <StyledColorTag
      id={id || 'color-tag'}
      style={{ backgroundColor: color, color: textColor, ...style }}
      className={className}
    >
      {children}
    </StyledColorTag>
  )
}

const StyledColorTag = styled.span`
  padding: 4px;
  border-radius: 4px;
`

export default ColorTag
