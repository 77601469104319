import React from 'react'

import { IconContainer } from '../../../containers/icon'

const Download = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/download'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id='Group'
        transform='translate(0.000000, 3.000000)'
        stroke={color}
        strokeWidth='1.5'
      >
        <line x1='12.001' y1='0.75' x2='12.001' y2='12.75' id='Path'></line>
        <polyline
          id='Path'
          points='7.501 8.25 7.501 8.25 12.001 12.75'
        ></polyline>
        <polyline
          id='Path-Copy'
          transform='translate(14.250000, 10.500000) scale(-1, 1) translate(-14.250000, -10.500000) '
          points='12 8.25 12 8.25 16.5 12.75'
        ></polyline>
        <path
          d='M23.251,12.75 L23.251,14.25 C23.251,15.9068542 21.9078542,17.25 20.251,17.25 L3.751,17.25 C2.09414575,17.25 0.751,15.9068542 0.751,14.25 L0.751,12.75'
          id='Path'
        ></path>
      </g>
    </g>
  </svg>
)

export default Download
