import React from 'react'
import styled from 'styled-components'

const UsersSelectorView = ({ user }) => {
  return (
    <ContentWrapper>
      <Line>
        <strong>{user.name}</strong>
      </Line>
      <Line>{user.email}</Line>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  padding-bottom: 4px;
`

const Line = styled.p`
  margin-bottom: 0px;
`

export default UsersSelectorView
