// eslint-disable-next-line no-unused-vars
import Dexie from 'dexie'

import { erro } from '../utils/logger'

import { loadLocalDb } from './common/versions'

/**
 * @type {Dexie}
 */
export let dbInstance = null

export function loadDatabase(userEmail, encryptionKey) {
  if (dbInstance) {
    dbInstance.close()
  }

  if (userEmail) {
    return new Promise((resolve, reject) => {
      loadLocalDb(userEmail, encryptionKey)
        .then((db) => {
          dbInstance = db
          resolve()
        })
        .catch((error) => {
          erro(
            `Error loading local database for user ${userEmail}, localdb index file`,
            error
          )
          reject(error)
        })
    })
  }

  dbInstance = null
}

export function getDatabase() {
  if (!dbInstance) {
    throw new Error('Database not loaded')
  }

  return dbInstance
}

export default dbInstance
