import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.EXECUTION_STEPS

export const deleteExecutionSteps = async (executionIds) => {
  const db = getDatabase()

  return db[tableName].where('executionId').anyOf(executionIds).delete()
}
