import { createSelector } from '@reduxjs/toolkit'

import {
  EXECUTION_QA_ACTION,
  EXECUTION_MODES
} from '../../utils/constants/execution'
import { canInsertComment } from '../../utils/helpers/comments'
import { getConnectedUsers } from '../sharedExecution/selectors'

const getExecutionStore = (state) => state.execution

export const getExecution = createSelector(getExecutionStore, (store) => {
  return store.execution
})

export const getExecutionId = createSelector(getExecution, (execution) => {
  return execution?.id
})

export const getIsExecution = createSelector(getExecutionStore, (store) => {
  return store?.isExecution
})

export const getExecutionMode = createSelector(getExecutionStore, (store) => {
  return store?.mode
})

export const getIsView = createSelector(getExecutionMode, (mode) => {
  return mode === EXECUTION_MODES.VIEW
})

export const getAllowedInsertComment = createSelector(
  getExecution,
  getExecutionMode,
  (execution, mode) =>
    canInsertComment(mode, execution?.status, [EXECUTION_MODES.VIEW])
)

export const getAllowedInsertStepComment = createSelector(
  getExecution,
  getExecutionMode,
  (execution, mode) =>
    canInsertComment(mode, execution?.status, [EXECUTION_MODES.VIEW])
)

export const getIsMerge = createSelector(getExecutionMode, (mode) => {
  return mode === EXECUTION_QA_ACTION.MERGE
})

export const getIsReview = createSelector(getExecutionMode, (mode) => {
  return mode === EXECUTION_QA_ACTION.REVIEW
})

export const getIsApprove = createSelector(getExecutionMode, (mode) => {
  return mode === EXECUTION_QA_ACTION.APPROVE
})

export const getIsShared = createSelector(getExecutionMode, (mode) => {
  return mode === EXECUTION_MODES.SHARED
})

export const getExecutionLoading = createSelector(
  getExecutionStore,
  (store) => {
    return store.loading
  }
)

export const getCurrentStep = createSelector(
  getExecution,
  getConnectedUsers,
  getIsShared,
  (execution, connectedUsers, isShared, user) => {
    const connectedUser = connectedUsers?.find((u) => u.user.id === user.id)

    return isShared ? connectedUser?.currentStep : execution?.currentStep
  }
)

export const getExecutionStatus = createSelector(getExecution, (execution) => {
  return execution?.status
})

export const getExecutionQaStatus = createSelector(
  getExecution,
  (execution) => {
    return execution?.qaStatus
  }
)
