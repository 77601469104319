import { Button } from 'antd'
import React, { useRef } from 'react'
import styled from 'styled-components'

import Upload from '../../../components/icons/upload'

import AttachedFile from './attached-file'

const FileInput = ({ value, onChange, accept, buttonText }) => {
  const inputRef = useRef(null)

  const validateOnChange = (e) => {
    const file = e.target.files?.[0]

    if (file && (!accept || accept.includes(file.type))) {
      onChange(file)
    }
  }

  return (
    <>
      {value ? (
        <AttachedFile file={value} onClick={() => inputRef.current.click()} />
      ) : (
        <UploadButton
          id='upload-button'
          icon={<Upload />}
          onClick={() => inputRef.current.click()}
        >
          <span className='text'>{buttonText}</span>
        </UploadButton>
      )}
      <input
        multiple
        type='file'
        id='file'
        ref={inputRef}
        style={{ display: 'none' }}
        accept={accept.join(',')}
        onChange={validateOnChange}
      />
    </>
  )
}

const UploadButton = styled(Button)`
  display: flex;
  border-radius: 0;

  & .text {
    margin-left: 8px;
  }
`

export default FileInput
