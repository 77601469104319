import { Button as AntButton, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Translation from '../../translations'

const TableFooter = ({ buttons, parameters }) => {
  const [loadingButtons, setLoadingButtons] = useState([])
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return (
    <FooterContainer>
      {buttons.map((button, index) => {
        const { id, translationId, loading, icon, onClick, validations } =
          button

        const failedValidation = validations?.find(
          (validation) => !validation.check(parameters)
        )

        return (
          <ButtonContainer key={id || index}>
            <ButtonTooltip title={failedValidation?.message}>
              <FooterButton
                id={id}
                disabled={failedValidation}
                loading={loading || loadingButtons.includes(id)}
                icon={icon}
                onClick={() => {
                  const result = onClick(parameters)

                  if (result instanceof Promise && mounted) {
                    setLoadingButtons((loadingButtons) => [
                      ...loadingButtons,
                      id
                    ])

                    result.finally(() => {
                      if (mounted) {
                        setLoadingButtons((loadingButtons) =>
                          loadingButtons.filter((buttonId) => buttonId !== id)
                        )
                      }
                    })
                  }
                }}
              >
                <Translation id={translationId} />
              </FooterButton>
            </ButtonTooltip>
          </ButtonContainer>
        )
      })}
    </FooterContainer>
  )
}

export const FooterContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const ButtonContainer = styled.div`
  flex: 1;
  margin: 5px;
  display: inline-flex;
  justify-content: start;
  align-items: center;
`

export const ButtonTooltip = styled(Tooltip)`
  flex: 1;
  max-width: 300px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
`

export const FooterButton = styled(AntButton)`
  width: 100%;
  gap: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: initial;
  color: #fff;
  background-color: #000;
  border-radius: 0;
  &:active,
  &:focus,
  &:hover {
    color: #fff;
    background-color: #000;
  }
`

export default TableFooter
