import { Tooltip } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import ExtraHeaderContentButton from '../../components/buttons/extra-header-content'
import Download from '../../components/icons/download'
import ExtraHeaderContentMessage from '../../components/message/extra-header-content'
import ExtraHeaderContentContainer from '../../containers/extra-header-content'
import { isOnline } from '../../store/userInterface/selectors'
import Translation from '../translations'

export const GlobalDrawerButton = ({
  drawerVisible,
  setDrawerVisible,
  message,
  id,
  hasPermission
}) => {
  const online = useSelector(isOnline)
  return (
    <Tooltip
      title={!hasPermission && <Translation id='validationHasPermission' />}
    >
      <DownloadContainer
        disabled={!hasPermission || !online}
        onClick={() =>
          hasPermission && online && setDrawerVisible(!drawerVisible)
        }
      >
        <ExtraHeaderContentButton
          id={id}
          disabled={!hasPermission || !online}
          onClick={() => setDrawerVisible(!drawerVisible)}
          type='primary'
        >
          <Download color='#fff' />
        </ExtraHeaderContentButton>

        <ExtraHeaderContentMessage>{message}</ExtraHeaderContentMessage>
      </DownloadContainer>
    </Tooltip>
  )
}

const DownloadContainer = styled(ExtraHeaderContentContainer)`
  &:hover > .downloadMessage {
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }

  &[disabled] > .downloadMessage {
    color: #aaa;
  }
`
