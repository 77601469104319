import { getTranslation } from '../../../i18n/getTranslation'

export function getCompleteTexts(
  messageId,
  messageParams,
  descriptionId,
  descriptionParams,
  description
) {
  const completeMessage = getTranslation(messageId, { params: messageParams })
  const completeDescription =
    description ?? getTranslation(descriptionId, { params: descriptionParams })

  return {
    completeDescription,
    completeMessage
  }
}
