import React from 'react'

const UserAdd = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.58334 14.583C9.58334 15.9091 10.1101 17.1809 11.0478 18.1185C11.9855 19.0562 13.2573 19.583 14.5833 19.583C15.9094 19.583 17.1812 19.0562 18.1189 18.1185C19.0566 17.1809 19.5833 15.9091 19.5833 14.583C19.5833 13.2569 19.0566 11.9852 18.1189 11.0475C17.1812 10.1098 15.9094 9.58301 14.5833 9.58301C13.2573 9.58301 11.9855 10.1098 11.0478 11.0475C10.1101 11.9852 9.58334 13.2569 9.58334 14.583Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5833 12.083V17.083'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.0833 14.583H17.0833'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.833344 14.5827C0.833977 13.6527 1.06505 12.7375 1.50589 11.9187C1.94674 11.0999 2.58362 10.4031 3.35959 9.89064C4.13556 9.37817 5.02643 9.06598 5.95255 8.98199C6.87867 8.89799 7.81117 9.04481 8.66668 9.40932'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.02084 4.27051C3.02084 5.18219 3.38301 6.05653 4.02766 6.70119C4.67232 7.34584 5.54666 7.70801 6.45834 7.70801C7.37002 7.70801 8.24437 7.34584 8.88902 6.70119C9.53368 6.05653 9.89584 5.18219 9.89584 4.27051C9.89584 3.35883 9.53368 2.48448 8.88902 1.83983C8.24437 1.19517 7.37002 0.833008 6.45834 0.833008C5.54666 0.833008 4.67232 1.19517 4.02766 1.83983C3.38301 2.48448 3.02084 3.35883 3.02084 4.27051V4.27051Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default UserAdd
