import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.FILES_CONTENT

export async function deleteFilesContent(ids) {
  if (!ids || ids.length === 0) return

  const db = getDatabase()

  return db[tableName].where('id').anyOf(ids).delete()
}

export const cleanFilesContent = async (executionsIds) => {
  const db = getDatabase()

  const executionComments = await db[TABLE_NAMES.EXECUTION_COMMENTS]
    .where('executionId')
    .anyOf(executionsIds)
    .toArray()

  const executionSteps = await db[TABLE_NAMES.EXECUTION_STEPS]
    .where('executionId')
    .anyOf(executionsIds)
    .toArray()

  const stepComments =
    executionSteps?.map((step) => step.comments)?.flat() || []

  const mediaIds = [...stepComments, ...executionComments]
    .map((comment) => comment?.mediaIds)
    .flat()
    .filter((id) => id)

  return deleteFilesContent(mediaIds)
}
