import { Select } from 'antd'
import React, { useState } from 'react'
import { useDebounce } from 'react-use'

import { useGlobalDevices } from '../../../hooks/metadata/useGlobalDevices'
import Translation from '../../translations'


const SelectGlobalDevices = ({ value, width = 200, onChange }) => {
  const [search, setSearch] = useState()
  const [debouncedSearch, setDebouncedSearch] = useState()
  const { data, isValidating: loading } = useGlobalDevices({
    search: debouncedSearch,
    select: ['id', 'name']
  })

  useDebounce(() => setDebouncedSearch(search), 300, [search])

  return (
    <Select
      id='select-device'
      showSearch
      style={{ width }}
      placeholder={<Translation id='selectDevice' />}
      value={value || []}
      onChange={(value) => {
        setSearch(null)
        setDebouncedSearch(null)
        onChange(data?.items.find((d) => d.id === value))
      }}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      allowClear
      onSearch={setSearch}
      loading={loading}
      options={(data?.items || []).map((d) => ({
        label: d.name,
        value: d.id
      }))}
    />
  )
}

export default SelectGlobalDevices
