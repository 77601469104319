import { createSlice } from '@reduxjs/toolkit'

export const procedureSlice = createSlice({
  name: 'procedure',
  initialState: {
    procedure: null,
    isProcedure: null,
    updatedProceduresToDownload: []
  },
  reducers: {
    setProcedure: (state, action) => {
      state.procedure = action.payload
    },
    setIsProcedure: (state, action) => {
      state.isProcedure = action.payload
    },
    setUpdatedProceduresToDownload: (state, action) => {
      state.isProcedure = action.payload
    }
  }
})

export const { setProcedure, setIsProcedure, setUpdatedProceduresToDownload } =
  procedureSlice.actions

export default procedureSlice.reducer
