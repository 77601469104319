import { useEffect, useState } from 'react'

import { useWaitForContent } from './useWaitForContent'

export const useProcedureReady = (procedureId) => {
  const [procedureReady, setProcedureReady] = useState(false)

  const { ready } = useWaitForContent(
    '#procedure-data',
    `#procedure-id[data-id="${procedureId}"]`
  )

  useEffect(() => {
    setProcedureReady(ready && procedureId)

    return () => {
      setProcedureReady(false)
    }
  }, [ready, procedureId])

  return {
    ready: procedureReady
  }
}
