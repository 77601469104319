import { getDatabase } from '../..'
import { erro, info } from '../../../utils/logger'
import { getPKValues } from '../pk'

export async function _writeLocalDbDataNoCatch({ tableName, data, action }) {
  if (!Array.isArray(data)) {
    throw new Error("'data' must be an array")
  }

  data = data.map((d) => ({ ...d }))

  info('Writing data to DB', tableName, action, data)

  // Data will be encrypted in all tables but in 'files_content' table
  const db = getDatabase()

  return db.transaction('rw', db[tableName], async () => {
    switch (action) {
      case 'PUT':
        await db[tableName].bulkPut(data)
        return data
      case 'ADD':
        await db[tableName].bulkAdd(data)
        return data
      case 'DELETE': {
        let pks = getPKValues(tableName, data)
        await db[tableName].bulkDelete(pks)
        break
      }
    }
  })
}

/**
 * Function to write data to the local database.
 * @param {Object} options
 * @param {string} options.tableName - The name of the table you want to write the data to.
 * @param {any} options.data - The array of data you want to write.
 * @param {"PUT" | "ADD" | "DELETE"} options.action - The id of the object you want to edit. Don't pass if you want to add objects.
 */
export async function _writeLocalDbData({ tableName, data, action }) {
  return _writeLocalDbDataNoCatch({ tableName, data, action }).catch(
    (error) => {
      erro('ERROR BULK: ' + error)
    }
  )
}
