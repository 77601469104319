import { readExecutionConditionals } from '../../../localdb/execution-conditionals/read'
import { writeExecutionConditionals } from '../../../localdb/execution-conditionals/write'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { erro } from '../../../utils/logger'
import { filterByUpdatedAt } from '../../helpers'
import { setModuleIsNotSyncing } from '../../userInterface/slice'
import {
  setConditionals,
  setConditional,
  setSyncedAt,
  updateLocaldbQueue
} from '../slice'

export function saveExecutionConditionals(conditionals) {
  return async (dispatch, getState) => {
    const { execution } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    dispatch(setConditionals({ conditionals }))
    if (!isShared) {
      dispatch(
        updateLocaldbQueue({
          conditional: conditionals,
          resetQueue: true
        })
      )
    }
  }
}

export function setConditionalValue({ id, forcedValue }) {
  return async (dispatch, getState) => {
    const {
      execution,
      executionConditionals: { conditionals }
    } = getState()
    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()
    const now = new Date().valueOf()
    const conditional = conditionals.find((c) => c.id === id)

    if (conditional) {
      if (!isShared) {
        dispatch(
          updateLocaldbQueue({
            conditional: { ...conditional, forcedValue, updatedAt: now }
          })
        )
      }
      dispatch(setConditional({ id: conditional.id, forcedValue, now }))
    }
  }
}

export function loadExecutionConditionals(executionId) {
  return async (dispatch) => {
    if (!executionId) {
      dispatch(setConditionals({ conditionals: null }))
    } else {
      const executionConditionals = await readExecutionConditionals(executionId)
      dispatch(setConditionals({ conditionals: executionConditionals }))
    }
  }
}

export function setSyncDate({ entities, syncedAt }) {
  return async (dispatch, getState) => {
    const {
      execution,
      executionConditionals: { conditionals }
    } = getState()

    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    /**
     * If it is not in the redux, it is stored directly in the indexedDB
     */
    const entitiesNotInState = entities.filter(
      (entity) => entity.executionId !== execution.execution?.id
    )

    if (entitiesNotInState.length > 0) {
      try {
        await writeExecutionConditionals(
          entitiesNotInState.map((e) => ({ ...e, syncedAt }))
        )
      } catch (error) {
        erro('Error writing execution conditionals', error)
      }
    }

    /**
     * We take only the data that are not updated in redux
     */
    const syncedConditionals = filterByUpdatedAt(entities, conditionals)

    if (syncedConditionals?.length) {
      try {
        if (!isShared) {
          /**
           * It is saved in the queue for indexing, only if it is not shared
           */
          const conditionalsToQueue = syncedConditionals.map(
            (_conditional) => ({
              ..._conditional,
              syncedAt
            })
          )

          dispatch(updateLocaldbQueue({ conditional: conditionalsToQueue }))
        }

        dispatch(setSyncedAt({ entities: syncedConditionals, syncedAt }))
      } catch (error) {
        erro('Error syncing execution conditionals', error)
      }
    } else {
      dispatch(
        setModuleIsNotSyncing({
          moduleName: TABLE_NAMES.EXECUTION_CONDITIONALS
        })
      )
    }
  }
}
