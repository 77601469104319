import React from 'react'

import Translation from '../translations'

export const columns = [
  {
    title: <Translation id='key' />,
    dataIndex: 'key',
    minWidth: 90,
    sorter: true
  },
  {
    title: <Translation id='rev' />,
    dataIndex: 'rev',
    width: 70,
    sorter: true
  },
  {
    title: <Translation id='version' />,
    dataIndex: 'buildNumber',
    width: 90,
    sorter: true
  },
  {
    title: <Translation id='status' />,
    dataIndex: 'status',
    render: (text) => <Translation id={text} />
  }
]
