import React from 'react'

const WarningDiamondIcon = ({ size = '24' }) => (
  <svg
    width={size}
    height={size}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Alert Diamond' clipPath='url(#clip0_7441_72166)'>
      <g id='Vector'>
        <path
          d='M8 11.5C7.86193 11.5 7.75 11.3881 7.75 11.25C7.75 11.1119 7.86193 11 8 11'
          fill='#FAAD14'
        />
        <path
          d='M8 11.5C7.86193 11.5 7.75 11.3881 7.75 11.25C7.75 11.1119 7.86193 11 8 11'
          stroke='#F7931E'
        />
      </g>
      <path
        id='Vector_2'
        d='M8 11.5C8.13807 11.5 8.25 11.3881 8.25 11.25C8.25 11.1119 8.13807 11 8 11'
        stroke='#F7931E'
      />
      <path
        id='Vector_3'
        d='M8 9V4'
        stroke='#F7931E'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        id='Vector_4'
        d='M0.8533 8.85408C0.62707 8.62755 0.5 8.32055 0.5 8.00041C0.5 7.68028 0.62707 7.37321 0.8533 7.14675L7.1466 0.854068C7.2586 0.741835 7.3916 0.652787 7.538 0.592031C7.68447 0.531274 7.8414 0.5 7.99993 0.5C8.15847 0.5 8.31547 0.531274 8.46193 0.592031C8.60833 0.652787 8.74133 0.741835 8.85327 0.854068L15.1466 7.14675C15.3729 7.37321 15.4999 7.68028 15.4999 8.00041C15.4999 8.32055 15.3729 8.62755 15.1466 8.85408L8.85327 15.1467C8.62693 15.3729 8.32 15.4999 7.99993 15.4999C7.67993 15.4999 7.373 15.3729 7.1466 15.1467L0.8533 8.85408Z'
        stroke='#F7931E'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_7441_72166'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default WarningDiamondIcon
