import React from 'react'

const Review = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.4999 6.46678C14.7546 6.22612 14.9939 5.98212 15.2152 5.73812C15.3988 5.53456 15.5004 5.2702 15.5004 4.99611C15.5004 4.72203 15.3988 4.45767 15.2152 4.25411C13.4666 2.33345 10.6872 0.456115 7.99989 0.500782C5.31255 0.456115 2.53322 2.33345 0.785885 4.25678C0.602474 4.4604 0.500977 4.72474 0.500977 4.99878C0.500977 5.27283 0.602474 5.53716 0.785885 5.74078C2.08255 7.19273 3.69882 8.32357 5.50722 9.04412'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.49667 7.00042C6.15641 6.74434 5.8872 6.40558 5.71458 6.01628C5.54197 5.62697 5.47166 5.20002 5.51033 4.77592C5.549 4.35182 5.69536 3.94463 5.93554 3.59297C6.17572 3.2413 6.50175 2.95681 6.88271 2.76649C7.26367 2.57617 7.68694 2.48632 8.11237 2.50546C8.5378 2.5246 8.95129 2.6521 9.31362 2.87586C9.67595 3.09963 9.97512 3.41224 10.1827 3.78406C10.3904 4.15588 10.4996 4.57457 10.5 5.00042'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7 10.5C7 11.4283 7.36875 12.3185 8.02513 12.9749C8.6815 13.6313 9.57174 14 10.5 14C11.4283 14 12.3185 13.6313 12.9749 12.9749C13.6313 12.3185 14 11.4283 14 10.5C14 9.57174 13.6313 8.6815 12.9749 8.02513C12.3185 7.36875 11.4283 7 10.5 7C9.57174 7 8.6815 7.36875 8.02513 8.02513C7.36875 8.6815 7 9.57174 7 10.5V10.5Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.4996 15.5005L12.9902 12.9912'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Review
