import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { downloadProcedure } from '../application/reports/download'
import { getTranslation } from '../i18n/getTranslation'
import { readProcedure } from '../localdb/procedures/read'
import {
  onUpdate,
  removeSubscription,
  sendUpdate
} from '../services/execution/websocket'
import { setExecution } from '../store/execution/slice'
import { setConditionals } from '../store/execution-conditionals/slice'
import { setExecutionComments } from '../store/executionComments/slice'
import {
  setExecutionComponents,
  setEditedLiveValue
} from '../store/executionComponents/slice'
import { setExecutionManeuvers } from '../store/executionManeuvers/slice'
import { setExecutionSteps } from '../store/executionSteps/slice'
import {
  addConnectedUser,
  removeConnectedUser,
  setConnectedUsers,
  setUserCurrentStep,
  clearConnectedUsers
} from '../store/sharedExecution/slice'
import { getUser } from '../store/user-management/selectors'
import { getConnectionStatus } from '../store/userInterface/selectors'
import { setIsWorking } from '../store/userInterface/slice'
import { history } from '../utils/constants/browser-history'
import { CONNECTION_STATUS } from '../utils/constants/connection'
import { WEBSOCKET_ERROR_TYPES } from '../utils/constants/errors'
import {
  EXECUTION_INTERACTION_MODE,
  SHARED_EXECUTION_UPDATE_TYPES
} from '../utils/constants/execution'
import { WS_UPDATE } from '../utils/constants/websocket'
import { notificationMessage } from '../utils/helpers/notification-message'
import Translation from '../views/translations'
import UserAvatar from '../views/users/avatar'

const WS_TYPES = SHARED_EXECUTION_UPDATE_TYPES

export const useLiveExecution = ({ executionId, isView, enabled }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(getUser)
  const connectionStatus = useSelector(getConnectionStatus)
  const [joinedExecution, setJoinedExecution] = useState(false)

  useEffect(() => {
    // Si el modo shared está activo y no estamos en una ejecución, nos unimos
    if (enabled && !joinedExecution) {
      if (connectionStatus === CONNECTION_STATUS.ONLINE) {
        sendUpdate({
          type: WS_TYPES.JOIN_EXECUTION,
          payload: {
            executionId,
            mode: isView
              ? EXECUTION_INTERACTION_MODE.READ
              : EXECUTION_INTERACTION_MODE.WRITE
          }
        })
        setJoinedExecution(true)
      }
    }

    // Si el modo shared no está activo y estamos en una ejecución, salimos
    if (!enabled && joinedExecution) {
      if (connectionStatus !== CONNECTION_STATUS.OFFLINE) {
        sendUpdate({
          type: WS_TYPES.LEAVE_EXECUTION,
          payload: executionId
        })
      }
      setJoinedExecution(false)
    }

    // Sale de la ejecución cuando se pierde la conexión
    if (joinedExecution && connectionStatus !== CONNECTION_STATUS.ONLINE) {
      setJoinedExecution(false)
    }

    // Sale de la ejecución cuando se desmonta el componente
    return () => {
      if (joinedExecution) {
        sendUpdate({
          type: WS_TYPES.LEAVE_EXECUTION,
          payload: executionId
        })
        dispatch(clearConnectedUsers())
        dispatch(setIsWorking(false))
      }
    }
  }, [
    joinedExecution,
    connectionStatus,
    enabled,
    executionId,
    isView,
    dispatch
  ])

  useEffect(() => {
    const updateIds = []
    updateIds.push(
      onUpdate(
        [
          WS_TYPES.UPDATE_UPDATED_AT_EXECUTION,
          WS_TYPES.MARK_STEP,
          WS_TYPES.UNMARK_STEP,
          WS_TYPES.SET_MANEUVER_STATE,
          WS_TYPES.ADD_STEP_COMMENT,
          WS_TYPES.ADD_MEDIA_TO_STEP_COMMENT,
          WS_TYPES.DELETE_MEDIA_STEP_COMMENT,
          WS_TYPES.DELETE_STEP_COMMENT,
          WS_TYPES.EDIT_STEP_COMMENT,
          WS_TYPES.ADD_EXECUTION_COMMENT,
          WS_TYPES.ADD_MEDIA_TO_EXECUTION_COMMENT,
          WS_TYPES.DELETE_MEDIA_EXECUTION_COMMENT,
          WS_TYPES.DELETE_EXECUTION_COMMENT,
          WS_TYPES.EDIT_EXECUTION_COMMENT,
          WS_TYPES.SET_COMPONENT_VALUE,
          WS_TYPES.SET_STATUS,
          WS_TYPES.SET_STATUS_COMMENT,
          WS_TYPES.PREPARE_EXECUTION_TO_FINISH,
          WS_TYPES.SET_CONDITIONAL
        ],
        ({ type, payload }) => {
          dispatch({
            type,
            payload: {
              ...payload,
              sendToWs: false
            }
          })
        }
      )
    )

    updateIds.push(
      onUpdate(WS_TYPES.SET_CURRENT_STEP, ({ from, payload }) => {
        dispatch(
          setUserCurrentStep({ userId: from, step: payload, sendToWs: false })
        )
      })
    )

    updateIds.push(
      onUpdate(WS_TYPES.JOIN_EXECUTION, ({ from, payload }) => {
        if (currentUser.id === from) return

        dispatch(
          addConnectedUser({
            user: payload,
            currentStep: null,
            sendToWs: false
          })
        )

        notificationMessage({
          type: 'open',
          message: <Translation id='userJoinedExecution' />,
          description: (
            <span>
              <UserAvatar user={payload.user} />
              <Translation
                id='userJoinedExecutionDescription'
                params={{
                  user: payload.user.name,
                  mode: getTranslation(`executionMode${payload.mode}`)
                }}
              ></Translation>
            </span>
          )
        })
      })
    )

    updateIds.push(
      onUpdate(WS_TYPES.LEAVE_EXECUTION, ({ from, payload }) => {
        if (from !== currentUser.id) {
          dispatch(removeConnectedUser({ userId: from, sendToWs: false }))
        }

        notificationMessage({
          type: 'open',
          message: <Translation id='userLeftExecution' />,
          description: (
            <NotificationDescription>
              <UserAvatar user={payload.user} />
              <Translation
                id='userLeftExecutionDescription'
                params={{ user: payload.user.name }}
              ></Translation>
            </NotificationDescription>
          )
        })
      })
    )

    updateIds.push(
      onUpdate(WS_UPDATE.SOCKET_ERROR, ({ payload }) => {
        const { type } = payload

        const sendNotificationError = (translation) => {
          notificationMessage({
            type: 'error',
            message: getTranslation('error'),
            description: getTranslation(translation)
          })
        }

        if (type === WEBSOCKET_ERROR_TYPES.EXEC_ALREADY_CONNECTED) {
          sendNotificationError('sharedAlreadyConnected')
          history.push('/dashboard/procedures')
        } else if (type === WEBSOCKET_ERROR_TYPES.EXEC_NOT_CONNECTED) {
          sendNotificationError('sharedNotConnected')
        } else {
          sendNotificationError('sharedUnknownError')
        }
      })
    )

    updateIds.push(
      onUpdate(WS_TYPES.SYNC, async ({ payload }) => {
        const procedure = await readProcedure(payload.execution.procedureId)

        if (!procedure) {
          await downloadProcedure(payload.execution.procedureId)
        }
        dispatch(setExecution({ execution: payload.execution }))
        dispatch(setExecutionComments({ comments: payload.comments }))
        dispatch(setExecutionComponents({ components: payload.components }))
        const readoutEditedComponents = payload.components.filter(
          (c) => c.additionalValues !== null
        )
        if (readoutEditedComponents.length > 0) {
          readoutEditedComponents.map((c) =>
            dispatch(
              setEditedLiveValue({
                componentId: c.procedureComponentId,
                liveValue: {
                  componentId: c.procedureComponentId,
                  editedBy: c.additionalValues.editedBy,
                  quality: c.additionalValues.quality,
                  unit: c.additionalValues.unit,
                  value: c.value
                }
              })
            )
          )
        }
        dispatch(setExecutionManeuvers({ maneuvers: payload.maneuvers }))
        dispatch(setExecutionSteps({ steps: payload.steps }))
        dispatch(setConnectedUsers({ users: payload.connectedUsers }))
        dispatch(setConditionals({ conditionals: payload.conditionals }))
      })
    )

    return () => {
      updateIds.forEach((updateId) => removeSubscription(updateId))
    }
  }, [currentUser.id, dispatch])
}

const NotificationDescription = styled.span`
  display: flex;
  align-items: center;
`
