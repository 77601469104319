import React, { useRef } from 'react'

import AttachmentButton from '../views/buttons/attachment'

function useAttachmentButton({ disabled }) {
  const ref = useRef(null)

  const AttachmentButtonWithRef = ({
    icon: Icon,
    onChangeMedia,
    acceptedTypes,
    onClick
  }) => (
    <AttachmentButton
      acceptedTypes={acceptedTypes}
      disabled={disabled}
      icon={Icon}
      onChangeMedia={onChangeMedia}
      fileRef={ref}
      onClick={onClick}
    />
  )

  return [ref, AttachmentButtonWithRef]
}

export default useAttachmentButton
