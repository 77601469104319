import { EXECUTION_COMPONENT_TYPES } from '../../../utils/constants/execution/components'
import { formatComponentValue } from '../../../utils/helpers/components/signatures'

export function formatValues({ values, dateFormat, timeFormat }) {
  const processedValues = []

  values?.forEach((historicalValue) => {
    const options = JSON.parse(historicalValue.options ?? '{}')

    if (historicalValue.type !== EXECUTION_COMPONENT_TYPES.MULTICELL) {
      const formattedValue = getFormattedValue({
        historicalValue,
        dateFormat,
        timeFormat
      })
      processedValues.push(formattedValue)
    } else if (
      !processedValues.some(
        (v) =>
          v.timestamp === historicalValue.updatedAt && v.group === options.group
      )
    ) {
      const formattedValue = getFormattedValue({
        historicalValue,
        dateFormat,
        timeFormat
      })

      processedValues.push({ ...formattedValue, group: options.group })
    }
  })

  return processedValues
}

function getFormattedValue({ historicalValue, dateFormat, timeFormat }) {
  return {
    rev: historicalValue.rev,
    value: formatComponentValue({
      value: historicalValue.value,
      type: historicalValue.type,
      dateFormat,
      timeFormat
    }),
    timestamp: historicalValue.updatedAt,
    key: historicalValue.id
  }
}
