import { Result, Button } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import NoConnectionIcon from '../components/icons/no-connection-icon'
import Centered from '../containers/centered'
import { enableConnection } from '../store/userInterface/actions'
import { getConnectionStatus } from '../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../utils/constants/connection'
import Translation from '../views/translations'

export const NoConnection = () => {
  const connectionStatus = useSelector(getConnectionStatus)
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Centered>
      <Result
        icon={<NoConnectionIcon color='#B8BAB6' size='120px' />}
        title={<Translation id='noConnectionTitle' />}
        subTitle={<Translation id='noConnectionDescription' />}
        extra={
          <>
            <Button onClick={() => history.push('/dashboard/procedures')}>
              <Translation id='goBack' />
            </Button>
            {connectionStatus !== CONNECTION_STATUS.ONLINE && (
              <Button
                type='primary'
                onClick={() => dispatch(enableConnection())}
                loading={connectionStatus !== CONNECTION_STATUS.DISABLED}
              >
                <Translation id='reconnect' />
              </Button>
            )}
          </>
        }
      />
    </Centered>
  )
}
