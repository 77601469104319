import React from 'react'

import { IconContainer } from '../../../containers/icon'

export const SettingsIcon = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/settings'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path
        d='M10.546,2.438 C11.269,3.241 12.507,3.306 13.31,2.582 C13.361,2.536 13.409,2.488 13.454,2.438 L14.4,1.4 C15.125,0.597 16.364,0.534 17.167,1.26 C17.604,1.655 17.84,2.225 17.81,2.813 L17.739,4.213 C17.685,5.293 18.516,6.213 19.596,6.267 C19.661,6.27 19.725,6.27 19.79,6.267 L21.19,6.196 C22.271,6.143 23.19,6.976 23.243,8.056 C23.272,8.643 23.036,9.212 22.6,9.606 L21.558,10.546 C20.755,11.272 20.692,12.511 21.417,13.314 C21.461,13.363 21.508,13.41 21.558,13.455 L22.6,14.395 C23.403,15.12 23.466,16.359 22.74,17.162 C22.345,17.599 21.775,17.835 21.187,17.805 L19.787,17.734 C18.707,17.678 17.787,18.509 17.731,19.589 C17.728,19.656 17.728,19.723 17.731,19.79 L17.802,21.19 C17.85,22.271 17.013,23.186 15.932,23.234 C15.353,23.26 14.792,23.028 14.4,22.6 L13.459,21.559 C12.734,20.756 11.495,20.693 10.692,21.418 C10.643,21.463 10.595,21.51 10.551,21.559 L9.606,22.6 C8.878,23.4 7.639,23.459 6.839,22.731 C6.408,22.339 6.174,21.775 6.2,21.192 L6.272,19.792 C6.328,18.712 5.497,17.792 4.417,17.736 C4.35,17.733 4.283,17.733 4.216,17.736 L2.816,17.807 C1.736,17.863 0.815,17.034 0.759,15.954 C0.728,15.366 0.963,14.795 1.4,14.4 L2.441,13.46 C3.244,12.734 3.307,11.495 2.582,10.692 C2.538,10.643 2.491,10.596 2.441,10.551 L1.4,9.606 C0.599,8.879 0.539,7.641 1.265,6.84 C1.658,6.407 2.225,6.172 2.809,6.2 L4.209,6.271 C5.289,6.328 6.21,5.498 6.267,4.419 C6.271,4.35 6.271,4.281 6.267,4.211 L6.2,2.81 C6.149,1.729 6.984,0.812 8.065,0.761 C8.648,0.734 9.213,0.968 9.606,1.4 L10.546,2.438 Z'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M12,7.501 C14.485,7.501 16.5,9.516 16.5,12.001 C16.5,14.486 14.485,16.501 12,16.501 C9.515,16.501 7.5,14.486 7.5,12.001 C7.5,9.516 9.515,7.501 12,7.501 Z'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
    </g>
  </svg>
)

export default SettingsIcon
