import { Input, Form } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import Modal from '..'
import { getTranslation } from '../../../i18n/getTranslation'
import { changePassword } from '../../../services/user/password/http/put'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { reencryptUserData } from '../../../utils/helpers/user'
import Translation from '../../translations'

const ChangePasswordModal = ({
  visible,
  setVisible,
  isFirstTime = false,
  email,
  onFinish
}) => {
  const [form] = Form.useForm()
  const [passwordError, setPasswordError] = useState(null)

  const onOk = async ({ currentPassword, newPassword }) => {
    try {
      await changePassword(email, currentPassword, newPassword)
      if (!isFirstTime) {
        reencryptUserData(email, currentPassword, newPassword)
      }
      setVisible(false)

      notificationMessage({ message: getTranslation('passwordChangedOK') })

      setPasswordError(null)

      onFinish?.()
    } catch (e) {
      setPasswordError('failedAuthAttempt')
    } finally {
      form.resetFields()
    }
  }

  return (
    <Modal
      id='change-password-modal'
      title={<Translation id='changePassword' />}
      okButtonText={<Translation id='saveChanges' />}
      closable={!isFirstTime}
      maskClosable={!isFirstTime}
      cancellable={!isFirstTime}
      onCancel={() => {
        form.resetFields()
        setVisible(false)
      }}
      visible={visible}
      setVisible={setVisible}
      formId='changeUserPassword'
    >
      <Form
        name='changeUserPassword'
        id='changeUserPassword'
        layout='vertical'
        onFinish={(values) => {
          onOk(values)
        }}
        autoComplete='off'
        form={form}
      >
        {isFirstTime && (
          <h2>
            <Translation id='welcomeToTecOSProceed' />
          </h2>
        )}
        <p>
          {isFirstTime ? (
            <Translation id='welcomeText' />
          ) : (
            <Translation id='changePasswordText' />
          )}
        </p>

        <Form.Item
          label={<Translation id='currentPassword' />}
          name='currentPassword'
          rules={[
            { required: true, message: <Translation id='fieldRequired' /> }
          ]}
        >
          <Input.Password id='current-password' autoComplete='password' />
        </Form.Item>

        <Form.Item
          label={<Translation id='newPassword' />}
          name='newPassword'
          rules={[
            { required: true, message: <Translation id='fieldRequired' /> }
          ]}
        >
          <Input.Password
            id='new-password'
            autoComplete='new-password'
            minLength='8'
          />
        </Form.Item>

        <Form.Item
          label={<Translation id='repeatNewPassword' />}
          dependencies={['newPassword, currentPassword']}
          name='repeatNewPassword'
          rules={[
            { required: true, message: <Translation id='fieldRequired' /> },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('newPassword') !== value) {
                  return Promise.reject(
                    new Error(getTranslation('newPasswordNotEqualError'))
                  )
                } else if (getFieldValue('currentPassword') === value) {
                  return Promise.reject(
                    new Error(
                      getTranslation('newPasswordEqualOldPasswordError')
                    )
                  )
                } else {
                  return Promise.resolve()
                }
              }
            })
          ]}
        >
          <Input.Password
            id='repeat-new-password'
            autoComplete='new-password'
            minLength='8'
          />
        </Form.Item>
        {passwordError && (
          <ErrorMessage>{<Translation id={passwordError} />}</ErrorMessage>
        )}
      </Form>
    </Modal>
  )
}

const ErrorMessage = styled.p`
  color: #f12e2e;
`

export default ChangePasswordModal
