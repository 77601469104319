import randomcolor from 'randomcolor'

// calcular el tamaño que ocupa una letra basado en el fontSize usado
function calculateLetterWidth(txt = 'M', font = '14px Arial') {
  const element = document.createElement('canvas')
  const context = element.getContext('2d')
  context.font = font
  return Math.ceil(context.measureText(txt).width || 11)
}

const defaultWidth = calculateLetterWidth()

export function getColorFromSeed(seed) {
  if (!seed) {
    return null
  }

  return randomcolor({
    seed,
    luminosity: 'light'
  })
}

export function getWidthInPixels(size, padding = 20) {
  return `${size * defaultWidth + padding}px`
}
