import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.PLACEKEEPING_HISTORY

/**
 * Deletes the placekeeping history for multiple executions
 * @param {Array} executionIds
 * @returns the result of the local db query
 */
export async function deletePlacekeepingHistory(executionIds) {
  const db = getDatabase()

  return db[tableName].where('executionId').anyOf(executionIds).delete()
}
