import { getDatabase } from '../../..'
import { TABLE_NAMES } from '../../../../utils/constants/localdb'

const tableName = TABLE_NAMES.UNITS

export async function deletePlant(plantId) {
  const db = getDatabase()

  return db[tableName].where('plantId').equals(plantId).delete()
}
