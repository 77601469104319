import { getDatabase } from '../../..'
import { TABLE_NAMES } from '../../../../utils/constants/localdb'

const tableName = TABLE_NAMES.UNITS

export async function readPlantByName(name) {
  const db = getDatabase()

  return db[tableName].where('name').equals(name).toArray()
}
