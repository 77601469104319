import { Button, Popconfirm } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Alert from '../../../../components/icons/alert'
import DeleteIcon from '../../../../components/icons/delete-icon'
import EditIcon from '../../../../components/icons/edit-icon'
import useDeletePopover from '../../../../hooks/use-delete-popover'
import Translation from '../../../translations'

import useClickEdit from './hooks/use-click-edit'

const ExtraStuff = ({
  element,
  deletePopoverTextId,
  disabledEdit,
  disabledDelete,
  handleDelete,
  onClickEdit
}) => {
  const {
    deletePopover,
    closeDeletePopover,
    openDeletePopover,
    handleDeleteElement
  } = useDeletePopover({ elementId: element?.id, handleDelete })

  const { onClickToEdit } = useClickEdit({ element, onClickEdit })

  return (
    <section onClick={(e) => e.stopPropagation()}>
      <ActionButton
        icon={<EditIcon size={14} />}
        onClick={onClickToEdit}
        disabled={disabledEdit}
      />{' '}
      <Popconfirm
        placement='topRight'
        showArrow={true}
        icon={<Alert size={16} />}
        open={deletePopover}
        title={<Translation id={deletePopoverTextId} />}
        onCancel={closeDeletePopover}
        onConfirm={handleDeleteElement}
        okText={<Translation id='yes' />}
        cancelText={<Translation id='no' />}
        okButtonProps={{
          style: { borderRadius: '2px' }
        }}
        cancelButtonProps={{
          style: { borderRadius: '2px' }
        }}
      >
        <ActionButton
          icon={<DeleteIcon size={14} />}
          onClick={openDeletePopover}
          disabled={disabledDelete}
        />
      </Popconfirm>
    </section>
  )
}

const ActionButton = styled(Button)`
  background: #ffffff;
  border-radius: 2px;
  border: none;
`

export default ExtraStuff
