import React from 'react'

const ArrowDown = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 26 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='atom/icon/down arrow'>
      <g id='arrow down'>
        <path
          id='Path'
          d='M25 6L13.5653 17.7588C13.2539 18.0802 12.7472 18.0802 12.4357 17.7599L12.4347 17.7588L1 6'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
)

export default ArrowDown
