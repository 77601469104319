import { Spin } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useExecution } from '../../hooks/useExecution'
import { getExecution, getExecutionId } from '../../store/execution/selectors'
import { getProcedure } from '../../store/procedure/selectors'
import ExecutionView from '../../views/execution-view'
import Translation from '../../views/translations'
import { NotFound } from '../NotFound'



export const ExecutionDetail = ({ entity, mode }) => {
  const procedure = useSelector(getProcedure)
  const execution = useSelector(getExecution)
  const executionId = useSelector(getExecutionId)

  const { id, executionRouterMode } = useParams()

  const { loaded } = useExecution(id, entity, mode ?? executionRouterMode)

  if (!loaded) {
    return (
      <SpinContainer id='loading-execution'>
        <Spin size='large' />
        <h3>Loading...</h3>
      </SpinContainer>
    )
  }

  if (!procedure) {
    return <NotFound message={<Translation id='procedureNotFound' />} />
  }

  if (executionId && !execution) {
    return <NotFound message={<Translation id='executionNotFound' />} />
  }

  return <ExecutionView />
}

const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
