import React from 'react'
import styled from 'styled-components'

import ExecutionViewFlex from '../../../containers/execution-view/flex'
import Translation from '../../translations'

const NoContent = () => (
  <Wrapper>
    <SubTitle>
      <Translation id='signature' />
    </SubTitle>
    <SubTitle>
      <Translation id='pending' />
    </SubTitle>
  </Wrapper>
)

const Wrapper = styled(ExecutionViewFlex)`
  align-items: start;
  padding: 16px;
  min-height: 118px;
  border: 1px solid #d9d9d9;
  border-radius: 0 0 8px 8px;
`

const SubTitle = styled.div`
  color: #8f8f8f;
  font-weight: 500;
`

export default NoContent
