import { useContext } from 'react'

import StepContext from '../../../../../context/step'

export default function useHistoricalButton({ stepId }) {
  const { historicalModeStep, toggleHistoricalMode } = useContext(StepContext)

  const toggleHistoricalModeStep = () => {
    toggleHistoricalMode(historicalModeStep ? null : stepId)
  }

  return { historicalModeStep, toggleHistoricalModeStep }
}
