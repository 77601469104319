import React from 'react'
import styled from 'styled-components'

import Modal from '..'
import Translation from '../../translations'

const RejectReasonsInfoModal = ({
  isOpen,
  handleRejectModal,
  rejectReason
}) => (
  <Modal
    visible={isOpen}
    title={<Translation id='rejectModal.rejectReason' />}
    okButtonText={<Translation id='continue' />}
    onOk={() => handleRejectModal()}
    cancellable={false}
    maskClosable={false}
    closable={false}
  >
    <Translation id='rejectModal.infoMessage' />
    <RejectReasonsContainer>{rejectReason}</RejectReasonsContainer>
  </Modal>
)

const RejectReasonsContainer = styled.p`
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 12px;
  margin-top: 12px;
  box-shadow: 1px 2px #00000012;
`

export default RejectReasonsInfoModal
