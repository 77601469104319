import React from 'react'

const ExpandIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='expand-icon'
  >
    <g clipPath='url(#clip0_8047_34518)'>
      <path
        d='M10.334 5.66927L15.6673 0.335938'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.333984 15.6693L5.66732 10.3359'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.66732 15.6693H0.333984V12.3359'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.6673 3.66927V0.335938H12.334'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.334 10.3359L15.6673 15.6693'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.333984 0.335938L5.66732 5.66927'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.333984 3.66927V0.335938H3.66732'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.334 15.6693H15.6673V12.3359'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8047_34518'>
        <rect width={size} height={size} fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default ExpandIcon
