import React from 'react'

import { IconContainer } from '../../../containers/icon'

const AnnotationIcon = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.8709 12.7799L8.77759 13.2224L9.21925 10.1282L17.1743 2.1732C17.9065 1.44096 19.0937 1.44096 19.8259 2.1732C20.5582 2.90544 20.5582 4.09263 19.8259 4.82487L11.8709 12.7799Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.29 3.05762L18.9417 5.70928'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.625 12.875V19.125C16.625 19.8154 16.0654 20.375 15.375 20.375H2.875C2.18464 20.375 1.625 19.8154 1.625 19.125V6.625C1.625 5.93464 2.18464 5.375 2.875 5.375H9.125'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default AnnotationIcon
