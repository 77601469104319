import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useGlobalExecutions } from '../../../../hooks/data/useGlobalExecutions'
import { useLocalExecutions } from '../../../../hooks/data/useLocalExecutions'
import usePagination from '../../../../hooks/metadata/use-pagination'
import { getUser } from '../../../../store/user-management/selectors'
import {
  EXECUTION_ROUTER_MODE,
  EXECUTION_STATUS
} from '../../../../utils/constants/execution'
import { COLUMN_TYPES } from '../../../../utils/constants/table'
import { getElipsisText } from '../../../../utils/helpers/strings'
import { getSelectPropertiesFromDataExecution } from '../../../../utils/helpers/tables/executions'
import ElementsCount from '../../../../views/elements-count'
import { showJoinSharedExecutionModal } from '../../../../views/modal/join-shared-execution-modal'
import BaseTable from '../../../../views/tables/base-table'
import ExecutionStatusTag from '../../../../views/tags/execution-status'
import UnitTag from '../../../../views/tags/unit'
import Translation from '../../../../views/translations'
import { DownloadExecutionButton } from '../../GlobalExecutionsDrawer/DownloadExecutionButton'

const columns = [
  {
    title: <Translation id='unit' />,
    dataIndex: 'procedure.unit',
    minWidth: 70,
    render: (text) => <UnitTag unit={text} />
  },
  {
    title: <Translation id='series' />,
    dataIndex: 'procedure.serie.name',
    width: 70,
    sorter: true
  },
  {
    title: <Translation id='key' />,
    dataIndex: 'procedure.key',
    minWidth: 90,
    sorter: true
  },
  {
    title: <Translation id='title' />,
    dataIndex: 'procedure.title',
    minWidth: 100,
    sorter: true
  },
  {
    title: <Translation id='rev' />,
    dataIndex: 'procedure.rev',
    width: 70,
    sorter: true
  },
  {
    title: <Translation id='executionGoal' />,
    dataIndex: 'executionGoal.name',
    width: 120,
    render: (text) => <p>{getElipsisText(text, 23)}</p>
  },
  {
    title: <Translation id='lastMod' />,
    dataIndex: 'updatedAt',
    sorter: true,
    defaultSortOrder: 'descend',
    width: 70,
    type: COLUMN_TYPES.DATE
  },
  {
    title: <Translation id='owner' />,
    dataIndex: 'user.name',
    sorter: true,
    width: 123
  },
  {
    title: <Translation id='status' />,
    dataIndex: 'status',
    width: 136,
    fixed: 'right',
    render: (text) => <ExecutionStatusTag status={text} />
  }
]

const selectProperties = getSelectPropertiesFromDataExecution(columns, [
  'id',
  'user.id',
  'serie.id',
  'serie.name'
])

export const ExecutionsTable = () => {
  const history = useHistory()
  const currentUser = useSelector(getUser)
  const defaultSortColumn = columns.find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'updatedAt',
    order: defaultSortColumn?.defaultSortOrder || 'descend'
  })

  const [localExecutions, loadingLocalExecutions] = useLocalExecutions()
  const [pagination] = usePagination(100)
  const {
    data,
    isValidating,
    mutate: refetch
  } = useGlobalExecutions({
    select: selectProperties,
    skip: (pagination.current - 1) * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    userDownloadable: true,
    // rolePlayable: true,
    status: [
      EXECUTION_STATUS.INTERRUPTED,
      EXECUTION_STATUS.SHARED,
      EXECUTION_STATUS.EXECUTING,
      EXECUTION_STATUS.PAUSED
    ]
  })

  const { items } = data || {}

  useEffect(() => {
    refetch()
  }, [refetch, localExecutions?.length])

  const handleTableChange = (_pagination, _, sorter) => {
    setSorter({ ...sorter })
  }

  const actionColumn = {
    render: (_, execution) => (
      <DownloadExecutionButton
        selectedExecution={execution}
        localExecutions={localExecutions}
        loading={loadingLocalExecutions}
        currentUser={currentUser}
      />
    ),
    fixed: 'right',
    minWidth: 40
  }

  return (
    <>
      <h3>
        <Translation id='userDashboardExecutionstypes' />
      </h3>
      <ElementsCount total={items?.length} />
      <Table
        id='local-executions-table'
        loading={isValidating}
        onChange={handleTableChange}
        columns={[...columns, actionColumn]}
        dataSource={items}
        onRow={(record) => {
          const isDownloaded = localExecutions?.some((i) => i.id === record.id)
          if (isDownloaded) {
            return {
              onClick: () => {
                history.push(
                  `/executions/${record.id}/${EXECUTION_ROUTER_MODE.RUNNING}`
                )
              }
            }
          } else if (record.status === EXECUTION_STATUS.SHARED) {
            return {
              onClick: () => {
                showJoinSharedExecutionModal(record.id)
              }
            }
          }
        }}
      />
    </>
  )
}

const Table = styled(BaseTable)`
  /* max-height: 50% !important; */
  margin-bottom: 30px;
`
