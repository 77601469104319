import { v4 as uuidv4 } from 'uuid'

import { uploadVideos } from '../../../../services/files'
import { store } from '../../../../store'
import { updateUpdatedAtExecution } from '../../../../store/execution/slice'
import {
  addMediaToExecutionCommentAction,
  createExecutionComment
} from '../../../../store/executionComments/actions'
import { setIsWorking } from '../../../../store/userInterface/slice'
import { uploadFilesAndSeparateVideos } from '../../../../utils/helpers/media-uploading'

export async function addComment(
  executionId,
  currentUser,
  newComment,
  media,
  commentType,
  isReviewMode
) {
  const { files, videosToUpload } = await uploadFilesAndSeparateVideos(media)

  const commentId = uuidv4().toUpperCase()

  const comment = {
    id: commentId,
    user: currentUser,
    comment: newComment,
    executionId,
    type: commentType,
    mediaIds: files.map((f) => f.id),
    createdAt: new Date().toString(),
    isReviewComment: isReviewMode
  }

  store.dispatch(setIsWorking(true))
  store.dispatch(createExecutionComment({ comment }))
  store.dispatch(updateUpdatedAtExecution({ updatedAt: new Date().valueOf() }))

  if (videosToUpload?.length) {
    const addMediaPayload = {
      commentId
    }

    uploadVideos(
      videosToUpload,
      commentId,
      undefined,
      undefined,
      undefined,
      executionId,
      addMediaPayload,
      addMediaToExecutionCommentAction,
      store.dispatch
    )
  }
}
