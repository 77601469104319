import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData } from '../../common/write'

export async function writeFilesContent(files) {
  const data = []

  for (const file of files) {
    data.push({
      id: file.id,
      data: { id: file.id, data: file.content }
    })
  }

  const tableName = TABLE_NAMES.FILES_CONTENT
  return _writeLocalDbData({
    tableName,
    data,
    action: 'PUT'
  })
}
