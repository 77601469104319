import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { loadFile } from '../../../../application/files/load'
import { getIsReview } from '../../../../store/execution/selectors'
import { getLinkToBlob } from '../../../../utils/helpers/files'
import { notificationMessage } from '../../../../utils/helpers/notification-message'
import ConfirmPopover from '../../../popover/confirm'
import Translation from '../../../translations'

const FileReference = ({ component, disabled }) => {
  const isReview = useSelector(getIsReview)
  const [visible, setVisible] = useState(false)

  const onConfirm = async () => {
    const loadedFile = await loadFile(component.options.fileId)

    if (loadedFile) {
      getLinkToBlob(loadedFile.originalName, loadedFile.file)
    } else {
      notificationMessage({
        message: 'Error',
        description: <Translation id='fileRefError' />
      })
    }
  }

  return (
    <ConfirmPopover
      onConfirm={() => onConfirm() && setVisible(false)}
      onCancel={() => setVisible(false)}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <a
        disabled={isReview || disabled}
        style={{ color: disabled ? '#4B98C6' : '#A709BC' }}
        onClick={() => setVisible(true)}
      >
        {component.options.text}
      </a>
    </ConfirmPopover>
  )
}

export default FileReference
