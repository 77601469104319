import { useContext } from 'react'

import LanguageContext from '../context/language'
import { getTranslation } from '../i18n/getTranslation'

export const useTranslation = (idOrIds, params) => {
  const { language: lang } = useContext(LanguageContext)

  if (Array.isArray(idOrIds)) {
    return idOrIds.map((id) => getTranslation(id, { lang, params }))
  }

  return getTranslation(idOrIds, { lang, params })
}
