import { axiosInstance } from '../../..'

export async function getProcedureExecutions(procedureId) {
  return axiosInstance.get('executions', { params: { procedureId } })
}

export async function getExecution(executionId, { downloading, merge }) {
  return axiosInstance.get('executions/' + executionId, {
    params: { downloading, merge }
  })
}
