import styled from 'styled-components'

const ConfigSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`

export default ConfigSection
