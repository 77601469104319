import { useMemo, useCallback } from 'react'

import { TABLE_NAMES } from '../../utils/constants/localdb'
import { isSynced } from '../../utils/helpers/sync-executions'
import useSyncStatus from '../use-sync-status'
import { useDexieTable } from '../useDexieTable'

import { useLocalProcedures } from './useLocalProcedures'

export const useLocalExecutions = ({
  disabled,
  withProcedure,
  key,
  qaStatus,
  series,
  status,
  title,
  unit,
  modifiedAfter,
  modifiedBefore,
  orderBy,
  orderDirection
} = {}) => {
  const findExecutions = useCallback(
    async (table) => {
      let _table = await table.with({ procedure: 'procedureId' })
      if (key && key.length) {
        _table = _table.filter((e) => {
          const regex = new RegExp(`.*${key}.*`, 'gi')
          return e.procedure.key.match(regex)
        })
      }
      if (title && title.length) {
        _table = _table?.filter((e) => {
          const regex = new RegExp(`.*${title}.*`, 'gi')
          return e.procedure.title.match(regex)
        })
      }
      if (qaStatus && qaStatus.length) {
        _table = _table?.filter((e) => qaStatus.includes(e.qaStatus))
      }
      if (series && series.length) {
        _table = _table?.filter((e) => series.includes(e.procedure.serieName))
      }
      if (status && status.length) {
        _table = _table?.filter((e) => status.includes(e.status))
      }
      if (unit && unit.length) {
        _table = _table?.filter((e) => unit.includes(e.procedure.unit))
      }
      if (modifiedAfter) {
        _table = _table?.filter((e) => e.updatedAt >= modifiedAfter.valueOf())
      }
      if (modifiedBefore) {
        _table = _table?.filter((e) => e.updatedAt <= modifiedBefore.valueOf())
      }
      if (orderBy) {
        if (orderDirection === 'ascend') {
          _table = _table.sortBy(orderBy)
        }

        if (orderDirection === 'descend') {
          _table = _table.reverse(orderBy)
        }
      }
      return _table
    },
    [
      key,
      modifiedAfter,
      modifiedBefore,
      orderBy,
      orderDirection,
      qaStatus,
      series,
      status,
      title,
      unit
    ]
  )

  let [executions, loadingExecutions] = useDexieTable({
    tableName: TABLE_NAMES.EXECUTIONS,
    findFunction: findExecutions,
    disabled
  })

  const { syncStatuses, loadingSyncStatuses } = useSyncStatus(
    executions,
    disabled
  )

  const args = useMemo(() => {
    return {
      disabled: loadingExecutions || disabled || !withProcedure,
      id: executions
        ? [...new Set(executions?.map((e) => e.procedureId))]
        : null
    }
  }, [disabled, executions, loadingExecutions, withProcedure])

  const [procedures, loadingProcedures] = useLocalProcedures(args)

  // Emparejamos cada ejecución con su procedimiento y determinamos si está sincronizada
  executions = executions?.map((execution) => ({
    ...execution,
    procedure: procedures?.find(
      (procedure) => procedure.id === execution.procedureId
    ),
    synced:
      syncStatuses.length === 0 ||
      isSynced(
        syncStatuses?.find((status) => status.executionId === execution.id)
      )
  }))

  return [
    executions,
    loadingExecutions || loadingProcedures || loadingSyncStatuses
  ]
}
