import { groupBy } from 'lodash'

import { putExecutionConditionals } from '../../../services/execution-conditionals/http/put'

export const sendExecutionConditionals = async (executionConditionals) => {
  const grouped = groupBy(executionConditionals, 'executionId')

  return Promise.all(
    Object.keys(grouped).map((executionId) =>
      putExecutionConditionals(executionId, grouped[executionId])
    )
  )
}
