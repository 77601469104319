import Dexie from 'dexie'
import { v4 as uuidv4 } from 'uuid'

import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { readAllExecutionSyncStatus, readExecutionSyncStatus } from '../read'


const tableName = TABLE_NAMES.EXECUTION_SYNC_STATUS

/**
 * Write to sync status table for the first time
 * @param {string} executionId
 * @returns Promise
 */
export const writeSyncStatus = (executionId) => {
  const db = getDatabase()

  const id = uuidv4().toUpperCase()

  const data = {
    id,
    executionId,
    [TABLE_NAMES.EXECUTION_COMMENTS]: 0,
    [TABLE_NAMES.EXECUTION_COMPONENTS]: 0,
    [TABLE_NAMES.EXECUTION_MANEUVERS]: 0,
    [TABLE_NAMES.EXECUTION_STEPS]: 0,
    [TABLE_NAMES.EXECUTION_WARNINGS]: 0,
    [TABLE_NAMES.EXECUTION_CONDITIONALS]: 0,
    [TABLE_NAMES.EXECUTIONS]: 0
  }

  return db[tableName].put(data)
}

const modifySyncStatus = (executionId, moduleName, data) => {
  const db = getDatabase()

  return db[tableName]
    .where('executionId')
    .equals(executionId)
    .modify({ [moduleName]: data })
}

/**
 * Transactional substraction to the actual sync status of the module with the number of synced data
 * @param {string} moduleName Module name which is being synced
 * @param {object} groupedData Grouped synced data by execution id
 */
export const substractSyncStatus = async (moduleName, groupedData) => {
  const db = getDatabase()

  return db.transaction('rw', db[tableName], async () => {
    await Dexie.Promise.all(
      Object.keys(groupedData).map(async (executionId) => {
        const result = await readExecutionSyncStatus(executionId)
        if (result) {
          await modifySyncStatus(
            executionId,
            moduleName,
            result[moduleName] - groupedData[executionId].length
          )
        }
      })
    )
  })
}

export const resetToZero = (moduleName) => {
  const db = getDatabase()

  return db.transaction('rw', db[tableName], async () => {
    const allExecutionSyncs = await readAllExecutionSyncStatus()

    await Dexie.Promise.all(
      allExecutionSyncs.map(({ id }) =>
        db[tableName]
          .where('id')
          .equals(id)
          .modify({
            [moduleName]: 0
          })
      )
    )
  })
}

/**
 * Transactional update the sync status of the module with the number of synced data. If not exists, create it
 * @param {string} moduleName Module name which is being synced
 * @param {object} groupedData Grouped data to sync by execution id
 */
export const updateSyncStatus = async (moduleName, groupedData) => {
  const db = getDatabase()

  if (Object.keys(groupedData).length === 0) {
    resetToZero(moduleName)
    return
  }

  return db.transaction('rw', db[tableName], async () => {
    await Dexie.Promise.all(
      Object.keys(groupedData).map(async (executionId) => {
        const result = await readExecutionSyncStatus(executionId)
        if (!result) {
          await writeSyncStatus(executionId)
          await modifySyncStatus(
            executionId,
            moduleName,
            groupedData[executionId].length
          )
        } else {
          await modifySyncStatus(
            executionId,
            moduleName,
            groupedData[executionId].length
          )
        }
      })
    )
  })
}
