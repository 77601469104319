// Aplicamos los estilos para el círculo raya a los elementos de un nivel inferior a los wrappers y div para los bookmars,  que posteriormente
// eliminamos al generar el informe.
// Así conservamos sus estilos y siguen dibujándose en el informe.
export function applyReadDoneStyles(content) {
  const spansCircle = content.querySelectorAll('.step-circle') || []
  Array.from(spansCircle).forEach((spanCircle) => {
    let parentBookmark = spanCircle.closest('[id^="_BMK"]')
    let firstElement = parentBookmark?.firstChild

    if (parentBookmark?.classList.contains('is-read')) {
      firstElement?.classList.add('stepParagraphs')
      spanCircle.parentNode.classList.add('is-read')
    }
  })

  const spansLine = content.querySelectorAll('.step-line') || []
  Array.from(spansLine).forEach((spanLine) => {
    let parentBookmark = spanLine.closest('[id^="_BMK"]')
    let firstElement = parentBookmark.firstChild
    if (parentBookmark.classList.contains('is-done')) {
      firstElement.classList.add('stepParagraphs')
      spanLine.parentNode.classList.add('is-done')
    }
  })
}
