export function checkDefaultElementInList({
  list,
  id,
  isChecked,
  handlerEvent
}) {
  // If it has already been checked, uncheck the unit that was previously
  // the default one (if any)

  if (!list || list.length <= 0) return
  // First all all -> uncheck the previous element set by default.
  if (isChecked) {
    const prevByDefault = list.find((element) => element.default === true)
    if (prevByDefault) {
      prevByDefault.default = false
      const showSuccessMessage = false
      handlerEvent(prevByDefault, showSuccessMessage)
    }
    const currentDefault = list.find((element) => element.id === id)
    if (currentDefault) {
      currentDefault.default = true
      const showSuccessMessage = true
      handlerEvent(currentDefault, showSuccessMessage)
    }
  } else {
    const localUnit = list.filter((localUnit) => localUnit.id === id)
    if (localUnit?.length > 0) {
      localUnit[0].default = false
      handlerEvent(localUnit[0])
    }
  }
}
