import React from 'react'
import { useDispatch, useSelector } from 'react-redux'


import useMediaUploadingModal from '../../../../hooks/use-media-uploading-modal'
import { changeCurrentStep } from '../../../../store/execution/actions'
import { getIsWorking } from '../../../../store/userInterface/selectors'
import { setExecutionJumpsWithReturnByExecutionId } from '../../../../store/userInterface/slice'
import MediaUploadingModal from '../../../modal/media-uploading-modal'

import JumpItem from './item'

const Jumps = ({ onClose, currentJumps, executionId, onSelectStep }) => {
  const [open, setOpen, shouldOpenMediaUploadingModal] =
    useMediaUploadingModal()
  const dispatch = useDispatch()

  const isWorking = useSelector(getIsWorking)

  const onJumpClick = (jump) => {
    if (!jump) return

    const jumps = currentJumps.filter(
      (j) => j.paragraphNumber !== jump.paragraphNumber
    )

    dispatch(
      setExecutionJumpsWithReturnByExecutionId({
        executionId,
        jumps
      })
    )

    dispatch(changeCurrentStep(jump.paragraphNumber))
    onSelectStep([jump.paragraphNumber])

    onClose()
  }

  return (
    <>
      {currentJumps.map((jump, index) => (
        <div key={index}>
          <JumpItem
            id={jump.paragraphNumber}
            text={jump.text}
            handleClick={() => {
              if (isWorking) return
              if (shouldOpenMediaUploadingModal()) {
                return
              }
              onJumpClick(jump)
            }}
            onClose={onClose}
            disabled={isWorking}
          />
        </div>
      ))}
      <MediaUploadingModal
        open={open}
        setOpen={setOpen}
        navigate={onJumpClick}
      />
    </>
  )
}

export default Jumps
