import isEqual from 'lodash.isequal'
import React from 'react'

import { createStepPortal } from '../../../../utils/helpers/dom-handlers'
import StepContent from '../step-content'

const ProcedureStepPortal = React.memo(
  (props) => {
    const { stepId } = props.procedureStep
    return createStepPortal(<StepContent {...props} />, stepId)
  },
  (oldProps, newProps) => {
    return (
      oldProps.procedureStep.visible === newProps.procedureStep.visible &&
      isEqual(
        removePropsThatWontChange(oldProps),
        removePropsThatWontChange(newProps)
      )
    )
  }
)

function removePropsThatWontChange(propsObj) {
  const {
    procedureStep,
    procedure,
    isManeuverParent,
    maneuverIndex,
    ...changingProps
  } = propsObj

  return changingProps
}

export default ProcedureStepPortal
