import React, { useContext } from 'react'
import styled from 'styled-components'

import FilterTags from '../../../../views/filters/filter-tags'
import Translation from '../../../../views/translations'


import { ExecutionHistoryFiltersContext } from '.'

export const ExecutionHistoryFilterTags = () => {
  const { filters, updateFilters, resetFilters } = useContext(
    ExecutionHistoryFiltersContext
  )

  return (
    <StyledFilterTags
      value={filters}
      onChange={(filters) => {
        updateFilters(filters)
      }}
      onClear={resetFilters}
      getDisplayName={(key) => {
        return <Translation id={key} />
      }}
      getDisplayValue={(key, value) => {
        const values = {
          status: () => <Translation id={value} />,
          qaStatus: () => <Translation id={value} />,
          modifiedAfter: () => value.format('DD/MM/YYYY'),
          modifiedBefore: () => value.format('DD/MM/YYYY'),
          user: () => value.name
        }

        return values[key]?.() || value
      }}
    />
  )
}

const StyledFilterTags = styled(FilterTags)`
  margin-top: 1rem;
`
