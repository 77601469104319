import React from 'react'
import styled from 'styled-components'

import { UNIT_COLORS } from '../../../utils/constants/config'

const UnitTag = ({ unit, ...props }) => {
  return (
    <Tag data-color={UNIT_COLORS[unit]} {...props}>
      {unit}
    </Tag>
  )
}

const Tag = styled.span`
  display: inline-block;
  background-color: ${(props) => props['data-color']};
  border-radius: 4px;
  height: 40px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default UnitTag
