import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useGlobal } from 'reactn'
import styled from 'styled-components'

import Exit from '../../../components/icons/exit'
import LockReload from '../../../components/icons/lock-reload'
import Shield from '../../../components/icons/shield'
import { getUser } from '../../../store/user-management/selectors'
import {
  getConnectionStatus,
  getIsWorking
} from '../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import { getInitials, getNameFromEmail } from '../../../utils/helpers/user'
import PopoverMenu from '../../menus/popover'
import ChangePasswordModal from '../../modal/change-password-modal'
import LogoutModal from '../../modal/logout-modal'
import SignatureCodeGeneratorModal from '../../modal/signature-code-generator-modal'
import UserInfo from '../../users/info'
import VersionInfo from '../../version'
import HeaderButton from '../header-button'

const ProfileButton = ({ disabled }) => {
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false)
  const [codeGeneratorModalVisible, setCodeGeneratorModalVisible] =
    useState(false)
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] =
    useState(false)

  const isWorking = useSelector(getIsWorking)
  const connectionStatus = useSelector(getConnectionStatus)
  const currentUser = useSelector(getUser)
  const isOnline = connectionStatus === CONNECTION_STATUS.ONLINE

  return (
    <>
      <SignatureCodeGeneratorModal
        visible={codeGeneratorModalVisible}
        setVisible={setCodeGeneratorModalVisible}
        onClose={() => setCodeGeneratorModalVisible(false)}
      />

      {isLogoutModalVisible && (
        <LogoutModal
          visible={isLogoutModalVisible}
          setVisible={setIsLogoutModalVisible}
          onCancel={() => setIsLogoutModalVisible(false)}
        />
      )}

      <ChangePasswordModal
        visible={isChangePasswordModalVisible}
        setVisible={setIsChangePasswordModalVisible}
      />

      <PopoverMenu
        footer={<VersionInfo />}
        items={[
          {
            content: <UserInfo />
          },
          {
            icon: Shield,
            translationId: 'codeGenerator',
            onClick: () => setCodeGeneratorModalVisible(true),
            disabled: isWorking
          },
          {
            icon: LockReload,
            translationId: 'changePassword',
            onClick: () => setIsChangePasswordModalVisible(true),
            disabled: !isOnline || isWorking
          },
          {
            icon: Exit,
            translationId: 'logout',
            onClick: () => setIsLogoutModalVisible(true),
            disabled: isWorking
          }
        ]}
        trigger='click'
        placement='bottomRight'
        overlayClassName='profile-popover'
        arrowPointAtCenter={true}
      >
        <MainProfileButton id='profile-button' disabledIcon={disabled}>
          <Initials color='#ffffff'>
            {currentUser.initials
              ? currentUser.initials
              : getInitials(getNameFromEmail(currentUser.email))}
          </Initials>
        </MainProfileButton>
      </PopoverMenu>
    </>
  )
}

const MainProfileButton = styled(HeaderButton)`
  min-width: 64px;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  background: #645377;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
  margin-right: 0;
  font-size: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus,
  &:active {
    background: #645377;
    color: #fff;
  }
`

const Initials = styled.span`
  font-size: x-large;
  color: ${({ color }) => `${color};`};
`

export default ProfileButton
