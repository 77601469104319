import React from 'react'

import Translation from '../../translations'

const DownloadNotificationContent = ({
  execution,
  procedure,
  referencedProcedures,
  failedFiles
}) => {
  return (
    <span>
      {execution ? (
        <p>
          <Translation id='downloadedExecution' />
        </p>
      ) : (
        ''
      )}
      {procedure ? (
        <>
          <p>
            <Translation id='downloadedProcedure' />
          </p>
          <p>
            {procedure?.key} rev {procedure?.rev}
          </p>
        </>
      ) : (
        ''
      )}
      {referencedProcedures && referencedProcedures.length > 0 && (
        <span>
          <p>
            <Translation id='downloadedReferencedProcedures' />
          </p>
          <ul>
            {referencedProcedures.map((rp) => {
              return (
                <li key={rp.id}>
                  {rp.key} rev {rp.rev}
                </li>
              )
            })}
          </ul>
        </span>
      )}
      {failedFiles && (
        <span>
          <p style={{ color: 'red' }}>
            <Translation id='downloadReferencedFilesFailed' />
          </p>
        </span>
      )}
    </span>
  )
}

export default DownloadNotificationContent
