import { _readLocalDbData } from '../../../localdb/common/read'
import { _writeLocalDbData } from '../../../localdb/common/write'
import { getUsersRaw } from '../../../services/user'
import { requestCodes } from '../../../services/user/codes/http/get'
import { store } from '../../../store'
import { getUser } from '../../../store/user-management/selectors'
import { setUser, setUsers } from '../../../store/user-management/slice'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { setUserLocalStorageItem } from '../../../utils/helpers/user'

const tableName = TABLE_NAMES.USERS

export async function syncUsers() {
  const user = getUser(store.getState())

  const { data: users } = await getUsersRaw()

  if (users && users.length) {
    await _writeLocalDbData({
      tableName,
      data: users,
      action: 'PUT'
    })
  }

  const allUsers = await _readLocalDbData({ tableName })
  store.dispatch(setUsers(allUsers.data))
  store.dispatch(
    setUser({ ...user, ...users.find((_user) => _user.id === user.id) })
  )
}

export async function syncUserCodes() {
  const codes = await requestCodes()

  setUserLocalStorageItem('codes', codes)
}
