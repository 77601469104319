export function scrollProcedureTo(element) {
  if (!element) return
  if (!element.hasChildNodes()) {
    document.querySelector('body').style.cursor = 'wait'
  }

  let headersOffset = 10 // initialize the offset with a 10px margin-top

  // Finding the headers height to set an offset from the top
  document
    .querySelectorAll('header')
    .forEach((element) => (headersOffset += element.offsetHeight))

  const elParent = document.getElementById('procedure-scroll')
  elParent.scroll(0, element.offsetTop - headersOffset)

  document.querySelector('body').style.cursor = 'auto'
}
