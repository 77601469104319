import React, { useState } from 'react'

import LanguageContext from '..'
import { UI_LANGUAGE } from '../../../utils/constants/config'

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(UI_LANGUAGE)

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
