import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { changeManeuverState } from '../../../../../../store/executionManeuvers/actions'
import Translation from '../../../../../translations'
import StepMenuButton from '../../button'


const ManeuverButton = ({
  maneuverId,
  visible,
  isManeuverActive,
  isExecutionRunning
}) => {
  const dispatch = useDispatch()
  if (!visible) {
    return null
  }

  const onClick = () => {
    dispatch(changeManeuverState({ maneuverId, active: !isManeuverActive }))
  }

  return (
    <StyledManeuverButton
      id={
        isManeuverActive ? 'cancel-maneuver-button' : 'perform-maneuver-button'
      }
      className={isManeuverActive ? 'maneuver-active' : ''}
      onClick={onClick}
      disabled={!isExecutionRunning}
    >
      <Translation
        id={isManeuverActive ? 'cancelManeuver' : 'performingManeuver'}
      />
    </StyledManeuverButton>
  )
}

const StyledManeuverButton = styled(StepMenuButton)`
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: initial;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #f12e2e;

  &:hover {
    background-color: #fff;
    color: #f12e2e;
    border-color: #d8d8d8;
  }

  &.maneuver-active {
    background-color: #272928;
    border: none;
    color: #fff;
  }

  &.maneuver-active:hover {
    color: #fff;
  }
`

export default ManeuverButton
