import React from 'react'

const ExecutionManagementIcon = ({ size = '24', color = 'currentColor' }) => (
    <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_7096_104557)'>
      <path
        d='M8.25 23.2617H2.25C1.85218 23.2617 1.47064 23.1037 1.18934 22.8224C0.908035 22.5411 0.75 22.1595 0.75 21.7617V2.26172C0.75 1.86389 0.908035 1.48236 1.18934 1.20106C1.47064 0.919754 1.85218 0.761719 2.25 0.761719H12.88C13.2777 0.762069 13.659 0.920334 13.94 1.20172L19.81 7.07172C19.9637 7.22744 20.0824 7.41411 20.1582 7.6193C20.2341 7.82449 20.2654 8.04349 20.25 8.26172'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.75 0.761719V6.76172C12.75 7.15954 12.908 7.54107 13.1893 7.82238C13.4706 8.10368 13.8522 8.26172 14.25 8.26172H20.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.17 9.62109L6 12.4611C5.93404 12.5464 5.85111 12.6171 5.75644 12.6687C5.66178 12.7204 5.55743 12.7518 5.45 12.7611C5.34335 12.7666 5.23672 12.75 5.13687 12.7121C5.03702 12.6742 4.94614 12.616 4.87 12.5411L3.75 11.3811'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.17 16.3711L6 19.2111C5.93404 19.2964 5.85111 19.3671 5.75644 19.4187C5.66178 19.4704 5.55743 19.5018 5.45 19.5111C5.34335 19.5166 5.23672 19.5 5.13687 19.4621C5.03702 19.4242 4.94614 19.366 4.87 19.2911L3.75 18.1311'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.999 18.25C17.8274 18.25 18.499 17.5785 18.499 16.75C18.499 15.9216 17.8274 15.25 16.999 15.25C16.1706 15.25 15.499 15.9216 15.499 16.75C15.499 17.5785 16.1706 18.25 16.999 18.25Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.2729 10.9487L18.7149 12.4017C18.7879 12.6428 18.9497 12.8472 19.1676 12.9736C19.3856 13.1001 19.6434 13.1391 19.8889 13.0827L21.3609 12.7417C21.6399 12.6788 21.9317 12.7069 22.1936 12.8219C22.4555 12.9369 22.6736 13.1327 22.8162 13.3806C22.9587 13.6286 23.0181 13.9155 22.9857 14.1997C22.9532 14.4838 22.8307 14.7502 22.6359 14.9597L21.6049 16.0707C21.4329 16.2557 21.3372 16.4989 21.3372 16.7516C21.3372 17.0043 21.4329 17.2476 21.6049 17.4326L22.6359 18.5407C22.8307 18.7501 22.9532 19.0164 22.9857 19.3006C23.0181 19.5847 22.9587 19.8718 22.8162 20.1198C22.6736 20.3677 22.4555 20.5634 22.1936 20.6784C21.9317 20.7934 21.6399 20.8215 21.3609 20.7586L19.8889 20.4207C19.6434 20.3643 19.3856 20.4032 19.1676 20.5296C18.9497 20.6561 18.7879 20.8605 18.7149 21.1017L18.2729 22.5547C18.1908 22.8287 18.0225 23.0689 17.793 23.2397C17.5635 23.4105 17.285 23.5028 16.9989 23.5028C16.7128 23.5028 16.4344 23.4105 16.2049 23.2397C15.9754 23.0689 15.8071 22.8287 15.7249 22.5547L15.2829 21.1017C15.21 20.8605 15.0482 20.6561 14.8303 20.5296C14.6123 20.4032 14.3545 20.3643 14.1089 20.4207L12.6369 20.7617C12.3574 20.8255 12.0647 20.7978 11.802 20.6828C11.5393 20.5678 11.3205 20.3716 11.1777 20.1229C11.035 19.8742 10.9758 19.5861 11.009 19.3013C11.0421 19.0165 11.1659 18.7499 11.3619 18.5407L12.3929 17.4297C12.565 17.2447 12.6606 17.0013 12.6606 16.7486C12.6606 16.4959 12.565 16.2527 12.3929 16.0677L11.3619 14.9567C11.1681 14.7471 11.0463 14.481 11.0143 14.1973C10.9824 13.9136 11.0419 13.6271 11.1843 13.3797C11.3267 13.1322 11.5444 12.9367 11.8057 12.8218C12.0671 12.7068 12.3583 12.6784 12.6369 12.7407L14.1089 13.0817C14.3545 13.1381 14.6123 13.0992 14.8303 12.9727C15.0482 12.8463 15.21 12.6418 15.2829 12.4007L15.7249 10.9477C15.8072 10.6737 15.9756 10.4335 16.2052 10.2628C16.4347 10.092 16.7132 9.99989 16.9993 10C17.2854 10.0001 17.5638 10.0925 17.7932 10.2634C18.0227 10.4343 18.1909 10.6746 18.2729 10.9487Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_7096_104557'>
        <rect width={size} height={size} fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default ExecutionManagementIcon
