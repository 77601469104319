import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

import CloseIcon from '../../../../components/icons/close-icon'
import ExpandIcon from '../../../../components/icons/expand'
import ShrinkIcon from '../../../../components/icons/shrink'

const HistoricalDrawerExtra = ({ expanded, closeDrawer, toggleExpanded }) => (
  <Container>
    <ExtraButton onClick={toggleExpanded}>
      {expanded ? <ShrinkIcon size={16} /> : <ExpandIcon size={16} />}
    </ExtraButton>
    <ExtraButton onClick={closeDrawer}>
      <CloseIcon size={16} />
    </ExtraButton>
  </Container>
)

const Container = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`

const ExtraButton = styled(Button)`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 2px;
  background: #fff;
`

export default HistoricalDrawerExtra
