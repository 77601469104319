import React from 'react'

const ProcedureEditIcon = ({ size = '24', color = 'currentColor' }) => (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.25 23.2617H2.25C1.85218 23.2617 1.47064 23.1037 1.18934 22.8224C0.908035 22.5411 0.75 22.1595 0.75 21.7617V2.26172C0.75 1.86389 0.908035 1.48236 1.18934 1.20106C1.47064 0.919754 1.85218 0.761719 2.25 0.761719H12.88C13.2777 0.762069 13.659 0.920334 13.94 1.20172L19.81 7.07172C19.9637 7.22744 20.0824 7.41411 20.1582 7.6193C20.2341 7.82449 20.2654 8.04349 20.25 8.26172'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.75 0.761719V6.76172C12.75 7.15954 12.908 7.54107 13.1893 7.82238C13.4706 8.10368 13.8522 8.26172 14.25 8.26172H20.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.17 9.62109L6 12.4611C5.93404 12.5464 5.85111 12.6171 5.75644 12.6687C5.66178 12.7204 5.55743 12.7518 5.45 12.7611C5.34335 12.7666 5.23672 12.75 5.13687 12.7121C5.03702 12.6742 4.94614 12.616 4.87 12.5411L3.75 11.3811'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.17 16.3711L6 19.2111C5.93404 19.2964 5.85111 19.3671 5.75644 19.4187C5.66178 19.4704 5.55743 19.5018 5.45 19.5111C5.34335 19.5166 5.23672 19.5 5.13687 19.4621C5.03702 19.4242 4.94614 19.366 4.87 19.2911L3.75 18.1311'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 12.7617H15'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.63 14.88L15 22.51L11.25 23.26L12 19.51L19.63 11.88C19.8262 11.6816 20.0598 11.5242 20.3173 11.4167C20.5748 11.3092 20.851 11.2539 21.13 11.2539C21.409 11.2539 21.6852 11.3092 21.9427 11.4167C22.2002 11.5242 22.4338 11.6816 22.63 11.88C22.8283 12.0762 22.9858 12.3098 23.0933 12.5673C23.2007 12.8247 23.2561 13.101 23.2561 13.38C23.2561 13.659 23.2007 13.9352 23.0933 14.1927C22.9858 14.4501 22.8283 14.6837 22.63 14.88Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
)

export default ProcedureEditIcon
