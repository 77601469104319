import moment from 'moment'

import { store } from '../../../../store'
import { changeCurrentStep } from '../../../../store/execution/actions'
import { EXECUTION_COMPONENT_TYPES } from '../../../constants/execution/components'
import { getComponentStep, sortBookmarks } from '../../steps'


export function getSignatures({
  procedure,
  executionId,
  signatureStatus,
  closeDrawer,
  executionComponents,
  onSelectStep
}) {
  if (!executionId || !executionComponents) {
    return null
  }

  return procedure.components
    .filter((pc) => {
      if (pc.type !== EXECUTION_COMPONENT_TYPES.SIGNATURE) {
        return false
      }

      const localComponent = executionComponents.find(
        (c) => c.procedureComponentId === pc.id && c.executionId === executionId
      )

      return localComponent && localComponent.value?.status === signatureStatus
    })
    .map((signature) => {
      const node = getComponentStep(
        procedure.index,
        signature.stepID || signature.stepId
      )
      if (!node) return null

      return {
        id: node.id,
        stepIndex: node.stepIndex,
        title: node.title,
        handleClick: () => {
          closeDrawer()
          store.dispatch(changeCurrentStep(node.id))
          onSelectStep([node.id])
        }
      }
    })
    .sort((signA, signB) => sortBookmarks(signA.id, signB.id))
}

/**
 * Format a component value
 * @param {value} string
 * @param {type} string
 * @param {dateFormat} string
 * @param {timeFormat} string
 * @returns {string} Value formatted
 */
export function formatComponentValue({ value, type, dateFormat, timeFormat }) {
  if (!value) return null

  switch (type) {
    case EXECUTION_COMPONENT_TYPES.DATE:
      return moment(Number(value)).format(dateFormat ?? 'MM/DD/YYYY')
    case EXECUTION_COMPONENT_TYPES.TIME:
      return moment(Number(value)).format(timeFormat ?? 'HH:mm A')
    default:
      return value.replace(/"/g, '')
  }
}

// Check if there are incorrect signatures
export const hasIncorrectSignatures = (
  components = [],
  procedureComponents = []
) => {
  if (!components.length || !procedureComponents.length) {
    return false
  }
  const procedureSignatureComponents = procedureComponents.filter(
    (component) => component.type === EXECUTION_COMPONENT_TYPES.SIGNATURE
  )

  const incorrectSignatures = components.filter((component) =>
    procedureSignatureComponents.some(
      (procComp) =>
        procComp.id === component.procedureComponentId &&
        component.value?.isCorrect === false
    )
  )
  return !!incorrectSignatures.length
}
