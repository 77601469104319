import styled from 'styled-components'

const UploadProgress = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  padding: 8px;

  width: 100%;
  height: 60px;

  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #b8bab6;
`

export default UploadProgress
