// README: al añadir una variable que venga del .env asegurarse de que se transforma al tipo de dato correcto.

const convertToBoolean = (data) => data === 'true'
const convertToInt = parseInt

// Connection
export const BACKEND_URL =
  window.settings?.BACKEND_URL || process.env.BACKEND_URL

export const WS_URL = window.settings?.WS_URL || process.env.WS_URL

// General settings
export const UNIT_COLORS = (
  window.settings?.UNIT_COLORS ||
  process.env.UNIT_COLORS ||
  ''
)
  .split(';')
  .reduce((acc, curr) => {
    const [key, value] = curr.split(':')
    acc[key] = value
    return acc
  }, {})

export const FULL_SCREEN =
  window.settings?.FULL_SCREEN ?? convertToBoolean(process.env.FULL_SCREEN)

export const RENEW_TOKEN_INTERVAL =
  convertToInt(process.env.RENEW_TOKEN_INTERVAL) || 2400000

export const RECONNECT_INTERVAL =
  window.settings?.RECONNECT_INTERVAL ||
  convertToInt(process.env.RECONNECT_INTERVAL) ||
  10000

export const ANIMATED_LOGO =
  window.settings?.ANIMATED_LOGO ?? convertToBoolean(process.env.ANIMATED_LOGO)

export const DOUBLE_CLICK_TO_COMPLETE_STEP =
  window.settings?.DOUBLE_CLICK_TO_COMPLETE_STEP ??
  convertToBoolean(process.env.DOUBLE_CLICK_TO_COMPLETE_STEP)

export const SIGNATURE_HOUR_VISIBLE =
  window.settings?.SIGNATURE_HOUR_VISIBLE ??
  convertToBoolean(process.env.SIGNATURE_HOUR_VISIBLE)

export const UI_LANGUAGE =
  window.settings?.UI_LANGUAGE || process.env.UI_LANGUAGE

export const VIEW_PAUSE_BUTTON =
  window.settings?.VIEW_PAUSE_BUTTON ??
  convertToBoolean(process.env.VIEW_PAUSE_BUTTON)

export const USE_PRINCE =
  window.settings?.USE_PRINCE ?? convertToBoolean(process.env.USE_PRINCE)
// Tests
export const TEST_URL = process.env.TEST_URL
export const TEST_HEADLESS = convertToBoolean(process.env.TEST_HEADLESS)
export const CHROME_PATH_FOR_TESTING = process.env.CHROME_PATH_FOR_TESTING

export const TEST_ADMIN = process.env.TEST_ADMIN
export const TEST_ADMIN_PASSWORD = process.env.TEST_ADMIN_PASSWORD

export const TEST_SHIFT_MANAGER = process.env.TEST_SHIFT_MANAGER
export const TEST_SHIFT_MANAGER_PASSWORD =
  process.env.TEST_SHIFT_MANAGER_PASSWORD

export const TEST_SUPERVISOR = process.env.TEST_SUPERVISOR
export const TEST_SUPERVISOR_PASSWORD = process.env.TEST_SUPERVISOR_PASSWORD

export const TEST_PLANT_OPERATOR = process.env.TEST_PLANT_OPERATOR
export const TEST_PLANT_OPERATOR_PASSWORD =
  process.env.TEST_PLANT_OPERATOR_PASSWORD

export const TEST_ID_PRU_AUTO_01 = process.env.TEST_ID_PRU_AUTO_01
export const TEST_KEY_PRU_AUTO_01 = process.env.TEST_KEY_PRU_AUTO_01
export const TEST_TITLE_PRU_AUTO_01 = process.env.TEST_TITLE_PRU_AUTO_01

export const TEST_ID_PRU_AUTO_03 = process.env.TEST_ID_PRU_AUTO_03
export const TEST_KEY_PRU_AUTO_03 = process.env.TEST_KEY_PRU_AUTO_03
export const TEST_TITLE_PRU_AUTO_03 = process.env.TEST_TITLE_PRU_AUTO_03

export const STORED_PROCEDURES = convertToInt(process.env.STORED_PROCEDURES)

// Files size limit
// By default 50MB
export const MAX_FILE_SIZE = convertToInt(
  window.settings?.MAX_FILE_SIZE ?? process.env.MAX_FILE_SIZE ?? 50000000
)

export const PLATFORM = process.env.PLATFORM

// Logger
export const LOG_GLOBAL_STATE_CHANGES = convertToBoolean(
  process.env.LOG_GLOBAL_STATE_CHANGES
)
export const LOG_LEVEL = process.env.LOG_LEVEL
export const LOG_CONNECTION_CHECK_REQUESTS = convertToInt(
  process.env.LOG_CONNECTION_CHECK_REQUESTS
)

export const SHOULD_CHECK_COMPLIANCIES =
  window.settings?.REQUIRED_NON_COMPLIANCIES_COMMENT ||
  convertToBoolean(process.env.REQUIRED_NON_COMPLIANCIES_COMMENT)

export const PAGE_SIZE_SCALE_FACTOR = Number(
  window.settings?.PAGE_SIZE_SCALE_FACTOR ||
    process.env.PAGE_SIZE_SCALE_FACTOR ||
    1
)

export const FAIR_QUALITY = process.env.FAIR_QUALITY
export const POOR_QUALITY = process.env.POOR_QUALITY
export const BAD_QUALITY = process.env.BAD_QUALITY
export const POINT_TIMED_OUT = process.env.POINT_TIMED_OUT
export const POINT_OFF_SCAN = process.env.POINT_OFF_SCAN
export const POINT_IN_ALARM = process.env.POINT_IN_ALARM

// time out before allowing accept a modal
export const mandatoryReadingTimeOut = 1000

// time out before wipe the readout rt value
export const READOUT_VALIDITY_TIME = process.env.READOUT_VALIDITY_TIME
