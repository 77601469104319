import React from 'react'
import styled from 'styled-components'

const StepArrowContainer = ({ children, disabled, onClick, ...props }) => (
  <StyledDiv
    data-disabled={disabled}
    onClick={disabled ? null : onClick}
    {...props}
  >
    {children}
  </StyledDiv>
)

const StyledDiv = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  padding: 8px;
  background-color: ${({ 'data-disabled': disabled }) =>
    disabled ? '#B8BAB6' : '#645377'};
  cursor: ${({ 'data-disabled': disabled }) =>
    disabled ? 'initial' : 'pointer'}; ;
`

export default StepArrowContainer
