import { Select, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { downloadFiles } from '../../../../../application/files/download'
import AddComment from '../../../../../components/buttons/add-comment'
import ActionsContainer from '../../../../../containers/add-comment/actions'
import ButtonContainer from '../../../../../containers/add-comment/button'
import AttachmentFilesWrapper from '../../../../../containers/attachments'
import CustomComment from '../../../../../containers/comment'
import CommentAuthor from '../../../../../containers/comment/author'
import CommentListContainer from '../../../../../containers/comment/list'
import SendArea from '../../../../../containers/comment/send-area'
import { ScrollListContainer } from '../../../../../containers/execution-view/scroll-list'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { getTranslation } from '../../../../../i18n/getTranslation'
import { getAllowedInsertComment } from '../../../../../store/execution/selectors'
import { getMediaUploadingExecutionComment } from '../../../../../store/media-uploading/selectors'
import { getConnectionStatus } from '../../../../../store/userInterface/selectors'
import { COMMENT_TO_PROCEDURE_TYPES } from '../../../../../utils/constants/comment'
import { MAX_FILE_SIZE } from '../../../../../utils/constants/config'
import { CONNECTION_STATUS } from '../../../../../utils/constants/connection'
import { ALLOWED_VIDEO_EXTENSIONS } from '../../../../../utils/constants/files'
import { getLinkToPDF } from '../../../../../utils/helpers/files'
import {
  addHashToFiles,
  areFilesValid
} from '../../../../../utils/helpers/files'
import { bytesToMegabytes } from '../../../../../utils/helpers/formulas'
import { notificationMessage } from '../../../../../utils/helpers/notification-message'
import AttachmentButton from '../../../../buttons/attachment'
import CommentActions from '../../../../comments/actions'
import CommentArea from '../../../../comments/area'
import CommentContent from '../../../../comments/content'
import ListItem from '../../../../execution-view/list/item'
import ScrollList from '../../../../execution-view/list/scroll'
import AttachmentFile from '../../../../files/attachment'
import AttachmentIconView from '../../../../icons/attachment'
import AttachmentModal from '../../../../modal/attachment-modal'
import ConfirmModal from '../../../../modal/confirm-modal'
import InfoModal from '../../../../modal/info-modal'
import Translation from '../../../../translations'

const { Option } = Select

const ExecutionComments = ({
  comments,
  addComment,
  deleteComment,
  editComment,
  executionId,
  deleteMediaFromComment
}) => {
  // const isView = useSelector(getIsView) // Cuando la ejecución se ha finalizado y la abrimos desde local, execution.mode está a indefinido, y isView a false, por lo
  // que deja poner comentarios.  Lo suyo sería definir siempre execution.mode y que al abortar o finalizar una ejecución se pusiese a 'view'

  const [textAreaValue, setTextAreaValue] = useState(null)
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [fileTooLarge, setFileTooLarge] = useState(null)
  const [mediaToUpload, setMediaToUpload] = useState([])
  const [mediaHashes, setMediaHashes] = useState([])
  const [commentsMedia, setCommentsMedia] = useState()
  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false)
  const [attachmentModalMedia, setAttachmentModalMedia] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [commentType, setCommentType] = useState(null)
  const [commentEditing, setCommentEditing] = useState(null)

  const connectionStatus = useSelector(getConnectionStatus)

  const dateTimeFormat = useTranslation('dateTimeFormat')
  const mediaUploading = useSelector(getMediaUploadingExecutionComment)
  const allowInsertComment = useSelector(getAllowedInsertComment)

  useEffect(() => {
    const loadCommentsMedia = async () => {
      const commentsMediaIds = comments
        .filter((c) => c.mediaIds && !c.deletedAt)
        .map((c) => c.mediaIds)
        .flat()

      if (commentsMediaIds.length) {
        const media = await downloadFiles(commentsMediaIds)
        setCommentsMedia(media)
      }
    }

    loadCommentsMedia()
  }, [comments])

  const onChangeMedia = async (e) => {
    let inputFiles = e?.target?.files

    if (inputFiles) {
      if (!areFilesValid(inputFiles, commentType.allowedFileExt)) {
        notificationMessage({
          type: 'error',
          message: getTranslation('error'),
          description: getTranslation('onlyPDF')
        })

        return null
      }

      const fileTooLarge = Array.from(inputFiles).find(
        (file) => file.size > MAX_FILE_SIZE
      )

      if (fileTooLarge) {
        setOpenInfoModal(true)
        setFileTooLarge(fileTooLarge)
        return
      }

      /**
       * We transform the incoming files to an array of objects containing the file and the hash
       * in order to give an unique ID to the file and avoid duplicated attachments.
       */
      const hashedFiles = await addHashToFiles(inputFiles)

      hashedFiles.forEach((hashedFile) => {
        if (mediaHashes.length && mediaHashes.includes(hashedFile.hash)) {
          notificationMessage({
            type: 'error',
            message: getTranslation('error'),
            description: getTranslation('attachmentRepeated')
          })
          return
        } else {
          setMediaHashes((prevHashes) => [...prevHashes, hashedFile.hash])
          setMediaToUpload((prevMedia) => [...prevMedia, hashedFile])
        }
      })
    }
  }

  const openModalMedia = (media) => {
    setAttachmentModalMedia(media)
    if (media.extension === '.pdf' && isMobile) {
      setOpenConfirmModal(true)
    } else {
      setAttachmentModalVisible(true)
    }
  }

  function canInsertComment() {
    if (!commentType) {
      return false
    }

    if (
      commentType.id === COMMENT_TO_PROCEDURE_TYPES.ATTACH_TO_BEGINNING.id ||
      commentType.id === COMMENT_TO_PROCEDURE_TYPES.ATTACH_TO_END.id
    ) {
      return mediaToUpload.length > 0 ? true : false
    }
    return true
  }

  const handleChangeCommentType = (value) => {
    const commentToProcedureType = COMMENT_TO_PROCEDURE_TYPES[value]

    if (
      connectionStatus === CONNECTION_STATUS.ONLINE &&
      value === 'CHANGE_PROPOSAL'
    ) {
      setCommentType({
        ...commentToProcedureType,
        allowedFileExt: [
          ...commentToProcedureType.allowedFileExt,
          ...ALLOWED_VIDEO_EXTENSIONS.map((ext) => ext.slice(1))
        ]
      })
    } else {
      setCommentType(commentToProcedureType)
    }
  }

  const handleAddComment = () => {
    if (canInsertComment()) {
      if (commentEditing) {
        editComment({
          ...commentEditing,
          comment: textAreaValue
        })
      } else {
        // Only sending the File type objects to the BE, removing hashes.
        const transformedMediaToUpload = mediaToUpload.map(({ file }) => file)
        addComment(textAreaValue, transformedMediaToUpload, commentType.id)
      }
      setCommentType(null)
    } else {
      notificationMessage({
        type: 'warning',
        message: getTranslation('invalidAttachment'),
        description: getTranslation('attachmentNotFound')
      })
    }

    setTextAreaValue(null)
    setCommentEditing(null)
    setMediaToUpload([])
    setMediaHashes([])
  }

  const handleRemoveUploadMedia = (mediaHashToDelete) => {
    setMediaToUpload((prevMedia) =>
      prevMedia.filter((media) => media.hash !== mediaHashToDelete)
    )
    setMediaHashes((prevHashes) =>
      prevHashes.filter((prevHash) => prevHash !== mediaHashToDelete)
    )
  }

  return (
    <>
      <ConfirmModal
        id='confirm-tab-pdf'
        okButtonTextId='confirm'
        titleId='media.pdf.tab.title'
        textId='media.pdf.tab.text'
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        onOk={() => {
          getLinkToPDF(attachmentModalMedia.file)
          setOpenConfirmModal(false)
        }}
      />
      <AttachmentModal
        isModalVisible={attachmentModalVisible}
        setIsModalVisible={setAttachmentModalVisible}
        attachment={attachmentModalMedia}
      />
      <CommentListContainer id='execution-comments-panel-flex'>
        <ScrollListContainer>
          <ScrollList
            id='execution-comment-list'
            dataSource={comments}
            renderItem={(item, index) => (
              <ListItem id={`step-comment-${index}`} key={index}>
                <CustomComment
                  author={
                    <CommentAuthor>
                      <span>{item.user.name}</span>
                    </CommentAuthor>
                  }
                  content={
                    <CommentContent
                      id='comment-content'
                      item={item}
                      executionId={executionId}
                      commentsMedia={commentsMedia}
                      mediaUploading={mediaUploading}
                      openModalMedia={openModalMedia}
                      deleteMediaFromComment={deleteMediaFromComment}
                    />
                  }
                  datetime={
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <Tooltip
                        id='tooltip'
                        title={moment(new Date(item.createdAt)).format(
                          dateTimeFormat
                        )}
                      >
                        <span>
                          {moment(new Date(item.createdAt)).fromNow()}
                        </span>
                      </Tooltip>

                      {allowInsertComment && (
                        <CommentActions
                          item={item}
                          commentEditing={commentEditing}
                          deleteComment={() =>
                            deleteComment(item.id, item.mediaIds)
                          }
                          setCommentEditing={setCommentEditing}
                          setTextAreaValue={setTextAreaValue}
                          setCommentType={setCommentType}
                          canEdit={
                            item.type ===
                            COMMENT_TO_PROCEDURE_TYPES.CHANGE_PROPOSAL.id
                          }
                          canDelete={
                            mediaUploading.filter(
                              (media) =>
                                media.executionId === executionId &&
                                (media.progress < 100 || media.error)
                            ).length === 0
                          }
                        />
                      )}
                    </span>
                  }
                />
              </ListItem>
            )}
          />
        </ScrollListContainer>
        {allowInsertComment && (
          <>
            <AttachmentArea>
              <MessageSelect
                id='type-of-comment'
                defaultValue=''
                value={{
                  value: <Translation id={commentType?.name} />,
                  label: <Translation id={commentType?.name} />
                }}
                style={{ width: 200 }}
                disabled={commentEditing}
                onChange={(value) => handleChangeCommentType(value)}
              >
                {Object.keys(COMMENT_TO_PROCEDURE_TYPES).map((key) => (
                  <Option key={key} value={key}>
                    <Translation id={COMMENT_TO_PROCEDURE_TYPES[key].name} />
                  </Option>
                ))}
              </MessageSelect>
            </AttachmentArea>
            <AttachmentFilesWrapper>
              {mediaToUpload.map((attachment, index) => {
                return (
                  <AttachmentFile
                    key={index}
                    attachment={attachment}
                    removeMediaToAttach={handleRemoveUploadMedia}
                    openModalMedia={openModalMedia}
                  />
                )
              })}
            </AttachmentFilesWrapper>
            <CommentArea
              id='comment-area'
              rows={4}
              value={textAreaValue}
              onChange={({ target: { value } }) => setTextAreaValue(value)}
            />
            <SendArea>
              <ActionsContainer>
                <AttachmentButton
                  icon={AttachmentIconView}
                  onChangeMedia={onChangeMedia}
                  acceptedTypes={commentType?.allowedFileExt}
                  disabled={!commentType || commentEditing}
                />
              </ActionsContainer>

              <ButtonContainer>
                <Tooltip
                  title={
                    !textAreaValue || !commentType ? (
                      <Translation id='button.add.comment.execution.disabled' />
                    ) : null
                  }
                >
                  <AddComment
                    id='add-comment-button'
                    disabled={!textAreaValue || !commentType}
                    onClick={handleAddComment}
                    size='large'
                  >
                    {commentEditing ? (
                      <Translation id='editComment' />
                    ) : (
                      <Translation id='addComment' />
                    )}
                  </AddComment>
                </Tooltip>
              </ButtonContainer>
            </SendArea>
          </>
        )}
      </CommentListContainer>
      <InfoModal
        id='file-too-large-modal'
        okButtonTextId='okText'
        textId='media.size.too.large.text'
        titleId='media.size.too.large.title'
        open={openInfoModal}
        setOpen={setOpenInfoModal}
        textParams={{
          name: fileTooLarge?.name,
          fileSize: bytesToMegabytes(fileTooLarge?.size ?? 0),
          maxFileSize: bytesToMegabytes(MAX_FILE_SIZE)
        }}
      />
    </>
  )
}

const AttachmentArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const MessageSelect = styled(Select)`
  margin-left: auto;
`

export default ExecutionComments
