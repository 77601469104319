import { useCallback } from 'react'

import { TABLE_NAMES } from '../../utils/constants/localdb'
import { useDexieTable } from '../useDexieTable'

export default function useSyncStatus(executions, disabled = false) {
  const findSyncStatusFn = useCallback(
    (table) => {
      if (!executions) return []
      const ids = executions?.map((e) => e.id)
      return table.where('executionId').anyOf(ids).toArray()
    },
    [executions]
  )

  const [syncStatuses, loadingSyncStatuses] = useDexieTable({
    tableName: TABLE_NAMES.EXECUTION_SYNC_STATUS,
    disabled,
    findFunction: findSyncStatusFn
  })

  return {
    syncStatuses,
    loadingSyncStatuses,
  }
}
