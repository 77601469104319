import { ALLOWED_VIDEO_EXTENSIONS } from '../../../utils/constants/files'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _readLocalDbData } from '../../common/read'

const tableName = TABLE_NAMES.FILES
export async function readFiles(ids) {
  return (
    await _readLocalDbData({
      tableName,
      ids
    })
  ).data
    .filter((d) => d !== undefined)
    .map((d) => d.data)
}

export async function loadVideosOfStepCommments(executionSteps) {
  if (!executionSteps) return []

  const steptsWithComments = [
    ...(executionSteps.map((step) => step.comments)?.flat() ?? [])
  ]

  const allMediaIds = []

  steptsWithComments.forEach((comment) => {
    if (comment.mediaIds && comment.mediaIds.length > 0) {
      allMediaIds.push(comment.mediaIds)
    }
  })

  if (allMediaIds.length === 0) return []

  const allMedias = await readFiles(allMediaIds.flat())
  const videos = allMedias.filter((media) =>
    ALLOWED_VIDEO_EXTENSIONS.includes(media.extension)
  )
  return videos
}

export async function loadVideosOfExecutionComments(executionComments) {
  if (!executionComments) return []

  const mediaIds = executionComments.map((comment) => comment.mediaIds).flat()

  if (mediaIds.length === 0) return []

  const allMedias = await readFiles(mediaIds)
  const videos = allMedias.filter((media) =>
    ALLOWED_VIDEO_EXTENSIONS.includes(media.extension)
  )

  return videos
}
