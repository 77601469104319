import React from 'react'

import { IconContainer } from '../../../containers/icon'

const CommentEdit = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/comment'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path
        d='M8.22,19.9 L3.75,23.25 L3.75,18.75 L2.25,18.75 C1.422,18.75 0.75,18.078 0.75,17.25 L0.75,2.25 C0.75,1.422 1.422,0.75 2.25,0.75 L21.75,0.75 C22.578,0.75 23.25,1.422 23.25,2.25 L23.25,8.25'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
      <path
        d='M22.63,14.867 L15,22.5 L11.25,23.25 L12,19.5 L19.63,11.869 C20.456,11.043 21.795,11.043 22.621,11.869 L22.63,11.878 C23.464,12.696 23.493,14.019 22.675,14.853 C22.665,14.863 22.655,14.873 22.63,14.867 Z'
        id='Path'
        stroke={color}
        strokeWidth='2'
      ></path>
    </g>
  </svg>
)

export default CommentEdit
