import { Button, Popover, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useGlobal } from 'reactn'
import styled from 'styled-components'

import { playExecution } from '../../../../../../application/execution/play'
import { getExecution } from '../../../../../../store/execution/selectors'
import { getExecutionConditionals } from '../../../../../../store/execution-conditionals/selectors'
import { getExecutionComments } from '../../../../../../store/executionComments/selectors'
import { getExecutionComponents } from '../../../../../../store/executionComponents/selectors'
import { getExecutionManeuvers } from '../../../../../../store/executionManeuvers/selectors'
import { getExecutionSteps } from '../../../../../../store/executionSteps/selectors'
import { getExecutionWarnings } from '../../../../../../store/executionWarnings/selectors'
import {
  getProcedure,
  getSequentialMode
} from '../../../../../../store/procedure/selectors'
import { getUser } from '../../../../../../store/user-management/selectors'
import { getConnectionStatus } from '../../../../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../../../../utils/constants/connection'
import {
  EXECUTION_ROUTER_MODE,
  EXECUTION_STATUS,
  EXECUTION_STATUS_CAN_CHANGE_TO
} from '../../../../../../utils/constants/execution'
import {
  EXECUTION_STATUS_COLOR,
  EXECUTION_STATUS_TEXT_COLOR
} from '../../../../../../utils/constants/styles'
import ExecutionStartModal from '../../../../../modal/execution-start-modal'
import InitialPrecautionsModal from '../../../../../modal/initial-precautions-modal'
import RejectReasonsInfoModal from '../../../../../modal/reject-reasons-info-modal'
import Translation from '../../../../../translations'

const PlayPopover = ({
  children,
  visible,
  setVisible,
  executionGoalVisible,
  setExecutionGoalVisible
}) => {
  const connectionStatus = useSelector(getConnectionStatus)
  const isOnline = connectionStatus === CONNECTION_STATUS.ONLINE

  const history = useHistory()
  const execution = useSelector(getExecution)
  const procedure = useSelector(getProcedure)
  const executionManeuvers = useSelector(getExecutionManeuvers)
  const executionComments = useSelector(getExecutionComments, shallowEqual)
  const executionSteps = useSelector(getExecutionSteps)
  const executionComponents = useSelector(getExecutionComponents)
  const executionWarnings = useSelector(getExecutionWarnings)
  const executionConditionals = useSelector(getExecutionConditionals)
  const [initialPrecautionsModalVisible, setInitialPrecautionsModalVisible] =
    useState(false)
  const [rejectReasonsModalVisible, setRejectReasonsModalVisible] =
    useState(false)
  const [selectedExecutionType, setSelectedExecutionType] = useState(null)
  const [executionSettings, setExecutionSettings] = useState(null)
  const procedureSequentialMode = useSelector(getSequentialMode)
  const user = useSelector(getUser)

  const onSelectExecutionType = (type) => {
    setVisible(false)
    setSelectedExecutionType(type)
    setExecutionGoalVisible(true)
  }

  const onSharedExecutionStart = async (settings, procedureSequentialMode) => {
    const execId = await playExecution({
      execution,
      status: EXECUTION_STATUS.SHARED,
      procedure,
      executionComponents,
      executionSteps,
      executionManeuvers,
      executionWarnings,
      executionComments,
      executionConditionals,
      executionGoalId: settings?.executionGoalId,
      otherExecutionGoal: settings?.otherExecutionGoal,
      sequentialMode: settings?.sequentialMode,
      simpleApprobation: settings?.simpleApprobation,
      unitId: settings?.unitId,
      procedureSequentialMode,
      user
    })

    setVisible(false)

    history.push({
      pathname: '/shared/' + execId
    })
  }

  const onIndividualExecutionStart = async (
    settings,
    procedureSequentialMode
  ) => {
    const execId = await playExecution({
      execution,
      status: EXECUTION_STATUS.EXECUTING,
      procedure,
      executionComponents,
      executionSteps,
      executionManeuvers,
      executionWarnings,
      executionComments,
      executionConditionals,
      executionGoalId: settings?.executionGoalId,
      otherExecutionGoal: settings?.otherExecutionGoal,
      sequentialMode: settings?.sequentialMode,
      simpleApprobation: settings?.simpleApprobation,
      unitId: settings?.unitId,
      procedureSequentialMode,
      user
    })

    setVisible(false)

    history.push({
      pathname: `/executions/${execId}/${EXECUTION_ROUTER_MODE.NEW}`
    })
  }

  const onConfirmExecutionGoal = (settings) => {
    const maneuverTexts = executionManeuvers?.filter((maneuver) => {
      const maneuverData = procedure?.maneuvers?.find(
        (m) => m.parentStep === maneuver.stepId && maneuver.active
      )
      return !!maneuverData?.warningText
    })
    if (
      execution?.status === EXECUTION_STATUS.PAUSED &&
      (procedure?.initialWarnings?.text || maneuverTexts?.length)
    ) {
      setInitialPrecautionsModalVisible(true)
    } else if (execution?.revisionComment) {
      setRejectReasonsModalVisible(true)
    } else {
      return selectedExecutionType === EXECUTION_STATUS.SHARED
        ? onSharedExecutionStart(settings, procedureSequentialMode)
        : onIndividualExecutionStart(settings, procedureSequentialMode)
    }
  }

  const initiateExecution = () => {
    selectedExecutionType === EXECUTION_STATUS.SHARED
      ? onSharedExecutionStart(executionSettings, procedureSequentialMode)
      : onIndividualExecutionStart(executionSettings, procedureSequentialMode)
  }

  return (
    <>
      <InitialPrecautionsModal
        modalVisible={initialPrecautionsModalVisible}
        setModalVisible={setInitialPrecautionsModalVisible}
        onAccept={async () => {
          if (execution?.revisionComment) {
            setRejectReasonsModalVisible(true)
          } else {
            initiateExecution()
          }
        }}
      />
      <RejectReasonsInfoModal
        isOpen={!initialPrecautionsModalVisible && rejectReasonsModalVisible}
        rejectReason={execution?.revisionComment}
        handleRejectModal={() => {
          setRejectReasonsModalVisible(false)
          initiateExecution()
        }}
      />

      <Popover
        placement='bottom'
        open={visible}
        onOpenChange={(visible) => setVisible(visible)}
        content={
          <div>
            <Button
              id='alone-button'
              onClick={() => onSelectExecutionType(EXECUTION_STATUS.EXECUTING)}
              disabled={
                execution &&
                !EXECUTION_STATUS_CAN_CHANGE_TO[execution.status].includes(
                  EXECUTION_STATUS.EXECUTING
                )
              }
              style={{ backgroundColor: EXECUTION_STATUS_COLOR.EXECUTING }}
            >
              <Translation id='single' />
            </Button>

            <Tooltip
              placement='bottom'
              title={<Translation id='isOffline' />}
              {...(isOnline ? { open: false } : {})}
            >
              <AdvancedButton
                id='shared-button'
                onClick={() => onSelectExecutionType(EXECUTION_STATUS.SHARED)}
                style={{
                  marginLeft: '8px',
                  backgroundColor: EXECUTION_STATUS_COLOR.SHARED,
                  color: EXECUTION_STATUS_TEXT_COLOR.SHARED
                }}
                disabled={
                  !isOnline ||
                  (execution &&
                    !EXECUTION_STATUS_CAN_CHANGE_TO[execution?.status].includes(
                      EXECUTION_STATUS.SHARED
                    ))
                }
              >
                <Translation id='shared' />
              </AdvancedButton>
            </Tooltip>
          </div>
        }
        trigger='click'
      >
        {children}
      </Popover>
      <ExecutionStartModal
        visible={executionGoalVisible}
        setExecutionGoalModal={setExecutionGoalVisible}
        onConfirm={(settings) => {
          setExecutionGoalVisible(false)
          setExecutionSettings(settings)
          onConfirmExecutionGoal(settings)
        }}
      />
    </>
  )
}

const AdvancedButton = styled(Button)`
  margin-right: 10px;
`

export default PlayPopover
