export class UnitNameConflict extends Error {
  constructor(message) {
    super(message)
    this.name = 'UnitNameConflict'
    this.descriptionId = 'unit.conflict.name.error'
    this.key = 'UNIT_NAME_CONFLICT'
  }
}

export class UnitHasNoName extends Error {
  constructor(message) {
    super(message)
    this.name = 'UnitHasNoName'
    this.descriptionId = 'unit.no.name.error'
    this.key = 'UNIT_HAS_NO_NAME'
  }
}
