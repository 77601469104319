import React from 'react'

const NoConnectionIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 80 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.8666 37.613C22.3847 35.1013 25.6079 33.4159 29.1075 32.7811C32.607 32.1464 36.2168 32.5923 39.4566 34.0597'
      stroke={color}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.2333 26.0732C16.213 20.5765 24.0341 17.518 32.1562 17.5001C40.2784 17.4822 48.1129 20.5062 54.1167 25.9765'
      stroke={color}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.5 12.9491C10.9379 6.17697 21.4347 2.48795 32.2541 2.49219C43.0735 2.49643 53.5674 6.19369 62 12.9724'
      stroke={color}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M37.5 57.5039C37.5 62.8082 39.6071 67.8953 43.3579 71.6461C47.1086 75.3968 52.1957 77.5039 57.5 77.5039C62.8043 77.5039 67.8914 75.3968 71.6421 71.6461C75.3929 67.8953 77.5 62.8082 77.5 57.5039C77.5 52.1996 75.3929 47.1125 71.6421 43.3618C67.8914 39.611 62.8043 37.5039 57.5 37.5039C52.1957 37.5039 47.1086 39.611 43.3579 43.3618C39.6071 47.1125 37.5 52.1996 37.5 57.5039V57.5039Z'
      stroke={color}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M65 50L50 65'
      stroke={color}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M50 50L65 65'
      stroke={color}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default NoConnectionIcon
