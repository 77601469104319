import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

import useModal from '../../../hooks/use-modal'
import Translation from '../../translations'

import useConfirmCreate from './hooks/use-confirm-create'

const CreateButtonHeader = ({
  Modal,
  actionTranslation,
  buttonTextId,
  handleCreate
}) => {
  const { openModal, closeModal, modalOpen } = useModal()
  const { onConfirmCreate } = useConfirmCreate({
    handleCreate,
    closeModal
  })

  return (
    <>
      <Button type='primary' onClick={openModal}>
        <PlusOutlined />
        <Translation id={buttonTextId} />
      </Button>
      <Modal
        open={modalOpen}
        closeModal={closeModal}
        onConfirm={onConfirmCreate}
        actionTranslation={actionTranslation}
      />
    </>
  )
}

export default CreateButtonHeader
