import { Layout as AntdLayout } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Header from '../views/header'

const { Content } = AntdLayout

const Layout = ({ children }) => {
  return (
    <AntdLayout className='full-height'>
      <Header />
      <FixedContent>{children}</FixedContent>
    </AntdLayout>
  )
}

const FixedContent = styled(Content)`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export default Layout
