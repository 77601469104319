import { Modal } from 'antd'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import useExecutionGoal from '../../../../../hooks/data/useExecutionGoal'
import useLocalPlantUnit from '../../../../../hooks/use-local-plant-unit'
import { getTranslation } from '../../../../../i18n/getTranslation'
import { getExecution } from '../../../../../store/execution/selectors'
import { getExecutionComments } from '../../../../../store/executionComments/selectors'
import { getExecutionComponents } from '../../../../../store/executionComponents/selectors'
import { getExecutionSteps } from '../../../../../store/executionSteps/selectors'
import { getProcedure } from '../../../../../store/procedure/selectors'
import { printExecutionReport } from '../../../../../utils/helpers/report/print'

export function usePrint({ loadPrint }) {
  const [loading, setLoading] = useState(false)

  const componentsExecution = useSelector(getExecutionComponents)
  const procedure = useSelector(getProcedure)
  const execution = useSelector(getExecution)
  const executionSteps = useSelector(getExecutionSteps)
  const executionComments = useSelector(getExecutionComments, shallowEqual)

  const { plantUnit } = useLocalPlantUnit(execution?.unitId)
  const { executionGoal } = useExecutionGoal(execution?.executionGoalId)

  const executionToPrint = execution
    ? {
        ...execution,
        executedFor: plantUnit,
        executionGoal,
        steps: executionSteps,
        comments: executionComments
      }
    : null

  const onPrint = async () => {
    const message = getTranslation('preparingToPrinting')
    let unloadPrint

    setLoading(true)

    try {
      unloadPrint = await loadPrint(
        message,
        componentsExecution,
        procedure?.components
      )
    } catch (unloadPrint) {
      setLoading(false)
      unloadPrint()
      const modalData = {
        title: 'printingError',
        content: 'procedureBadDigitized'
      }
      Modal.error({
        ...modalData,
        title: getTranslation(modalData.title),
        content: getTranslation(modalData.content)
      })
    }

    if (!unloadPrint) return

    try {
      await printExecutionReport(executionToPrint, procedure)
    } catch (error) {
      const modalData = {
        title: 'printingError',
        content: 'procedureBadDigitized'
      }
      Modal.error({
        ...modalData,
        title: getTranslation(modalData.title),
        content: getTranslation(modalData.content)
      })
    } finally {
      setLoading(false)
      unloadPrint()
    }
  }

  return {
    loading,
    onPrint
  }
}
