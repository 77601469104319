import Dexie from 'dexie'

export async function getKeys(table, keys) {
  return new Promise((resolve, reject) => {
    const minKeys = keys.map(() => Dexie.minKey)
    const maxKeys = keys.map(() => Dexie.maxKey)

    return table
      .where(`[${keys.join('+')}]`)
      .between(minKeys, maxKeys)
      .keys((keysValuesArrays) => {
        // Convert each keysArray to object
        const keysObjects = keysValuesArrays.map((keysValuesArray) => {
          return keysValuesArray.reduce((acc, curr, index) => {
            acc[keys[index]] = curr
            return acc
          }, {})
        })
        resolve(keysObjects)
      })
      .catch(reject)
  })
}
