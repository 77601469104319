import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

const SquareButton = (props) => {
  const { disabledIcon, color, disabled, ...antProps } = props
  return (
    <StyledButton
      {...antProps}
      data-disabled={disabledIcon || disabled}
      color={color}
      disabled={disabledIcon || disabled}
    />
  )
}

const StyledButton = styled(Button)`
  width: 48px;
  height: 48px;
  border: none;
  padding: 0;
  margin: 0 2px;
  background-color: ${(props) => props.color || 'transparent'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled] {
    background-color: transparent;
    &:hover,
    &:active {
      background-color: transparent;
      border: none;
    }
    & .icon {
      opacity: 30%;
    }
  }

  & .icon {
    opacity: ${(props) => (props['data-disabled'] ? '30%' : '100%')};
  }
`

export default SquareButton
