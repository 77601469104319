import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StatisticPopoverContent = ({
  steps,
  loading,
  onSelectStep,
  closeDrawer
}) => {
  return (
    <PopoverContainer>
      {loading && <Spin size='small' />}
      {!loading &&
        steps.map(({ stepIndex, stepId }, i) => (
          <PopoverStep
            key={`${stepId}-${i}`}
            onClick={() => {
              closeDrawer(false)
              onSelectStep([stepId])
            }}
          >
            Paso <span>{stepIndex}</span>
          </PopoverStep>
        ))}
    </PopoverContainer>
  )
}

const PopoverContainer = styled.div`
  width: 150px;
  max-height: 200px;
  overflow: auto;

  & p {
    width: calc(100% - 10px);
    border-bottom: 1px solid #edece8;
  }

  & p:last-of-type {
    border-bottom: none;
  }
`

const PopoverStep = styled.p`
  margin: 0;
  padding: 5px;

  & span {
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
    background-color: #edece8;
  }
`

export default StatisticPopoverContent
