import { Button } from 'antd'
import Title from 'antd/lib/typography/Title'
import React from 'react'
import styled from 'styled-components'

import Copy from '../../../../components/icons/copy'
import { IconContainer } from '../../../../containers/icon'
import { getTranslation } from '../../../../i18n/getTranslation'
import { notificationMessage } from '../../../../utils/helpers/notification-message'
import { erro } from '../../../../utils/logger'

const DisplayedPassword = ({ children }) => {
  const onCopy = () => {
    try {
      navigator.clipboard.writeText(children)
      notificationMessage({
        message: getTranslation('passwordCopiedToClipboard')
      })
    } catch (e) {
      erro(e)
      notificationMessage({
        type: 'error',
        message: getTranslation('passwordCopyError')
      })
    }
  }

  return (
    <DisplayedPasswordContainer id='temporal-password'>
      <Title style={{ marginBottom: 0 }} level={1}>
        {children}
      </Title>
      <CopyButton id='add-user-button' onClick={onCopy}>
        <IconContainer size='24' color='black' icon={Copy} />
      </CopyButton>
    </DisplayedPasswordContainer>
  )
}

const DisplayedPasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
`

const CopyButton = styled(Button)`
  padding: 0;

  width: 40px;
  height: 40px;

  border: 1px solid #efeeeb;
  border-radius: 2px;
`

export default DisplayedPassword
