{
  "error": "Error",
  "error.generic": "Ha ocurrido un error en la operación, por favor inténtelo de nuevo.",
  "dateTimeFormat": "DD/MM/YYYY HH:mm:ss",
  "dateTimeFormatShort": "DD/MM/YY-HH:mm",
  "dateFormat": "DD/MM/YYYY",
  "timeFormat": "HH:mm",
  "dateFormatShort": "DD/MM/YY",
  "executionCodeDateTimeFormat": "DDMMYY-HHmmss",
  "procedures": "Procedimientos",
  "executions": "Ejecuciones",
  "execution": "Ejecución",
  "executionId": "ID de ejecución",
  "executionStartDate": "Fecha de inicio",
  "step": "Paso",
  "proceduresOnDevice": "Procedimientos en el dispositivo",
  "executionsOnDevice": "Ejecuciones en el dispositivo",
  "downloadedProcedures": "Procedimientos descargados",
  "unit": "Grupo",
  "series": "Serie",
  "series.lower": "series",
  "units.lower": "unidades",
  "unit.by.default": "Unidad {unitName} por defecto",
  "key": "Clave",
  "rev": "Rev.",
  "title": "Título",
  "buildNumberShort": "NC",
  "lastMod": "Última Mod.",
  "startDate": "Fecha inicio",
  "status": "Estado",
  "owner": "Propietario",
  "action": "Acción",
  "progress": "Progreso",
  "sync": "Sinc",
  "syncExecutions": "Sincronizar",
  "range": "Rango",
  "clean": "Limpiar",
  "apply": "Aplicar",
  "okText": "Aceptar",
  "confirm": "Confirmar",
  "cancel": "Cancelar",
  "close": "Cerrar",
  "abort": "Abortar",
  "finish": "Finalizar",
  "single": "Individual",
  "shared": "Compartido",
  "initToExecute": "Para Ejecutar el procedimiento pulse Iniciar",
  "language": "Idioma",
  "help": "Ayuda",
  "management": "Administración",
  "confirmLogout": "¿Quieres cerrar la sesión?",
  "userInfo": "Información de usuario",
  "logout": "Cerrar sesión",
  "beforeLoggingOut": "Antes de cerrar la sesión...",
  "executionMassiveInterruption": "Interrupción masiva de ejecuciones",
  "executionMassiveInterruptionText": "Estas son las ejecuciones que puedes interrumpir en conjunto. Una vez las interrumpas se borrarán de tu dispositivo y se subirán al servidor.",
  "executionMassiveInterruptionTextEmpty": "Parece que no tienes ninguna ejecución que puedas interrumpir.",
  "proceduresWorking": "Ejecuciones en progreso",
  "reviseExecutions": "Revisar ejecuciones",
  "executionsOutOfSync": "Existen ejecuciones sin sincronizar. Debes de sincronizarlas antes de poder cerrar sesión.",
  "executionsOutOfSyncNotLogout": "Existen ejecuciones sin sincronizar. Debes de sincronizarlas para poder hacer una interrupción masiva.",
  "executionsInterrupted": "Todas las ejecuciones en progreso han sido interrumpidas",
  "interruptLogout": "Interrumpir y cerrar sesión",
  "interruptButton": "Interrumpir ejecuciones",
  "executionsNeedsInterrupt": "No puedes cerrar sesión con ejecuciones locales en curso. Por favor revísalas y cambia su estado al que corresponda",
  "executionsNeedsSync": "Dirígete a un punto de conexión para poder sincronizar las ejecuciones.",
  "workNeedsSync": "Tienes trabajo sin terminar o sin sincronizar. Por favor, dirígete a un punto de conexión para sincronizarlo.",
  "downloadComplete": "Descarga completada",
  "downloadFailed": "La descarga falló",
  "downloadedProcedure": "Descargado procedimiento:",
  "downloadedExecution": "Ejecución descargada",
  "downloadedReferencedProcedures": "Junto con los siguientes procedimientos referenciados:",
  "downloadReferencedFilesFailed": "No se pudieron descargar algunos de los documentos referenciados",
  "downloadReferencedProceduresFailed": "No se pudieron descargar los procedimientos referenciados",
  "downloadReferencedProcedureIdFailed": "El/los procedimientos con clave: {procedureKey} no se han podido descargar",
  "delete": "Eliminar",
  "deleteSuccess": "Eliminado correctamente",
  "deleteError": "Error de eliminación",
  "deleteDescrProc": "El procedimiento ha sido eliminado",
  "deleteDescrExec": "La ejecución ha sido eliminada",
  "loadProcedureError": "Ha habido un problema y no se puede cargar el procedimiento",
  "loadProcedureErrorSubtitle": "Es posible que no haya sido generado correctamente, o haya sido generado con una versión previa no soportada",
  "index": "Índice",
  "nStepsToSignPlural": "{count} pasos por firmar",
  "nStepsToSignSingular": "{count} paso por firmar",
  "documents": "Documentos",
  "jumps": "Saltos",
  "return": "Volver",
  "fileRefError": "Archivo referenciado no encontrado",
  "jump": "Saltar",
  "add": "Añadir",
  "edit": "Editar",
  "goBack": "Volver",
  "pageNotFound": "Lo sentimos, la página a la que intentas acceder no existe",
  "noConnectionTitle": "No hay conexión",
  "noConnectionDescription": "Información disponible solo si el dispositivo está conectado",
  "commentsDrawerStep": "Comentarios del paso ",
  "nonCompliance": "Incumplimiento",
  "commentsDrawerAll": "Todos los pasos con comentarios",
  "addComment": "Agregar comentario",
  "editComment": "Editar comentario",
  "requiredNonCompliantComment": "Es necesario reflejar el motivo del incumplimiento del componente para continuar con la ejecución",
  "recommendedNonCompliantComment": "Es recomendable reflejar el motivo del incumplimiento del componente para continuar con la ejecución",
  "nonCompliantInitialValue": "El componente {componentType} no satisface el criterio de aceptación por el siguiente motivo:",
  "loadingLocalExecutions": "Cargando ejecuciones locales",
  "noLocalExecutions": "No existen ejecuciones locales",
  "stepsInProgress": "Pasos en curso",
  "stepsOnHold": "Pasos en espera",
  "procedureComments": "Propuestas de cambio y adjuntos a ejecución",
  "performingManeuver": "Ejecutar maniobra",
  "cancelManeuver": "Cancelar maniobra",
  "select": "Seleccionar",
  "sign": "Firma",
  "selectdate": "Fecha selec.",
  "invalidNumber": "Número no válido",
  "outOfRange": "Valor fuera de rango: {min} ≤ x ≤ {max}",
  "validationNotPassed": "Fuera de límite: {validationRule}",
  "signalQualityPoor": "Calidad de la señal pobre",
  "comment": "Comentario",
  "comments": "Comentarios",
  "noComments": "No hay comentarios",
  "fieldRequired": "Campo requerido",
  "finishExecutionQuestion": "¿Quieres finalizar la ejecución?",
  "finishStepsOnHoldProgress": "No se puede finalizar una ejecución con pasos en curso o espera",
  "abortStepsOnProgress": "No se puede abortar una ejecución con pasos en curso",
  "annexed": "Anexo de comentarios",
  "modal.editField": "Editar Campo",
  "downloadProceduresModalTitle": "Selecciona los procedimientos referenciados para descargar",
  "download": "Descargar",
  "procedureNotDownloaded": "Procedimiento no descargado",
  "downloadProcedureBeforeJump": "Por favor, descarga el documento antes de saltar.",
  "error_uploading_files": "Algunos ficheros no se han subido",
  "only_upload_images": "Sólo se pueden subir imágenes",
  "only_upload_images_or_videos": "Sólo se pueden subir imágenes y vídeos",
  "search": "Buscar",
  "reset": "Reiniciar",
  "initial_precautions": "Precauciones iniciales",
  "initial_warnings": "Precauciones iniciales",
  "maneuver_warnings": "Precauciones de maniobras",
  "connectionLostTitle": "Sin conexión",
  "connectionLostDescription": "Se ha perdido la conexión con el servidor",
  "connectedTitle": "Conectado",
  "connectedDescription": "Se ha conectado con el servidor",
  "connection_needed": "Se requiere conexión al servidor para realizar esta acción",
  "username": "Usuario",
  "password": "Contraseña",
  "newPassword": "Nueva contraseña",
  "initials": "Iniciales",
  "loginButton": "Entrar",
  "errorOnOfflineLogin": "Error al recuperar tus datos locales",
  "insertRemotePassword": "Introduce la contraseña del servidor",
  "insertLocalPassword": "Introduce la contraseña local",
  "remotePasswordWrong": "Tu constraseña ha cambiado en otro dispositivo. Por favor, introduce tu nueva contraseña para actualizarla en este dispositivo.",
  "localPasswordWrong": "Tu contraseña ha cambiado en otro dispositivo. Por favor, introduce la última contraseña que hayas utilizado en este dispositivo.",
  "loginOnlineFirst": "Estás sin conexión. Se necesita contactar con el servidor para iniciar sesión por primera vez.",
  "failedAuthAttempt": "La contraseña o la dirección de correo no son correctas",
  "unknownError": "Error desconocido",
  "other": "Otro",
  "qa": "QA",
  "qaStatus": "QA",
  "myWorkForToday": "Mi trabajo para hoy",
  "userDashboardExecutionstypes": "Ejecuciones interrumpidas y en progreso",
  "assignedProcedures": "Procedimientos asignados",
  "manageExecutions": "Administrar ejecuciones",
  "review": "Revisión",
  "approve": "Aprobación",
  "merge": "Merge",
  "mergeModalTitle": "Parámetros de Merge",
  "mergedExecutionsTitle": "Ejecuciones mergeadas",
  "done": "Finalizado",
  "approved": "Aprobado",
  "reviewed": "Revisado",
  "rejected": "Rechazado",
  "reject": "Rechazar",
  "pending": "Por hacer",
  "signatures": "Firmas",
  "signature": "Firma",
  "solveConflicts": "Resolución de conflictos",
  "noConflicts": "No hay conflictos",
  "EXECUTING": "EJECUTANDO",
  "PAUSED": "PAUSADO",
  "ABORTED": "ABORTADO",
  "INTERRUPTED": "INTERRUMPIDO",
  "FINISHED": "FINALIZADO",
  "SHARED": "COMPARTIDO",
  "EXECUTING.capitalized": "Ejecutando",
  "PAUSED.capitalized": "Pausado",
  "ABORTED.capitalized": "Abortado",
  "INTERRUPTED.capitalized": "Interrumpido",
  "FINISHED.capitalized": "Finalizado",
  "SHARED.capitalized": "Compartido",
  "APPROVED": "APROBADO",
  "REVIEWED": "REVISADO",
  "REJECTED": "RECHAZADO",
  "DRAFT": "BORRADOR",
  "REVIEW": "REVISADO",
  "PUBLISHED": "PUBLICADO",
  "DEPRECATED": "OBSOLETO",
  "isOffline": "Estás desconectado",
  "noPermission": "No tienes permiso para esta operación. Contacta con el administrador.",
  "noPermissionForSpecificRole": "Debes tener el nivel de rol '{role}' o superior y el permiso correspondiente para realizar esta operación. Contacta con el administrador.",
  "rejectModal.infoMessage": "La ejecución ha sido rechazada por los siguientes motivos:",
  "rejectModal.rejectReasons": "Motivos de rechazo",
  "rejectModal.rejectMessage": "Va a rechazar la ejecución, esta acción no se puede deshacer. Es necesario que indique el motivo de rechazo para poder continuar.",
  "rejectModal.rejectReason": "Motivo de rechazo",
  "confirmRevisionQuestion": "Todos los cambios en revisión se guardarán automáticamente.",
  "incorrectSignatureWarning": "Atención: se han encontrado firmas incorrectas.",
  "deprecatedProcedureWarning": "Atención: este procedimiento está obsoleto",
  "cannotFinishReview": "No se puede finalizar la revisión debido a firmas incorrectas.",
  "componentChangedFromTo": "El componente {type} ha cambiado de {oldValue} a {newValue} a fecha {date} por {user}.",
  "empty": "vacío",
  "turn_on_sync": "Activar sincronización",
  "trying_to_reconnect": "Intentando reconectar",
  "reconnect": "Reconectar",
  "could_not_connect": "No se puede conectar con el servidor. Las ejecuciones compartidas necesitan conexión a internet para funcionar.",
  "exit": "Salir",
  "me": "Yo",
  "executionInformation": "Información de la ejecución",
  "procedure": "Procedimiento",
  "executionDate": "Fecha ejecución",
  "executionCode": "Código ejecución",
  "executionGoal": "Motivo de ejecución",
  "editExecutionGoal": "Editar motivo de ejecución",
  "executionGoalRequired": "Por favor introduzca un motivo de ejecución",
  "executionGoals": "Motivos de ejecución",
  "executionGoalsEdit": "Modifique el Motivo de ejecución",
  "executionGoalDelete": "¿Está seguro de borrar este motivo de ejecución?",
  "executionGoalPlaceholder": "Añade un motivo",
  "executionStatus": "Estado ejecución",
  "internalCode": "Código interno",
  "date": "Fecha",
  "hour": "Hora",
  "start": "Comienzo",
  "final": "Final",
  "notYetFinished": "Aún no finalizada",
  "saveChanges": "Guardar cambios",
  "writeHere": "Escribe aqui...",
  "TextFieldEditing": "Edición de campo de texto",
  "exit_execution": "Salir de la ejecución",
  "deleteProcedureQuestion": "¿Quieres eliminar el procedimiento?",
  "deleteExecutionQuestion": "¿Quieres eliminar la ejecución?",
  "proceduresDownload": "Procedimientos para descarga",
  "executionsDownload": "Ejecuciones para descarga",
  "checkIn": "Interrumpir",
  "pause": "Pausar",
  "checkInOk": "La ejecución ha sido correctamente interrumpida",
  "proceduresExecTab": "Procedimientos",
  "execution.start.modal.title": "Inicio de ejecución",
  "execution.goals": "Motivos de Ejecución",
  "execution.plant.unit": "Ejecutado para",
  "execution.client.department": "Departamento",
  "execution.client.department.placeholder": "Departamentos",
  "execution.plant.unit.placeholder": "Seleccione una opción",
  "execution.client.settings.errMsg": "Por favor introduzca el cliente o planta y la unidad!",
  "execution.client.settings.SuccessMsg": "Configuración guardada con éxito.",
  "execution.clientSettings.delete.successMsg": "Configuración eliminada con éxito.",
  "execution.clientSettings.delete.failedMsg": "Se ha producido un error guardando la configuración.",
  "execution.goal.successMsg": "Motivo de ejecución guardado con éxito.",
  "execution.goal.edit.successMsg": "Motivo de ejecución modificado con éxito.",
  "execution.goal.delete.successMsg": "Motivo de ejecución borrado con éxito.",
  "execution.goal.delete.failMsg": "Ha ocurrido un error borrando el motivo de ejecución.",
  "execution.unit": "Unidad",
  "execution.client.settings": "Escriba para añadir un cliente o planta a la lista",
  "execution.customer.settings.add.SuccessMsg": "El cliente o planta ha sido añadido con éxito",
  "execution.unit.plant": "Añada una unidad al cliente o planta",
  "continue": "Continuar",
  "observations": "Observaciones",
  "next": "Siguiente",
  "previous": "Anterior",
  "seniorRoleRequired": "Se requiere un rol senior para firmar",
  "chooseSeniorRole": "La firma de este paso debe ser realizada por un rol superior. Selecciona de la lista a continuación, al superior que proporcionará un código para la firma:",
  "chooseSeniorRolePlaceholder": "Elige a un superior",
  "enterSignatureCode": "Introduce el código de firma proporcionado",
  "codeInvalid": "El código introducido es inválido",
  "noMergeDifferentProcedure": "No se pueden fusionar dos ejecuciones de distinto procedimiento",
  "codeGenerator": "Generador de código",
  "singleUseCodeTitle": "Generador de códigos de un solo uso",
  "singleUseCodText": "Los códigos de un solo uso se utilizan para realizar acciones a través de otros usuarios, como autorizar firmas",
  "singleUseCodeList1": "Puede generar tantos códigos como necesite.",
  "singleUseCodeList2": "El uso de un código se permite una sola vez",
  "singleUseCodeList3": "Una vez el generador de códigos se cierre, el código generado se perderá.",
  "generateBtn": "Generar",
  "admin": "Administrador",
  "shift_manager": "Jefe de Turno",
  "supervisor": "Supervisor",
  "room_operator": "Operador de Sala",
  "plant_operator": "Operador de Planta",
  "guest": "Invitado",
  "digitalizer": "Digitalizador",
  "view": "Ver",
  "downloadProceduresTitle": "Descargar procedimientos",
  "downloadExecutionsTitle": "Descargar ejecuciones",
  "cloneExecution": "Clonar",
  "resumeExecution": "Reanudar",
  "errorResume": "Error de reanudación",
  "errorResumeDescription": "Se ha producido un error durante la reanudación de la ejecución",
  "successResume": "Reanudación finalizada",
  "successResumeDescription": "La reanudación ha sido un éxito",
  "disconectModalBody": "¿Estás seguro de que quieres cerrar la sesión?",
  "proceedVersion": "Versión de Proceed",
  "userJoinedExecution": "Usuario conectado",
  "userLeftExecution": "Usuario desconectado",
  "userJoinedExecutionDescription": "{user} se ha unido a la ejecución en modo '{mode}'",
  "userLeftExecutionDescription": "{user} ha salido de la ejecución",
  "enterUserName": "Por favor, introduzca su usuario.",
  "enterPassword": "Por favor, introduzca su contraseña.",
  "executionNoSync": "La ejecución no ha sido sincronizada",
  "procedureNotFound": "El procedimiento requerido no existe o no está descargado",
  "executionNotFound": "La ejecución requerida no existe o no está descargada",
  "referencedProcedureNotFound": "El procedimiento referenciado con clave '{key}' no existe",
  "onlineUsers": "Usuarios en línea",
  "clickedStepIsDisabled": "El paso está desactivado por una maniobra o condicional",
  "clickedStepIsOccupied": "El paso está ocupado por otro usuario",
  "interactExecutionNotRunning": "El procedimiento no está en ejecución",
  "interactExecutionDuringRevisionNotRunning": "El procedimiento no está en ejecución, se están aplicando cambios desde el proceso de revisión",
  "cannotMakeChangesInMerge": "No puedes hacer cambios mientras fusionas ejecuciones",
  "reviewerCantMakeChanges": "La ejecución ya se ha revisado, así que no se pueden hacer más cambios",
  "legalPolicies": "AVISO LEGAL",
  "legalPoliciesIdentity": "1) IDENTIDAD DEL TITULAR DEL WEB",
  "legalPoliciesIdentityText": "TECNATOM, S.A. es una compañía con domicilio en Avenida Montes de Oca, 1 CP. 28703 de San Sebastián de los Reyes (Madrid- España), con número de identificación fiscal A28074078 e inscrita en el Registro Mercantil de Madrid el 4 de septiembre de 1957, Tomo 1208 general 692 sección 3ª del Libro de Sociedades, Folio 22 Hoja 4239. Teléfono: 916598845 · FAX: 916598677",
  "legalPoliciesConditions": "2) CONDICIONES DE USO Y ACEPTACIÓN",
  "legalPoliciesConditionsText": "Las presentes Condiciones de Uso regulan el uso de los servicios que TECNATOM, S.A. pone a disposición de los Usuarios de Proceed. La utilización del sitio web por un tercero le atribuye la condición de Usuario y, supone la aceptación plena por dicho Usuario, de todas y cada una de las condiciones que se incorporan en el presente Aviso legal. TECNATOM, S.A. puede ofrecer a través del Web, servicios o productos que podrán encontrarse sometidos a unas condiciones particulares propias que, según los casos, sustituyen, completan y/o modifican las presentes Condiciones, y sobre las cuales se informará al Usuario en cada caso concreto.",
  "counterOf": "{index} de {total}",
  "selectTime": "Hora selec.",
  "manageProcedures": "Administrar procedimientos",
  "import": "Importar",
  "important": "Importante",
  "export": "Exportar",
  "importSuccess": "Archivo importado con éxito",
  "procedureAlreadyExists": "El procedimiento ya existe: {id}",
  "fileAlreadyExists": "El archivo ya existe: {id}",
  "attachmentRepeated": "¡Este archivo ya ha sido adjuntado!",
  "unknownImportError": "Ha habido un error en la importación del archivo",
  "controlPanel": "Panel de control",
  "configurationPanel": "Configuración",
  "configurationPanelClients": "Cliente o Planta",
  "configurationPanelUnits": "Unidad",
  "configurationPanelUsers": "Usuarios",
  "configurationPanelRoles": "Roles y permisos",
  "configurationPanel.alert.deleteEntry": "¿Está seguro de que quiere eliminar este campo?",
  "roleAddSuccessMsg": "Rol creado con éxito",
  "roleAddErrorMsg": "Error al añadir el rol",
  "roleEditSuccessMsg": "Rol modificado con éxito",
  "roleEditErrorMsg": "Error al modificar el rol",
  "roleDeleteSuccessMsg": "Rol eliminado con éxito",
  "roleDeleteErrorMsg": "Error al eliminar el rol",
  "role.resetPermissions": "Restablecer permisos",
  "role.resetPermissionsTooltip.text": "Al restablecer los permisos por defecto se desmarcarán los permisos actuales",
  "role.resetPermissionsTooltip.button.discard": "Descartar",
  "role.resetPermissionsTooltip.button.reset": "Restablecer",
  "role.create.error.conflict.exists": "El rol no se pudo crear debido a que existe otro rol con el mismo nombre",
  "role.delete.error.conflict.user": "El rol no se pudo eliminar debido a que está asignado a uno o más usuarios",
  "role.level.select.description.part1": "Los niveles se muestran por orden de rangos, siendo el nivel 1 el de mayor rango.",
  "role.level.select.description.part2": " Cada nivel engloba las capacidades de los rangos más bajos.",
  "role.level.admin.title": "Administrador",
  "role.level.admin.description": "Gestión de la aplicación y sus usuarios",
  "role.level.digitalizer.title": "Digitalizador",
  "role.level.digitalizer.description": "Digitalización y gestión de procedimientos",
  "role.level.approver.title": "Aprobador",
  "role.level.approver.description": "Aprobación y revisión de ejecuciones realizadas",
  "role.level.reviewer.title": "Revisor",
  "role.level.reviewer.description": "Revisión de ejecuciones realizadas",
  "role.level.procedure.executor.title": "Ejecutor de procedimientos",
  "role.level.procedure.executor.description": "Realización y finalización de ejecuciones",
  "role.level.field.staff.title": "Personal de campo",
  "role.level.field.staff.description": "Realización de ejecuciones de campo",
  "role.level.guest.title": "Invitado",
  "role.level.guest.description": "Usuario con permisos de visualización",
  "deleteRole": "¿Está seguro de borrar este rol?",
  "addRole": "Crear Rol",
  "editRole": "Editar rol",
  "roleName": "Nombre del rol",
  "roleNamePlaceholder": "Escribir nombre...",
  "roleLevel": "Nivel del rol",
  "roleLevelPlaceholder": "Seleccione un nivel",
  "level": "Nivel",
  "permissions": "Permisos",
  "executionHistory": "Histórico de ejecuciones",
  "manageUsers": "Administrar usuarios",
  "name": "Nombre",
  "email": "Correo",
  "role": "Rol",
  "filters": "Filtros",
  "applyFilters": "Aplicar filtros",
  "clearFilters": "Restablecer filtros",
  "modifiedAfter": "Modificado después de",
  "modifiedBefore": "Modificado antes de",
  "searchByTerms": "Buscar por términos",
  "addNewUser": "Añadir nuevo usuario",
  "addUser": "Añadir usuario",
  "editUserInfo": "Editar información de usuario",
  "emailTaken": "El correo ya está en uso",
  "errorCreatingUser": "Ha habido un error al crear el usuario",
  "errorUpdatingUser": "Ha habido un error al actualizar el usuario",
  "exitWithoutSaving": "Salir sin guardar",
  "exitWithoutSavingDescription": "Si sales sin guardar los cambios, los perderás.",
  "changingPasswordDisabledForAdmin": "Solo los usuarios pueden cambiar su contraseña.",
  "passwordWillBeChangedOnFirstLogin": "Se pedirá al usuario que cambie su contraseña la primera vez que se conecte.",
  "changingEmailDisabledForAdmin": "El email del usuario no se puede cambiar porque es su identificador único.",
  "userAddedTemporaryPassword": "El usuario se ha añadido correctamente. Se ha generado una contraseña temporal de un solo uso, por favor, anótala y comunícasela al usuario.",
  "copy": "Copiar",
  "passwordCopiedToClipboard": "La contraseña se ha copiado al portapapeles",
  "passwordCopyError": "No se ha podido copiar la contraseña al portapapeles. Por favor, copia la contraseña manualmente.",
  "mergedFrom": "MERGEADO DESDE: ",
  "noDownloadExecutionPermission": "No puedes descargar esta ejecución porque no tienes permisos para ejecutarla.",
  "sharedAlreadyConnected": "Ya estás conectado/a a esta ejecución compartida desde otro dispositivo",
  "sharedNotConnected": "No estás conectado a ninguna ejecución compartida",
  "sharedUnknownError": "Ha ocurrido un error en la conexión con el servidor",
  "deleteProcedureStatusError": "Solo se puede borrar un procedimiento si su estado es DRAFT, REVISED o APPROVED",
  "deleteProcedureWithExecutionsAdvice": "Este procedimiento tiene ejecuciones, si se borra el procedimiento se borrarán sus ejecuciones. ¿Desea continuar?",
  "deleteProcedureAdvice": "¿Está seguro de borrar el procedimiento?",
  "deleteProcedureError": "Ha habido un error borrando el procedimiento",
  "deleteProcedureConflictDeviceError": "No se puede borrar el procedimiento, porque está descargado en los dispositivos:",
  "deleteProcedureSuccess": "Se ha borrado el procedimiento correctamente",
  "changeStatus": "Cambiar estado",
  "changePassword": "Cambiar contraseña",
  "changePasswordText": "Ingrese su contraseña actual y configure una nueva para usar cuando inicie sesión en TecOS Proceed",
  "currentPassword": "Contraseña actual",
  "repeatNewPassword": "Repite la nueva contraseña",
  "passwordChangedOK": "La contraseña ha sido cambiada correctamente",
  "newPasswordNotEqualError": "Las nuevas contraseñas no coinciden",
  "newPasswordEqualOldPasswordError": "La nueva contraseña debe ser distinta a la antigua",
  "welcomeToTecOSProceed": "Bienvenido a TecOS Proceed",
  "welcomeText": "Has utilizado una contraseña temporal para iniciar sesión por primera vez, ahora tienes que configurar tu contraseña personal con la que iniciarás sesión de aquí en adelante.",
  "saveAndContinue": "Guardar y continuar",
  "skipAndContinue": "Saltar y continuar",
  "yesDelete": "Si, borrar",
  "yes": "Sí",
  "no": "No",
  "previousDataLostTitle": "Se perderán los datos anteriores",
  "previousDataLostWarn": "Si continúa sin ingresar su contraseña anterior, se perderán todos los datos anteriores. ¿Está seguro de que desea continuar?",
  "version": "Versión",
  "clearAll": "Limpiar",
  "reportGeneration": "Generación de informe final",
  "downloadReport": "Descargar informe",
  "exportToPDFFailed": "Se produjo un error durante la generación del documento pdf",
  "generateReport": "Re-generar informe pdf",
  "reportErrorExecutionNotFound": "No se ha encontrado la ejecución",
  "reportErrorReportNotFound": "No se ha encontrado el informe de la ejecución",
  "reportErrorFileNotFound": "No se ha encontrado el archivo del informe de la ejecución",
  "reportErrorExecutionNotFoundDescription": "La ejecución que tienes en tu dispositivo ya no está disponible en el servidor",
  "reportErrorReportNotFoundDescription": "Es posible que el informe de la ejecución no se haya generado correctamente",
  "reportErrorFileNotFoundDescription": "Es posible que el informe de la ejecución no se haya generado correctamente",
  "existOtherProcedurePublished": "Existe una versión de este procedimiento publicada",
  "descriptionWarningPublishingProcedure": "Antes de publicarlo, debe marcar como obsoleto el procedimiento publicado con la misma clave",
  "errorUpdatingProcedure": "Error actualizando procedimiento",
  "errorClonation": "Error de clonación",
  "errorClonationDescription": "Se ha producido un error durante la clonación de la ejecución",
  "successClonation": "Clonación finalizada",
  "successClonationDescription": "La clonación ha sido un éxito",
  "uploadManualExecution": "Subir ejecución manual",
  "addManualExecution": "Añadir ejecución manual",
  "uploadManualExecutionText": "Rellene los datos y adjunte un archivo PDF que contenga la ejecución manual que desea incluir en el administrador de ejecuciones.",
  "executionProcedure": "Procedimiento de la ejecución",
  "user": "Usuario",
  "executionTimes": "Fechas de la ejecución",
  "uploadExecution": "Subir ejecución",
  "onlyPDF": "Solo se aceptan ficheros PDF",
  "proceduresSearchPlaceholder": "Buscar procedimiento por título o clave",
  "usersSearchPlaceholder": "Buscar usuario por nombre",
  "findProcedure": "Buscar procedimiento",
  "findUser": "Buscar usuario",
  "recentProcedures": "Hay una versión más reciente para algunos de tus procedimientos locales. ¿Quieres actualizarlos?",
  "executionProgress": "Progreso de ejecución",
  "signaturesCompleted": "Firmas completadas",
  "recordersNumericalsFilled": "Campos numéricos completados",
  "radioButtonsSelected": "Botones de radio completados",
  "checkboxFilled": "Checkbox completados",
  "optionListsFilled": "Listas de opciones completadas",
  "buttonSave": "Guardar",
  "errorSyncingExecutionTitle": "Error al sincronizar ejecución",
  "errorSyncingExecutionContent": "Ha ocurrido un error desconocido al intentar sincronizar una de tus ejecuciones locales.",
  "errorSyncingExecutionProcedureNotFoundTitle": "Procedimiento eliminado",
  "errorSyncingExecutionProcedureNotFoundContent": "Se está intentando sincronizar una ejecución cuyo procedimiento se ha eliminado en el servidor. La ejecución local y su procedimiento también se eliminarán.",
  "errorSyncingExecutionDownloadedAfterLastDownloadTitle": "Ejecución continuada en otro dispositivo",
  "errorSyncingExecutionDownloadedAfterLastDownloadContent": "Se está intentando sincronizar una ejecución que se ha descargado en otro dispositivo. La ejecución local se eliminará.",
  "minimumThreeCharacters": "Mínimo 3 caracteres",
  "goToComment": "Ir al comentario",
  "backFromComment": "Volver",
  "copyToCustomFolderFailed": "Se produjo un error al copiar el informe. Puede que la ruta personalizada no exista o se esté intentando sobrescribir el informe.",
  "insertDeviceName": "Introduce el nombre del dispositivo",
  "device": "Dispositivo",
  "selectDevice": "Selecciona un dispositivo",
  "downloadedBy": "Descargado por",
  "lastSync": "Última sinc.",
  "changeProposal": "Propuesta de cambio",
  "attachBefore": "Adjuntar al inicio",
  "attachAfter": "Adjuntar al final",
  "confirmDeleteComment": "¿Estás seguro de que quieres borrar el comentario?",
  "confirmDeleteNonCompliantComment": "Esto es un comentario de incumplimiento, ¿Estás seguro de que quieres borrarlo?",
  "attachmentNotFound": "Es necesario adjuntar un pdf",
  "fieldNotSet": "Campo no asignado",
  "invalidAttachment": "El fichero no se ha adjuntado",
  "noCommentsFinishing": "No hay comentarios de Finalización",
  "noCommentsReviewing": "No hay comentarios de Revisión",
  "noCommentsApproving": "No hay comentarios de Aprobación ",
  "advanced": "Avanzado",
  "selectExecutionType": "Cambiar parámetros de ejecución",
  "simpleApprobation": "Aprobación simplificada",
  "customSettingsDetected": "Atención, ejecución personalizada",
  "descriptionModalAdvanced": "Permite modificar las opciones de la ejecución",
  "annotations": "Anotaciones",
  "textAreaMaxRowsError": "Excedido máximo de filas ({maxRows})",
  "placeHolderAnnotation": "Anotaciones a la ejecución. Estas anotaciones se consideran temporales y se borrarán al finalizar la ejecución",
  "validationExactSelection": "Debes seleccionar {num} elemento/s",
  "validationMinimumSelection": "Debes seleccionar al menos {num} elemento/s",
  "validationStatus": "Debes seleccionar elementos con el estado {status}",
  "validationStatusNot": "No debes seleccionar elementos con el estado {status}",
  "validationSameProcedure": "Todas las ejecuciones deben partir del mismo procedimiento",
  "validationNotReviewed": "No debes seleccionar ejecuciones sin revisar",
  "validationNotAlreadyReviewed": "La ejecución ya está revisada",
  "validationCanBeApproved": "La ejecución debe ser revisada antes",
  "validationHasPermission": "No tienes permiso para realizar esta acción",
  "validationNoRealExecution": "Esta ejecución no se llevó a cabo mediante Proceed. Esta acción no está permitida",
  "validationStatusMerge": "Debes seleccionar elementos con el estado INTERRUMPIDO o FINALIZADO",
  "joinSharedExecution": "Uniéndote a una ejecución compartida",
  "chooseExecutionMode": "Elige el modo con el que te unirás a la ejecución",
  "executionModeREAD": "Supervisor",
  "executionModeWRITE": "Ejecución",
  "confirmationDownloadExecutions": "Atención! Al descargar esta ejecución dejará como obsoleta la versión local del usuario propietario",
  "errorGeneratingHTMLReportTitle": "Se produjo un error durante el proceso de aprobación",
  "errorGeneratingHTMLReportContent": "El Informe Final no se ha enviado correctamente.  La ejecución no ha sido aprobada. Por favor, revise su conexión a internet e inténtelo de nuevo.",
  "preparingToPrinting": "Preparando procedimiento para imprimir...",
  "generatingReport": "Generando informe de ejecución...",
  "NONE": "Flexible",
  "STRICT": "Secuencial y exigencia de todas las firmas",
  "MIX": "Flexible y exigencia de todas las firmas",
  "Signature": "firma",
  "Recorder": "campo de texto",
  "Numerical": "campo numérico",
  "MultiCell": "multicelda",
  "OptionList": "lista de opciones",
  "Formula": "fórmula",
  "Jump": "salto interno",
  "Reference": "referencia",
  "FileReference": "referencia de fichero",
  "CheckBox": "checkbox",
  "Date": "fecha",
  "Time": "tiempo",
  "TextArea": "área de texto",
  "checkbox.title": "Checkbox",
  "formula.title": "Fórmula",
  "multicell.title": "Multicelda",
  "numerical.title": "Numérico",
  "option.list.title": "Lista de opciones",
  "recorder.title": "Entrada de texto",
  "signature.title": "Firma",
  "textarea.title": "Área de texto",
  "time.title": "Tiempo",
  "date.title": "Fecha",
  "completed": "Completados",
  "dont.meet.criteria": "No cumple criterio",
  "printingError": "El procedimiento no se puede imprimir.",
  "procedureBadDigitized": "Ha habido un error durante la preparación del procedimiento para impresión. Esto suele deberse a errores durante el proceso de digitalización.",
  "printingErrorDuringApproveReason": "Se produjo un error al preparar el informe para impresión",
  "printingErrorDuringApproveAction": "La ejecución no ha sido aprobada, ya que el informe final no puede generarse. \nPor favor, asegúrse de que la digitalización del procedimiento es correcta.",
  "clear.selection": "Borrar selección",
  "delete.media.confirm.title": "Eliminar archivo de comentario",
  "delete.media.confirm.text": "Vas a eliminar el archivo {name}, pero el comentario no se borrará. ¿Estás seguro?",
  "failed": "Fallido",
  "media.uploading": "Subiendo vídeos",
  "media.uploading.cancel.all": "Cancelar subidas y realizar acción",
  "media.uploading.finish": "Subida finalizada",
  "media.uploading.error": "Subida fallida",
  "media.uploading.offline": "Sin conexión",
  "media.uploading.text": "Hay vídeos subiéndose, toca la notificación para ver más detalles.",
  "media.uploading.warning.title": "Hay vídeos subiéndose",
  "media.uploading.warning.text": "Espera hasta que todos los vídeos se suban antes de salir de la ejecución",
  "media.upload.success": "Vídeos subidos con éxito",
  "media.upload.error": "Error subiendo videos",
  "media.upload.offline": "No hay conexión",
  "media.upload.offline.text": "Necesitas conexión a internet para subir vídeos",
  "media.upload.cancel.title": "Cancelar subida",
  "media.upload.cancel.text": "¿Estás seguro de que quieres cancelar la subida del vídeo {name}? El vídeo no se adjuntará al comentario que has añadido.",
  "media.upload.retry.title": "Reintentar subida",
  "media.upload.retry.text": "Ha ocurrido un error en la subida del vídeo {name}. Puedes volver a intentarlo o cancelar la subida. Si cancelas, el vídeo no se adjuntará al comentario.",
  "media.size.too.large.title": "Archivo demasiado grande",
  "media.size.too.large.text": "El archivo {name} tiene un tamaño de {fileSize}MB que supera el tamaño máximo permitido de {maxFileSize}MB, de modo que no puede ser adjuntado al comentario.",
  "media.pdf.tab.title": "Abrir pdf en nueva pestaña",
  "media.pdf.tab.text": "Se va a abrir otra pestaña para visualizar el pdf, una vez terminado, tendrá que volver para continuar. ¿Quiere continuar?",
  "show.details": "Ver detalles",
  "comment.step.level": "Comentarios de paso",
  "comment.execution.level": "Comentarios de ejecución",
  "retry": "Reintentar",
  "total.elements": "Total de elementos",
  "total.elements.selected": "Seleccionados",
  "not.support.video.tag": "Tu navegador no soporta la reproducción de este vídeo.",
  "error.change.procedures.status.title": "Error actualizando estado de procedimientos",
  "error.change.procedures.status.text": "Se ha producido un error cambiando el estado de los procedimientos que has seleccionado.",
  "error.change.status.same.keys.to.published": "Solo puede haber un procedimiento con la misma clave en estado publicado",
  "change.status.modal.text": "Selecciona el nuevo estado al que transicionarán los procedimientos seleccionados.",
  "change.status.modal.not.complatible": "Los procedimientos seleccionados no tiene un estado común al que poder transicionar.",
  "select.placeholder": "Selecciona una opción...",
  "execution.needs.review.title": "La ejecución debe ser revisada",
  "execution.needs.review.delegated.signatures.text": "La ejecución contiene firmas delegadas, por lo que debe de ser revisada antes de su aprobación.",
  "user.reset.password.title": "Restablecer contraseña",
  "user.reset.password.text": "Puedes restablecer una nueva contraseña para los usuarios que la hayan olvidado.",
  "user.reset.password.error": "No se ha podido generar una nueva contraseña para el usuario.",
  "user.password.title": "Contraseña de usuario",
  "user.password.text": "Una vez establecida la contraseña por primera vez, sólo el usuario puede cambiarla.",
  "login.forgot.password": "¿Has olvidado tu contraseña?",
  "login.forgot.password.tooltip": "Póngase en contacto con su administrador por teléfono o correo electrónico para restablecer su contraseña.",
  "abort.execution.disabled.steps.progress": "No se puede abortar una ejecución con pasos en curso o espera",
  "stop.execution.disabled.steps.progress": "No se puede finalizar una ejecución con pasos en curso o espera",
  "stop.execution.disabled.not.finished": "No se puede finalizar una ejecución con pasos sin firmar, en curso o espera",
  "stop.execution.disabled.no.execution": "No se ha identificado ejecución que finalizar",
  "stop.execution.disabled.can.not.status": "La ejecución no puede cambiarse a este estado",
  "stop.execution.disabled.is.working": "La ejecución no puede finalizar hasta que no esté guardada",
  "stop.execution.disabled.media.uploading": "La ejecución no puede finalizar ya que hay videos subiéndose",
  "permission.route.procedure": "Acceder a la vista de Procedimiento.",
  "permission.route.execution": "Acceder a la vista de Ejecución",
  "permission.route.home.title": "Mi trabajo para hoy",
  "permission.route.home.description": "Visualizar las tareas propias y del equipo para el día de hoy",
  "permission.route.procedure.title": "Procedimientos del dispositivo",
  "permission.route.procedure.description": "Descargar y visualizar procedimientos en el dispositivo",
  "permission.route.procedure.download.title": "Descargar procedimientos",
  "permission.route.procedure.download.description": "Descargar procedimientos disponibles",
  "permission.route.procedure.showStatuses.title": "Mostrar procedimientos en todos los estados",
  "permission.route.procedure.showStatuses.description": "Visualizar procedimientos en cualquier estado",
  "permission.route.procedure.version.title": "Mostrar versión del procedimiento",
  "permission.route.procedure.version.description": "Visualizar la versión del procedimiento",
  "permission.route.execution.title": "Ejecuciones del dispositivo",
  "permission.route.execution.description": "Descargar y realizar ejecuciones en el dispositivo",
  "permission.route.execution.download.title": "Descargar ejecuciones de otros usuarios",
  "permission.route.execution.download.description": "Descargar ejecuciones realizadas por otros usuarios",
  "permission.route.execution.keepExecutions.title": "Mantener ejecuciones",
  "permission.route.execution.keepExecutions.description": "Mantener ejecuciones locales finalizadas o abortadas",
  "permission.route.execution.management.title": "Administrar ejecuciones",
  "permission.route.execution.management.description": "Gestionar las acciones sobre las ejecuciones realizadas",
  "permission.route.execution.management.merge.title": "Merge",
  "permission.route.execution.management.merge.description": "Unión de dos ejecuciones interrummpidas o finalizadas",
  "permission.route.execution.management.review.title": "Revisión",
  "permission.route.execution.management.review.description": "Revisión de ejecuciones finalizadas",
  "permission.route.execution.management.approve.title": "Aprobación",
  "permission.route.execution.management.approve.description": "Aprobación de ejecuciones finalizadas",
  "permission.route.execution.management.view.title": "Ver",
  "permission.route.execution.management.view.description": "Visualización de ejecuciones finalizadas",
  "permission.route.execution.management.resume.title": "Reanudar",
  "permission.route.execution.management.resume.description": "Reanudación de ejecuciones rechazadas",
  "permission.route.execution.management.upload.title": "Subir ejecución manual",
  "permission.route.execution.management.upload.description": "Carga manual de una ejecución finalizada",
  "permission.route.executionHistory.title": "Histórico de ejecuciones",
  "permission.route.executionHistory.description": "Listado histórico de todas las ejecuciones aprobadas",
  "permission.route.executionHistory.view.title": "Ver",
  "permission.route.executionHistory.view.description": "Visualizar ejecuciones aprobadas",
  "permission.route.executionHistory.regenerate.title": "Regenerar informes",
  "permission.route.executionHistory.regenerate.description": "Regenerar informe para ejecuciones aprobadas",
  "permission.route.adminProcedures.title": "Administrar procedimientos",
  "permission.route.adminProcedures.description": "Gestión de procedimientos digitalizados",
  "permission.route.adminProcedures.export.title": "Exportar procedimientos",
  "permission.route.adminProcedures.export.description": "Exportar procedimientos del servidor",
  "permission.route.adminProcedures.import.title": "Importar procedimientos",
  "permission.route.adminProcedures.import.description": "Importar procedimientos al servidor",
  "permission.route.adminProcedures.delete.title": "Borrar procedimientos",
  "permission.route.adminProcedures.delete.description": "Borrar procedimientos del servidor",
  "permission.route.adminProcedures.changeStatus.title": "Cambiar el estado del procedimiento",
  "permission.route.adminProcedures.changeStatus.description": "Cambiar el estado de cada procedimiento",
  "permission.route.controlPanel.title": "Panel de control",
  "permission.route.controlPanel.description": "Configuración y gestión de usuarios",
  "permission.route.executionView.title": "Vista de ejecución",
  "permission.route.executionView.description": "Visualizar procedimiento antes de su ejecución",
  "permission.route.executionView.changeParams.title": "Cambiar parámetros de ejecución",
  "permission.route.executionView.changeParams.description": "Configurar los parámetros de una ejecución",
  "permission.route.executionView.start.title": "Iniciar una ejecución",
  "permission.route.executionView.start.description": "Iniciar una nueva ejecución de un procedimiento",
  "button.add.comment.disabled": "Añade texto para poder agregar el comentario",
  "button.add.comment.execution.disabled": "Añade texto y selecciona el tipo de comentario para poder agregar el comentario",
  "error.execution.start": "Ha ocurrido un error en el inicio de la ejecución. Por favor, vuelva a intentarlo y si el error persiste comnuníquelo",
  "error.execution.start.manual.delete": "Ha ocurrido un error en el inicio de la ejecución. Por favor, borre manualmente la ejecución que acaba de crear e inténtelo de nuevo. Si el error persiste comuníquelo.",
  "error.execution.start.maybe.manual.delete": "Ha ocurrido un error en el inicio de la ejecución. Por favor, si la ejecución acaba de ser creada bórrela manualmente e inténtelo de nuevo. Si el error persiste comuníquelo.",
  "error.execution.save.data": "Ha ocurrido un error guardando la información. Por favor, si el error persiste repórtelo o borre la ejecución problemática y recargue la aplicación para volver a intentarlo.",
  "error.cancel.merge.title": "Error cancelando merge",
  "error.cancel.merge.description": "Ha ocurrido un error cancelando el merge, por favor inténtelo de nuevo.",
  "print.needs.connection": "Se necesita conexión para imprimir",
  "config.tab.roles.permissions": "Roles y permisos",
  "config.tab.series.departments": "Series y departamentos",
  "config.tab.execution.goals": "Motivos de ejecución",
  "config.tab.customer.settings.unit": "Cliente o Planta",
  "config.series.title": "Series",
  "config.department.button": "Crear departamento",
  "config.department.delete.popover": "¿Está seguro de borrar esta departamento?",
  "config.department.edit.modal.action": "Editar departamento",
  "config.departments.title": "Departamentos",
  "config.department.title": "Departamento",
  "config.plant.delete.popover": "¿Está seguro de que desea borrar el cliente o planta?",
  "config.unit.delete.popover": "¿Está seguro de que desea borrar la unidad?",
  "config.unit.column.name": "Nombre de la unidad",
  "config.unit.column.default": "Por defecto",
  "config.unit.column.options": "Opciones",
  "departments.no.serie": "No hay series asignadas ¿Desea asignarlas ahora?",
  "series.delete.error": "Ha ocurrido un error borrando la serie",
  "series.delete.error.conflict.procedure": "La serie no se pudo eliminar debido a que está asignada a uno o más procedimientos",
  "series.delete.success": "Serie borrada con éxito",
  "series.create.success": "Serie creada con éxito",
  "series.create.error": "Ha ocurrido un error creando la serie",
  "series.edit.success": "Serie editada con éxito",
  "series.edit.error": "Ha ocurrido un error editando la serie",
  "series.conflict.name.error": "El nombre de la serie ya existe. Por favor, elija uno diferente.",
  "series.no.name.error": "Tiene que asignarle un nombre a la serie",
  "department.successMsg": "Departamento guardado con éxito.",
  "department.errorMsg": "Ha ocurrido un error guardando el departamento.",
  "department.edit.successMsg": "Departamento modificado con éxito.",
  "department.edit.errorMsg": "Ha ocurrido un error modificando el departamento.",
  "department.delete.successMsg": "Departamento borrado con éxito.",
  "department.delete.failMsg": "Ha ocurrido un error borrando el departamento.",
  "department.conflict.name.error": "El nombre del departamento ya existe. Por favor, elija uno diferente.",
  "unit.add.successMsg": "Unidad guardada con éxito.",
  "unit.add.errorMsg": "Ha ocurrido un error guardando la unidad.",
  "unit.edit.successMsg": "Unidad modificada con éxito.",
  "unit.edit.errorMsg": "Ha ocurrido un error modificando la unidad.",
  "unit.delete.successMsg": "Unidad borrada con éxito.",
  "unit.delete.failMsg": "Ha ocurrido un error borrando la unidad.",
  "unit.conflict.name.error": "El nombre de la unidad ya existe. Por favor, elija uno diferente.",
  "unit.delete.error.conflict.procedure": "La unidad está asignada a un procedimiento y no se puede borrar.",
  "unit.delete.error.conflict.default": "No es posible borrar la unidad por defecto.",
  "unit.no.name.error": "El nombre de la unidad es obligatorio",
  "plant.conflict.name.error": "El nombre del cliente o planta ya existe. Por favor, elija uno diferente.",
  "plant.add.successMsg": "Cliente o planta guardada con éxito ",
  "plant.add.errorMsg": "Ha ocurrido un error añadiendo un cliente o planta",
  "plant.edit.successMsg": "Planta o unidad modificada con éxito",
  "plant.edit.errorMsg": "Ha ocurrido un error editando el cliente o planta",
  "plant.delete.failMsg": "Ha ocurrido un error borrando el cliente o planta",
  "plant.delete.sucessMsg": "Planta o cliente borrado con éxito",
  "plants.no.default.unit": "Unidad por defecto sin asignar",
  "plant.no.name.error": "El nombre del cliente o planta es obligatorio",
  "plant.no.units": "(sin unidades)",
  "plant.add.error.conflict": "El nombre del cliente o planta ya existe.",
  "plant.delete.error.conflict.default": "No es posible borrar el cliente o planta que contiene la unidad por defecto.",
  "plant.delete.error.conflict.procedure": "El cliente o planta tiene una unidad asignada a un procedimiento y no se puede borrar.",
  "forcible.conditionals.tab.title": "Pasos habilitables por condición",
  "forcible.conditionals.tab.text": "Los siguientes pasos del procedimiento se han visto afectados por un condicional. Active o desactive el condicional en función de sus necesidades.",
  "forcible.conditionals.step.type.title.positive": "Habilitados si condicional activado",
  "forcible.conditionals.step.type.title.negative": "Habilitados si condicional desactivado",
  "execution.info.tab.title": "Información",
  "conditional": "Condicional",
  "force.contional.true.title": "Condicional activado",
  "force.contional.false.title": "Condicional desactivado",
  "force.contional.null.title": "Por defecto",
  "readout.menu.editMode.text": "Editar campos Readout",
  "readout.editMode.message": "Seleccione un componente Readout para editar",
  "readout.menu.lockValueMode.text": "Bloquear valores Readout",
  "readout.menu.lockValueMode.message": "Pulse sobre los campos para bloquear/desbloquear",
  "readout.editedBy.text": "Editado por",
  "error.login.unknown": "Se ha producido un error desconocido al tratar de iniciar sesión. Por favor, inténtelo de nuevo y si persiste, comuníquelo.",
  "historical.title": "Histórico",
  "elements.count": "elementos",
  "element.count": "elemento",
  "historical.table.value.column": "Valor",
  "historical.table.timestamp.column": "Última modificación",
  "historical.table.rev.column": "Rev.",
  "save.historical.values.error": "Ha ocurrido un error guardando los valores históricos del procedimiento"
}