import { readPlantByName } from '../../../localdb/units/plants/read'
import { PlantHasNoName, PlantNameConflict } from '../../../utils/errors/plants'

export async function checkIfPlantExists(customerSetting) {
  const plant = await readPlantByName(customerSetting.name)

  if (plant?.length > 0) {
    throw new PlantNameConflict()
  }
}

export async function validateCustomerSetting(customerSetting) {
  if (!customerSetting.name?.trim()) {
    throw new PlantHasNoName()
  }

  return checkIfPlantExists(customerSetting)
}
