import React from 'react'

import { IconContainer } from '../../../containers/icon'

const Caret = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/back'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id='Path-2'
        transform='translate(6.310125, 0.750000)'
        stroke={color}
        strokeWidth='2'
      >
        <path
          d='M10.9398752,22.5 L0.21987516,11.78 C-0.0731248397,11.488 -0.0731248397,11.013 0.21887516,10.721 C0.21887516,10.721 0.21987516,10.72 0.21987516,10.72 L10.9398752,0'
          id='Path'
        ></path>
      </g>
    </g>
  </svg>
)

export default Caret
