import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { getUser } from '../store/user-management/selectors'

const useRouteRedirect = (routes, allowedRoutes, parentRoute) => {
  const location = useLocation()
  const history = useHistory()
  const user = useSelector(getUser)

  useEffect(() => {
    /**
     * Verifica que la location actual existe dentro de las rutas definidas para el router
     * Es necesario verificarlo con el parentRoute para que no se ejecute el efecto
     */
    const currentRoute = routes.find(
      (_route) => `${parentRoute}${_route.path}` === location.pathname
    )
    const routeToRedirect = `${parentRoute}${allowedRoutes[0]?.path}`

    if (currentRoute && routeToRedirect !== location.pathname) {
      const routeIsAllowed = allowedRoutes.find(
        (_allowed) => _allowed.key === currentRoute?.key
      )

      if (!routeIsAllowed || routeIsAllowed.skipPermission) {
        history.push(routeToRedirect)
      }
    }
  }, [allowedRoutes, history, location.pathname, parentRoute, routes, user])
}

export default useRouteRedirect
