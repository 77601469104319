import { getTranslation } from '../../../i18n/getTranslation'
import { writeNewUnit } from '../../../localdb/units/write'
import { addNewUnit } from '../../../services/unit/http/post'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { notifyError } from '../../../utils/notifications'
import { validateUnit } from '../validation'

export async function addUnit(unit, reload) {
  try {
    await validateUnit(unit)
    const { data } = await addNewUnit(unit)
    const { plant, ...rest } = data
    await writeNewUnit({
      ...rest,
      plantId: plant?.id,
      plantName: plant?.name
    })
    reload()
    notificationMessage({
      message: getTranslation('unit.add.successMsg')
    })
  } catch (error) {
    notifyError(error)
  }
}
