import React from 'react'

const User = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 1.5C9.93267 1.5 11.5 3.06733 11.5 5C11.5 6.93267 9.93267 8.5 8 8.5C6.06733 8.5 4.5 6.93267 4.5 5C4.5 3.06733 6.06733 1.5 8 1.5Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.5 16.5C1.5 12.91 4.41 10 8 10C11.59 10 14.5 12.91 14.5 16.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default User
