import React from 'react'
import { useHistory } from 'react-router-dom'

import Download from '../../../../components/icons/download'
import Eye from '../../../../components/icons/eye'
import { generatePDFReport } from '../../../../services/execution/report/http/post'
import { EXECUTION_STATUS } from '../../../../utils/constants/execution'
import { PERMISSION_TYPES } from '../../../../utils/constants/roles'
import {
  validationExactSelection,
  validationNoRealExecution,
  validationStatus,
  validationUserHasPermission
} from '../../../../utils/helpers/validations/table-footer'
import TableFooter from '../../../../views/tables/footer'

const view = async ({ selection, history }) => {
  const [{ id }] = selection

  history.push({
    pathname: `/view/${id}`
  })
}

const generateSelectedReport = async ({ selection }) => {
  const execution = selection[0]

  await generatePDFReport(execution)
}

export const Footer = ({ selection }) => {
  const history = useHistory()

  return (
    <TableFooter
      buttons={[
        {
          id: 'view-button',
          translationId: 'view',
          icon: <Eye />,
          onClick: view,
          validations: [
            validationUserHasPermission(
              PERMISSION_TYPES.EXECUTION_HISTORY_VIEW
            ),
            validationStatus(EXECUTION_STATUS.FINISHED),
            validationExactSelection(1),
            validationNoRealExecution()
          ]
        },
        {
          id: 'generate-report-button',
          translationId: 'generateReport',
          icon: <Download />,
          onClick: generateSelectedReport,
          validations: [
            validationUserHasPermission(
              PERMISSION_TYPES.EXECUTION_HISTORY_REGENERATE
            ),
            validationStatus(EXECUTION_STATUS.FINISHED),
            validationExactSelection(1),
            validationNoRealExecution()
          ]
        }
      ]}
      parameters={{
        selection,
        history
      }}
    />
  )
}
