import React, { useEffect, useState } from 'react'


import { getTranslation } from '../../../i18n/getTranslation'
import { deleteLocalExecutionGoal } from '../../../localdb/execution-goal/delete'
import { readExecutionGoals } from '../../../localdb/execution-goal/read'
import { updateLocalExecutionGoal } from '../../../localdb/execution-goal/update'
import { writeExecutionGoal } from '../../../localdb/execution-goal/write'
import {
  addNewExecutionGoal,
  deleteExecutionGoal,
  editExecutionGoal
} from '../../../services/execution-goal'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import EditableList from '../../editable-list'

const ExecutionGoals = () => {
  const [goalsList, setGoalsList] = useState([])

  const loadExecutionGoals = async () => {
    const executionGoals = await readExecutionGoals()
    setGoalsList(executionGoals)
  }

  const modifyExecutionGoal = async (executionGoalData) => {
    await editExecutionGoal(executionGoalData)
    await updateLocalExecutionGoal(executionGoalData)
    notificationMessage({
      message: getTranslation('execution.goal.edit.successMsg')
    })
    loadExecutionGoals()
  }

  const addExecutionGoal = async (executionGoalData) => {
    const { data } = await addNewExecutionGoal(executionGoalData)
    await writeExecutionGoal(data)
    notificationMessage({
      message: getTranslation('execution.goal.successMsg')
    })
    loadExecutionGoals()
  }

  const removeExecutionGoal = async (goalData) => {
    try {
      await deleteExecutionGoal(goalData.id)
      await deleteLocalExecutionGoal(goalData.id)
      notificationMessage({
        message: getTranslation('execution.goal.delete.successMsg')
      })
      loadExecutionGoals()
    } catch (error) {
      notificationMessage({
        type: 'error',
        message: getTranslation('execution.goal.delete.failMsg')
      })
    }
  }

  useEffect(() => {
    loadExecutionGoals()
  }, [])

  return (
    <EditableList
      itemsList={goalsList}
      listTitle='executionGoals'
      itemTitle='executionGoal'
      submitListItem={addExecutionGoal}
      editListItem={modifyExecutionGoal}
      deleteListItem={removeExecutionGoal}
    />
  )
}

export default ExecutionGoals
