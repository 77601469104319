import { createSelector } from '@reduxjs/toolkit'

const getSocketReadoutStore = (state) => state.socketReadout

export const getIsReadoutSubscribed = createSelector(
  getSocketReadoutStore,
  (store) => store?.isReadoutSubscribed
)

export const getIsLiveData = createSelector(
  getSocketReadoutStore,
  (store) => store.isLiveData
)
