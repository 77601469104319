import { createSlice } from '@reduxjs/toolkit'

import { addToLocaldbQueue, deleteFromLocaldbQueue } from '../helpers'

export const executionManeuversSlice = createSlice({
  name: 'executionManeuvers',
  initialState: {
    maneuvers: null,
    lastSave: null,
    localdbQueue: []
  },
  reducers: {
    setExecutionManeuvers: (state, action) => {
      const { maneuvers } = action.payload
      state.maneuvers = maneuvers
      state.lastSave = maneuvers ? new Date(Date.now() + 1000).toString() : null
    },
    setManeuverState: (state, action) => {
      const { active, id, now } = action.payload
      const maneuver = state.maneuvers.find((m) => m.id === id)

      if (maneuver) {
        maneuver.active = active
        maneuver.updatedAt = now
      }
    },
    updateLocaldbQueue: (state, action) => {
      const { maneuver, resetQueue } = action.payload

      if (resetQueue) {
        state.localdbQueue = []
      }

      if (Array.isArray(maneuver)) {
        maneuver.forEach((m) => addToLocaldbQueue(state, m))
        return
      }

      addToLocaldbQueue(state, maneuver)
    },
    removeLocaldbQueue: (state, action) => {
      const { entitiesToDelete } = action.payload
      deleteFromLocaldbQueue(state, entitiesToDelete)
    },
    setLastSave: (state, action) => {
      state.lastSave = action.payload || new Date().valueOf()
    },
    setSyncedAt: (state, action) => {
      const { entities, syncedAt } = action.payload

      state.maneuvers
        ?.filter((m) => entities.some((e) => e.id === m.id))
        .forEach((m) => {
          m.syncedAt = syncedAt
        })
    }
  }
})

export const {
  setExecutionManeuvers,
  setManeuverState,
  setLastSave,
  setSyncedAt,
  removeLocaldbQueue,
  updateLocaldbQueue
} = executionManeuversSlice.actions

export default executionManeuversSlice.reducer
