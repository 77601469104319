import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { throttle } from 'throttle-debounce'

import { getUser } from '../../../../../store/user-management/selectors'
import {
  EXECUTION_SIGNATURE_BUTTON_ID,
  EXECUTION_SIGNATURE_STATUS_ICON
} from '../../../../../utils/constants/execution/components'
import { EXECUTION_SIGNATURE_STATUS_COLOR } from '../../../../../utils/constants/styles'
import DeleteIcon from '../../../../icons/delete'
import ConfirmPopover from '../../../../popover/confirm'

const throttleHideConfirmPopover = throttle(100, (setVisible) => {
  setVisible(false)
})
const SignatureStatusButton = ({
  onComponentChange,
  component,
  signatureValue,
  status,
  doubleCheckSignature,
  signatureCodeUser,
  setSignatureCodeUser
}) => {
  const [confirmVisible, setConfirmVisible] = useState(false)
  const { email: userEmail, initials: userInitials } = useSelector(getUser)
  const Icon = EXECUTION_SIGNATURE_STATUS_ICON[status] || DeleteIcon

  useEffect(() => {
    // Cerramos el popover de confirmación cuando el usuario hace scroll para evitar que se queden flotando
    if (doubleCheckSignature) {
      document
        .querySelector('#procedure-scroll')
        ?.addEventListener('scroll', () => {
          // Todos los botones del estado de la firma llaman a la vez al throttle, por esto solo funciona el primer botón y el último.
          // Comprobando si ya está visible solo aplicamos el throttle al que está visible (solo se llama una vez)
          if (confirmVisible) {
            throttleHideConfirmPopover(setConfirmVisible)
          }
        })
    }

    return () => {
      document
        .querySelector('#procedure-content')
        ?.removeEventListener('scroll', throttleHideConfirmPopover)
    }
  }, [setConfirmVisible, doubleCheckSignature, confirmVisible])

  const onSignatureChange = (updatedConfirmVisible) => {
    const value = signatureCodeUser
      ? {
          ...signatureValue,
          status,
          supervisor: signatureCodeUser.supervisor,
          code: signatureCodeUser.code,
          supervisorEmail: signatureCodeUser.email,
          supervisorInitials: signatureCodeUser.initials,
          user: userEmail,
          initials: userInitials
        }
      : {
          ...signatureValue,
          status,
          user: userEmail,
          initials: userInitials
        }

    onComponentChange({
      id: component.id,
      value
    })
    setSignatureCodeUser(null)
    updatedConfirmVisible !== undefined &&
      setConfirmVisible(updatedConfirmVisible)
  }

  return (
    <ConfirmPopover
      onConfirm={() => onSignatureChange(false)}
      onCancel={() => setConfirmVisible(false)}
      visible={doubleCheckSignature && confirmVisible}
      onVisibleChange={setConfirmVisible}
    >
      <CircleButton
        data-confirm={doubleCheckSignature}
        id={EXECUTION_SIGNATURE_BUTTON_ID[status] || 'clean-signature-button'}
        color={EXECUTION_SIGNATURE_STATUS_COLOR[status] || 'transparent'}
        onClick={() =>
          doubleCheckSignature ? setConfirmVisible(true) : onSignatureChange()
        }
      >
        <Icon />
      </CircleButton>
    </ConfirmPopover>
  )
}

const CircleButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: none;
  background-color: ${({ color }) => color};
  margin: 0 5px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
`

export default SignatureStatusButton
