export const WS_UPDATE = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  EXECUTION_UPDATE: 'EXECUTION_UPDATE',
  SOCKET_ERROR: 'SOCKET_ERROR'
}

export const WS_READOUT_EVENT = {
  UNSUSCRIBE: 'UNSUSCRIBE',
  SUSCRIBE: 'SUSCRIBE',
  NEW_DATA: 'NEW_DATA'
}
