import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getExecutionManeuvers } from '../../../../store/executionManeuvers/selectors'
import { getProcedure } from '../../../../store/procedure/selectors'
import { getConnectedUsers } from '../../../../store/sharedExecution/selectors'
import { SEQUENTIAL_MODE } from '../../../../utils/constants/execution'
import { EXECUTION_COMPONENT_TYPES } from '../../../../utils/constants/execution/components'
import { bmkIsMinorThan, sortBookmarks } from '../../../../utils/helpers/steps'
import ProcedureStepPortal from '../portal'

const Step = ({
  step,
  maneuverParentSteps,
  maneuverDisabledSteps,
  conditionalDisabledSteps,
  executionComponents,
  executionSequentialMode,
  loadingPrint,
  onSelectStep
}) => {
  const procedure = useSelector(getProcedure)
  const executionManeuvers = useSelector(getExecutionManeuvers)
  const connectedUsers = useSelector(getConnectedUsers)

  useEffect(() => {
    document.querySelector('body').style.cursor = 'default'

    return () => {
      document.querySelector('body').style.cursor = 'default'
    }
  }, [])

  const isSequential = executionSequentialMode === SEQUENTIAL_MODE.STRICT

  const components = useMemo(
    () =>
      procedure.components
        .filter((component) => component.stepId === step.stepId)
        .map((component) => ({
          ...component,
          procedureComponentId: component.id
        })),
    [procedure.components, step.stepId]
  )

  const executionManeuver = useMemo(() => {
    return executionManeuvers?.find((m) => m.stepId === step.stepId)
  }, [executionManeuvers, step.stepId])

  const isManeuverChild = useMemo(() => {
    return procedure.maneuvers.some((m) =>
      m.steps.some((s) => s.step === step.stepId)
    )
  }, [procedure.maneuvers, step.stepId])

  const isDisabledBecauseIsSecuential = useMemo(() => {
    if (!isSequential) return false

    const previousSignatures = procedure.components
      .filter(
        (component) => component.type === EXECUTION_COMPONENT_TYPES.SIGNATURE
      )
      .sort((componentA, componentB) =>
        sortBookmarks(componentA.stepId, componentB.stepId)
      )
      .filter((component) => {
        if (bmkIsMinorThan(component.stepId, step.stepId)) return component
      })

    return !previousSignatures.every((previousSignature) => {
      const disabledByConditional = conditionalDisabledSteps?.includes(
        previousSignature.stepId
      )
      const disabledByManeuver = maneuverDisabledSteps?.includes(
        previousSignature.stepId
      )

      const previousSignatureValue = executionComponents?.find(
        (e) => e.procedureComponentId === previousSignature.id
      )?.value

      return (
        !!previousSignatureValue?.status ||
        disabledByConditional ||
        disabledByManeuver
      )
    })
  }, [
    isSequential,
    procedure.components,
    step.stepId,
    conditionalDisabledSteps,
    maneuverDisabledSteps,
    executionComponents
  ])

  const componentsThatAffectUsersInConditionals = useMemo(() => {
    if (!connectedUsers?.length) {
      return []
    }

    const usedSteps = connectedUsers?.map((u) => u.currentStep)

    return procedure.conditionals
      .map((c) => {
        const componentIds = c.items?.map((i) => i.element)
        const conditionalSteps = c.steps?.map((s) => s.stepId)
        return conditionalSteps.some((cStep) => usedSteps.includes(cStep))
          ? componentIds
          : []
      })
      .flat()
  }, [connectedUsers, procedure.conditionals])

  const componentsThatAffectUsersInConditionalsForThisStep = useMemo(
    () =>
      componentsThatAffectUsersInConditionals.filter(
        (cc) =>
          procedure.components.find((pc) => pc.id === cc).stepId === step.stepId
      ),
    [componentsThatAffectUsersInConditionals, procedure.components, step.stepId]
  )

  const disabled =
    isDisabledBecauseIsSecuential ||
    conditionalDisabledSteps?.includes(step.stepId) ||
    maneuverDisabledSteps?.includes(step.stepId)

  return (
    <ProcedureStepPortal
      procedureStep={step}
      usedBy={connectedUsers?.find((u) => u.currentStep === step.stepId)}
      components={components}
      procedure={procedure}
      disabled={disabled}
      isManeuverParent={maneuverParentSteps?.includes(step.stepId)}
      isManeuverChild={isManeuverChild}
      isManeuverActive={executionManeuver?.active}
      maneuverId={executionManeuver?.id}
      componentsThatAffectUsersInConditionals={
        componentsThatAffectUsersInConditionalsForThisStep
      }
      loadingPrint={loadingPrint}
      onSelectStep={onSelectStep}
    />
  )
}

export default Step
