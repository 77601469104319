import { readProcedure } from '../../localdb/procedures/read'

import { setProcedure } from './slice'

export function loadProcedure(procedureId) {
  return async (dispatch) => {
    if (!procedureId) {
      dispatch(setProcedure(null))
    } else {
      const procedure = await readProcedure(procedureId)
      dispatch(setProcedure(procedure))
    }
  }
}
