import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getExecutionManeuvers } from '../../../store/executionManeuvers/selectors'
import { getProcedure } from '../../../store/procedure/selectors'
import { createHtmlOfWarnings } from '../../../utils/helpers/html-maker'
import { sortBookmarks } from '../../../utils/helpers/steps'
import Translation from '../../translations'
import ModalMandatoryReading from '../modal-mandatory-reading'

function getTexts(text) {
  return <div dangerouslySetInnerHTML={{ __html: text }} />
}

function getManeuversWarningsTexts(procedure, executionManeuvers) {
  const activeManeuvers = procedure.maneuvers.filter((maneuver) =>
    executionManeuvers.find((m) => m.stepId === maneuver.parentStep && m.active)
  )

  if (
    procedure.html?.length &&
    activeManeuvers?.length &&
    activeManeuvers.some((m) => m.warningText)
  ) {
    const sortedManeuverWarningsText = activeManeuvers
      ?.sort((manA, manB) => sortBookmarks(manA.parentStep, manB.parentStep))
      .map((maneuver) => maneuver.warningText || '')
      .reduce((prev, curr) => `${prev} <hr> ${curr}`)
    return getTexts(
      createHtmlOfWarnings(procedure.html, sortedManeuverWarningsText)
    )
  } else {
    return null
  }
}

const InitialPrecautionsModal = ({
  modalVisible,
  setModalVisible,
  onAccept
}) => {
  const procedure = useSelector(getProcedure)
  const executionManeuvers = useSelector(getExecutionManeuvers)
  const [initialPrecautionsElement, setInitialPrecautionsElement] =
    useState(null)
  const [maneuverWarningsElement, setManeuverWarningsElement] = useState(null)
  const [modalPages, setModalPages] = useState([])

  useEffect(() => {
    if (executionManeuvers) {
      let html = getManeuversWarningsTexts(procedure, executionManeuvers)
      setManeuverWarningsElement(html)
    }
  }, [procedure, executionManeuvers])

  useEffect(() => {
    if (procedure.initialWarnings?.text) {
      const htmlOfWarnings = createHtmlOfWarnings(
        procedure.html,
        procedure.initialWarnings?.text
      )

      const texts = getTexts(htmlOfWarnings)

      setInitialPrecautionsElement(texts)
    }
  }, [procedure])

  useEffect(() => {
    let pages = []

    if (initialPrecautionsElement) {
      pages.push({
        title: <Translation id={'initial_precautions'}></Translation>,
        content: initialPrecautionsElement
      })
    }

    if (maneuverWarningsElement) {
      pages.push({
        title: <Translation id={'maneuver_warnings'}></Translation>,
        content: maneuverWarningsElement
      })
    }

    setModalPages(pages)
  }, [initialPrecautionsElement, maneuverWarningsElement])

  return (
    modalVisible && (
      <ModalMandatoryReading
        modalHeight={400}
        modalWidth={'80vw'}
        pages={modalPages}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        showCancelButton={false}
        okButtonTextId='continue'
        onAccept={onAccept}
      />
    )
  )
}

export default InitialPrecautionsModal
