import { Select as SelectAntd } from 'antd'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { PROCEDURE_STATUS_CHANGES } from '../../utils/constants/procedure'
import BaseTable from '../tables/base-table'
import Translation from '../translations'


import { columns } from './data'


const { Option } = SelectAntd

function ChangeStatus({
  selectedProcedures,
  errorSameKeysToPublished,
  newStatus,
  setNewStatus
}) {
  const availableStatus = useMemo(() => {
    const allStatus = selectedProcedures.map((procedure) => procedure.status)
    const uniqueStatus = [...new Set(allStatus)]

    let available = [...PROCEDURE_STATUS_CHANGES[uniqueStatus[0]]]

    uniqueStatus.forEach((status) => {
      available = available.filter((_status) =>
        PROCEDURE_STATUS_CHANGES[status].includes(_status)
      )
    })

    return available
  }, [selectedProcedures])

  return (
    <Container>
      {availableStatus.length === 0 ? (
        <strong>
          <Translation id='change.status.modal.not.complatible' />
        </strong>
      ) : (
        <Translation id='change.status.modal.text' />
      )}

      <SelectContainer>
        <Select
          autoFocus
          value={newStatus}
          placeholder={<Translation id='select.placeholder' />}
          onChange={(value) => setNewStatus(value)}
        >
          {availableStatus.map((status) => (
            <Option key={status} value={status}>
              <Translation id={status} />
            </Option>
          ))}
        </Select>
      </SelectContainer>
      {errorSameKeysToPublished ? (
        <Error>
          <Translation id='error.change.status.same.keys.to.published' />
        </Error>
      ) : null}
      <TableContainer>
        <BaseTable columns={columns} dataSource={selectedProcedures} />
      </TableContainer>
    </Container>
  )
}

const Container = styled.div``

const TableContainer = styled.section`
  margin-top: 20px;

  & .ant-table-container {
    max-height: 400px !important;
  }
`

const SelectContainer = styled.section`
  margin: 10px 0;
`

const Select = styled(SelectAntd)`
  width: 100%;
`

const Error = styled.span`
  color: #ed1c24;
  font-size: 14px;
`

export default ChangeStatus
