import { deleteFilesAndContentLocally } from '../../../../localdb/files/delete'
import { store } from '../../../../store'
import { updateUpdatedAtExecution } from '../../../../store/execution/slice'
import {
  deleteMediaFromExecutionCommentAction,
  removeExecutionComment
} from '../../../../store/executionComments/actions'
import { removeMediaUploadings } from '../../../../store/media-uploading/slice'
import { setIsWorking } from '../../../../store/userInterface/slice'


export async function deleteComment(commentId, mediaIds) {
  if (mediaIds.length > 0) {
    await deleteFilesAndContentLocally(mediaIds)
  }
  store.dispatch(setIsWorking(true))
  store.dispatch(removeMediaUploadings({ ids: mediaIds }))
  store.dispatch(removeExecutionComment({ commentId }))
  store.dispatch(updateUpdatedAtExecution({ updatedAt: new Date().valueOf() }))
}

export async function deleteMediaFromComment(commentId, mediaId) {
  await deleteFilesAndContentLocally([mediaId])
  store.dispatch(setIsWorking(true))
  store.dispatch(deleteMediaFromExecutionCommentAction({ commentId, mediaId }))
  store.dispatch(updateUpdatedAtExecution({ updatedAt: new Date().valueOf() }))
}
