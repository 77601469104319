import { liveQuery } from 'dexie'
import groupBy from 'lodash.groupby'

import { EXECUTION_SYNC_CHILDREN } from '../localdb/common/sync'
import { readLocalAbortedFinishedExecutions } from '../localdb/execution/read'
import { updateSyncStatus } from '../localdb/execution-sync-status/write'
import { TABLE_NAMES } from '../utils/constants/localdb'
import { erro } from '../utils/logger'

import { cleanExecutions } from './execution/localDb'

export function getSyncQuery(table) {
  return EXECUTION_SYNC_CHILDREN[table].syncQuery
}

export const observableMap = new Map()

export function setupSyncStatus() {
  Object.keys(EXECUTION_SYNC_CHILDREN).forEach((moduleName) => {
    const observable = liveQuery(getSyncQuery(moduleName))

    observable.subscribe({
      next: (result) => {
        updateSyncStatus(
          moduleName,
          groupBy(
            result,
            moduleName === TABLE_NAMES.EXECUTIONS ? 'id' : 'executionId'
          )
        )
      },
      error: (error) => erro(`Error sync status ${moduleName}`, error)
    })
  })
}

export function subscribeSync() {
  /**
   * Live Query para sincronización reactiva
   */
  Object.keys(EXECUTION_SYNC_CHILDREN).forEach((moduleName) => {
    const observable = liveQuery(getSyncQuery(moduleName))

    if (observableMap.keys.length > 0) {
      unsubscribeSync()
    }

    const subscription = observable.subscribe({
      next: (result) => {
        syncTable(moduleName, result)
      },
      error: (error) => erro(`Error syncing ${moduleName}`, error)
    })
    observableMap.set(moduleName, subscription)
  })
}

export function unsubscribeSync() {
  observableMap.forEach((subscription) => {
    subscription.unsubscribe()
  })
  observableMap.clear()
}

/**
 * Función para sincronización
 */
async function syncTable(table, result) {
  const module = EXECUTION_SYNC_CHILDREN[table]
  const { syncFn } = module

  return syncFn(result)
}

/**
 * Función para lanzar sincronización forzada
 */
export async function syncAllTables() {
  const { [TABLE_NAMES.EXECUTIONS]: executions, ...restModules } =
    EXECUTION_SYNC_CHILDREN

  await syncTable(TABLE_NAMES.EXECUTIONS)
  await Promise.all(Object.keys(restModules).map((table) => syncTable(table)))
}

/**
 * Function that sets up an observable to remove finished or aborted synced
 * executions from local.
 */
export function observeFinishedAbortedExecutions() {
  const observable = liveQuery(readLocalAbortedFinishedExecutions)

  const subscription = observable.subscribe({
    next: async (result) => {
      result.length && (await cleanExecutions())
    },
    error: (error) => erro(`Error clearing executions`, error)
  })
  observableMap.set('ABORTED_FINISHED_EXECUTIONS_OBSERVABLE', subscription)
}
