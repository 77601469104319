import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData } from '../../common/write'

const tableName = TABLE_NAMES.EXECUTION_GOALS

export const writeExecutionGoal = async (executionGoal) => {
  return _writeLocalDbData({
    tableName,
    data: [executionGoal],
    action: 'ADD'
  })
}

export const writeExecutionGoals = async (executionGoals) => {
  return _writeLocalDbData({
    tableName,
    data: executionGoals,
    action: 'PUT'
  })
}
