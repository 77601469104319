import React from 'react'
import styled from 'styled-components'

import CloseIcon from '../../../../components/icons/close-icon'
import AttachmentIconView from '../../../icons/attachment'

const AttachedFile = ({ file, onRemove, ...rest }) => {
  return (
    <AttachedFileContainer {...rest}>
      <AttachmentFileIcon size='16px' />
      <span>{file.name}</span>
      {onRemove && <RemoveFileIcon onClick={onRemove} />}
    </AttachedFileContainer>
  )
}

const AttachedFileContainer = styled.div`
  display: inline-flex;
  align-items: center;
  border: solid 1px #d9d9d9;
  padding: 4px 8px;
  margin-bottom: 8px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`

const RemoveFileIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-left: 8px;
`

const AttachmentFileIcon = styled(AttachmentIconView)`
  margin-right: 8px;
`

export default AttachedFile
