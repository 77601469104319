import { Button, Popover, Tooltip } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import useDisableSteps from '../../../hooks/use-disable-steps'
import { store } from '../../../store'
import {
  abortExecution,
  finishExecution
} from '../../../store/execution/actions'
import { getExecution } from '../../../store/execution/selectors'
import { getExecutionComponents } from '../../../store/executionComponents/selectors'
import { hasUploadFinished } from '../../../store/media-uploading/selectors'
import { getProcedure } from '../../../store/procedure/selectors'
import { getIsWorking } from '../../../store/userInterface/selectors'
import { setIsWorking } from '../../../store/userInterface/slice'
import {
  EXECUTION_STATUS,
  SEQUENTIAL_MODE
} from '../../../utils/constants/execution'
import {
  EXECUTION_SIGNATURE_STATUS,
  EXECUTION_SIGNATURE_STATUS_IS_FINAL
} from '../../../utils/constants/execution/components'
import { EXECUTION_COMPONENT_TYPES } from '../../../utils/constants/execution/components'
import { EXECUTION_STATUS_COLOR } from '../../../utils/constants/styles'
import CommentModal from '../../../views/modal/comment-modal'
import Translation from '../../translations'

import { canFinishOrAbortExecution } from './helpers'

function getSteps(procedure, executionComponents, signatureStatus) {
  const steps = procedure.components.filter((component) => {
    const executionComponent = executionComponents?.find(
      (c) => c.procedureComponentId === component.id
    )
    const isSignature = component.type === EXECUTION_COMPONENT_TYPES.SIGNATURE
    const notFinal = executionComponent?.value?.status === signatureStatus
    return isSignature && notFinal
  })
  return steps.length
}

const StopPopover = ({ children }) => {
  const [modalFor, setModalFor] = useState(null)
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState({
    stepsInProgress: false,
    stepsOnHold: false,
    executionIsFinished: false
  })

  const procedure = useSelector(getProcedure)
  const executionComponents = useSelector(getExecutionComponents)
  const execution = useSelector(getExecution)
  const isWorking = useSelector(getIsWorking)
  const allUploadFinished = useSelector(hasUploadFinished)

  const { disabledSteps } = useDisableSteps()

  const isExecutionFinished = useCallback(() => {
    if (
      (execution?.sequentialMode ?? procedure?.sequentialMode) ===
      SEQUENTIAL_MODE.NONE
    )
      return true
    if (!executionComponents) return false

    // Comprobamos que todas las firmas estén completas
    return procedure.components.every((component) => {
      if (component.type !== EXECUTION_COMPONENT_TYPES.SIGNATURE) return true

      const signature = executionComponents?.find(
        (c) => c.procedureComponentId === component.id
      )
      const signatureStatus = signature?.value?.status

      const isInDisabledStep = disabledSteps.includes(component.stepId)

      const signatureIsFinal =
        signatureStatus && EXECUTION_SIGNATURE_STATUS_IS_FINAL[signatureStatus]

      return isInDisabledStep || signatureIsFinal
    })
  }, [
    disabledSteps,
    execution?.sequentialMode,
    executionComponents,
    procedure.components,
    procedure?.sequentialMode
  ])

  useEffect(() => {
    // No es necesario calcular los pasos en progreso y en
    // espera si el popover de finalizar no está abierto
    if (!visible) {
      return undefined
    }
    const nextStatus = {
      stepsInProgress: getSteps(
        procedure,
        executionComponents,
        EXECUTION_SIGNATURE_STATUS.IN_PROGRESS
      ),
      stepsOnHold: getSteps(
        procedure,
        executionComponents,
        EXECUTION_SIGNATURE_STATUS.WAITING
      ),
      executionIsFinished: isExecutionFinished()
    }

    setStatus(nextStatus)
  }, [executionComponents, procedure, visible, isExecutionFinished])
  const { stepsInProgress, stepsOnHold, executionIsFinished } = status

  function updateExecution(status, statusComment) {
    const action =
      status === EXECUTION_STATUS.FINISHED ? finishExecution : abortExecution
    store.dispatch(action(statusComment))
  }

  const abortButtonStatus = useMemo(() => {
    return canFinishOrAbortExecution(
      stepsInProgress,
      execution,
      isWorking,
      allUploadFinished,
      null,
      EXECUTION_STATUS.ABORTED,
      (execution?.sequentialMode ?? procedure?.sequentialMode) ===
        SEQUENTIAL_MODE.NONE
    )
  }, [
    stepsInProgress,
    execution,
    isWorking,
    allUploadFinished,
    procedure?.sequentialMode
  ])

  const finishButtonStatus = useMemo(() => {
    return canFinishOrAbortExecution(
      stepsInProgress + stepsOnHold,
      execution,
      isWorking,
      allUploadFinished,
      executionIsFinished,
      EXECUTION_STATUS.FINISHED,
      (execution?.sequentialMode ?? procedure?.sequentialMode) ===
        SEQUENTIAL_MODE.NONE
    )
  }, [
    stepsInProgress,
    execution,
    isWorking,
    allUploadFinished,
    executionIsFinished,
    stepsOnHold,
    procedure?.sequentialMode
  ])

  return (
    <Popover
      placement='bottom'
      open={visible}
      onOpenChange={(visible) => setVisible(visible)}
      content={
        <div>
          <Tooltip
            placement='bottom'
            title={
              abortButtonStatus.disabled ? (
                <Translation id={abortButtonStatus.message} />
              ) : null
            }
          >
            <Button
              id='confirm-abort-button'
              onClick={() => {
                setModalFor(EXECUTION_STATUS.ABORTED)
                setVisible(false)
              }}
              style={{ backgroundColor: EXECUTION_STATUS_COLOR.ABORTED }}
              disabled={abortButtonStatus.disabled}
            >
              <Translation id='abort' />
            </Button>
          </Tooltip>

          <Tooltip
            placement='bottom'
            title={
              finishButtonStatus.disabled ? (
                <Translation id={finishButtonStatus.message} />
              ) : null
            }
          >
            <Button
              id='confirm-finish-button'
              onClick={() => {
                setModalFor(EXECUTION_STATUS.FINISHED)
                setVisible(false)
              }}
              style={{
                marginLeft: '8px',
                backgroundColor: EXECUTION_STATUS_COLOR.FINISHED
              }}
              disabled={finishButtonStatus.disabled}
            >
              <Translation id='finish' />
            </Button>
          </Tooltip>

          <CommentModal
            status={modalFor}
            visible={modalFor}
            onCancel={() => setModalFor(null)}
            onConfirm={(status, comment) => {
              store.dispatch(setIsWorking(true))
              updateExecution(status, comment)
              setModalFor(null)
            }}
            action={modalFor}
            observations={execution?.statusComment || ''}
            required={modalFor === EXECUTION_STATUS.ABORTED}
          />
        </div>
      }
      trigger='click'
    >
      {children}
    </Popover>
  )
}

export default StopPopover
