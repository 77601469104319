import React from 'react'

const CheckboxUnchecked = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 32 32'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='Procedimiento'
      stroke={color}
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Artboard'
        transform='translate(-435.000000, -688.000000)'
        stroke={color}
        strokeWidth='1.5'
      >
        <g
          id='checkbox-uncomplete'
          transform='translate(435.000000, 688.000000)'
        >
          <g id='Group'>
            <path
              d='M30,0.748577778 L2,0.748577778 C1.65482203,0.748577778 1.34232203,0.888488793 1.11611652,1.1146943 C0.889911016,1.34089981 0.75,1.65339981 0.75,1.99857778 L0.75,29.9985778 C0.75,30.3437557 0.889911016,30.6562557 1.11611652,30.8824613 C1.34232203,31.1086668 1.65482203,31.2485778 2,31.2485778 L30,31.2485778 C30.345178,31.2485778 30.657678,31.1086668 30.8838835,30.8824613 C31.110089,30.6562557 31.25,30.3437557 31.25,29.9985778 L31.25,1.99857778 C31.25,1.65339981 31.110089,1.34089981 30.8838835,1.1146943 C30.657678,0.888488793 30.345178,0.748577778 30,0.748577778 Z'
              id='Path'
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CheckboxUnchecked
