export const PROCEDURE_PERMISSION_TYPE = {
  creation: 'roleCreationLevel',
  merge: 'roleMergeLevel',
  revision: 'roleRevisionLevel',
  approve: 'roleApproveLevel'
}

export const ROLES = {
  ADMIN: 'admin',
  SHIFT_MANAGER: 'shift_manager',
  SUPERVISOR: 'supervisor',
  ROOM_OPERATOR: 'room_operator',
  PLANT_OPERATOR: 'plant_operator',
  GUEST: 'guest',
  DIGITALIZER: 'digitalizer'
}

export const PERMISSION_TYPES = {
  PROCEDURE_MANAGE_EXPORT: 'adminProcedures-export',
  PROCEDURE_MANAGE_IMPORT: 'adminProcedures-import',
  PROCEDURE_MANAGE_DELETE: 'adminProcedures-delete',
  PROCEDURE_MANAGE_CHANGE_STATUS: 'adminProcedures-changeStatus',
  PROCEDURE_DOWNLOAD: 'procedure-download',
  PROCEDURE_SHOW_ALL_STATUSES: 'procedure-showStatuses',
  PROCEDURE_VERSION: 'procedure-version',
  EXECUTION_HISTORY_REGENERATE: 'executionHistory-regenerate',
  EXECUTION_DOWNLOAD_OTHER_USER: 'execution-download',
  EXECUTION_CHANGE_TYPE: 'executionView-changeParams',
  EXECUTION_HISTORY_VIEW: 'executionHistory-view',
  EXECUTION_START: 'executionView-start',
  EXECUTION_RESUME: 'execution-management-resume',
  EXECUTION_APPROVE: 'execution-management-approve',
  EXECUTION_MERGE: 'execution-management-merge',
  EXECUTION_REVIEW: 'execution-management-review',
  EXECUTION_VIEW: 'execution-management-view',
  EXECUTION_UPLOAD: 'execution-management-upload',
  EXECUTION_KEEP_EXECUTIONS: 'execution-keepExecutions'
}
