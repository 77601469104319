import { Progress } from 'antd'
import React, { useMemo, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'

import ArrowDown from '../../components/icons/arrow-down'
import ArrowUp from '../../components/icons/arrow-up'
import UploadFileIcon from '../../components/icons/upload-file'
import { getMediaUploading } from '../../store/media-uploading/selectors'
import { getConnectionStatus } from '../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../utils/constants/connection'
import MediaUploading from '../media-uploading'
import Translation from '../translations'

function MediaUploadNotification({ showProgress }) {
  const [showMedia, setShowMedia] = useState(false)
  const connectionStatus = useSelector(getConnectionStatus)

  const mediaUploading = useSelector(getMediaUploading, shallowEqual)

  const mediaUploadComplete = useMemo(
    () =>
      mediaUploading.filter(({ completed, error }) => completed && !error)
        .length,
    [mediaUploading]
  )
  const mediaWithError = useMemo(
    () => mediaUploading.filter(({ error }) => error).length,
    [mediaUploading]
  )

  const error = mediaWithError > 0
  const finished = mediaUploadComplete === mediaUploading.length
  const offline = connectionStatus !== CONNECTION_STATUS.ONLINE

  const errorText = error ? '.error' : ''
  const offlineText = offline ? '.offline' : ''
  const finishedText = finished ? '.finish' : ''

  return (
    <>
      <Container
        className='upload-video-notification'
        onClick={() => setShowMedia(!showMedia)}
      >
        <TitleContainer>
          {showProgress ? <UploadFileIcon size={24} color='#000000' /> : null}
          <Translation
            id={`media.uploading${
              offlineText || errorText || finishedText || ''
            }`}
          />
        </TitleContainer>
        {showProgress ? (
          <ProgressContainer>
            <Progress
              type='circle'
              percent={(mediaUploadComplete / mediaUploading.length) * 100}
              width={30}
              strokeColor={error || offline ? '#ED1C24' : '#89D000'}
            />
            <Text>
              <span className='count'>
                {mediaUploadComplete}/{mediaUploading.length}
              </span>
            </Text>
          </ProgressContainer>
        ) : null}
        {showMedia ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
      </Container>

      <MediaUploading
        showMedia={showMedia}
        onClickEyeAction={() => setShowMedia(false)}
        isNotification
      />
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  padding: 10px 0px;
`

const Text = styled.p`
  margin: 10px 0;
  padding: 0;
  font-size: ${({ 'data-fontsize': fontsize }) => fontsize || '16px'};

  & > .count {
    font-size: 18px;

    color: #b8bab6;
  }
`

export default MediaUploadNotification
