import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

const TextButton = (props) => {
  return <StyledButton type='text' {...props}></StyledButton>
}

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #645377;

  & span {
    margin-left: 8px;
  }
`

export default TextButton
