import styled from 'styled-components'

import BlankButton from '../../../components/buttons/blank-button'

const CommentsButtonStyle = styled(BlankButton)`
  border-radius: 2px;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  color: #000;
  padding: 0;
  border-radius: 10px;
`

export default CommentsButtonStyle
