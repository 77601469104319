import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import { axiosInstance } from '..'
import { writeFile } from '../../localdb/files/write'
import { writeFilesContent } from '../../localdb/filesContent/write'
import { store } from '../../store'
import { updateUpdatedAtExecution } from '../../store/execution/slice'
import {
  addMediaUploading,
  completeProgress,
  errorUpload,
  removeMediaByNameAndComment,
  updateProgress
} from '../../store/media-uploading/slice'
import { CONNECTION_STATUS } from '../../utils/constants/connection'
import { MediaUploadingCancelFuncMap } from '../../utils/constants/media-upload'

async function sendFile(file, type, id) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)
  formData.append('id', id || null)

  const response = await axiosInstance.post('files', formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })

  return response.data
}

export async function removeFileContent(id) {
  return axiosInstance.delete(`files/${id}`)
}

export function uploadVideo(
  video,
  videoId,
  dispatch,
  addMediaPayload,
  addMediaToCommentAction,
  cancelToken
) {
  const formData = new FormData()
  formData.append('file', video)
  formData.append('fileId', videoId)

  const config = {
    onUploadProgress: (progressEvent) => {
      const percent = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      )
      dispatch(
        updateProgress({
          id: videoId,
          progress: percent
        })
      )
    },
    cancelToken
  }

  axiosInstance
    .post('/videos/upload', formData, config)
    .then((res) => {
      const {
        data: { fileSaved }
      } = res
      dispatch(completeProgress({ id: videoId }))
      dispatch(addMediaToCommentAction(addMediaPayload))
      dispatch(updateUpdatedAtExecution({ updatedAt: new Date().valueOf() }))
      writeFile([{ ...fileSaved, sync: true }])
    })
    .catch(() => {
      dispatch(errorUpload({ id: videoId }))
    })
}

export function uploadVideos(
  videosToUpload,
  commentId,
  stepId,
  executionStep,
  stepIndex,
  executionId,
  addMediaPayload,
  addMediaToCommentAction,
  dispatch,
  isRetry = false
) {
  videosToUpload.forEach((video) => {
    const videoId = uuidv4().toUpperCase()

    const cancelToken = axios.CancelToken.source()

    MediaUploadingCancelFuncMap.set(videoId, cancelToken)

    const addMediaUploadingPayload = {
      id: videoId,
      name: video.name,
      commentId,
      stepId,
      stepBmk: executionStep,
      stepIndex,
      size: video.size,
      type: video.type,
      progress: 0,
      error: false,
      executionId,
      cancelByUser: false,
      completed: false
    }

    if (isRetry) {
      dispatch(removeMediaByNameAndComment({ name: video.name, commentId }))
      dispatch(addMediaUploading(addMediaUploadingPayload))
    } else {
      dispatch(addMediaUploading(addMediaUploadingPayload))
    }

    uploadVideo(
      video,
      videoId,
      dispatch,
      { ...addMediaPayload, mediaId: videoId },
      addMediaToCommentAction,
      cancelToken.token
    )
  })
}

export async function uploadFiles(files, type) {
  let filesResult = []
  for (let i = 0; i < files.length; i++) {
    let file = files[i]
    let data = file
    let sync = false

    const connectionStatus = store.getState().userInterface.connectionStatus

    if (
      connectionStatus !== CONNECTION_STATUS.OFFLINE &&
      connectionStatus !== CONNECTION_STATUS.DISABLED
    ) {
      data = await sendFile(file, type)
      sync = true
    } else {
      data.id = uuidv4().toUpperCase()
      data.originalName = file.name
      data.extension = file.name?.substring(
        file.name.length - 4,
        file.name.length
      )
      file = new File([file], file.name, {
        type: file.type
      })
    }

    await writeFile([{ ...data, ...{ type, sync } }])
    await writeFilesContent([{ id: data.id, content: file }])

    filesResult.push(data)
  }
  return filesResult
}
