import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _readLocalDbData } from '../../common/read'

const tableName = TABLE_NAMES.PROCEDURES

export async function readProcedures(indexesOnly = true) {
  const { data } = await _readLocalDbData({ tableName, indexesOnly })

  return data
}

export async function readProcedure(id) {
  const db = getDatabase()

  return db[tableName].get(id)
}
