import React from 'react'

import RadioButton from '../../../../components/radio-buttons'
import useHistoricalComponent from '../../../../hooks/use-historical-component'

const RadioCellGroup = ({
  onComponentChange,
  component,
  executionComponent,
  disabled,
  className,
  procedure,
  isHistoricalMode
}) => {
  const procedureComponent = procedure?.components?.find(
    (comp) => comp.id === component.procedureComponentId
  )
  const { group, label, showHistorical } = procedureComponent?.options ?? {}
  const checked = executionComponent?.value === label

  const {
    historicalModeEnabled,
    historicalComponentActive,
    onClickHistorical
  } = useHistoricalComponent({
    isHistoricalMode,
    showHistorical,
    component
  })

  return (
    <RadioButton
      active={historicalComponentActive}
      value={checked}
      id={group + label}
      name={group}
      data-checked={!!checked}
      disabled={disabled}
      className={className}
      historicalModeEnabled={historicalModeEnabled}
      onClickHistorical={onClickHistorical}
      onChange={() =>
        onComponentChange({
          id: procedureComponent.id,
          value: checked ? null : label
        })
      }
    />
  )
}

export default RadioCellGroup
