export default function useActions({
  modifyDepartment,
  removeDepartment,
  addDepartment,
  reloadDepartments
}) {
  const modifyDepartmentAction = (department, closeModal) =>
    modifyDepartment(department, reloadDepartments, closeModal)
  const removeDepartmentAction = (departmentId) =>
    removeDepartment(departmentId, reloadDepartments)
  const addDepartmentAction = (department, closeModal) =>
    addDepartment(department, reloadDepartments, closeModal)

  return {
    modifyDepartmentAction,
    removeDepartmentAction,
    addDepartmentAction
  }
}
