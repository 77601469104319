import { removeNodes } from '../remove'
import { setWidthOfCellToTextArea } from '../set'

export function simplifyComponents(content) {
  // option list: eliminamos los elementos que lo componen y dejamos el input. Borramos las flechas de la lista de ops.

  removeNodes(content, '.ant-select-selection-search')
  const selectArrows = content.querySelectorAll('.ant-select-arrow')
  const selectSelector = content.querySelectorAll('.ant-select-selection-item')
  const selectPlaceholders = content.querySelectorAll(
    '.ant-select-selection-placeholder'
  )
  const datePickerInputs = content.querySelectorAll('.ant-picker-input input')

  // Adding the select box width to the inner selector tag class remaining
  selectSelector.forEach((selectSelector) => {
    const selectWidth = selectSelector.closest('.ant-select').style.width
    selectSelector.style.display = 'inline-block'
    selectSelector.style.width = selectWidth
  })

  // Removing "Select" placeholder text while keeping the select's width
  selectPlaceholders.forEach((selectPlaceholder) => {
    const selectWidth = selectPlaceholder.closest('.ant-select').style.width
    selectPlaceholder.firstElementChild.innerHTML = ''
    selectPlaceholder.style.display = 'inline-block'
    selectPlaceholder.style.width = selectWidth
    selectPlaceholder.style.verticalAlign = 'middle'
    selectPlaceholder.style.margin = '4px 0'
  })

  // Set a grey background to the date picker inputs
  datePickerInputs.forEach((datePickerInput) => {
    datePickerInput.style.backgroundColor = '#EFEFEF'
    datePickerInput.style.border = 'none'
  })

  Array.from(selectArrows).forEach((selectArrow) => {
    selectArrow.remove()
  })

  const searchInputs = content.querySelectorAll(
    '.ant-select-selection-search-input'
  )
  Array.from(searchInputs).forEach((searchInput) => {
    searchInput.remove()
  })

  // textarea: le sumamos 1 columna para que no se desfase el texto

  const textareas = content.querySelectorAll('.textarea>textarea')
  Array.from(textareas).forEach((textarea) => {
    textarea.setAttribute('cols', textarea.cols + 1)
  })

  // De cara a que las tablas no se descuadren porque los text area tienen un alto mayor al que se les asigna en ProEdit,
  // buscamos aquellos que estén en una tabla y les asignamos el ancho de la celda donde están.
  setWidthOfCellToTextArea(content)

  // date picker: eliminamos de los data pickers los elementos que lo componen para dejar sólo el input interno
  removeNodes(content, '.ant-picker')
  removeNodes(content, '.ant-picker-input')
  removeNodes(content, '.ant-select-selector')
  removeNodes(content, '.ant-select')
}

//Elimina saltos de línea para algunos componentes
export function formatComponents(content) {
  Array.from(
    content.querySelectorAll('.reference, .filereference, .jump')
  ).forEach((node) => {
    const firstChild = node.children[0]
    if (firstChild?.tagName === 'DIV') {
      node.innerHTML = firstChild.innerHTML
    }
  })
}
