import { getTranslation } from '../../../i18n/getTranslation'
import { addNewDepartment } from '../../../services/departments/http/post'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { notifyError } from '../../../utils/notifications'

export async function addDepartment(department, reload, closeModal) {
  try {
    await addNewDepartment(department)
    notificationMessage({
      message: getTranslation('department.successMsg')
    })
    if (closeModal) closeModal()
    reload()
  } catch (error) {
    notifyError(error)
  }
}
