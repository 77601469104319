import { useDispatch } from 'react-redux'

import { setConditionalValue } from '../../../../../../../store/execution-conditionals/actions'

export default function useChangeValue({ conditionalId }) {
  const dispatch = useDispatch()

  const onChangeValue = (value) => {
    if (value === 'default') {
      dispatch(setConditionalValue({ forcedValue: null, id: conditionalId }))
    } else {
      dispatch(setConditionalValue({ forcedValue: value, id: conditionalId }))
    }
  }

  return {
    onChangeValue
  }
}
