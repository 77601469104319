import React from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import ExtraHeaderContentButton from '../../../components/buttons/extra-header-content'
import Sync from '../../../components/icons/sync'
import ExtraHeaderContentMessage from '../../../components/message/extra-header-content'
import ExtraHeaderContent from '../../../containers/extra-header-content'
import { IconContainer } from '../../../containers/icon'
import { syncAllTables } from '../../../store/synchronization'
import {
  areModulesSyncing,
  getConnectionStatus
} from '../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import Translation from '../../translations'

const SyncExecutionsButton = ({ disabled = true }) => {
  const [isSyncing, setIsSyncing] = React.useState(false)
  const connectionStatus = useSelector(getConnectionStatus)
  const areModulesStillSyncing = useSelector(areModulesSyncing)

  const onClick = async () => {
    setIsSyncing(true)
    try {
      await syncAllTables()
      setIsSyncing(false)
    } catch (error) {
      setIsSyncing(false)
    }
  }

  const isOnline = useMemo(
    () => connectionStatus === CONNECTION_STATUS.ONLINE,
    [connectionStatus]
  )

  const isDisabled = useMemo(() => {
    return disabled || !isOnline || isSyncing || areModulesStillSyncing
  }, [disabled, isOnline, isSyncing, areModulesStillSyncing])

  return (
    <ExtraHeaderContent
      disabled={isDisabled}
      onClick={() => (isDisabled ? {} : onClick())}
    >
      <ExtraHeaderContentButton
        id='massive-interruption-button'
        onClick={onClick}
        type='primary'
        loading={isSyncing || areModulesStillSyncing}
        disabled={isDisabled}
      >
        {!isSyncing && !areModulesStillSyncing && (
          <IconContainer color='#ffffff' icon={Sync} />
        )}
      </ExtraHeaderContentButton>
      <ExtraHeaderContentMessage>
        <Translation id='syncExecutions' />
      </ExtraHeaderContentMessage>
    </ExtraHeaderContent>
  )
}

export default SyncExecutionsButton
