import styled from 'styled-components'

const ElementsCountContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  width: 100%;
  overflow-y: hidden;
  padding: 10px 0 10px 0;
`

export default ElementsCountContainer
