import { store } from '../../../../store'
import { updateUpdatedAtExecution } from '../../../../store/execution/slice'
import { editStepCommentAction } from '../../../../store/executionSteps/actions'
import { setIsWorking } from '../../../../store/userInterface/slice'

export async function editComment(stepId, comment) {
  store.dispatch(setIsWorking(true))
  store.dispatch(editStepCommentAction({ stepId, comment }))
  store.dispatch(updateUpdatedAtExecution({ updatedAt: new Date().valueOf() }))
}
