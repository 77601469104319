import { Divider } from 'antd'
import React from 'react'
import styled from 'styled-components'

const HeaderDivider = (props) => {
  return <CustDivider {...props} type='vertical' />
}

const CustDivider = styled(Divider)`
  background: #cdcdc8;
  height: 40px;
`

export default HeaderDivider
