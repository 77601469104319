import { groupBy } from 'lodash'

import { putExecutionWarnings } from '../../../services/execution-warnings/http/put'

export const sendExecutionWarnings = async (executionWarnings) => {
  const grouped = groupBy(executionWarnings, 'executionId')

  return Promise.all(
    Object.keys(grouped).map((executionId) =>
      putExecutionWarnings(executionId, grouped[executionId])
    )
  )
}
