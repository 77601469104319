import { Modal } from 'antd'

import { getTranslation } from '../../../i18n/getTranslation'
import { writeExecutions } from '../../../localdb/execution/write'
import { putSendExecution } from '../../../services/execution/http/put'
import { store } from '../../../store'
import { loadExecution } from '../../../store/execution/actions'
import { deleteExecutions } from '../../../store/execution/localDb'
import { getExecutionId } from '../../../store/execution/selectors'
import { setSyncedAt } from '../../../store/execution/slice'
import { getSyncQuery } from '../../../store/synchronization'
import {
  setModuleIsNotSyncing,
  setModuleIsSyncing
} from '../../../store/userInterface/slice'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { debug, erro } from '../../../utils/logger'

const tableName = TABLE_NAMES.EXECUTIONS

export async function syncExecutions(result) {
  const syncedAt = new Date().valueOf()

  const data = result ?? (await getSyncQuery(tableName)())

  if (data.length) {
    debug(`Syncing ${tableName}`, data)

    store.dispatch(setModuleIsSyncing({ moduleName: tableName }))

    try {
      await putSendExecution(data)
      data.forEach((c) => {
        c.syncedAt = syncedAt
      })
      store.dispatch(setSyncedAt({ syncedAt, entities: data }))

      const executionsToWrite = data.filter(
        (e) => e.status !== EXECUTION_STATUS.INTERRUPTED
      )
      const executionsToDelete = data.filter(
        (e) => e.status === EXECUTION_STATUS.INTERRUPTED
      )

      await deleteExecutions(executionsToDelete.map((e) => e.id))
      await writeExecutions(executionsToWrite)
      store.dispatch(setModuleIsNotSyncing({ moduleName: tableName }))
    } catch (err) {
      store.dispatch(setModuleIsNotSyncing({ moduleName: tableName }))
      const [error, data] = err.response?.data?.message?.split(':') ?? []
      const executionIds = data?.split(';')

      const cases = {
        EXECUTION_DOWNLOADED_AFTER_LAST_DOWNLOAD: {
          modalData: {
            title: 'errorSyncingExecutionDownloadedAfterLastDownloadTitle',
            content: 'errorSyncingExecutionDownloadedAfterLastDownloadContent',
            onOk: async () => {
              await deleteExecutions(executionIds).catch((error) => {
                erro(`Error deleting executions: ${error}`)
              })

              const currentExecutionId = getExecutionId(store.getState())

              if (executionIds.includes(currentExecutionId)) {
                history.push('/dashboard')
                store.dispatch(loadExecution(null))
              }
            }
          }
        }
      }

      const errorCase = cases[error]

      if (errorCase) {
        const { modalData } = errorCase
        Modal.error({
          ...modalData,
          title: getTranslation(modalData.title),
          content: getTranslation(modalData.content)
        })
      } else {
        erro('An error ocurred syncing executions', err)
      }
    }
  }
}
