import { Parser } from 'expr-eval'
import { mapValues } from 'lodash'

import { EXECUTION_COMPONENT_TYPES } from '../../../utils/constants/execution/components'

export function getVariableValues({
  variableIdsMap,
  executionComponents,
  procedureComponents
}) {
  const relatedProcedureComponents = procedureComponents.filter((c) =>
    Object.values(variableIdsMap).includes(c.id)
  )
  const relatedExecutionComponents = relatedProcedureComponents.map((c) => {
    const executionComponent = executionComponents?.find(
      (comp) => comp.procedureComponentId === c.id
    )

    let value = executionComponent?.value

    if (c.type === 'Formula') {
      const variableValues = getVariableValues({
        variableIdsMap: c.options.variables,
        executionComponents,
        procedureComponents
      })
      value = calculateResult({
        formula: c.options.formula,
        variableValuesMap: variableValues,
        numberOfDecimals: c.options.numberOfDecimals
      })
    }
    if (
      c.type === EXECUTION_COMPONENT_TYPES.READOUT_RT_NUMERICAL ||
      c.type === EXECUTION_COMPONENT_TYPES.READOUT_RT_RECORDER
    ) {
      value = executionComponent?.value || executionComponent?.liveValue?.value
    }
    return { id: c.id, value }
  })

  return mapValues(variableIdsMap, (compId) => {
    const relatedExecComponent = relatedExecutionComponents.find(
      (c) => c.id === compId
    )
    return relatedExecComponent ? relatedExecComponent.value : null
  })
}

export function calculateResult({
  variableValuesMap,
  formula,
  numberOfDecimals = 0
}) {
  if (
    Object.values(variableValuesMap).some((v) => v === undefined || v === null)
  ) {
    return null
  }

  const num = Parser.evaluate(formula, variableValuesMap)

  if (typeof num === 'number') {
    return isNaN(num) ? null : num.toFixed(numberOfDecimals)
  }
  return num
}

export function bytesToMegabytes(bytes) {
  return (bytes / 1000000).toFixed(1)
}
