import { Form } from 'antd'
import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { saveExecution } from '../../../../../application/execution'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { uploadFinalReport } from '../../../../../services/execution/report/http/post'
import { EXECUTION_QA_STATUS, EXECUTION_STATUS } from '../../../../../utils/constants/execution'
import DateRangePicker from '../../../../../views/date/range-picker'
import FileInput from '../../../../../views/inputs/file-input'
import Modal from '../../../../../views/modal'
import SelectGlobalProcedures from '../../../../../views/select/global-procedures'
import SelectGlobalUsers from '../../../../../views/select/global-users'
import Translation from '../../../../../views/translations'


export const UploadExecutionModal = ({ visible, setVisible }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible, form])

  const [
    executionProcedureTranslation,
    userTranslation,
    executionTimesTranslation,
    uploadExecutionTranslation
  ] = useTranslation([
    'executionProcedure',
    'user',
    'executionTimes',
    'uploadExecution'
  ])

  async function onOk(values) {
    const executionId = uuidv4().toUpperCase()

    const {
      'input-dates': [createdAt, finishedAt],
      user: { id: userId },
      file
    } = values

    const newExecution = {
      id: executionId,
      procedureId: values.procedureId,
      status: EXECUTION_STATUS.FINISHED,
      progress: 0,
      userId: userId,
      createdAt,
      finishedAt,
      finishedById: userId,
      revisedById: userId,
      revisedAt: finishedAt,
      approvedById: userId,
      approvedAt: finishedAt,
      qaStatus: EXECUTION_QA_STATUS.APPROVED,
      manualExecution: true
    }
    await saveExecution(newExecution, { saveLocally: false })

    if (file) {
      var filesToStore = new FormData()
      filesToStore.append('pdfFile', file)
      await uploadFinalReport(newExecution, filesToStore)
    }

    onExit()
  }

  function onExit() {
    setVisible(false)
    form.resetFields()
  }

  return (
    <Modal
      formId='uploadExecutionForm'
      okButtonText={<Translation id='addManualExecution' />}
      cancellable={true}
      visible={visible}
      setVisible={setVisible}
      onCancel={onExit}
    >
      <Form
        id='uploadExecutionForm'
        layout='vertical'
        onFinish={onOk}
        form={form}
      >
        <h2>
          <Translation id='uploadManualExecution' />
        </h2>
        <p>
          <Translation id='uploadManualExecutionText' />
        </p>

        <Form.Item
          name='procedureId'
          label={executionProcedureTranslation}
          rules={[{ required: true }]}
        >
          <SelectGlobalProcedures
            placeholder={<Translation id='findProcedure' />}
          />
        </Form.Item>

        <Form.Item
          name='user'
          label={userTranslation}
          rules={[{ required: true }]}
        >
          <SelectGlobalUsers
            placeholder={<Translation id='findUser' />}
            value={null}
          />
        </Form.Item>

        <Form.Item
          name='input-dates'
          label={executionTimesTranslation}
          rules={[{ required: true }]}
        >
          <DateRangePicker
            style={{ marginBottom: '20px' }}
            allowClear={false}
            showTime={{ format: 'HH:mm' }}
          />
        </Form.Item>
        <Form.Item
          name='file'
          label={uploadExecutionTranslation}
          rules={[{ required: true }]}
        >
          <FileInput
            accept={['application/pdf']}
            buttonText={<Translation id='uploadExecution' />}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
