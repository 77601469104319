import { createSelector } from '@reduxjs/toolkit'

import { getConditionalStepInfo } from '../../../utils/helpers/execution-conditionals'
import { getProcedure } from '../../procedure/selectors'

export const getExecutionConditionals = createSelector(
  (state) => {
    return state?.executionConditionals
  },
  (executionConditionals) => {
    return executionConditionals?.conditionals
  }
)

export const getForcibleExecutionConditionals = createSelector(
  getExecutionConditionals,
  getProcedure,
  (executionConditionals, procedure) => {
    return executionConditionals
      ?.filter((c) => c && c.procedureConditional?.forcible)
      .map((c) => ({
        ...c,
        info: getConditionalStepInfo(c, procedure)
      }))
  }
)
