import React from 'react'

import Attachment from '../../../components/icons/attachment'
import { IconContainer } from '../../../containers/icon'

const AttachmentIconView = (props) => (
  <IconContainer {...props} icon={Attachment} />
)

export default AttachmentIconView
