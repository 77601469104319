import { Layout, Tree } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'


import ColorTag from '../../../containers/color-tag'
import useStepsToSign from '../../../hooks/use-steps-to-sign'
import { getProcedure } from '../../../store/procedure/selectors'
import Translation from '../../translations'
import CustomTreeNode from '../../tree'

const { Sider } = Layout

function convertStepsToAntdTree(steps, stepsToSign, stepsWithUnsignedChildren) {
  return steps.map((s) =>
    convertStepToAntdTree(s, stepsToSign, stepsWithUnsignedChildren)
  )
}

function convertStepToAntdTree(step, stepsToSign, stepsWithUnsignedChildren) {
  return {
    key: step.id,
    title: (
      <CustomTreeNode
        index={step.stepIndex}
        title={step.title}
        isUnsigned={stepsToSign?.includes(step.id)}
        isParentOfUnsigned={stepsWithUnsignedChildren?.includes(step.id)}
      />
    ),
    children: step.children
      ? convertStepsToAntdTree(
          step.children,
          stepsToSign,
          stepsWithUnsignedChildren
        )
      : undefined
  }
}

const Menu = ({ onSelectStep, stepsToSign, stepsWithUnsignedChildren }) => {
  const procedure = useSelector(getProcedure)

  return (
    <Tree
      treeData={convertStepsToAntdTree(
        procedure.index.children,
        stepsToSign,
        stepsWithUnsignedChildren
      )}
      onSelect={onSelectStep}
    />
  )
}

const ProcedureIndexSider = ({ onSelectStep, leftDrawerVisible }) => {
  const { stepsToSign, parentSteps } = useStepsToSign()

  return (
    <Sider
      id='barra-index'
      collapsed={!leftDrawerVisible}
      width='300px'
      collapsedWidth={0}
      style={{
        backgroundColor: '#EFEEEB',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      <div id='index-section' style={{ padding: '8px' }}>
        <TitleIndex>
          <Translation id='index' />
          {stepsToSign && stepsToSign.length > 0 && (
            <StepsToSignTag color='#007EC3'>
              <Translation
                id={
                  stepsToSign.length > 1
                    ? 'nStepsToSignPlural'
                    : 'nStepsToSignSingular'
                }
                params={{ count: stepsToSign.length }}
              />
            </StepsToSignTag>
          )}
        </TitleIndex>
        <Menu
          onSelectStep={onSelectStep}
          stepsToSign={stepsToSign}
          stepsWithUnsignedChildren={parentSteps}
        />
      </div>
    </Sider>
  )
}

const TitleIndex = styled.h3`
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  font-family: inherit;
`

const StepsToSignTag = styled(ColorTag)`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-left: 12px;
  padding: 0 4px;
`

export default ProcedureIndexSider
