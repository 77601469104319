import { useRemoteData } from '../useRemoteData'

export const useGlobalUsers = ({
  disabled,
  skip,
  take,
  orderBy,
  orderDirection,
  role,
  search
}) => {
  return useRemoteData({
    url: '/users',
    disabled,
    skip,
    take,
    orderBy: orderBy || 'updatedAt',
    orderDirection: orderDirection || 'ascend',
    role,
    search
  })
}
