import ReactDOM from 'react-dom'

import { erro } from '../../logger'

// ---------------------- REVISIONES ----------------- //
export function markRevisions(activate) {
  const revisedInsSteps = document.querySelectorAll('.msoIns')
  const revisedDelSteps = document.querySelectorAll('.msoDel')

  revisedInsSteps.forEach((revisedInsStep) => {
    revisedInsStep.style.color = activate ? 'green' : 'black'
  })
  revisedDelSteps.forEach((revisedDelStep) => {
    revisedDelStep.style.display = activate ? 'inline' : 'none'
  })
}

// ---------------------- BOOKMARKS ----------------- //

export function getWrapperByBookmark(
  bookmarkId,
  parent = document.querySelector('#procedure-data')
) {
  return parent.querySelector('[data-id="' + bookmarkId + '"]')
}

// -------------------------------------------------- //

export function createStepPortal(reactElement, stepId) {
  let domElem = document.querySelector(`.wrapper[data-id=${stepId}]`)

  if (!domElem) {
    domElem = document.querySelector(`.wrapper-revised[data-id=${stepId}]`)
    if (!domElem) {
      erro('Could not find step with ID:', stepId)
      return null
    }
  }

  return ReactDOM.createPortal(reactElement, domElem)
}

export function createPortal(element, componentId) {
  const domElem = getContentControl(componentId)

  if (!domElem) {
    erro('Could not find component container with ID:', componentId)
    return null
  }

  return ReactDOM.createPortal(
    element,
    domElem.querySelector('.insert_recorder')
  )
}

export function createPortalStepMenu(element, stepId) {
  let domElem = document.querySelector(`.wrapper[data-id=${stepId}]`)

  if (!domElem) {
    domElem = document.querySelector(`.wrapper-revised[data-id=${stepId}]`)
    if (!domElem) {
      erro('Could not find step container with ID:', stepId)
      return null
    }
  }

  return ReactDOM.createPortal(
    element,
    domElem.querySelector('.insert_stepMenu')
  )
}

export function createPortalManeuverTag(element, stepId) {
  const domElem = document.querySelector(`.wrapper[data-id=${stepId}]`)

  if (!domElem) {
    erro('Could not find step container with ID:', stepId)
    return null
  }

  const tagElement = domElem.outerHTML.includes('insert_maneuver_tag')
  if (!tagElement) {
    erro("There isn't maneuver tag element in the step: ", stepId)
    return null
  }

  return ReactDOM.createPortal(
    element,
    domElem.querySelector('.insert_maneuver_tag')
  )
}

// Función que devuelve todos los elementos que representan los content controls en un elemento especificado
// o en el contenido del procedimiento cargado
export function getContentControls(
  parent = document.querySelector('#procedure-data')
) {
  return Array.from(parent.querySelectorAll('span.contentcontrol'))
}

export function getContentControl(id, parent, contentControls) {
  return (contentControls || getContentControls(parent)).find(
    (cc) => cc.dataset.ccid === id
  )
}
