import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getExecutionConditionals } from '../../store/execution-conditionals/selectors'
import { getExecutionComponents } from '../../store/executionComponents/selectors'
import { getExecutionManeuvers } from '../../store/executionManeuvers/selectors'
import { getProcedure } from '../../store/procedure/selectors'
import {
  getConditionalDisabledSteps,
  getManeuverDisabledSteps
} from '../../utils/helpers/steps'

export default function useDisableSteps() {
  const procedure = useSelector(getProcedure)
  const executionComponents = useSelector(getExecutionComponents)
  const executionManeuvers = useSelector(getExecutionManeuvers)
  const executionConditionals = useSelector(getExecutionConditionals)

  const disabledStepsByConditionals = useMemo(
    () =>
      getConditionalDisabledSteps(
        procedure,
        executionConditionals,
        executionComponents
      ),
    [executionComponents, procedure, executionConditionals]
  )

  const disabledStepsByManeuvers = useMemo(
    () => getManeuverDisabledSteps(procedure, executionManeuvers),
    [procedure, executionManeuvers]
  )

  return {
    disabledSteps: [
      ...new Set([...disabledStepsByConditionals, ...disabledStepsByManeuvers])
    ],
    disabledStepsByConditionals,
    disabledStepsByManeuvers
  }
}
