import styled from 'styled-components'

const SelectPicker = styled.span`
  position: relative;

  @media print {
    & {
      background-color: ${(props) => props['data-background-color']};
      color: ${(props) => props['data-color']};
      padding: 2px;
    }

    & > input {
      width: ${(props) => props['data-input-size']};
    }
  }
`

export default SelectPicker
