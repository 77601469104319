import { Popover, Divider, Progress } from 'antd'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useWindowSize } from 'react-use'
import styled from 'styled-components'

import ArrowRight from '../../../../../../components/icons/arrow-right'
import Eye from '../../../../../../components/icons/eye'
import { IconContainer } from '../../../../../../containers/icon'
import { useStatistic } from '../../../../../../hooks/useStatistic'
import { getProcedure } from '../../../../../../store/procedure/selectors'
import { getIsWorking } from '../../../../../../store/userInterface/selectors'
import { sortStepsByBookmarks } from '../../../../../../utils/helpers/steps'
import { buildStepIndex } from '../../../../../../utils/helpers/steps'
import Translation from '../../../../../translations'
import StatisticPopoverContent from '../popover-content'

const Statistic = ({ description, type, onSelectStep, closeDrawer }) => {
  const procedure = useSelector(getProcedure)
  const isWorking = useSelector(getIsWorking)
  const { itemsToFill, filledItems, dontMeetCriteria } = useStatistic(type)
  const { width } = useWindowSize()

  const stepIndexes = useMemo(() => {
    if (!dontMeetCriteria || !Array.isArray(dontMeetCriteria)) return []

    return (
      dontMeetCriteria?.sort(sortStepsByBookmarks)?.map(({ stepId }) => {
        const stepIndex = buildStepIndex(procedure?.index.children, stepId)
        return {
          stepIndex,
          stepId
        }
      }) ?? []
    )
  }, [procedure, dontMeetCriteria])

  const items = filledItems ? filledItems.length : 0
  const totalItems =
    itemsToFill && filledItems ? itemsToFill.length + filledItems.length : 0
  const percentageProgress = (items * 100) / totalItems

  return (
    <StaticContainer id={description.props.id}>
      <SectionLabelContainer>
        <LabelStatistic>{description}</LabelStatistic>
      </SectionLabelContainer>
      <SectionContainer>
        <SectionTitle>
          <Translation id='completed' />
        </SectionTitle>
        <ValuesContainer>
          <ValueStatistic id={`filled-${type}`}>{items}</ValueStatistic>
          <ArrowRight color='#72408D' />
          <ValueStatistic id={`total-${type}`}>{totalItems}</ValueStatistic>
        </ValuesContainer>
        <ProgressBar
          percent={percentageProgress}
          showInfo={false}
          strokeColor='#645377'
          strokeLinecap='square'
          trailColor='#F1EEF7'
          success={{
            percent: percentageProgress === 100 ? 100 : 0,
            strokeColor: '#89D000'
          }}
        />
      </SectionContainer>
      <Divider
        type={
          width < 768 ? 'horizontal' : width < 1024 ? 'vertical' : 'horizontal'
        }
      />
      <SectionContainer>
        <SectionTitle>
          <Translation id='dont.meet.criteria' />
        </SectionTitle>
        <DontMeetCriteriaContainer>
          <ValueStatistic id={`dont-meet-criteria-${type}`}>
            {dontMeetCriteria?.length ?? 0}
          </ValueStatistic>
          <Popover
            placement='rightTop'
            content={() => (
              <StatisticPopoverContent
                steps={stepIndexes}
                loading={isWorking}
                onSelectStep={onSelectStep}
                closeDrawer={closeDrawer}
              />
            )}
            open={
              !dontMeetCriteria || dontMeetCriteria?.length === 0
                ? false
                : undefined
            }
          >
            <IconContainer icon={Eye} />
          </Popover>
        </DontMeetCriteriaContainer>
      </SectionContainer>
    </StaticContainer>
  )
}

const StaticContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  background: #f7f5fa;
  border-radius: 4px;
  width: 100%;
  padding: 16px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 0px 20px;
  }

  @media screen and (min-width: 1024px) {
    width: 17%;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 132px;
  }
`

const SectionLabelContainer = styled.section`
  width: 100%;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    width: 20%;
    margin-bottom: 0px;
  }

  @media screen and (min-width: 1024px) {
    width: 100%;
    margin-bottom: 10px;
  }
`

const SectionContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px 50px;
  flex-wrap: wrap;
  width: 100%;

  & h6 {
    width: 100%;
    text-align: start;

    @media screen and (min-width: 768px) {
      width: unset;
    }

    @media screen and (min-width: 1024px) {
      width: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    justify-content: space-between;
    width: 40%;
    gap: 0px 10px;
    flex-wrap: nowrap;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0px 10px;
    width: 100%;
  }
`

const DontMeetCriteriaContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`

const ValuesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 768px) {
    min-width: 80px;
    width: 80px;
  }

  @media screen and (min-width: 1024px) {
    min-width: unset;
    width: 100%;
  }
`

const SectionTitle = styled.h6`
  font-size: 14px;
  font-weight: 400;
  color: #72408d;
  line-height: 16px;
  font-family: 'Work Sans', sans-serif;
  margin: none;
  padding: none;

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
`

const LabelStatistic = styled.p`
  color: #000000;
  font-size: 16px;
  line-height: unset;
  font-weight: 600;
  font-family: inherit;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 1200px) {
    font-size: 20px;
    line-height: 18.77px;
  }
`

const ValueStatistic = styled.p`
  color: #72408d;
  font-size: 20px;
  line-height: 46.92px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;
  font-family: inherit;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
  }
`

const ProgressBar = styled(Progress)`
  width: 100% !important;
  @media screen and (min-width: 768px) {
    width: 100px !important;
  }

  @media screen and (min-width: 1024px) {
    width: 100% !important;
  }
`

export default Statistic
