import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Eye from '../../../../../components/icons/eye'
import Pencil from '../../../../../components/icons/pencil'
import { getConnectedUsers } from '../../../../../store/sharedExecution/selectors'
import { getUser } from '../../../../../store/user-management/selectors'
import { EXECUTION_INTERACTION_MODE } from '../../../../../utils/constants/execution'
import { getColorFromSeed } from '../../../../../utils/helpers/styles'
import Translation from '../../../../translations'
import UserAvatar from '../../../../users/avatar'

const ConnectedUsersPopover = ({ onSelectStep }) => {
  const currentUser = useSelector(getUser)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const connectedUsers = useSelector(getConnectedUsers)

  if (!connectedUsers.length) {
    return null
  }

  return (
    <div>
      <OnlineUsersTitle>
        <Translation id='onlineUsers' />
      </OnlineUsersTitle>
      {connectedUsers.map((u) => {
        const isCurrentUser = u.user.id === currentUser.id
        const color = isCurrentUser ? '#007EC3' : getColorFromSeed(u.user.id)
        const textColor = isCurrentUser ? '#fff' : '#000'
        const isSelected = selectedUserId === u.user.id
        const Icon = u.mode === EXECUTION_INTERACTION_MODE.READ ? Eye : Pencil

        return (
          <UserLine
            key={u.user.id}
            showBorder={isSelected && u.currentStep}
            color={color}
            onClick={() => {
              setSelectedUserId(u.user.id)
              if (u.currentStep) {
                onSelectStep([u.currentStep])
              }
            }}
          >
            <UserAvatar user={u.user} color={color} textColor={textColor} />
            <UserText>
              <UserName isCurrent={isCurrentUser}>{u.user.name}</UserName>
              <UserEmail>{u.user.email}</UserEmail>
            </UserText>

            <ExecutionMode data-asdf='asdf'>
              <Icon size={16} />
            </ExecutionMode>
          </UserLine>
        )
      })}
    </div>
  )
}

const OnlineUsersTitle = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 18px;
`

const UserLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 4px;
  box-shadow: ${(props) =>
    props['showBorder']
      ? `0px 0px 0px 3px #ffffff, 0px 0px 0px 4px ${props.color} `
      : 'none'};
`

const UserName = styled.span`
  font-weight: ${({ isCurrent }) => (isCurrent ? 'bold' : 'normal')};
  text-decoration: ${({ isCurrent }) => (isCurrent ? 'underline' : 'none')};
  font-size: 16px;
`

const UserEmail = styled.span`
  font-weight: normal;
  text-decoration: none;
  font-size: 12px;
`

const UserText = styled.span`
  flex: 1;
  margin-left: 8px;
  display: inline-flex;
  flex-direction: column;
`

const ExecutionMode = styled.span`
  margin-left: 8px;
  width: 16px;
  height: 16px;
`

export default ConnectedUsersPopover
