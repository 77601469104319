import { Button, Input as AntdInput } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import useHistoricalComponent from '../../../../hooks/use-historical-component'
import { colorDisabled, colorEnabled } from '../../../../utils/constants/styles'
import { getWidthInPixels } from '../../../../utils/helpers/styles'

const Input = ({
  onComponentChange,
  component,
  executionComponent,
  disabled,
  className,
  isHistoricalMode
}) => {
  const { options = {}, procedureComponentId } = component
  const { size } = options
  const [typedValue, setTypedValue] = useState(executionComponent?.value)

  const {
    historicalModeEnabled,
    historicalComponentActive,
    onClickHistorical
  } = useHistoricalComponent({
    isHistoricalMode,
    showHistorical: options?.showHistorical,
    component
  })

  useEffect(() => {
    setTypedValue(executionComponent?.value)
  }, [executionComponent?.value])

  return (
    <InputButton
      disabled={disabled}
      color='#edece9'
      data-active={historicalComponentActive}
      data-size={size ? getWidthInPixels(size) : '120px'}
      onClick={historicalModeEnabled ? onClickHistorical : undefined}
      className={
        historicalModeEnabled
          ? `${className} historical-component`
          : `${className} recorder-input`
      }
    >
      <AntdInput
        disabled={disabled}
        onBlur={() => {
          if (executionComponent?.value !== typedValue) {
            onComponentChange({
              id: procedureComponentId,
              value: typedValue
            })
          }
        }}
        onChange={({ target: { value } }) => {
          setTypedValue(value)
        }}
        value={typedValue}
        maxLength={size ?? ''}
      />
    </InputButton>
  )
}

const InputButton = styled(Button)`
  width: ${(props) => props['data-size']};
  height: 35px;
  padding: 0;
  margin: 0 2px;
  border-radius: 2px;

  & input {
    color: ${({ disabled }) =>
      disabled ? `${colorDisabled} !important` : `${colorEnabled} !important`};
    border-radius: 2px;
  }

  @media print {
    & > input {
      width: ${(props) => props['data-size']};
    }
  }
`

export default Input
