import React from 'react'
import styled from 'styled-components'

import Info from '../../components/icons/info'
import WarningIcon from '../../components/icons/warning-icon'
import { IconContainer } from '../icon'

const MessageContainer = ({
  children,
  type = 'info',
  iconColor = 'currentColor',
  mode
}) => {
  let iconData = { icon: Info, iconColor }

  switch (type) {
    case 'info':
      iconData.icon = Info
      break
    case 'warning':
      iconData.icon = WarningIcon
      iconData.iconColor = '#EFB410'
      break
    default:
      iconData.icon = Info
      break
  }
  return (
    <Container>
      <IconContainer color={iconData.iconColor} icon={iconData.icon} />
      <Text data-textmode={mode}>{children}</Text>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px;
  top: 56px;
  background-color: #efeeeb;
  width: 100%;
`

const Text = styled.span`
  font-size: 16px;
  font-weight: ${(props) =>
    props['data-textmode'] === 'light' ? '400' : '500'};
  color: #000000;
  margin: 0;
  padding: 0;
`

export default MessageContainer
