import { Button as AntButton } from 'antd'
import React from 'react'
import styled from 'styled-components'

const ModalButton = styled(({ ...props }) => {
  return <AntButton {...props} />
})`
  height: 40px;
  width: 136px;
  border-radius: 2px;
`

export default ModalButton
