import React from 'react'

const ProcedureIcon = ({ size = '24', color = 'currentColor' }) => (
    <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.25 14.2617H16.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.25 18.7617H16.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.25 9.76172H16.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.25 14.6367C8.04289 14.6367 7.875 14.4688 7.875 14.2617C7.875 14.0546 8.04289 13.8867 8.25 13.8867'
      stroke={color}
      strokeWidth='1.5'
    />
    <path
      d='M8.25 14.6367C8.45711 14.6367 8.625 14.4688 8.625 14.2617C8.625 14.0546 8.45711 13.8867 8.25 13.8867'
      stroke={color}
      strokeWidth='1.5'
    />
    <path
      d='M8.25 19.1367C8.04289 19.1367 7.875 18.9688 7.875 18.7617C7.875 18.5546 8.04289 18.3867 8.25 18.3867'
      stroke={color}
      strokeWidth='1.5'
    />
    <path
      d='M8.25 19.1367C8.45711 19.1367 8.625 18.9688 8.625 18.7617C8.625 18.5546 8.45711 18.3867 8.25 18.3867'
      stroke={color}
      strokeWidth='1.5'
    />
    <path
      d='M8.25 10.1367C8.04289 10.1367 7.875 9.96882 7.875 9.76172C7.875 9.55461 8.04289 9.38672 8.25 9.38672'
      stroke={color}
      strokeWidth='1.5'
    />
    <path
      d='M8.25 10.1367C8.45711 10.1367 8.625 9.96882 8.625 9.76172C8.625 9.55461 8.45711 9.38672 8.25 9.38672'
      stroke={color}
      strokeWidth='1.5'
    />
    <path
      d='M15.75 4.5H19.5C19.8978 4.5 20.2794 4.65804 20.5607 4.93934C20.842 5.22064 21 5.60218 21 6V21.75C21 22.1478 20.842 22.5294 20.5607 22.8107C20.2794 23.092 19.8978 23.25 19.5 23.25H4.5C4.10218 23.25 3.72064 23.092 3.43934 22.8107C3.15804 22.5294 3 22.1478 3 21.75V6C3 5.60218 3.15804 5.22064 3.43934 4.93934C3.72064 4.65804 4.10218 4.5 4.5 4.5H8.25C8.25 3.50544 8.64509 2.55161 9.34835 1.84835C10.0516 1.14509 11.0054 0.75 12 0.75C12.9946 0.75 13.9484 1.14509 14.6517 1.84835C15.3549 2.55161 15.75 3.50544 15.75 4.5Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 4.51172C11.7929 4.51172 11.625 4.34382 11.625 4.13672C11.625 3.92961 11.7929 3.76172 12 3.76172'
      stroke={color}
      strokeWidth='1.5'
    />
    <path
      d='M12 4.51172C12.2071 4.51172 12.375 4.34382 12.375 4.13672C12.375 3.92961 12.2071 3.76172 12 3.76172'
      stroke={color}
      strokeWidth='1.5'
    />
  </svg>
)

export default ProcedureIcon
