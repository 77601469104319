import { Modal as AntModal } from 'antd'
import React from 'react'
import { createRoot } from 'react-dom/client'
import styled from 'styled-components'

import ModalButton from '../../components/buttons/modal-button'
import Translation from '../translations'

const Modal = ({
  okButtonText = <Translation id='confirm' />,
  cancelButtonText = <Translation id='cancel' />,
  okButtonIcon,
  cancelButtonIcon,
  cancellable = true,
  loading,
  loadingCancel,
  onOk,
  onClose,
  onCancel,
  formId,
  disableOkButton,
  ...antdProps
}) => {
  return (
    <AntModal
      width={600}
      onCancel={onClose || onCancel}
      footer={
        <Footer>
          {cancellable && (
            <CancelButton
              loading={loadingCancel}
              disabled={loading}
              onClick={onCancel}
            >
              <ButtonIcon>{cancelButtonIcon}</ButtonIcon>
              {cancelButtonText}
            </CancelButton>
          )}
          <ConfirmButton
            id={antdProps?.okButtonProps?.id}
            form={formId}
            htmlType={formId ? 'submit' : 'button'}
            type
            disabled={disableOkButton}
            onClick={() => {
              onOk?.()
            }}
            loading={loading}
          >
            <ButtonIcon>{okButtonIcon}</ButtonIcon>
            {okButtonText}
          </ConfirmButton>
        </Footer>
      }
      bodyStyle={antdProps.children ? {} : { display: 'none' }}
      {...antdProps}
    />
  )
}

export function showComponentModal(Component, props) {
  const div = document.createElement('div')
  document.body.appendChild(div)

  const root = createRoot(div)

  const allProps = {
    visible: true,
    onClose: () => {
      const unmountResult = root.unmount()
      if (unmountResult && div.parentNode) {
        div.parentNode.removeChild(div)
      }
    },
    ...props
  }

  root.render(<Component {...allProps} />)
}

const Footer = styled.div`
  display: flex;
  padding: 24px 16px 24px 16px;
`

const StyledButton = styled(ModalButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

const ConfirmButton = styled(StyledButton)`
  background-color: #645377;
  color: #fff;
`

const CancelButton = styled(StyledButton)`
  background-color: #fff;
  color: #000;
`

const ButtonIcon = styled(({ children, className }) =>
  children ? React.cloneElement(children, { size: '16px', className }) : null
)`
  margin-right: 8px;
  height: 16px;
`

export default Modal
