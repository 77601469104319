import { TABLE_NAMES } from '../../../../utils/constants/localdb'

export function loadVersion1(db) {
  return db.version(1).stores({
    [TABLE_NAMES.PROCEDURES]:
      'id, unit, series, key, rev, title, buildNumber, status, [id+unit+series+key+rev+title+buildNumber+status]',
    [TABLE_NAMES.EXECUTIONS]:
      'id, procedureId, status, createdAt, updatedAt, syncedAt',
    [TABLE_NAMES.EXECUTION_COMPONENTS]: `id, createdAt, updatedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
    [TABLE_NAMES.EXECUTION_STEPS]: `id, createdAt, updatedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
    [TABLE_NAMES.EXECUTION_MANEUVERS]: `id, createdAt, updatedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
    [TABLE_NAMES.EXECUTION_WARNINGS]: `id, createdAt, updatedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
    [TABLE_NAMES.EXECUTION_COMMENTS]: `id, createdAt, updatedAt, deletedAt, syncedAt, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
    [TABLE_NAMES.FILES]: 'id',
    [TABLE_NAMES.FILES_CONTENT]: 'id',
    [TABLE_NAMES.MEDIA]: 'id',
    [TABLE_NAMES.USERS]: 'id',
    [TABLE_NAMES.PLACEKEEPING_HISTORY]: 'id, executionId, stepId, createdAt',
    [TABLE_NAMES.EXECUTION_SYNC_STATUS]: `id, ${TABLE_NAMES.EXECUTIONS}, ${TABLE_NAMES.EXECUTION_COMPONENTS}, ${TABLE_NAMES.EXECUTION_STEPS}, ${TABLE_NAMES.EXECUTION_MANEUVERS}, ${TABLE_NAMES.EXECUTION_WARNINGS}, ${TABLE_NAMES.EXECUTION_COMMENTS}, executionId -> ${TABLE_NAMES.EXECUTIONS}.id`,
    [TABLE_NAMES.CUSTOMER_SETTINGS]: 'id, name, syncedAt, createdAt, updatedAt',
    [TABLE_NAMES.EXECUTION_GOALS]: 'id, name, createdAt, updatedAt'
  })
}
