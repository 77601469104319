import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.UNITS

export async function readLocalUnits() {
  const db = getDatabase()

  return db[tableName].toArray()
}

export async function readLocalUnitById(id) {
  const db = getDatabase()

  return db[tableName].where('id').equals(id)
}

export async function readLocalUnitByName(name) {
  const db = getDatabase()

  return db[tableName].where('name').equals(name).toArray()
}
