import { readFiles } from '../../../localdb/files/read'
import { writeFile } from '../../../localdb/files/write'
import { readFilesContent } from '../../../localdb/filesContent/read'
import { writeFilesContent } from '../../../localdb/filesContent/write'
import { getContent } from '../../../services/files/download/http/get'
import { getFile, getFiles } from '../../../services/files/http/get'
import { EXECUTION_COMPONENT_TYPES } from '../../../utils/constants/execution/components'
import { ALLOWED_VIDEO_EXTENSIONS } from '../../../utils/constants/files'
import { erro } from '../../../utils/logger'

export async function downloadFiles(ids, info, content) {
  const filesInfo = info ?? (await readFiles(ids))
  const filesContent = content ?? (await readFilesContent(ids))

  //Descargamos la información de los ficheros que no tengamos ya guardados
  const missingInfoIds = ids.filter(
    (id) => !filesInfo.find((info) => info.id === id)
  )

  if (missingInfoIds.length) {
    const { data: files } = await getFiles(missingInfoIds)
    await writeFile(files.map((f) => ({ ...f, sync: true })))
    filesInfo.push(...files)
  }

  const videoIds = filesInfo
    .map((f) => ALLOWED_VIDEO_EXTENSIONS.includes(f.extension) && f.id)
    .filter(Boolean)

  //Descargamos el contenido de los ficheros que no tengamos ya guardados
  const missingContentIds = ids.filter(
    (id) => !filesContent.find((content) => content.id === id)
  )
  if (missingContentIds.length) {
    const missingContentIdsWithoutVideos = missingContentIds.filter(
      (id) => !videoIds.includes(id)
    )

    await Promise.all(
      missingContentIdsWithoutVideos.map(async (fileId) => {
        const response = await getContent(fileId)

        const fileInfo = filesInfo.find((c) => c.id === fileId)
        const file = new File([response.content], fileInfo.originalName, {
          type: fileInfo.mimeType
        })
        await writeFilesContent([{ id: response.id, content: file }])
        filesContent.push({ id: response.id, content: file })
      })
    )
  }

  //Devolvemos los ficheros enteros (info + content)
  return filesInfo.map((info) => {
    const fileContent = filesContent.find((c) => c.id === info.id)
    const file = fileContent?.data ?? fileContent?.content
    return {
      ...info,
      file: ALLOWED_VIDEO_EXTENSIONS.includes(info.extension) ? undefined : file
    }
  })
}

export async function downloadProceduresFiles(procedures) {
  const procedureFiles = []
  let downloadError = false

  procedures.forEach((procedure) => {
    const fileIds = procedure.components
      .filter((c) => c.type === EXECUTION_COMPONENT_TYPES.FILE_REFERENCE)
      .map((c) => c.options.fileId)

    procedureFiles.push(...fileIds)
  })

  const filesInfo = await readFiles(procedureFiles)
  const filesContent = await readFilesContent(procedureFiles)

  if (
    procedureFiles.length !== filesInfo.length ||
    procedureFiles.length !== filesContent.length
  ) {
    const promises = procedureFiles.map(async (fileId) => {
      if (!filesInfo.find((f) => f.id === fileId)) {
        // Obtenemos la info de los ficheros que no tenemos en local
        try {
          const { data } = await getFile(fileId)
          await writeFile([{ sync: true, ...data }])
        } catch (e) {
          erro(`File info ${fileId} not found in server. Error: ${e.message}`)
          downloadError = true
        }
      }

      if (!filesContent.find((f) => f.id === fileId)) {
        // Obtenemos el contenido de los ficheros que no tenemos en local
        try {
          const fileContent = await getContent(fileId)
          await writeFilesContent([fileContent])
        } catch (e) {
          erro(`File ${fileId} not found in server. Error: ${e.message}`)
          downloadError = true
        }
      }
    })
    await Promise.all(promises)
  }

  return downloadError
}
