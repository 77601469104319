import React from 'react'

import ExtraHeaderContentButton from '../../../components/buttons/extra-header-content'
import Pause from '../../../components/icons/pause'
import ExtraHeaderContentMessage from '../../../components/message/extra-header-content'
import ExtraHeaderContentContainer from '../../../containers/extra-header-content'
import { IconContainer } from '../../../containers/icon'
import Translation from '../../translations'

const MassiveInterruptionButton = ({ visible, setVisible }) => {
  return (
    <ExtraHeaderContentContainer onClick={() => setVisible(!visible)}>
      <ExtraHeaderContentButton
        id='massive-interruption-button'
        onClick={() => setVisible(!visible)}
        type='primary'
      >
        <IconContainer color='#ffffff' icon={Pause} />
      </ExtraHeaderContentButton>
      <ExtraHeaderContentMessage>
        <Translation id='interruptButton' />
      </ExtraHeaderContentMessage>
    </ExtraHeaderContentContainer>
  )
}

export default MassiveInterruptionButton
