import { Progress } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useLocalExecutions } from '../../../hooks/data/useLocalExecutions'
import { useFilters } from '../../../hooks/useFilters'
import { EXECUTION_ROUTER_MODE } from '../../../utils/constants/execution'
import { PERMISSION_TYPES } from '../../../utils/constants/roles'
import { COLUMN_TYPES } from '../../../utils/constants/table'
import { userHasPermission } from '../../../utils/helpers/permissions'
import BasePage from '../../../views/base-page'
import MassiveInterruptionButton from '../../../views/buttons/massive-interruption-button'
import SyncExecutionsButton from '../../../views/buttons/sync-executions'
import { GlobalDrawerButton } from '../../../views/global-drawer-button'
import MassiveInterruptionModal from '../../../views/modal/massive-interruption-modal'
import BaseTable from '../../../views/tables/base-table'
import ExecutionStatusTag from '../../../views/tags/execution-status'
import UnitTag from '../../../views/tags/unit'
import Translation from '../../../views/translations'
import { GlobalExecutionsDrawer } from '../GlobalExecutionsDrawer'

import { DeleteExecutionButton } from './DeleteExecutionButton'
import { Filters } from './Filters'


export const ExecutionsFiltersContext = React.createContext()

const columns = [
  {
    title: <Translation id='unit' />,
    dataIndex: 'procedure.unit',
    minWidth: 70,
    render: (procedureUnit) => <UnitTag unit={procedureUnit} />
  },
  {
    title: <Translation id='series' />,
    dataIndex: 'procedure.serieName',
    sorter: true,
    width: 70
  },
  {
    title: <Translation id='key' />,
    dataIndex: 'procedure.key',
    sorter: true,
    minWidth: 90
  },
  {
    title: <Translation id='title' />,
    dataIndex: 'procedure.title',
    sorter: true,
    minWidth: 100
  },
  {
    title: <Translation id='rev' />,
    dataIndex: 'procedure.rev',
    sorter: true,
    width: 50
  },
  {
    title: <Translation id='lastMod' />,
    dataIndex: 'updatedAt',
    defaultSortOrder: 'descend',
    width: 70,
    type: COLUMN_TYPES.DATE
  },
  {
    title: <Translation id='startDate' />,
    dataIndex: 'createdAt',
    width: 70,
    type: COLUMN_TYPES.DATE
  },
  {
    title: <Translation id='owner' />,
    dataIndex: 'user.name',
    sorter: true,
    width: 123
  },
  {
    title: <Translation id='status' />,
    dataIndex: 'status',
    sorter: true,
    width: 136,
    fixed: 'right',
    render: (status) => <ExecutionStatusTag status={status} />
  },
  {
    title: <Translation id='sync' />,
    dataIndex: 'synced',
    fixed: 'right',
    minWidth: 40,
    render: (synced) => (
      <CustomProgress
        percent={100}
        status={synced ? 'success' : 'exception'}
        type='circle'
        width={14}
        size='small'
        strokeColor={synced ? '#52c41a' : '#f5222d'}
        $background={synced}
      />
    )
  },
  {
    title: <Translation id='action' />,
    key: 'action',
    fixed: 'right',
    width: 100,
    dataIndex: 'id',
    render: (id) => {
      return <DeleteExecutionButton executionId={id} />
    },
    onCell: () => {
      return {
        onClick: (e) => e.stopPropagation()
      }
    }
  }
]

export const LocalExecutions = () => {
  const history = useHistory()
  const defaultSortColumn = columns.find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'updatedAt',
    order: defaultSortColumn?.defaultSortOrder || 'descend'
  })
  const [showMassiveInterruption, setShowMassiveInterruption] = useState(false)

  const [globalExecutionsDrawerVisible, setGlobalExecutionsDrawerVisible] =
    useState(false)
  const { filters, updateFilters, resetFilters } = useFilters()

  const [localExecutions, isLoading] = useLocalExecutions({
    withProcedure: true,
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    ...filters
  })

  const handleTableChange = (_pagination, _, sorter) => {
    setSorter({ ...sorter })
  }

  return (
    <BasePage
      title={<Translation id='executionsOnDevice' />}
      extraHeaderContent={
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
          <SyncExecutionsButton
            disabled={
              localExecutions &&
              (localExecutions.length === 0 ||
                localExecutions.filter((execution) => execution.synced)
                  .length === localExecutions?.length)
            }
          />
          <MassiveInterruptionButton
            setVisible={setShowMassiveInterruption}
            visible={showMassiveInterruption}
          />
          <GlobalDrawerButton
            drawerVisible={globalExecutionsDrawerVisible}
            setDrawerVisible={setGlobalExecutionsDrawerVisible}
            message={<Translation id='downloadExecutionsTitle' />}
            id={'download-global-executions-button'}
            hasPermission={userHasPermission(
              PERMISSION_TYPES.EXECUTION_DOWNLOAD_OTHER_USER
            )}
          />
        </div>
      }
      subHeaderContent={
        <ExecutionsFiltersContext.Provider
          value={{ filters, updateFilters, resetFilters }}
        >
          <Filters />
        </ExecutionsFiltersContext.Provider>
      }
      totalElements={localExecutions?.length}
    >
      <BaseTable
        id='local-executions-table'
        loading={isLoading}
        onChange={handleTableChange}
        columns={columns}
        dataSource={localExecutions}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push(
                `/executions/${record.id}/${EXECUTION_ROUTER_MODE.RUNNING}`
              )
            }
          }
        }}
      />
      <GlobalExecutionsDrawer
        visible={globalExecutionsDrawerVisible}
        setVisible={(value) => setGlobalExecutionsDrawerVisible(value)}
      />
      {showMassiveInterruption && (
        <MassiveInterruptionModal
          visible={showMassiveInterruption}
          setVisible={setShowMassiveInterruption}
        />
      )}
    </BasePage>
  )
}

const CustomProgress = styled(Progress)`
  svg.ant-progress-circle {
    background: ${(props) => (props.$background ? '#52c41a' : '#f5222d')};
  }
  .anticon svg {
    color: white;
  }
`
