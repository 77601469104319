import { Checkbox, Input, Popover } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Lock from '../../../../components/icons/lock'
import WarningIcon from '../../../../components/icons/warning-icon'
import useReadout from '../../../../hooks/use-readout'
import { getUser } from '../../../../store/user-management/selectors'
import { POOR_QUALITY } from '../../../../utils/constants/config'
import { getWidthInPixels } from '../../../../utils/helpers/styles' 
import Translation from '../../../translations'

const initReadoutComponent = {
  componentId: '',
  quality: '',
  unit: '',
  value: '',
  editedBy: null
}

export const ReadoutRT = ({
  className,
  component,
  onComponentChange,
  executionComponent,
  isReadoutEditMode,
  isReadoutLockMode,
  checkReadoutValue,
  error,
  setInputValue
}) => {
  const { options = {}, procedureComponentId } = component || {}
  const isEdited = executionComponent?.isEdited
  const { signalIdentifier, shouldPersist = true } = options
  const {
    componentId = '',
    value = '',
    quality = '',
    unit = '',
    editedBy = null
  } = executionComponent?.liveValue || {}
  const isPoor = quality === POOR_QUALITY
  const { initials } = useSelector(getUser)
  const dispatch = useDispatch()

  const [isLocked, setIsLocked] = useState(executionComponent?.isLocked)
  const [isEditing, setIsEditing] = useState(isEdited || isReadoutEditMode)
  const [readoutValues, setReadoutValues] = useState(
    executionComponent?.liveValue
  )
  const { handleEditReadout, handleToggleEditMode, handleToggleLock } =
    useReadout({
      value,
      isLocked,
      setIsLocked,
      procedureComponentId,
      readoutValues,
      setReadoutValues,
      shouldPersist,
      onComponentChange,
      setIsEditing,
      initReadoutComponent,
      initials,
      dispatch
    })

  useEffect(() => {
    if (isEditing || isLocked) return
    if (executionComponent?.liveValue && !isPoor) {
      setReadoutValues(executionComponent?.liveValue)
    } else {
      setReadoutValues(initReadoutComponent)
    }
  }, [
    executionComponent?.liveValue,
    isPoor,
    readoutValues?.quality,
    isEditing,
    isLocked
  ])

  const sizeContainer = options.size ? getWidthInPixels(options.size) : '140px'
  const sizeContainerNumber = parseInt(sizeContainer.replace('px', ''))

  return (
    <ReadoutWrapper>
      <ReadoutContainer>
        <Container
          key={'w' + componentId}
          data-testid={componentId}
          data-bgcolor={'#d7d7d7'}
          data-size={sizeContainer}
          data-locked-mode={isLocked}
          className={className}
          onClick={() => {
            isReadoutLockMode && !isEditing && !isEdited && handleToggleLock()
          }}
        >
          <InfoContainer>
            <Label>{signalIdentifier}</Label>
            <DataContainer
              data-bgcolor='#d7d7d7'
              data-size={'100%'}
              data-jfcontent={error ? 'unset' : 'space-between'}
            >
              <Wrapper
                data-bgcolor='#efefef'
                color='#645377'
                data-size-container={sizeContainerNumber}
                data-size={error ? 0.4 : 0.55}
                data-locked-mode={isLocked}
              >
                {isEditing && isReadoutEditMode ? (
                  <Input
                    size='small'
                    name='readout-value'
                    value={readoutValues?.value}
                    onChange={({ target: { value } }) => {
                      if (setInputValue !== undefined) {
                        setInputValue(value)
                        checkReadoutValue(value) &&
                          handleEditReadout('value', value)
                      } else handleEditReadout('value', value)
                    }}
                  />
                ) : (
                  <>
                    {isLocked && <Lock />} {value}
                  </>
                )}
              </Wrapper>
              <Wrapper
                data-bgcolor='#efefef'
                color='unset'
                data-size-container={sizeContainerNumber}
                data-size={0.15}
              >
                {quality}
              </Wrapper>
              <Wrapper
                data-bgcolor='unset'
                color='unset'
                data-size-container={sizeContainerNumber}
                data-size={0.3}
              >
                {isEditing && isReadoutEditMode ? (
                  <Input
                    size='small'
                    name='readout-unit'
                    value={readoutValues?.unit}
                    onChange={({ target: { value } }) =>
                      handleEditReadout('unit', value)
                    }
                  />
                ) : (
                  unit || readoutValues?.unit
                )}
              </Wrapper>
            </DataContainer>
          </InfoContainer>
          {error && (
            <Popover
              content={<Translation id={error?.id} params={error?.params} />}
            >
              <WarningIcon size={24} color='#ff0000' />
            </Popover>
          )}
        </Container>
        {(editedBy || readoutValues?.editedBy) && (
          <EditedByLabel>
            <Translation id='readout.editedBy.text' />{' '}
            {readoutValues.editedBy ?? editedBy}
          </EditedByLabel>
        )}
      </ReadoutContainer>
      {isReadoutEditMode && !isLocked && (
        <Checkbox
          checked={isEditing || isLocked}
          onChange={(e) => handleToggleEditMode(e)}
        />
      )}
    </ReadoutWrapper>
  )
}

const ReadoutWrapper = styled.span`
  display: flex;
  gap: 8px;
  height: 100%;
  align-items: center;
  margin: 0 18px;

  @media print {
    display: inline-block;
    height: auto;
  }
`
const ReadoutContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 100%;

  @media print {
    height: auto;
  }
`

const Container = styled.span`
  border: ${(props) =>
    props['data-locked-mode'] && '1px solid #645377 !important'};
  background-color: ${(props) => props['data-bgcolor']};
  border-radius: 1px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-width: ${(props) => props['data-size']};

  @media print {
    width: ${(props) => props['data-size']};
    height: auto;
  }
`
const InfoContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  @media print {
    width: auto;
    height: auto;
  }
`
const Label = styled.label`
  font-size: 12px;
`
const DataContainer = styled.span`
  background-color: ${(props) => props['data-bgcolor']};
  display: inline-flex;
  font-size: 13px;
  gap: 2px;
  height: 100%;
  justify-content: ${(props) => props['data-jfcontent']};
  width: ${(props) => props['data-size']};

  @media print {
    width: auto;
    height: auto;
    min-height: 15px;
  }
`
const Wrapper = styled.span`
  background-color: ${(props) =>
    props['data-locked-mode'] ? '#F6F0FC' : props['data-bgcolor']};
  color: ${(props) => props['color']};
  text-align: center;
  width: ${(props) => {
    const sizeContainer = props['data-size-container']
    const size = props['data-size']

    return `${(sizeContainer * size).toFixed(2)}px`
  }};

  @media print {
    display: inline-block;
    height: 100%;
  }
`
const EditedByLabel = styled.span`
  color: #afafaf;
  margin-bottom: 8px;
`
export default ReadoutRT
