import {
  Modal as AntModal,
  Form as AntForm,
  Input,
  Button as AntButton,
  message,
  Layout,
  Tooltip
} from 'antd'
import React, { useEffect, useState, useCallback, useContext } from 'react'
import styled from 'styled-components'

import packageJson from '../../../package.json'
import { requestAndSaveDevice } from '../../application/device/save'
import { login } from '../../application/users/session'
import Info from '../../components/icons/info'
import Lock from '../../components/icons/lock'
import User from '../../components/icons/user'
import LegalPolicies from '../../components/legal'
import { IconContainer } from '../../containers/icon'
import LanguageContext from '../../context/language'
import { useTranslation } from '../../hooks/useTranslation'
import { getTranslation } from '../../i18n/getTranslation'
import { deleteUserData } from '../../localdb/user'
import { cleanExecutions } from '../../store/execution/localDb'
import { cleanProcedures } from '../../store/procedure/localDb'
import { FULL_SCREEN } from '../../utils/constants/config'
import { encryptSHA256 } from '../../utils/helpers/crypto'
import { getDeviceId } from '../../utils/helpers/device'
import { getUserLocalStorageItem, userExists } from '../../utils/helpers/user'
import ChangePasswordModal from '../../views/modal/change-password-modal'
import ModalMandatoryReading from '../../views/modal/modal-mandatory-reading'
import Translation from '../../views/translations'

import { ConfirmSkipAndDeleteModal } from './ConfirmSkipAndDeleteModal'
import { InsertPasswordModal } from './InsertPasswordModal'


const { Content, Footer } = Layout

export const Login = () => {
  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [passwordModalShown, setPasswordModalShown] = useState(null)
  const [passwordModalMode, setPasswordModalMode] = useState(null)
  const [showLegalClauses, setShowLegalClauses] = useState(false)
  const [acceptedLegalClauses, setAcceptedLegalClauses] = useState(false)
  const [userClickedLogin, setUserClickedLogin] = useState(false)
  const [changePassModalVisible, setChangePassModalVisible] = useState(false)
  const [confirmDeleteDataModalVisible, setConfirmDeleteDataModalVisible] =
    useState(false)

  const [form] = Form.useForm()

  const { setLanguage } = useContext(LanguageContext)

  const tryLogin = useCallback(async () => {
    setConfirmLoading(true)

    const { showError, askPassword, isLoginOk, changeFirstPassword } =
      await login(username, password)

    const language = getUserLocalStorageItem('language', username, encryptSHA256(password))
    if (language) {
      setLanguage(language)
    }

    if (isLoginOk) {
      await cleanExecutions()
      await cleanProcedures()
    } else {
      setUserClickedLogin(false)
    }

    if (showError) {
      message.error(getTranslation(showError), 5)
    }

    if (changeFirstPassword) {
      setChangePassModalVisible(true)
    }

    if (askPassword) {
      setPasswordModalMode(askPassword)
      setPasswordModalShown(true)
    }

    if (isLoginOk && !getDeviceId()) {
      await requestAndSaveDevice()
    }

    if (FULL_SCREEN) {
      document.documentElement.requestFullscreen()
    }
  }, [password, username, setLanguage])

  useEffect(() => {
    if (username && userClickedLogin) {
      if (userExists(username) || acceptedLegalClauses) {
        tryLogin()
      } else if (!acceptedLegalClauses) {
        setShowLegalClauses(true)
      }
    }
  }, [acceptedLegalClauses, tryLogin, userClickedLogin, username])

  return (
    <PageWrapper>
      <Content>
        <Modal
          centered
          closable={false}
          maskClosable={false}
          open={true}
          confirmLoading={confirmLoading}
          footer={null}
          maskStyle={{ background: 'transparent' }}
        >
          <ImageWrapper>
            <img
              src='/public/ProceedLarge.png'
              alt='Logo Proceed'
              width='250'
            />
          </ImageWrapper>
          <Form
            id='login-form'
            name='basic'
            initialValues={{ remember: true }}
            onFinish={() => setUserClickedLogin(true)}
            form={form}
          >
            <Form.Item
              value={username}
              onChange={(ev) => setUsername(ev.target.value?.toLowerCase())}
              name='username'
              rules={[
                { required: true, message: <Translation id='enterUserName' /> }
              ]}
            >
              <Input
                autoComplete='username'
                placeholder={useTranslation('username')}
                prefix={<User />}
              />
            </Form.Item>

            <Form.Item
              name='password'
              value={password}
              onChange={(ev) => setPassword(ev.target.value)}
              rules={[
                { required: true, message: <Translation id='enterPassword' /> }
              ]}
            >
              <Input.Password
                autoComplete='current-password'
                placeholder={useTranslation('password')}
                prefix={<Lock size='16px' />}
              />
            </Form.Item>

            <ForgotPasswordSection>
              <ForgotPasswordText>
                <Translation id='login.forgot.password' />
              </ForgotPasswordText>
              <Tooltip
                placement='bottom'
                title={<Translation id='login.forgot.password.tooltip' />}
              >
                <IconContainer size='16' color='#3FA9F5' icon={Info} />
              </Tooltip>
            </ForgotPasswordSection>

            <Button id='login-button' type='primary' htmlType='submit'>
              <Translation id='loginButton' />
            </Button>
          </Form>
        </Modal>
        <InsertPasswordModal
          visible={passwordModalShown}
          setVisible={setPasswordModalShown}
          mode={passwordModalMode}
          username={username}
          password={password}
          onFinish={(modalPassword) => {
            setPasswordModalShown(false)
            setPassword(modalPassword)
            form.setFieldsValue({ password: modalPassword })
            setUserClickedLogin(true)
          }}
          onSkipAndContinue={() => setConfirmDeleteDataModalVisible(true)}
        />
        <ModalMandatoryReading
          id='legal-clauses-modal'
          modalHeight={400}
          modalWidth={600}
          pages={[
            {
              title: null,
              content: <LegalPolicies />
            }
          ]}
          modalVisible={showLegalClauses}
          setModalVisible={setShowLegalClauses}
          onAccept={() => setAcceptedLegalClauses(true)}
          onCancel={() => setUserClickedLogin(false)}
        />
        <ChangePasswordModal
          visible={changePassModalVisible}
          setVisible={setChangePassModalVisible}
          isFirstTime
          email={username}
          onFinish={() => {
            setPassword(null)
            form.setFieldsValue({ password: null })
          }}
        />

        <ConfirmSkipAndDeleteModal
          visible={confirmDeleteDataModalVisible}
          setVisible={setConfirmDeleteDataModalVisible}
          onOk={() => {
            setPasswordModalShown(false)
            setConfirmDeleteDataModalVisible(false)
            deleteUserData(username)
          }}
        />
      </Content>
      <Version>
        <Translation id='proceedVersion' /> {packageJson.version}
      </Version>
    </PageWrapper>
  )
}

const PageWrapper = styled(Layout)`
  background: #f2f2f2;
  height: 100vh;
`

export const Modal = styled(AntModal)`
  background: #fff;
  border-radius: 4px;
  padding: 0;
  max-width: 340px;
  > .ant-modal-content {
    border-radius: 4px;
    box-shadow: none;
  }
  > .ant-modal-content .ant-modal-body {
    padding: 48px 40px;
  }
`

export const Form = styled(AntForm)`
  .ant-input-affix-wrapper {
    border-radius: 2px;
    padding: 12px;
  }
  .anticon {
    color: #000;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`

const Version = styled(Footer)`
  background: #f2f2f2;
  border-top: 1px solid #e1e1db;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
  font-weight: 500;
  text-align: center;
  padding: 5px 0;
`

export const Button = styled(AntButton)`
  width: 100%;
  border-radius: 2px;
  padding: 12px 0;
  height: 100%;
`

const ForgotPasswordSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  margin-bottom: 32px;
`

const ForgotPasswordText = styled.p`
  padding: 0px;
  margin: 0px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: rgba(51, 51, 51, 0.45);
`
