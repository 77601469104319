export const PROCEDURE_STATUS = {
  DRAFT: 'DRAFT',
  REVIEW: 'REVIEW',
  APPROVED: 'APPROVED',
  PUBLISHED: 'PUBLISHED',
  DEPRECATED: 'DEPRECATED'
}

export const PROCEDURE_STATUS_CHANGES = {
  DRAFT: ['REVIEW', 'APPROVED'],
  REVIEW: ['DRAFT', 'APPROVED'],
  APPROVED: ['REVIEW', 'PUBLISHED'],
  PUBLISHED: ['DEPRECATED'],
  DEPRECATED: []
}
