import Dexie from 'dexie'
import middleware from 'dexie-easy-encrypt'
import relationships from 'dexie-relationships'

import { ENCRYPTED_TABLES } from '../../../utils/constants/localdb'
import { decrypt, encrypt } from '../../../utils/helpers/crypto'

import { loadVersion1 } from './db-v1'
import { loadVersion2 } from './db-v2'
import { loadVersion3 } from './db-v3'

export const LOAD_LOCALDB_FN = {
  1: loadVersion1,
  2: loadVersion2,
  3: loadVersion3
}

export const DEXIE_VERSION = 3

export async function loadLocalDb(username, encryptionKey) {
  const db = new Dexie(username, { addons: [relationships] })
  const load = LOAD_LOCALDB_FN[DEXIE_VERSION]

  middleware({
    db,
    tables: Object.values(ENCRYPTED_TABLES),
    encryption: {
      encrypt: (values) => encrypt(encryptionKey, values),
      decrypt: (data) => decrypt(encryptionKey, data)
    }
  })

  load(db, encryptionKey)

  return db.open()
}
