export const addToLocaldbQueue = (state, updatedValue) => {
  const componentToUpdateInQueue = state.localdbQueue.find(
    (queueValue) => queueValue?.id === updatedValue?.id
  )

  if (componentToUpdateInQueue?.updatedAt && !updatedValue?.updatedAt) return
  if (componentToUpdateInQueue?.updatedAt > updatedValue?.updatedAt) return

  const componentsInQueue = state.localdbQueue.filter(
    (queueValue) => queueValue?.id !== updatedValue?.id
  )

  state.localdbQueue = [...componentsInQueue, updatedValue]
}

/**
 * Delete entities from queue if there are with the same id and updatedAt
 * @param {*} state
 * @param {*} entitiesToDelete Object with id and updatedAt
 */
export const deleteFromLocaldbQueue = (state, entitiesToDelete) => {
  if (!entitiesToDelete) return deleteAllFromLocaldbQueue(state)

  state.localdbQueue = state.localdbQueue.filter(
    (_queuedEntity) =>
      !entitiesToDelete?.some(
        (_entity) =>
          _queuedEntity.id === _entity.id &&
          _entity.updatedAt === _queuedEntity.updatedAt
      )
  )
}

export const deleteAllFromLocaldbQueue = (state) => {
  state.localdbQueue = []
}

export const filterByUpdatedAt = (entities, stateEntities) => {
  return stateEntities?.filter((_stateEntity) =>
    entities?.some(
      (_entity) =>
        _stateEntity.id === _entity.id &&
        _entity.updatedAt === _stateEntity.updatedAt
    )
  )
}

export const updateLocaldbQueueEntity = (state, entity, resetQueue) => {
  if (resetQueue) {
    state.localdbQueue = []
  }

  if (Array.isArray(entity)) {
    entity.forEach((c) => addToLocaldbQueue(state, c))
    return
  }

  addToLocaldbQueue(state, entity)
}

export const updateSyncedAt = (stateEntities, entities, syncedAt) => {
  stateEntities
    ?.filter((c) => entities.some((e) => e.id === c.id))
    .forEach((c) => {
      c.syncedAt = syncedAt
    })
}
