import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'



import useMediaUploadingModal from '../../../../hooks/use-media-uploading-modal'
import { getIsWorking } from '../../../../store/userInterface/selectors'
import MediaUploadingModal from '../../../modal/media-uploading-modal'
import Translation from '../../../translations'

import ExecutionItem from './item'

const Executions = ({ filteredExecutions, executions }) => {
  const [redirect, setRedirect] = useState(null)

  const history = useHistory()
  const [open, setOpen, shouldOpenMediaUploadingModal] =
    useMediaUploadingModal()

  const isWorking = useSelector(getIsWorking)

  if (!executions) {
    return (
      <span>
        <Translation id='loadingLocalExecutions' />
      </span>
    )
  }

  if (executions.length <= 0) {
    return (
      <span>
        <Translation id='noLocalExecutions' />
      </span>
    )
  }

  return (
    <div>
      {filteredExecutions.map((ex, index) => (
        <ExecutionItem
          index={index}
          key={ex.id}
          execution={ex}
          procedure={ex.procedure}
          shouldOpenMediaUploadingModal={shouldOpenMediaUploadingModal}
          history={history}
          setRedirect={setRedirect}
          disabled={isWorking}
        />
      ))}
      <MediaUploadingModal
        open={open}
        setOpen={setOpen}
        navigate={() => history.push(redirect)}
      />
    </div>
  )
}

export default Executions
