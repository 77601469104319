import { Tag } from 'antd'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import Translation from '../../translations'
import FilterTag from '../filter-tag'
import DarkText from '../text/dark'

const FilterTags = ({
  value: filters,
  defaultFilters,
  fixedFilters,
  onChange,
  onClear,
  hideClear,
  getDisplayName,
  getDisplayValue,
  isHidden,
  className
}) => {
  const filterKeys = useMemo(
    () => Object.keys(filters).filter((key) => !isHidden?.(key)),
    [filters, isHidden]
  )

  const isReset = useMemo(
    () =>
      filterKeys.every((key) => {
        const filter = filters[key]
        const defaultFilter = defaultFilters?.[key]

        if (defaultFilter && Array.isArray(filter)) {
          return (
            filter.every((value) => defaultFilter.includes(value)) &&
            defaultFilter.length === filter.length
          )
        } else {
          return filter == defaultFilter
        }
      }),
    [filters, defaultFilters, filterKeys]
  )

  return (
    <TagsContainer className={className}>
      {filterKeys

        .map((key) => {
          // Algunos filtros son array y otros no,
          // los convertimos todos a array para tratarlos igual
          const valueIsArray = Array.isArray(filters[key])
          const values = (valueIsArray ? filters[key] : [filters[key]]).filter(
            (v) => !!v
          )
          const fixedValues =
            (Array.isArray(fixedFilters?.[key])
              ? fixedFilters?.[key]
              : [fixedFilters?.[key]]
            )?.filter((v) => !!v) || []
          const displayValues = [...new Set([...fixedValues, ...values])]
          return displayValues
            .filter((v) => v != null)
            .map((value) => {
              const checkable = fixedFilters?.[key]?.includes(value)
              return (
                <FilterTag
                  key={`${String(key)}:${String(value)}`}
                  displayName={
                    getDisplayName ? getDisplayName(key, value) : key
                  }
                  displayValue={
                    getDisplayValue ? getDisplayValue(key, value) : value
                  }
                  checkable={checkable}
                  checked={checkable && values.includes(value)}
                  onClick={() => {
                    const isFilterApplied = values?.includes(value)

                    let newValue = valueIsArray
                      ? isFilterApplied
                        ? values.filter((v) => v !== value)
                        : [...values, value]
                      : isFilterApplied
                        ? null
                        : value

                    onChange({
                      ...filters,
                      [key]: newValue
                    })
                  }}
                />
              )
            })
        })
        .flat()}
      {!hideClear && !isReset && (
        <ClickableTag
          onClick={onClear}
          color='transparent'
          data-clickable={true}
        >
          <DarkText>
            <Translation id='clearFilters' />
          </DarkText>
        </ClickableTag>
      )}
    </TagsContainer>
  )
}

const ClickableTag = styled(Tag)`
  cursor: pointer;
`

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
`

export default FilterTags
