import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData } from '../../common/write'

export async function writeExecutionConditionals(conditionals) {
  return _writeLocalDbData({
    tableName: TABLE_NAMES.EXECUTION_CONDITIONALS,
    data: conditionals,
    action: 'PUT'
  })
}
