import { useEffect, useState, useCallback } from 'react'

export const useWaitForContent = (
  selector,
  childSelector,
  renderCondition = true
) => {
  const [ready, setReady] = useState(false)

  const setReadyIfChanges = useCallback(
    (newReady) => {
      if (newReady !== ready) {
        setReady(newReady)
      }
    },
    [ready]
  )

  useEffect(() => {
    const element = document.querySelector(selector)

    if (!element || !renderCondition) {
      return
    }

    function testReady() {
      if (element.hasChildNodes()) {
        if (childSelector) {
          const childElement = element.querySelector(childSelector)
          setReadyIfChanges(!!childElement)
        } else {
          setReadyIfChanges(true)
        }
      }
    }

    testReady()

    const observer = new MutationObserver(() => {
      testReady()
      observer.disconnect()
    })

    observer.observe(element, {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
      attributeOldValue: true,
      characterDataOldValue: true
    })

    return () => {
      if (observer) observer.disconnect()
    }
  }, [childSelector, selector, renderCondition, setReadyIfChanges])

  return {
    ready
  }
}
