import { Select } from 'antd'
import React, { useState } from 'react'
import { useDebounce } from 'react-use'

import { useGlobalUnits } from '../../../hooks/metadata/useGlobalUnits'

const SelectGlobalUnits = ({ value, onChange, id }) => {
  const [search, setSearch] = useState()
  const [debouncedSearch, setDebouncedSearch] = useState()
  const { data: units, isValidating: loadingUnits } = useGlobalUnits({
    disabled: !debouncedSearch,
    search: debouncedSearch
  })

  useDebounce(() => setDebouncedSearch(search), 300, [search])

  return (
    <Select
      id={id}
      showSearch
      value={value || []}
      onChange={(value) => {
        setSearch(null)
        setDebouncedSearch(null)
        onChange(value)
      }}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      mode='multiple'
      allowClear
      onSearch={setSearch}
      loading={loadingUnits}
      options={(units || []).map((unit) => ({
        label: unit,
        value: unit
      }))}
    />
  )
}

export default SelectGlobalUnits
