// Text colors for enabled/disabled recorders
export const colorEnabled = '#000000'
export const colorDisabled = '#3d3d3d'

export const EXECUTION_STATUS_COLOR = {
  EXECUTING: '#86C48B',
  SHARED: '#645377',
  PAUSED: '#F0C78A',
  INTERRUPTED: '#EEB12B',
  FINISHED: '#E69696',
  ABORTED: '#CB9FF5'
}

export const EXECUTION_STATUS_TEXT_COLOR = {
  EXECUTING: '#000000',
  SHARED: '#FFFFFF',
  PAUSED: '#000000',
  INTERRUPTED: '#000000',
  FINISHED: '#000000',
  ABORTED: '#000000'
}

export const EXECUTION_SIGNATURE_STATUS_COLOR = {
  IN_PROGRESS: '#FDFF00',
  WAITING: '#FF8E00',
  N_A: '#B8BAB6',
  NOT_OK: '#D45200',
  OK: '#89D000'
}

export const EXECUTION_QA_STATUS_COLOR = {
  REVIEWED: '#EFEEEB',
  APPROVED: '#EFEEEB',
  REJECTED: '#EFEEEB'
}

export const EXECUTION_QA_STATUS_TEXT_COLOR = {
  REVIEWED: '#000000',
  APPROVED: '#000000',
  REJECTED: '#000000'
}
