export const TABLE_NAMES = {
  PROCEDURES: 'procedures',
  EXECUTIONS: 'executions',
  EXECUTION_COMPONENTS: 'execution_components',
  EXECUTION_STEPS: 'execution_steps',
  EXECUTION_MANEUVERS: 'execution_maneuvers',
  EXECUTION_WARNINGS: 'execution_warnings',
  EXECUTION_COMMENTS: 'execution_comments',
  EXECUTION_CONDITIONALS: 'execution_conditionals',
  FILES: 'files',
  FILES_CONTENT: 'files_content',
  MEDIA: 'media',
  USERS: 'users',
  ANNOTATIONS: 'annotations',
  PLACEKEEPING_HISTORY: 'placekeeping_history',
  CUSTOMER_SETTINGS: 'customer_settings',
  DEPARTMENTS: 'departments',
  SERIES: 'series',
  EXECUTION_GOALS: 'execution_goals',
  EXECUTION_SYNC_STATUS: 'execution_sync_status',
  UNITS: 'units',
  HISTORICAL_VALUES: 'historical_values'
}

export const LS_ENCRYPTION_KEY = 'encryptionKey'
export const LS_USER_KEY = 'user'

export const UNENCRYPTED_TABLES = [TABLE_NAMES.FILES_CONTENT]
export const ENCRYPTED_TABLES = Object.values(TABLE_NAMES).filter(
  (t) => !UNENCRYPTED_TABLES.includes(t)
)
