import React from 'react'

const Flag = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.5 15.5V0.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.5 11.8054L2.60267 11.1587C3.47368 10.8906 4.39187 10.8113 5.29594 10.9263C6.20002 11.0412 7.06916 11.3478 7.84533 11.8254C8.60278 12.2922 9.44912 12.5963 10.3305 12.7182C11.2119 12.8401 12.1089 12.7771 12.9647 12.5334L14.956 11.964C15.1128 11.9193 15.2506 11.8246 15.3488 11.6945C15.447 11.5643 15.5 11.4057 15.5 11.2427V3.42471C15.4999 3.30866 15.4729 3.19421 15.4211 3.09036C15.3693 2.98651 15.2941 2.89609 15.2015 2.82619C15.1088 2.75629 15.0012 2.70882 14.8872 2.68751C14.7731 2.6662 14.6556 2.67163 14.544 2.70338L12.9647 3.15471C12.1092 3.39905 11.2123 3.46264 10.3309 3.34144C9.44955 3.22024 8.60307 2.91689 7.84533 2.45071C7.06916 1.97309 6.20002 1.66657 5.29594 1.55161C4.39187 1.43664 3.47368 1.51589 2.60267 1.78404L0.5 2.43004'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Flag
