import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import { useTranslation } from '../../hooks/useTranslation'
import Translation from '../../views/translations'

const ExecutionDataCard = ({ id, startDate, executedFor, goal }) => {
  return (
    <Grid>
      <div>
        <b>
          <Translation id='executionId' />
        </b>
      </div>
      <div>{id}</div>
      <div>
        <b>
          <Translation id='executionStartDate' />
        </b>
      </div>
      <div>{moment(startDate).format(useTranslation('dateFormat'))}</div>
      <div>
        <b>
          <Translation id='execution.plant.unit' />
        </b>
      </div>
      <div>{executedFor}</div>
      <div>
        <b>
          <Translation id='executionGoal' />
        </b>
      </div>
      <div>{goal}</div>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0.5em;
`

export default ExecutionDataCard
