import { createSlice } from '@reduxjs/toolkit'

export const sharedExecutionSlice = createSlice({
  name: 'sharedExecution',
  initialState: {
    connectedUsers: []
  },
  reducers: {
    clearConnectedUsers: (state) => {
      state.connectedUsers = []
    },
    setConnectedUsers: (state, action) => {
      const { users } = action.payload
      state.connectedUsers = users
    },
    addConnectedUser: (state, action) => {
      const { user } = action.payload
      state.connectedUsers = [...state.connectedUsers, user]
    },
    removeConnectedUser: (state, action) => {
      const { userId } = action.payload
      state.connectedUsers = state.connectedUsers.filter(
        (u) => u.user.id !== userId
      )
    },
    setUserCurrentStep: (state, action) => {
      const { userId, step } = action.payload
      state.connectedUsers.find((u) => u.user.id === userId).currentStep = step
    }
  }
})

export const {
  clearConnectedUsers,
  setConnectedUsers,
  addConnectedUser,
  removeConnectedUser,
  setUserCurrentStep
} = sharedExecutionSlice.actions

export default sharedExecutionSlice.reducer
