import { Tag as TagAntd } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Translation from '../../../translations'

const HistoricalDrawerTitle = ({ historicalCount }) => (
  <Container>
    <Translation id='historical.title' />
    <Tag color='#FAC8AA'>
      {historicalCount}{' '}
      <Translation
        id={historicalCount === 1 ? 'element.count' : 'elements.count'}
      />
    </Tag>
  </Container>
)

const Container = styled.section`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Tag = styled(TagAntd)`
  display: flex;
  height: 22px;
  padding: 1px 8px;
  align-items: center;
  gap: 3px;

  border-radius: 2px;
  border: 1px solid #fac8aa !important;
  background: #fff0e0 !important;
  color: #333;
`

export default HistoricalDrawerTitle
