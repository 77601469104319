import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData, _writeLocalDbDataNoCatch } from '../../common/write'

const tableName = TABLE_NAMES.UNITS

export const writeNewUnit = async (unit) =>
  _writeLocalDbDataNoCatch({
    tableName,
    data: [unit],
    action: 'ADD'
  })

export const writeUnits = async (units) =>
  _writeLocalDbData({
    tableName,
    data: units,
    action: 'PUT'
  })
