import moment from 'moment'
import schedule from 'node-schedule'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGlobal } from 'reactn'

import { _syncData } from '../application/sync'
import { relogin } from '../application/users/session'
import { renewToken } from '../application/users/token'
import { getTranslation } from '../i18n/getTranslation'
import { writeExecutionGoals } from '../localdb/execution-goal/write'
import { writeUnits } from '../localdb/units/write'
import { serverIsUp } from '../services/connection/http/get'
import {
  connect,
  disconnect,
  onUpdate,
  removeSubscription
} from '../services/execution/websocket'
import {
  connectReadout,
  disconnectReadout
} from '../services/execution-components/readout/websocket'
import { fetchExecutionGoals } from '../services/execution-goal'
import { getUnits } from '../services/unit/http/get'
import { store } from '../store'
import { saveAllExecutionStore } from '../store/middleware/executionSaveMiddleware'
import { checkProcedureUpdates } from '../store/procedure/localDb'
import { getUpdatedProceduresToDownload } from '../store/procedure/selectors'
import {
  subscribeSync,
  unsubscribeSync,
  observeFinishedAbortedExecutions
} from '../store/synchronization'
import { getUser } from '../store/user-management/selectors'
import { getConnectionStatus } from '../store/userInterface/selectors'
import { setConnectionStatus } from '../store/userInterface/slice'
import {
  RECONNECT_INTERVAL,
  RENEW_TOKEN_INTERVAL
} from '../utils/constants/config'
import { CONNECTION_STATUS } from '../utils/constants/connection'
import { PERMISSION_TYPES } from '../utils/constants/roles'
import { WS_UPDATE } from '../utils/constants/websocket'
import { notificationMessage } from '../utils/helpers/notification-message'
import { userHasPermission } from '../utils/helpers/permissions'
import { erro } from '../utils/logger'

export const useConnection = () => {
  const dispatch = useDispatch()
  const [hasToSyncData, setHasToSyncData] = useState(false)

  const [token] = useGlobal('token')
  const user = useSelector(getUser)
  const [tokenGeneratedAt] = useGlobal('tokenGeneratedAt')
  const connectionStatus = useSelector(getConnectionStatus)

  const updatedProceduresToDownload = useSelector(
    getUpdatedProceduresToDownload
  )

  const userIsLogged = !!user
  const canKeepExecutions = userHasPermission(
    PERMISSION_TYPES.EXECUTION_KEEP_EXECUTIONS
  )

  const disabled = connectionStatus === CONNECTION_STATUS.DISABLED
  const online = connectionStatus === CONNECTION_STATUS.ONLINE
  const offline = connectionStatus === CONNECTION_STATUS.OFFLINE
  const connecting = connectionStatus === CONNECTION_STATUS.CONNECTING

  useEffect(() => {
    if (token) {
      connecting && connect(token) && connectReadout(token)
    } else {
      dispatch(setConnectionStatus(CONNECTION_STATUS.OFFLINE))
    }
  }, [connecting, dispatch, token])
  // Interval to check if server is up when disconnected, then login

  useEffect(() => {
    let timeout
    if (offline) {
      const checkServerUp = async () => {
        if (await serverIsUp()) {
          await relogin()
          dispatch(setConnectionStatus(CONNECTION_STATUS.CONNECTING))
        } else {
          timeout = setTimeout(checkServerUp, RECONNECT_INTERVAL)
          dispatch(setConnectionStatus(CONNECTION_STATUS.OFFLINE))
        }
      }
      checkServerUp()
    }
    return () => timeout && clearTimeout(timeout)
  }, [dispatch, offline])

  useEffect(() => {
    if (disabled) {
      unsubscribeSync()
      disconnect()
      disconnectReadout()
    }
  }, [disabled])

  // Token renew
  useEffect(() => {
    let job
    const fn = async () => {
      if (online) {
        try {
          await renewToken()
        } catch (error) {
          erro(error)
          await relogin()
        }
      }
    }
    const momentToRenew = moment(tokenGeneratedAt).add(
      RENEW_TOKEN_INTERVAL,
      'milliseconds'
    )

    if (!token) {
      relogin()
    }

    if (token && momentToRenew.isAfter(moment())) {
      job = schedule.scheduleJob(momentToRenew.toDate(), fn)
    }

    if (momentToRenew.isBefore(moment())) {
      fn()
    }

    return () => job?.cancel()
  }, [online, token, tokenGeneratedAt, connecting])

  useEffect(() => {
    const ev = onUpdate(WS_UPDATE.CONNECTED, async () => {
      dispatch(setConnectionStatus(CONNECTION_STATUS.SYNCING))
      await saveAllExecutionStore(store)
      const { hasToSyncData } = await _syncData()
      subscribeSync()
      !canKeepExecutions && observeFinishedAbortedExecutions()

      setHasToSyncData(hasToSyncData)

      dispatch(setConnectionStatus(CONNECTION_STATUS.ONLINE))

      notificationMessage({
        message: getTranslation('connectedTitle'),
        description: getTranslation('connectedDescription')
      })
    })

    return () => removeSubscription(ev)
  }, [canKeepExecutions, dispatch])

  useEffect(() => {
    const ev = onUpdate(WS_UPDATE.DISCONNECTED, async () => {
      unsubscribeSync()
      if (!disabled) {
        dispatch(setConnectionStatus(CONNECTION_STATUS.OFFLINE))
      }
      if (online) {
        notificationMessage({
          type: 'error',
          message: getTranslation('connectionLostTitle'),
          description: getTranslation('connectionLostDescription')
        })
      }
    })

    return () => removeSubscription(ev)
  }, [disabled, dispatch, online])

  useEffect(() => {
    const loadExecutionGoals = async () => {
      const executionGoalsResponse = await fetchExecutionGoals()
      writeExecutionGoals(executionGoalsResponse)
    }

    const loadUnits = async () => {
      const unitsResponse = await getUnits()
      const unitsToSave = unitsResponse.map((unit) => {
        const { plant, ...rest } = unit

        return {
          ...rest,
          plantName: plant?.name,
          plantId: plant?.id
        }
      })

      writeUnits(unitsToSave)
    }

    let fn = async () => {
      await checkProcedureUpdates()
    }
    if (online && userIsLogged && !canKeepExecutions) {
      fn()
    }

    if (online && userIsLogged) {
      loadExecutionGoals()
      loadUnits()
    }

    return () => {
      return (fn = null)
    }
  }, [online, userIsLogged, canKeepExecutions])

  return {
    hasToSyncData,
    updatedProceduresToDownload
  }
}
