import { useCallback } from 'react'

import { getKeys } from '../../localdb/common/key'
import { useDexieTable } from '../useDexieTable'

export const useLocalProcedures = ({
  disabled,
  id,
  key,
  search,
  series,
  status,
  title,
  unit,
  orderBy,
  orderDirection = 'descend'
} = {}) => {
  const findFunction = useCallback(
    async (table) => {
      if (disabled) {
        return []
      }

      return getKeys(table, [
        'id',
        'unit',
        'serieName',
        'key',
        'rev',
        'title',
        'buildNumber',
        'status'
      ])
    },
    [disabled]
  )

  let [procedures, isLoading] = useDexieTable({
    tableName: 'procedures',
    findFunction,
    decrypt: false,
    disabled
  })

  if (id?.length) {
    procedures = procedures?.filter((p) => id.includes(p.id))
  }

  if (key && key.length) {
    procedures = procedures?.filter((p) => {
      const regex = new RegExp(`.*${key}.*`, 'gi')
      return p.key.match(regex)
    })
  }

  if (title && title.length) {
    procedures = procedures?.filter((p) => {
      const regex = new RegExp(`.*${title}.*`, 'gi')
      return p.title.match(regex)
    })
  }

  if (search && search.length) {
    procedures = procedures?.filter((p) => {
      const regex = new RegExp(`.*${search}.*`, 'gi')
      return p.title.match(regex) || p.key.match(regex) || p.unit.match(regex)
    })
  }

  if (series && series.length) {
    procedures = procedures?.filter((p) => series.includes(p.serieName))
  }

  if (status && status.length) {
    procedures = procedures?.filter((p) => status.includes(p.status))
  }

  if (unit && unit.length) {
    procedures = procedures?.filter((p) => unit.includes(p.unit))
  }

  if (orderBy) {
    if (orderDirection === 'ascend') {
      procedures = procedures?.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) return -1
        if (a[orderBy] > b[orderBy]) return 1
        return 0
      })
    }

    if (orderDirection === 'descend') {
      procedures = procedures?.sort((a, b) => {
        if (a[orderBy] > b[orderBy]) return -1
        if (a[orderBy] < b[orderBy]) return 1
        return 0
      })
    }
  }

  return [procedures, isLoading]
}
