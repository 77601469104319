import { uniq } from 'lodash'

import { readFiles } from '../../../localdb/files/read'
import { readFilesContent } from '../../../localdb/filesContent/read'
import { info } from '../../../utils/logger'
import { downloadFiles } from '../download'
import { uploadUnsyncedFiles } from '../upload'

export async function syncFiles() {
  // TODO Refactor toda el sync de archivos con el sistema de fechas
  const localFilesInfo = await readFiles()
  const localFilesContent = await readFilesContent()

  //Buscamos los ficheros que tengan la info pero no el contenido o que no estén sincronizados con el backend
  const missingFileInfoIds = localFilesInfo
    .filter(
      (info) =>
        info && !localFilesContent.find((content) => content.id === info.id)
    )
    .map((info) => info.id)

  //Buscamos los ficheros que tengan el contenido pero no la info
  const missingFileContentIds = localFilesContent
    .filter((content) => !localFilesInfo.find((info) => info.id === content.id))
    .map((content) => content.id)

  // Buscamos los ficheros que están en local pero no en remoto
  const missingRemoteFilesInfo = localFilesInfo.filter((info) => !info.sync)

  // Si no hay, no se sincroniza
  if (
    !missingFileInfoIds.length &&
    !missingFileContentIds.length &&
    !missingRemoteFilesInfo.length
  )
    return

  // Sincronizamos los que falten
  const missingFilesIds = uniq([
    ...missingFileInfoIds,
    ...missingFileContentIds
  ])

  info(`Synchronizing ${missingFilesIds.length} Media`)

  await downloadFiles(missingFilesIds)
  await uploadUnsyncedFiles(missingRemoteFilesInfo)
}
