import axios from 'axios'

import { BACKEND_URL } from '../utils/constants/config'
import {
  transformAxiosRequest,
  transformAxiosResponse
} from '../utils/helpers/axios'

export const axiosInstance = axios.create({
  baseURL: BACKEND_URL
})

axiosInstance.interceptors.request.use(transformAxiosRequest)
axiosInstance.interceptors.response.use(transformAxiosResponse)
