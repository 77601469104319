import React from 'react'

import { useTranslation } from '../../hooks/useTranslation'

const Translation = ({ id, className, params }) => {
  const translation = useTranslation(id, params)

  return (
    <span className={className} data-translation-id={id}>
      {translation}
    </span>
  )
}

export default Translation
