import { Badge } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Conversation from '../../../components/icons/conversation'
import useExecutionDrawer from '../../../hooks/use-execution-drawer'
import { getCurrentStepDrawerComments } from '../../../store/userInterface/selectors'
import { setCurrentStepDrawerComments } from '../../../store/userInterface/slice'
import { DRAWERS } from '../../../utils/constants/drawer'
import { treeFindById } from '../../../utils/helpers/steps'
import StepMenuButton from '../../procedure/steps/menu/button'

const CommentButton = ({ stepId, procedure, isCurrent, commentCount }) => {
  const { currentDrawer, openDrawer } = useExecutionDrawer()
  const dispatch = useDispatch()
  const currentStepDrawerComments = useSelector(getCurrentStepDrawerComments)

  const step = treeFindById(procedure.index.children, stepId)

  if (!isCurrent && commentCount <= 0) {
    return null
  }

  return (
    <Badge
      count={commentCount}
      offset={[-10, 5]}
      style={{
        backgroundColor: '#000',
        color: '#fff',
        boxShadow: '0 0 0 1px #000'
      }}
    >
      <StepMenuButton
        id='comments-button'
        color={
          currentStepDrawerComments === step &&
          currentDrawer === DRAWERS.STEPS_COMMENTS
            ? '#4B98C6'
            : undefined
        }
        onClick={() => {
          // Se abre el drawer de comentarios
          openDrawer(DRAWERS.STEPS_COMMENTS)

          // Abrimos el paso correspondiente al placekeeping en el drawer
          dispatch(setCurrentStepDrawerComments(step))
        }}
      >
        <Conversation />
      </StepMenuButton>
    </Badge>
  )
}

export default CommentButton
