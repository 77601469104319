import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { BACKEND_URL } from '../../utils/constants/config'
import Translation from '../../views/translations'

function Video() {
  const { videoName, videoOriginalName } = useParams()
  return (
    <Container>
      <h3>
        Video: <span>{videoOriginalName}</span>
      </h3>
      <video width='100%' src={`${BACKEND_URL}/video/${videoName}`} controls>
        <Translation id='not.support.video.tag' />
      </video>
    </Container>
  )
}

const Container = styled.figure`
  width: 100%;

  & > h3 {
    margin-left: 10px;

    & > span {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  & > video {
    height: calc(100vh - 150px);
  }
`

export default Video
