import { PlusOutlined } from '@ant-design/icons'
import { Button, Form as FormAntd, Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { useTranslation } from '../../../../../hooks/useTranslation'
import Translation from '../../../../translations'

import useForm from './hooks/use-form'

const AddUnit = ({ plant, handleAddUnit }) => {
  const { form, handleSubmitItem } = useForm({
    handleAddUnit,
    plant
  })
  const unitPlaceholderTranslation = useTranslation('execution.unit.plant')

  return (
    <Form
      data-plant-id={plant.id}
      form={form}
      onFinish={handleSubmitItem}
      initialValues={{ unitName: '' }}
    >
      <Input.Group compact>
        <Form.Item
          name='unitName'
          style={{ width: 'calc(100% - 95px)' }}
          rules={[
            {
              required: true,
              message: <Translation id='fieldRequired' />
            }
          ]}
        >
          <Input placeholder={unitPlaceholderTranslation} />
        </Form.Item>
        <Button type='primary' shape='default' htmlType='submit'>
          <PlusOutlined />
        </Button>
      </Input.Group>
    </Form>
  )
}

const Form = styled(FormAntd)`
  width: 100%;
`

export default AddUnit
