import { useCallback, useMemo } from 'react'

import { TABLE_NAMES } from '../../utils/constants/localdb'
import { isSynced } from '../../utils/helpers/sync-executions'
import { useDexieTable } from '../useDexieTable'

export default function useExecutionIsSynced(executionId) {
  const findSyncStatusFn = useCallback(
    (table) => {
      if (!executionId) return []
      return table.where('executionId').equals(executionId).first()
    },
    [executionId]
  )

  const [syncStatus] = useDexieTable({
    tableName: TABLE_NAMES.EXECUTION_SYNC_STATUS,
    findFunction: findSyncStatusFn
  })

  const executionIsSynced = useMemo(() => {
    if (!executionId) return false
    return isSynced(syncStatus)
  }, [syncStatus, executionId])

  return executionIsSynced
}
