import { Empty, List } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Collapse, { Panel } from '../../../containers/collapse'
import { useElementToEditModal } from '../../../hooks/use-element-to-edit-modal'
import Translation from '../../translations'

import ExtraStuff from './extra-stuff'


const ConfigurationList = ({
  elements,
  deletePopoverTextId,
  keyList,
  itemProp,
  useTranslation,
  editModalActionTranslation,
  handleDelete,
  handleEdit,
  isDisabledEdit = () => false,
  isDisabledDelete = () => false,
  ElementHeader,
  EditModal
}) => {
  const {
    elementToEdit,
    modalOpen,
    openModalToEdit,
    closeModal,
    handleEditElement
  } = useElementToEditModal({ handleEdit })

  return (
    <ConfigListContainer>
      {elements.length > 0 ? (
        <Collapse>
          {elements.map((element, index) => (
            <Panel
              header={<ElementHeader element={element} />}
              key={`${element.id}-${index}`}
              extra={
                <ExtraStuff
                  element={element}
                  deletePopoverTextId={deletePopoverTextId}
                  disabledEdit={isDisabledEdit(element)}
                  disabledDelete={isDisabledDelete(element)}
                  handleDelete={handleDelete}
                  onClickEdit={openModalToEdit}
                />
              }
            >
              <List
                dataSource={element[keyList]}
                renderItem={(item) => (
                  <List.Item>
                    {useTranslation ? (
                      <Translation id={item[itemProp]} />
                    ) : (
                      item[itemProp]
                    )}
                  </List.Item>
                )}
              />
            </Panel>
          ))}
          <EditModal
            open={modalOpen}
            closeModal={closeModal}
            elementToEdit={elementToEdit}
            actionTranslation={editModalActionTranslation}
            onConfirm={handleEditElement}
          />
        </Collapse>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </ConfigListContainer>
  )
}

const ConfigListContainer = styled.div`
  margin: 1em 0;
  width: 100%;
`

export default ConfigurationList
