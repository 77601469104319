import { Col, Form, Input, Row } from 'antd'
import React, { useContext, useEffect } from 'react'

import { EXECUTION_QA_STATUS, EXECUTION_STATUS } from '../../../utils/constants/execution'
import Modal from '../../../views/modal'
import RadioGroup from '../../../views/radio-buttons/group'
import SelectGlobalSeries from '../../../views/select/global-series'
import SelectGlobalUnits from '../../../views/select/global-units'
import SelectGlobalUsers from '../../../views/select/global-users'
import Translation from '../../../views/translations'


import { ExecutionsFiltersContext } from '.'


const FIELDS_PER_ROW = 2
const COLUMSIZE = FIELDS_PER_ROW * 6

export const FiltersModal = ({ visible, setVisible }) => {
  const [form] = Form.useForm()

  const { filters, updateFilters, resetFilters } = useContext(
    ExecutionsFiltersContext
  )

  // Actualiza los valores del formulario cuando cambian los filtros externamente
  // y solo cuando el modal está visible para asegurarnos de que el modal
  // ya está renderizado
  useEffect(() => {
    if (visible) {
      form.setFieldsValue(filters)
    }
  }, [visible, form, filters])

  return (
    <Modal
      destroyOnClose
      visible={visible}
      formId='global-executions-filters-form'
      width={600}
      title={<Translation id='filters' />}
      okButtonText={<Translation id='applyFilters' />}
      cancelButtonText={<Translation id='clearFilters' />}
      cancellable
      onClose={() => {
        setVisible(false)
      }}
      onCancel={() => {
        setVisible(false)
        resetFilters()
      }}
    >
      <Form
        id='global-executions-filters-form'
        name='global-executions-filters-form'
        layout='vertical'
        form={form}
        onFinish={(filters) => {
          updateFilters(filters)
          setVisible(false)
        }}
        initialValues={filters}
      >
        <Row gutter={16}>
          <Col span={COLUMSIZE}>
            <Form.Item name='status' label={<Translation id='status' />}>
              <RadioGroup
                multiple
                optionsPerRow={2}
                options={Object.values(EXECUTION_STATUS).map((status) => ({
                  label: <Translation id={status} />,
                  key: status
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={COLUMSIZE}>
            <Form.Item name='qaStatus' label={<Translation id='qaStatus' />}>
              <RadioGroup
                multiple
                optionsPerRow={2}
                options={Object.values(EXECUTION_QA_STATUS).map((status) => ({
                  label: <Translation id={status} />,
                  key: status
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name='user' noStyle>
          <Input hidden />
        </Form.Item>
        <Row gutter={16}>
          <Col span={COLUMSIZE}>
            <Form.Item name='unit' label={<Translation id='unit' />}>
              <SelectGlobalUnits />
            </Form.Item>
          </Col>
          <Col span={COLUMSIZE}>
            <Form.Item name='series' label={<Translation id='series' />}>
              <SelectGlobalSeries />
            </Form.Item>
          </Col>
          <Col span={COLUMSIZE}>
            <Form.Item name='key' label={<Translation id='key' />}>
              <Input id='global-executions-filters-form-input-key' />
            </Form.Item>
          </Col>
          <Col span={COLUMSIZE}>
            <Form.Item name='title' label={<Translation id='title' />}>
              <Input id='global-executions-filters-form-input-title' />
            </Form.Item>
          </Col>
          <Col span={COLUMSIZE}>
            <Form.Item name='user' label={<Translation id='user' />}>
              <SelectGlobalUsers
                value={filters.user?.id}
                onChange={(user) => updateFilters({ ...filters, user })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
