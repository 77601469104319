import React from 'react'

import { getTranslation } from '../../../../i18n/getTranslation'
import { userHasPermission } from '../../../../utils/helpers/permissions'
import Translation from '../../../../views/translations'

export const validationExactSelection = (exactSelection) => ({
  check: ({ selection }) => selection.length === exactSelection,
  message: () => (
    <Translation
      id='validationExactSelection'
      params={{ num: exactSelection }}
    />
  )
})

export const validationMinimumSelection = (minimumSelection) => ({
  check: ({ selection }) => selection.length >= minimumSelection,
  message: () => (
    <Translation
      id='validationMinimumSelection'
      params={{ num: minimumSelection }}
    />
  )
})

export const validationStatus = (status) => ({
  check: ({ selection }) => {
    return selection.every((e) => e.status === status)
  },
  message: () => (
    <Translation
      id='validationStatus'
      params={{ status: getTranslation(status) }}
    />
  )
})

export const validationMultipleStatus = (status = []) => ({
  check: ({ selection }) => selection.every((e) => status.includes(e.status)),
  message: () => (
    <Translation
      id='validationStatus'
      params={{
        status: status.map((_status) => getTranslation(_status)).join(', ')
      }}
    />
  )
})

export const validationStatusMerge = (status) => ({
  check: ({ selection }) => {
    return selection.every((e) => status.includes(e.status))
  },
  message: () => <Translation id='validationStatusMerge' />
})

export const validationStatusNot = (status) => ({
  check: ({ selection }) => {
    return selection.every((e) => e.status !== status)
  },
  message: () => (
    <Translation
      id='validationStatusNot'
      params={{ status: getTranslation(status) }}
    />
  )
})

export const validationNoRealExecution = () => ({
  check: ({ selection }) => {
    return selection.every((e) => !e.manualExecution)
  },
  message: () => <Translation id='validationNoRealExecution' />
})

export const validationUserHasPermission = (permission) => ({
  check: () => {
    return userHasPermission(permission)
  },
  message: () => <Translation id='validationHasPermission' />
})
