import React from 'react'
import styled from 'styled-components'

import UnitTag from '../../../tags/unit'
import Translation from '../../../translations'

const ProcedureSelectorView = ({ procedure }) => {
  return (
    <ContentWrapper>
      <Line>
        <span>
          <Title>
            <Translation id='key' />
            :&nbsp;
          </Title>
          <span>{procedure.key}</span>
        </span>
        <span>
          <Title>
            <Translation id='rev' />
            :&nbsp;
          </Title>
          <span>{procedure.rev}</span>
        </span>
      </Line>
      <Line>
        <span>
          <Title>
            <Translation id='title' />
            :&nbsp;
          </Title>
          <span>{procedure.title}</span>
        </span>
        <UnitTag unit={procedure.unit} style={{ width: '10%' }}>
          {procedure.unit}
        </UnitTag>
      </Line>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  padding-bottom: 4px;
`

const Line = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0px;
`

const Title = styled.span`
  color: #949494;
  font-size: 11px;
`

export default ProcedureSelectorView
