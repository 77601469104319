import { difference } from 'lodash'
import queryString from 'query-string'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import StepContextProvider from '../../context/step/provider'
import { useCheckMassiveScroll } from '../../hooks/use-check-massive-scroll'
import usePlacekeepingHistory from '../../hooks/use-placekeeping-history'
import useUploadVideoNotification from '../../hooks/use-upload-video-notification'
import { useProcedureReady } from '../../hooks/useProcedureReady'
import { deletePlacekeepingHistory } from '../../localdb/placekeeping/delete'
import { store } from '../../store'
import {
  getExecution,
  getIsReview,
  getIsExecution,
  getExecutionStatus,
  getCurrentStep,
  getIsApprove,
  getExecutionMode
} from '../../store/execution/selectors'
import { getExecutionComponents } from '../../store/executionComponents/selectors'
import { getConflicts } from '../../store/mergeExecution/selectors'
import { setCurrentStepConflict } from '../../store/mergeExecution/slice'
import { getProcedure } from '../../store/procedure/selectors'
import { setHeaderTitleInfo } from '../../store/userInterface/slice'
import {
  EXECUTION_MODES,
  EXECUTION_STATUS
} from '../../utils/constants/execution'
import { PROCEDURE_STATUS } from '../../utils/constants/procedure'
import { hasIncorrectSignatures } from '../../utils/helpers/components/signatures'
import { getWrapperByBookmark } from '../../utils/helpers/dom-handlers'
import { scrollProcedureTo } from '../../utils/helpers/procedure'
import { sortProceduresByStepId } from '../../utils/helpers/sort'
import { extractStepsText } from '../../utils/helpers/steps'
import { warn } from '../../utils/logger'
import HistoricalDrawer from '../../views/drawers/historical'
import ExecutionMergeModal from '../execution-merge-modal/execution-merge-modal'
import ProcedureSteps from '../procedure/steps'
import StepHistory from '../step-history'

import ExecutionHtml from './html'
import ProcedureDetailLayout from './layout'
import PrintOverlay from './print-overlay'

const ExecutionView = () => {
  const [loadingPrint, setLoadingPrint] = useState(false)
  const [printMessage, setPrintMessage] = useState('')
  const [incorrectSignatureAlertVisible, setIncorrectSignatureAlertVisible] =
    useState(false)

  const procedure = useSelector(getProcedure)
  const isReview = useSelector(getIsReview)
  const isApprove = useSelector(getIsApprove)
  const execution = useSelector(getExecution)
  const components = useSelector(getExecutionComponents)
  const isExecution = useSelector(getIsExecution)
  const executionStatus = useSelector(getExecutionStatus)
  const currentStep = useSelector(getCurrentStep)
  const executionMode = useSelector(getExecutionMode)
  // Do not want to have changes on every change of conflicts
  // so we check by the length of actual and previous state
  const mergeConflicts = useSelector(
    getConflicts,
    (a, b) => a?.length === b?.length
  )
  const dispatch = useDispatch()

  const steps = useMemo(() => {
    const procedureSteps = [...procedure.steps]
    return procedureSteps?.sort(sortProceduresByStepId)
  }, [procedure.steps])

  const isExecutionShared = EXECUTION_STATUS.SHARED === executionStatus
  const isExecutionMerge = executionMode === EXECUTION_MODES.MERGE

  const [isMergeModalVisible, setIsMergeModalVisible] =
    useState(isExecutionMerge)

  const location = useLocation()

  const stepsTexts = useMemo(
    () => extractStepsText(procedure.steps),
    [procedure.steps]
  )

  const { ready } = useProcedureReady(procedure?.id)
  useUploadVideoNotification()
  useCheckMassiveScroll()

  // Todos los saltos de scroll deberían pasar por ésta función
  const onSelectIndexStep = useCallback(async (selectedSteps) => {
    if (selectedSteps.length > 0) {
      const id = selectedSteps[0]
      const el = getWrapperByBookmark(id, document)

      if (!el) return warn('Element ' + id + ' not found!')

      scrollProcedureTo(el)
    }
  }, [])

  // Reset the placekeepping history when entering Review mode.
  useEffect(() => {
    const removePlaceKeepingHistory = async () => {
      await deletePlacekeepingHistory([execution?.id])
    }

    if (isReview) {
      removePlaceKeepingHistory()
    }
  }, [isReview, execution?.id])

  const { goBack, goForward, goToCurrent, canGoBack, canGoForward } =
    usePlacekeepingHistory({ onSelectStep: onSelectIndexStep })

  useEffect(() => {
    // If isn't ready or there are conflicts we do not do anything
    if (!ready || mergeConflicts?.length) {
      return
    }
    const focusBookmark = queryString.parse(location.search).p
    if (focusBookmark) {
      // Hace scroll a un paso concreto que se pide vía URL
      onSelectIndexStep([focusBookmark])
    } else if (execution?.currentStep && !isExecutionShared) {
      // Hace scroll al paso actual cuando carga el procedimiento y la ejecución y no estamos en modo ejecución compartida
      onSelectIndexStep([execution?.currentStep])
    } else {
      onSelectIndexStep([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ready,
    isExecutionShared,
    location.search,
    onSelectIndexStep,
    mergeConflicts
  ])

  // Actualiza el título del header de la aplicación si cambia el procedimiento
  useEffect(() => {
    if (!procedure) {
      return
    }

    dispatch(setHeaderTitleInfo(procedure))

    return () => {
      dispatch(setHeaderTitleInfo(null))
    }
  }, [dispatch, procedure])

  // show warning modal for incorrect signatures only on review process
  useEffect(() => {
    if (isReview && components?.length) {
      setIncorrectSignatureAlertVisible(
        hasIncorrectSignatures(components, procedure?.components)
      )
    }
  }, [isReview, components, procedure])

  // Effect to scroll to the first step where are conflicts
  useEffect(() => {
    if (mergeConflicts?.length) {
      const firstConflict = mergeConflicts[0]
      const bmkToRender = firstConflict?.componentStep
      onSelectIndexStep([bmkToRender])
      store.dispatch(setCurrentStepConflict(bmkToRender))

      return () => {
        store.dispatch(setCurrentStepConflict(null))
      }
    }
  }, [mergeConflicts, onSelectIndexStep])

  const loadPrint = useCallback(
    (message, componentsExecution, componentsProcedure) =>
      new Promise((resolve, reject) => {
        const container = document.querySelector('#procedure-content')
        let timeOutNoChange
        const mutation = new MutationObserver(() => {
          const unLoadPrint = () => {
            setLoadingPrint(false)
            mutation.disconnect()
          }

          clearTimeout(timeOutNoChange)
          timeOutNoChange = setTimeout(() => {
            reject(unLoadPrint)
          }, 5000)

          let readyToPrint = false
          let procedureComponentIDList = []
          let procedureComponentIDWithValueList = []

          if (isExecution) {
            if (componentsExecution?.length) {
              procedureComponentIDList = componentsExecution.map(
                (component) => component.procedureComponentId
              )

              // Get procedure components ids which have an assigned value
              procedureComponentIDWithValueList = componentsExecution
                .filter(
                  (component) => component.value && component.value !== ''
                )
                .map((component) => component.procedureComponentId)
            }
          } else {
            if (componentsProcedure?.length)
              procedureComponentIDList = componentsProcedure.map(
                (component) => component.id
              )
          }

          const emptyComponents = container.querySelectorAll(
            '.contentcontrol .insert_recorder:empty'
          )

          const allCcs = container.querySelectorAll('.contentcontrol')

          // For some reason time components get more time to have the value, so
          // check if some component of this type with assigned value has no value
          // in the moment to generate report
          const anyTimeComponentWithoutValue = Array.from(allCcs)
            .filter((cc) => {
              const inputDate = cc.querySelector('.insert_recorder.date input')
              const inputTime = cc.querySelector('.insert_recorder.time input')
              return (
                (inputDate || inputTime) &&
                procedureComponentIDWithValueList.includes(
                  cc.getAttribute('data-ccid')
                )
              )
            })
            .map((cc) => {
              const input = cc.querySelector('input')
              return input.value
            })
            .filter((value) => !value)

          if (emptyComponents?.length) {
            const contentControlsIDsHtml = Array.from(emptyComponents)
              .map((cc) => cc.parentElement.dataset.ccid)
              .filter((cc) => cc)

            if (contentControlsIDsHtml?.length) {
              //Se comprueba que todos los componentes de la BD están cargados en el DOM
              //porque podría ser que algún componente se hubiera quedado huérfano en el DOM sin correspondencia en la BD
              readyToPrint =
                difference(procedureComponentIDList, contentControlsIDsHtml)
                  .length === 0
            } else {
              readyToPrint = true
            }
          } else {
            readyToPrint = true
          }

          if (readyToPrint && !anyTimeComponentWithoutValue.length) {
            clearTimeout(timeOutNoChange)
            resolve(unLoadPrint)
          }
        })

        setLoadingPrint(true)
        setPrintMessage(message)
        mutation.observe(container, {
          childList: true,
          attributes: true,
          characterData: true,
          subtree: true,
          attributeOldValue: true,
          characterDataOldValue: true
        })
      }),
    [isExecution]
  )

  if (!procedure) return null

  // TO DO -> Cuando abordemos la tarea 1525, sustituir aquí el html que metenemos directamete por un elemento iframe que lo contenga e insertarle
  // los estilos de antd (obteniéndolos con fetch, igual que se hace en el htmlMaker.js)

  const handleExecutionMergeModal = (isVisible) => {
    setIsMergeModalVisible(isVisible)
  }

  return (
    <>
      <ProcedureDetailLayout
        onSelectStep={onSelectIndexStep}
        alertVisible={incorrectSignatureAlertVisible}
        isDeprecated={procedure.status === PROCEDURE_STATUS.DEPRECATED}
        stepsTexts={stepsTexts}
        loadPrint={loadPrint}
      >
        <ExecutionHtml html={procedure.processedHtml} />
        <StepContextProvider>
          <ProcedureSteps
            loadingPrint={loadingPrint}
            steps={steps}
            procedureReady={ready}
            onSelectStep={onSelectIndexStep}
          />
          <HistoricalDrawer />
        </StepContextProvider>

        {loadingPrint && <PrintOverlay message={printMessage} />}
        {!isApprove && !isExecutionMerge && (
          <StepHistory
            actualStepId={currentStep}
            stepsIndex={procedure?.index.children}
            goBack={goBack}
            goForward={goForward}
            goToCurrent={goToCurrent}
            canGoBack={canGoBack}
            canGoForward={canGoForward}
            canGoToCurrent={!!currentStep}
          />
        )}
        {isExecutionMerge && (
          <ExecutionMergeModal
            visible={isMergeModalVisible}
            setVisible={handleExecutionMergeModal}
          />
        )}
      </ProcedureDetailLayout>
    </>
  )
}

export default ExecutionView
