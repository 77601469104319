import { Badge } from 'antd'
import React from 'react'

import SquareButton from '../../../components/buttons/square'

const HeaderButton = ({
  badge,
  disabledIcon,
  children,
  loading,
  ...antdProps
}) => {
  return (
    // TODO fix react warning for unknown prop on DOM element
    <SquareButton {...antdProps} loading={loading} disabledIcon={disabledIcon}>
      <Badge
        style={{
          backgroundColor: '#FFFFFF',
          color: '#000000',
          boxShadow: '0 0 0 1px #000'
        }}
        {...badge}
      >
        {!loading && children}
      </Badge>
    </SquareButton>
  )
}

export default HeaderButton
