import React from 'react'

const ArrowUp = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 26 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='atom/icon/up arrow'>
      <g id='arrow up'>
        <path
          id='Path'
          d='M1 18L12.4347 6.2412C12.7461 5.91978 13.2528 5.91978 13.5643 6.24011L13.5653 6.2412L25 18'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
)

export default ArrowUp
