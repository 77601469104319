import React from 'react'

import Check from '../../../../components/checkbox'

const Checkbox = ({
  onComponentChange,
  component,
  executionComponent,
  disabled
}) => {
  const { procedureComponentId } = component

  return (
    <Check
      disabled={disabled}
      value={executionComponent?.value}
      data-checked={!!executionComponent?.value}
      onChange={(checked) => {
        onComponentChange({
          id: procedureComponentId,
          value: checked
        })
      }}
    />
  )
}

export default Checkbox
