import { createSelector } from '@reduxjs/toolkit'

export const getUser = createSelector(
  (state) => state?.userManagement,
  (userManagement) => userManagement?.user
)

export const getUsers = createSelector(
  (state) => state?.userManagement,
  (userManagement) => userManagement?.users
)
