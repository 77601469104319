import { groupBy } from 'lodash'

import { putExecutionSteps } from '../../../services/execution-steps/http/put'

export const sendExecutionSteps = async (executionSteps) => {
  const executionStepsWithoutCommentsToDelete = executionSteps.map((step) => {
    const { comments, ...rest } = step
    const commentsToSend = comments.filter((comment) => !comment.deletedAt)
    return { ...rest, comments: commentsToSend }
  })

  const grouped = groupBy(executionStepsWithoutCommentsToDelete, 'executionId')

  const toDelete = {}
  executionSteps.forEach((step) => {
    const { executionId, comments } = step
    const commentsToDelete = comments.filter(
      (comment) => comment.deletedAt && step.syncedAt < comment.deletedAt
    )
    if (toDelete[executionId] && commentsToDelete.length > 0) {
      toDelete[executionId].push(...commentsToDelete)
    } else {
      toDelete[executionId] = commentsToDelete
    }
  })

  const promises = Object.keys(grouped).map((executionId) =>
    putExecutionSteps(executionId, grouped[executionId], toDelete[executionId])
  )

  return Promise.all(promises)
}
