export function getInfoContainerWidth(size) {
  switch (size) {
    case 'small':
      return '296px'
    case 'large':
      return '100%'
    default:
      return '296px'
  }
}

export function getPercentageContainerWidth(size) {
  switch (size) {
    case 'small':
      return '100px'
    case 'large':
      return '100%'
    default:
      return '130px'
  }
}

export function getFilenameContainerWidth(size) {
  switch (size) {
    case 'small':
      return '150px'
    case 'large':
      return '100%'
    default:
      return '150px'
  }
}

export function getFilenameWidth(size) {
  switch (size) {
    case 'small':
      return '127px'
    case 'large':
      return '100%'
    default:
      return '127px'
  }
}

export function getProgressContainerWidth(size) {
  switch (size) {
    case 'small':
      return '50px'
    case 'large':
      return '70%'
    default:
      return '50px'
  }
}

export function getErrorContainerWidth(size) {
  switch (size) {
    case 'small':
      return '61px'
    case 'large':
      return '70%'
    default:
      return '61px'
  }
}
