import React from 'react'

const EyeIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.49989 3.5008C5.81255 3.45547 3.03322 5.33347 1.28588 7.2568C0.906007 7.67854 0.906007 8.31907 1.28588 8.7408C2.99522 10.6235 5.76655 12.5448 8.49989 12.4988C11.2332 12.5448 14.0052 10.6235 15.7159 8.7408C16.0958 8.31907 16.0958 7.67854 15.7159 7.2568C13.9666 5.33347 11.1872 3.45547 8.49989 3.5008Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 7.99969C10.9996 9.38031 9.88018 10.4993 8.49956 10.499C7.11893 10.4988 5.99988 9.37943 6 7.9988C6.00012 6.61818 7.11937 5.49902 8.5 5.49902C9.16321 5.49885 9.7993 5.76227 10.2682 6.2313C10.7371 6.70032 11.0004 7.33648 11 7.99969V7.99969Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default EyeIcon
