import React from 'react'

import { IconContainer } from '../../../containers/icon'

export const Paperclip = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 25 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.3438 4.73096H22.3658C22.6241 4.73069 22.8719 4.83268 23.0552 5.01464C23.2385 5.19659 23.3422 5.44371 23.3438 5.70196V20.261C23.342 20.519 23.2381 20.7659 23.0549 20.9476C22.8716 21.1294 22.6239 21.2312 22.3658 21.231H1.82183C1.56374 21.2312 1.31604 21.1294 1.1328 20.9476C0.949562 20.7659 0.845675 20.519 0.843826 20.261V5.70196C0.845412 5.44371 0.949183 5.19659 1.13245 5.01464C1.31572 4.83268 1.56357 4.73069 1.82183 4.73096H5.34383'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.0938 11.481H20.3438'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.84383 15.981H20.3438'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.84383 4.73096V3.23096C3.84383 2.63422 4.08088 2.06192 4.50284 1.63997C4.92479 1.21801 5.49709 0.980957 6.09383 0.980957V0.980957C6.69056 0.980957 7.26286 1.21801 7.68482 1.63997C8.10677 2.06192 8.34383 2.63422 8.34383 3.23096V9.98096C8.34383 10.3788 8.18579 10.7603 7.90449 11.0416C7.62318 11.3229 7.24165 11.481 6.84383 11.481C6.446 11.481 6.06447 11.3229 5.78317 11.0416C5.50186 10.7603 5.34383 10.3788 5.34383 9.98096V7.73096'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Paperclip
