import { Alert, Layout } from 'antd'
import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import InfoFill from '../../../components/icons/info-fill'
import ProcedureDeprecated from '../../../components/icons/procedure-deprecated'
import WarningIcon from '../../../components/icons/warning-icon'
import useExecutionDrawer from '../../../hooks/use-execution-drawer'
import { getIsReview } from '../../../store/execution/selectors'
import { DRAWERS } from '../../../utils/constants/drawer'
import ExecutionViewRightDrawer from '../../drawers/execution-view/right'
import ConnectionModal from '../../modal/connection-modal'
import Translation from '../../translations'
import ExecutionViewFooter from '../footer'
import ProcedureIndexSider from '../procedure-index'
import ExecutionSubHeader from '../subheader'

const { Content } = Layout

const ProcedureDetailLayout = ({
  children,
  onSelectStep,
  alertVisible,
  isDeprecated,
  stepsTexts,
  loadPrint
}) => {
  const [mounted, setMounted] = useState(false)
  const isReview = useSelector(getIsReview)

  useEffect(() => {
    const timeout = setTimeout(() => setMounted(true), 100)

    return () => clearTimeout(timeout)
  }, [])

  const { currentDrawer, openDrawer, closeDrawer } = useExecutionDrawer()

  const rightDrawerVisible = currentDrawer === DRAWERS.PROCEDURE_RIGHT
  const leftDrawerVisible = currentDrawer === DRAWERS.PROCEDURE_LEFT
  const setRightDrawerVisible = useCallback(
    (visible) => {
      visible ? openDrawer(DRAWERS.PROCEDURE_RIGHT) : closeDrawer()
    },
    [openDrawer, closeDrawer]
  )
  const setLeftDrawerVisible = useCallback(
    (visible) => {
      visible ? openDrawer(DRAWERS.PROCEDURE_LEFT) : closeDrawer()
    },
    [openDrawer, closeDrawer]
  )

  return (
    <ProcLayout>
      {/* Retrasar el pintado del subheader para mejorar la carga */}
      {mounted && (
        <ExecutionSubHeader
          loadPrint={loadPrint}
          onSelectStep={onSelectStep}
          leftDrawerVisible={leftDrawerVisible}
          rightDrawerVisible={rightDrawerVisible}
          stepsTexts={stepsTexts}
          onToggleLeftDrawer={() => setLeftDrawerVisible(!leftDrawerVisible)}
          onToggleRightDrawer={() => setRightDrawerVisible(!rightDrawerVisible)}
        />
      )}
      <ConnectionModal />

      <ProcLayout
        data-add-margin-bottom={
          currentDrawer === DRAWERS.HISTORICAL_DRAWER && !isReview
        }
      >
        <ProcedureIndexSider
          onSelectStep={onSelectStep}
          leftDrawerVisible={leftDrawerVisible}
        />
        <ProcedureWidth id='procedure-scroll'>
          {alertVisible && (
            <IncorrectSignaturesAlert
              message={<Translation id='incorrectSignatureWarning' />}
              showIcon
              type='error'
              closable={false}
              icon={<WarningIcon color='#FF4545' />}
            />
          )}
          {isDeprecated && (
            <DeprecatedProcedure
              message={<Translation id='deprecatedProcedureWarning' />}
              showIcon
              type='error'
              closable={false}
              icon={<ProcedureDeprecated color='#ffffff' />}
              action={<InfoFill color='#B8BAB6' />}
            />
          )}
          <ProcContent id='procedure-content'>{children}</ProcContent>
        </ProcedureWidth>
      </ProcLayout>

      <ExecutionViewRightDrawer
        visible={rightDrawerVisible}
        onClose={() => setRightDrawerVisible(false)}
        onSelectStep={onSelectStep}
      />
      <ExecutionViewFooter loadPrint={loadPrint} onSelectStep={onSelectStep} />
    </ProcLayout>
  )
}

const ProcLayout = styled(Layout)`
  height: 100%;
  // Same width as the Historical Drawer
  margin-bottom: ${(props) => (props['data-add-margin-bottom'] ? '408px' : 0)};
`

const ProcContent = styled.div`
  display: inline-block;
  text-align: initial;
  margin: 14px;
  width: 95%;
`

const ProcedureWidth = styled(Content)`
  overflow-y: auto;
`

const IncorrectSignaturesAlert = styled(Alert)`
  position: fixed;
  top: 120px;
  flex-direction: row;
  height: initial !important;
  justify-content: center;
  z-index: 10;
  margin: 0 auto;
  padding: 14px 24px;
  background: #ffe8e8;
  border: 2px solid #f10808;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.06),
    0px 5px 9px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 75%;
  left: 13%;
  .ant-alert-message {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #f10808;
  }
`

const DeprecatedProcedure = styled(Alert)`
  position: fixed;
  top: 120px;
  flex-direction: row;
  height: initial !important;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  z-index: 11;
  margin: 0 auto;
  padding: 14px 24px;
  background-color: #000000;
  border: 1px solid #000000;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.06),
    0px 5px 9px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 95%;
  left: 2%;
  .ant-alert-message {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
  }
  .ant-alert-icon {
    margin-right: 21px;
  }
  .ant-alert-action {
    margin-top: 5px;
  }
`

export default ProcedureDetailLayout
