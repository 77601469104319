import React from 'react'

import DepartmentConfig from './department-config'
import SeriesConfig from './series-config'

const SeriesDepartmentConfig = () => {
  return (
    <>
      <DepartmentConfig />
      <SeriesConfig />
    </>
  )
}

export default SeriesDepartmentConfig
