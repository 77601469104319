import { generateHTML } from '..'
import { printReport } from '../../../../services/execution/report/http/post'
import { downloadFile } from '../../../../utils/helpers/files'

function hideHeadersAndFooters(text) {
  if (!text) return text
  const divHeadersAndFooters = '<div id="headersAndFooters">'
  const divHidden = '<div id="headersAndFooters" style="display:none">'
  return text.replace(divHeadersAndFooters, divHidden)
}

export async function printExecutionReport(execution, procedure) {
  const procedureData = document.querySelector('#procedure-data')
  let htmlToGenerated = await generateHTML(procedureData, execution, procedure)

  const filename = ` ${procedure.serie.name} ${procedure.unit}    ${procedure.key}  Rev.${procedure.rev}.pdf`
  const htmlToPrint = hideHeadersAndFooters(htmlToGenerated)

  const pdfBuffer = await printReport(htmlToPrint)

  return downloadFile(pdfBuffer, 'application/pdf', filename)
}
