import React from 'react'

const Camera = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.75 7.26099C0.75 6.15642 1.64543 5.26099 2.75 5.26099H13.75C14.8546 5.26099 15.75 6.15642 15.75 7.26099V16.761C15.75 17.8656 14.8546 18.761 13.75 18.761H2.75C1.64543 18.761 0.75 17.8656 0.75 16.761V7.26099Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.75 15.011L22.165 16.718C22.2793 16.775 22.4064 16.802 22.534 16.7962C22.6617 16.7904 22.7858 16.752 22.8945 16.6848C23.0032 16.6176 23.0929 16.5237 23.1551 16.4121C23.2173 16.3004 23.25 16.1748 23.25 16.047V7.97396C23.2501 7.84614 23.2175 7.72042 23.1553 7.60876C23.093 7.49711 23.0033 7.40324 22.8945 7.33608C22.7858 7.26892 22.6616 7.23071 22.5339 7.22509C22.4062 7.21947 22.2792 7.24662 22.165 7.30396L18.75 9.01096'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Camera
