import { Drawer as AntDrawer } from 'antd'
import React from 'react'
import styled from 'styled-components'

import BlankButton from '../../../../../components/buttons/blank-button'
import Caret from '../../../../../components/icons/caret'

const ItemsMenu = ({
  id,
  title,
  onBack,
  icon,
  color,
  visible,
  setVisible,
  children,
  badgeCount,
  placement,
  className,
  disabled
}) => {
  return (
    <StepCounterDrawer
      id={`${id}-items`}
      width={400}
      open={visible}
      closable={false}
      maskClosable={true}
      className={className + ' steps-drawer'}
      placement={placement || 'right'}
      style={{
        transform: 'translateY(112px)',
        overflow: 'hidden'
      }}
      onClose={() => setVisible(false)}
    >
      <FlexContainer>
        <StepCounterHeader color={color}>
          <StepCounterIcon>
            {onBack ? (
              <BlankButton
                onClick={onBack}
                color='transparent'
                data-active-color='transparent'
                disabled={disabled}
              >
                <Caret />
              </BlankButton>
            ) : (
              icon
            )}
          </StepCounterIcon>
          <HeaderTitle>{title}</HeaderTitle>
          <HeaderCounter>{badgeCount || 0}</HeaderCounter>
        </StepCounterHeader>
        <DrawerContent>{children}</DrawerContent>
      </FlexContainer>
    </StepCounterDrawer>
  )
}

const StepCounterDrawer = styled(AntDrawer)`
  & .ant-drawer-body {
    padding: 0;
  }
`

const StepCounterHeader = styled.div`
  height: 60px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`

const HeaderTitle = styled.span`
  font-size: 19px;
  font-weight: bold;
`

const StepCounterIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeaderCounter = styled.span`
  width: 20px;
  height: 20px;
  background-color: #fff;
  font-size: 13px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 10px;
  text-align: center;
`

const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const DrawerContent = styled.div`
  flex-grow: 1;
`

export default ItemsMenu
