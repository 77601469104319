import React from 'react'
import { useSelector } from 'react-redux'
import {
  Router as ReactRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import Layout from '../layout'
import { getUser } from '../store/user-management/selectors'
import { history } from '../utils/constants/browser-history'
import { EXECUTION_MODES } from '../utils/constants/execution'
import { filterRoutesByPermissions } from '../utils/helpers'

import { Dashboard } from './Dashboard/index'
import { ExecutionDetail } from './ExecutionDetail'
import { HelpPage } from './Help'
import { Login } from './Login'
import { NotFound } from './NotFound'
import Video from './video'

// TODO refactor de las rutas de ejecuciones
// unificar toda a /executions/ y pasar los props por query params

export const routes = [
  {
    key: 'procedure',
    path: '/procedures/:id',
    component: ExecutionDetail,
    parent: 'procedures',
    exact: true,
    props: {
      entity: 'procedure'
    }
  },
  {
    key: 'executionView',
    path: '/executions/:id/:executionRouterMode',
    component: ExecutionDetail,
    parent: 'procedures',
    exact: true,
    props: {
      entity: 'execution'
    }
  },
  {
    key: 'shared-execution',
    path: '/shared/:id',
    component: ExecutionDetail,
    parent: 'procedures',
    exact: true,
    props: {
      entity: 'execution',
      mode: EXECUTION_MODES.SHARED
    },
    skipPermission: true
  },
  {
    key: 'execution-management-review',
    path: '/review/:id',
    component: ExecutionDetail,
    parent: 'procedures',
    exact: true,
    props: {
      entity: 'execution',
      mode: EXECUTION_MODES.REVIEW
    }
  },
  {
    key: 'execution-management-approve',
    path: '/approve/:id',
    component: ExecutionDetail,
    parent: 'procedures',
    exact: true,
    props: {
      entity: 'execution',
      mode: EXECUTION_MODES.APPROVE
    }
  },
  {
    key: 'execution-management-view',
    path: '/view/:id',
    component: ExecutionDetail,
    parent: 'procedures',
    exact: true,
    props: {
      entity: 'execution',
      mode: EXECUTION_MODES.VIEW
    }
  },
  {
    key: 'execution-management-merge',
    path: '/merge/:id',
    component: ExecutionDetail,
    parent: 'procedures',
    exact: true,
    props: {
      entity: 'execution',
      mode: EXECUTION_MODES.MERGE
    }
  },
  {
    key: 'video',
    path: '/video/:videoName/:videoOriginalName',
    component: Video,
    parent: 'home',
    exact: false
  },
  {
    key: 'help',
    path: '/help',
    component: HelpPage,
    parent: 'home',
    exact: false,
    skipPermission: true
  },
  {
    key: 'home',
    path: '/',
    component: () => <Redirect to='/dashboard' />,
    parent: null,
    exact: true,
    skipPermission: true
  },
  {
    key: 'dashboard',
    path: '/dashboard',
    component: Dashboard,
    parent: 'home',
    exact: false,
    skipPermission: true
  },
  {
    key: 'not-found',
    path: '*',
    component: NotFound,
    parent: 'home',
    exact: false,
    skipPermission: true
  }
]

export const Router = () => {
  const user = useSelector(getUser)

  const allowedRoutes = filterRoutesByPermissions(routes, user)

  return (
    <ReactRouter history={history}>
      {user ? (
        <Layout>
          <Switch>
            {allowedRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <route.component {...props} {...route.props} route={route} />
                )}
              />
            ))}
          </Switch>
        </Layout>
      ) : (
        <Switch>
          <Route path='/' component={Login} />
        </Switch>
      )}
    </ReactRouter>
  )
}
