import { createSlice } from '@reduxjs/toolkit'

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: {
    user: null,
    users: []
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setUsers: (state, action) => {
      state.users = action.payload
    }
  }
})

export const { setUser, setUsers } = userManagementSlice.actions

export default userManagementSlice.reducer
