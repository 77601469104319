import React from 'react'

const StatusChange = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 16.375H23'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.25 12.375L23.5 16.375L19.25 20.375'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.919 4.618C11.0155 4.32886 11.2005 4.07739 11.4478 3.89918C11.6951 3.72097 11.9922 3.62506 12.297 3.625V3.625C12.4878 3.62487 12.6768 3.66236 12.8532 3.73534C13.0295 3.80831 13.1898 3.91533 13.3247 4.05028C13.4597 4.18523 13.5667 4.34546 13.6397 4.52181C13.7126 4.69815 13.7501 4.88715 13.75 5.078V5.078C13.75 5.42833 13.6107 5.7643 13.363 6.012L10.75 8.625H13.75'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.25 4.625C20.25 4.35978 20.3554 4.10543 20.5429 3.91789C20.7304 3.73036 20.9848 3.625 21.25 3.625H22.007C22.1712 3.62533 22.3336 3.65799 22.4852 3.72111C22.6367 3.78423 22.7743 3.87658 22.8902 3.99288C23.006 4.10919 23.0978 4.24717 23.1603 4.39896C23.2228 4.55074 23.2548 4.71335 23.2545 4.8775C23.2542 5.04165 23.2215 5.20413 23.1584 5.35566C23.0953 5.5072 23.0029 5.64481 22.8866 5.76065C22.7703 5.87649 22.6323 5.96829 22.4805 6.03081C22.3288 6.09332 22.1662 6.12533 22.002 6.125C22.3335 6.12434 22.6517 6.2554 22.8866 6.48935C23.1215 6.7233 23.2538 7.04098 23.2545 7.3725C23.2552 7.70402 23.1241 8.02223 22.8902 8.25712C22.6562 8.492 22.3385 8.62434 22.007 8.625H21.25C20.9848 8.625 20.7304 8.51964 20.5429 8.33211C20.3554 8.14457 20.25 7.89022 20.25 7.625'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.25 8.625V4.125C3.25 4.03214 3.22414 3.94112 3.17533 3.86213C3.12651 3.78315 3.05666 3.71931 2.97361 3.67779C2.89055 3.63626 2.79758 3.61868 2.7051 3.62702C2.61262 3.63536 2.52428 3.66929 2.45 3.725L1.25 4.625'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.25 8.625H4.25'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.25 6.625C7.52614 6.625 7.75 6.40114 7.75 6.125C7.75 5.84886 7.52614 5.625 7.25 5.625C6.97386 5.625 6.75 5.84886 6.75 6.125C6.75 6.40114 6.97386 6.625 7.25 6.625Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.25 6.625C17.5261 6.625 17.75 6.40114 17.75 6.125C17.75 5.84886 17.5261 5.625 17.25 5.625C16.9739 5.625 16.75 5.84886 16.75 6.125C16.75 6.40114 16.9739 6.625 17.25 6.625Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default StatusChange
