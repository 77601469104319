import React from 'react'

import { IconContainer } from '../../../containers/icon'

const Info = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_5287_8706)'>
      <path
        d='M9.5 11H9C8.7348 11 8.4804 10.8947 8.29287 10.7071C8.10533 10.5196 8 10.2652 8 10V7.5C8 7.3674 7.94733 7.2402 7.85353 7.14647C7.7598 7.05267 7.6326 7 7.5 7H7'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.75 5C7.61193 5 7.5 4.88807 7.5 4.75C7.5 4.61193 7.61193 4.5 7.75 4.5'
        stroke={color}
      />
      <path
        d='M7.75 5C7.88807 5 8 4.88807 8 4.75C8 4.61193 7.88807 4.5 7.75 4.5'
        stroke={color}
      />
      <path
        d='M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85787 12.1421 0.5 8 0.5C3.85787 0.5 0.5 3.85787 0.5 8C0.5 12.1421 3.85787 15.5 8 15.5Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_5287_8706'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Info
