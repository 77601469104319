import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getExecution } from '../../../../store/execution/selectors'
import { getExecutionComponents } from '../../../../store/executionComponents/selectors'
import { getConflicts } from '../../../../store/mergeExecution/selectors'
import { solveMergeConflict } from '../../../../store/mergeExecution/slice'
import Translation from '../../../translations'

import MergeComponents from './merge-components'

const MergeContent = ({ conflictIndex, setConflictIndex, onSelectStep }) => {
  const dispatch = useDispatch()
  const execution = useSelector(getExecution)
  const executionComponents = useSelector(getExecutionComponents)
  const mergeConflicts = useSelector(getConflicts)

  const onSolveMergeConflict = (conflictId, value) => {
    dispatch(solveMergeConflict({ conflictId, value }))
  }

  if (!mergeConflicts?.length) return <Translation id='noConflicts' />

  return (
    <MergeComponents
      execution={execution}
      executionComponents={executionComponents}
      mergeConflicts={mergeConflicts}
      conflictIndex={conflictIndex}
      setConflictIndex={(index) =>
        setConflictIndex(
          ((index % mergeConflicts.length) + mergeConflicts.length) %
            mergeConflicts.length
        )
      }
      onSelectStep={onSelectStep}
      onSolveMergeConflict={onSolveMergeConflict}
    />
  )
}

export default MergeContent
