import { downloadExecution } from '..'
import { deleteExecution } from '../../../localdb/execution/delete'
import { deleteRemoteExecution } from '../../../services/execution/http/delete'
import { postMergeExecution } from '../../../services/execution/merge/http/post'
import { putChangeStatusSourceMergeExecutions } from '../../../services/execution/merge/http/put'
import { CancelMergeError } from '../../../utils/errors/merge'

export async function cancelMerge({
  executionId,
  executionOneId,
  executionTwoId
}) {
  try {
    await putChangeStatusSourceMergeExecutions(
      executionOneId,
      executionTwoId,
      false
    )
    await deleteRemoteExecution(executionId)
    await deleteExecution(executionId)
  } catch (error) {
    throw new CancelMergeError(error)
  }
}

export async function downloadMergeExecution(executionId1, executionId2) {
  const { data: result } = await postMergeExecution(executionId1, executionId2)

  await downloadExecution(result.id, true)
  return result
}
