import { v4 as uuidv4 } from 'uuid'

import { uploadVideos } from '../../../../services/files'
import { store } from '../../../../store'
import { updateUpdatedAtExecution } from '../../../../store/execution/slice'
import {
  addMediaToStepCommentAction,
  addStepCommentAction
} from '../../../../store/executionSteps/actions'
import { setIsWorking } from '../../../../store/userInterface/slice'
import { COMMENT_TO_STEP_TYPES } from '../../../../utils/constants/comment'
import { uploadFilesAndSeparateVideos } from '../../../../utils/helpers/media-uploading'

export async function addComment(
  currentUser,
  newComment,
  executionStep,
  media,
  isCritical,
  isNonCompliantComment,
  executionId,
  step,
  stepIndex,
  isReviewMode
) {
  const { files, videosToUpload } = await uploadFilesAndSeparateVideos(media)

  const commentId = uuidv4().toUpperCase()

  const comment = {
    id: commentId,
    user: currentUser,
    comment: newComment,
    stepId: step.id,
    bookmark: executionStep,
    mediaIds: files.map((m) => m.id),
    type: isCritical
      ? COMMENT_TO_STEP_TYPES.CRITICAL
      : COMMENT_TO_STEP_TYPES.COMMENT,
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
    isNonCompliantComment,
    isReviewStepComment: isReviewMode
  }
  store.dispatch(setIsWorking(true))
  store.dispatch(
    addStepCommentAction({ stepId: executionStep, newComment: comment })
  )
  store.dispatch(
    updateUpdatedAtExecution({
      updatedAt: new Date().valueOf()
    })
  )

  if (videosToUpload?.length) {
    const addMediaPayload = {
      commentId,
      stepId: step.id,
      executionId
    }

    uploadVideos(
      videosToUpload,
      commentId,
      step.id,
      executionStep,
      stepIndex,
      executionId,
      addMediaPayload,
      addMediaToStepCommentAction,
      store.dispatch
    )
  }
}
