import { Drawer, Button } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import { exportProcedures } from '../../../../application/procedure'
import BaseTable from '../../../../views/tables/base-table'
import UnitTag from '../../../../views/tags/unit'
import Translation from '../../../../views/translations'

export const ReferencedProceduresDrawer = ({
  visible,
  setVisible,
  selectedProcedures,
  referencedProcedures,
  exportSaving,
  setExportSaving
}) => {
  const [selectedReferencedProcedures, setSelectedReferencedProcedures] =
    useState([])

  const columns = [
    {
      title: <Translation id='unit' />,
      dataIndex: 'unit',
      render: (text) => <UnitTag unit={text} />
    },
    {
      title: <Translation id='series' />,
      dataIndex: 'serie.name',
      minWidth: 50,
      sorter: true
    },
    {
      title: <Translation id='key' />,
      dataIndex: 'key',
      minWidth: 50,
      sorter: true
    },
    {
      title: <Translation id='rev' />,
      dataIndex: 'rev',
      minWidth: 50,
      sorter: true
    },
    {
      title: <Translation id='title' />,
      dataIndex: 'title',
      sorter: true
    }
  ]

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys: selectedReferencedProcedures.map((proc) => proc.id),
    onChange: (selectedKey, selectedItems) => {
      setSelectedReferencedProcedures(selectedItems)
    }
  }

  return (
    <Drawer
      id='global-referenced-admin-procedures-table'
      title={
        <TitleContainer>
          <ListTitle>
            <Translation id='downloadProceduresModalTitle' />
          </ListTitle>
          <span>
            <CancelButton onClick={() => setVisible(false)}>
              <Translation id='cancel' />
            </CancelButton>
            <OkButton
              id='ok-export-procedures'
              loading={exportSaving}
              onClick={() => {
                setExportSaving(true)
                exportProcedures(
                  [...selectedProcedures, ...selectedReferencedProcedures].map(
                    (p) => p.id
                  )
                ).finally(() => setExportSaving(false))
                setVisible(false)
              }}
            >
              <Translation id='export' />
            </OkButton>
          </span>
        </TitleContainer>
      }
      placement='bottom'
      closable={true}
      open={visible}
      height='85%'
      onClose={() => {
        setVisible(false)
      }}
    >
      <BaseTable
        loading={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={referencedProcedures}
      />
    </Drawer>
  )
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`

const ListTitle = styled.span`
  font-size: 17px;
  font-weight: bold;
`

const CancelButton = styled(Button)`
  border: none;
  border-radius: 2px;
  width: 108px;
  height: 40px;
  padding: 8px;
  background-color: #edece8;
  margin-right: 8px;
  color: #000000;
  font-size: 16px;
  border: 1px solid #b8bab6;

  &:active,
  &:focus {
    background-color: #b8bab6;
    color: #000;
    border: #b8bab6;
  }

  &:hover {
    background-color: #edece8;
    color: #000000;
    border-color: #b8bab6;
  }
`

const OkButton = styled(Button)`
  border: none;
  border-radius: 2px;
  width: 108px;
  height: 40px;
  padding: 8px;
  background-color: #645377;
  color: #ffffff;
  font-size: 16px;
  border: '1px solid #272928';

  &:active,
  &:focus {
    background-color: #b8bab6;
    color: #000000;
    border-color: #272928;
  }

  &:hover {
    background-color: #645377;
    color: #ffffff;
    border-color: #272928;
  }
`
