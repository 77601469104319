import React from 'react'
import styled from 'styled-components'

import ElementsCount from '../elements-count'

const BasePage = ({
  title,
  className,
  children,
  extraHeaderContent,
  subHeaderContent,
  totalElements,
  selectedElements,
  clearSelection,
  topMenu
}) => (
  <PageContainer className={className}>
    <PageHeaders id='page-header' data-topmenu={topMenu}>
      <MainHeader>
        <PageTitle id='page-title'>{title}</PageTitle>
        {extraHeaderContent}
      </MainHeader>
      {subHeaderContent && <SubHeader>{subHeaderContent}</SubHeader>}
      <ElementsCount
        total={totalElements}
        selected={selectedElements}
        clearSelection={clearSelection}
      />
    </PageHeaders>
    <PageContent id='page-content'>{children}</PageContent>
  </PageContainer>
)

const PageContainer = styled.div`
  padding: 0 16px 16px 16px;
  height: 100%;
  width: 100%;
  background-color: #efeeeb;
  display: flex;
  flex-direction: column;
`

const PageHeaders = styled.div`
  margin-top: ${({ 'data-topmenu': topMenu }) => (topMenu ? '45px' : null)};
  padding: 16px 0 16px 0;
  width: 100%;
`

const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const SubHeader = styled.div`
  margin-top: 16px;
`

const PageTitle = styled.span`
  font-size: 17px;
  font-weight: bold;
`

const PageContent = styled.div`
  flex-grow: 1;
  min-height: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export default BasePage
