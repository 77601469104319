import { axiosInstance } from '../../../..'

export async function requestCodes() {
  return (await axiosInstance.get('/users/codes')).data
}

export async function isAvailableCode(code, userId) {
  try {
    const result = await axiosInstance.get(`/users/isAvailableCode/${code}`, {
      params: { userId }
    })
    return result.data
  } catch (error) {
    return false
  }
}
