import { Modal } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { useWaitForContent } from '../../../hooks/useWaitForContent'
import { mandatoryReadingTimeOut } from '../../../utils/constants/config'
import Translation from '../../translations'

const ModalMandatoryReading = ({
  id,
  modalHeight,
  modalWidth,
  pages,
  modalVisible,
  setModalVisible,
  onAccept,
  onCancel,
  okButtonTextId,
  showCancelButton = true
}) => {
  const [timeoutPassed, setTimeoutPassed] = useState(false)
  const [okButtonEnabled, setOkButtonEnabled] = useState(false)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const contentElement = useRef(null)

  const currentPage = pages[currentPageIndex]
  const isFirstPage = currentPageIndex === 0
  const isLastPage = currentPageIndex + 1 === pages.length

  const { ready } = useWaitForContent('#modal-content')

  useEffect(() => {
    let timeout

    if (modalVisible) {
      setTimeout(() => {
        setTimeoutPassed(true)
      }, mandatoryReadingTimeOut)
    }

    return () => (timeout ? clearTimeout(timeout) : setTimeoutPassed(false))
  }, [modalVisible])

  useEffect(() => {
    const elem = contentElement?.current

    if (!elem) {
      return
    }

    const atBottom = isScrollAtBottom(elem)

    const enabled = modalVisible && ready && atBottom && timeoutPassed
    setOkButtonEnabled(enabled)
  }, [modalVisible, ready, timeoutPassed])

  function clearModal() {
    setModalVisible(false)
    scrollToTheBeginning()
    setCurrentPageIndex(0)
  }

  function scrollToTheBeginning() {
    contentElement.current.scrollTo(0, 0)
  }

  return (
    <CustomModal
      id='custom-modal'
      centered
      width={modalWidth}
      forceRender
      modalHeight={modalHeight}
      closable={false}
      maskClosable={false}
      open={modalVisible}
      title={currentPage?.title}
      onOk={async () => {
        if (isLastPage) {
          onAccept?.()
          clearModal()
        } else {
          scrollToTheBeginning()
          setCurrentPageIndex((current) => current + 1)
        }
      }}
      onCancel={() => {
        if (isFirstPage) {
          onCancel?.()
          clearModal()
        } else {
          setCurrentPageIndex((current) => current - 1)
        }
      }}
      okButtonProps={{
        disabled: !okButtonEnabled,
        id: 'ok-button'
      }}
      okText={
        <Translation
          id={okButtonTextId ? okButtonTextId : isLastPage ? 'okText' : 'next'}
        />
      }
      cancelText={<Translation id={isFirstPage ? 'cancel' : 'previous'} />}
      cancelButtonProps={{
        style: { display: showCancelButton ? '' : 'none' }
      }}
    >
      <ModalContentWrapper
        data-nbpages={pages.length}
        id={id}
        ref={contentElement}
        onScroll={({ target }) => {
          // Ponemos el botón a enabled cuando se llega al final del scroll
          setOkButtonEnabled(isScrollAtBottom(target))
        }}
      >
        <section id='modal-content'>{currentPage?.content}</section>
      </ModalContentWrapper>
    </CustomModal>
  )
}

const ModalContentWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
`

function isScrollAtBottom(element) {
  const margin = 10
  const maxHeight = element.clientHeight + margin
  const minHeight = element.clientHeight - margin
  const scroll = element.scrollHeight - element.scrollTop
  const elementHasNoScroll = element.scrollHeight <= element.clientHeight

  return elementHasNoScroll || (scroll <= maxHeight && scroll >= minHeight)
}

const CustomModal = styled(Modal)`
  max-height: 80%;
  display: inline-flex !important;

  & .ant-modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  & .ant-modal-body {
    overflow-y: hidden;
  }
`

export default ModalMandatoryReading
