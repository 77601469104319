import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import { getComponent } from '../../store/historical-values/selectors'
import { setComponent } from '../../store/historical-values/slice'
import { DRAWERS } from '../../utils/constants/drawer'
import { EXECUTION_COMPONENT_TYPES } from '../../utils/constants/execution/components'
import useExecutionDrawer from '../use-execution-drawer'

export default function useHistoricalComponent({
  isHistoricalMode,
  showHistorical,
  component
}) {
  const historicalComponentSelected = useSelector(getComponent)
  const { openDrawer } = useExecutionDrawer()
  const dispatch = useDispatch()

  const historicalComponentActive = useMemo(() => {
    if (!historicalComponentSelected || !component) {
      return false
    }
    if (component?.type === EXECUTION_COMPONENT_TYPES.MULTICELL) {
      return (
        historicalComponentSelected?.options?.group ===
        component?.options?.group
      )
    }

    return historicalComponentSelected?.id === component?.id
  }, [historicalComponentSelected, component])

  const historicalModeEnabled = useMemo(() => {
    return isHistoricalMode && showHistorical
  }, [isHistoricalMode, showHistorical])

  const onClickHistorical = useCallback(
    (e) => {
      e?.preventDefault()

      openDrawer(DRAWERS.HISTORICAL_DRAWER)
      dispatch(setComponent({ component }))
    },
    [openDrawer, dispatch, component]
  )

  return {
    historicalModeEnabled,
    historicalComponentActive,
    onClickHistorical
  }
}
