import React from 'react'

const Attachment = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='atom/icon/attach'>
      <g id='Group'>
        <path
          id='Path'
          d='M18.75 15.749V7.5C18.75 3.77208 15.7279 0.75 12 0.75C8.27208 0.75 5.25 3.77208 5.25 7.5V18.75C5.25 21.2353 7.26472 23.25 9.75 23.25C12.2353 23.25 14.25 21.2353 14.25 18.75V7.5C14.25 6.25736 13.2426 5.25 12 5.25C10.7574 5.25 9.75 6.25736 9.75 7.5V17.25'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
)

export default Attachment
