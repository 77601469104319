import { Modal, Select } from 'antd'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Flag from '../../../components/icons/flag'
import Help from '../../../components/icons/help'
import SettingsIcon from '../../../components/icons/settings-icon'
import LanguageContext from '../../../context/language'
import { languages } from '../../../i18n/getTranslation'
import { setUserLocalStorageItem } from '../../../utils/helpers/user'
import HeaderButton from '../../buttons/header-button'
import PopoverMenu from '../../menus/popover'
import Translation from '../../translations'

const { Option } = Select

const Settings = ({ disabled }) => {
  const history = useHistory()
  const { language, setLanguage } = useContext(LanguageContext)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [provisionalLanguage, setProvisionalLanguage] = useState(language)

  return (
    <>
      <PopoverMenu
        id='settings-pop-over'
        placement='bottomRight'
        trigger='click'
        items={[
          {
            id: 'language-button',
            icon: Flag,
            translationId: 'language',
            onClick: () => {
              setProvisionalLanguage(language)
              setShowSettingsModal(true)
            }
          },
          {
            icon: Help,
            translationId: 'help',
            onClick: () => {
              history.push('/help')
            }
          }
        ]}
      >
        <HeaderButton id='settings-button' disabledIcon={disabled}>
          <SettingsIcon disabled={disabled} />
        </HeaderButton>
      </PopoverMenu>

      <Modal
        open={showSettingsModal}
        onOk={() => {
          setLanguage(provisionalLanguage)
          setUserLocalStorageItem('language', provisionalLanguage)
          setShowSettingsModal(false)
        }}
        onCancel={() => setShowSettingsModal(false)}
        okText={<Translation id='okText' />}
        cancelText={<Translation id='cancel' />}
      >
        <p>
          <Translation id='language' />
        </p>
        <Select
          id='language-list'
          style={{ width: '175px' }}
          value={provisionalLanguage}
          onChange={(value) => {
            setProvisionalLanguage(value)
          }}
        >
          {Object.keys(languages).map((option, index) => (
            <Option key={index} title={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  )
}

export default Settings
