import { sendUpdate } from '../../services/execution/websocket'
import { EXECUTION_MODES, SHARED_EXECUTION_UPDATE_TYPES } from '../../utils/constants/execution'

export const sharedExecutionMiddleware =
  (store) => (next) => async (action) => {
    const executionMode = store.getState().execution.mode

    if (typeof action === 'function') {
      return next(action)
    }

    if (
      executionMode !== EXECUTION_MODES.SHARED &&
      executionMode !== EXECUTION_MODES.VIEW
    ) {
      return next(action)
    }

    if (!Object.values(SHARED_EXECUTION_UPDATE_TYPES).includes(action.type)) {
      return next(action)
    } else if (executionMode === EXECUTION_MODES.VIEW) {
      if (action.payload?.sendToWs === false) {
        return next(action)
      } else {
        return
      }
    }

    if (action.payload && action.payload.sendToWs === false) {
      return next(action)
    }

    sendUpdate({ type: 'EXECUTION_UPDATE', payload: action })
  }
