import { Button, Result } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

import Centered from '../containers/centered'
import Translation from '../views/translations'


export const NotFound = ({ message }) => {
  const history = useHistory()
  return (
    <Centered>
      <Result
        status='404'
        title='404'
        subTitle={message || <Translation id='pageNotFound' />}
        extra={
          <Button type='primary' onClick={() => history.goBack()}>
            <Translation id='goBack' />
          </Button>
        }
      />
    </Centered>
  )
}
