import { Layout } from 'antd'
import Mark from 'mark.js'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import HeaderDivider from '../../../components/dividers/header'
import CloseIcon from '../../../components/icons/close-icon'
import Info from '../../../components/icons/info'
import List from '../../../components/icons/list'
import Paperclip from '../../../components/icons/paperclip'
import Search from '../../../components/icons/search'
import useExecutionDrawer from '../../../hooks/use-execution-drawer'
import useStepsToSign from '../../../hooks/use-steps-to-sign'
import { getIsReview } from '../../../store/execution/selectors'
import { getIsProcedure } from '../../../store/procedure/selectors'
import { SHOULD_CHECK_COMPLIANCIES } from '../../../utils/constants/config'
import { DRAWERS } from '../../../utils/constants/drawer'
import { EXECUTION_SIGNATURE_STATUS } from '../../../utils/constants/execution/components'
import HeaderButton from '../../buttons/header-button'
import PrintButton from '../../buttons/print'
import InfoExecutionDrawer from '../../drawers/execution-info'
import ExecutionCommentsDrawer from '../../drawers/execution-view/execution-comments'
import SignatureDrawer from '../../drawers/execution-view/signatures'
import StepCommentsDrawer from '../../drawers/execution-view/step-comments'

import PauseButton from './actions/pause/button'
import PlayButton from './actions/play/button'
import StopButton from './actions/stop/button'
import ConnectedUsersButton from './connected-users'
import SearchBox from './search'

const { Header } = Layout
const { IN_PROGRESS, WAITING } = EXECUTION_SIGNATURE_STATUS

const ExecutionSubHeader = ({
  loadPrint,
  onSelectStep,
  rightDrawerVisible,
  leftDrawerVisible,
  stepsTexts,
  onToggleRightDrawer,
  onToggleLeftDrawer
}) => {
  const isReview = useSelector(getIsReview)
  const isProcedure = useSelector(getIsProcedure)
  const { openDrawer, isNonCompliantComment } = useExecutionDrawer()
  const [searchVisible, setSearchVisible] = useState(false)

  const { stepsToSign } = useStepsToSign()

  const areActionsDisabled = SHOULD_CHECK_COMPLIANCIES && isNonCompliantComment

  return (
    <TecHeader>
      <ButtonsContainer>
        <DrawerButton
          id='list-index-button'
          onClick={onToggleLeftDrawer}
          className={leftDrawerVisible ? 'active' : ''}
          disabled={isReview || areActionsDisabled}
          badge={{
            count:
              stepsToSign && stepsToSign.length > 0 ? stepsToSign.length : 0
          }}
        >
          {leftDrawerVisible ? (
            <CloseIcon disabled={isReview || areActionsDisabled} />
          ) : (
            <List disabled={isReview || areActionsDisabled} />
          )}
        </DrawerButton>

        <HeaderDivider style={{ marginLeft: '0' }} />

        <PlayButton />
        <PauseButton />
        <StopButton />

        <ConnectedUsersButton onSelectStep={onSelectStep} />
      </ButtonsContainer>

      <ButtonsContainer>
        <ExecutionCommentsDrawer disabled={areActionsDisabled} />

        <PrintButton
          id='print-button'
          loadPrint={loadPrint}
          disabled={areActionsDisabled}
        />

        <HeaderButton
          id='info-button'
          disabledIcon={isProcedure}
          onClick={() => openDrawer(DRAWERS.EXECUTION_INFO)}
          disabled={areActionsDisabled}
        >
          <Info disabled={isProcedure || areActionsDisabled} />
        </HeaderButton>
        <InfoExecutionDrawer
          onSelectStep={onSelectStep}
          disabled={areActionsDisabled}
        />

        <HeaderButton
          id='search-button'
          onClick={() => {
            if (searchVisible) {
              const procedureContent =
                document.getElementById('procedure-content')
              var instance = new Mark(procedureContent)
              instance.unmark()
            }
            setSearchVisible(!searchVisible)
          }}
          disabled={areActionsDisabled}
        >
          <Search disabled={areActionsDisabled} />
        </HeaderButton>
        {searchVisible && (
          <SearchBox
            stepsTexts={stepsTexts}
            onSelectStep={onSelectStep}
            setVisible={setSearchVisible}
          />
        )}
      </ButtonsContainer>

      <ButtonsContainer>
        <SignatureDrawer
          onSelectStep={onSelectStep}
          signatureStatus={IN_PROGRESS}
          disabled={areActionsDisabled}
          drawerId={DRAWERS.SIGNATURE_IN_PROGRESS}
        />
        <SignatureDrawer
          onSelectStep={onSelectStep}
          signatureStatus={WAITING}
          disabled={areActionsDisabled}
          drawerId={DRAWERS.SIGNATIRE_WAITING}
        />
        <StepCommentsDrawer onSelectStep={onSelectStep} />

        <HeaderDivider style={{ marginRight: '0' }} />

        <DrawerButton
          id='more-actions-button'
          onClick={onToggleRightDrawer}
          className={rightDrawerVisible ? 'active' : ''}
          disabled={isReview || areActionsDisabled}
        >
          {rightDrawerVisible ? (
            <CloseIcon disabled={isReview || areActionsDisabled} />
          ) : (
            <Paperclip disabled={isReview || areActionsDisabled} />
          )}
        </DrawerButton>
      </ButtonsContainer>
    </TecHeader>
  )
}

const TecHeader = styled(Header)`
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonsContainer = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
`

const DrawerButton = styled(HeaderButton)`
  height: 100%;
  width: 64px;
  border-radius: 0;
  margin: 0;

  &.active {
    background-color: #efeeeb;
  }
`

export default ExecutionSubHeader
