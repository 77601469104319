import React from 'react'

import { addDepartment } from '../../../../application/departments/create'
import { removeDepartment } from '../../../../application/departments/delete'
import { modifyDepartment } from '../../../../application/departments/put'
import ConfigSection from '../../../../containers/config-section'
import SectionTitle from '../../../../containers/section-title'
import useDepartments from '../../../../hooks/use-departments'
import PutDepartmentModal from '../../../modal/put-department-modal'
import Translation from '../../../translations'
import ConfigurationList from '../../configuration-list'
import CreateButtonHeader from '../../create-button-header'
import ElementHeaderDepartment from '../element-header'

import useActions from './hooks/use-actions'



const DepartmentConfig = () => {
  const { data: departments = [], mutate: reload } = useDepartments()

  const {
    addDepartmentAction,
    modifyDepartmentAction,
    removeDepartmentAction
  } = useActions({
    modifyDepartment,
    removeDepartment,
    addDepartment,
    reloadDepartments: reload
  })

  return (
    <ConfigSection>
      <SectionTitle
        extra={
          <CreateButtonHeader
            Modal={PutDepartmentModal}
            buttonTextId={'config.department.button'}
            actionTranslation={'config.department.button'}
            handleCreate={addDepartmentAction}
          />
        }
      >
        <Translation id='config.departments.title' />
      </SectionTitle>
      <ConfigurationList
        elements={departments}
        deletePopoverTextId={'config.department.delete.popover'}
        keyList={'series'}
        itemProp={'name'}
        editModalActionTranslation={'config.department.edit.modal.action'}
        handleEdit={modifyDepartmentAction}
        handleDelete={removeDepartmentAction}
        ElementHeader={ElementHeaderDepartment}
        EditModal={PutDepartmentModal}
      />
    </ConfigSection>
  )
}

export default DepartmentConfig
