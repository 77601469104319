import { findStepInfo } from '../../../utils/helpers/steps'

export function getConditionalStepInfo(conditional, procedure) {
  const canGetConditionalStepInfo =
    conditional?.procedureConditional && procedure?.index

  return {
    stepsIfTrue: canGetConditionalStepInfo
      ? conditional.procedureConditional.steps
          .filter((step) => step.enabledIfConditionalIsTrue)
          .map((step) => ({
            ...step,
            info: findStepInfo(procedure?.index.children, step.stepId)
          }))
      : [],
    stepsIfFalse: canGetConditionalStepInfo
      ? conditional.procedureConditional.steps
          .filter((step) => !step.enabledIfConditionalIsTrue)
          .map((step) => ({
            ...step,
            info: findStepInfo(procedure?.index.children, step.stepId)
          }))
      : []
  }
}
