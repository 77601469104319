import { getTranslation } from '../../../i18n/getTranslation'
import { getDeviceByName } from '../../../services/devices/http/get'
import { saveDevice } from '../../../services/devices/http/post'
import { saveDeviceIdCookie } from '../../../utils/helpers/device'
import { prompt } from '../../../utils/helpers/dialogs'

export async function requestAndSaveDevice() {
  const deviceName = await prompt({
    title: getTranslation('insertDeviceName'),
    placeholder: getTranslation('device'),
    rules: [
      {
        required: true
      }
    ],
    modalProps: {
      cancellable: false,
      closable: false,
      maskClosable: false
    }
  })

  try {
    const { data: device } = await getDeviceByName(deviceName)
    saveDeviceIdCookie(device.id)
  } catch (error) {
    const { data: device } = await saveDevice(deviceName)
    saveDeviceIdCookie(device.id)
  }
}
