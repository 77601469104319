import { createSelector } from '@reduxjs/toolkit'

export const getProcedure = createSelector(
  (state) => {
    return state.procedure
  },
  (procedure) => {
    return procedure?.procedure
  }
)

export const getIsProcedure = createSelector(
  (state) => {
    return state.procedure
  },
  (procedure) => {
    return procedure?.isProcedure
  }
)

export const getUpdatedProceduresToDownload = createSelector(
  (state) => {
    return state.procedure
  },
  (procedure) => {
    return procedure?.updatedProceduresToDownload
  }
)

export const getSequentialMode = createSelector(
  (state) => {
    return state?.procedure
  },
  (procedure) => {
    return procedure?.procedure.sequentialMode
  }
)

export const getProcedureKey = createSelector(getProcedure, (procedure) => {
  return procedure?.key
})
