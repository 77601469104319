/* eslint-disable no-extra-semi */
import moment from 'moment'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { setGlobal } from 'reactn'
import { SWRConfig } from 'swr'

import { version } from '../package.json'

import LanguageProvider from './context/language/provider'
import { useLaggySwr } from './hooks/use-laggy-swr'
import TranslationProvider from './i18n/provider'
import { Router } from './screens'
import { swrFetcher } from './services/swr/fetcher'
import { store } from './store'
import { debug, erro } from './utils/logger'

moment.locale('es')

// Initialize global state
setGlobal({
  token: null, // Token para autenticar peticiones
  tokenGeneratedAt: null, // Indica la fecha de última renovación del token
  encryptionKey: null, // Clave para encriptar/desencriptar datos locales
})

const container = document.getElementById('root')
const root = createRoot(container)

if ('serviceWorker' in navigator) {
  ;(async () => {
    try {
      const registration = await navigator.serviceWorker.register(
        new URL('../service-worker.js', import.meta.url),
        { type: 'module', scope: '/' },
        'all'
      )
      debug('ServiceWorker registered', registration)

      registration.addEventListener('updatefound', () => {
        const installingWorker = registration.installing
        if (installingWorker) {
          installingWorker.onstatechange = async () => {
            try {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  debug(
                    'ServiceWorker already installed, updating ServiceWorker registration\n',
                    'Proceed version: ',
                    version
                  )
                  return registration.update()
                }
              }
            } catch (error) {
              erro('ServiceWorker onstatechange update error', error)
            }
          }
        }
      })
    } catch (error) {
      erro('ServiceWorker registration failed', error)
    }
  })()
} else {
  erro('ServiceWorkers are not supported')
}

root.render(
  <LanguageProvider>
    <Provider store={store}>
      <SWRConfig
        value={{
          dedupingInterval: 0,
          fetcher: swrFetcher,
          use: [useLaggySwr]
        }}
      >
        <TranslationProvider>
          <Router />
        </TranslationProvider>
      </SWRConfig>
    </Provider>
  </LanguageProvider>
)
