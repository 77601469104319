{
  "name": "proceed",
  "version": "2.5.0-version-base",
  "description": "",
  "scripts": {
    "start": "parcel",
    "clean-start": "npm run clear-parcel && npm start",
    "build": "parcel build --no-optimize --no-cache",
    "clear-parcel": "rm -rf dist && rm -rf .parcel-cache",
    "lint": "eslint src && prettier --check \"src/**/*.js\"",
    "lint:fix": "eslint --fix src && prettier --write \"src/**/*.js\"",
    "lint:tests": "eslint tests && prettier --check \"tests/E2E/*.js\"",
    "all-tests": "cd testing/E2E && npx codeceptjs run --verbose",
    "all-tests-allure": "cd testing/E2E && npx codeceptjs run --plugins allure --verbose",
    "all-tests-mocha": "cd testing/E2E && npx codeceptjs run --reporter mochawesome",
    "test:new": "codeceptjs gt",
    "test:unit": "jest --watch --verbose --coverage=true",
    "test:unit-coverage": "jest --coverage",
    "preinstall": "npx npm-force-resolutions"
  },
  "browserslist": [
    "safari 11"
  ],
  "repository": {
    "type": "git",
    "url": "git+ssh://git@bitbucket.org/tnt-int/proceed.git"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "homepage": "https://bitbucket.org/tnt-int/proceed#readme",
  "dependencies": {
    "@ant-design/icons": "^4.7.0",
    "@babel/plugin-proposal-optional-chaining": "^7.11.0",
    "@babel/polyfill": "^7.12.1",
    "@parcel/service-worker": "^2.10.3",
    "@reduxjs/toolkit": "^2.0.1",
    "@svgr/parcel-plugin-svgr": "^5.5.0",
    "@swc/helpers": "^0.5.3",
    "antd": "^4.2.0",
    "appium-uiautomator2-driver": "^2.36.0",
    "axios": "^1.6.3",
    "codecept3-upgrade": "^1.0.5",
    "compare-pdf": "^1.1.8",
    "core-js-compat": "^3.35.0",
    "cross-env": "^7.0.3",
    "crypto-js": "^4.2.0",
    "deep-diff": "^1.0.2",
    "dexie": "^3.2.4",
    "dexie-easy-encrypt": "alexInteracso/dexie-easy-encrypt",
    "dexie-react-hooks": "^1.1.7",
    "dexie-relationships": "dGarciaInteracso/dexie-relationships",
    "dotenv": "^16.3.1",
    "expr-eval": "^2.0.2",
    "form-data": "^4.0.0",
    "glob": "^10.3.10",
    "history": "^4.10.1",
    "jwt-decode": "^4.0.0",
    "lodash": "^4.17.21",
    "lodash.clonedeep": "^4.5.0",
    "lodash.get": "^4.4.2",
    "lodash.groupby": "^4.6.0",
    "lodash.isequal": "^4.5.0",
    "lodash.mapvalues": "^4.6.0",
    "lodash.maxby": "^4.6.0",
    "lodash.minby": "^4.6.0",
    "lodash.pick": "^4.4.0",
    "lodash.remove": "^4.7.0",
    "lodash.uniq": "^4.5.0",
    "lodash.uniqwith": "^4.5.0",
    "mark.js": "^8.11.1",
    "moment": "^2.30.1",
    "node-schedule": "^2.1.1",
    "parcel": "^2.10.3",
    "pubsub-js": "^1.9.4",
    "query-string": "^8.1.0",
    "raf": "^3.4.1",
    "randomcolor": "^0.6.2",
    "react": "^18.2.0",
    "react-device-detect": "^2.2.3",
    "react-dom": "^18.2.0",
    "react-is": "^18.2.0",
    "react-redux": "^8.0.2",
    "react-router-dom": "^5.1.2",
    "react-use": "^17.4.2",
    "reactn": "^2.2.7",
    "redux-logger": "^3.0.6",
    "socket.io-client": "^4.7.2",
    "string-template": "^1.0.0",
    "styled-components": "^6.1.6",
    "swr": "^1.3.0",
    "throttle-debounce": "^5.0.0",
    "ts-node": "^10.9.2",
    "typescript": "^5.3.3",
    "uuid": "^9.0.1"
  },
  "devDependencies": {
    "@babel/eslint-parser": "^7.23.3",
    "@babel/plugin-transform-modules-commonjs": "^7.23.3",
    "@babel/preset-env": "^7.23.6",
    "@babel/preset-react": "^7.23.3",
    "@codeceptjs/allure-legacy": "^1.0.2",
    "@parcel/packager-raw-url": "^2.10.3",
    "@parcel/transformer-css": "^2.10.3",
    "@parcel/transformer-less": "^2.10.3",
    "@parcel/transformer-webmanifest": "^2.10.3",
    "@parcel/watcher": "^2.3.0",
    "@testing-library/jest-dom": "^6.1.6",
    "@testing-library/react": "^14.1.2",
    "@testing-library/user-event": "^14.5.2",
    "@types/jest": "^29.5.11",
    "@types/node": "^20.10.5",
    "@types/pubsub-js": "^1.8.6",
    "@types/puppeteer": "^5.4.7",
    "buffer": "^6.0.3",
    "codeceptjs": "^3.5.10",
    "crypto-browserify": "^3.12.0",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-babel": "^5.3.1",
    "eslint-plugin-codeceptjs": "^1.3.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jest": "^27.6.0",
    "eslint-plugin-node": "^11.1.0",
    "eslint-plugin-promise": "^6.1.1",
    "eslint-plugin-react": "^7.33.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "events": "^3.3.0",
    "husky": "^8.0.3",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-styled-components": "^7.2.0",
    "less": "^4.2.0",
    "lightningcss": "^1.24.1",
    "lint-staged": "^15.2.0",
    "mochawesome": "7.1.3",
    "parcel-namer-rewrite": "^2.10.3-rc.2",
    "path-browserify": "^1.0.1",
    "prettier": "^3.1.1",
    "process": "^0.11.10",
    "puppeteer": "^5.5.0",
    "selenium-standalone": "^9.3.1",
    "stream-browserify": "^3.0.0",
    "webdriverio": "^8.27.0"
  },
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged"
    }
  },
  "lint-staged": {
    "*.js": "eslint --fix",
    "*.{js,css,md}": "prettier --write"
  },
  "resolutions": {
    "@babel/preset-env": "7.13.8"
  },
  "parcel-namer-rewrite": {
    "rules": {
      "deployCfg(.*).js": "deployCfg.js"
    }
  },
  "targets": {
    "default": {
      "sourceMap": {
        "inline": false,
        "inlineSources": true,
        "sourceRoot": "/"
      },
      "source": [
        "/public/help/helpPage_en-US.html",
        "/public/help/helpPage_es-ES.html",
        "/public/ProceedLarge.png",
        "/public/ProceedSmallColour.png",
        "/public/ProceedSmallColour192x192.png",
        "/public/ProceedSmallColour512x512.png",
        "/public/ProceedSmallTrans.png",
        "/public/favicon.ico",
        "/public/icons/back.png",
        "/public/Logo0.png",
        "/public/icons/document.svg",
        "/public/icons/execution-comment.svg",
        "/public/icons/step-comment.svg",
        "/public/icons/video.svg",
        "/public/logo.gif",
        "index.html",
        "src/index.js"
      ],
      "distDir": "dist/",
      "outputFormat": "esmodule",
      "engines": {
        "browsers": ">= 50%"
      }
    }
  },
  "engines": {
    "node": "18.15",
    "npm": "9"
  },
  "transform": {
    "\\.js$": "/node_modules/babel-jest"
  },
  "optionalDependencies": {
    "@parcel/watcher-linux-x64-glibc": "^2.4.1"
  }
}
