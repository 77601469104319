import React from 'react'

import Modal from '..'
import useExecutionDrawer from '../../../hooks/use-execution-drawer'
import { store } from '../../../store'
import {
  cancelUploadByUser,
  clearMediaUploading
} from '../../../store/media-uploading/slice'
import { MediaUploadingCancelFuncMap } from '../../../utils/constants/media-upload'
import MediaUploading from '../../media-uploading'
import Translation from '../../translations'

function MediaUploadingModal({ open, setOpen, navigate }) {
  const { closeDrawer } = useExecutionDrawer()
  const onCancelAllUploads = () => {
    const ids = Array.from(MediaUploadingCancelFuncMap.keys())

    MediaUploadingCancelFuncMap.forEach((cancelFunc) => cancelFunc.cancel())
    ids.forEach((id) => store.dispatch(cancelUploadByUser({ id })))

    setTimeout(() => {
      store.dispatch(clearMediaUploading())
      setOpen(false)
      closeDrawer()
      navigate()
    }, 3000)
  }

  return (
    <Modal
      id='media-uploading-modal'
      title={<Translation id='media.uploading.warning.title' />}
      okButtonText={<Translation id='okText' />}
      cancelButtonText={<Translation id='media.uploading.cancel.all' />}
      onOk={() => setOpen(false)}
      closable={true}
      maskClosable={false}
      cancellable={true}
      onCancel={onCancelAllUploads}
      onClose={() => setOpen(false)}
      visible={open}
      setVisible={setOpen}
    >
      <Translation id='media.uploading.warning.text' />
      <MediaUploading
        size='large'
        isNotification
        showMedia
        onClickEyeAction={() => setOpen(false)}
      />
    </Modal>
  )
}

export default MediaUploadingModal
