import { Select } from 'antd'
import React, { useState } from 'react'
import { useDebounce } from 'react-use'
import styled from 'styled-components'

import { useGlobalUsers } from '../../../hooks/data/useGlobalUsers'

import UsersSelectorView from './user-selector-view'


const { Option } = Select

const SelectGlobalUsers = ({ value, onChange, placeholder, id }) => {
  const [search, setSearch] = useState()
  const [debouncedSearch, setDebouncedSearch] = useState()
  const { data: { items: users } = {}, isValidating: loadingProcedures } =
    useGlobalUsers({
      disabled: !value && !debouncedSearch,
      search: debouncedSearch || value?.name,
      orderBy: 'name',
      orderDirection: 'ascend',
      take: 5
    })

  useDebounce(() => setDebouncedSearch(search), 300, [search])

  return (
    <DropDown
      id={id}
      showSearch
      value={value?.id}
      placeholder={placeholder}
      showArrow={true}
      filterOption={false}
      onSearch={setSearch}
      onChange={(value) => {
        setSearch(null)
        setDebouncedSearch(null)
        onChange(users?.find((u) => u.id === value))
      }}
      loading={loadingProcedures}
    >
      {users?.map((u) => (
        <Option key={u.id} value={u.id}>
          <UsersSelectorView user={u} />
        </Option>
      ))}
    </DropDown>
  )
}

const DropDown = styled(Select)`
  width: 90%;
  margin-bottom: 20px;

  & > .ant-select-selector {
    height: 60px !important;
  }
`

export default SelectGlobalUsers
