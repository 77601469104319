import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData } from '../../common/write'

const tableName = TABLE_NAMES.EXECUTION_STEPS

export const writeExecutionSteps = async (executionSteps) => {
  return _writeLocalDbData({
    tableName,
    data: executionSteps,
    action: 'PUT'
  })
}
