import React, { useContext, useState } from 'react'

import TextButton from '../../../components/buttons/text'
import Filter from '../../../components/icons/filter'
import SelectGlobalDevices from '../../../views/select/global-devices'
import Translation from '../../../views/translations'

import { FiltersModal } from './FiltersModal'

import { DownloadedProceduresFiltersContext } from '.'

export const Filters = () => {
  const { filters, updateFilters } = useContext(
    DownloadedProceduresFiltersContext
  )

  return (
    <>
      <SelectGlobalDevices
        value={filters.device?.id}
        onChange={(device) =>
          updateFilters({
            device
          })
        }
      />
      <ExtraFilters />
    </>
  )
}

const ExtraFilters = () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <TextButton
        id='download-procedures-filters-button'
        onClick={() => setModalVisible(true)}
        icon={<Filter size={20} color='#645377' />}
      >
        <Translation id='filters' />
      </TextButton>
      <FiltersModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  )
}
