import React, { useContext, useState, useEffect } from 'react'
import { useDebounce } from 'react-use'
import styled from 'styled-components'

import TextButton from '../../../components/buttons/text'
import Filter from '../../../components/icons/filter'
import DateRangePicker from '../../../views/date/range-picker'
import Translation from '../../../views/translations'

import { ExecutionFilterTags } from './ExecutionFilterTags'
import { FiltersModal } from './FiltersModal'


import { ExecutionsFiltersContext } from '.'

export const Filters = () => {
  const { filters, updateFilters } = useContext(ExecutionsFiltersContext)
  const [search, setSearch] = useState(filters.search)

  useEffect(() => {
    setSearch(filters.search)
  }, [filters.search])

  useDebounce(
    () => {
      updateFilters({ search })
    },
    500,
    [search]
  )

  return (
    <>
      <FiltersRow>
        <DateRangePicker
          value={[filters.modifiedAfter, filters.modifiedBefore]}
          onChange={(dateRange) =>
            updateFilters({
              modifiedAfter: dateRange?.[0]?.startOf('day') || undefined,
              modifiedBefore: dateRange?.[1]?.endOf('day') || undefined
            })
          }
        />
        <ExtraFilters />
      </FiltersRow>
      <ExecutionFilterTags />
    </>
  )
}

const ExtraFilters = () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <TextButton
        id='local-executions-filters-button'
        onClick={() => setModalVisible(true)}
        icon={<Filter size={20} color='#645377' />}
      >
        <Translation id='filters' />
      </TextButton>
      <FiltersModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  )
}

const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
`
