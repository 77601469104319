import { Empty } from 'antd'
import React, { useState, useMemo } from 'react'
import styled from 'styled-components'

import Collapse, { Panel } from '../../../../containers/collapse'
import { checkDefaultElementInList } from '../../../../utils/helpers/list'
import Translation from '../../../translations'
import EditableHeader from '../editable-header'

import AddUnit from './add-unit'
import ExtraStuff from './extra-stuff'
import TableUnits from './table-unit'


const PlanUnitConfigList = ({
  plants,
  units,
  deletePopoverTextId,
  handleDeleteCustomerSetting,
  handleModifyCustomerSetting,
  handleAddUnit,
  handleModifyUnit,
  handleDeleteUnit
}) => {
  const [editionPlant, setEditionPlant] = useState(null)
  const [editionUnit, setEditionUnit] = useState(null)

  const unitByDefault = useMemo(() => {
    return units?.find((unit) => unit.default)
  }, [units])

  return (
    <ConfigListContainer>
      {plants.length > 0 ? (
        <Collapse>
          {plants.map((plant, index) => (
            <Panel
              header={
                <EditableHeader
                  element={plant}
                  elementInEdition={editionPlant}
                  setEdition={setEditionPlant}
                  showBadge={true}
                  badgeInfo={
                    units.filter((unit) => unit.plant?.id === plant.id).length
                  }
                  showAditionalInfo={true}
                  additionalInfo={
                    unitByDefault?.plant?.id === plant.id ? (
                      <Translation
                        id='unit.by.default'
                        params={{
                          unitName: unitByDefault?.name
                        }}
                      />
                    ) : (
                      <Translation
                        className='plants.no.default.unit'
                        id='plants.no.default.unit'
                      />
                    )
                  }
                />
              }
              key={`${plant.id}-${index}`}
              extra={
                <ExtraStuff
                  element={plant}
                  setElementToEdit={setEditionPlant}
                  elementToEdit={editionPlant}
                  deletePopoverTextId={'config.plant.delete.popover'}
                  handleDelete={handleDeleteCustomerSetting}
                  handleClickEdit={handleModifyCustomerSetting}
                />
              }
            >
              <AddUnit
                key={`add-${plant.id}-${index}`}
                plant={plant}
                handleAddUnit={handleAddUnit}
              />
              <TableUnits
                units={units.filter((unit) => unit.plant?.id === plant.id)}
                key={`${plant.id}-${index}`}
                plantId={plant.id}
                setUnitToEdit={setEditionUnit}
                unitToEdit={editionUnit}
                deletePopoverTextId={deletePopoverTextId}
                handleEdit={handleModifyUnit}
                handleDelete={handleDeleteUnit}
                handleChangeDefault={(value, unitId) =>
                  checkDefaultElementInList({
                    list: units,
                    id: unitId,
                    isChecked: value,
                    handlerEvent: handleModifyUnit
                  })
                }
              />
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </ConfigListContainer>
  )
}

const ConfigListContainer = styled.div`
  margin: 1em 0;
  width: 100%;
`

export default PlanUnitConfigList
