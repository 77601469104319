import React, { useMemo } from 'react'

import Modal from '..'
import { useLocalExecutions } from '../../../hooks/data/useLocalExecutions'
import useInterruptMassiveExecution from '../../../hooks/executions/use-interrupt-massive-execution'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { MassiveInterruption } from '../../massive-interruption'
import Translation from '../../translations'

const MassiveInterruptionModal = ({ visible, setVisible }) => {
  const [executionsExecutingOrPausedSyncInfo] = useLocalExecutions({
    withProcedure: true,
    status: [EXECUTION_STATUS.EXECUTING, EXECUTION_STATUS.PAUSED]
  })

  const areAllExecutionsSynced = useMemo(
    () => executionsExecutingOrPausedSyncInfo?.every(({ synced }) => synced),
    [executionsExecutingOrPausedSyncInfo]
  )

  const [interrupt, loading] = useInterruptMassiveExecution(
    executionsExecutingOrPausedSyncInfo,
    () => setVisible(false)
  )

  async function onConfirm() {
    if (
      executionsExecutingOrPausedSyncInfo?.length > 0 &&
      executionsExecutingOrPausedSyncInfo?.every((e) => e.synced)
    ) {
      await interrupt()
      return
    }
  }

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      okButtonText={<Translation id='interruptButton' />}
      onOk={onConfirm}
      disableOkButton={
        !areAllExecutionsSynced || !executionsExecutingOrPausedSyncInfo?.length
      }
      loading={loading}
      cancellable={true}
      onCancel={() => setVisible(!visible)}
      cancelButtonText={<Translation id='reviseExecutions' />}
      title={<Translation id='executionMassiveInterruption' />}
    >
      <MassiveInterruption
        executions={executionsExecutingOrPausedSyncInfo}
        showWarning={!areAllExecutionsSynced}
        textId={
          executionsExecutingOrPausedSyncInfo?.length > 0
            ? 'executionMassiveInterruptionText'
            : 'executionMassiveInterruptionTextEmpty'
        }
        warningTextId='executionsOutOfSyncNotLogout'
      />
    </Modal>
  )
}

export default MassiveInterruptionModal
