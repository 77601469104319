import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { store } from '../../store'
import {
  getCurrentComponentType,
  getCurrentDrawer,
  getIsNonCompliantComment
} from '../../store/userInterface/selectors'
import {
  setCurrentComponentType,
  setCurrentDrawer,
  setIsNonCompliantComment
} from '../../store/userInterface/slice'

function useExecutionDrawer() {
  const currentDrawer = useSelector(getCurrentDrawer)
  const isNonCompliantComment = useSelector(getIsNonCompliantComment)
  const currentComponentType = useSelector(getCurrentComponentType)

  const openDrawer = useCallback(
    (drawerId) => store.dispatch(setCurrentDrawer(drawerId)),
    []
  )
  const closeDrawer = useCallback(
    () => store.dispatch(setCurrentDrawer(null)),
    []
  )
  const setIsNonCompliantCommentFn = useCallback(
    (isNonCompliantComment) =>
      store.dispatch(setIsNonCompliantComment(isNonCompliantComment)),
    []
  )
  const setCurrentComponentTypeFn = useCallback(
    (currentComponentType) =>
      store.dispatch(setCurrentComponentType(currentComponentType)),
    []
  )

  return {
    currentDrawer,
    isNonCompliantComment,
    currentComponentType,
    openDrawer,
    closeDrawer,
    setIsNonCompliantCommentFn,
    setCurrentComponentTypeFn
  }
}

export default useExecutionDrawer
