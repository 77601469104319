export const BACKEND_ERRORS = {
  SERIE_NAME_ALREADY_EXISTS: { descriptionId: 'series.conflict.name.error' },
  DEPARTMENT_NAME_ALREADY_EXISTS: {
    descriptionId: 'department.conflict.name.error'
  },
  UNIT_NAME_ALREADY_EXISTS: {
    descriptionId: 'unit.conflict.name.error'
  },
  PLANT_NAME_ALREADY_EXISTS: {
    descriptionId: 'plant.add.error.conflict'
  },
  PLANT_HAS_UNIT_BY_DEFAULT: {
    descriptionId: 'plant.delete.error.conflict.default'
  },
  PLANT_UNIT_HAS_EXECUTIONS: {
    descriptionId: 'plant.delete.error.conflict.procedure'
  },
  UNIT_ALREADY_EXISTS_BY_NAME_AND_PLANT: {
    descriptionId: 'unit.conflict.name.error'
  }
}

export const WEBSOCKET_ERROR_TYPES = {
  EXEC_ALREADY_CONNECTED: 'EXEC_ALREADY_CONNECTED',
  EXEC_NOT_CONNECTED: 'EXEC_NOT_CONNECTED',
  INTERNAL: 'INTERNAL',
  UNKNOWN: 'UNKNOWN'
}
