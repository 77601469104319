import React from 'react'
import styled from 'styled-components'

import Badge from '../../components/icons/badge'
import BadgeHollow from '../../components/icons/badge-hollow'

const CustomTreeNode = ({ index, title, isUnsigned, isParentOfUnsigned }) => {
  const Icon = isUnsigned ? Badge : isParentOfUnsigned ? BadgeHollow : null
  return (
    <div>
      <BoldSpan>{index}</BoldSpan>
      <BadgeContainer>
        {Icon && <Icon size='10px' color='#007EC3' />}
      </BadgeContainer>
      <span>{title}</span>
    </div>
  )
}

const BoldSpan = styled.span`
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
`

const BadgeContainer = styled.span`
  margin: 0 6px;
`

export default CustomTreeNode
