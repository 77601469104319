import React from 'react'

const SmallArrowRight = ({size = 24, color='currentColor'}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.50002 1.5L11.6467 8.64667C11.842 8.84133 11.842 9.158 11.6474 9.35267L11.6467 9.35333L4.50002 16.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SmallArrowRight
