import { Badge } from 'antd'
import React from 'react'
import styled from 'styled-components'

const HistoricalDrawerHeaderBody = ({ componentName, total }) => (
  <Container>
    <Title>{componentName}</Title>
    <Badge className='badge-primary'>{total}</Badge>
  </Container>
)

const Container = styled.section`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  align-self: stretch;

  gap: 16px;
`

const Title = styled.h6`
  color: #333;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;

  padding: 0;
  margin: 0;
`

export default HistoricalDrawerHeaderBody
