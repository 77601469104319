import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import { useTranslation } from '../../../../hooks/useTranslation'
import { EXECUTION_SIGNATURE_STATUS_ICON } from '../../../../utils/constants/execution/components'
import { EXECUTION_SIGNATURE_STATUS_COLOR } from '../../../../utils/constants/styles'

const FooterSignature = ({ id, name, date }) => {
  const dateFormat = useTranslation('dateFormat')
  const timeFormat = useTranslation('timeFormat')
  const Icon = EXECUTION_SIGNATURE_STATUS_ICON.OK

  return (
    <Wrapper>
      <div style={{ textAlign: 'left', marginBottom: '5px' }}>
        <Initials id={id}>{name}</Initials>
        <span style={{ fontSize: '14px' }}>
          {moment(date).format(dateFormat)} {moment(date).format(timeFormat)}
        </span>
      </div>
      <SelectedSignatureIcon color={EXECUTION_SIGNATURE_STATUS_COLOR.OK}>
        <IconContainer>
          <Icon />
        </IconContainer>
      </SelectedSignatureIcon>
    </Wrapper>
  )
}

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 46px;
  &:last-child {
    margin-right: 0;
  }
`

const Wrapper = styled(Flex)`
  align-items: start;
  padding: 11px 16px;
  min-height: 118px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 0 0 8px 8px;
`

const Initials = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const SelectedSignatureIcon = styled.div`
  background-color: ${({ color }) => color || 'transparent'};
  padding: 6px;
  border-radius: 50%;
`

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default FooterSignature
