import { Input, Popover } from 'antd'
import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import WarningIcon from '../../../../components/icons/warning-icon'
import useHistoricalComponent from '../../../../hooks/use-historical-component'
import { colorDisabled, colorEnabled } from '../../../../utils/constants/styles'
import { calculateResult } from '../../../../utils/helpers/formulas'
import { getWidthInPixels } from '../../../../utils/helpers/styles' 
import Translation from '../../../translations'

const RangeInput = ({
  onComponentChange,
  component,
  executionComponent,
  disabled,
  className,
  isHistoricalMode
}) => {
  const { options = {}, procedureComponentId } = component
  const { validationFormula, size } = options
  const [displayedValue, setDisplayedValue] = useState(
    executionComponent?.value
  )

  const error = useMemo(() => {
    const parsedValue = parseFloat(displayedValue)

    if (displayedValue == null) {
      return null
    } else if (validationFormula) {
      return calculateResult({
        formula: validationFormula,
        variableValuesMap: {
          result: parsedValue
        }
      }) ? null : (
        <Translation
          id={'validationNotPassed'}
          params={{ validationRule: validationFormula }}
        />
      )
    } else if (isNaN(parsedValue)) {
      return <Translation id={'invalidNumber'} />
    }
    return null
  }, [displayedValue, validationFormula])

  const {
    historicalModeEnabled,
    historicalComponentActive,
    onClickHistorical
  } = useHistoricalComponent({
    isHistoricalMode,
    showHistorical: options?.showHistorical,
    component
  })

  useEffect(() => {
    setDisplayedValue(executionComponent?.value)
  }, [executionComponent?.value])

  return (
    // El 'key' es necesario para que cuando se re-renderiza el componente, no pierda el foco y el usuario pueda seguir escribiendo
    <Wrapper
      key={'w' + component.id}
      color={error ? '#F10808' : '#d7d7d7'}
      data-size={size ? getWidthInPixels(size) : '120px'}
      data-active={historicalComponentActive}
      className={
        historicalModeEnabled
          ? `${className} historical-component`
          : `${className} recorder-numerical`
      }
      disabled={disabled}
      onClick={historicalModeEnabled ? onClickHistorical : undefined}
    >
      <InputRange
        disabled={disabled}
        data-size={size ? getWidthInPixels(size) : '120px'}
        onBlur={() => {
          if (executionComponent?.value != displayedValue) {
            onComponentChange({
              id: procedureComponentId,
              value: displayedValue ? parseFloat(displayedValue) : null
            })
          }
        }}
        onChange={({ target: { value } }) => {
          setDisplayedValue(value ? value.replace(/[^e|\d|.-]/g, '') : null)
        }}
        value={displayedValue}
        suffix={
          error ? (
            <Popover content={error}>
              <WarningIcon size={24} color='#ff0000' />
            </Popover>
          ) : (
            <span />
          )
        }
        maxLength={size ?? ''}
        inputMode={'numeric'}
      />
    </Wrapper>
  )
}

const Wrapper = styled.span`
  display: inline-flex;
  border-radius: 1px;
  width: ${(props) => props['data-size']};
  border: 2px solid ${(props) => props.color};
  @media print {
    height: 18px;
  }
`

const InputRange = styled(Input)`
  border-radius: 0px;

  & > .ant-input {
    border-radius: 0px;
    color: ${({ disabled }) =>
      disabled ? `${colorDisabled} !important` : `${colorEnabled} !important`};
  }

  @media print {
    .ant-input-affix-wrapper {
      width: ${(props) => props['data-size']};
      height: 16px;
      span {
        height: 16px;
      }
    }
  }
`

export default RangeInput
