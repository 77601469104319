import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { SaveHistoricalValuesError } from '../../../utils/errors/historical-values/save'

const tableName = TABLE_NAMES.HISTORICAL_VALUES

export async function writeHistoricalValues(data) {
  if (!data || !data.key || !data.name) throw new SaveHistoricalValuesError()

  const db = getDatabase()

  await db[tableName]
    .where('[key+name]')
    .anyOf([[data.key, data.name]])
    .delete()

  return db[tableName].put(data)
}
