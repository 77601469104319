import React from 'react'
import styled from 'styled-components'

import BlankButton from '../../../components/buttons/blank-button'
import Download from '../../../components/icons/download'

const DownloadButton = ({ id, disabled, loading, icon, onClick }) => {
  return (
    <StyledDownloadButton
      data-id={icon.type.name}
      id={id}
      disabled={disabled}
      loading={loading}
      icon={icon || <Download />}
      onClick={onClick}
    ></StyledDownloadButton>
  )
}

const StyledDownloadButton = styled(BlankButton)`
  margin: 0 5px;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #ffffff;
  background-color: #272928;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default DownloadButton
