import React, { useContext, useState } from 'react'

import TextButton from '../../../../components/buttons/text'
import Filter from '../../../../components/icons/filter'
import DateRangePicker from '../../../../views/date/range-picker'
import Translation from '../../../../views/translations'

import { FiltersModal } from './FiltersModal'

import { ProceduresFiltersContext } from '.'

export const Filters = () => {
  const { filters, updateFilters } = useContext(ProceduresFiltersContext)

  return (
    <>
      <DateRangePicker
        value={[filters.modifiedAfter, filters.modifiedBefore]}
        onChange={(dateRange) =>
          updateFilters({
            modifiedAfter: dateRange?.[0]?.startOf('day') || undefined,
            modifiedBefore: dateRange?.[1]?.endOf('day') || undefined
          })
        }
      />
      <ExtraFilters />
    </>
  )
}

const ExtraFilters = () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <TextButton
        onClick={() => setModalVisible(true)}
        icon={<Filter size={20} color='#645377' />}
      >
        <Translation id='filters' />
      </TextButton>
      <FiltersModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  )
}
