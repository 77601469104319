import WordArray from 'crypto-js/lib-typedarrays'
import SHA1 from 'crypto-js/sha1'

import { CONNECTION_STATUS } from '../../constants/connection'
import { ALLOWED_VIDEO_EXTENSIONS } from '../../constants/files'

export function getFilenameExtension(filename) {
  if (!filename || !filename.includes('.')) return ''

  return filename.substr(filename.lastIndexOf('.') + 1).toLowerCase()
}

// Check if the file extension is supported.
export function areFilesValid(files, allowedFileExtensions) {
  return Array.from(files).every((f) =>
    allowedFileExtensions.includes(getFilenameExtension(f.name))
  )
}

async function readBlobAsBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(blob)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export async function computeChecksum(blob) {
  let buffer = await readBlobAsBuffer(blob)
  var wordArray = WordArray.create(buffer),
    hash = SHA1(wordArray).toString()
  return hash
}

export async function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export function getAllowedExtensionsStepComments(connectionStatus) {
  const allowed = ['png', 'jpg']

  if (connectionStatus === CONNECTION_STATUS.ONLINE) {
    allowed.push(...ALLOWED_VIDEO_EXTENSIONS.map((e) => e.slice(1)))
  }

  return allowed
}

/**
 * Transforms a FileList and adds a hash with SHA1.
 * @param {FileList} files
 * @returns an array of objects containing the file object and a hash for the file
 */
export async function addHashToFiles(files) {
  return await Promise.all(
    Array.from(files).map(async (file) => {
      const fileHash = await computeChecksum(file)
      return {
        file: file,
        hash: fileHash
      }
    })
  )
}

export async function downloadFile(file, applicationType, name) {
  const blob = new Blob([file], { type: applicationType })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export async function getLinkToBlob(fileName, fileContent) {
  const link = document.createElement('a')

  const url = getBlob(fileContent)
  link.href = url

  link.download = fileName
  link.click()
}

// TODO: Eliminar los objectURL cuando ya no sean necesarios para ahorrar memoria
export function getBlob(fileContent) {
  let content = fileContent && fileContent.data ? fileContent.data : fileContent
  if (!content) return null
  return URL.createObjectURL(content)
}

export function getLinkToPDF(fileContent) {
  const link = document.createElement('a')

  link.href = getBlob(fileContent)
  link.download = fileContent.data.name
  link.click()
}
