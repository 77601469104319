import { createSelector } from '@reduxjs/toolkit'

export const getConnectedUsers = createSelector(
  (state) => {
    return state.sharedExecution
  },
  (sharedExecution) => {
    return sharedExecution.connectedUsers
  }
)
