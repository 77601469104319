export default function useActions({
  addPlant,
  modifyPlant,
  removePlant,
  reloadCustomerSettings,
  addUnit,
  modifyUnit,
  removeUnit,
  reloadUnits
}) {
  const modifyCustomerSettingAction = (customerSetting) =>
    modifyPlant(customerSetting, reloadCustomerSettings)
  const removeCustomerSettingAction = (customSettingId) =>
    removePlant(customSettingId, reloadCustomerSettings)
  const addCustomerSettingAction = (customerSetting) =>
    addPlant(customerSetting, reloadCustomerSettings)

  const modifyUnitAction = (unit, showSuccessMessage) =>
    modifyUnit(unit, reloadUnits, showSuccessMessage)
  const removeUnitAction = (unitId) => removeUnit(unitId, reloadUnits)
  const addUnitAction = (unit) => addUnit(unit, reloadUnits)

  return {
    addCustomerSettingAction,
    modifyCustomerSettingAction,
    removeCustomerSettingAction,
    addUnitAction,
    modifyUnitAction,
    removeUnitAction
  }
}
