import React from 'react'
import styled from 'styled-components'

import { ANIMATED_LOGO } from '../../utils/constants/config'

const Logo = () => {
  return (
    <Logotype
      id='LogoId'
      src={ANIMATED_LOGO ? '/public/logo.gif' : '/public/logo0.png'}
    />
  )
}

const Logotype = styled.img`
  height: 48px;
  width: 48px;
  margin-left: 6px;
`

export default Logo
