import { Select as SelectAntd } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Translation from '../../../../translations'

import useChangeValue from './hooks/use-change-value'

const SelectConditionalForcedValue = ({ conditional }) => {
  const value =
    typeof conditional.forcedValue === 'boolean'
      ? conditional.forcedValue
      : 'default'

  const { onChangeValue } = useChangeValue({
    conditionalId: conditional.id
  })

  return (
    <Select
      onClick={(e) => e.stopPropagation()}
      onChange={onChangeValue}
      value={value}
      data-testid='select-conditional-forced-value'
      options={[
        { label: <Translation id='force.contional.true.title' />, value: true },
        {
          label: <Translation id='force.contional.false.title' />,
          value: false
        },
        {
          label: <Translation id='force.contional.null.title' />,
          value: 'default'
        }
      ]}
    />
  )
}

const Select = styled(SelectAntd)`
  width: 194px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border: 1px solid #d9d9d9;
  background: #fff;
`

export default SelectConditionalForcedValue
