import { io } from 'socket.io-client'

import { store } from '../../../../store'
import { setLiveValue } from '../../../../store/executionComponents/slice'
import {
  setIsLiveData,
  setIsReadoutSubscribed
} from '../../../../store/socket/readout/slice'
import {
  READOUT_VALIDITY_TIME,
  WS_URL
} from '../../../../utils/constants/config'
import {
  WS_UPDATE,
  WS_READOUT_EVENT
} from '../../../../utils/constants/websocket'
import { debug } from '../../../../utils/logger'

let ws = null

export async function connectReadout(token) {
  if (ws) {
    ws.close()
  }

  ws = io(`${WS_URL}/readout`, { auth: { token } })

  ws.on('connect_error', (ev) => {
    debug(':::::: Readout websocket connection error', ev)
  })

  ws.on('connect', (...params) => {
    debug(':::::: Readout websocket connected', params)
  })

  let sec = 0
  let events = 0

  ws.on(WS_READOUT_EVENT.NEW_DATA, (data) => {
    store.dispatch(setLiveValue({ components: data.components, clear: false }))
    store.dispatch(setIsLiveData(true))
    sec = READOUT_VALIDITY_TIME
    events += 1
    const event = events
    if (event === 1) {
      const interval = setInterval(() => {
        if (sec > 0) sec--
        else if (sec === 0) {
          store.dispatch(
            setLiveValue({ components: data.components, clear: true })
          )
          store.dispatch(setIsLiveData(false))
          clearInterval(interval)
          events = 0
        }
      }, 1000)
    }

    debug(':::::: Readout websocket received new data', data)
  })

  ws.on(WS_UPDATE.SOCKET_ERROR, (update) => {
    debug(':::::: Readout websocket received error', update)
  })

  ws.on('disconnect', (...params) => {
    debug(':::::: Readout websocket disconnected', params)
  })
}

export function subscribeReadout(data) {
  try {
    ws.emit(WS_READOUT_EVENT.SUSCRIBE, data)
    store.dispatch(setIsReadoutSubscribed(true))
    debug(':::::: Readout websocket subscribing data', data)
  } catch (error) {
    console.error(error)
  }
}

export function unsubscribeReadout(data) {
  try {
    ws.emit(WS_READOUT_EVENT.UNSUSCRIBE, data)
    store.dispatch(setIsReadoutSubscribed(false))
    debug(':::::: Readout websocket unsubscribing data', data)
  } catch (error) {
    console.error(error)
  }
}

export function disconnectReadout() {
  if (ws) {
    store.dispatch(setIsReadoutSubscribed(false))
    ws.close()
  }
}
