import moment from 'moment'

import { store } from '..'
import { getDatabase } from '../../localdb'
import { readExecutions } from '../../localdb/execution/read'
import { readProcedures } from '../../localdb/procedures/read'
import { writeProcedures } from '../../localdb/procedures/write'
import { requestProcedures } from '../../services/procedures/http/get'
import { TABLE_NAMES } from '../../utils/constants/localdb'
import { PROCEDURE_STATUS } from '../../utils/constants/procedure'
import {
  getUserLocalStorageItem,
  setUserLocalStorageItem
} from '../../utils/helpers/user'

import { setUpdatedProceduresToDownload } from './slice'

const tableName = TABLE_NAMES.PROCEDURES

export const deleteProcedures = async (procedures) => {
  const db = getDatabase()

  return db[tableName]
    .where('id')
    .anyOf(procedures.map((pr) => pr.id))
    .delete()
}

export const cleanProcedures = async () => {
  const db = getDatabase()

  const localExecutions = await readExecutions()

  return db[tableName]
    .where('id')
    .noneOf(localExecutions.map((ex) => ex.procedureId))
    .delete()
}

export const checkProcedureUpdates = async () => {
  const newDate = new Date()
  const localProcedures = await readProcedures(true)

  const lastProceduresSyncDate = getUserLocalStorageItem(
    'lastProceduresSyncDate'
  )
  const date = moment.utc(lastProceduresSyncDate).subtract(1, 'days').format()

  setUserLocalStorageItem('lastProceduresSyncDate', newDate)

  const { data: proceduresRes } = await requestProcedures({
    modifiedAfter: date,
    status: PROCEDURE_STATUS.DEPRECATED
  })
  const serverProcedures = proceduresRes.items

  const deprecatedProcedures = serverProcedures?.filter((sp) =>
    localProcedures?.some((lp) => lp.id === sp.id)
  )

  let updatedProceduresToDownload = []
  if (deprecatedProcedures.length) {
    updatedProceduresToDownload = await Promise.all(
      deprecatedProcedures.map(async (p) => {
        // Por cada procedimiento obsoleto se busca en el server si hay otro publicado
        const { data: serverProcedures } = await requestProcedures({
          key: p.key,
          select: ['id', 'status', 'buildNumber', 'rev']
        })
        return serverProcedures?.items
          ?.filter((sp) => !localProcedures.some((lp) => lp.id === sp.id))
          .find((sp) => {
            return (
              sp.status === PROCEDURE_STATUS.PUBLISHED &&
              (sp.rev !== p.rev || sp.buildNumber !== p.buildNumber)
            )
          })
      })
    )
    // Se actualiza la BD local con los procedimientos obsoletos
    await writeProcedures(deprecatedProcedures)
    store.dispatch(setUpdatedProceduresToDownload(updatedProceduresToDownload))
  }
}
