import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'


import useExecutionDrawer from '../../../../hooks/use-execution-drawer'
import {
  getExecutionId,
  getIsExecution,
  getIsReview
} from '../../../../store/execution/selectors'
import { getExecutionComponents } from '../../../../store/executionComponents/selectors'
import { getProcedure } from '../../../../store/procedure/selectors'
import {
  EXECUTION_SIGNATURE_STATUS,
  EXECUTION_SIGNATURE_STATUS_ICON
} from '../../../../utils/constants/execution/components'
import { EXECUTION_SIGNATURE_STATUS_COLOR } from '../../../../utils/constants/styles'
import { getSignatures } from '../../../../utils/helpers/components/signatures'
import Translation from '../../../translations'
import CommonExecutionViewDrawer from '../common'

const statusTitle = {
  [EXECUTION_SIGNATURE_STATUS.IN_PROGRESS]: 'stepsInProgress',
  [EXECUTION_SIGNATURE_STATUS.WAITING]: 'stepsOnHold'
}

const SignatureDrawer = ({
  onSelectStep,
  signatureStatus,
  disabled,
  drawerId
}) => {
  const procedure = useSelector(getProcedure)
  const isReview = useSelector(getIsReview)
  const isExecution = useSelector(getIsExecution)
  const executionId = useSelector(getExecutionId)

  const { currentDrawer, openDrawer, closeDrawer } = useExecutionDrawer()

  const executionComponents = useSelector(getExecutionComponents)
  const Icon = EXECUTION_SIGNATURE_STATUS_ICON[signatureStatus]
  const color = EXECUTION_SIGNATURE_STATUS_COLOR[signatureStatus]

  // Retrasar cálculo de los ítems para mejorar la respuesta de la aplicación
  // TODO: Explorar si se puede replantear el proceso como un Web Worker
  const [items, setItems] = useState()
  useEffect(() => {
    let mounted = true
    const timeout = setTimeout(() => {
      const nextItems =
        executionComponents &&
        getSignatures({
          procedure,
          executionId,
          signatureStatus,
          closeDrawer,
          executionComponents,
          onSelectStep
        })
      if (mounted) {
        setItems(nextItems)
      }
    }, 1000)
    return () => {
      mounted = false
      clearTimeout(timeout)
    }
  }, [
    signatureStatus,
    procedure,
    executionComponents,
    executionId,
    onSelectStep,
    closeDrawer
  ])

  const setVisible = (visible) => {
    visible ? openDrawer(drawerId) : closeDrawer()
  }

  return (
    <CommonExecutionViewDrawer
      id={drawerId}
      className={drawerId}
      icon={<Icon disabled={isReview || disabled} />}
      color={color}
      visible={isExecution && currentDrawer === drawerId}
      setVisible={setVisible}
      badgeCount={items?.length ?? 0}
      disabled={isReview || disabled}
      title={<Translation id={statusTitle[signatureStatus]} />}
    >
      <Menu
        id='menu-items'
        className='drawer-comments'
        items={items?.map((item, i) => ({
          key: i,
          label: (
            <ItemText onClick={item.handleClick}>
              {item.stepIndex} {item.title}
            </ItemText>
          )
        }))}
      />
    </CommonExecutionViewDrawer>
  )
}

const ItemText = styled.a`
  width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export default SignatureDrawer
