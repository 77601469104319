import { EXECUTION_STATUS } from '../../../utils/constants/execution'

export function canInsertComment(mode, executionStatus, modesNotAllowed) {
  const allowInsertComment = mode
    ? !modesNotAllowed.includes(mode)
    : ![
        EXECUTION_STATUS.FINISHED,
        EXECUTION_STATUS.ABORTED,
        EXECUTION_STATUS.PAUSED
      ].includes(executionStatus)

  return allowInsertComment
}
