import { Input } from 'antd'
import React, { useState, useContext, useEffect } from 'react'
import { useDebounce } from 'react-use'
import styled from 'styled-components'

import TextButton from '../../../components/buttons/text'
import Filter from '../../../components/icons/filter'
import Search from '../../../components/icons/search'
import { useTranslation } from '../../../hooks/useTranslation'
import Translation from '../../../views/translations'

import { FiltersModal } from './FiltersModal'
import { UserFilterTags } from './UserFilterTags'

import { UsersFiltersContext } from '.'

export const Filters = ({ roles }) => {
  // El placeholder no permite elementos de react, solo strings
  const searchTranslation = useTranslation('searchByTerms')
  const { filters, updateFilters } = useContext(UsersFiltersContext)
  const [search, setSearch] = useState(filters.search)

  useEffect(() => {
    setSearch(filters.search)
  }, [filters.search])

  useDebounce(
    () => {
      updateFilters({ search })
    },
    500,
    [search]
  )

  return (
    <>
      <FiltersRow>
        <SearchInput
          id='search-input'
          placeholder={searchTranslation}
          value={search}
          suffix={<Search size={12} />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <ExtraFilters roles={roles} />
      </FiltersRow>
      <UserFilterTags roles={roles} />
    </>
  )
}

const ExtraFilters = ({ roles }) => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <TextButton
        id='admin-users-filters-button'
        onClick={() => setModalVisible(true)}
        icon={<Filter size={20} color='#645377' />}
      >
        <Translation id='filters' />
      </TextButton>
      <FiltersModal
        visible={modalVisible}
        setVisible={setModalVisible}
        roles={roles}
      />
    </>
  )
}

const FiltersRow = styled.div`
  display: flex;
`

const SearchInput = styled(Input)`
  margin-right: 10px;
  border-color: #979797;

  &,
  input {
    background-color: transparent;
  }

  & input::placeholder {
    color: #979797;
  }
`
