import { Table } from 'antd'
import get from 'lodash.get'
import moment from 'moment'
import React, { useMemo } from 'react'
import styled from 'styled-components'


import { COLUMN_TYPES } from '../../../utils/constants/table'
import LocalizedMoment from '../../date/localized-moment'

const BaseTable = ({ loading, pagination, columns, dataSource, ...rest }) => {
  const scrollWidth = useMemo(
    () =>
      columns.reduce((acc, column) => {
        if (column.width) {
          return acc + column.width
        }
        if (column.minWidth) {
          return acc + column.minWidth
        }
        return acc
      }, 0),
    [columns]
  )
  return (
    <StyledTable
      {...rest}
      dataSource={dataSource}
      data-loading={loading}
      data-has-pagination={!!pagination}
      pagination={pagination || false}
      showSorterTooltip={false}
      columns={columns.map((column) => ({
        ...column,

        render: (...renderArgs) => {
          const [, record] = renderArgs
          let newText = get(record, column.dataIndex)
          if (
            newText === undefined &&
            column.dataIndex === 'executionGoal.name'
          ) {
            newText = record.otherExecutionGoal
          }
          let componentResult = newText

          renderArgs[0] = newText
          if (column.render) {
            componentResult = column.render(...renderArgs)
          } else {
            if (column.type === COLUMN_TYPES.DATE) {
              componentResult = <LocalizedMoment date={moment(newText)} />
            }
          }

          if (column.width) {
            componentResult = (
              <StyledCell data-width={column.width}>
                {componentResult}
              </StyledCell>
            )
          } else if (column.minWidth) {
            componentResult = (
              <StyledCell data-min-width={column.minWidth}>
                {componentResult}
              </StyledCell>
            )
          }

          return componentResult
        },
        onCell: (...args) => ({
          'data-column-id': column.dataIndex,
          'data-row-id': args[1],
          ...column.onCell?.(...args)
        })
      }))}
      rowKey='id'
      scroll={{
        x: dataSource?.length ? 'max-content' : scrollWidth,
        y: true
      }}
    />
  )
}

const StyledTable = styled(Table)`
  .ant-table-container {
    display: flex;
    flex-direction: column;
  }
  &.ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table-container,
  .ant-table-body,
  .ant-table,
  table .ant-empty {
    overflow: auto;
    height: 100%;
  }
  .ant-spin-container {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }
  .ant-table {
    flex-grow: 1;
    min-height: 0;
  }
  .ant-pagination {
    margin-bottom: 0;
  }
  .ant-table-filter-trigger.active {
    color: #fff;
    background: #a709bc;
  }
  div.ant-typography,
  .ant-typography p,
  .ant-table-cell p {
    margin-bottom: 0;
  }
  .ant-table-row.ant-table-row-level-0.ant-table-row-selected
    > .ant-table-cell {
    background: #f8f8f8;
  }
  .ant-table-filter-column {
    display: flex;
    justify-content: space-evenly;
  }
`

const StyledCell = styled.span`
  display: inline-block;
  ${({ 'data-width': width }) => (width ? `width: ${width}px;` : '')}
  ${({ 'data-min-width': minWidth }) =>
    minWidth ? `min-width: ${minWidth}px;` : ''}
`

export default BaseTable
