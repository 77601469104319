import {
  setEditedLiveValue,
  setIsLiveValueEdited,
  setIsLiveValueLocked
} from '../../store/executionComponents/slice'

export default function useReadout({
  value,
  isLocked,
  setIsLocked,
  procedureComponentId,
  readoutValues,
  setReadoutValues,
  shouldPersist,
  onComponentChange,
  setIsEditing,
  initReadoutComponent,
  initials,
  dispatch
}) {
  const saveValues = (value, liveValue) => {
    const saveComponent = {
      id: procedureComponentId,
      value: value,
      additionalValues: liveValue
        ? {
            editedBy: liveValue.editedBy,
            quality: liveValue.quality,
            timestamp: new Date().valueOf(),
            unit: liveValue.unit
          }
        : null
    }
    shouldPersist && onComponentChange(saveComponent)
    dispatch(
      setEditedLiveValue({
        componentId: procedureComponentId,
        liveValue: liveValue
      })
    )
  }

  const handleEditReadout = (key, val) => {
    let readoutValueToSave = { ...readoutValues }
    readoutValueToSave[key] = val
    setReadoutValues(readoutValueToSave)

    const valuesToSave = {
      ...readoutValueToSave,
      timestamp: new Date().valueOf(),
      editedBy: initials
    }

    saveValues(key === 'value' ? val : value, valuesToSave)
  }

  const handleToggleEditMode = ({ target: { checked } }) => {
    if (checked) {
      dispatch(
        setIsLiveValueEdited({
          componentId: procedureComponentId,
          isEdited: true
        })
      )
      setIsEditing(true)
    } else {
      setIsEditing(false)
      setReadoutValues(initReadoutComponent)
      dispatch(
        setIsLiveValueEdited({
          componentId: procedureComponentId,
          isEdited: false
        })
      )
      saveValues(null, null)
    }
  }

  const handleToggleLock = () => {
    if (!isLocked) {
      setIsLocked(true)
      dispatch(
        setIsLiveValueLocked({
          componentId: procedureComponentId,
          isLocked: true
        })
      )
      saveValues(readoutValues.value, readoutValues)
    } else {
      setIsLocked(false)
      dispatch(
        setIsLiveValueLocked({
          componentId: procedureComponentId,
          isLocked: false
        })
      )
      saveValues(null, null)
    }
  }

  return { handleEditReadout, handleToggleEditMode, handleToggleLock }
}
