import { store } from '../../store'
import { getUser } from '../../store/user-management/selectors'
import { syncDeviceProcedures } from '../device/sync'
import { syncFiles } from '../files/sync'
import { syncUserCodes, syncUsers } from '../users/sync'

export async function _syncData() {
  let hasToSyncData = false
  let updatedProceduresToDownload = []
  const user = getUser(store.getState())
  if (user) {
    await Promise.all([syncUsers(), syncUserCodes(), syncFiles()])

    await syncDeviceProcedures()
  }

  return { hasToSyncData, updatedProceduresToDownload }
}
