import React from 'react'

const BurgerMenu = ({ size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='wrapper'>
        <path
          id='Union'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.00033 3.33073C8.73671 3.33073 9.33366 2.73378 9.33366 1.9974C9.33366 1.26102 8.73671 0.664062 8.00033 0.664062C7.26395 0.664062 6.66699 1.26102 6.66699 1.9974C6.66699 2.73378 7.26395 3.33073 8.00033 3.33073ZM8.00033 9.16406C8.73671 9.16406 9.33366 8.56711 9.33366 7.83073C9.33366 7.09435 8.73671 6.4974 8.00033 6.4974C7.26395 6.4974 6.66699 7.09435 6.66699 7.83073C6.66699 8.56711 7.26395 9.16406 8.00033 9.16406ZM9.33366 13.6641C9.33366 14.4004 8.73671 14.9974 8.00033 14.9974C7.26395 14.9974 6.66699 14.4004 6.66699 13.6641C6.66699 12.9277 7.26395 12.3307 8.00033 12.3307C8.73671 12.3307 9.33366 12.9277 9.33366 13.6641Z'
          fill='#333333'
        />
      </g>
    </svg>
  )
}

export default BurgerMenu
