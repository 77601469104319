import { deleteDeviceProcedure } from '../../../services/devices/procedures/delete'
import { isOnline } from '../../../utils/helpers/connection'
import { getDeviceId } from '../../../utils/helpers/device'

export async function deleteDeviceProcedures(procedures) {
  const deviceId = getDeviceId()

  if (isOnline()) {
    await Promise.all(
      procedures.map(({ id }) => deleteDeviceProcedure(deviceId, id))
    )
  }
}
