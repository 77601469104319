import { Input } from 'antd'
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'


import useHistoricalComponent from '../../../../hooks/use-historical-component'
import { colorDisabled, colorEnabled } from '../../../../utils/constants/styles'
import { calculateContentHeight } from '../../../../utils/helpers/procedure-components/textarea'
import Translation from '../../../translations'

const { TextArea: AntdTextArea } = Input

const TextArea = ({
  onComponentChange,
  component,
  executionComponent,
  disabled,
  className,
  isHistoricalMode
}) => {
  const { options = {}, procedureComponentId } = component
  const { cols, rows } = options
  const [typedValue, setTypedValue] = useState(executionComponent?.value)
  const [canEdit, setCanEdit] = useState(true)
  const [error, setError] = useState(false)

  const calculateNumberOfLines = useCallback(() => {
    const textArea = document.getElementById(procedureComponentId)
    const style = window.getComputedStyle
      ? window.getComputedStyle(textArea)
      : textArea.currentStyle
    const taLineHeight = parseInt(style.lineHeight, 10)

    // Get the scroll height of the textarea & number of lines
    const taHeight = calculateContentHeight(textArea, taLineHeight)
    return taHeight
  }, [procedureComponentId])

  useEffect(() => {
    setTypedValue(executionComponent?.value)
  }, [executionComponent?.value])

  useEffect(() => {
    setError(calculateNumberOfLines() > rows)
  }, [calculateNumberOfLines, rows])

  const {
    historicalModeEnabled,
    historicalComponentActive,
    onClickHistorical
  } = useHistoricalComponent({
    isHistoricalMode,
    showHistorical: options?.showHistorical,
    component
  })

  return (
    <section
      data-active={historicalComponentActive}
      className={
        historicalModeEnabled
          ? `${className} historical-component-textarea`
          : `${className} recorder-textarea`
      }
      onClick={historicalModeEnabled ? onClickHistorical : undefined}
    >
      <StyledTextArea
        id={procedureComponentId}
        className={className}
        autoSize={{ minRows: rows, maxRows: rows }}
        rows={rows}
        cols={cols}
        disabled={disabled}
        data-error={error}
        onChange={(e) => {
          setError(calculateNumberOfLines() > rows)
          if (canEdit) {
            setTypedValue(e.target.value)
          }
        }}
        onKeyDown={(e) => {
          setCanEdit(e.code === 'Backspace' || calculateNumberOfLines() <= rows)
        }}
        onBlur={() => {
          if (executionComponent?.value !== typedValue) {
            onComponentChange({
              id: procedureComponentId,
              value: typedValue
            })
          }
        }}
        value={typedValue}
      />
      {error && (
        <ErrorMsg>
          <Translation id={'textAreaMaxRowsError'} params={{ maxRows: rows }} />
        </ErrorMsg>
      )}
    </section>
  )
}

const StyledTextArea = styled(AntdTextArea)`
  padding: 10px;
  margin: 0 2px;
  width: auto;

  color: ${({ disabled }) =>
    disabled ? `${colorDisabled} !important` : `${colorEnabled} !important`};

  @media print {
    width: ${({ cols }) => cols * 8}px;
  }
`

const ErrorMsg = styled.p`
  color: #ff0000;
  margin-top: 5px;
  margin-left: 1px;
`

export default TextArea
