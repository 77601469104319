import React, { useRef } from 'react'
import styled from 'styled-components'

import CommentsButtonStyle from '../comment'

const AttachmentButton = ({
  icon: Icon,
  onChangeMedia,
  acceptedTypes,
  disabled,
  fileRef,
  onClick
}) => {
  const fileUploadInput = useRef(null)

  return (
    <CommentsButton
      disabled={disabled}
      onClick={() => {
        onClick ? onClick() : fileUploadInput?.current.click()
      }}
    >
      <div>
        <input
          accept={
            acceptedTypes
              ? acceptedTypes.map((type) => `.${type}`).join(',')
              : '*'
          }
          multiple
          type='file'
          id='file'
          ref={fileRef ?? fileUploadInput}
          style={{ display: 'none' }}
          onChange={(e) => {
            onChangeMedia(e)
            fileUploadInput.current.value = null
          }}
        />
        <div id='attach-button' style={{ marginTop: '5px' }}>
          <Icon size={24} />
        </div>
      </div>
    </CommentsButton>
  )
}

const CommentsButton = styled(CommentsButtonStyle)`
  margin-right: 5px;
`

export default AttachmentButton
