import React from 'react'

import { IconContainer } from '../../../containers/icon'

export const Pencil = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.4917 2.50838C19.2078 2.22572 18.8707 2.00211 18.5 1.85045C18.1292 1.6988 17.732 1.62211 17.3314 1.62483C16.9308 1.62754 16.5347 1.70959 16.1661 1.86625C15.7974 2.02291 15.4634 2.25107 15.1833 2.53755L3.1 14.6209L1.625 20.375L7.37917 18.8992L19.4625 6.81588C19.7491 6.53599 19.9773 6.20208 20.134 5.83344C20.2907 5.46481 20.3728 5.06877 20.3755 4.66821C20.3782 4.26766 20.3015 3.87054 20.1498 3.49982C19.9981 3.1291 19.7744 2.79212 19.4917 2.50838V2.50838Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.8379 2.8833L19.1162 7.16163'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.10059 14.6201L7.38309 18.8951'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Pencil
