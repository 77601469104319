export function setWidthOfCellToTextArea(content) {
  const tables = content.querySelectorAll('table')
  Array.from(tables).forEach((table) => {
    let textAreaList = table.querySelectorAll('.textarea > textarea')
    Array.from(textAreaList).forEach((textArea) => {
      textArea.style.setProperty('height', 'inherit')
      textArea.style.setProperty('max-height', 'inherit')
      textArea.style.setProperty('padding', '0px')
    })
  })
}
