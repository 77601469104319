import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _readLocalDbData } from '../../common/read'
import { _writeLocalDbData } from '../../common/write'

const tableName = TABLE_NAMES.EXECUTION_GOALS

export const deleteLocalExecutionGoal = async (executionGoalId) => {
  const localExecutionGoals = await _readLocalDbData({
    tableName
  })

  const executionGoalsToDelete = localExecutionGoals.data
    .filter((executionGoal) => executionGoal.id === executionGoalId)
    .map((item) => ({ id: item.id }))

  executionGoalsToDelete &&
    (await _writeLocalDbData({
      tableName,
      data: executionGoalsToDelete,
      action: 'DELETE'
    }))
}
