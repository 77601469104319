import { List } from 'antd'
import styled from 'styled-components'

const ListItem = styled(List.Item)`
  border-bottom: 1px solid #d9d9d9 !important;
  & .ant-comment-content-author-time {
    display: flex;
  }
`

export default ListItem
