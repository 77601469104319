import React from 'react'
import { useSelector } from 'react-redux'

import RoundButton from '../../../../../components/buttons/round'
import { getTranslation } from '../../../../../i18n/getTranslation'
import { getIsMerge } from '../../../../../store/execution/selectors'
import { getProcedure } from '../../../../../store/procedure/selectors'
import { getConnectedUsers } from '../../../../../store/sharedExecution/selectors'
import { getUser } from '../../../../../store/user-management/selectors'
import {
  EXECUTION_STATUS,
  EXECUTION_STATUS_CAN_CHANGE_TO
} from '../../../../../utils/constants/execution'
import { PERMISSION_TYPES } from '../../../../../utils/constants/roles'
import {
  EXECUTION_STATUS_COLOR,
  EXECUTION_STATUS_TEXT_COLOR
} from '../../../../../utils/constants/styles'
import { notificationMessage } from '../../../../../utils/helpers/notification-message'
import { userHasPermission } from '../../../../../utils/helpers/permissions'

function isButtonEnabled({
  keepEnabled,
  activeStatus,
  isMerge,
  connectedUsers,
  statuses
}) {
  const isShared = activeStatus === EXECUTION_STATUS.SHARED
  const canChangeToStatuses = EXECUTION_STATUS_CAN_CHANGE_TO[
    activeStatus
  ]?.some((s) => statuses.includes(s))

  if (keepEnabled) {
    return true
  }

  if (!canChangeToStatuses) {
    return false
  }

  if (isShared) {
    return connectedUsers?.length < 2
  }

  if (isMerge) {
    return false
  }

  return true
}

export const ActionButton = ({
  id,
  icon: Icon,
  onClick,
  activeStatus,
  statuses,
  keepEnabled,
  neededPermissionLevel,
  ...props
}) => {
  const isMerge = useSelector(getIsMerge)
  const procedure = useSelector(getProcedure)
  const connectedUsers = useSelector(getConnectedUsers)
  const currentUser = useSelector(getUser)

  const backgroundColor = EXECUTION_STATUS_COLOR[activeStatus]
  const textColor = EXECUTION_STATUS_TEXT_COLOR[activeStatus]
  const active = statuses.includes(activeStatus)

  // If the execution is shared, it will only be able to change status if no users are connected apart from the current user
  const enabled = isButtonEnabled({
    keepEnabled,
    activeStatus,
    isMerge,
    connectedUsers,
    statuses
  })

  const hasPermission = () => {
    if (!neededPermissionLevel) return true
    const minimumRoleLevel = procedure[neededPermissionLevel]
    return (
      userHasPermission(PERMISSION_TYPES.EXECUTION_START) &&
      currentUser.role.roleLevel.level <= minimumRoleLevel
    )
  }

  const onActionButtonClick = () => {
    if (hasPermission()) {
      onClick()
    } else {
      notificationMessage({
        type: 'error',
        message: getTranslation('error'),
        description: getTranslation('noPermissionForSpecificRole', {
          params: { role: procedure[neededPermissionLevel] }
        })
      })
    }
  }

  return (
    <RoundButton
      {...props}
      id={id}
      onClick={onActionButtonClick}
      data-active={active}
      backgroundColor={active ? backgroundColor : undefined}
      textColor={active ? textColor : undefined}
      disabled={!enabled}
    >
      <Icon className='icon' />
    </RoundButton>
  )
}
