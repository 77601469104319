import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { BadgeCounter } from './badge-counter'

export const BadgedButton = ({ onClick, children, badgeProps, ...rest }) => {
  return (
    <ButtonCount onClick={onClick} {...rest}>
      <BadgeCounter {...badgeProps}>{children}</BadgeCounter>
    </ButtonCount>
  )
}

const ButtonCount = styled(Button)`
  border: none;
  box-shadow: none;
  padding-bottom: 0;
`
