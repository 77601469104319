import React from 'react'

import RadioChecked from '../../components/icons/radio-checked'
import RadioUnchecked from '../../components/icons/radio-unchecked'
import Checkbox from '../checkbox'

const RadioButton = (props) => (
  <Checkbox
    checkedIcon={RadioChecked}
    uncheckedIcon={RadioUnchecked}
    {...props}
  />
)

export default RadioButton
