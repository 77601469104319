import React from 'react'

const Home = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/home'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id='Group'
        transform='translate(0.499636, 1.000000)'
        stroke={color}
        strokeWidth='2'
      >
        <path
          d='M1.24344979e-14,10.7772343 L10.2927273,0.475779802 C10.9341818,-0.161311107 11.9694545,-0.15803838 12.6065455,0.482325256 C12.6065455,0.482325256 12.6065455,0.482325256 12.6076364,0.483416165 L22.9090909,10.7772343'
          id='Path'
        ></path>
        <polyline
          id='Path'
          points='19.6363636 7.50668889 19.6363636 20.5954162 3.27272727 20.5954162 3.27272727 7.50123435'
        ></polyline>
        <path
          d='M14.7272727,20.5954162 L14.7272727,15.6863253 C14.7272727,13.8786889 13.2621818,12.413598 11.4545455,12.413598 C9.64690909,12.413598 8.18181818,13.8786889 8.18181818,15.6863253 L8.18181818,20.5954162'
          id='Path'
        ></path>
      </g>
    </g>
  </svg>
)

export default Home
