import {
  EXECUTION_COMPONENT_TYPES,
  EXECUTION_SIGNATURE_STATUS
} from '../../constants/execution/components'
import { calculateResult } from '../formulas'

export function checkComponentMeetsCriteria(
  componentType,
  procedureComponentId,
  componentValue,
  procedure
) {
  let dontMeetCriteria = false
  const filteredComponent = procedure.components.find((procComponent) => {
    return procComponent.id === procedureComponentId
  })
  switch (componentType.toUpperCase()) {
    case EXECUTION_COMPONENT_TYPES.SIGNATURE.toUpperCase():
      dontMeetCriteria =
        componentValue?.status === EXECUTION_SIGNATURE_STATUS.NOT_OK
      break
    case EXECUTION_COMPONENT_TYPES.MULTICELL.toUpperCase():
      dontMeetCriteria =
        componentValue &&
        filteredComponent.options.dontMeetCriteria &&
        componentValue === filteredComponent.options?.label
      break
    case EXECUTION_COMPONENT_TYPES.NUMERICAL.toUpperCase(): {
      const {
        options: { maxRange, minRange, validationFormula }
      } = filteredComponent

      if (!maxRange && !minRange && !validationFormula) {
        dontMeetCriteria = false
        break
      }

      if (validationFormula) {
        const result = calculateResult({
          formula: validationFormula,
          variableValuesMap: {
            result: parseFloat(componentValue)
          }
        })
        dontMeetCriteria = !result
        break
      }

      dontMeetCriteria = componentValue > maxRange || componentValue < minRange
      break
    }
    case EXECUTION_COMPONENT_TYPES.OPTION_LIST.toUpperCase(): {
      const {
        options: { list }
      } = filteredComponent

      const option = list.find((item) => {
        return item.option
          ? (Array.isArray(componentValue) &&
              componentValue.includes(item.option)) ||
              item.option === componentValue
          : (Array.isArray(componentValue) && componentValue.includes(item)) ||
              item === componentValue
      })
      dontMeetCriteria = option.dontMeetCriteria
      break
    }
    case EXECUTION_COMPONENT_TYPES.CHECKBOX.toUpperCase(): {
      const { options } = filteredComponent
      if (componentValue) {
        dontMeetCriteria = options && options.dontMeetCriteria
      } else {
        dontMeetCriteria = (options && !options.dontMeetCriteria) || !options
      }
      break
    }
    case EXECUTION_COMPONENT_TYPES.RECORDER.toUpperCase():
    case EXECUTION_COMPONENT_TYPES.TEXTAREA.toUpperCase():
    case EXECUTION_COMPONENT_TYPES.TIME.toUpperCase():
    case EXECUTION_COMPONENT_TYPES.DATE.toUpperCase(): {
      const { options } = filteredComponent
      dontMeetCriteria = !componentValue && options?.dontMeetCriteriaIfEmpty
      break
    }
    default:
      dontMeetCriteria = false
      break
  }
  return dontMeetCriteria
}
