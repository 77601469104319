import { useRemoteData } from '../useRemoteData'

export const useGlobalExecutions = ({
  disabled,
  select,
  skip,
  take,
  orderBy,
  orderDirection,
  modifiedBefore,
  modifiedAfter,
  unit,
  key,
  series,
  title,
  status,
  qaStatus,
  excludeQaStatus,
  user,
  userDownloadable,
  rolePlayable
} = {}) => {
  return useRemoteData({
    url: '/executions',
    disabled,
    select,
    modifiedBefore: modifiedBefore?.toISOString(),
    modifiedAfter: modifiedAfter?.toISOString(),
    status,
    qaStatus,
    excludeQaStatus,
    user: user,
    key,
    unit,
    series,
    title,
    userDownloadable,
    rolePlayable,
    skip,
    take,
    orderBy: orderBy || 'updatedAt',
    orderDirection: orderDirection || 'ascend'
  })
}
