import { Drawer } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { useGlobalProcedures } from '../../../hooks/data/useGlobalProcedures'
import usePagination from '../../../hooks/metadata/use-pagination'
import { useFilters } from '../../../hooks/useFilters'
import { isOnline } from '../../../store/userInterface/selectors'
import { PROCEDURE_STATUS } from '../../../utils/constants/procedure'
import { PERMISSION_TYPES } from '../../../utils/constants/roles'
import { userHasPermission } from '../../../utils/helpers/permissions'
import BasePage from '../../../views/base-page'
import BaseTable from '../../../views/tables/base-table'
import UnitTag from '../../../views/tags/unit'
import Translation from '../../../views/translations'

import { DownloadProcedureButton } from './DownloadProcedureButton'
import { Filters } from './Filters'
import { ProcedureFilterTags } from './ProcedureFilterTags'


export const ProceduresFiltersContext = React.createContext()

const getColumns = () => {
  let columns = [
    {
      title: <Translation id='unit' />,
      dataIndex: 'unit',
      width: 70,
      render: (text) => <UnitTag unit={text} />
    },
    {
      title: <Translation id='series' />,
      dataIndex: 'serie.name',
      width: 70,
      sorter: true
    },
    {
      title: <Translation id='key' />,
      dataIndex: 'key',
      defaultSortOrder: 'ascend',
      minWidth: 90,
      sorter: true
    },
    {
      title: <Translation id='title' />,
      dataIndex: 'title',
      minWidth: 100,
      sorter: true
    },
    {
      title: <Translation id='rev' />,
      dataIndex: 'rev',
      width: 70,
      sorter: true
    },
    {
      title: <Translation id='status' />,
      dataIndex: 'status',
      fixed: 'right',
      render: (text) => <Translation id={text} />
    }
  ]

  if (userHasPermission(PERMISSION_TYPES.PROCEDURE_VERSION)) {
    columns = [
      ...columns,
      {
        title: <Translation id='version' />,
        dataIndex: 'buildNumber',
        width: 90,
        fixed: 'right',
        sorter: true
      }
    ]
  }

  return columns
}

export const GlobalProceduresDrawer = ({ visible, setVisible }) => {
  const defaultSortColumn = getColumns().find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'key',
    order: defaultSortColumn?.defaultSortOrder || 'ascend'
  })

  const online = useSelector(isOnline)
  const [pagination, setPagination] = usePagination()
  const { filters, updateFilters, resetFilters } = useFilters()
  const statusFilter = userHasPermission(
    PERMISSION_TYPES.PROCEDURE_SHOW_ALL_STATUSES
  )
    ? filters.status
    : PROCEDURE_STATUS.PUBLISHED

  const columns = getColumns()

  const {
    data,
    isValidating,
    mutate: refetch
  } = useGlobalProcedures({
    select: ['id', ...columns.map((column) => column.dataIndex), 'serie.id'],
    skip: (pagination.current - 1) * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    ...filters,
    status: statusFilter
  })

  const { items, total } = data || {}

  useEffect(() => {
    if (!online) {
      setVisible(false)
    }
  }, [online, setVisible])

  const setPaginationRef = useRef(setPagination)

  useEffect(() => {
    setPaginationRef.current((prev) => ({ ...prev, current: 1 }))
  }, [filters])

  useEffect(() => {
    if (visible) {
      refetch()
      resetFilters()
    }
  }, [visible, refetch, resetFilters])

  const handleTableChange = (pagination, _, sorter) => {
    setPagination({ ...pagination, total })
    setSorter({ ...sorter })
  }

  const actionColumn = {
    render: (_, procedure) => <DownloadProcedureButton procedure={procedure} />,
    fixed: 'right',
    width: '1%'
  }

  return (
    <CustomDrawer
      placement='bottom'
      closable={true}
      open={visible}
      onClose={() => {
        setVisible(false)
      }}
      height='85%'
    >
      <ProceduresFiltersContext.Provider
        value={{ filters, updateFilters, resetFilters }}
      >
        <BasePage
          title={<Translation id='proceduresDownload' />}
          extraHeaderContent={<Filters />}
          subHeaderContent={
            Object.keys(filters).length > 0 ? (
              <ProcedureFilterTags />
            ) : undefined
          }
          totalElements={total}
        >
          <BaseTable
            id='global-procedures-table'
            columns={[...columns, actionColumn]}
            dataSource={items}
            pagination={{ ...pagination, total }}
            loading={isValidating}
            onChange={handleTableChange}
          />
        </BasePage>
      </ProceduresFiltersContext.Provider>
    </CustomDrawer>
  )
}

const CustomDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
  }

  .ant-drawer-header-title {
    position: absolute;
    top: 20px;
    right: 10px;
  }
`
