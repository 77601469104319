import React from 'react'
import styled from 'styled-components'

import Translation from '../../../translations'

const PopoverContent = ({ items, footer, setVisible }) => {
  return (
    <>
      {items.map(
        (
          {
            content,
            icon: Icon,
            translationId,
            text,
            onClick,
            disabled,
            visible = true
          },
          i
        ) =>
          visible && (
            <MenuItem
              key={i}
              data-selectable={!!onClick}
              onClick={() => {
                if (disabled || !onClick) return
                onClick()
                setVisible(false)
              }}
              disabled={disabled}
            >
              {content || (
                <>
                  <MenuIcon>
                    <Icon color={disabled ? '#ccc' : '#000'} />
                  </MenuIcon>
                  {text || <Translation id={translationId} />}
                </>
              )}
            </MenuItem>
          )
      )}
      {footer}
    </>
  )
}

const MenuItem = styled.div`
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #edece8;
  transition: background-color 0.5s;

  &:last-child {
    border-bottom: none;
  }

  ${({ 'data-selectable': selectable }) =>
    selectable &&
    `
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`}
  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    color: #ccc;
  }
`

const MenuIcon = styled.span`
  margin-right: 9px;
`

export default PopoverContent
