import { Button, Spin } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getIsView } from '../../../store/execution/selectors'
import { ALLOWED_VIDEO_EXTENSIONS } from '../../../utils/constants/files'
import { getFilenameExtension } from '../../../utils/helpers/files'
import CloseButtonView from '../../buttons/close'
import EyeButtonView from '../../buttons/eye'
import AttachmentIconView from '../../icons/attachment'
import CameraIconView from '../../icons/camera'
import ConfirmModal from '../../modal/confirm-modal'

const AttachmentFile = ({
  attachment,
  removeMediaToAttach,
  preview = false,
  openModalMedia,
  deleteMediaFromComment
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const fileName = attachment?.file ? attachment?.file?.name : attachment?.name

  const isVideo = ALLOWED_VIDEO_EXTENSIONS.includes(
    attachment?.extension ?? `.${getFilenameExtension(fileName)}` ?? ''
  )
  const isView = useSelector(getIsView)

  return (
    <MediaWrapper>
      {attachment ? (
        <>
          <NameWrapper>
            <Button
              type='text'
              icon={
                isVideo ? (
                  <CameraIconView size={24} />
                ) : (
                  <AttachmentIconView size={24} />
                )
              }
              onClick={() => openModalMedia(attachment)}
            />
            <span style={{ marginLeft: '3px' }}>
              {(attachment?.name ? attachment?.name : attachment?.file?.name) ||
                (attachment?.originalName
                  ? attachment?.originalName
                  : attachment?.file?.originalName)}
            </span>
          </NameWrapper>
          {preview ? (
            <IconsContainer>
              <EyeButtonView onClick={() => openModalMedia(attachment)} />
              {!isView && (
                <CloseButtonView onClick={() => setOpenConfirmModal(true)} />
              )}
            </IconsContainer>
          ) : (
            <CloseButtonView
              onClick={() =>
                removeMediaToAttach && removeMediaToAttach(attachment.hash)
              }
            />
          )}
        </>
      ) : (
        <MediaSpin />
      )}
      <ConfirmModal
        id='confirm-delete-media'
        okButtonTextId='confirm'
        titleId='delete.media.confirm.title'
        textId='delete.media.confirm.text'
        textParams={{ name: attachment?.name || attachment?.originalName }}
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        onOk={() => {
          deleteMediaFromComment(attachment.id)
          setOpenConfirmModal(false)
        }}
      />
    </MediaWrapper>
  )
}

const MediaSpin = styled(Spin)`
  margin: 0 auto;
  margin-top: 5px;
`

const MediaWrapper = styled.div`
  height: 35px;
  width: 100%;
  margin: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 2px;
  padding-right: 5px;
  background-color: #ffffff;
`

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const NameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export default AttachmentFile
