import { Table, Checkbox } from 'antd'
import React from 'react'

import Translation from '../../../../translations'
import EditableHeader from '../../editable-header'
import ExtraStuff from '../extra-stuff'

function buildColumns({
  setUnitToEdit,
  unitToEdit,
  deletePopoverTextId,
  handleEdit,
  handleDelete,
  handleChangeDefault
}) {
  const unitConfigColumns = [
    {
      rowKey: 'C_NAME',
      title: <Translation id='config.unit.column.name' />,
      dataIndex: 'name',
      wdth: '60%',
      fixed: 'left',
      render: (_, unit) => (
        <EditableHeader
          element={unit}
          elementInEdition={unitToEdit}
          setEdition={setUnitToEdit}
          showBadge={false}
          showAditionalInfo={false}
        />
      )
    },

    {
      rowKey: 'C_DEFAULT',
      title: <Translation id='config.unit.column.default' />,
      dataIndex: 'default',
      width: '20%',
      fixed: 'left',
      render: (_, unit) => (
        <Checkbox
          name={unit?.id}
          key={unit?.id}
          value={unit?.key}
          checked={unit?.default}
          onChange={(value) =>
            handleChangeDefault(value.target.checked, unit?.id)
          }
        />
      )
    },
    {
      rowKey: 'C_OPTIONS',
      title: <Translation id='config.unit.column.options' />,
      dataIndex: 'options',
      width: '30%',
      fixed: 'right',
      render: (_, unit) => (
        <ExtraStuff
          element={unit}
          setElementToEdit={setUnitToEdit}
          elementToEdit={unitToEdit}
          deletePopoverTextId={deletePopoverTextId}
          handleDelete={handleDelete}
          handleClickEdit={handleEdit}
        />
      )
    }
  ]
  return unitConfigColumns
}

const TableUnits = ({
  units,
  plantId,
  setUnitToEdit,
  unitToEdit,
  deletePopoverTextId,
  handleEdit,
  handleDelete,
  handleChangeDefault
}) => {
  const columns = buildColumns({
    setUnitToEdit,
    unitToEdit,
    deletePopoverTextId,
    handleEdit,
    handleDelete,
    handleChangeDefault
  })

  return (
    <Table
      rowKey={'id'}
      key={plantId}
      columns={columns}
      dataSource={units}
      pagination={false}
    />
  )
}

export default TableUnits
