import { writeHistoricalValues } from '../../../localdb/historical-values/write'

export async function saveHistoricalComponents(historicalComponents) {
  if (!historicalComponents) {
    return
  }

  return Promise.all(
    Object.keys(historicalComponents).map(async (procKey) => {
      const historicalComponentsProc = historicalComponents[procKey]

      return Promise.all(
        Object.keys(historicalComponentsProc).map(async (componentName) => {
          const historicalComponentsByName =
            historicalComponentsProc[componentName]

          return writeHistoricalValues({
            components: historicalComponentsByName,
            key: procKey,
            name: componentName
          })
        })
      )
    })
  )
}
