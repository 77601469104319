import { interruptExecution } from '../execution/actions'
import { finishMergeExecution as finishedMergeExecution } from '../execution/slice'

import { finishMergeExecution as finishedMerge } from './slice'

export function finishMergeExecution(doRedirect = true) {
  return async (dispatch) => {
    dispatch(finishedMerge())
    dispatch(finishedMergeExecution())
    dispatch(interruptExecution(doRedirect))
  }
}
