import { Layout } from 'antd'
import React, { useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { cancelMerge } from '../../application/execution/merge'
import Home from '../../components/icons/home'
import Logo from '../../components/logo'
import useExecutionDrawer from '../../hooks/use-execution-drawer'
import useMediaUploadingModal from '../../hooks/use-media-uploading-modal'
import { unsubscribeReadout } from '../../services/execution-components/readout/websocket'
import {
  getExecution,
  getIsMerge,
  getIsReview
} from '../../store/execution/selectors'
import { getIsExecution } from '../../store/execution/selectors'
import { getMergeExecution } from '../../store/mergeExecution/selectors'
import { saveAllExecutionStore } from '../../store/middleware/executionSaveMiddleware'
import { getProcedure } from '../../store/procedure/selectors'
import {
  getHeaderTitleInfo,
  getIsWorking
} from '../../store/userInterface/selectors'
import { setHeaderTitleInfo } from '../../store/userInterface/slice'
import { EXECUTION_STATUS } from '../../utils/constants/execution'
import { notifyError } from '../../utils/notifications'
import ExecutionViewPageTitle from '../../views/execution-view/title'
import HeaderButton from '../buttons/header-button'
import ProfileButton from '../buttons/profile'
import MediaUploadingModal from '../modal/media-uploading-modal'
import RejectModal from '../modal/reject'

import ConnectionIndicator from './connection-indicator'
import Settings from './settings'

const { Header: AntHeader } = Layout

const Header = () => {
  const [rejectModalVisible, setRejectModalVisible] = useState(false)

  const history = useHistory()

  const execution = useSelector(getExecution)
  const isMerge = useSelector(getIsMerge)
  const isReview = useSelector(getIsReview)
  const isExecution = useSelector(getIsExecution)
  const mergeExecution = useSelector(getMergeExecution)
  const isWorking = useSelector(getIsWorking)
  const procedure = useSelector(getProcedure)
  const headerTitleInfo = useSelector(getHeaderTitleInfo)
  const store = useStore()
  const location = useLocation()

  const [open, setOpen, shouldOpenMediaUploadingModal] =
    useMediaUploadingModal()

  const { closeDrawer } = useExecutionDrawer()

  const pauseReadoutSubscription = () => {
    if (
      (execution?.status === EXECUTION_STATUS.EXECUTING ||
        execution?.status === EXECUTION_STATUS.SHARED) &&
      (location.pathname.includes('executions') ||
        location.pathname.includes('shared') ||
        location.pathname.includes('view')) &&
      procedure?.hasReadouts
    )
      unsubscribeReadout({
        procedureId: procedure.id,
        executionId: execution.id
      })
  }

  const onClickHeaderButton = async () => {
    if (isWorking) return

    if (shouldOpenMediaUploadingModal()) return

    if (isExecution) {
      await saveAllExecutionStore(store)
      pauseReadoutSubscription()
    }

    if (isReview) {
      setRejectModalVisible(true)
      return
    }

    if (isMerge) {
      return cancelMerge({
        executionId: execution.id,
        executionOneId: mergeExecution.executionOneId,
        executionTwoId: mergeExecution.executionTwoId
      })
        .then(() => history.push('/dashboard'))
        .catch((error) => {
          notifyError(error)
        })
    }

    closeDrawer()
    store.dispatch(setHeaderTitleInfo(null))
    history.push('/dashboard')
  }

  return (
    <TecHeader>
      <ButtonsContainerLeft>
        <Logo />
        {/* PR-1411: ocultar botón de volver */}
        {/* <HeaderButton
          id='back-button'
          onClick={async () => {
            if (isReview) {
              setRejectModalVisible(true)
            } else if (isMerge) {
              await deleteLocalAndRemoteExecution(execution)
              history.goBack()
            } else {
              history.goBack()
            }
          }}
          disabled={history.length <= 1}
        >
          <CaretLeftIcon disabled={history.length <= 1} />
        </HeaderButton> */}
        <HeaderButton
          id='home-button'
          onClick={onClickHeaderButton}
          loading={isWorking}
          icon={<Home />}
        ></HeaderButton>
      </ButtonsContainerLeft>

      {procedure && (
        <TitleContainer id='title-container'>
          <ExecutionViewPageTitle procedure={headerTitleInfo} />
        </TitleContainer>
      )}

      <ButtonsContainerRight>
        <ConnectionIndicator />
        <Settings disabled={isReview} />
        <ProfileButton disabled={isReview} />
      </ButtonsContainerRight>
      <RejectModal
        visible={rejectModalVisible}
        setVisible={setRejectModalVisible}
        execution={execution}
      />
      <MediaUploadingModal
        open={open}
        setOpen={setOpen}
        navigate={() => history.push('/dashboard')}
      />
    </TecHeader>
  )
}

const TecHeader = styled(AntHeader)`
  border-bottom: solid 2px #a289b2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0 2px 2px;
`

const ButtonsContainerLeft = styled.span`
  display: flex;
  align-items: center;
  justify-content: left;
`

const TitleContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  line-height: 24px;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const ButtonsContainerRight = styled.span`
  display: flex;
  align-items: center;
  justify-content: right;
`

export default Header
