import React from 'react'

import Modal from '..'
import Translation from '../../translations'

function InfoModal({
  open,
  setOpen,
  id,
  titleId,
  textId,
  okButtonTextId,
  textParams = {}
}) {
  return (
    <Modal
      id={id}
      title={<Translation id={titleId} />}
      okButtonText={<Translation id={okButtonTextId} />}
      onOk={() => setOpen(false)}
      closable={true}
      maskClosable={true}
      cancellable={false}
      onCancel={() => setOpen(false)}
      visible={open}
      setVisible={setOpen}
    >
      <Translation id={textId} params={textParams} />
    </Modal>
  )
}

export default InfoModal
