import React from 'react'
import styled from 'styled-components'

import FooterSignature from '../../../../execution-view/footer/signature'
import NoContent from '../../../../execution-view/no-content'
import Translation from '../../../../translations'
import EditableItem from '../editable-item'

const QAFormItem = ({ title, date, name, comment, disabled, onClick }) => (
  <Flex>
    <div
      id={`info-sign-${title}`}
      style={{ marginRight: '16px', minWidth: '160px' }}
    >
      <Title id='qa-title'>
        <Translation id={title} />
      </Title>
      {date ? (
        <FooterSignature id={`qa-signature-${title}`} name={name} date={date} />
      ) : (
        <NoContent />
      )}
    </div>
    <EditableItem
      id={
        !disabled
          ? `editable-comment-button-${title}`
          : `no-editable-button-${title}`
      }
      comment={comment}
      disabled={disabled}
      onClick={onClick}
    />
  </Flex>
)

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 16px;
`

const Title = styled.h4`
  padding: 3px 0;
  background: #e1e1db;
  border-radius: 8px 8px 0px 0px;
  font-weight: bold;
  width: 100%;
  margin: 0;
  text-align: center;
  font-family: inherit;
  text-indent: inherit;
`

export default QAFormItem
