import { Badge } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Translation from '../../../translations'

const ElementHeaderDepartment = ({ element }) => (
  <HeaderContainer>
    <span>{element.name}</span>

    {element?.series?.length > 0 ? (
      <Badge
        className='badge-primary'
        count={
          <>
            {element?.series?.length} <Translation id='series.lower' />
          </>
        }
      />
    ) : (
      <Translation className='departments-no-serie' id='departments.no.serie' />
    )}
  </HeaderContainer>
)

const HeaderContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`

export default ElementHeaderDepartment
