import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { addComment } from '../../../../application/execution-comments/actions/add'
import {
  deleteComment,
  deleteMediaFromComment
} from '../../../../application/execution-comments/actions/delete'
import { editComment } from '../../../../application/execution-comments/actions/edit'
import CommentEdit from '../../../../components/icons/comment-edit'
import useExecutionDrawer from '../../../../hooks/use-execution-drawer'
import {
  getExecution,
  getIsReview
} from '../../../../store/execution/selectors'
import { getExecutionComments } from '../../../../store/executionComments/selectors'
import { getUser } from '../../../../store/user-management/selectors'
import { DRAWERS } from '../../../../utils/constants/drawer'
import Translation from '../../../translations'
import CommonExecutionViewDrawer from '../common'

import ExecutionComments from './comments'

const ExecutionCommentsDrawer = ({ disabled }) => {
  const execution = useSelector(getExecution)
  const executionComments = useSelector(getExecutionComments, shallowEqual)

  const { currentDrawer, closeDrawer, openDrawer } = useExecutionDrawer()
  const currentUser = useSelector(getUser)
  const isReviewMode = useSelector(getIsReview)

  const sortedComments = useMemo(() => {
    if (executionComments) {
      return [...executionComments].sort((itemA, itemB) => {
        return (
          new Date(itemA.createdAt).getTime() -
          new Date(itemB.createdAt).getTime()
        )
      })
    }
  }, [executionComments])

  return (
    <CommonExecutionViewDrawer
      disabled={!execution || disabled}
      id={DRAWERS.EXECUTION_COMMENTS}
      icon={<CommentEdit disabled={!execution || disabled} />}
      title={<Translation id='procedureComments' />}
      color='#4B98C6'
      visible={execution && currentDrawer === DRAWERS.EXECUTION_COMMENTS}
      setVisible={(visible) =>
        visible ? openDrawer(DRAWERS.EXECUTION_COMMENTS) : closeDrawer()
      }
      badgeCount={sortedComments ? sortedComments.length : 0}
      placement='top'
      className='vertical-drawer'
    >
      <ExecutionComments
        comments={sortedComments}
        addComment={(text, media, commentType) => {
          addComment(
            execution.id,
            currentUser,
            text,
            media,
            commentType,
            isReviewMode
          )
        }}
        deleteComment={(commentId, media) => {
          deleteComment(commentId, media)
        }}
        editComment={editComment}
        deleteMediaFromComment={deleteMediaFromComment}
        executionId={execution?.id}
      />
    </CommonExecutionViewDrawer>
  )
}

export default ExecutionCommentsDrawer
