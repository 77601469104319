import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useGlobalExecutions } from '../../../../hooks/data/useGlobalExecutions'
import usePagination from '../../../../hooks/metadata/use-pagination'
import useSelectItemsTable from '../../../../hooks/use-select-items-table'
import { useFilters } from '../../../../hooks/useFilters'
import { getConnectionStatus } from '../../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../../utils/constants/connection'
import {
  EXECUTION_QA_STATUS,
  EXECUTION_STATUS
} from '../../../../utils/constants/execution'
import { COLUMN_TYPES } from '../../../../utils/constants/table'
import { getElipsisText } from '../../../../utils/helpers/strings'
import { getSelectPropertiesFromDataExecution } from '../../../../utils/helpers/tables/executions'
import BasePage from '../../../../views/base-page'
import ExecutionManagementSubheader from '../../../../views/subheader/execution-management'
import BaseTable from '../../../../views/tables/base-table'
import ExecutionStatusTag from '../../../../views/tags/execution-status'
import UnitTag from '../../../../views/tags/unit'
import Translation from '../../../../views/translations'
import { NoConnection } from '../../../NoConnection'

import { Filters } from './Filters'
import { Footer } from './Footer'
import { UploadExecutionModal } from './Footer/UploadExecutionModal'

export const ExecutionsFiltersContext = React.createContext({})

const columns = [
  {
    title: <Translation id='unit' />,
    dataIndex: 'procedure.unit',
    minWidth: 70,
    render: (text) => <UnitTag unit={text} />
  },
  {
    title: <Translation id='series' />,
    dataIndex: 'procedure.serie.name',
    width: 70,
    sorter: true
  },
  {
    title: <Translation id='key' />,
    dataIndex: 'procedure.key',
    minWidth: 90,
    sorter: true
  },
  {
    title: <Translation id='title' />,
    dataIndex: 'procedure.title',
    minWidth: 100,
    sorter: true
  },
  {
    title: <Translation id='rev' />,
    dataIndex: 'procedure.rev',
    width: 50,
    sorter: true
  },
  {
    title: <Translation id='executionGoal' />,
    dataIndex: 'executionGoal.name',
    ellipsis: true,
    minWidth: 120,
    render: (text) => <p>{getElipsisText(text, 23)}</p>
  },
  {
    title: <Translation id='lastMod' />,
    dataIndex: 'updatedAt',
    sorter: true,
    width: 70,
    defaultSortOrder: 'descend',
    type: COLUMN_TYPES.DATE
  },
  {
    title: <Translation id='startDate' />,
    dataIndex: 'createdAt',
    sorter: true,
    width: 70,
    type: COLUMN_TYPES.DATE
  },
  {
    title: <Translation id='owner' />,
    dataIndex: 'user.name',
    sorter: true,
    minWidth: 90
  },
  {
    title: <Translation id='status' />,
    dataIndex: 'status',
    width: 100,
    fixed: 'right',
    sorter: true,
    render: (text) => <ExecutionStatusTag status={text} />
  },
  {
    title: <Translation id='qa' />,
    dataIndex: 'qaStatus',
    sorter: true,
    fixed: 'right',
    width: 40,
    render: (text) => <ExecutionStatusTag qa status={text} />
  }
]

const selectProperties = getSelectPropertiesFromDataExecution(columns, [
  'id',
  'lastDownloadedAt',
  'procedureId',
  'procedure.id',
  'procedure.roleCreationLevel',
  'procedure.roleRevisionLevel',
  'procedure.roleApproveLevel',
  'procedure.roleMergeLevel',
  'procedure.simpleApprobation',
  'simpleApprobation'
])

export const ExecutionManagement = () => {
  const defaultSortColumn = columns.find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'updatedAt',
    order: defaultSortColumn?.defaultSortOrder || 'descend'
  })

  const [pagination, setPagination] = usePagination()
  const connectionStatus = useSelector(getConnectionStatus)
  const { selectedItems, onSelectChange, clearSelectedItems } =
    useSelectItemsTable()
  const { filters, defaultFilters, updateFilters, resetFilters } = useFilters({
    status: [EXECUTION_STATUS.FINISHED, EXECUTION_STATUS.INTERRUPTED]
  })
  const [uploadExecutionModalVisible, setUploadExecutionModalVisible] =
    useState(false)

  const {
    data,
    isValidating,
    mutate: refetch
  } = useGlobalExecutions({
    select: selectProperties,
    skip: (pagination.current - 1) * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    excludeQaStatus: [EXECUTION_QA_STATUS.APPROVED],
    ...filters,
    user: filters.user?.id
  })

  const clearSelectedItemsRef = useRef(clearSelectedItems)
  const setPaginationRef = useRef(setPagination)

  useEffect(() => {
    setPaginationRef.current((prev) => ({ ...prev, current: 1 }))
    clearSelectedItemsRef.current()
  }, [filters])

  useEffect(() => {
    refetch()
  }, [refetch])

  const { items, total } = data || {}

  const handleTableChange = (pagination, _, sorter) => {
    setPagination({ ...pagination })
    setSorter({ ...sorter })
  }

  return connectionStatus !== CONNECTION_STATUS.ONLINE &&
    location.pathname.includes('admin') ? (
    <NoConnection />
  ) : (
    <ExecutionsFiltersContext.Provider
      value={{ filters, defaultFilters, updateFilters, resetFilters }}
    >
      <BasePage
        title={<Translation id='manageExecutions' />}
        extraHeaderContent={<Filters />}
        subHeaderContent={
          <ExecutionManagementSubheader
            filters={filters}
            setModal={setUploadExecutionModalVisible}
          />
        }
        totalElements={total}
        selectedElements={selectedItems.length}
        clearSelection={clearSelectedItems}
      >
        <BaseTable
          id='admin-executions-table'
          columns={columns}
          dataSource={items?.filter(
            (i) => i.qaStatus !== EXECUTION_QA_STATUS.APPROVED
          )}
          pagination={{ ...pagination, total }}
          loading={isValidating}
          onChange={handleTableChange}
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys: selectedItems.map((item) => item.id),
            onSelect: onSelectChange
          }}
        />
        <Footer
          selection={selectedItems}
          clearSelectedItems={clearSelectedItems}
          onChange={() => refetch()}
        />
        <UploadExecutionModal
          visible={uploadExecutionModalVisible}
          setVisible={setUploadExecutionModalVisible}
        />
      </BasePage>
    </ExecutionsFiltersContext.Provider>
  )
}
