import { Button as AntButton, Popover, Modal } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { generateAndSendFinalReport } from '../../../application/reports/export'
import Caret from '../../../components/icons/caret'
import useExecutionGoal from '../../../hooks/data/useExecutionGoal'
import useLocalPlantUnit from '../../../hooks/use-local-plant-unit'
import { getTranslation } from '../../../i18n/getTranslation'
import { putSendExecution } from '../../../services/execution/http/put'
import { putChangeStatusSourceMergeExecutions } from '../../../services/execution/merge/http/put'
import { store } from '../../../store'
import { deleteExecutions } from '../../../store/execution/localDb'
import {
  getExecution,
  getIsApprove,
  getIsMerge,
  getIsReview
} from '../../../store/execution/selectors'
import {
  approveExecution,
  reviewExecution
} from '../../../store/execution/slice'
import { setMode } from '../../../store/execution/slice'
import { getExecutionComponents } from '../../../store/executionComponents/selectors'
import { finishMergeExecution } from '../../../store/mergeExecution/actions'
import { getConflicts } from '../../../store/mergeExecution/selectors'
import { getMergeExecution } from '../../../store/mergeExecution/selectors'
import { getProcedure } from '../../../store/procedure/selectors'
import { getUser } from '../../../store/user-management/selectors'
import { getIsWorking } from '../../../store/userInterface/selectors'
import {
  EXECUTION_QA_ACTION,
  EXECUTION_QA_STATUS,
  EXECUTION_STATUS
} from '../../../utils/constants/execution'
import { hasIncorrectSignatures } from '../../../utils/helpers/components/signatures'
import { erro } from '../../../utils/logger'
import RejectModal from '../../modal/reject'
import Translation from '../../translations'

import ApproveContent from './approve-content'
import MergeContent from './merge-content'

const SUB_TITLE = {
  merge: 'solveConflicts',
  review: 'signatures',
  approve: 'signatures'
}

const BUTTON_TEXT = {
  merge: 'merge',
  review: 'reviewed',
  approve: 'approved'
}

const ExecutionViewFooter = ({ loadPrint, onSelectStep }) => {
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [finishPopoverVisible, setFinishPopoverVisible] = useState(false)
  const [conflictIndex, setConflictIndex] = useState(0)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isLoadingReview, setIsLoadingReview] = useState(false)

  const execution = useSelector(getExecution)
  const procedure = useSelector(getProcedure)
  const isMerge = useSelector(getIsMerge)
  const isReview = useSelector(getIsReview)
  const isApprove = useSelector(getIsApprove)
  const mergeConflicts = useSelector(getConflicts)

  const isWorking = useSelector(getIsWorking)
  const components = useSelector(getExecutionComponents)
  const mergeExecution = useSelector(getMergeExecution)
  const componentsExecution = useSelector(getExecutionComponents)
  const currentUser = useSelector(getUser)

  const dispatch = useDispatch()
  const { plantUnit } = useLocalPlantUnit(execution?.unitId)
  const { executionGoal } = useExecutionGoal(execution?.executionGoalId)

  const mergeButtonDisabled = useMemo(() => {
    return (isMerge && mergeConflicts?.some((mc) => !mc.solved)) || isWorking
  }, [isMerge, mergeConflicts, isWorking])

  const history = useHistory()

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed])

  const action = useMemo(() => {
    if (isApprove) {
      return EXECUTION_QA_ACTION.APPROVE
    }
    if (isMerge) {
      return EXECUTION_QA_ACTION.MERGE
    }
    if (isReview) {
      return EXECUTION_QA_ACTION.REVIEW
    }
    return null
  }, [isApprove, isMerge, isReview])

  if (!execution || (!isReview && !isMerge && !isApprove)) {
    return null
  }

  const reviewButtonDisabled =
    action === EXECUTION_QA_ACTION.REVIEW &&
    hasIncorrectSignatures(components, procedure?.components)

  const finishOnAccept = async () => {
    //quito el modo review/approve porque vuelvo al menú principal
    await dispatch(setMode(null))
    setIsLoadingReview(false)
    history.push({
      pathname: `/dashboard/admin-executions`
    })
  }

  const onAccept = async () => {
    setIsLoadingReview(true)
    const actions = {
      [EXECUTION_QA_ACTION.REVIEW]: reviewExecution,
      [EXECUTION_QA_ACTION.APPROVE]: approveExecution,
      [EXECUTION_QA_ACTION.MERGE]: finishMergeExecution
    }

    const isMerge = action === EXECUTION_QA_ACTION.MERGE
    const isReview = action === EXECUTION_QA_ACTION.REVIEW
    const isApprove = action === EXECUTION_QA_ACTION.APPROVE

    if (isReview) {
      let updateData = {
        ...execution,
        qaStatus: EXECUTION_QA_STATUS.REVIEWED,
        currentStep: null,
        updatedAt: new Date().valueOf()
      }
      await putSendExecution([updateData])
    }

    if (isApprove) {
      let updateData = {
        ...execution,
        qaStatus: EXECUTION_QA_STATUS.APPROVED,
        currentStep: null,
        updatedAt: new Date().valueOf(),
        approvedBy: getUser(store.getState()),
        approvedAt: new Date().valueOf()
      }

      const message = getTranslation('generatingReport')

      let unloadPrint
      try {
        unloadPrint = await loadPrint(
          message,
          componentsExecution,
          procedure?.components
        )
      } catch (unloadPrint) {
        unloadPrint()
        const modalData = {
          title: 'printingErrorDuringApproveReason',
          content: 'printingErrorDuringApproveAction'
        }
        Modal.error({
          ...modalData,
          title: getTranslation(modalData.title),
          content: getTranslation(modalData.content)
        })
        setIsLoadingReview(false)
        return
      }
      try {
        const reportSent = await generateAndSendFinalReport(
          updateData.approvedAt,
          updateData.approvedBy,
          plantUnit,
          executionGoal
        )

        unloadPrint()

        if (reportSent) {
          await putSendExecution([updateData])
        } else {
          setIsLoadingReview(false)
          return
        }
      } catch (error) {
        const modalData = {
          title: 'printingErrorDuringApproveReason',
          content: 'printingErrorDuringApproveAction'
        }
        Modal.error({
          ...modalData,
          title: getTranslation(modalData.title),
          content: getTranslation(modalData.content)
        })
        setIsLoadingReview(false)
        return
      }
    }

    if (isMerge) {
      let updateData = {
        ...execution,
        qaStatus: null,
        status: EXECUTION_STATUS.FINISHED,
        currentStep: null,
        updatedAt: new Date().valueOf()
      }
      await putSendExecution([updateData])
    }

    await dispatch(actions[action](isMerge ? false : currentUser)).then(
      async () => {
        // Esperamos a que la ejecución se guarde en local y se sincronice
        // Después la borramos de local
        setTimeout(() => {
          deleteExecutions([execution.id]).catch((error) => {
            erro(`Error deleting executions: ${error}`)
          })
        }, 3000)

        if (isMerge) {
          await putChangeStatusSourceMergeExecutions(
            mergeExecution.executionOneId,
            mergeExecution.executionTwoId,
            true
          )

          // Damos tiempo a la sincronización para que se complete antes de redirigir
          setTimeout(async () => {
            await finishOnAccept()
          }, 3000)
        } else {
          await finishOnAccept()
        }
      }
    )
  }

  return (
    <TecFooter id='footer'>
      <CollapsableContainer onClick={toggleCollapse}>
        <CollapseButton
          id='expand-collapse-button'
          data-iscollapsed={isCollapsed}
        />
        <Title id='title-footer'>
          <Translation id={action} />
        </Title>
      </CollapsableContainer>

      <CollapsableContent data-iscollapsed={isCollapsed}>
        <SubTitle>
          {execution.merge?.conflicts?.length ? (
            <ConflictsCounter>
              <Translation
                id='counterOf'
                params={{
                  index: conflictIndex + 1,
                  total: execution.merge.conflicts.length
                }}
              />
            </ConflictsCounter>
          ) : (
            <></>
          )}
          <Translation id={SUB_TITLE[action]} />
        </SubTitle>
        <Content action={action}>
          {action === EXECUTION_QA_ACTION.MERGE ? (
            <MergeContentWrapper>
              <MergeContent
                conflictIndex={conflictIndex}
                setConflictIndex={setConflictIndex}
                onSelectStep={onSelectStep}
              />
            </MergeContentWrapper>
          ) : (
            <ApproveContent />
          )}
        </Content>
        <ButtonsContainer>
          {(isReview || isApprove) && (
            <Button
              id='reject-button'
              color='#F12E2E'
              background='#fff'
              onClick={() => setRejectModalVisible(true)}
            >
              <Translation id='rejected' />
            </Button>
          )}
          <Popover
            placement='top'
            content={<Translation id='cannotFinishReview' />}
            open={finishPopoverVisible && reviewButtonDisabled}
            onOpenChange={setFinishPopoverVisible}
            overlayClassName='review-tooltip'
          >
            <Button
              id='accept-button'
              loading={isLoadingReview}
              background='#000'
              onClick={onAccept}
              disabled={reviewButtonDisabled || mergeButtonDisabled}
            >
              <Translation id={BUTTON_TEXT[action]} />
            </Button>
          </Popover>
        </ButtonsContainer>
      </CollapsableContent>

      <RejectModal
        visible={rejectModalVisible}
        setVisible={setRejectModalVisible}
        execution={execution}
      />
    </TecFooter>
  )
}

const TecFooter = styled.footer`
  width: 100%;
`
const Title = styled.h3`
  text-align: center;
  background-color: #e1e1db;
  width: 100%;
  height: 56px;
  margin: 0;
  padding: 0;
  line-height: 56px;
  font-weight: bold;
  font-size: 30px;
  border-radius: 16px 16px 0 0;
  text-decoration: none;
  font-family: inherit;
`

const CollapsableContainer = styled.div`
  cursor: pointer;
  background-color: #e1e1db;
  border-bottom: solid 2px #a289b2;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
`

const CollapsableContent = styled.div`
  transition: max-height 0.4s ease-out;
  max-height: 500px;
  overflow: hidden;

  ${({ 'data-iscollapsed': collapsed }) => collapsed && 'max-height: 0;'}
`

const SubTitle = styled(Title)`
  font-size: 22px;
  border-bottom: none;
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    flex: 1;
    text-align: center;
    padding-left: 52px;
  }
`

const Content = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: ${({ action }) =>
    action === EXECUTION_QA_ACTION.MERGE ? '0px' : '30px'};
`

const ButtonsContainer = styled.div`
  background-color: #e1e1db;
  border-bottom: solid 2px #a289b2;
  padding: 20px;
  display: flex;
  justify-content: center;
`

const Button = styled(AntButton)`
  border: 1px solid #b8bab6;
  color: ${({ color }) => color || '#fff'};
  background-color: ${({ background }) => background || 'transparent'};
  width: 340px;
  text-align: center;
  margin-right: 18px;
  border-radius: 2px;
  &:last-child {
    margin-right: 0;
  }
`

const ConflictsCounter = styled.div`
  left: 20px;
  width: 100px;
  position: absolute;
`

const MergeContentWrapper = styled.div`
  min-height: 206px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CollapseButton = styled(Caret)`
  margin: 20px 20px 0 8px;
  transition: transform 0.2s ease-out;
  transform: ${({ 'data-iscollapsed': isCollapsed }) =>
    isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)'};
`

export default ExecutionViewFooter
