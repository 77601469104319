import React from 'react'

const Copy = ({ size = '24', color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.75 0.75H18.75V18.75H0.75V0.75Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.75 5.25C22.5784 5.25 23.25 5.92157 23.25 6.75V21.75C23.25 22.5784 22.5784 23.25 21.75 23.25H6.75C5.92157 23.25 5.25 22.5784 5.25 21.75'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Copy
