import { createSelector } from '@reduxjs/toolkit'

export const getMediaUploading = createSelector(
  (state) => {
    return state?.mediaUploading
  },
  (mediaUploading) => {
    return mediaUploading.uploading
  }
)

export const getMediaUploadingStep = createSelector(
  (state) => {
    return state?.mediaUploading
  },
  (mediaUploading) => {
    return mediaUploading.uploading?.filter((item) => item.stepId)
  }
)

export const getMediaUploadingExecutionComment = createSelector(
  (state) => {
    return state?.mediaUploading
  },
  (mediaUploading) => {
    return mediaUploading.uploading?.filter((item) => !item.stepId)
  }
)

export const hasUploadFinished = createSelector(
  (state) => {
    return state?.mediaUploading
  },
  (mediaUploading) => {
    if (mediaUploading.uploading.length === 0) return true

    const finished = mediaUploading.uploading.filter(
      (item) => item.completed && !item.error
    )
    const withError = mediaUploading.uploading.filter((item) => item.error)

    return (
      finished.length + withError.length === mediaUploading.uploading.length
    )
  }
)
