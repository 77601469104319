import { Drawer as AntDrawer, Tabs as TabsAntd } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'


import useExecutionDrawer from '../../../hooks/use-execution-drawer'
import { getExecution } from '../../../store/execution/selectors'
import { getProcedure } from '../../../store/procedure/selectors'
import { DRAWERS } from '../../../utils/constants/drawer'
import Translation from '../../translations'

import ConditionalsTab from './conditionals-tab'
import InfoTab from './info-tab'

const InfoExecutionDrawer = ({ onSelectStep }) => {
  const procedure = useSelector(getProcedure)
  const execution = useSelector(getExecution)

  const { currentDrawer, openDrawer, closeDrawer } = useExecutionDrawer()

  const visible = currentDrawer === DRAWERS.EXECUTION_INFO

  if (!execution || !procedure) {
    return null
  }

  return (
    <Drawer
      placement='bottom'
      closable={true}
      open={visible}
      height='85%'
      destroyOnClose
      onClose={closeDrawer}
      title={
        <ListTitle>
          <Translation id='executionInformation' />
        </ListTitle>
      }
    >
      <Tabs
        renderTabBar={(props, DefaultTabBar) => (
          <DefaultTabBar {...props} className={'sticky-tab-bar bg-gray'} />
        )}
        items={[
          {
            label: <Translation id='execution.info.tab.title' />,
            key: 'execution-info',
            children: (
              <InfoTab
                execution={execution}
                procedure={procedure}
                closeDrawer={closeDrawer}
                openDrawer={openDrawer}
                onSelectStep={onSelectStep}
              />
            )
          },
          {
            label: <Translation id='forcible.conditionals.tab.title' />,
            key: 'conditionals',
            children: (
              <ConditionalsTab
                closeDrawer={closeDrawer}
                onSelectStep={onSelectStep}
              />
            )
          }
        ]}
      />
    </Drawer>
  )
}

const Drawer = styled(AntDrawer)`
  .ant-drawer-header {
    padding: 18px 16px;
  }
  .ant-drawer-title {
    padding: 0;
  }
  .ant-drawer-close {
    padding: 18px 31px 0;
  }
  .ant-drawer-body {
    padding: 0 16px 18px;
  }
  .ant-drawer-header-title button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 18px;
  }
`

const ListTitle = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`

const Tabs = styled(TabsAntd)`
  height: 100%;

  .ant-tabs-tabpane {
    height: 100%;
  }
`

export default InfoExecutionDrawer
