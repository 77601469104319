import { getBlob } from '../../../../utils/helpers/files'
import { toBase64 } from '../../files'

export async function getEmbebedImageParagraph(commentMedia) {
  const attachmentNode = document.createElement('p')
  attachmentNode.style.border = '1px solid gray'
  attachmentNode.style.margin = '0px 10px 0px 10px'
  attachmentNode.style.padding = '5px'

  const url = getBlob(commentMedia)

  const embebedImage = await convertImageToBase64(url)
  const attachmentImage = document.createElement('img')
  attachmentImage.src = embebedImage
  attachmentImage.style.marginRight = '5px'
  attachmentImage.style.maxWidth = '100%'
  attachmentNode.append(attachmentImage)

  const attachmentName = document.createElement('div')
  attachmentName.textContent = commentMedia?.name
  attachmentName.style.textAlign = 'center'
  attachmentNode.append(attachmentName)
  return attachmentNode
}

export async function convertImageToBase64(url) {
  const data = await fetch(url)
  const blob = await data.blob()
  const imageAsBase64 = await toBase64(blob)

  return imageAsBase64
}

export function isAnImage(commentMedia) {
  const ext = commentMedia.name
    ?.substr(commentMedia.name?.lastIndexOf('.') + 1)
    .toLowerCase()
  if (ext.toLowerCase() === 'pdf') return false
  else return true
}

/**
 * Transform the images size attributes to inline styles
 * @param {Element | null} container Container which contains the nodes
 */
export function transformSizeAttrsToInlineStyles(container) {
  const images = container.querySelectorAll('img')

  images.forEach((image) => {
    const width = image.getAttribute('width')
    const height = image.getAttribute('height')

    if (width) {
      image.style.width = `${width}px`
    }

    if (height) {
      image.style.height = `${height}px`
    }
  })
}
