import React from 'react'

const BadgeHollow = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='6.5' cy='6.5' r='4.5' stroke={color} strokeWidth='3' />
  </svg>
)

export default BadgeHollow
