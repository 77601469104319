import { Drawer } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useGlobal } from 'reactn'
import styled from 'styled-components'


import { useGlobalExecutions } from '../../../hooks/data/useGlobalExecutions'
import { useLocalExecutions } from '../../../hooks/data/useLocalExecutions'
import usePagination from '../../../hooks/metadata/use-pagination'
import { useFilters } from '../../../hooks/useFilters'
import { getUser } from '../../../store/user-management/selectors'
import { getConnectionStatus } from '../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import { EXECUTION_STATUS } from '../../../utils/constants/execution'
import { COLUMN_TYPES } from '../../../utils/constants/table'
import { getElipsisText } from '../../../utils/helpers/strings'
import { getSelectPropertiesFromDataExecution } from '../../../utils/helpers/tables/executions'
import BasePage from '../../../views/base-page'
import BaseTable from '../../../views/tables/base-table'
import ExecutionStatusTag from '../../../views/tags/execution-status'
import UnitTag from '../../../views/tags/unit'
import Translation from '../../../views/translations'

import { DownloadExecutionButton } from './DownloadExecutionButton'
import { ExecutionFilterTags } from './ExecutionFilterTags'
import { Filters } from './Filters'

export const ExecutionsFiltersContext = React.createContext()

const columns = [
  {
    title: <Translation id='unit' />,
    dataIndex: 'procedure.unit',
    width: 70,
    render: (text) => <UnitTag unit={text} />
  },
  {
    title: <Translation id='series' />,
    dataIndex: 'procedure.serie.name',
    width: 70,
    sorter: true
  },
  {
    title: <Translation id='key' />,
    dataIndex: 'procedure.key',
    minWidth: 90,
    sorter: true
  },
  {
    title: <Translation id='title' />,
    dataIndex: 'procedure.title',
    minWidth: 100,
    sorter: true
  },
  {
    title: <Translation id='rev' />,
    dataIndex: 'procedure.rev',
    width: 50,
    sorter: true
  },
  {
    title: <Translation id='executionGoal' />,
    dataIndex: 'executionGoal.name',
    render: (text) => (
      <p style={{ minWidth: '120px' }}>{getElipsisText(text, 23)}</p>
    )
  },
  {
    title: <Translation id='lastMod' />,
    dataIndex: 'updatedAt',
    sorter: true,
    defaultSortOrder: 'descend',
    width: 70,
    type: COLUMN_TYPES.DATE
  },
  {
    title: <Translation id='startDate' />,
    dataIndex: 'createdAt',
    sorter: true,
    width: 70,
    type: COLUMN_TYPES.DATE
  },
  {
    title: <Translation id='owner' />,
    dataIndex: 'user.name',
    sorter: true,
    minWidth: 123
  },
  {
    title: <Translation id='status' />,
    dataIndex: 'status',
    width: 136,
    fixed: 'right',
    sorter: true,
    render: (text) => <ExecutionStatusTag status={text} />
  },
  {
    title: <Translation id='qa' />,
    dataIndex: 'qaStatus',
    sorter: true,
    fixed: 'right',
    minWidth: 40,
    render: (_, exec) => <ExecutionStatusTag qa status={exec.qaStatus} />
  }
]

const selectProperties = getSelectPropertiesFromDataExecution(columns, [
  'id',
  'procedure.id',
  'procedure.roleRevisionLevel',
  'procedure.roleApproveLevel',
  'procedure.simpleApprobation',
  'user.id'
])

export const GlobalExecutionsDrawer = ({ visible, setVisible }) => {
  const defaultSortColumn = columns.find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'updatedAt',
    order: defaultSortColumn?.defaultSortOrder || 'descend'
  })

  const currentUser = useSelector(getUser)
  const connectionStatus = useSelector(getConnectionStatus)
  const [pagination, setPagination] = usePagination()
  const { filters, defaultFilters, updateFilters, resetFilters } = useFilters({
    status: [
      EXECUTION_STATUS.INTERRUPTED,
      EXECUTION_STATUS.SHARED,
      EXECUTION_STATUS.PAUSED,
      EXECUTION_STATUS.EXECUTING
    ]
  })

  const [localExecutions, loadingLocalExecutions] = useLocalExecutions({
    disabled: !visible
  })

  const {
    data,
    isValidating,
    mutate: refetch
  } = useGlobalExecutions({
    select: selectProperties,
    skip: (pagination.current - 1) * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    ...filters,
    user: filters.user?.id
  })

  const { items, total } = data || {}
  const online = connectionStatus === CONNECTION_STATUS.ONLINE

  const setPaginationRef = useRef(setPagination)

  useEffect(() => {
    setPaginationRef.current({ current: 1, pageSize: 10 })
  }, [filters])

  useEffect(() => {
    if (!online) {
      setVisible(false)
    }
  }, [online, setVisible])

  // TODO: Este useEffect refresca la info pero no evita que se haga un fetch cuando se monta el componente
  // Este componente se monta cuando lo hace el padre, ocurre con todos los Drawer, habría que darle una vuelta
  // para mejorar el rendimiento
  useEffect(() => {
    if (visible) {
      refetch()
      resetFilters()
    }
  }, [visible, refetch, resetFilters])

  const handleTableChange = (pagination, _, sorter) => {
    setPagination({ ...pagination, total })
    setSorter({ ...sorter })
  }

  const actionColumn = {
    render: (_, execution) => (
      <DownloadExecutionButton
        selectedExecution={execution}
        localExecutions={localExecutions}
        loading={loadingLocalExecutions}
        currentUser={currentUser}
      />
    ),
    fixed: 'right',
    width: '1%'
  }

  return (
    <CustomDrawer
      placement='bottom'
      closable
      open={visible}
      onClose={() => {
        setVisible(false)
      }}
      height='85%'
    >
      <ExecutionsFiltersContext.Provider
        value={{ filters, defaultFilters, updateFilters, resetFilters }}
      >
        <BasePage
          title={<Translation id='executionsDownload' />}
          extraHeaderContent={<Filters />}
          subHeaderContent={
            Object.keys(filters).length > 0 ? (
              <ExecutionFilterTags />
            ) : undefined
          }
          totalElements={total}
        >
          <BaseTable
            id='global-executions-table'
            columns={[...columns, actionColumn]}
            dataSource={items}
            pagination={{ ...pagination, total }}
            loading={isValidating}
            onChange={handleTableChange}
          />
        </BasePage>
      </ExecutionsFiltersContext.Provider>
    </CustomDrawer>
  )
}

const CustomDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
  }

  .ant-drawer-header-title {
    position: absolute;
    top: 20px;
    right: 10px;
  }
`
