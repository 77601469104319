import React from 'react'

const Check = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.625 1.375L5.079 12.1545C4.87702 12.4447 4.54784 12.6202 4.1943 12.626C3.84076 12.6319 3.50596 12.4674 3.2945 12.184L1.375 9.625'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Check
