import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'

import ExtraHeaderContentButton from '../../../components/buttons/extra-header-content'
import Upload from '../../../components/icons/upload'
import ExtraHeaderContentMessage from '../../../components/message/extra-header-content'
import { ExecutionFilterTags } from '../../../screens/Dashboard/Admin/ExecutionManagement/ExecutionFilterTags'
import { PERMISSION_TYPES } from '../../../utils/constants/roles'
import { userHasPermission } from '../../../utils/helpers/permissions'
import Translation from '../../translations'

const ExecutionManagementSubheader = ({ filters, setModal }) => {
  const canUploadExecution = userHasPermission(
    PERMISSION_TYPES.EXECUTION_UPLOAD
  )
  return (
    <SubHeaderContainer>
      {Object.keys(filters)?.length > 0 ? <ExecutionFilterTags /> : undefined}
      <Tooltip
        title={
          !canUploadExecution ? (
            <Translation id='validationHasPermission' />
          ) : null
        }
      >
        <UploadExecutionContainer>
          <ExtraHeaderContentButton
            type='primary'
            id='upload-manual-execution-button'
            size='small'
            onClick={() => setModal(true)}
            disabled={!canUploadExecution}
          >
            <Upload />
          </ExtraHeaderContentButton>
          <ExtraHeaderContentMessage>
            <Translation id='uploadManualExecution' />
          </ExtraHeaderContentMessage>
        </UploadExecutionContainer>
      </Tooltip>
    </SubHeaderContainer>
  )
}

const SubHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const UploadExecutionContainer = styled.div`
  display: flex;
  align-items: center;
`

export default ExecutionManagementSubheader
