import { Drawer, Button } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'


import { downloadProcedure } from '../../../application/reports/download'
import { PERMISSION_TYPES } from '../../../utils/constants/roles'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { userHasPermission } from '../../../utils/helpers/permissions'
import DownloadNotificationContent from '../../../views/notifications/download'
import BaseTable from '../../../views/tables/base-table'
import UnitTag from '../../../views/tags/unit'
import Translation from '../../../views/translations'

const getColumns = () => {
  let columns = [
    {
      title: <Translation id='unit' />,
      dataIndex: 'unit',
      render: (text) => <UnitTag unit={text} />
    },
    {
      title: <Translation id='series' />,
      dataIndex: 'series',
      minWidth: 50,
      sorter: true
    },
    {
      title: <Translation id='key' />,
      dataIndex: 'key',
      minWidth: 50,
      sorter: true
    },
    {
      title: <Translation id='rev' />,
      dataIndex: 'rev',
      minWidth: 50,
      sorter: true
    },
    {
      title: <Translation id='title' />,
      dataIndex: 'title',
      sorter: true
    },
    {
      title: <Translation id='status' />,
      dataIndex: 'status',
      render: (text) => <Translation id={text} />
    }
  ]

  if (userHasPermission(PERMISSION_TYPES.PROCEDURE_VERSION)) {
    columns = [
      ...columns,
      {
        title: <Translation id='version' />,
        dataIndex: 'buildNumber',
        minWidth: 60,
        sorter: true
      }
    ]
  }

  return columns
}

export const ReferencedProceduresDrawer = ({
  visible,
  setVisible,
  selectedProcedure,
  referencedProcedures,
  setReferencedProcedures
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedReferencedProcedures, setSelectedReferencedProcedures] =
    useState([])

  const rowSelection = {
    hideSelectAll: false,
    selectedRowKeys: selectedReferencedProcedures.map((proc) => proc.id),
    onChange: (_, selectedItems) => {
      setSelectedReferencedProcedures(selectedItems)
    }
  }

  const columns = getColumns()

  return (
    <Drawer
      title={
        <TitleContainer>
          <ListTitle>
            <Translation
              id='downloadProceduresModalTitle'
              params={{ procedureName: selectedProcedure?.key }}
            />
          </ListTitle>
          <span>
            <CancelButton onClick={() => setVisible(false)}>
              <Translation id='cancel' />
            </CancelButton>
            <OkButton
              id='download-referenced-procedures-button'
              loading={isLoading}
              onClick={async () => {
                setIsLoading(true)
                setVisible(false)
                try {
                  const { procedure, referencedProcedures, failedFiles } =
                    await downloadProcedure(
                      selectedProcedure.id,
                      selectedReferencedProcedures.map((p) => p.id)
                    )
                  notificationMessage({
                    message: <Translation id='downloadComplete' />,
                    placement: 'topLeft',
                    description: (
                      <DownloadNotificationContent
                        procedure={procedure}
                        referencedProcedures={referencedProcedures}
                        failedFiles={failedFiles}
                      />
                    )
                  })
                } catch (error) {
                  notificationMessage({
                    type: 'error',
                    message: <Translation id='downloadFailed' />,
                    placement: 'topLeft',
                    description: (
                      <div>
                        {error.message}
                        <br />
                        {error.response?.data?.message}
                      </div>
                    )
                  })
                  throw error
                } finally {
                  setSelectedReferencedProcedures([])
                  setReferencedProcedures([])
                  setIsLoading(false)
                  setVisible(false)
                }
              }}
            >
              <Translation id='download' />
            </OkButton>
          </span>
        </TitleContainer>
      }
      placement='bottom'
      closable={false}
      open={visible}
      height='85%'
      onClose={() => {
        setReferencedProcedures([])
        setVisible(false)
      }}
    >
      <BaseTable
        id='global-referenced-procedures-table'
        loading={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={referencedProcedures}
      />
    </Drawer>
  )
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`

const ListTitle = styled.span`
  font-size: 17px;
  font-weight: bold;
`

const CancelButton = styled(Button)`
  border: none;
  border-radius: 2px;
  width: 108px;
  height: 40px;
  padding: 8px;
  background-color: #edece8;
  margin-right: 8px;
  color: #000000;
  font-size: 16px;
  border: 1px solid #b8bab6;

  &:active,
  &:focus {
    background-color: #b8bab6;
    color: #000;
    border: #b8bab6;
  }

  &:hover {
    background-color: #edece8;
    color: #000000;
    border-color: #b8bab6;
  }
`

const OkButton = styled(Button)`
  border: none;
  border-radius: 2px;
  width: 108px;
  height: 40px;
  padding: 8px;
  background-color: #645377;
  color: #ffffff;
  font-size: 16px;
  border: '1px solid #272928';

  &:active,
  &:focus {
    background-color: #b8bab6;
    color: #000000;
    border-color: #272928;
  }

  &:hover {
    background-color: #645377;
    color: #ffffff;
    border-color: #272928;
  }
`
