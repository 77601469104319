import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { useGlobal } from 'reactn'

import ControlPanelIcon from '../../components/icons/control-panel'
import ExecutionIcon from '../../components/icons/execution'
import ExecutionManagementIcon from '../../components/icons/execution-management'
import ProcedureIcon from '../../components/icons/procedure'
import ProcedureEditIcon from '../../components/icons/procedure-edit'
import ProcedureManagementIcon from '../../components/icons/procedure-management'
import UserWorkIcon from '../../components/icons/user-work'
import useRouteRedirect from '../../hooks/use-route-redirect'
import { getUser } from '../../store/user-management/selectors'
import { getConnectionStatus } from '../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../utils/constants/connection'
import { filterRoutesByPermissions } from '../../utils/helpers'
import RouteTab from '../../views/routes/tab'
import RouteTabs from '../../views/routes/tabs'
import Translation from '../../views/translations'
import ControlPanel from '../control-panel'
import { NotFound } from '../NotFound'

import { ExecutionHistory } from './Admin/ExecutionHistory'
import { ExecutionManagement } from './Admin/ExecutionManagement'
import { ProcedureManagement } from './Admin/ProcedureManagement'
import { DashboardMainView } from './DashboardMainView'
import { LocalExecutions } from './LocalExecutions'
import { LocalProcedures } from './LocalProcedures'

const PARENT_ROUTE = '/dashboard'

const routes = [
  {
    key: 'home',
    path: '',
    component: DashboardMainView,
    exact: true,
    showTab: true,
    textTranslationId: 'myWorkForToday',
    icon: UserWorkIcon,
    tabButtonId: 'tab-dashboard',
    onlineOnly: true
  },
  {
    key: 'procedure',
    path: '/procedures',
    component: LocalProcedures,
    exact: true,
    showTab: true,
    textTranslationId: 'proceduresOnDevice',
    icon: ProcedureIcon,
    tabButtonId: 'tab-procedures-button'
  },
  {
    key: 'execution',
    path: '/executions',
    component: LocalExecutions,
    exact: true,
    showTab: true,
    textTranslationId: 'executionsOnDevice',
    icon: ProcedureEditIcon,
    tabButtonId: 'tab-executions-button'
  },
  {
    key: 'execution-management',
    path: '/admin-executions',
    component: ExecutionManagement,
    showTab: true,
    textTranslationId: 'manageExecutions',
    icon: ExecutionManagementIcon,
    tabButtonId: 'tab-manage-executions-button',
    onlineOnly: true
  },
  {
    key: 'executionHistory',
    path: '/admin-execution-history',
    component: ExecutionHistory,
    showTab: true,
    textTranslationId: 'executionHistory',
    icon: ExecutionIcon,
    tabButtonId: 'tab-executions-history-button',
    onlineOnly: true
  },
  {
    key: 'adminProcedures',
    path: '/admin-procedures',
    component: ProcedureManagement,
    showTab: true,
    textTranslationId: 'manageProcedures',
    icon: ProcedureManagementIcon,
    tabButtonId: 'tab-manage-procedures-button',
    onlineOnly: true
  },
  {
    key: 'controlPanel',
    path: '/control-panel',
    component: ControlPanel,
    showTab: true,
    textTranslationId: 'controlPanel',
    icon: ControlPanelIcon,
    tabButtonId: 'tab-control-panel-button',
    onlineOnly: true
  },
  {
    key: 'dashboard-not-found',
    path: '*',
    component: NotFound,
    showTab: false,
    skipPermission: true
  }
]

export const Dashboard = () => {
  const { path } = useRouteMatch()
  const user = useSelector(getUser)
  const connectionStatus = useSelector(getConnectionStatus)
  const allowedRoutes = filterRoutesByPermissions(routes, user)

  const GRAY_COLOR = '#b4b4ae'
  const PURPLE_COLOR = '#645377'

  // Redirect if current route does not exist
  useRouteRedirect(routes, allowedRoutes, PARENT_ROUTE)

  return (
    <RouteTabs
      tabs={allowedRoutes
        .filter((p) => p.showTab)
        .map(
          ({
            key,
            path,
            exact,
            icon: Icon,
            textTranslationId,
            tabButtonId,
            onlineOnly
          }) => {
            const disabled =
              connectionStatus !== CONNECTION_STATUS.ONLINE && onlineOnly
            return (
              <RouteTab
                key={key}
                path={`${PARENT_ROUTE}${path}`}
                exact={exact}
                text={<Translation id={textTranslationId} />}
                icon={<Icon color={disabled ? GRAY_COLOR : PURPLE_COLOR} />}
                id={tabButtonId}
                disabled={disabled}
              />
            )
          }
        )}
    >
      <Switch>
        {allowedRoutes.map((page) => (
          <Route
            key={page.key}
            path={`${path}${page.path}`}
            exact={page.exact}
            render={(props) => <page.component {...props} />}
          />
        ))}
      </Switch>
    </RouteTabs>
  )
}
