import { Form, Modal, Input } from 'antd'
import React, { useState } from 'react'

import { useTranslation } from '../../../hooks/useTranslation'
import { info } from '../../../utils/logger'
import Translation from '../../translations'

const { TextArea } = Input

function CommentModal({
  visible,
  onConfirm,
  onCancel,
  status,
  observations,
  required
}) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const fieldRequiredTranslation = useTranslation('fieldRequired')

  async function handleOk() {
    setLoading(true)
    try {
      const values = await form.validateFields()
      onConfirm(status, values.comment)
    } catch (error) {
      info('Validation failed:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      closable={false}
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={loading}
      okText={<Translation id='okText' />}
      cancelText={<Translation id='cancel' />}
    >
      <Form
        form={form}
        name='comment'
        layout='vertical'
        initialValues={{ comment: observations }}
      >
        <Form.Item
          label={<Translation id='observations' />}
          name='comment'
          rules={[{ required, message: fieldRequiredTranslation }]}
        >
          <TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CommentModal
