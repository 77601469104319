import { List } from 'antd'
import React from 'react'

import ConfigSection from '../../../../containers/config-section'
import SectionTitle from '../../../../containers/section-title'
import useSeries from '../../../../hooks/use-series'
import Translation from '../../../translations'


const SeriesConfig = () => {
  const { data: series } = useSeries()
  return (
    <ConfigSection>
      <SectionTitle>
        <Translation id='config.series.title' />
      </SectionTitle>
      <List
        dataSource={series}
        renderItem={(item) => <List.Item>{item.name}</List.Item>}
        style={{ width: '100%' }}
        bordered
      />
    </ConfigSection>
  )
}

export default SeriesConfig
