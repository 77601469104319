import { setGeneratedUserCode } from '../../../services/user/codes/http/post'
import {
  getUserLocalStorageItem,
  setUserLocalStorageItem
} from '../../../utils/helpers/user'

export function getUserCode() {
  let codes = getUserLocalStorageItem('codes')

  let codeObject = codes.find((c) => !c.isGenerated)
  codeObject.isGenerated = true
  codes = codes.filter((c) => c.code !== codeObject.code && !c.isGenerated)

  setUserLocalStorageItem('codes', codes)
  setGeneratedUserCode(codeObject.code)

  let { code } = codeObject
  code = code.split('')
  code = `${code.slice(0, 3).join('')} - ${code.slice(3, 6).join('')} - ${code
    .slice(6, 9)
    .join('')}  ${code.slice(9, 10).join('')}`

  return { ...codeObject, code }
}
