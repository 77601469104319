import React from 'react'


import ColorTag from '../../../containers/color-tag'
import {
  EXECUTION_QA_STATUS_COLOR,
  EXECUTION_QA_STATUS_TEXT_COLOR,
  EXECUTION_STATUS_COLOR,
  EXECUTION_STATUS_TEXT_COLOR
} from '../../../utils/constants/styles'
import Translation from '../../translations'

const ExecutionStatusTag = ({ status, qa, ...props }) => {
  const colors = qa ? EXECUTION_QA_STATUS_COLOR : EXECUTION_STATUS_COLOR
  const textColors = qa
    ? EXECUTION_QA_STATUS_TEXT_COLOR
    : EXECUTION_STATUS_TEXT_COLOR

  return (
    <ColorTag color={colors[status]} textColor={textColors[status]} {...props}>
      <span id='status-text' style={{ fontWeight: 'bold' }}>
        <Translation id={status} />
      </span>
    </ColorTag>
  )
}

export default ExecutionStatusTag
