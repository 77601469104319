export function getIcon(srcImage) {
  const iconNode = document.createElement('img')
  iconNode.src = srcImage
  iconNode.style.marginLeft = '10px'
  iconNode.style.marginRight = '5px'
  iconNode.style.color = 'blue'
  iconNode.style.verticalAlign = 'middle'

  return iconNode
}

export function getBackCommentIcon(srcImage) {
  const iconNode = document.createElement('img')
  iconNode.src = srcImage
  iconNode.style.marginLeft = '10px'
  iconNode.style.marginRight = '5px'
  iconNode.style.color = 'blue'

  return iconNode
}
