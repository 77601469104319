import { createSelector } from '@reduxjs/toolkit'

export const getExecutionComments = createSelector(
  (state) => {
    return state?.executionComments
  },
  (executionComments) => {
    return executionComments?.comments?.filter((c) => !c.deletedAt)
  }
)
