import { Col, Form, Input, Row } from 'antd'
import React, { useContext, useEffect } from 'react'

import { PROCEDURE_STATUS } from '../../../utils/constants/procedure'
import Modal from '../../../views/modal'
import RadioGroup from '../../../views/radio-buttons/group'
import SelectGlobalSeries from '../../../views/select/global-series'
import SelectGlobalUnits from '../../../views/select/global-units'
import Translation from '../../../views/translations'

import { ProceduresFiltersContext } from '.'


const FIELDS_PER_ROW = 2
const COLUMSIZE = FIELDS_PER_ROW * 6

export const FiltersModal = ({ visible, setVisible }) => {
  const [form] = Form.useForm()

  const { filters, updateFilters, resetFilters } = useContext(
    ProceduresFiltersContext
  )

  // Actualiza los valores del formulario cuando cambian los filtros externamente
  // y solo cuando el modal está visible para asegurarnos de que el modal
  // ya está renderizado
  useEffect(() => {
    if (visible) {
      form.setFieldsValue(filters)
    }
  }, [visible, form, filters])

  return (
    <Modal
      destroyOnClose
      visible={visible}
      formId='global-procedures-filters-form'
      width={600}
      title={<Translation id='filters' />}
      okButtonText={<Translation id='applyFilters' />}
      cancelButtonText={<Translation id='clearFilters' />}
      cancellable
      onClose={() => {
        setVisible(false)
      }}
      onCancel={() => {
        setVisible(false)
        resetFilters()
      }}
    >
      <Form
        id='global-procedures-filters-form'
        name='global-procedures-filters-form'
        layout='vertical'
        form={form}
        onFinish={(filters) => {
          updateFilters(filters)
          setVisible(false)
        }}
        initialValues={filters}
      >
        <Form.Item name='status' label={<Translation id='status' />}>
          <RadioGroup
            multiple
            options={Object.values(PROCEDURE_STATUS).map((status) => ({
              label: <Translation id={status} />,
              key: status
            }))}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={COLUMSIZE}>
            <Form.Item name='unit' label={<Translation id='unit' />}>
              <SelectGlobalUnits />
            </Form.Item>
          </Col>
          <Col span={COLUMSIZE}>
            <Form.Item name='series' label={<Translation id='series' />}>
              <SelectGlobalSeries />
            </Form.Item>
          </Col>
          <Col span={COLUMSIZE}>
            <Form.Item name='key' label={<Translation id='key' />}>
              <Input id='global-procedures-filters-form-input-key' />
            </Form.Item>
          </Col>
          <Col span={COLUMSIZE}>
            <Form.Item name='title' label={<Translation id='title' />}>
              <Input id='global-procedures-filters-form-input-title' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
