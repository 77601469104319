import { EditOutlined } from '@ant-design/icons'
import { Form, Input, Modal, Button } from 'antd'
import React, { useEffect } from 'react'

import { getTranslation } from '../../i18n/getTranslation'
import Translation from '../translations'

const EditListItem = ({ itemTitle, itemName, open, closeModal, editItem }) => {
  const [form] = Form.useForm()

  const handleEditForm = async ({ itemName }) => {
    await editItem(itemName)
    closeModal()
  }

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        itemName
      })
    } else {
      form.resetFields()
    }
  }, [form, itemName, open])

  return (
    <Modal
      open={open}
      title={
        <>
          <Translation id='edit' /> <Translation id='itemTitle' />
        </>
      }
      onCancel={() => {
        closeModal()
      }}
      footer={null}
      closable
    >
      <Form form={form} onFinish={handleEditForm}>
        <Form.Item
          name='itemName'
          rules={[
            {
              required: true,
              message: <Translation id='fieldRequired' />
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Button key='submit' type='primary' htmlType='submit'>
          <EditOutlined />
          <Translation id='saveChanges' />
        </Button>
      </Form>
    </Modal>
  )
}

export default EditListItem
