import { getTranslation } from '../../../../i18n/getTranslation'
import { getBackCommentIcon, getIcon } from '../icons'

export function getLinkComment(comment, numberOfComment) {
  const link = document.createElement('a')
  link.className = 'linkToComment'
  link.title = getTranslation('goToComment')
  link.href = '#' + comment.id
  link.setAttribute('data-src', comment.id)
  const textContent = document.createTextNode(`(${numberOfComment})`)
  link.appendChild(textContent)
  return link
}

export function getLinkVideo(to, name, icon) {
  const attachmentNode = document.createElement('div')
  attachmentNode.className = 'video-attachment'

  const videoIconNode = getIcon(icon)
  videoIconNode.style.verticalAlign = 'text-top'
  attachmentNode.append(videoIconNode)

  const link = document.createElement('a')
  link.style.fontSize = '14px'
  link.style.textDecoration = 'none'
  link.title = to
  link.href = to
  link.innerHTML = name

  attachmentNode.append(link)

  return attachmentNode
}

export function getLinkCommentBack(whereToBack, commentBackImage) {
  const link = document.createElement('a')
  link.className = 'linkBack'
  link.title = getTranslation('backFromComment')
  link.href = whereToBack

  const iconNode = getBackCommentIcon(commentBackImage)
  link.appendChild(iconNode)

  return link
}
