import React, { useMemo } from 'react'

import SmallArrowLeft from '../../components/icons/small-arrow-left'
import SmallArrowRight from '../../components/icons/small-arrow-right'
import StepArrowContainer from '../../containers/step-arrow-container'
import StepHistoryContainer from '../../containers/step-history'
import StepHistoryActual from '../../containers/step-history-actual'
import { buildStepIndex } from '../../utils/helpers/steps'

const StepHistory = ({
  actualStepId,
  stepsIndex,
  goBack,
  goForward,
  goToCurrent,
  canGoBack,
  canGoForward,
  canGoToCurrent
}) => {
  const currentIndex = useMemo(
    () => buildStepIndex(stepsIndex, actualStepId),
    [stepsIndex, actualStepId]
  )

  return (
    <StepHistoryContainer data-testid='historic-steps-navigator'>
      <StepArrowContainer
        disabled={!canGoBack}
        onClick={goBack}
        data-testid='go-back'
      >
        <SmallArrowLeft size={16} color='white' />
      </StepArrowContainer>
      <StepHistoryActual
        disabled={!canGoToCurrent}
        onClick={goToCurrent}
        data-testid='go-current'
      >
        <span>{currentIndex}</span>
      </StepHistoryActual>
      <StepArrowContainer
        disabled={!canGoForward}
        onClick={goForward}
        data-testid='go-forward'
      >
        <SmallArrowRight size={16} color='white' />
      </StepArrowContainer>
    </StepHistoryContainer>
  )
}

export default StepHistory
