import React, { useState } from 'react'

import TextButton from '../../../components/buttons/text'
import Filter from '../../../components/icons/filter'
import Translation from '../../../views/translations'

import { FiltersModal } from './FiltersModal'


export const Filters = () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <TextButton
        id='global-procedures-table-filters-button'
        onClick={() => setModalVisible(true)}
        icon={<Filter size={20} color='#645377' />}
      >
        <Translation id='filters' />
      </TextButton>
      <FiltersModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  )
}
