import { DatePicker } from 'antd'
import React from 'react'

const { RangePicker } = DatePicker

const DateRangePicker = ({ ...props }) => {
  const { style, ...rest } = props
  return (
    <RangePicker
      {...rest}
      style={{ ...style, borderRadius: '2px', height: '40px' }}
    />
  )
}

export default DateRangePicker
