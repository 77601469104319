import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { downloadProcedure } from '../../../application/reports/download'
import Download from '../../../components/icons/download'
import { useLocalProcedures } from '../../../hooks/data/useLocalProcedures'
import { getReferencedProcedureKeys } from '../../../services/procedures/components/http/get'
import { requestProcedures } from '../../../services/procedures/http/get'
import {
  getConnectionStatus,
  getDownloadingProcedureIds
} from '../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import DownloadButton from '../../../views/buttons/download-button'
import CheckIconView from '../../../views/icons/check'
import DownloadNotificationContent from '../../../views/notifications/download'
import Translation from '../../../views/translations'

import { ReferencedProceduresDrawer } from './ReferencedProceduresDrawer'

const onDownloadProcedure = async (selectedProcedure) => {
  const { procedure, referencedProcedures, failedFiles } =
    await downloadProcedure(selectedProcedure.id)
  notificationMessage({
    message: <Translation id='downloadComplete' />,
    description: (
      <DownloadNotificationContent
        procedure={procedure}
        referencedProcedures={referencedProcedures}
        failedFiles={failedFiles}
      />
    )
  })
}

export const DownloadProcedureButton = ({ procedure }) => {
  const downloadingProcedureIds = useSelector(getDownloadingProcedureIds)
  const [localProcedures] = useLocalProcedures()
  const [referencedDrawerVisible, setReferencedDrawerVisible] = useState(false)
  const [referencedProcedures, setReferencedProcedures] = useState([])
  const connectionStatus = useSelector(getConnectionStatus)

  const online = connectionStatus === CONNECTION_STATUS.ONLINE
  const isLoading = downloadingProcedureIds.includes(procedure.id)
  const isDownloaded = localProcedures?.some((i) => i.id === procedure.id)

  useEffect(() => {
    if (!online) {
      setReferencedDrawerVisible(false)
    }
  }, [online])

  const onClick = async () => {
    try {
      // Sacamos las keys de los procedimientos referenciados
      let referencedKeys = await getReferencedProcedureKeys(procedure.id)

      if (referencedKeys?.length) {
        // Sacamos los metadatos de los procedimientos referenciados
        const { data: proceduresRes } = await requestProcedures({
          keys: referencedKeys,
          publishedVersionOnly: true
        })
        const proceduresData = proceduresRes?.items

        // Excluímos los procedimientos que ya están descargados
        const filteredProcedures = proceduresData?.filter(
          (i) => !localProcedures?.some((p) => p.id === i.id)
        )

        if (filteredProcedures.length) {
          setReferencedProcedures(filteredProcedures)
          setReferencedDrawerVisible(true)
        } else if (proceduresData.length < referencedKeys.length) {
          notificationMessage({
            type: 'warning',
            message: <Translation id='downloadReferencedProceduresFailed' />,
            description: (
              <Translation
                id='downloadReferencedProcedureIdFailed'
                params={{ procedureKey: referencedKeys }}
              />
            )
          })
          await onDownloadProcedure(procedure)
        } else {
          await onDownloadProcedure(procedure)
        }
      } else {
        await onDownloadProcedure(procedure)
      }
    } catch (error) {
      notificationMessage({
        type: 'error',
        message: <Translation id='downloadFailed' />,
        description: (
          <div>
            {error.message}
            <br />
            {error.response?.data?.message}
          </div>
        )
      })
      throw error
    }
  }

  return (
    <>
      <DownloadButton
        id={`download-${procedure.id}`}
        disabled={isDownloaded}
        loading={isLoading}
        icon={isDownloaded ? <CheckIconView /> : <Download />}
        onClick={onClick}
      />

      <ReferencedProceduresDrawer
        visible={referencedDrawerVisible}
        setVisible={setReferencedDrawerVisible}
        selectedProcedure={procedure}
        referencedProcedures={referencedProcedures}
        setReferencedProcedures={setReferencedProcedures}
      />
    </>
  )
}
