import React from 'react'

const Shield = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.50002 3.61596V8.69196C1.50004 10.2964 1.98623 11.8631 2.89453 13.1857C3.80282 14.5083 5.09057 15.5246 6.58802 16.1006L7.28202 16.3673C7.74418 16.5451 8.25586 16.5451 8.71802 16.3673L9.41202 16.1006C10.9095 15.5246 12.1972 14.5083 13.1055 13.1857C14.0138 11.8631 14.5 10.2964 14.5 8.69196V3.61596C14.5013 3.42494 14.4474 3.23763 14.3447 3.07655C14.242 2.91546 14.095 2.78747 13.9214 2.70796C12.0546 1.89315 10.0368 1.48173 8.00002 1.50062C5.96326 1.48173 3.94546 1.89315 2.07869 2.70796C1.90501 2.78747 1.758 2.91546 1.65534 3.07655C1.55268 3.23763 1.49874 3.42494 1.50002 3.61596V3.61596Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.02993 9.61826C7.06488 9.58326 7.10943 9.55942 7.15793 9.54975C7.20644 9.54008 7.25672 9.54501 7.30242 9.56392C7.34812 9.58283 7.38718 9.61488 7.41467 9.656C7.44215 9.69712 7.45682 9.74546 7.45682 9.79492C7.45682 9.84438 7.44215 9.89273 7.41467 9.93385C7.38718 9.97497 7.34812 10.007 7.30242 10.0259C7.25672 10.0448 7.20644 10.0498 7.15793 10.0401C7.10943 10.0304 7.06488 10.0066 7.02993 9.97159C7.00667 9.94842 6.98821 9.92089 6.97562 9.89057C6.96303 9.86026 6.95654 9.82775 6.95654 9.79492C6.95654 9.76209 6.96303 9.72959 6.97562 9.69927C6.98821 9.66895 7.00667 9.64142 7.02993 9.61826Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.72473 8.80761L12.1827 6.34894C12.2834 6.24826 12.3633 6.12874 12.4178 5.99719C12.4722 5.86565 12.5003 5.72466 12.5003 5.58228C12.5003 5.43989 12.4722 5.2989 12.4178 5.16736C12.3633 5.03582 12.2834 4.91629 12.1827 4.81561C12.0821 4.71493 11.9625 4.63507 11.831 4.58058C11.6994 4.52609 11.5584 4.49805 11.4161 4.49805C11.2737 4.49805 11.1327 4.52609 11.0011 4.58058C10.8696 4.63507 10.7501 4.71493 10.6494 4.81561L8.1934 7.27628C7.61452 7.04958 6.97525 7.02907 6.38303 7.2182C5.79081 7.40732 5.28175 7.79455 4.9414 8.31479C4.60105 8.83504 4.45017 9.45659 4.51412 10.075C4.57806 10.6934 4.85293 11.2709 5.29252 11.7105C5.73212 12.1501 6.30965 12.4249 6.92803 12.4889C7.54642 12.5528 8.16797 12.402 8.68822 12.0616C9.20846 11.7213 9.59569 11.2122 9.78481 10.62C9.97393 10.0278 9.95342 9.38849 9.72673 8.80961L9.72473 8.80761Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Shield
