import { Collapse as AntdCollapse } from 'antd'
import styled from 'styled-components'

export const { Panel } = AntdCollapse

const Collapse = styled(AntdCollapse)`
  width: 100%;

  .ant-collapse-expand-icon {
    line-height: 32px;
  }

  .ant-collapse-header-text {
    line-height: 32px;
  }
`

export default Collapse
