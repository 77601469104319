import { Empty } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Collapse, { Panel } from '../../../../containers/collapse'
import { getForcibleExecutionConditionals } from '../../../../store/execution-conditionals/selectors'
import Translation from '../../../translations'

import SelectConditionalForcedValue from './select'
import ConditionalStep from './step'

const ConditionalsTab = ({ closeDrawer, onSelectStep }) => {
  const conditionals = useSelector(getForcibleExecutionConditionals)
  return (
    <Container>
      <Text>
        <Translation id='forcible.conditionals.tab.text' />
      </Text>
      <Collapse>
        {conditionals?.map((conditional, index) => {
          return (
            <Panel
              header={
                <span>
                  <Translation id='conditional' /> {index + 1}
                </span>
              }
              extra={<SelectConditionalForcedValue conditional={conditional} />}
              key={conditional.id}
            >
              {!conditional.info.stepsIfTrue.length &&
                !conditional.info.stepsIfFalse.length && (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              {conditional.info.stepsIfTrue.length > 0 && (
                <StepsTypeTitle>
                  <Translation id='forcible.conditionals.step.type.title.positive' />
                </StepsTypeTitle>
              )}
              {conditional.info.stepsIfTrue.map((step) => (
                <ConditionalStep
                  key={step.id}
                  step={step}
                  closeDrawer={closeDrawer}
                  onSelectStep={onSelectStep}
                />
              ))}
              {conditional.info.stepsIfFalse.length > 0 && (
                <StepsTypeTitle>
                  <Translation id='forcible.conditionals.step.type.title.negative' />
                </StepsTypeTitle>
              )}
              {conditional.info.stepsIfFalse.map((step) => (
                <ConditionalStep
                  key={step.id}
                  step={step}
                  closeDrawer={closeDrawer}
                  onSelectStep={onSelectStep}
                />
              ))}
            </Panel>
          )
        })}
      </Collapse>
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  height: 100%;

  background: #fff;
`

const Text = styled.p`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  margin: 0;
`

const StepsTypeTitle = styled.p`
  color: #6f5b82;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;

  margin: 0;
`

export default ConditionalsTab
