import React from 'react'

import ConfigSection from '../../../containers/config-section'
import SectionTitle from '../../../containers/section-title'
import { useRoles } from '../../../hooks/use-roles'
import { getTranslation } from '../../../i18n/getTranslation'
import { postRole } from '../../../services/roles/http/post'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import PutRoleModal from '../../modal/put-role-modal'
import Translation from '../../translations'
import CreateButtonHeader from '../create-button-header'

import RolesConfigurationList from './roles-configuration-list'

const RolesConfiguration = () => {
  const { data: roles = [], mutate: refetch } = useRoles()

  const handleAddRole = async (role, closeModal) => {
    try {
      const { selectedPermissions, ...restRole } = role
      await postRole({
        role: restRole,
        roleScreenPermissions: selectedPermissions
      })
      notificationMessage({
        message: getTranslation('roleAddSuccessMsg')
      })
      if (closeModal) closeModal()
      await refetch()
    } catch (error) {
      notificationMessage('roleAddErrorMsg', error)
    }
  }

  return (
    <ConfigSection>
      <SectionTitle
        extra={
          <CreateButtonHeader
            Modal={PutRoleModal}
            buttonTextId={'addRole'}
            actionTranslation={'addRole'}
            handleCreate={(role, closeModal) => handleAddRole(role, closeModal)}
          />
        }
      >
        <Translation id='configurationPanelRoles' />
      </SectionTitle>
      <RolesConfigurationList roles={roles} refetch={refetch} />
    </ConfigSection>
  )
}

export default RolesConfiguration
