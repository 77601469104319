import { Typography } from 'antd'
import styled from 'styled-components'

const { Text } = Typography

const DarkText = styled(Text)`
  color: #645377 !important;
`

export default DarkText
