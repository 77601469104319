import { Button as AntButton } from 'antd'
import React from 'react'
import styled from 'styled-components'

function Button({ children, width, height, color, icon, loading, onClick }) {
  return (
    <CustomButton
      data-width={width}
      data-height={height}
      data-color={color}
      icon={icon ?? null}
      loading={loading}
      onClick={onClick}
    >
      {children}
    </CustomButton>
  )
}

const CustomButton = styled(AntButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;

  color: #fff;
  ${({ 'data-width': width }) => (width ? `width: ${width};` : 'width: 100%;')}
  ${({ 'data-height': height }) =>
    height ? `height: ${height};` : 'height: 32px;'}
  ${({ 'data-color': color }) =>
    color ? `background: ${color}px;` : 'background: #272928;'}
  border-radius: 2px;
`

export default Button
