import React from 'react'

import { IconContainer } from '../../../containers/icon'

const ConnectionIcon = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='atom/icon/conection/on-manual'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g id='Group' stroke={color} strokeWidth='2'>
        <path
          d='M19.5,19.875 C19.2928932,19.875 19.125,20.0428932 19.125,20.25 C19.125,20.4571068 19.2928932,20.625 19.5,20.625 C19.7071068,20.625 19.875,20.4571068 19.875,20.25 C19.875,20.0428932 19.7071068,19.875 19.5,19.875 L19.5,19.875'
          id='Path'
        ></path>
        <polyline
          id='Path'
          points='3.75 15.75 3.75 15.75 6.75 12.75'
        ></polyline>
        <polyline
          id='Path'
          transform='translate(8.250000, 14.250000) scale(-1, 1) translate(-8.250000, -14.250000) '
          points='6.75 15.75 6.75 15.75 9.75 12.75'
        ></polyline>
        <path
          d='M6.75,12.75 L6.75,18.75 C6.75,19.5784271 7.42157288,20.25 8.25,20.25 L12.75,20.25'
          id='Path'
        ></path>
        <path
          d='M12,3.75 C11.112454,3.75287053 10.2723133,4.15094767 9.708,4.836 C9.47836812,2.42151678 7.3723927,0.62604584 4.95198271,0.7812087 C2.53157272,0.936371561 0.672094656,2.98605195 0.752641294,5.41009244 C0.833187931,7.83413292 2.82462874,9.755854 5.25,9.75001334 L12,9.75001334 C13.6568542,9.75001334 15,8.40685425 15,6.75 C15,5.09314575 13.6568542,3.75 12,3.75 Z'
          id='Path'
        ></path>
        <polygon
          id='Path'
          points='15.75 11.25 23.25 11.25 23.25 23.25 15.75 23.25'
        ></polygon>
      </g>
    </g>
  </svg>
)

export default ConnectionIcon
