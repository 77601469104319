import React from 'react'

import Modal from '..'
import Translation from '../../translations'

function ConfirmModal({
  id,
  titleId,
  textId,
  textParams,
  okButtonTextId,
  disableOkButton = false,
  open,
  setOpen,
  onOk,
  cancelButtonTextId,
  onCancel,
  maskClosable = false,
  children = undefined
}) {
  return (
    <Modal
      id={id}
      title={<Translation id={titleId} />}
      okButtonText={<Translation id={okButtonTextId} />}
      disableOkButton={disableOkButton}
      cancelButtonText={
        cancelButtonTextId ? <Translation id={cancelButtonTextId} /> : undefined
      }
      onOk={onOk}
      closable={true}
      maskClosable={maskClosable}
      cancellable={true}
      onCancel={() => {
        onCancel ? onCancel() : setOpen(false)
      }}
      visible={open}
      setVisible={setOpen}
    >
      {children ? children : <Translation id={textId} params={textParams} />}
    </Modal>
  )
}

export default ConfirmModal
