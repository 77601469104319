import { Layout, Radio, Badge } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

const { Content } = Layout
const TABBUTTON = 'tab-button-'
const TABBADGE = 'tab-badge-'

function buttonStyle(selected) {
  if (!selected) {
    return
  }

  return {
    backgroundColor: '#645377',
    color: '#fff',
    fontWeight: 'bold'
  }
}

function buttonName(index) {
  switch (index) {
    case 0:
      return 'procedures'
    case 1:
      return 'documents'
    case 2:
      return 'jumps'
  }
  return 'unknown'
}
const Tabs = ({ tabs }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <TabsLayout className='h-100'>
      <TabsHeader>
        <TabsButtonGroup defaultValue={0}>
          {tabs.map((tab, index) => {
            return (
              <TabsButton
                id={`${TABBUTTON}${buttonName(index)}`}
                value={index}
                key={index}
                onClick={() => setSelectedTab(index)}
                style={buttonStyle(selectedTab === index)}
              >
                <WrapperContent>
                  <Badge
                    id={`${TABBADGE}${buttonName(index)}`}
                    key={`Badge${index}`}
                    count={tab.counter}
                    size='small'
                    offset={[10, 10]}
                    style={{
                      backgroundColor: '#FFFFFF',
                      color: '#000000',
                      boxShadow: '0 0 0 1px #000'
                    }}
                  />
                  {tab.buttonContent}
                </WrapperContent>
              </TabsButton>
            )
          })}
        </TabsButtonGroup>
      </TabsHeader>
      <Content className='h-100'>
        <TabScroll>{tabs[selectedTab].content}</TabScroll>
      </Content>
    </TabsLayout>
  )
}

const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row-reverse;
  justify-content: center;
`

const TabsLayout = styled(Layout)`
  background-color: #efeeeb;
`

const TabsButtonGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;
`

const TabsButton = styled(Radio.Button)`
  height: 40px;
  line-height: 40px;
  flex-basis: 100%;
  text-align: center;
`

const TabsHeader = styled.div`
  height: 40px;
  display: flex;
  margin-bottom: 32px;
`

const TabScroll = styled.div`
  height: 100%;
  overflow-y: auto;
`

export default Tabs
