import { Button, Popover, Tooltip } from 'antd'
import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'


import useExecutionIsSynced from '../../../../../../hooks/use-execution-is-synced'
import { unsubscribeReadout } from '../../../../../../services/execution-components/readout/websocket'
import { store } from '../../../../../../store'
import {
  interruptExecution,
  pauseExecution
} from '../../../../../../store/execution/actions'
import { getExecution } from '../../../../../../store/execution/selectors'
import { hasUploadFinished } from '../../../../../../store/media-uploading/selectors'
import { getProcedure } from '../../../../../../store/procedure/selectors'
import { getConnectionStatus, getIsWorking } from '../../../../../../store/userInterface/selectors'
import { setIsWorking } from '../../../../../../store/userInterface/slice'
import { VIEW_PAUSE_BUTTON } from '../../../../../../utils/constants/config'
import { CONNECTION_STATUS } from '../../../../../../utils/constants/connection'
import {
  EXECUTION_STATUS,
  EXECUTION_STATUS_CAN_CHANGE_TO
} from '../../../../../../utils/constants/execution'
import Translation from '../../../../../translations'

const PausePopover = ({ children }) => {
  const [visible, setVisible] = useState(false)

  const procedure = useSelector(getProcedure)
  const execution = useSelector(getExecution)
  const allUploadFinished = useSelector(hasUploadFinished)

  const connectionStatus = useSelector(getConnectionStatus)

  const isWorking = useSelector(getIsWorking)

  const isOnline = connectionStatus === CONNECTION_STATUS.ONLINE

  const executionIsSynced = useExecutionIsSynced(execution?.id)

  const tooltipTranslationId = isOnline
    ? executionIsSynced
      ? null
      : 'executionNoSync'
    : 'isOffline'

  const isPauseDisabled = useMemo(
    () =>
      !execution ||
      !EXECUTION_STATUS_CAN_CHANGE_TO[execution.status].includes(
        EXECUTION_STATUS.PAUSED || !allUploadFinished
      ) ||
      isWorking,
    [execution, allUploadFinished, isWorking]
  )

  const isInterruptDisabled = useMemo(
    () =>
      !isOnline ||
      !executionIsSynced ||
      !execution ||
      !EXECUTION_STATUS_CAN_CHANGE_TO[execution.status].includes(
        EXECUTION_STATUS.INTERRUPTED
      ) ||
      !allUploadFinished ||
      isWorking,
    [isOnline, executionIsSynced, execution, allUploadFinished, isWorking]
  )

  const pauseReadoutSubscription = () => {
    if (procedure.hasReadouts)
      unsubscribeReadout({
        procedureId: procedure.id,
        executionId: execution.id
      })
  }

  return (
    <Popover
      placement='bottom'
      open={visible}
      onOpenChange={(visible) => setVisible(visible)}
      content={
        <div>
          {VIEW_PAUSE_BUTTON && (
            <Tooltip
              placement='bottom'
              title={
                !allUploadFinished ? (
                  <Translation id='media.uploading.warning.title' />
                ) : null
              }
            >
              <Button
                id='pause-confirm-button'
                onClick={() => {
                  pauseReadoutSubscription()
                  store.dispatch(setIsWorking(true))
                  store.dispatch(pauseExecution()).then(() => {
                    store.dispatch(setIsWorking(false))
                    setVisible(false)
                  })
                }}
                disabled={isPauseDisabled}
                style={{ backgroundColor: '#F0C78A' }}
              >
                <Translation id='pause' />
              </Button>
            </Tooltip>
          )}

          <Tooltip
            placement='bottom'
            title={
              tooltipTranslationId ? (
                <Translation id={tooltipTranslationId} />
              ) : !allUploadFinished > 0 ? (
                <Translation id='media.uploading.warning.title' />
              ) : null
            }
          >
            <Button
              id='check-in-confirm-button'
              onClick={() => {
                pauseReadoutSubscription()
                store.dispatch(setIsWorking(true))
                store.dispatch(interruptExecution()).then(() => {
                  store.dispatch(setIsWorking(false))
                })
              }}
              disabled={isInterruptDisabled}
              style={{ marginLeft: '8px', backgroundColor: '#EFB410' }}
            >
              <Translation id='checkIn' />
            </Button>
          </Tooltip>
        </div>
      }
      trigger='click'
    >
      {children}
    </Popover>
  )
}

export default PausePopover
