import { Popover } from 'antd'
import React from 'react'

import PopoverButton from '../../buttons/popover'
import Translation from '../../translations'


const ConfirmPopover = ({
  confirmButtonProps,
  confirmButtonText,
  content,
  children,
  onConfirm,
  visible,
  onCancel = () => {},
  onVisibleChange
}) => {
  return (
    <div>
      <Popover
        placement='top'
        trigger='click'
        onOpenChange={onVisibleChange}
        open={visible}
        content={
          content || (
            <div>
              <PopoverButton
                id='confirm-signature-button'
                color='#29BC4F'
                onClick={() => {
                  onConfirm()
                }}
                {...confirmButtonProps}
              >
                {confirmButtonText || <Translation id='confirm' />}
              </PopoverButton>
              <PopoverButton
                id='cancel-signature-button'
                color='#EB4648'
                style={{ marginLeft: '8px' }}
                onClick={() => {
                  onCancel()
                }}
              >
                <Translation id='close' />
              </PopoverButton>
            </div>
          )
        }
      >
        {children}
      </Popover>
    </div>
  )
}

export default ConfirmPopover
