import { getDatabase } from '../..'
import { removeFileContent } from '../../../services/files'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { erro } from '../../../utils/logger'
import { deleteFilesContent } from '../../filesContent/delete'

const tableName = TABLE_NAMES.FILES
async function deleteFilesInfo(ids) {
  const db = getDatabase()

  return db[tableName].where('id').anyOf(ids).delete()
}

export async function deleteFilesAndContentLocally(fileIds) {
  if (!fileIds || fileIds.length === 0) return
  await deleteFilesInfo(fileIds)
  await deleteFilesContent(fileIds)
}

export async function deleteFilesLocallyAndRemote(fileIds) {
  try {
    deleteFilesAndContentLocally(fileIds)

    await Promise.all(fileIds.map((id) => removeFileContent(id)))
  } catch (e) {
    erro(`Cannot remove files ${fileIds}. Error: ${e.message}`)
  }
}

export const cleanFiles = async (executionsIds) => {
  const db = getDatabase()

  const executionComments = await db[TABLE_NAMES.EXECUTION_COMMENTS]
    .where('executionId')
    .anyOf(executionsIds)
    .toArray()

  const executionSteps = await db[TABLE_NAMES.EXECUTION_STEPS]
    .where('executionId')
    .anyOf(executionsIds)
    .toArray()

  const stepComments =
    executionSteps?.map((step) => step.comments)?.flat() || []

  const mediaIds = [...stepComments, ...executionComments]
    .map((comment) => comment?.mediaIds)
    .flat()
    .filter((id) => id)

  return deleteFilesAndContentLocally(mediaIds)
}
