import React from 'react'

import { ALLOWED_VIDEO_EXTENSIONS } from '../../../utils/constants/files'
import { getBlob } from '../../../utils/helpers/files'
import PdfViewer from '../../../views/pdf'
import Translation from '../../../views/translations'
import { BACKEND_URL } from '../../constants/config'

export function getAttachmentRepresentation(attachment, mediaRef) {
  const url = ALLOWED_VIDEO_EXTENSIONS.includes(attachment.extension)
    ? `${BACKEND_URL}/video/${attachment.filename}`
    : getBlob(attachment.file)

  if (ALLOWED_VIDEO_EXTENSIONS.includes(attachment?.extension?.toLowerCase())) {
    return (
      <video ref={mediaRef} width='100%' height='500px' src={url} controls>
        <Translation id='not.support.video.tag' />
      </video>
    )
  }

  switch (attachment?.extension?.toLowerCase()) {
    case '.jpg':
    case '.png':
    case '.jpeg': {
      return <img style={{ maxWidth: '100%', marginTop: '24px' }} src={url} />
    }

    case '.pdf': {
      return (
        <div style={{ height: 'calc(100vh - 250px)', width: '100%' }}>
          <PdfViewer file={url} />
        </div>
      )
    }
    default:
      return <></>
  }
}
