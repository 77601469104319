import { LoadingOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { downloadProcedure } from '../../../application/reports/download'
import CloseIcon from '../../../components/icons/close-icon'
import ConnectionIcon from '../../../components/icons/connection-icon'
import { useConnection } from '../../../hooks/useConnection'
import { disableConnection, enableConnection } from '../../../store/userInterface/actions'
import { getConnectionStatus, getIsWorking } from '../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import { alert } from '../../../utils/helpers/dialogs'
import Translation from '../../../views/translations'
import HeaderButton from '../../buttons/header-button'
import Modal from '../../modal'


const ConnectionIndicator = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const connectionStatus = useSelector(getConnectionStatus)
  const { hasToSyncData, updatedProceduresToDownload } = useConnection()
  const [
    downloadupdatedProceduresModalVisible,
    setDownloadupdatedProceduresModalVisible
  ] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isWorking = useSelector(getIsWorking)

  const disabled = connectionStatus === CONNECTION_STATUS.DISABLED

  useEffect(() => {
    if (connectionStatus === CONNECTION_STATUS.ONLINE)
      setDownloadupdatedProceduresModalVisible(
        updatedProceduresToDownload.length > 0
      )
    // Solo se debe ejecutar el useEffect cuando se vuelve a tener conexión
    // No se debe ejecutar cuando cambia el numero de procedimientos a descargar
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionStatus])

  useEffect(() => {
    const fn = async () => {
      if (hasToSyncData) {
        await alert({
          title: <Translation id='workNeedsSync' />
        })

        history.push({
          pathname: '/dashboard/executions'
        })
      }
    }

    fn()
  }, [hasToSyncData, history])

  return (
    <>
      <HeaderButton
        data-status={connectionStatus}
        badge={{
          count: badgeCount[connectionStatus]
        }}
        id='connection-indicator-button'
        onClick={() => {
          if (disabled) {
            dispatch(enableConnection())
          } else {
            dispatch(disableConnection())
          }
        }}
        loading={isWorking}
      >
        <ConnectionIcon disabled={disabled} />
      </HeaderButton>

      <Modal
        cancellable={true}
        visible={downloadupdatedProceduresModalVisible}
        onOk={async () => {
          //Descargar los nuevos procedimientos
          setIsLoading(true)
          Promise.all(
            updatedProceduresToDownload.map((p) => downloadProcedure(p.id))
          ).finally(() => {
            setIsLoading(false)
            setDownloadupdatedProceduresModalVisible(false)
          })
        }}
        onCancel={() => {
          setDownloadupdatedProceduresModalVisible(false)
        }}
        loading={isLoading}
      >
        <p>
          <Translation id='recentProcedures' />
        </p>
      </Modal>
    </>
  )
}

const Circle = styled.div`
  background-color: #ffffff;
  color: #000000;
  width: 16px;
  height: 16px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99999px;
  font-size: 10px;
`

const RedCircle = styled(Circle)`
  background-color: #ffffff;
  color: #ff4d4f;
`

const badgeCount = {
  [CONNECTION_STATUS.ONLINE]: 0,
  [CONNECTION_STATUS.OFFLINE]: (
    <RedCircle>
      <CloseIcon size={10} color='#ff4d4f' />
    </RedCircle>
  ),
  [CONNECTION_STATUS.CONNECTING]: (
    <RedCircle>
      <LoadingOutlined />
    </RedCircle>
  ),
  [CONNECTION_STATUS.DISABLED]: 0
}

export default ConnectionIndicator
