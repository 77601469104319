import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Route,
  Switch,
  useRouteMatch,
  Link,
  useLocation
} from 'react-router-dom'

import useRouteRedirect from '../../hooks/use-route-redirect'
import { getUser } from '../../store/user-management/selectors'
import { getConnectionStatus } from '../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../utils/constants/connection'
import Configuration from '../../views/configuration'
import Translation from '../../views/translations'
import { DownloadedProcedureManagement } from '../Admin/DownloadedProcedureManagement'
import { UserManagement } from '../Admin/UserManagement'
import { NoConnection } from '../NoConnection'

const PARENT_ROUTE = '/dashboard/control-panel'

const configurationPages = [
  {
    key: 'configuration-panel-client-settings',
    label: <Translation id='configurationPanel' />,
    path: '/configuration',
    component: Configuration
  },
  {
    key: 'downloaded-procedure-management',
    label: <Translation id='downloadedProcedures' />,
    path: '/downloaded-procedures',
    component: DownloadedProcedureManagement
  },
  {
    key: 'users-administration',
    label: <Translation id='configurationPanelUsers' />,
    path: '/users-administration',
    component: UserManagement
  },
  {
    key: 'configuration-panel',
    path: '',
    skipPermission: true,
    skipMenu: true
  }
]

const ControlPanel = () => {
  const { role } = useSelector(getUser)
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const [currentKey, setCurrent] = useState(
    'configuration-panel-client-settings'
  )
  const connectionStatus = useSelector(getConnectionStatus)

  const menuItems = configurationPages
    .filter(
      (_item) =>
        !_item.skipMenu && (_item.key !== 'users-administration' || role.admin)
    )
    .map((configPage) => ({
      label: <Link to={`${path}${configPage.path}`}>{configPage.label}</Link>,
      key: configPage.key
    }))

  useRouteRedirect(configurationPages, configurationPages, PARENT_ROUTE)

  useEffect(() => {
    if (menuItems?.length) {
      const currentRoute = configurationPages.find(
        (_route) => `${PARENT_ROUTE}${_route.path}` === pathname
      )
      const currentItem = menuItems.find(
        (_item) => currentRoute.key === _item.key
      )
      setCurrent(currentItem?.key ?? menuItems[0].key)
    }
  }, [menuItems, pathname])

  return (
    <>
      <Menu
        mode='horizontal'
        defaultSelectedKeys={['configuration-panel-client-settings']}
        selectedKeys={[currentKey]}
        onClick={(e) => {
          setCurrent(e.key)
        }}
        items={menuItems}
      />
      <Switch>
        {configurationPages.map((page) => (
          <Route
            key={page.key}
            exact
            path={`${path}${page.path}`}
            render={(props) =>
              connectionStatus === CONNECTION_STATUS.DISABLED ||
              connectionStatus === CONNECTION_STATUS.OFFLINE ? (
                <NoConnection />
              ) : (
                page.component && <page.component {...props} />
              )
            }
          />
        ))}
      </Switch>
    </>
  )
}

export default ControlPanel
