import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { executionChildrenSyncQuery } from '../../common/sync'

const tableName = TABLE_NAMES.EXECUTION_CONDITIONALS

export const executionConditionalsSyncQuery = async () => {
  return executionChildrenSyncQuery(tableName)
}

export async function readExecutionConditionals(executionId) {
  const db = getDatabase()

  const executionConditionals = await db[tableName]
    .where({
      executionId
    })
    .toArray()

  return executionConditionals
}
