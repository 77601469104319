import { Button, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGlobal } from 'reactn'
import styled from 'styled-components'

import { isAvailableCode } from '../../../services/user/codes/http/get'
import { setUsedUserCode } from '../../../services/user/codes/http/post'
import { getIsReview } from '../../../store/execution/selectors'
import { getExecutionComponents } from '../../../store/executionComponents/selectors'
import { getUsers } from '../../../store/user-management/selectors'
import { getConnectionStatus } from '../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'
import { validateUserCode } from '../../../utils/helpers/user/codes'
import { erro } from '../../../utils/logger'
import Translation from '../../translations'

import Character from './character'

const UserSignatureCodeModal = ({
  visible,
  onOk,
  onCancel,
  signatureRoleLevel,
  signatureId
}) => {
  const DIGITS_LENGHT = 10
  const digitsPositions = [...Array(DIGITS_LENGHT).keys()]

  const [supervisor, setSupervisor] = useState(null)
  const [supervisorError, setSupervisorError] = useState(false)
  const [digits, setDigits] = useState([])
  const [error, setError] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState(null)
  const connectionStatus = useSelector(getConnectionStatus)
  const isOnline = connectionStatus === CONNECTION_STATUS.ONLINE
  const executionComponents = useSelector(getExecutionComponents)
  const isReview = useSelector(getIsReview)
  const users = useSelector(getUsers)

  useEffect(() => {
    /**
     * Si desde pro-edit no viene roleLevel definido,
     * se considera que todos los usuarios pueden firmar (anteriormente a nivel de guest)
     */
    const selectorUsers = users?.filter(
      (u) =>
        !signatureRoleLevel || u.role?.roleLevel.level <= signatureRoleLevel
    )
    setFilteredUsers(selectorUsers)
  }, [users, signatureRoleLevel])

  async function validateCode(code, userId) {
    if (!validateUserCode(code)) return false

    const signedSignatureCodes = executionComponents.filter(
      (c) => c.value?.code
    )

    if (
      signedSignatureCodes.some(
        (c) => c.value.code.toUpperCase() === code.toUpperCase()
      )
    )
      return false

    return isOnline ? isAvailableCode(code, userId) : true
  }

  function validation() {
    const code = digits.join('')
    validateCode(code, supervisor)
      .then((isCodeValid) => {
        if (isCodeValid && supervisor) {
          setSupervisorError(false)
          setError(false)
          setDigits([])
          const selectedUser = filteredUsers?.find(
            (user) => user.id === supervisor
          )

          if (isOnline && isReview) {
            setUsedUserCode(code, supervisor, signatureId)
              .then(() => {
                onOk(
                  supervisor,
                  code.toUpperCase(),
                  selectedUser?.email,
                  selectedUser?.initials
                )
              })
              .catch((e) => {
                erro('validation:', e.message)
                setError(true)
                setSupervisorError(!supervisor)
                return
              })
          } else {
            onOk(
              supervisor,
              code.toUpperCase(),
              selectedUser?.email,
              selectedUser?.initials
            )
          }
        } else {
          setError(!isCodeValid)
          setSupervisorError(!supervisor)
          return
        }
      })
      .catch((e) => {
        erro('validation:', e.message)
        setError(true)
        setSupervisorError(!supervisor)
        return
      })
  }

  function onCancelClick() {
    setSupervisor(null)
    setDigits([])

    setSupervisorError(false)
    setError(false)

    onCancel()
  }

  function onClearAllClick() {
    setDigits([])
  }

  return (
    <CodeModal
      bodyStyle={{
        backgroundColor: '#efeeeb',
        borderRadius: '0px',
        paddingBottom: '0px !important'
      }}
      closable={false}
      title={
        <span style={{ fontSize: '22px' }}>
          <Translation id='seniorRoleRequired' />
        </span>
      }
      open={visible}
      destroyOnClose
      footer={[
        <ModalButton
          key='cancel'
          data-isokbutton={false}
          onClick={onCancelClick}
        >
          <Translation id='cancel' />
        </ModalButton>,
        <ModalButton key='confirm' data-isokbutton={true} onClick={validation}>
          <Translation id='confirm' />
        </ModalButton>
      ]}
    >
      <p>{<Translation id='chooseSeniorRole' />}</p>
      <SeniorRoleSelector
        id='role-selector'
        placeholder={<Translation id='chooseSeniorRolePlaceholder' />}
        showSearch
        onChange={(value) => {
          setSupervisor(value)
          setSupervisorError(false)
        }}
        value={supervisor}
        loading={!filteredUsers}
        data-error={supervisorError}
        options={filteredUsers?.map((user) => ({
          label: user.name,
          value: user.id,
          id: `op-${user.name}`
        }))}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
      <p>{<Translation id='enterSignatureCode' />}</p>
      <CodeWrapper>
        {digitsPositions.map((i) => (
          <Character
            id={`code-${i}`}
            key={i}
            name={`character_${i}`}
            value={digits[i]}
            onChangeValue={(value) => {
              if (error) {
                setError(false)
              }
              setDigits((digits) => {
                digits[i] = value
                return [...digits]
              })

              // AutoTab
              if (i < DIGITS_LENGHT) {
                const nextSibling = document.querySelector(
                  `input[name=character_${i + 1}]`
                )
                if (nextSibling) {
                  nextSibling.focus()
                }
              }
            }}
            error={error}
          />
        ))}
      </CodeWrapper>
      <CleanButton
        key='clear'
        data-isanynumberfill={digits.length > 0}
        onClick={onClearAllClick}
      >
        <Translation id='clearAll' />
      </CleanButton>

      <CodeError data-error={error}>
        <Translation id='codeInvalid' />
      </CodeError>
    </CodeModal>
  )
}

const CodeModal = styled(Modal)`
  min-width: 650px;
  .ant-modal-header,
  .ant-modal-footer {
    background-color: #efeeeb;
    border-radius: 0px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding: 0 0 20px;
  }
`

const SeniorRoleSelector = styled(Select)`
  width: 100%;
  margin-bottom: 20px;
  border: 2px solid
    ${({ 'data-error': error }) => (error ? '#F10808' : 'transparent')};
`

const CodeWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
`

const CodeError = styled.p`
  color: ${({ 'data-error': error }) => (error ? '#F10808' : 'transparent')};
  margin: 0;
`

const ModalButton = styled(Button)`
  border-radius: 2px;
  padding: 10px 82px;
  height: 40px;
  background-color: ${({ 'data-isokbutton': isOkButton }) =>
    isOkButton ? '#272928' : 'transparent'};
  color: ${({ 'data-isokbutton': isOkButton }) =>
    isOkButton ? '#fff' : '#000'};
  margin-right: ${({ 'data-isokbutton': isOkButton }) =>
    isOkButton ? '12px' : '2px'};

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ 'data-isokbutton': isOkButton }) =>
      isOkButton ? '#272928' : 'transparent'};
    color: ${({ 'data-isokbutton': isOkButton }) =>
      isOkButton ? '#fff' : '#000'};
  }
`

const CleanButton = styled(Button)`
  border-radius: 2px;
  padding: 10px 82px;
  height: 40px;
  background-color: ${({ 'data-isanynumberfill': isAnyNumberFill }) =>
    isAnyNumberFill ? '#272928' : 'transparent'};
  color: ${({ 'data-isanynumberfill': isAnyNumberFill }) =>
    isAnyNumberFill ? '#fff' : '#000'};
  margin-left: 200px;
  &:hover,
  &:active,
  &:focus {
    background-color: ${({ 'data-isanynumberfill': isAnyNumberFill }) =>
      isAnyNumberFill ? '#272928' : 'transparent'};
    color: ${({ 'data-isanynumberfill': isAnyNumberFill }) =>
      isAnyNumberFill ? '#fff' : '#000'};
  }
`

export default UserSignatureCodeModal
