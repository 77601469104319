/**
 * This function checks wether the different routes from the application are accesible
 * depending on the permissions. Some screens depend on action permissions
 * not only on screen permissions.
 * @param {*} routes - Application routes
 * @param {*} user -
 * @returns
 */

export const filterRoutesByPermissions = (routes, user) =>
  routes.filter(
    (r) =>
      user?.role?.roleScreenPermissions.some(
        (roleScreenPermission) =>
          r.key === roleScreenPermission.screenPermission.name ||
          roleScreenPermission.roleActionPermissions?.some(
            (aP) => aP.actionPermission.name === r.key
          )
      ) ||
      r.skipPermission ||
      !!user?.role?.admin
  )
