import React from 'react'

const People = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 20 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.8125 4.5625C2.8125 5.47418 3.17466 6.34852 3.81932 6.99318C4.46398 7.63784 5.33832 8 6.25 8C7.16168 8 8.03602 7.63784 8.68068 6.99318C9.32534 6.34852 9.6875 5.47418 9.6875 4.5625C9.6875 3.65082 9.32534 2.77648 8.68068 2.13182C8.03602 1.48716 7.16168 1.125 6.25 1.125C5.33832 1.125 4.46398 1.48716 3.81932 2.13182C3.17466 2.77648 2.8125 3.65082 2.8125 4.5625V4.5625Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.625 14.875C0.625 13.3832 1.21763 11.9524 2.27252 10.8975C3.32742 9.84263 4.75816 9.25 6.25 9.25C7.74184 9.25 9.17258 9.84263 10.2275 10.8975C11.2824 11.9524 11.875 13.3832 11.875 14.875'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.96 6.4375C11.96 7.18342 12.2563 7.89879 12.7837 8.42624C13.3112 8.95368 14.0265 9.25 14.7725 9.25C15.5184 9.25 16.2338 8.95368 16.7612 8.42624C17.2886 7.89879 17.585 7.18342 17.585 6.4375C17.585 5.69158 17.2886 4.97621 16.7612 4.44876C16.2338 3.92132 15.5184 3.625 14.7725 3.625C14.0265 3.625 13.3112 3.92132 12.7837 4.44876C12.2563 4.97621 11.96 5.69158 11.96 6.4375V6.4375Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.1777 10.5567C13.8736 10.3002 14.6212 10.2156 15.3568 10.3101C16.0924 10.4045 16.7944 10.6753 17.4029 11.0993C18.0114 11.5233 18.5085 12.088 18.8519 12.7454C19.1953 13.4028 19.3748 14.1333 19.3752 14.875'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default People
