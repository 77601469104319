import React from 'react'
import { useSelector } from 'react-redux'

import Pause from '../../../../../../components/icons/pause'
import { getExecution } from '../../../../../../store/execution/selectors'
import { EXECUTION_STATUS } from '../../../../../../utils/constants/execution'
import { ActionButton } from '../../button'
import PausePopover from '../popover'

const PauseButton = () => {
  const execution = useSelector(getExecution)

  return (
    <PausePopover execution={execution}>
      <ActionButton
        id='pause-execution-button'
        icon={Pause}
        activeStatus={execution?.status}
        statuses={[EXECUTION_STATUS.PAUSED, EXECUTION_STATUS.INTERRUPTED]}
      />
    </PausePopover>
  )
}

export default PauseButton
