import { getDeviceProcedures } from '../../../localdb/device/read'
import { sendDeviceProcedures } from '../../../services/devices/http/put'
import { getDeviceId } from '../../../utils/helpers/device'

export async function syncDeviceProcedures() {
  const deviceId = getDeviceId()
  if (!deviceId) return

  const deviceProcedures = await getDeviceProcedures()

  await sendDeviceProcedures(deviceProcedures, deviceId)
}
