import PubSub from 'pubsub-js'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { readPlacekeepingHistory } from '../../localdb/placekeeping/read'
import { writePlacekeepingHistory } from '../../localdb/placekeeping/write'
import {
  getExecutionId,
  getIsExecution,
  getIsShared
} from '../../store/execution/selectors'
import { EXECUTION_EVENTS } from '../../utils/constants/execution'
import { sortByKey } from '../../utils/helpers/sort'

function usePlacekeepingHistory({ onSelectStep }) {
  const [stagedHistory, setStagedHistory] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  const isExecution = useSelector(getIsExecution)
  const executionId = useSelector(getExecutionId)
  const isShared = useSelector(getIsShared)

  useEffect(() => {
    async function getPlacekeepingHistory() {
      if (!executionId || isShared) return
      const result = await readPlacekeepingHistory(executionId)

      if (result && result.length > 0) {
        const sorted = sortByKey(result, 'createdAt')
        setStagedHistory(sorted)
        setCurrentIndex(result.length - 1)
      }
    }

    getPlacekeepingHistory()
  }, [executionId, isShared])

  useEffect(() => {
    const id = PubSub.subscribe(
      EXECUTION_EVENTS.CHANGE_CURRENT_STEP,
      (_, { step }) => {
        if (step) {
          const newStep = {
            id: uuidv4(),
            executionId: executionId,
            stepId: step,
            createdAt: new Date()
          }
          setStagedHistory((previousStagedHistory) => {
            setCurrentIndex(previousStagedHistory.length)
            return [...previousStagedHistory, newStep]
          })

          if (isExecution && !isShared) writePlacekeepingHistory([newStep])
        }
      }
    )

    return () => PubSub.unsubscribe(id)
  }, [executionId, isExecution, isShared])

  const goBack = () => {
    if (currentIndex === 0) return

    const step = stagedHistory[currentIndex - 1]
    if (!step) return

    onSelectStep([step.stepId])
    setCurrentIndex(currentIndex - 1)
  }

  const goForward = () => {
    if (currentIndex === stagedHistory.length - 1) return

    const step = stagedHistory[currentIndex + 1]
    if (!step) return

    onSelectStep([step.stepId])
    setCurrentIndex(currentIndex + 1)
  }

  const goToCurrent = () => {
    const step = stagedHistory.at(-1)
    if (!step) return

    onSelectStep([step.stepId])
    setCurrentIndex(stagedHistory.length - 1)
  }

  return {
    goBack,
    goForward,
    goToCurrent,
    canGoBack: stagedHistory.length > 1 && currentIndex > 0,
    canGoForward:
      stagedHistory.length > 1 && currentIndex < stagedHistory.length - 1
  }
}

export default usePlacekeepingHistory
