import { Menu } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Conversation from '../../../../../components/icons/conversation'
import Translation from '../../../../translations'

const StepAnnotations = ({ annotations }) => {
  return (
    <>
      <MenuTitle>
        <TitleWrapper>
          <Icon />
          <Title>
            <Translation id='annotations' />
          </Title>
        </TitleWrapper>
      </MenuTitle>
      <Menu
        id='panel-step-annotations'
        className='drawer-comments'
        items={annotations?.map((item, i) => ({
          key: i,
          label: (
            <ItemText onClick={item.handleClick}>
              {item.stepIndex} {item.title}
            </ItemText>
          )
        }))}
      />
    </>
  )
}

const MenuTitle = styled.div`
  background-color: #4b98c6;
  color: #000 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  &:hover {
    cursor: text;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled(Conversation)`
  margin-right: 12px;
`

const Title = styled.span`
  font-size: 19px;
  font-weight: bold;
`

const ItemText = styled.a`
  width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export default StepAnnotations
