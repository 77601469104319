import {
  EXECUTION_QA_ACTION,
  EXECUTION_QA_STATUS,
  EXECUTION_STATUS
} from '../../../../utils/constants/execution'

export const REJECT_ACTION_BASED_ON_STATUS = {
  [EXECUTION_STATUS.FINISHED]: EXECUTION_QA_ACTION.REVIEW,
  [EXECUTION_QA_STATUS.REJECTED]: EXECUTION_QA_ACTION.REVIEW,
  [EXECUTION_QA_STATUS.REVIEWED]: EXECUTION_QA_ACTION.APPROVE
}

export const REJECT_COMMENT_TYPE = {
  [EXECUTION_QA_ACTION.APPROVE]: 'approvalComment',
  [EXECUTION_QA_ACTION.REVIEW]: 'revisionComment'
}
