import { getDatabase } from '../..'
import { deleteExecutionComments } from '../../../store/executionComments/localDb'
import { deleteExecutionManeuvers } from '../../../store/executionManeuvers/localDb'
import { deleteExecutionWarnings } from '../../../store/executionWarnings/localDb'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { deleteExecutionComponents } from '../../execution-component/delete'
import { deleteExecutionConditionals } from '../../execution-conditionals/delete'
import { deleteExecutionSteps } from '../../execution-step/delete'
import { deleteExecutionSyncStatus } from '../../execution-sync-status/delete'
import { cleanFiles } from '../../files/delete'
import { cleanFilesContent } from '../../filesContent/delete'
import { deletePlacekeepingHistory } from '../../placekeeping/delete/index'

const tableName = TABLE_NAMES.EXECUTIONS

export async function deleteExecution(executionId) {
  const db = getDatabase()

  await cleanFiles([executionId])
  await cleanFilesContent([executionId])
  await deleteExecutionSyncStatus([executionId])
  await deleteExecutionComments([executionId])
  await deleteExecutionComponents([executionId])
  await deleteExecutionSteps([executionId])
  await deleteExecutionManeuvers([executionId])
  await deleteExecutionWarnings([executionId])
  await deleteExecutionConditionals([executionId])
  await deletePlacekeepingHistory([executionId])

  return db[tableName].where('id').anyOf([executionId]).delete()
}
