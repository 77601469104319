import { createSlice } from '@reduxjs/toolkit'

import { addToLocaldbQueue, deleteFromLocaldbQueue } from '../helpers'

export const executionWarningsSlice = createSlice({
  name: 'executionWarnings',
  initialState: {
    warnings: null,
    lastSave: null,
    localdbQueue: []
  },
  reducers: {
    updateLocaldbQueue: (state, action) => {
      const { warning, resetQueue } = action.payload

      if (resetQueue) {
        state.localdbQueue = []
      }

      if (Array.isArray(warning)) {
        warning.forEach((w) => addToLocaldbQueue(state, w))
        return
      }

      addToLocaldbQueue(state, warning)
    },
    setWarningRead: (state, action) => {
      const { id, isRead, now } = action.payload

      const warning = state.warnings.find((w) => w.id === id)

      warning.isRead = isRead
      warning.updatedAt = now
    },
    setExecutionWarnings: (state, action) => {
      state.warnings = action.payload
      state.lastSave = action.payload
        ? new Date(Date.now() + 1000).toString()
        : null
    },
    setLastSave: (state, action) => {
      state.lastSave = action.payload || new Date().valueOf()
    },
    setSyncedAt: (state, action) => {
      const { entities, syncedAt } = action.payload

      state.warnings
        ?.filter((w) => entities.some((e) => e.id === w.id))
        .forEach((w) => {
          w.syncedAt = syncedAt
        })
    },
    removeLocaldbQueue: (state, action) => {
      const { entitiesToDelete } = action.payload
      deleteFromLocaldbQueue(state, entitiesToDelete)
    }
  }
})

export const {
  setWarningRead,
  setExecutionWarnings,
  setLastSave,
  setSyncedAt,
  updateLocaldbQueue,
  removeLocaldbQueue
} = executionWarningsSlice.actions

export default executionWarningsSlice.reducer
