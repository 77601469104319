import React from 'react'

import Translation from '../../views/translations'

const LegalPolicies = () => {
  return (
    <div>
      <h1>
        <Translation id={'legalPolicies'} />
      </h1>
      <div>
        <h2>
          <Translation id={'legalPoliciesIdentity'} />
        </h2>
        <p>
          <Translation id={'legalPoliciesIdentityText'} />{' '}
          <a href='http://www.tecnatom.es/'>http://www.tecnatom.es/</a>
        </p>
        <h2>
          <Translation id={'legalPoliciesConditions'} />
        </h2>
        <p>
          <Translation id={'legalPoliciesConditionsText'} />
        </p>
      </div>
    </div>
  )
}

export default LegalPolicies
