import { Button } from 'antd'
import styled from 'styled-components'

export const StepMenuButton = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color || `#edece8`};
  border-radius: 2px;
  width: 40px;
  height: 40px;

  border: ${(props) =>
    props['data-historical'] ? '1px solid #786E82' : 'none'};

  &:hover,
  &:active {
    background-color: #edece8;
  }
`

export default StepMenuButton
