import React from 'react'

import HistoricalIcon from '../../../components/icons/historical'
import { IconContainer } from '../../../containers/icon'
import StepMenuButton from '../../procedure/steps/menu/button'

import useHistoricalButton from './hooks/use-historical-button'

const HistoricalButton = ({ stepId }) => {
  const { toggleHistoricalModeStep, historicalModeStep } = useHistoricalButton({
    stepId
  })

  return (
    <StepMenuButton
      data-historical={historicalModeStep === stepId}
      onClick={toggleHistoricalModeStep}
    >
      <IconContainer icon={HistoricalIcon} />
    </StepMenuButton>
  )
}

export default HistoricalButton
