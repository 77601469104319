import React from 'react'

const Time = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.33325 8.00065C1.33325 9.76876 2.03563 11.4645 3.28587 12.7147C4.53612 13.9649 6.23181 14.6673 7.99992 14.6673C9.76803 14.6673 11.4637 13.9649 12.714 12.7147C13.9642 11.4645 14.6666 9.76876 14.6666 8.00065C14.6666 6.23254 13.9642 4.53685 12.714 3.28661C11.4637 2.03636 9.76803 1.33398 7.99992 1.33398C6.23181 1.33398 4.53612 2.03636 3.28587 3.28661C2.03563 4.53685 1.33325 6.23254 1.33325 8.00065V8.00065Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 8.00009V5.61914'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 8L10.9759 10.9765'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Time
