import { Avatar } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getUser } from '../../../store/user-management/selectors'
import { getInitials, getNameFromEmail } from '../../../utils/helpers/user'

const UserInfo = ({ className }) => {
  const user = useSelector(getUser)

  return (
    <MenuItem id='profile-menu' className={className}>
      <TagCircle id='initial-user-name'>
        {user.initials
          ? user.initials
          : getInitials(getNameFromEmail(user.email))}
      </TagCircle>
      <div>
        <Name>{user.name}</Name>
        <Email>{user.email}</Email>
        <Role>
          <span>{user.role.name}</span>
        </Role>
      </div>
    </MenuItem>
  )
}

const MenuItem = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`

const Role = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #8f8f8f;
  margin-bottom: 8px;
  font-weight: normal;
`

const Name = styled.div`
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 4px;
  text-transform: capitalize;
`

const Email = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  font-weight: normal;
`

const TagCircle = styled(Avatar)`
  color: #ffffff;
  background-color: #645377;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
  align-self: start;
`

export default UserInfo
