import { useEffect, useState } from 'react'

export default function useForm({
  form,
  elementToEdit,
  open,
  onConfirm,
  series
}) {
  const [selectedSeries, setSelectedSeries] = useState([])

  useEffect(() => {
    if (open) {
      if (elementToEdit) {
        form.setFieldValue('name', elementToEdit.name)
        setSelectedSeries(elementToEdit?.series?.map(({ id }) => id))
      } else {
        form.resetFields()
        setSelectedSeries([])
      }
    }
  }, [form, elementToEdit, open])

  const handleConfirmForm = async ({ name }) => {
    onConfirm({
      ...elementToEdit,
      name,
      series: series
        .filter(({ id }) => selectedSeries.includes(id))
        .map(({ id }) => ({ id })),
      createdAt: elementToEdit?.createdAt ?? new Date().valueOf(),
      updatedAt: elementToEdit ? new Date().valueOf() : null
    })
  }

  const onFilterSelectOption = (inputValue, option) =>
    option.label.props.children
      .toUpperCase()
      .indexOf(inputValue.toUpperCase()) !== -1

  return {
    selectedSeries,
    setSelectedSeries,
    handleConfirmForm,
    onFilterSelectOption
  }
}
