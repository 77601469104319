import { cloneDeep, maxBy } from "lodash"

import { calculateResult, getVariableValues } from '../formulas'
import { extractHtmlText } from '../html'

function findParent(steps, stepId) {
  const parent = steps.find((s) => s.id.split('_')[2] === stepId.split('_')[2])
  return parent
}

function buildBranch(step, stepId, actualBranch) {
  if (step.id === stepId) {
    return [...actualBranch, step.stepIndex]
  }

  for (const children of step.children) {
    const result = buildBranch(children, stepId, [
      ...actualBranch,
      step.stepIndex
    ])
    if (result) return result
  }

  // In the case that is the last node in the branch and is not the stepId,
  // we remove the father of the node, which is the last element of the array,
  // as this is not the branch we are looking for
  actualBranch.splice(-1)
  return null
}

/**
 * Function to build a step index with numbers and letters, like 1.2.3 or 1.2.a
 * @param {Array} stepsIndex Array of steps as a tree, where children is an array of steps which also each one could have another childrens
 * @param {string} step Id of the step which index we want to built
 */
export function buildStepIndex(stepsIndex, stepId) {
  if (!stepId) return ''
  const parent = findParent(stepsIndex, stepId)
  if (!parent) return ''

  // If the parent has no children, then the parent is no correct
  // so we return the step index
  if (parent.children.length === 0) {
    return stepsIndex.find((step) => step.id === stepId)?.stepIndex ?? ''
  }

  const indexes = buildBranch(parent, stepId, [])

  // If there are no indexes, then the current step is not a children of the parent
  // So we guess that there is no branch and we return the step index
  if (!indexes) {
    return stepsIndex.find((step) => step.id === stepId)?.stepIndex ?? ''
  }

  const lastElement = indexes.at(-1)

  // If the last step index is like .b, .2, .a, etc, we remove the dot and return the complete branch index
  if (!/^.+\..+/g.test(lastElement)) {
    return indexes.map((i) => i.replaceAll('.', '')).join('.')
  }

  // If the last step index is like 1.2, we return only the last element as it indicates the step index and subindex
  return lastElement
}

function findStep(step, stepId) {
  if (step.id === stepId) {
    return step
  }

  for (const children of step.children) {
    const result = findStep(children, stepId)
    if (result) return result
  }

  return null
}

export function findStepInfo(stepsIndex, stepId) {
  if (!stepId) return ''
  const parent = findParent(stepsIndex, stepId)
  if (!parent) return ''

  if (parent.children.length === 0) {
    return stepsIndex.find((step) => step.id === stepId) ?? null
  }

  const step = findStep(parent, stepId, [])

  return step
}

// Obtenemos el bookmark en forma de número para poder ordenar el árbol y los pasos
// También nos sirve en los componentes que necesiten saber algo de pasos, como por ejemplo las notas y precauciones
// Cada dígito del bookmark se convierte en 3 dígitos del número, ejemplo: 6_1_20 --> 006001020
export function getBookmarkIndex(bookmark) {
  const bookmarkNumber = bookmark
    .split('_')
    .filter((_, i) => i > 1) // quitamos el _ y BMK
    .map((number) => number.padStart(3, '0'))
    .join('')

  return bookmarkNumber
}

// Obtenemos el paso al que pertenece el componente con la posición facilitada
// Será el paso cuyo 'id' (posición de p) sea el máximo sin superar la posición de la firma
// Muchos componentes están en párrafos que no están reflejados en el índice, por eso es
// necesario este método
export function getComponentStep(procedureIndex, position) {
  return maxBy(
    flattenTree({ parent: procedureIndex }).filter(
      (node) => node.id <= position
    ),
    (node) => node.id
  )
}

export function getBookmarkNumbers(bmk) {
  const numberOfBMK = bmk.substring(4)
  return numberOfBMK.split('_').map(Number)
}

export function sortStepsByBookmarks(step1, step2) {
  return sortBookmarks(step1.stepId, step2.stepId)
}


export function bmkIsMinorThan(bmk1, bmk2) {
  return sortBookmarks(bmk1, bmk2) < 0
}

export function sortBookmarks(bmk1, bmk2) {
  const bmk1Numbers = getBookmarkNumbers(bmk1)
  const bmk2Numbers = getBookmarkNumbers(bmk2)

  for (let i = 0; i < bmk1Numbers.length; i++) {
    if (bmk1Numbers[i] > bmk2Numbers[i]) return 1
    if (bmk1Numbers[i] < bmk2Numbers[i]) return -1
  }

  return 0
}

export function flattenTree({
  parent,
  clone = true,
  childrenProperty = 'children'
}) {
  const parentSteps = clone
    ? cloneDeep(parent[childrenProperty])
    : parent[childrenProperty]
  const steps = []

  for (const step of parentSteps) {
    step.index = getBookmarkIndex(step.id)
    steps.push(step)
    steps.push(...flattenTree({ parent: step, clone: false }))
  }

  return steps
}

export function getParent(tree, id) {
  const flatTree = flattenTree({ parent: tree })
  return flatTree.find((step) => step.children.find((child) => child.id === id))
}

// Recursively get parent steps
export function getParents(tree, stepId) {
  const parents = []
  let parent = getParent(tree, stepId)

  while (parent) {
    parents.push(parent)
    parent = getParent(tree, parent.id)
  }

  return parents
}

export function treeFindById(tree, id) {
  for (const c of tree) {
    if (c.id === id) {
      return c
    } else if (c.children) {
      const res = treeFindById(c.children, id)
      if (res) return res
    }
  }
}

export function getConditionalDisabledSteps(
  procedure,
  executionConditionals,
  executionComponents
) {
  if (
    !procedure?.conditionals ||
    !executionComponents ||
    !executionConditionals
  )
    return []

  const stepsToDisable = procedure?.conditionals.map(
    ({ formula, items, steps, id }) => {
      // Dividir pasos por condición true/false
      const ifConditionalTrueSteps = []
      const ifConditionalFalseSteps = []
      steps.forEach((step) => {
        step.enabledIfConditionalIsTrue
          ? ifConditionalTrueSteps.push(step.stepId)
          : ifConditionalFalseSteps.push(step.stepId)
      })

      const executionConditional = executionConditionals.find(
        (executionConditional) => executionConditional.conditionalId === id
      )

      /**
       * If the condition is forced, all the steps of the condition are deactivated.
       * The logic is inverse since this function returns the steps to be deactivated.
       */

      if (executionConditional && executionConditional.forcedValue) {
        return ifConditionalFalseSteps
      }

      if (
        executionConditional &&
        executionConditional.forcedValue !== null &&
        !executionConditional.forcedValue
      ) {
        return ifConditionalTrueSteps
      }

      // Creamos el mapa de {'variable':'id_de_componente'} que necesita la función del helper
      const variableIdsMap = items.reduce(
        (acc, item) => ({ ...acc, [item.variable]: item.element }),
        {}
      )

      const variableValuesMap = getVariableValues({
        variableIdsMap,
        executionComponents,
        procedureComponents: procedure.components
      })

      // Si algún componente no tiene valor, se desactivan todos los pasos de la condición
      if (Object.values(variableValuesMap).some((value) => value == null)) {
        return steps.map((step) => step.stepId)
      }

      const result = calculateResult({
        formula,
        variableValuesMap
      })
      return result ? ifConditionalFalseSteps : ifConditionalTrueSteps
    }
  )
  // flat final array and remove duplicates
  return [...new Set([].concat(...stepsToDisable))]
}

export function getManeuverDisabledSteps(procedure, executionManeuvers) {
  if (!procedure?.maneuvers) return []
  const stepsToDisable = procedure?.maneuvers.map((maneuver) => {
    const { parentStep, steps } = maneuver
    // check if maneuver is active, return empty array, if not return all child steps
    return executionManeuvers?.some(
      (maneuver) => maneuver.stepId === parentStep && maneuver.active
    )
      ? []
      : steps.map((s) => s.step)
  })
  // flat final array and remove duplicates
  return [...new Set([].concat(...stepsToDisable))]
}

export const extractStepsText = (steps) =>
  steps.map((step) => ({
    stepId: step.stepId,
    text: extractHtmlText(step.html)
}))
