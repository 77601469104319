import { Button } from 'antd'
import React, { useState } from 'react'

import Modal from '../../../views/modal'
import Translation from '../../../views/translations'


export const DeleteButton = ({ disabled, onClick, deleteQuestion }) => {
  const [modalConfirmVisible, setModalConfirmVisible] = useState(false)

  return (
    <>
      <Button
        id='delete-procedure-button'
        disabled={disabled}
        onClick={(ev) => {
          ev.stopPropagation()
          setModalConfirmVisible(true)
        }}
      >
        <Translation id='delete' />
      </Button>
      <Modal
        closable={false}
        visible={modalConfirmVisible}
        onOk={() => {
          onClick()
          setModalConfirmVisible(false)
        }}
        onCancel={(ev) => {
          ev.stopPropagation()
          setModalConfirmVisible(false)
        }}
        okButtonText={<Translation id='okText' />}
      >
        {deleteQuestion}
      </Modal>
    </>
  )
}
