import { Input, message, Button as AntButton } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import { readLogin, requestLogin } from '../../application/users/session'
import Lock from '../../components/icons/lock'
import { useTranslation } from '../../hooks/useTranslation'
import { getTranslation } from '../../i18n/getTranslation'
import { STATUS_CODES } from '../../utils/constants/http'
import { reencryptUserData } from '../../utils/helpers/user'
import Translation from '../../views/translations'

import { Modal, Form, Button } from './index'

export const InsertPasswordModal = ({
  mode,
  onFinish,
  username,
  password,
  visible,
  setVisible,
  onSkipAndContinue
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  async function submitForm({ password: typedPassword }) {
    setLoading(true)
    let response
    try {
      if (mode === 'remote') {
        response = await requestLogin(username, typedPassword)
      } else {
        response = await readLogin(username, typedPassword)
      }
    } catch (error) {
      response = error.response
    }

    let newPassword = mode === 'remote' ? typedPassword : password
    let oldPassword = mode === 'remote' ? password : typedPassword

    if (response?.status === STATUS_CODES.OK) {
      reencryptUserData(username, oldPassword, newPassword)
    } else if (response?.status === STATUS_CODES.UNAUTHORIZED) {
      message.error(getTranslation('wrongPassword'))
    } else {
      message.error(getTranslation('unknownError'))
    }

    setLoading(false)
    form.resetFields()
    onFinish?.(newPassword)
  }

  return (
    <Modal
      centered
      title={
        <Translation
          id={
            mode === 'remote' ? 'insertRemotePassword' : 'insertLocalPassword'
          }
        />
      }
      open={visible}
      closable={true}
      onCancel={() => setVisible(false)}
      footer={null}
      maskStyle={{ background: 'transparent' }}
    >
      <Translation
        id={mode === 'remote' ? 'remotePasswordWrong' : 'localPasswordWrong'}
      />

      <Form
        id='re-enter-password'
        name='basic'
        form={form}
        preserve={false}
        initialValues={{ remember: true }}
        onFinish={submitForm}
        style={{ marginTop: '20px' }}
      >
        <Form.Item
          name='password'
          rules={[
            { required: true, message: <Translation id='fieldRequired' /> }
          ]}
        >
          <Input.Password
            autoComplete='current-password'
            type='password'
            placeholder={useTranslation('password')}
            prefix={<Lock />}
          />
        </Form.Item>

        <Button
          id='re-enter-password-button'
          type='primary'
          htmlType='submit'
          loading={loading}
        >
          <Translation id='okText' />
        </Button>

        <SkipButton
          id='skip-and-continue-button'
          type='primary'
          loading={loading}
          onClick={onSkipAndContinue}
        >
          <Translation id='skipAndContinue' />
        </SkipButton>
      </Form>
    </Modal>
  )
}

const SkipButton = styled(AntButton)`
  width: 100%;
  border-radius: 2px;
  padding: 12px 0;
  height: 100%;
  margin-top: 15px;
  background-color: #fff;
  color: #f12e2e;
`
