import React from 'react'
import { useSelector } from 'react-redux'

import Stop from '../../../../../../components/icons/stop'
import {
  getExecution,
  getIsReview
} from '../../../../../../store/execution/selectors'
import { EXECUTION_STATUS } from '../../../../../../utils/constants/execution'
import StopPopover from '../../../../../popover/stop'
import { ActionButton } from '../../button'

const StopButton = () => {
  const execution = useSelector(getExecution)
  const isReview = useSelector(getIsReview)

  return (
    <StopPopover>
      <ActionButton
        id='stop-execution-button'
        icon={Stop}
        activeStatus={isReview ? execution?.qaStatus : execution?.status}
        statuses={[EXECUTION_STATUS.FINISHED, EXECUTION_STATUS.ABORTED]}
      />
    </StopPopover>
  )
}

export default StopButton
