import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData } from '../../common/write'

export function writeFile(files) {
  const data = files.map((file) => ({ id: file.id, data: file }))

  const tableName = TABLE_NAMES.FILES
  return _writeLocalDbData({
    tableName,
    data,
    action: 'PUT'
  })
}
