import { axiosInstance } from '../../..'

export async function postSendFile(file, type, id) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)
  formData.append('id', id || null)

  return axiosInstance.post('files', formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}
