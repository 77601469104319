import { Select as SelectAntd } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Translation from '../../../views/translations'

import TagRender from './tag-render'


export default function SelectMultipleCheckbox({
  options,
  setSelected,
  value,
  disabled,
  ...rest
}) {
  return (
    <Select
      mode='multiple'
      allowClear
      showArrow
      placeholder={<Translation id='select' />}
      tagRender={TagRender}
      onChange={setSelected}
      options={options}
      value={value}
      disabled={disabled}
      {...rest}
    />
  )
}

const Select = styled(SelectAntd)`
  min-width: 131px;
`
