import { Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Caret from '../../../../../components/icons/caret'
import { getTranslation } from '../../../../../i18n/getTranslation'

const { TextArea } = Input

export const Annotation = ({
  annotation,
  isCurrent,
  isExecutionRunning,
  isExecutionShared,
  isExpanded,
  setIsExpanded,
  isAnnotationVisible,
  onChangeAnnotation,
  annotationRef
}) => {
  return (
    !isExecutionShared &&
    isAnnotationVisible && (
      <AnnotationWrapper>
        <AnnotationTextArea
          className='annotation-text'
          disabled={!isCurrent || !isExecutionRunning}
          rows={isExpanded ? 4 : 1}
          defaultValue={annotation}
          onBlur={(ev) => onChangeAnnotation(ev.target.value)}
          maxLength='400'
          placeholder={getTranslation('placeHolderAnnotation')}
          ref={annotationRef}
        />

        <CollapseButton
          data-isexpanded={isExpanded}
          onClick={() => {
            if (!isCurrent) return
            setIsExpanded(!isExpanded)
          }}
        />
      </AnnotationWrapper>
    )
  )
}

const AnnotationWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 98%;
  background-color: #ffff9c;
  border-radius: 4px;
`

const AnnotationTextArea = styled(TextArea)`
  background-color: #ffff9c;
  border: none;
  height: 100%;
  outline: none;
  resize: none;

  &:focus {
    border: none;
    box-shadow: none;
  }

  &:disabled {
    background-color: #ffff9c;
    color: #000000;
  }
`

const CollapseButton = styled(Caret)`
  margin: 10px 10px 0 0;
  transition: transform 0.2s ease-out;
  transform: ${({ 'data-isexpanded': isExpanded }) =>
    isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'};
  height: 20px;
  width: 20px;
`

export default Annotation
