import { LOG_LEVEL } from '../constants/config'

/* eslint-disable no-console */
const DEBUG = 3
const INFO = 2
const WARN = 1
const ERRO = 0
const DEBUG_LEVEL = Number(LOG_LEVEL || INFO)

const _log = (level, log, ...data) => {
  if (level <= DEBUG_LEVEL) log(...data)
}

export const debug = (...data) => _log(DEBUG, console.debug, ...data)
export const info = (...data) => _log(INFO, console.info, ...data)
export const warn = (...data) => _log(WARN, console.warn, ...data)
export const erro = (...data) => _log(ERRO, console.error, ...data)
