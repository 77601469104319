import { Tooltip } from 'antd'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import LinkButton from '../../buttons/link'

const RouteTab = ({ path, exact, text, icon, id, disabled }) => {
  const match = useRouteMatch(path, { exact })

  return (
    <Tooltip title={text} placement='right'>
      <TabButton
        className='tab-button'
        type='link'
        to={path}
        data-active={match?.isExact}
        id={id}
        disabled={disabled}
      >
        {icon}
      </TabButton>
    </Tooltip>
  )
}

const TabButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 56px;
  // Lighter background on hover
  &:hover {
    background-color: #b8bab6;
  }
  background-color: ${({ 'data-active': active }) =>
    active ? '#a8a7a5' : 'transparent'};
`

export default RouteTab
