import React from 'react'
import styled from 'styled-components'

const SectionTitle = ({ children, extra }) => {
  return (
    <TitleContainer>
      <Title>{children}</Title>
      <section>{extra}</section>
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Title = styled.h3`
  padding: 0.5em;
`

export default SectionTitle
