import { Tabs } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getUser } from '../../store/user-management/selectors'

import { configTabs } from './tabs'

const Configuration = () => {
  const user = useSelector(getUser)
  const { admin } = user.role

  const finalTabs = admin ? configTabs : configTabs.filter((tab) => !tab.admin)

  return (
    <ConfigContainer>
      <Tabs type='card' items={finalTabs} />
    </ConfigContainer>
  )
}

const ConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 77px 28px 28px; // 2em+49px=77px
  max-height: 90vh;
  overflow-y: auto;
`

export default Configuration
