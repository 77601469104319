import { pick } from 'lodash'

import { getDatabase } from '../..'

export function getTablePKs(tableName, database) {
  const db = database || getDatabase()

  const dexieKeys = db[tableName].schema.primKey.keyPath
  const arrayKeys = Array.isArray(dexieKeys) ? dexieKeys : [dexieKeys]
  return arrayKeys
}

export function getPKValues(tableName, cryptedArray) {
  const pks = getTablePKs(tableName)

  let keysForTable = cryptedArray.map((c) => {
    let array = Object.values(pick(c, pks))
    return array.length > 1 ? array : array[0]
  })
  return keysForTable
}
