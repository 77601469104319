import { Popover } from 'antd'
import React, { useState } from 'react'

import PopoverContent from './content'

const PopoverMenu = ({
  items,
  children,
  placement,
  trigger,
  footer,
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  return (
    <Popover
      content={
        <PopoverContent items={items} footer={footer} setVisible={setVisible} />
      }
      open={visible}
      overlayClassName='custom-popover'
      placement={placement}
      trigger={trigger}
      onOpenChange={(v) => setVisible(v)}
      {...props}
    >
      <div onClick={() => setVisible(true)}>{children}</div>
    </Popover>
  )
}

export default PopoverMenu
