import React, { useState } from 'react'
import styled from 'styled-components'

import ConfigSection from '../../containers/config-section'
import SectionTitle from '../../containers/section-title'
import Translation from '../translations'

import AddListItem from './add-list-item'
import EditListItem from './edit-list-item'
import ListItem from './list-item'

const EditableList = ({
  itemsList,
  listTitle,
  itemTitle,
  submitListItem,
  editListItem,
  deleteListItem
}) => {
  const [editableElement, setEditableElement] = useState(null)

  const handleEditItem = async (itemName) => {
    const itemData = {
      id: editableElement.id,
      name: itemName
    }
    await editListItem(itemData)
    setEditableElement(null)
  }

  return (
    <ConfigSection>
      <SectionTitle>
        <Translation id={listTitle} />
      </SectionTitle>
      <AddListItem submitListItem={submitListItem} item={itemTitle} />
      {itemsList.length ? (
        <EditableListContainer>
          {itemsList.map((item) => (
            <ListItem
              item={item}
              key={item.id}
              editListItem={editListItem}
              deleteListItem={deleteListItem}
              openEditModal={(item) => setEditableElement(item)}
            />
          ))}
        </EditableListContainer>
      ) : (
        ''
      )}
      <EditListItem
        open={editableElement !== null}
        closeModal={() => setEditableElement(null)}
        editItem={handleEditItem}
        itemName={editableElement?.name}
        itemTitle={itemTitle}
      />
    </ConfigSection>
  )
}

const EditableListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-width: 1px 1px 0 1px;
  margin: 1em 0;
  width: 100%;
`

export default EditableList
