import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

import ElementsCountContainer from '../../containers/elements-count'
import Translation from '../translations'

function ElementsCount({ total, selected, clearSelection }) {
  return (
    total !== undefined && (
      <ElementsCountContainer>
        <p>
          <ElementsCountText>
            <Translation id='total.elements' />:
          </ElementsCountText>{' '}
          <ElementsCountNumber>{total}</ElementsCountNumber>
        </p>
        {selected !== undefined && (
          <>
            <p>
              <ElementsCountText>|</ElementsCountText>
            </p>
            <p>
              <ElementsCountNumber style={{ textDecoration: 'underline' }}>
                {selected}
              </ElementsCountNumber>{' '}
              <ElementsCountText>
                <Translation id='total.elements.selected' />
              </ElementsCountText>
            </p>
            {selected > 0 && (
              <>
                <p>
                  <ElementsCountText>|</ElementsCountText>
                </p>
                <p>
                  <ClearButton onClick={clearSelection}>
                    <Translation id='clear.selection' />
                  </ClearButton>
                </p>
              </>
            )}
          </>
        )}
      </ElementsCountContainer>
    )
  )
}

const ElementsCountText = styled.span`
  color: #979797;
  font-size: 15px;

  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
`

const ElementsCountNumber = styled.span`
  color: #000000;
  font-size: 15px;

  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
`

const ClearButton = styled(Button)`
  border-radius: 2px;
  color: #ffffff;
  background-color: #645377;
  padding: 0px 7px;
  height: auto;
  font-size: 14px;

  &:hover {
    background-color: #645377;
    color: #ffffff;
  }

  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
`

export default ElementsCount
