import React from 'react'
import styled from 'styled-components'

const PdfViewer = ({ file }) => <Iframe src={file} />

const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
`

export default PdfViewer
