import { STATUS_CODES } from '../http'

// prettier-ignore
export const LOGIN_CASES = {
    // ONLINE STATUS                   // OFFLINE STATUS
    [STATUS_CODES.ERROR        + ',' + STATUS_CODES.ERROR]:        { showError: 'errorOnOfflineLogin' },
    [STATUS_CODES.ERROR        + ',' + STATUS_CODES.OK]:           { isLoginOk: true },
    [STATUS_CODES.ERROR        + ',' + STATUS_CODES.NOT_FOUND]:    { showError: 'loginOnlineFirst' },
    [STATUS_CODES.ERROR        + ',' + STATUS_CODES.UNAUTHORIZED]: { showError: 'failedAuthAttempt' },
    [STATUS_CODES.OK           + ',' + STATUS_CODES.ERROR]:        { showError: 'errorOnOfflineLogin' },
    [STATUS_CODES.OK           + ',' + STATUS_CODES.OK]:           { isLoginOk: true, isOnline: true },
    [STATUS_CODES.OK           + ',' + STATUS_CODES.NOT_FOUND]:    { isLoginOk: true, isOnline: true, isFirstLogin: true },
    [STATUS_CODES.OK           + ',' + STATUS_CODES.UNAUTHORIZED]: { askPassword: 'local'  },
    [STATUS_CODES.UNAUTHORIZED + ',' + STATUS_CODES.ERROR]:        { showError: 'errorOnOfflineLogin' },
    [STATUS_CODES.UNAUTHORIZED + ',' + STATUS_CODES.OK]:           { askPassword: 'remote' },
    [STATUS_CODES.UNAUTHORIZED + ',' + STATUS_CODES.NOT_FOUND]:    { showError: 'failedAuthAttempt' },
    [STATUS_CODES.UNAUTHORIZED + ',' + STATUS_CODES.UNAUTHORIZED]: { showError: 'failedAuthAttempt' },
    [STATUS_CODES.CONFLICT     + ',' + STATUS_CODES.ERROR]:        { showError: 'errorOnOfflineLogin' },
    [STATUS_CODES.CONFLICT     + ',' + STATUS_CODES.OK]:           { changeFirstPassword: true },
    [STATUS_CODES.CONFLICT     + ',' + STATUS_CODES.NOT_FOUND]:    { changeFirstPassword: true },
    [STATUS_CODES.CONFLICT     + ',' + STATUS_CODES.UNAUTHORIZED]: { askPassword: 'local'  },
  }
