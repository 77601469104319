import React from 'react'

const Alert = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_7281_31119)'>
      <rect
        width='16'
        height='16'
        transform='translate(0 0.0703125)'
        fill='white'
        fillOpacity='0.01'
      />
      <g clipPath='url(#clip1_7281_31119)'>
        <path
          d='M8 11.5703C7.86193 11.5703 7.75 11.4584 7.75 11.3203C7.75 11.1822 7.86193 11.0703 8 11.0703'
          fill='#FAAD14'
        />
        <path
          d='M8 11.5703C7.86193 11.5703 7.75 11.4584 7.75 11.3203C7.75 11.1822 7.86193 11.0703 8 11.0703'
          stroke='#F7931E'
        />
        <path
          d='M8 11.5703C8.13807 11.5703 8.25 11.4584 8.25 11.3203C8.25 11.1822 8.13807 11.0703 8 11.0703'
          stroke='#F7931E'
        />
        <path
          d='M8 9.07031V4.07031'
          stroke='#F7931E'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M0.8533 8.92439C0.62707 8.69786 0.5 8.39086 0.5 8.07073C0.5 7.75059 0.62707 7.44353 0.8533 7.21706L7.1466 0.92438C7.2586 0.812147 7.3916 0.7231 7.538 0.662343C7.68447 0.601587 7.8414 0.570312 7.99993 0.570312C8.15847 0.570312 8.31547 0.601587 8.46193 0.662343C8.60833 0.7231 8.74133 0.812147 8.85327 0.92438L15.1466 7.21706C15.3729 7.44353 15.4999 7.75059 15.4999 8.07073C15.4999 8.39086 15.3729 8.69786 15.1466 8.92439L8.85327 15.2171C8.62693 15.4433 8.32 15.5703 7.99993 15.5703C7.67993 15.5703 7.373 15.4433 7.1466 15.2171L0.8533 8.92439Z'
          stroke='#F7931E'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_7281_31119'>
        <rect
          width='16'
          height='16'
          fill='white'
          transform='translate(0 0.0703125)'
        />
      </clipPath>
      <clipPath id='clip1_7281_31119'>
        <rect
          width='16'
          height='16'
          fill='white'
          transform='translate(0 0.0703125)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default Alert
