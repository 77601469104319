import React from 'react'
import styled from 'styled-components'

import { getColorFromSeed } from '../../../utils/helpers/styles'
import {
  getInitials,
  getNameFromEmail
} from '../../../utils/helpers/user'

const UserAvatar = ({ user, color, textColor, className }) => {
  const getText = (userId) =>
    userId.length > 4 ? `${userId.slice(0, 3)}...` : userId
  return (
    <Avatar
      id={`user-color-${user.name}`}
      color={color || getColorFromSeed(user.id)}
      textColor={textColor}
      className={className}
    >
      {getText(
        user.initials
          ? user.initials
          : getInitials(getNameFromEmail(user.email))
      )}
    </Avatar>
  )
}

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  background-color: ${({ color }) => `${color};`};
  color: ${({ textColor }) => `${textColor};`};
  align-items: center;
  justify-content: center;
  margin: 5px;
  line-height: 0;
  padding: 20px;
  font-size: 0.8em;
`

export default UserAvatar
