import React from 'react'
import styled from 'styled-components'

const RouteTabs = ({ children, tabs }) => {
  return (
    <TabsContainer className='tabs-container'>
      <TabBar className='tab-bar'>{tabs}</TabBar>
      <TabsContent className='tabs-content'>{children}</TabsContent>
    </TabsContainer>
  )
}

const TabBar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 64px;
  background-color: #e1e1db;
`

const TabsContent = styled.div`
  flex: 1;
  overflow: hidden;
`

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export default RouteTabs
