import React from 'react'

const ArrowRight = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 32 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M26.6667 16.166L5.33337 16.166'
      stroke={color}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.3334 21.166L27.3334 16.166L22.3334 11.166'
      stroke={color}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ArrowRight
