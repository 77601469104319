import { computeChecksum } from '../files'
import { prepareHTML } from '../html-maker'

//A Blob() is almost a File() - it's just missing the two properties below which we will add
function blobToFile(theBlob, fileName) {
  theBlob.lastModifiedDate = new Date()
  theBlob.name = fileName

  return theBlob
}

export function generateHTML(procedureData, execution, procedure) {
  const contentHTML = procedureData.cloneNode(true)
  return prepareHTML(contentHTML, execution, procedure)
}

export async function generateReport(procedureData, execution, procedure) {
  const htmlReport = await generateHTML(procedureData, execution, procedure)
  const fileNameHTML = `${procedure.key}_${procedure.rev}_${
    execution?.id ?? 'noExecution'
  }.html`

  const aBlobHTML = new Blob([htmlReport], { type: 'text/html' })
  blobToFile(aBlobHTML, fileNameHTML)

  const checksum = await computeChecksum(aBlobHTML)

  const filesToStore = new FormData()

  filesToStore.append('htmlFile', aBlobHTML, aBlobHTML.name)
  filesToStore.append('checksum', checksum)

  return filesToStore
}
