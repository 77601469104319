import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const PrintOverlay = ({ message }) => {
  return (
    <SpinContainer>
      <Spin size='large' />
      <h3>{message}</h3>
    </SpinContainer>
  )
}

const SpinContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`

export default PrintOverlay
