import moment from 'moment'

import { getTranslation } from '../../../../i18n/getTranslation'
import {
  loadVideosOfExecutionComments,
  loadVideosOfStepCommments
} from '../../../../localdb/files/read'
import {
  loadFilesOfExecutionCommments,
  loadFilesOfStepComments
} from '../../../../localdb/filesContent/read'
import { COMMENT_TO_STEP_TYPES, COMMENT_TYPES } from '../../../../utils/constants/comment'
import { sortBookmarks } from '../../../../utils/helpers/steps'
import { getIcon } from '../icons'
import {
  convertImageToBase64,
  getEmbebedImageParagraph,
  isAnImage
} from '../images'
import { getLinkComment, getLinkCommentBack, getLinkVideo } from '../links'
import { createCommentTag } from '../tags'

export async function getCommentParagraph(
  comment,
  commentFiles,
  kindOfComment,
  commentImg,
  attachmentImg,
  videoIcon,
  videos,
  isLastComment
) {
  const containerReference = document.createElement('a')

  containerReference.id = comment.id
  containerReference.className = kindOfComment

  const containerNode = document.createElement('section')
  containerNode.className = 'containerComment'

  const commentTitleNode = document.createElement('span')
  commentTitleNode.className = 'step-comment-title'
  containerNode.appendChild(commentTitleNode)

  //Icon
  const iconNode = getIcon(commentImg)
  commentTitleNode.append(iconNode)

  //User
  const userNode = document.createElement('span')
  userNode.style.fontWeight = 'bold'
  userNode.textContent = comment.user.name
  commentTitleNode.appendChild(userNode)

  //Date
  const dateTimeFormat = getTranslation('dateFormat')
  const dateNode = document.createElement('span')
  dateNode.textContent = ` (${moment(comment.createdAt).format(
    dateTimeFormat
  )}): `
  commentTitleNode.appendChild(dateNode)

  // Comment type
  const commentTypeNode = document.createElement('span')
  commentTypeNode.className = 'step-comment-tag'

  if (comment.isNonCompliantComment) {
    createCommentTag(commentTitleNode, 'nonCompliance')
  }

  if (comment.isReviewComment || comment.isReviewStepComment) {
    createCommentTag(commentTitleNode, 'review')
  }

  if (comment.type === COMMENT_TO_STEP_TYPES.CRITICAL) {
    createCommentTag(commentTitleNode, 'important')
  }

  //Comment
  const commentNode = document.createElement('div')
  commentNode.textContent = comment.comment
  commentNode.className = 'comment-content'
  containerNode.append(commentNode)

  switch (kindOfComment) {
    case COMMENT_TYPES.COMMENT_TO_PROCEDURE: {
      for (const media of commentFiles) {
        const fileNodeContainer = document.createElement('div')
        fileNodeContainer.className = 'attachment-container'

        if (media) {
          if (isAnImage(media.data)) {
            const imageNode = await getEmbebedImageParagraph(media.data)
            fileNodeContainer.append(imageNode)
          } else {
            const fileNode = document.createElement('span')
            fileNode.style.fontSize = '14px'
            fileNode.textContent = media.data.name
            fileNodeContainer.append(fileNode)

            const iconAttachmentNode = getIcon(attachmentImg)
            iconAttachmentNode.style.verticalAlign = 'text-top'
            fileNodeContainer.append(iconAttachmentNode)

            fileNodeContainer.append(fileNode)
          }
        }
        containerNode.append(fileNodeContainer)
      }

      videos?.forEach((video) => {
        if (video) {
          const link = getLinkVideo(
            `${window.location.origin}/video/${video.filename}/${video.originalName}`,
            video.originalName,
            videoIcon
          )
          containerNode.append(link)
        }
      })

      break
    }

    case COMMENT_TYPES.COMMENT_TO_STEP:
      {
        //Attachment Container

        containerNode.style.marginBottom = '0px'

        const fileNodeContainer = document.createElement('div')
        fileNodeContainer.style.marginTop = '10px'
        containerNode.append(fileNodeContainer)

        if (comment.mediaIds) {
          for (const mediaId of comment.mediaIds) {
            const mediaOfComment = commentFiles.find(
              (media) => media.id === mediaId
            )

            if (mediaOfComment) {
              const imageNode = await getEmbebedImageParagraph(
                mediaOfComment.data
              )
              fileNodeContainer.append(imageNode)
            } else {
              const videoOfComment = videos?.find(
                (video) => video.id === mediaId
              )
              if (videoOfComment) {
                const link = getLinkVideo(
                  `${window.location.origin}/video/${videoOfComment.filename}/${videoOfComment.originalName}`,
                  videoOfComment.originalName,
                  videoIcon
                )
                containerNode.append(link)
              }
            }
          }
        }
      }
      break
  }

  // Insert a comment divider if it is not the last comment
  if (!isLastComment) {
    const commentDividerNode = document.createElement('hr')
    commentDividerNode.className = 'comment-divider'
    containerNode.appendChild(commentDividerNode)
  }

  containerReference.append(containerNode)
  return containerReference
}

export async function getStepComments(
  sortedComments,
  commentMedia,
  commentImage,
  attachmentImage,
  videoIcon,
  videos
) {
  if (!sortedComments) return null

  var stepCommentElement = document.createElement('li')
  const bookmark = sortedComments[0].bookmark
  const stepTitle =
    document.getElementById(bookmark).textContent.substring(0, 30) + '...'

  var spanStep = document.createElement('div')
  spanStep.style.marginBottom = '24px'
  spanStep.style.marginTop = '24px'
  spanStep.innerHTML = stepTitle
  stepCommentElement.appendChild(spanStep)

  const allParagraphs = await Promise.all(
    sortedComments.map(async (comment, index) => {
      const isLastComment = sortedComments.length - 1 === index

      return await getCommentParagraph(
        comment,
        commentMedia,
        COMMENT_TYPES.COMMENT_TO_STEP,
        commentImage,
        attachmentImage,
        videoIcon,
        videos,
        isLastComment
      )
    })
  )

  Array.from(allParagraphs).forEach((p) => {
    stepCommentElement.appendChild(p)
  })

  return stepCommentElement
}

export function isEmptyComments(stepComments) {
  if (stepComments.length === 0) return true
}

// Aquí en vez de insertar el comentario, tenemos que marcarlo de algún modo
export async function markCommentElement(execution, content) {
  if (!execution || !execution.steps) return

  const attachment64 = await convertImageToBase64(
    '/public/icons/execution-comment.svg'
  )
  for (let i = 0; i < execution.steps.length; i++) {
    const aStep = execution.steps[i]

    const stepElement = content.querySelector(`#${aStep.stepId}`)
    if (stepElement && !isEmptyComments(aStep.comments)) {
      // hay que pegarlo tras el último paragraph del elemento
      const firstElement = stepElement.firstChild
      const iconNode = getIcon(attachment64)

      firstElement?.appendChild(iconNode)

      for (let c = 0; c < aStep.comments.length; c++) {
        const link = getLinkComment(aStep.comments[c], c + 1)
        firstElement?.appendChild(link)
        firstElement.id = 'P_' + aStep.comments[c].bookmark
      }
    }
  }
}

// Comentarios del procedimiento y comentarios al paso
export async function getCommentsAnnexed(execution, content) {
  if (!execution || !execution.steps) return
  const stepsCommentMediaFiles = await loadFilesOfStepComments(execution.steps)
  const videosStepComments = await loadVideosOfStepCommments(execution.steps)

  const executionCommentFiles = await loadFilesOfExecutionCommments(
    execution.comments
  )

  // Creamos una sección para el anexo de comentarios
  const annexed = document.createElement('section')
  annexed.setAttribute('id', 'commentsSection')
  const title = document.createElement('div')
  title.className = 'annexedComments'
  title.textContent = getTranslation('annexed')
  annexed.appendChild(title)

  // Con dos apartados: Comentarios al paso y al procedimiento
  const sectionCommmentsToProcedure = document.createElement('section')
  sectionCommmentsToProcedure.className = 'annexedComments'

  const executionCommentsIcon = await convertImageToBase64(
    '/public/icons/execution-comment.svg'
  )
  const stepCommentsIcon = await convertImageToBase64(
    '/public/icons/step-comment.svg'
  )
  const attachmentImage = await convertImageToBase64(
    '/public/icons/document.svg'
  )
  const videoIcon = await convertImageToBase64('/public/icons/video.svg')

  // Comentarios al procedmiento

  if (execution.comments) {
    const videosExecutionComments = await loadVideosOfExecutionComments(
      execution.comments
    )

    const sectionCommentsToProcedure = document.createElement('section')
    sectionCommentsToProcedure.style.marginBottom = '40px'

    const titleCommentsAtProcedure = document.createElement('div')
    titleCommentsAtProcedure.className = 'titleComments'

    titleCommentsAtProcedure.textContent = getTranslation('procedureComments') // Comentarios al procedimiento
    sectionCommentsToProcedure.append(titleCommentsAtProcedure)

    const sortedExecutionComments = [...execution.comments]
      .filter((c) => !c.deletedAt)
      .sort((itemA, itemB) => {
        return (
          new Date(itemA.createdAt).getTime() -
          new Date(itemB.createdAt).getTime()
        )
      })

    const allComments = await Promise.all(
      sortedExecutionComments.map(async (comment, index) => {
        // cada comentario al procedimiento puede tener un adjunto, le pasamos el suyo
        const mediasOfComment = executionCommentFiles.filter((media) =>
          comment.mediaIds.includes(media.id)
        )
        const videosOfComment = videosExecutionComments.filter((video) =>
          comment.mediaIds.includes(video.id)
        )

        const isLastComment = sortedExecutionComments.length - 1 === index

        const executionCommentParagraph = await getCommentParagraph(
          comment,
          mediasOfComment,
          COMMENT_TYPES.COMMENT_TO_PROCEDURE,
          executionCommentsIcon,
          attachmentImage,
          videoIcon,
          videosOfComment,
          isLastComment
        )
        return executionCommentParagraph
      })
    )

    Array.from(allComments).forEach((c) => {
      if (c) {
        sectionCommentsToProcedure.appendChild(c)
      }
    })
    annexed.appendChild(sectionCommentsToProcedure)
  }

  // Apartado de comentarios al paso
  const sectionCommentsToStep = document.createElement('section')

  const titleCommentsAtStep = document.createElement('div')
  titleCommentsAtStep.className = 'titleComments'
  titleCommentsAtStep.textContent = getTranslation('commentsDrawerStep') // Comentarios del paso
  sectionCommentsToStep.appendChild(titleCommentsAtStep)

  const stepCommentListContainer = document.createElement('ul')

  // Los ordenamos por paso
  const commentsSortedByStep = execution?.steps
    .filter((s) => s.comments.length > 0)
    .sort((a, b) => sortBookmarks(a.stepId, b.stepId))

  // y dentro del paso por fecha

  let sortedCommentsByStepAndDate = commentsSortedByStep
    .map((step) =>
      [...step.comments]
        .filter((c) => !c.deletedAt)
        .sort((itemA, itemB) => {
          return (
            new Date(itemA.createdAt).getTime() -
            new Date(itemB.createdAt).getTime()
          )
        })
    )
    .filter((c) => c.length > 0)

  const allCommentsToSteps = await Promise.all(
    sortedCommentsByStepAndDate.map(async (step) => {
      return await getStepComments(
        step,
        stepsCommentMediaFiles,
        stepCommentsIcon,
        attachmentImage,
        videoIcon,
        videosStepComments
      )
    })
  )

  Array.from(allCommentsToSteps).forEach((c) => {
    if (c) {
      stepCommentListContainer.appendChild(c)
    }
  })

  sectionCommentsToStep.appendChild(stepCommentListContainer)
  annexed.appendChild(sectionCommentsToStep)

  content.appendChild(annexed)

  // Add back link to all comments to step

  const backCommentImage = await convertImageToBase64('/public/icons/back.png')

  Array.from(
    content.querySelectorAll(`.${COMMENT_TYPES.COMMENT_TO_STEP}`)
  ).forEach((comment) => {
    let link = content.querySelector(`a[data-src="${comment.id}"]`)
    let linkBack = getLinkCommentBack(
      `#${link.parentNode.id}`,
      backCommentImage
    )
    // Insert before de icon of comments
    const commentParagraphTitle = comment.firstChild.firstChild
    commentParagraphTitle.prepend(linkBack)
  })
}
