import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

const ExtraHeaderContent = (props) => (
  <ExtraHeaderContentContainer {...props}>
    {props.children}
  </ExtraHeaderContentContainer>
)

const ExtraHeaderContentContainer = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 38px;
  height: 38px;
  border-radius: 2px;

  &[disabled] {
    background-color: #aaa;
  }
`

export default ExtraHeaderContent
