import React from 'react'

import { IconContainer } from '../icon'

const IconButtonContainer = ({
  size = '24px',
  color = 'currentColor',
  disabled = false,
  rotation = 0,
  icon,
  ...otherProps
}) => (
  <IconContainer
    size={size}
    color={color}
    disabled={disabled}
    rotation={rotation}
    icon={icon}
    style={disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
    {...otherProps}
  />
)

export default IconButtonContainer
