import { Modal } from 'antd'
import React, { useRef } from 'react'
import styled from 'styled-components'

import { ALLOWED_VIDEO_EXTENSIONS } from '../../../utils/constants/files'
import { getAttachmentRepresentation } from '../../../utils/helpers/attachments'

const AttachmentModal = ({ isModalVisible, setIsModalVisible, attachment }) => {
  const mediaRef = useRef(null)

  return (
    <Modal
      open={isModalVisible}
      footer={null}
      title={attachment?.originalName}
      onCancel={(e) => {
        e.preventDefault()
        if (ALLOWED_VIDEO_EXTENSIONS.includes(attachment.extension)) {
          mediaRef.current?.pause()
        }
        setIsModalVisible(false)
      }}
      width={'80%'}
    >
      <MediaContent>
        {getAttachmentRepresentation(attachment, mediaRef)}
      </MediaContent>
    </Modal>
  )
}

const MediaContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default AttachmentModal
