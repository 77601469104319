import { axiosInstance } from '../../../..'

export async function putChangeStatusSourceMergeExecutions(
  executionId1,
  executionId2,
  finishing
) {
  return axiosInstance.put('/executions/merge/changeStatus', {
    executionId1: executionId1,
    executionId2: executionId2,
    finishing
  })
}
