import { Progress } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'


import MessageContainer from '../../containers/message-container'
import LocalizedMoment from '../date/localized-moment'
import ExecutionStatusTag from '../tags/execution-status'
import UnitTag from '../tags/unit'
import Translation from '../translations'

const columns = [
  {
    title: <Translation id='unit' />,
    dataIndex: 'procedure.unit',
    width: 55,
    render: (procedureUnit) => <UnitTag unit={procedureUnit} />
  },
  {
    title: <Translation id='key' />,
    dataIndex: 'procedure.key',
    width: 50
  },
  {
    title: <Translation id='title' />,
    dataIndex: 'procedure.title',
    width: 70
  },
  {
    title: <Translation id='rev' />,
    dataIndex: 'procedure.rev',
    width: 40
  },
  {
    title: <Translation id='lastMod' />,
    dataIndex: 'updatedAt',
    defaultSortOrder: 'descend',
    width: 80,
    render: (updatedAt) => <LocalizedMoment date={moment(updatedAt)} />
  },
  {
    title: <Translation id='status' />,
    dataIndex: 'status',
    width: 100,
    fixed: 'right',
    render: (status) => (
      <ExecutionStatusTag status={status} style={{ fontSize: '12px' }} />
    )
  },
  {
    title: <Translation id='sync' />,
    dataIndex: 'synced',
    fixed: 'right',
    width: 40,
    render: (synced) => (
      <Progress
        percent={100}
        status={synced ? 'success' : 'exception'}
        size='small'
        strokeColor='#52c41a'
      />
    )
  }
]

export const MassiveInterruption = ({
  executions,
  showWarning,
  textId,
  warningTextId
}) => {
  return (
    <MassiveInterruptionContainer>
      {textId && (
        <Text>
          <Translation id={textId} />
        </Text>
      )}

      {showWarning && (
        <MessageContainer type='warning'>
          <Translation id={warningTextId} />
        </MessageContainer>
      )}
      <MassiveInterruptionTableContainer>
        <Title>
          <Translation id='proceduresWorking' />
        </Title>
        <div>
          <table cellPadding={5}>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.dataIndex}>
                    <Column data-width={column.width}>{column.title}</Column>
                  </th>
                ))}
              </tr>
            </thead>
          </table>
        </div>
        <TableBodyContainer id='massive-interruption-table'>
          <table cellPadding={5}>
            <tbody>
              {executions?.map((execution) => (
                <TableRow key={execution.id}>
                  {columns.map((column) => (
                    <td
                      key={column.dataIndex}
                      style={{ textAlign: 'center' }}
                      data-column-id={column.dataIndex}
                    >
                      <Cell data-width={column.width}>
                        {column.render
                          ? column.render(get(execution, column.dataIndex))
                          : get(execution, column.dataIndex)}
                      </Cell>
                    </td>
                  ))}
                </TableRow>
              ))}
            </tbody>
          </table>
        </TableBodyContainer>
      </MassiveInterruptionTableContainer>
    </MassiveInterruptionContainer>
  )
}

const MassiveInterruptionContainer = styled.div`
  padding: 20px;
`

const MassiveInterruptionTableContainer = styled.div`
  margin-top: 40px;
`

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
`

const Title = styled.h4`
  font-size: 18px;
  font-weight: 600;
  color: #000000;
`

const TableBodyContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`

const TableRow = styled.tr`
  border-bottom: 1px solid #edece6;
`

const Column = styled.span`
  display: inline-block;
  ${({ 'data-width': width }) => (width ? `width: ${width}px;` : '')}
  font-size: 14px;
  font-weight: 600;
  padding: 4px;
  text-align: center;
`

const Cell = styled.span`
  display: inline-block;
  ${({ 'data-width': width }) => (width ? `width: ${width}px;` : '')}
  font-size: 12px;
  padding: 4px;
  text-align: center;
`
