import React from 'react'

import { getTranslation } from '../../i18n/getTranslation'
import { notificationMessage } from '../helpers/notification-message'

export function handleDownloadExecutionError(error) {
  let description =
    error?.response?.status === 403
      ? getTranslation('noDownloadExecutionPermission')
      : error?.message
  let detailedMessage =
    error?.response?.status === 403 ? null : error.response?.data?.message

  notificationMessage({
    type: 'error',
    message: getTranslation('downloadFailed'),
    description: (
      <div>
        {description}
        <br />
        {detailedMessage}
      </div>
    )
  })
}
