import React from 'react'

import CloseFilled from '../../../components/icons/close-filled'
import { IconContainer } from '../../../containers/icon'

const CloseFilledView = (props) => (
  <IconContainer {...props} icon={CloseFilled} />
)

export default CloseFilledView
