import { readExecutionComponents } from '../../localdb/execution-component/read'
import { writeExecutionComponents } from '../../localdb/execution-component/write'
import {
  EXECUTION_QA_ACTION,
  EXECUTION_STATUS
} from '../../utils/constants/execution'
import {
  EXECUTION_COMPONENT_TYPES,
  EXECUTION_SIGNATURE_STATUS_IS_FINAL
} from '../../utils/constants/execution/components'
import { TABLE_NAMES } from '../../utils/constants/localdb'
import { erro } from '../../utils/logger'
import { setReviewComment } from '../executionComments/actions'
import { markStepAction, unmarkStepAction } from '../executionSteps/actions'
import { filterByUpdatedAt } from '../helpers'
import { getUser } from '../user-management/selectors'
import { setModuleIsNotSyncing } from '../userInterface/slice'

import {
  setComponentValue,
  setExecutionComponents,
  updateLocaldbQueue,
  setSyncedAt,
  setEditedLiveValue
} from './slice'

export function saveExecutionComponents(components) {
  return async (dispatch) => {
    dispatch(setExecutionComponents({ components }))
    const readoutEditedComponents = components.filter(
      (c) => c.additionalValues !== null
    )
    if (readoutEditedComponents.length > 0) {
      readoutEditedComponents.map((c) =>
        dispatch(
          setEditedLiveValue({
            componentId: c.procedureComponentId,
            liveValue: {
              componentId: c.procedureComponentId,
              editedBy: c.additionalValues?.editedBy,
              quality: c.additionalValues?.quality,
              unit: c.additionalValues?.unit,
              value: c.value
            }
          })
        )
      )
    }
    dispatch(updateLocaldbQueue({ component: components, resetQueue: true }))
  }
}

export function loadExecutionComponents(executionId) {
  return async (dispatch) => {
    if (!executionId) {
      dispatch(setExecutionComponents({ components: null }))
    } else {
      const executionComponents = await readExecutionComponents(executionId)
      dispatch(setExecutionComponents({ components: executionComponents }))
      const readoutEditedComponents = executionComponents.filter(
        (c) => c.additionalValues !== null
      )
      if (readoutEditedComponents.length > 0) {
        readoutEditedComponents.map((c) =>
          dispatch(
            setEditedLiveValue({
              componentId: c.procedureComponentId,
              liveValue: {
                componentId: c.procedureComponentId,
                editedBy: c.liveValue
                  ? c.liveValue.editedBy
                  : c.additionalValues?.editedBy,
                quality: c.liveValue
                  ? c.liveValue.quality
                  : c.additionalValues?.quality,
                unit: c.liveValue ? c.liveValue.unit : c.additionalValues?.unit,
                value: c.value
              }
            })
          )
        )
      }
    }
  }
}

export function changeComponentValue({
  id,
  stepId,
  value,
  additionalValues,
  type
}) {
  return async (dispatch, getState) => {
    const state = getState()
    const user = getUser(state)
    const isReview = state.execution?.mode === EXECUTION_QA_ACTION.REVIEW
    const procedureComponentGroup = state.procedure.procedure.components?.find(
      (ec) => ec.id === id
    )?.options?.group

    const oldValue = state.executionComponents?.components.find(
      (ec) => ec.procedureComponentId === id
    )?.value

    const updatedAt = new Date().valueOf()

    const isShared =
      state.execution.mode?.toUpperCase() ===
      EXECUTION_STATUS.SHARED.toLocaleUpperCase()

    // Cambio de valor para componentes grupales
    if (procedureComponentGroup) {
      const idsToUpdate = state.procedure.procedure.components
        .filter((pc) => pc.options?.group === procedureComponentGroup)
        .map((c) => c.id)

      if (!isShared) {
        const components = state.executionComponents.components?.filter((ec) =>
          idsToUpdate.includes(ec.procedureComponentId)
        )

        const componentsUpdated = components?.map((c) => ({
          ...c,
          value,
          userId: user.id,
          updatedAt,
          additionalValues
        }))

        dispatch(updateLocaldbQueue({ component: componentsUpdated }))
      }

      dispatch(
        setComponentValue({
          id: idsToUpdate,
          value,
          userId: user.id,
          updatedAt,
          additionalValues
        })
      )
    } else {
      if (!isShared) {
        const component = state.executionComponents.components?.find(
          (ec) => ec.procedureComponentId === id
        )

        const componentUpdated = {
          ...component,
          value,
          userId: user.id,
          updatedAt,
          additionalValues
        }

        dispatch(updateLocaldbQueue({ component: componentUpdated }))
      }

      // Cambio de valor para componentes simples
      dispatch(
        setComponentValue({
          id,
          value,
          userId: user.id,
          updatedAt,
          additionalValues
        })
      )
    }

    // Marcar/Desmarcar paso como completado
    if (type === EXECUTION_COMPONENT_TYPES.SIGNATURE) {
      dispatch(completeStep({ value, stepId, componentId: id, state }))
    }

    // Poner comentario de review al cambio de valor de componente
    if (isReview) {
      dispatch(
        setReviewComment({ stepId, oldValue, newValue: value, type, user })
      )
    }
  }
}

function completeStep({ value, stepId, componentId, state }) {
  return async (dispatch) => {
    const user = getUser(state)

    const stepSignatures = state.procedure.procedure.components?.filter(
      (c) =>
        c.stepId === stepId &&
        c.type === EXECUTION_COMPONENT_TYPES.SIGNATURE &&
        c.id !== componentId
    )

    if (
      stepSignatures
        .map((ss) =>
          state.executionComponents.components?.find(
            (ec) => ec.procedureComponentId === ss.id
          )
        )
        .every((s) => s && EXECUTION_SIGNATURE_STATUS_IS_FINAL[s.value?.status])
    ) {
      if (value?.status && EXECUTION_SIGNATURE_STATUS_IS_FINAL[value?.status]) {
        dispatch(markStepAction({ stepId, userId: user.id, action: 'read' }))
        dispatch(
          markStepAction({ stepId, userId: user.id, action: 'completed' })
        )
      } else {
        dispatch(unmarkStepAction({ stepId, userId: user.id }))
      }
    }
  }
}

export function setSyncDate({ entities, syncedAt }) {
  return async (dispatch, getState) => {
    const {
      execution,
      executionComponents: { components }
    } = getState()

    const isShared =
      execution.mode?.toLowerCase() ===
      EXECUTION_STATUS.SHARED.toLocaleLowerCase()

    /**
     * Si no está en el redux se guarda directamente en la indexedDB
     */
    const entitiesNotInState = entities.filter(
      (entity) => entity.executionId !== execution.execution?.id
    )

    if (entitiesNotInState.length > 0) {
      try {
        await writeExecutionComponents(
          entitiesNotInState.map((e) => ({ ...e, syncedAt }))
        )
      } catch (error) {
        erro('Error writing execution components', error)
      }
    }

    /**
     * Cogemos solo los datos que no están actualizados en redux
     */
    const syncedComponents = filterByUpdatedAt(entities, components)

    if (syncedComponents?.length) {
      try {
        if (!isShared) {
          /**
           * Se guarda en la cola para la indexed, solo si es shared
           */
          const componentsToQueue = syncedComponents.map((_component) => ({
            ..._component,
            syncedAt
          }))

          dispatch(updateLocaldbQueue({ component: componentsToQueue }))
        }

        dispatch(setSyncedAt({ entities: syncedComponents, syncedAt }))
      } catch (error) {
        erro('Error syncing execution components', error)
      }
    } else {
      dispatch(
        setModuleIsNotSyncing({ moduleName: TABLE_NAMES.EXECUTION_COMPONENTS })
      )
    }
  }
}
