import { getDatabase } from '../../localdb'
import { _writeLocalDbData } from '../../localdb/common/write'
import { TABLE_NAMES } from '../../utils/constants/localdb'

const tableName = TABLE_NAMES.EXECUTION_COMMENTS

export const readExecutionComments = async (executionId) => {
  const db = getDatabase()

  const comments = await db[tableName].where({ executionId }).toArray()

  return comments
}

export const writeExecutionComments = async (executionComments) => {
  return _writeLocalDbData({
    tableName,
    data: executionComments,
    action: 'PUT'
  })
}

export const deleteExecutionComments = async (executionIds) => {
  const db = getDatabase()

  return db[tableName].where('executionId').anyOf(executionIds).delete()
}
