import { Badge, Popover } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import RoundButton from '../../../../components/buttons/round'
import People from '../../../../components/icons/people'
import { getExecution } from '../../../../store/execution/selectors'
import { getConnectedUsers } from '../../../../store/sharedExecution/selectors'
import { EXECUTION_STATUS } from '../../../../utils/constants/execution'
import { EXECUTION_STATUS_COLOR } from '../../../../utils/constants/styles'

import ConnectedUsersPopover from './popover'

const ConnectedUsersButton = styled((props) => {
  const execution = useSelector(getExecution)
  const connectedUsers = useSelector(getConnectedUsers)
  const [visible, setVisible] = useState(false)

  const { onSelectStep, ...rest } = props

  if (
    execution?.status !== EXECUTION_STATUS.SHARED ||
    !connectedUsers?.length
  ) {
    return null
  }

  return (
    <Popover
      placement='bottom'
      trigger='click'
      open={visible}
      onOpenChange={(v) => setVisible(v)}
      content={<ConnectedUsersPopover onSelectStep={onSelectStep} />}
    >
      <Badge
        id='connected-users-badge'
        count={connectedUsers.length}
        className='connected-users-badge'
        {...rest}
      >
        <RoundButton
          id='connected-users-button'
          color={
            connectedUsers.length >= 1
              ? EXECUTION_STATUS_COLOR.EXECUTING
              : undefined
          }
        >
          <People />
        </RoundButton>
      </Badge>
    </Popover>
  )
})`
  & .ant-badge-count {
    color: #000;
    background-color: #fff;
    margin-top: 4px;
    margin-right: 4px;
  }
`

export default ConnectedUsersButton
