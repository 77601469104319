import React from 'react'

import { EXECUTION_COMPONENT_TYPES } from '../../../utils/constants/execution/components'
import Translation from '../../../views/translations'

export const ITEM_LIST_FOR_STATISTIC = [
  {
    description: <Translation id={'signature.title'} />,
    type: EXECUTION_COMPONENT_TYPES.SIGNATURE
  },
  {
    description: <Translation id={'numerical.title'} />,
    type: EXECUTION_COMPONENT_TYPES.NUMERICAL
  },
  {
    description: <Translation id={'multicell.title'} />,
    type: EXECUTION_COMPONENT_TYPES.MULTICELL
  },
  {
    description: <Translation id={'checkbox.title'} />,
    type: EXECUTION_COMPONENT_TYPES.CHECKBOX
  },
  {
    description: <Translation id={'option.list.title'} />,
    type: EXECUTION_COMPONENT_TYPES.OPTION_LIST
  },
  {
    description: <Translation id={'formula.title'} />,
    type: EXECUTION_COMPONENT_TYPES.FORMULA
  },
  {
    description: <Translation id={'recorder.title'} />,
    type: EXECUTION_COMPONENT_TYPES.RECORDER
  },
  {
    description: <Translation id={'textarea.title'} />,
    type: EXECUTION_COMPONENT_TYPES.TEXTAREA
  },
  {
    description: <Translation id={'time.title'} />,
    type: EXECUTION_COMPONENT_TYPES.TIME
  },
  {
    description: <Translation id={'date.title'} />,
    type: EXECUTION_COMPONENT_TYPES.DATE
  }
]
