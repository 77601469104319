import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbData } from '../../common/write'

const tableName = TABLE_NAMES.PROCEDURES

export async function writeProcedures(procedures) {
  // serieName prop is needed to be indexed because serie data is an object
  // that can not be indexed. Only for local procedures in indexed db table
  const proceduresToSave = procedures.map((procedure) => ({
    ...procedure,
    serieName: procedure.serie?.name || ''
  }))
  return _writeLocalDbData({
    tableName,
    data: proceduresToSave,
    action: 'PUT'
  })
}
