import { writeFile } from '../../../localdb/files/write'
import { readFilesContent } from '../../../localdb/filesContent/read'
import { postSendFile } from '../../../services/files/http/post'
import { store } from '../../../store'
import { CONNECTION_STATUS } from '../../../utils/constants/connection'

export async function uploadUnsyncedFiles(filesInfo) {
  const promises = filesInfo.map(async (fileInfo) => {
    const fileContent = await readFilesContent([fileInfo.id])
    const connectionStatus = store.getState().userInterface.connectionStatus
    if (
      connectionStatus !== CONNECTION_STATUS.OFFLINE &&
      connectionStatus !== CONNECTION_STATUS.DISABLED
    ) {
      const content = fileContent[0].data
      const { data } = await postSendFile(
        content.data ?? content,
        fileInfo.type,
        fileInfo.id.toUpperCase()
      )
      await writeFile([{ ...data, ...{ sync: true } }])
    }
  })
  await Promise.all(promises)
}
