import { useMemo } from 'react'

import { calculateResult } from '../../../utils/helpers/formulas'

export default function useReadoutNumerical({
  isPoor,
  maxRange,
  minRange,
  validationFormula,
  value,
  inputValue
}) {
  const isValid = (val) => {
    return !isNaN(val)
  }

  const error = useMemo(() => {
    if (value !== '') {
      if (isPoor) {
        return { id: 'signalQualityPoor' }
      }
      if (value < minRange || value > maxRange) {
        return {
          id: 'outOfRange',
          params: { min: minRange, max: maxRange }
        }
      } else if (validationFormula) {
        return (
          !calculateResult({
            formula: validationFormula,
            variableValuesMap: {
              A: value
            }
          }) && {
            id: 'validationNotPassed',
            params: { validationRule: validationFormula }
          }
        )
      }
    } else if (isNaN(inputValue)) {
      return {
        id: 'invalidNumber'
      }
    }
  }, [isPoor, maxRange, minRange, validationFormula, value, inputValue])

  return {
    error,
    isValid
  }
}
