import { createSlice } from '@reduxjs/toolkit'

import {
  deleteFromLocaldbQueue,
  updateLocaldbQueueEntity,
  updateSyncedAt
} from '../../helpers'

export const executionConditionalsSlice = createSlice({
  name: 'executionConditionals',
  initialState: {
    conditionals: [],
    localdbQueue: []
  },
  reducers: {
    setConditionals: (state, action) => {
      const { conditionals } = action.payload
      state.conditionals = conditionals
    },
    setConditional: (state, action) => {
      const { id, forcedValue, now } = action.payload
      const conditional = state.conditionals.find((_c) => _c.id === id)

      if (conditional) {
        conditional.forcedValue = forcedValue
        conditional.updatedAt = now
      }
    },
    setSyncedAt: (state, action) => {
      const { entities, syncedAt } = action.payload
      updateSyncedAt(state.conditionals, entities, syncedAt)
    },
    updateLocaldbQueue: (state, action) => {
      const { conditional, resetQueue } = action.payload
      updateLocaldbQueueEntity(state, conditional, resetQueue)
    },
    removeLocaldbQueue: (state, action) => {
      const { entitiesToDelete } = action.payload
      deleteFromLocaldbQueue(state, entitiesToDelete)
    }
  }
})

export const {
  setConditionals,
  setConditional,
  removeLocaldbQueue,
  setSyncedAt,
  updateLocaldbQueue
} = executionConditionalsSlice.actions

export default executionConditionalsSlice.reducer
