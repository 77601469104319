import React from 'react'

import CloseIcon from '../../../components/icons/close-icon'
import { IconContainer } from '../../../containers/icon'

const CloseButtonView = (props) => (
  <IconContainer {...props} style={{ cursor: 'pointer' }} icon={CloseIcon} />
)

export default CloseButtonView
