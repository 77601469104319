import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Checkbox from '../../../components/checkbox'
import RadioButton from '../../../components/radio-buttons'

function isChecked(option, checkedOptions, multiple) {
  if (multiple) {
    return checkedOptions?.includes(option.key)
  }
  return checkedOptions && option.key === checkedOptions
}

function toggleCheck(option, checkedOptions, multiple, onChange) {
  if (multiple) {
    if (!checkedOptions) {
      onChange([option.key])
    } else if (checkedOptions.includes(option.key)) {
      onChange(checkedOptions.filter((o) => o !== option.key))
    } else {
      onChange([...checkedOptions, option.key])
    }
  } else {
    onChange(option.key)
  }
}

// 'options' debe ser un array de objetos con las propiedades 'key' y 'label'
const RadioGroup = ({
  multiple,
  value: checkedOptions,
  options,
  onChange,
  disabled,
  optionsPerRow = 3
}) => {
  const Component = multiple ? Checkbox : RadioButton
  const colSpan = 24 / optionsPerRow
  return (
    <Row gutter={[16, 16]}>
      {options.map((option) => {
        return (
          <Col span={colSpan} key={option.key}>
            <CheckboxContainer>
              <Component
                label={option.label}
                value={isChecked(option, checkedOptions, multiple)}
                onChange={() =>
                  toggleCheck(option, checkedOptions, multiple, onChange)
                }
                disabled={disabled}
              />
            </CheckboxContainer>
          </Col>
        )
      })}
    </Row>
  )
}

const CheckboxContainer = styled.div`
  display: flex;
`

export default RadioGroup
