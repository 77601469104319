import { Select } from 'antd'
import React from 'react'
import styled from 'styled-components'

import SelectPickerContainer from '../../../../containers/select-picker'
import useHistoricalComponent from '../../../../hooks/use-historical-component'
import { colorDisabled, colorEnabled } from '../../../../utils/constants/styles'
import { getWidthInPixels } from '../../../../utils/helpers/styles'
import Translation from '../../../translations'

const { Option } = Select

const DropDown = ({
  component,
  executionComponent,
  onComponentChange,
  disabled,
  className,
  procedure,
  isHistoricalMode
}) => {
  const { procedureComponentId } = component
  const procedureComponent = procedure?.components?.find(
    (comp) => comp.id === component.procedureComponentId
  )
  const {
    size,
    selectionType,
    list = [],
    showHistorical
  } = procedureComponent?.options ?? {}

  const {
    historicalModeEnabled,
    historicalComponentActive,
    onClickHistorical
  } = useHistoricalComponent({
    isHistoricalMode,
    showHistorical,
    component
  })

  const isMultiple = selectionType === 'MULTIPLE'
  const selectOptions = list.map((item, index) => (
    <Option key={index} title={item.option ?? item} value={item.option ?? item}>
      {item.option ?? item}
    </Option>
  ))

  return (
    <SelectPickerContainer
      data-background-color='#f5f5f5'
      data-color={disabled ? colorDisabled : colorEnabled}
    >
      <DropDownList
        // El text-indent está para sustituir al que viene del word
        style={{
          width: size ? getWidthInPixels(size, 25) : '175px',
          textIndent: 0
        }}
        mode={isMultiple ? 'multiple' : undefined}
        value={
          executionComponent?.value
            ? executionComponent.value
            : isMultiple
              ? []
              : undefined
        }
        placeholder={<Translation id='select' />}
        title={executionComponent?.value}
        disabled={disabled}
        data-disabled={disabled}
        data-active={historicalComponentActive}
        className={
          historicalModeEnabled
            ? `${className} historical-component`
            : `${className} recorder-list`
        }
        onClick={historicalModeEnabled ? onClickHistorical : undefined}
        onChange={(value) => {
          onComponentChange({
            id: procedureComponentId,
            value
          })
        }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {selectOptions}
      </DropDownList>
    </SelectPickerContainer>
  )
}

const DropDownList = styled(Select)`
  & > .ant-select-selector {
    ${({ disabled }) =>
      disabled
        ? `color:` + colorDisabled + `!important;`
        : `color:` + colorEnabled + `!important;`}

    & .ant-select-selection-item {
      ${({ disabled }) =>
        disabled
          ? `color:` + colorDisabled + `!important;`
          : `color:` + colorEnabled + `!important;`}
    }
  }
`

export default DropDown
