import { Progress } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import UploadProgressContainer from '../../containers/upload-progress'
import useAttachmentButton from '../../hooks/use-attachment-button'
import useExecutionDrawer from '../../hooks/use-execution-drawer'
import { uploadVideos } from '../../services/files'
import { addMediaToExecutionCommentAction } from '../../store/executionComments/actions'
import { addMediaToStepCommentAction } from '../../store/executionSteps/actions'
import {
  cancelUploadByUser,
  removeMediaUploading
} from '../../store/media-uploading/slice'
import { getProcedure } from '../../store/procedure/selectors'
import { getConnectionStatus } from '../../store/userInterface/selectors'
import { setCurrentStepDrawerComments } from '../../store/userInterface/slice'
import { CONNECTION_STATUS } from '../../utils/constants/connection'
import { DRAWERS } from '../../utils/constants/drawer'
import { MediaUploadingCancelFuncMap } from '../../utils/constants/media-upload'
import { getAllowedExtensionsStepComments } from '../../utils/helpers/files'
import { treeFindById } from '../../utils/helpers/steps'
import CloseButtonView from '../buttons/close'
import EyeButtonView from '../buttons/eye'
import RechargeIconButtonView from '../buttons/recharge'
import CameraIconView from '../icons/camera'
import CheckIconView from '../icons/check'
import CloseFilledView from '../icons/close-filled'
import ConfirmModal from '../modal/confirm-modal'
import Translation from '../translations'

import {
  getErrorContainerWidth,
  getFilenameContainerWidth,
  getFilenameWidth,
  getInfoContainerWidth,
  getPercentageContainerWidth,
  getProgressContainerWidth
} from './helpers'

function UploadProgress({
  media,
  isNotification = false,
  size = 'medium',
  onClickEyeAction,
  style
}) {
  const [showConfirmRetry, setShowConfirmRetry] = useState(false)
  const [showConfirmCancel, setShowConfirmCancel] = useState(false)
  const connectionStatus = useSelector(getConnectionStatus)
  const dispatch = useDispatch()

  const procedure = useSelector(getProcedure)

  const { openDrawer } = useExecutionDrawer()

  const {
    name,
    progress,
    completed,
    error,
    cancelByUser,
    commentId,
    executionId,
    stepId,
    stepBmk,
    stepIndex
  } = media

  const [ref, AttachmentButton] = useAttachmentButton({
    disabled: connectionStatus !== CONNECTION_STATUS.ONLINE
  })

  const onClickEyeIcon = () => {
    if (!stepId) {
      openDrawer(DRAWERS.EXECUTION_COMMENTS)
      onClickEyeAction()
      return
    }
    const step = treeFindById(procedure?.index.children, stepBmk)

    dispatch(setCurrentStepDrawerComments(step))
    openDrawer(DRAWERS.STEPS_COMMENTS)
    onClickEyeAction()
  }

  const onRetryUpload = (video) => {
    setShowConfirmRetry(false)
    const addMediaPayload = {
      commentId,
      stepId,
      executionId
    }

    uploadVideos(
      [video],
      commentId,
      stepId,
      stepBmk,
      stepIndex,
      executionId,
      addMediaPayload,
      !stepId ? addMediaToExecutionCommentAction : addMediaToStepCommentAction,
      dispatch,
      true
    )
  }

  const onClickRetryUploadButton = () => {
    setShowConfirmRetry(true)
  }

  const onConfirmRetryUpload = () => {
    ref?.current?.click()
  }

  const onCancelRetryUpload = () => {
    dispatch(removeMediaUploading({ id: media.id }))
    setShowConfirmRetry(false)
  }

  const onClickCancelUploadButton = () => {
    setShowConfirmCancel(true)
  }

  const onConfirmCancelUpload = () => {
    const cancelFunc = MediaUploadingCancelFuncMap.get(media.id)
    if (cancelFunc) {
      cancelFunc.cancel(`Upload cancelled by user for media ${name}}`)
    }
    setShowConfirmCancel(false)

    setTimeout(() => {
      dispatch(removeMediaUploading({ id: media.id }))
    }, 2000)

    dispatch(cancelUploadByUser({ id: media.id }))
  }

  return (
    <>
      <UploadProgressContainer style={style}>
        <InfoContainer data-width={getInfoContainerWidth(size)}>
          <FilenameContainer data-width={getFilenameContainerWidth(size)}>
            <CameraIconView size={20} />
            <Filename data-width={getFilenameWidth(size)}>{name}</Filename>
          </FilenameContainer>
          <PercentageContainer data-width={getPercentageContainerWidth(size)}>
            <Percentage>{progress}%</Percentage>
            {error && !cancelByUser ? (
              <ErrorContainer data-width={getErrorContainerWidth(size)}>
                <CloseFilledView color='#ED1C24' size={16} />
                <ErrorText>
                  <Translation id='failed' />
                </ErrorText>
              </ErrorContainer>
            ) : (
              <>
                <ProgressContainer data-width={getProgressContainerWidth(size)}>
                  <Progress
                    percent={progress}
                    size='small'
                    status={progress !== 100 ? 'active' : 'success'}
                    showInfo={false}
                    strokeColor={cancelByUser ? '#ED1C24' : '#89D000'}
                  />
                </ProgressContainer>
                {completed ? <CheckIconView size={15} color='green' /> : null}
              </>
            )}
          </PercentageContainer>
        </InfoContainer>
        {progress !== 100 && !error && !isNotification ? (
          <IconContainer>
            <CloseButtonView onClick={onClickCancelUploadButton} />
          </IconContainer>
        ) : error && !cancelByUser && !isNotification ? (
          <AttachmentButton
            icon={RechargeIconButtonView}
            onChangeMedia={(e) => onRetryUpload(e.target.files[0])}
            acceptedTypes={getAllowedExtensionsStepComments(connectionStatus)}
            disabled={false}
            onClick={onClickRetryUploadButton}
          />
        ) : (
          <IconContainer>
            <EyeButtonView onClick={onClickEyeIcon} />
          </IconContainer>
        )}
      </UploadProgressContainer>

      <ConfirmModal
        id={'confirm-cancel-upload'}
        okButtonTextId={'confirm'}
        titleId={'media.upload.cancel.title'}
        textId={'media.upload.cancel.text'}
        textParams={{ name: media?.name }}
        open={showConfirmCancel}
        setOpen={setShowConfirmCancel}
        onOk={onConfirmCancelUpload}
        maskClosable
        cancellable
      />
      <ConfirmModal
        id={'confirm-retry-upload'}
        okButtonTextId={'retry'}
        cancelButtonTextId={'media.upload.cancel.title'}
        titleId={'media.upload.retry.title'}
        textId={'media.upload.retry.text'}
        textParams={{ name: media?.name }}
        open={showConfirmRetry}
        setOpen={setShowConfirmRetry}
        onOk={onConfirmRetryUpload}
        onCancel={onCancelRetryUpload}
      />
    </>
  )
}

const InfoContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: ${({ 'data-width': width }) => width};
`

const FilenameContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;

  width: ${({ 'data-width': width }) => width};
`

const PercentageContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: ${({ 'data-width': width }) => width};
`

const IconContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;

  width: 44px;
  height: 44px;
`

const ErrorContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: ${({ 'data-width': width }) => width};
  height: 16px;
`

const Percentage = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #b8bab6;
`

const Filename = styled.span`
  width: ${({ 'data-width': width }) => width};

  font-weight: 500;
  font-size: 14px;

  color: #000000;

  word-break: break-all;
`

const ErrorText = styled.span`
  width: 51px;
  height: 16px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #ed1c24;
`

const ProgressContainer = styled.div`
  width: ${({ 'data-width': width }) => width};
`

export default UploadProgress
