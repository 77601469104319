import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'

import { LOG_GLOBAL_STATE_CHANGES } from '../utils/constants/config'

import executionReducer from './execution/slice'
import executionConditionalsReducer from './execution-conditionals/slice'
import executionCommentsReducer from './executionComments/slice'
import executionComponentsReducer from './executionComponents/slice'
import executionManeuversReducer from './executionManeuvers/slice'
import executionStepsReducer from './executionSteps/slice'
import executionWarningsReducer from './executionWarnings/slice'
import historicalValuesReducer from './historical-values/slice'
import mediaUploadingReducer from './media-uploading/slice'
import mergeExecutionReducer from './mergeExecution/slice'
import { executionSaveMiddleware } from './middleware/executionSaveMiddleware'
import { sharedExecutionMiddleware } from './middleware/sharedExecutionMiddleware'
import procedureReducer from './procedure/slice'
import sharedExecutionReducer from './sharedExecution/slice'
import socketReadoutSlice from './socket/readout/slice'
import userManagementReducer from './user-management/slice'
import userInterfaceReducer from './userInterface/slice'

export const store = configureStore({
  reducer: {
    procedure: procedureReducer,
    execution: executionReducer,
    executionComponents: executionComponentsReducer,
    executionSteps: executionStepsReducer,
    executionManeuvers: executionManeuversReducer,
    executionWarnings: executionWarningsReducer,
    executionComments: executionCommentsReducer,
    executionConditionals: executionConditionalsReducer,
    mergeExecution: mergeExecutionReducer,
    sharedExecution: sharedExecutionReducer,
    userInterface: userInterfaceReducer,
    mediaUploading: mediaUploadingReducer,
    socketReadout: socketReadoutSlice,
    historicalValues: historicalValuesReducer,
    userManagement: userManagementReducer
  },
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware()

    if (LOG_GLOBAL_STATE_CHANGES) {
      middleware = middleware.concat(logger)
    }

    middleware = middleware.concat(executionSaveMiddleware)
    middleware = middleware.concat(sharedExecutionMiddleware)

    return middleware
  }
})
