import { createSlice } from '@reduxjs/toolkit'

import { addToLocaldbQueue, deleteFromLocaldbQueue } from '../helpers'

export const executionStepsSlice = createSlice({
  name: 'executionSteps',
  initialState: {
    steps: null,
    lastSave: null,
    localdbQueue: []
  },
  reducers: {
    setExecutionSteps: (state, action) => {
      const { steps } = action.payload
      state.steps = steps
      state.lastSave = steps ? new Date(Date.now() + 1000).toString() : null
    },
    markStep: (state, act) => {
      const { stepId, userId, action, now } = act.payload
      const step = state.steps?.find((s) => s.stepId === stepId)

      if (!step) return

      step[action + 'At'] = now
      step.userId = userId
      delete step.user
      step.updatedAt = now
    },
    updateLocaldbQueue: (state, action) => {
      const { step, resetQueue } = action.payload

      if (resetQueue) {
        state.localdbQueue = []
      }

      if (!step) return

      if (Array.isArray(step)) {
        step.forEach((s) => addToLocaldbQueue(state, s))
        return
      }

      addToLocaldbQueue(state, step)
    },
    unmarkStep: (state, action) => {
      const { stepId, userId, now } = action.payload
      const step = state.steps?.find((s) => s.stepId === stepId)

      if (!step) return
      step.readAt = null
      step.completedAt = null
      step.userId = userId
      step.updatedAt = now
    },
    setStepAnnotation: (state, action) => {
      const { stepId, annotation, now } = action.payload
      const step = state.steps?.find((s) => s.stepId === stepId)

      if (!step) return
      step.annotation = annotation
      step.updatedAt = now
    },
    clearAnnotations: (state, action) => {
      const { now } = action.payload
      state.steps
        ?.filter((_step) => _step.annotation)
        .forEach((_step) => {
          _step.annotation = null
          _step.updatedAt = now
        })
    },
    addStepComment: (state, action) => {
      const { stepId, newComment, now } = action.payload
      const step = state.steps?.find((s) => s.stepId === stepId)

      if (!step) return

      step.comments?.push(newComment)
      step.updatedAt = now
    },
    addMediaToStepComment: (state, action) => {
      const { stepId, commentId, mediaId, now } = action.payload

      const step = state.steps.find((s) => s.id === stepId)
      if (!step) return
      const comment = step?.comments.find((c) => c.id === commentId)

      if (comment?.mediaIds.includes(mediaId)) return

      comment.mediaIds.push(mediaId)
      comment.updatedAt = now
      step.updatedAt = now
    },
    deleteMediaStepComment: (state, action) => {
      const { stepId, commentId, mediaId, now } = action.payload

      const step = state.steps.find((s) => s.id === stepId)
      if (!step) return

      const comment = step?.comments.find((c) => c.id === commentId)

      comment.mediaIds = comment.mediaIds.filter((m) => m !== mediaId)
      comment.updatedAt = now
      step.updatedAt = now
    },
    deleteStepComment: (state, action) => {
      const { stepId, commentId, now } = action.payload

      const step = state.steps.find((s) => s.stepId === stepId)
      if (!step) return
      const comment = step.comments.find((c) => c.id === commentId)

      comment.deletedAt = now
      step.updatedAt = now
    },
    editStepComment: (state, action) => {
      const { comment, stepId, now } = action.payload

      const step = state.steps.find((s) => s.stepId === stepId)
      if (!step) return

      const commentToUpdate = step.comments?.find((c) => c.id === comment.id)

      commentToUpdate.comment = comment.comment
      commentToUpdate.updatedAt = now
      step.updatedAt = now
    },
    setLastSave: (state, action) => {
      state.lastSave = action.payload || new Date().valueOf()
    },
    setSyncedAt: (state, action) => {
      const { entities, syncedAt } = action.payload

      state.steps
        ?.filter((s) => entities.some((e) => e.id === s.id))
        .forEach((s) => {
          s.syncedAt = syncedAt
        })
    },
    removeLocaldbQueue: (state, action) => {
      const { entitiesToDelete } = action.payload
      deleteFromLocaldbQueue(state, entitiesToDelete)
    }
  }
})

export const {
  updateLocaldbQueue,
  setExecutionSteps,
  markStep,
  unmarkStep,
  setStepAnnotation,
  clearAnnotations,
  addStepComment,
  addMediaToStepComment,
  deleteMediaStepComment,
  deleteStepComment,
  editStepComment,
  setLastSave,
  setSyncedAt,
  removeLocaldbQueue
} = executionStepsSlice.actions

export default executionStepsSlice.reducer
