import { getDatabase } from '../../..'
import { TABLE_NAMES } from '../../../../utils/constants/localdb'
import { _writeLocalDbDataNoCatch } from '../../../common/write'

const tableName = TABLE_NAMES.UNITS

export async function updatePlantName(plantId, plantName) {
  const db = getDatabase()

  const units = await db[tableName].where('plantId').equals(plantId).toArray()
  const updatedUnits = units.map((unit) => ({
    ...unit,
    plantName
  }))

  return _writeLocalDbDataNoCatch({
    tableName: TABLE_NAMES.UNITS,
    action: 'PUT',
    data: updatedUnits
  })
}
