import { getTranslation } from '../../../i18n/getTranslation'
import { addNewCustomerSetting } from '../../../services/customer-settings'
import { notificationMessage } from '../../../utils/helpers/notification-message'
import { notifyError } from '../../../utils/notifications'
import { validateCustomerSetting } from '../validation'

export async function addPlant(customerSetting, reload) {
  try {
    validateCustomerSetting(customerSetting)
    await addNewCustomerSetting(customerSetting)
    notificationMessage({
      message: getTranslation('plant.add.successMsg')
    })
    reload()
  } catch (error) {
    notifyError(error)
  }
}
