import React from 'react'

import { IconContainer } from '../../../containers/icon'

export const Print = (props) => {
  return <IconContainer {...props} icon={Icon} />
}

const Icon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 26 26'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='Symbols'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id='atom/icon/print'
        transform='translate(1.000000, 1.000000)'
        stroke={color}
        strokeWidth='1.5'
      >
        <g id='Group'>
          <path
            d='M4.84090909,17.5671212 L1.65909091,17.5671212 C0.78045608,17.5671212 0.0681818182,16.854847 0.0681818182,15.9762121 L0.0681818182,8.02166667 C0.0681818182,7.14303184 0.78045608,6.43075758 1.65909091,6.43075758 L22.3409091,6.43075758 C23.2195439,6.43075758 23.9318182,7.14303184 23.9318182,8.02166667 L23.9318182,15.9762121 C23.9318182,16.854847 23.2195439,17.5671212 22.3409091,17.5671212 L19.1590909,17.5671212'
            id='Path'
          ></path>
          <line
            x1='3.25'
            y1='9.61257576'
            x2='4.84090909'
            y2='9.61257576'
            id='Path'
          ></line>
          <polygon
            id='Path'
            points='4.84090909 12.7943939 19.1590909 12.7943939 19.1590909 23.9307576 4.84090909 23.9307576'
          ></polygon>
          <path
            d='M19.1590909,6.43075758 L4.84090909,6.43075758 L4.84090909,1.6580303 C4.84090909,0.779395474 5.55318335,0.0671212121 6.43181818,0.0671212121 L17.5681818,0.0671212121 C18.4468166,0.0671212121 19.1590909,0.779395474 19.1590909,1.6580303 L19.1590909,6.43075758 Z'
            id='Path'
          ></path>
          <polyline
            id='Path'
            points='8.02272727 20.7489394 9.61363636 18.3625758 11.2045455 20.7489394 13.5909091 15.9762121 15.9772727 20.7489394'
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
)

export default Print
