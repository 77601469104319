import React, { useState } from 'react'

import { writeExecutions } from '../../localdb/execution/write'
import { EXECUTION_STATUS } from '../../utils/constants/execution'
import { notificationMessage } from '../../utils/helpers/notification-message'
import Translation from '../../views/translations'

function useInterruptMassiveExecution(executions, action) {
  const [loading, setLoading] = useState(false)

  const interruptMassiveExecution = async () => {
    const executionsUpdated = executions.map((execution) => {
      return {
        ...execution,
        status: EXECUTION_STATUS.INTERRUPTED,
        syncedAt: null
      }
    })
    setLoading(true)
    await writeExecutions(executionsUpdated)

    notificationMessage({
      type: 'success',
      message: <Translation id='executionsInterrupted' />
    })

    // Delay action as is not inmediate for the user, which is better UX
    setTimeout(() => {
      setLoading(false)
      action()
    }, 2000)
  }

  return [interruptMassiveExecution, loading]
}

export default useInterruptMassiveExecution
