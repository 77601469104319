import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useGlobalProcedures } from '../../../../hooks/data/useGlobalProcedures'
import usePagination from '../../../../hooks/metadata/use-pagination'
import useSelectItemsTable from '../../../../hooks/use-select-items-table'
import { useFilters } from '../../../../hooks/useFilters'
import { getConnectionStatus } from '../../../../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../../../../utils/constants/connection'
import { COLUMN_TYPES } from '../../../../utils/constants/table'
import BasePage from '../../../../views/base-page'
import BaseTable from '../../../../views/tables/base-table'
import UnitTag from '../../../../views/tags/unit'
import Translation from '../../../../views/translations'
import { NoConnection } from '../../../NoConnection'

import { Filters } from './Filters'
import { Footer } from './Footer'
import { ProcedureFilterTags } from './ProcedureFilterTags'

export const ProceduresFiltersContext = React.createContext()

const columns = [
  {
    title: <Translation id='unit' />,
    dataIndex: 'unit',
    width: 70,
    render: (text) => <UnitTag unit={text} />
  },
  {
    title: <Translation id='series' />,
    dataIndex: 'serie.name',
    width: 70,
    sorter: true
  },
  {
    title: <Translation id='key' />,
    dataIndex: 'key',
    minWidth: 90,
    sorter: true
  },
  {
    title: <Translation id='title' />,
    dataIndex: 'title',
    minWidth: 100,
    sorter: true
  },
  {
    title: <Translation id='rev' />,
    dataIndex: 'rev',
    width: 70,
    sorter: true
  },
  {
    title: <Translation id='version' />,
    dataIndex: 'buildNumber',
    width: 90,
    sorter: true
  },
  {
    title: <Translation id='lastMod' />,
    dataIndex: 'updatedAt',
    width: 70,
    type: COLUMN_TYPES.DATE,
    sorter: true,
    defaultSortOrder: 'descend'
  },
  {
    title: <Translation id='status' />,
    dataIndex: 'status',
    render: (text) => <Translation id={text} />
  }
]

const selectProperties = ['id', ...columns.map((c) => c.dataIndex), 'serie.id']

export const ProcedureManagement = () => {
  const defaultSortColumn = columns.find((c) => c.defaultSortOrder)
  const [sorter, setSorter] = useState({
    field: defaultSortColumn?.dataIndex || 'updatedAt',
    order: defaultSortColumn?.defaultSortOrder || 'descend'
  })

  const [pagination, setPagination] = usePagination()
  const connectionStatus = useSelector(getConnectionStatus)
  const { selectedItems, onSelectChange, clearSelectedItems } =
    useSelectItemsTable()
  const { filters, updateFilters, resetFilters } = useFilters()

  const clearSelectedItemsRef = useRef(clearSelectedItems)
  const setPaginationRef = useRef(setPagination)

  useEffect(() => {
    setPaginationRef.current((prev) => ({ ...prev, current: 1 }))
    clearSelectedItemsRef.current()
  }, [filters])

  const {
    data,
    isValidating,
    mutate: refetch
  } = useGlobalProcedures({
    // Seleccionamos solo las propiedades que se muestran en la tabla
    select: selectProperties,
    skip: (pagination.current - 1) * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorter?.field,
    orderDirection: sorter?.order,
    ...filters
  })

  const { items, total } = data || {}

  const handleTableChange = (pagination, _, sorter) => {
    setPagination({ ...pagination, total })
    setSorter({ ...sorter })
  }

  return connectionStatus !== CONNECTION_STATUS.ONLINE &&
    location.pathname.includes('admin') ? (
    <NoConnection />
  ) : (
    <ProceduresFiltersContext.Provider
      value={{ filters, updateFilters, resetFilters }}
    >
      <BasePage
        title={<Translation id='manageProcedures' />}
        extraHeaderContent={<Filters />}
        subHeaderContent={
          Object.keys(filters).length > 0 ? <ProcedureFilterTags /> : undefined
        }
        totalElements={total}
        selectedElements={selectedItems.length}
        clearSelection={clearSelectedItems}
      >
        <BaseTable
          id='admin-procedures-table'
          columns={columns}
          dataSource={items}
          pagination={{ ...pagination, total }}
          loading={isValidating}
          onChange={handleTableChange}
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys: selectedItems.map((item) => item.id),
            onSelect: onSelectChange
          }}
        />
        <Footer
          selection={selectedItems}
          procedures={items}
          onChange={() => {
            refetch()
            clearSelectedItemsRef.current()
            setPagination({ ...pagination, current: 1 })
          }}
        />
      </BasePage>
    </ProceduresFiltersContext.Provider>
  )
}
