import { Tooltip as Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'

import { downloadFiles } from '../../../../../application/files/download'
import AddComment from '../../../../../components/buttons/add-comment'
import AlertCircle from '../../../../../components/icons/alert-circle'
import ExclamationCircle from '../../../../../components/icons/exclamation-circle'
import ActionsContainer from '../../../../../containers/add-comment/actions'
import ButtonContainer from '../../../../../containers/add-comment/button'
import AttachmentFilesWrapper from '../../../../../containers/attachments'
import ColorTag from '../../../../../containers/color-tag'
import CustomComment from '../../../../../containers/comment'
import CommentAuthor from '../../../../../containers/comment/author'
import CommentListContainer from '../../../../../containers/comment/list'
import SendArea from '../../../../../containers/comment/send-area'
import { ScrollListContainer } from '../../../../../containers/execution-view/scroll-list'
import MessageContainer from '../../../../../containers/message-container'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { getTranslation } from '../../../../../i18n/getTranslation'
import { getAllowedInsertStepComment } from '../../../../../store/execution/selectors'
import { getMediaUploading } from '../../../../../store/media-uploading/selectors'
import { getConnectionStatus } from '../../../../../store/userInterface/selectors'
import { COMMENT_TO_STEP_TYPES } from '../../../../../utils/constants/comment'
import {
  MAX_FILE_SIZE,
  SHOULD_CHECK_COMPLIANCIES
} from '../../../../../utils/constants/config'
import { CONNECTION_STATUS } from '../../../../../utils/constants/connection'
import {
  areFilesValid,
  getAllowedExtensionsStepComments,
  addHashToFiles
} from '../../../../../utils/helpers/files'
import { bytesToMegabytes } from '../../../../../utils/helpers/formulas'
import { notificationMessage } from '../../../../../utils/helpers/notification-message'
import AttachmentButton from '../../../../buttons/attachment'
import CommentsButtonStyle from '../../../../buttons/comment'
import CommentActions from '../../../../comments/actions'
import CommentArea from '../../../../comments/area'
import CommentContent from '../../../../comments/content'
import ListItem from '../../../../execution-view/list/item'
import ScrollList from '../../../../execution-view/list/scroll'
import AttachmentFile from '../../../../files/attachment'
import AttachmentIconView from '../../../../icons/attachment'
import AttachmentModal from '../../../../modal/attachment-modal'
import InfoModal from '../../../../modal/info-modal'
import Translation from '../../../../translations'

const StepComments = ({
  comments,
  addComment,
  deleteComment,
  editComment,
  isNonCompliantComment,
  setIsNonCompliant,
  componentType,
  deleteMediaFromComment
}) => {
  const [textAreaValue, setTextAreaValue] = useState(null)
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [fileTooLarge, setFileTooLarge] = useState(null)
  const [isCritical, setIsCritical] = useState(false)
  const [mediaToUpload, setMediaToUpload] = useState([])
  const [mediaHashes, setMediaHashes] = useState([])
  const [commentsMedia, setCommentsMedia] = useState()
  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false)
  const [attachmentModalMedia, setAttachmentModalMedia] = useState(false)
  const [commentEditing, setCommentEditing] = useState(null)

  const connectionStatus = useSelector(getConnectionStatus)

  const allowInsertComment = useSelector(getAllowedInsertStepComment)
  const mediaUploading = useSelector(getMediaUploading, shallowEqual)
  const dateTimeFormat = useTranslation('dateTimeFormat')

  useEffect(() => {
    setTextAreaValue(
      isNonCompliantComment
        ? getTranslation('nonCompliantInitialValue', {
            params: { componentType: componentType }
          })
        : null
    )
  }, [componentType, isNonCompliantComment])

  useEffect(() => {
    const loadCommentsMedia = async () => {
      const commentsMediaIds = comments
        ?.filter((c) => c.mediaIds)
        .map((c) => c.mediaIds)
        .flat()

      if (commentsMediaIds.length) {
        const media = await downloadFiles(commentsMediaIds)
        setCommentsMedia(media)
      }
    }

    loadCommentsMedia()
  }, [comments])

  const onChangeMedia = async (e) => {
    let inputFiles = e?.target?.files

    if (
      !areFilesValid(
        inputFiles,
        getAllowedExtensionsStepComments(connectionStatus)
      )
    ) {
      notificationMessage({
        type: 'error',
        message: getTranslation('error'),
        description: getTranslation(
          connectionStatus === CONNECTION_STATUS.ONLINE
            ? 'only_upload_images_or_videos'
            : 'only_upload_images'
        ),
        placement: 'bottomLeft'
      })
      return null
    }

    const fileTooLarge = Array.from(inputFiles).find(
      (file) => file.size > MAX_FILE_SIZE
    )

    if (fileTooLarge) {
      setOpenInfoModal(true)
      setFileTooLarge(fileTooLarge)
      return
    }

    /**
     * We transform the incoming files to an array of objects containing the file and the hash
     * in order to give an unique ID to the file and avoid duplicated attachments.
     */

    const hashedFiles = await addHashToFiles(inputFiles)

    hashedFiles.forEach((hashedFile) => {
      if (mediaHashes.length && mediaHashes.includes(hashedFile.hash)) {
        notificationMessage({
          type: 'error',
          message: getTranslation('error'),
          description: getTranslation('attachmentRepeated')
        })
        return
      } else {
        setMediaHashes((prevHashes) => [...prevHashes, hashedFile.hash])
        setMediaToUpload((prevMedia) => [...prevMedia, hashedFile])
      }
    })
  }

  const openModalMedia = (media) => {
    setAttachmentModalMedia(media)
    setAttachmentModalVisible(true)
  }

  const handleAddStepComment = () => {
    if (commentEditing) {
      editComment({
        ...commentEditing,
        comment: textAreaValue
      })
    } else {
      // Only sending the File type objects to the BE, removing hashes.
      const transformedMediaToUpload = mediaToUpload.map(({ file }) => file)
      addComment(
        textAreaValue,
        transformedMediaToUpload,
        isCritical,
        isNonCompliantComment
      )
    }
    isNonCompliantComment && setIsNonCompliant(false)
    setTextAreaValue(null)
    setMediaToUpload([])
    setMediaHashes([])
    setCommentEditing(null)
  }

  const handleRemoveUploadMedia = (mediaHashToDelete) => {
    setMediaToUpload((prevMedia) =>
      prevMedia.filter((media) => media.hash !== mediaHashToDelete)
    )
    setMediaHashes((prevHashes) =>
      prevHashes.filter((prevHash) => prevHash !== mediaHashToDelete)
    )
  }

  return (
    <>
      <AttachmentModal
        isModalVisible={attachmentModalVisible}
        setIsModalVisible={setAttachmentModalVisible}
        attachment={attachmentModalMedia}
      />
      <CommentListContainer id='step-comments-panel-flex'>
        <ScrollListContainer>
          <ScrollList
            id='step-comment-list'
            dataSource={comments}
            renderItem={(item, index) => (
              <ListItem id={`step-comment-${index}`} key={index}>
                <CustomComment
                  style={{ width: '100%' }}
                  author={
                    <CommentAuthor>
                      <span>{item.user.name}</span>
                      {item.type === COMMENT_TO_STEP_TYPES.CRITICAL && (
                        <AlertCircle color='#ED1C24' />
                      )}
                    </CommentAuthor>
                  }
                  content={
                    <CommentContent
                      item={item}
                      commentsMedia={commentsMedia}
                      mediaUploading={mediaUploading}
                      openModalMedia={openModalMedia}
                      deleteMediaFromComment={deleteMediaFromComment}
                    />
                  }
                  datetime={
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'baseline'
                      }}
                    >
                      <Tooltip
                        title={moment(new Date(item.createdAt)).format(
                          dateTimeFormat
                        )}
                      >
                        <span>
                          {moment(new Date(item.createdAt)).fromNow()}
                        </span>
                      </Tooltip>

                      {item.isNonCompliantComment && (
                        <ColorTag color='#EFB410' textColor='black'>
                          <Translation id='nonCompliance' />
                        </ColorTag>
                      )}

                      {allowInsertComment && (
                        <CommentActions
                          item={item}
                          commentEditing={commentEditing}
                          deleteComment={() =>
                            deleteComment(item.id, item.mediaIds)
                          }
                          setCommentEditing={setCommentEditing}
                          setTextAreaValue={setTextAreaValue}
                          canEdit
                          canDelete={
                            mediaUploading.filter(
                              (media) =>
                                media.commentId === item.id &&
                                (media.progress < 100 || media.error)
                            ).length === 0
                          }
                        />
                      )}
                    </span>
                  }
                />
              </ListItem>
            )}
          />
          {isNonCompliantComment &&
            (SHOULD_CHECK_COMPLIANCIES ? (
              <MessageContainer type='warning'>
                <Translation id='requiredNonCompliantComment' />
              </MessageContainer>
            ) : (
              <MessageContainer>
                <Translation id='recommendedNonCompliantComment' />
              </MessageContainer>
            ))}
        </ScrollListContainer>
        {allowInsertComment && (
          <>
            <AttachmentFilesWrapper>
              {mediaToUpload.map((attachment, index) => {
                return (
                  <AttachmentFile
                    key={index}
                    attachment={attachment}
                    removeMediaToAttach={handleRemoveUploadMedia}
                    openModalMedia={openModalMedia}
                  />
                )
              })}
            </AttachmentFilesWrapper>
            <CommentArea
              id='comment-area'
              rows={4}
              value={textAreaValue}
              onChange={({ target: { value } }) => setTextAreaValue(value)}
            />

            <SendArea>
              <ActionsContainer>
                <AttachmentButton
                  icon={AttachmentIconView}
                  onChangeMedia={onChangeMedia}
                  acceptedTypes={getAllowedExtensionsStepComments(
                    connectionStatus
                  )}
                  disabled={commentEditing}
                />

                <CriticalButton
                  id='critical-button'
                  onClick={() => setIsCritical(!isCritical)}
                  disabled={commentEditing}
                >
                  <ExclamationCircle
                    color={isCritical ? '#ED1C24' : '#000000'}
                  />
                </CriticalButton>
              </ActionsContainer>

              <ButtonContainer>
                <Tooltip
                  title={
                    !textAreaValue ? (
                      <Translation id='button.add.comment.disabled' />
                    ) : null
                  }
                >
                  <AddComment
                    id='add-comment-button'
                    disabled={!textAreaValue}
                    onClick={handleAddStepComment}
                    size='large'
                    data-critical={isCritical}
                  >
                    {commentEditing ? (
                      <Translation id='editComment' />
                    ) : (
                      <Translation id='addComment' />
                    )}
                  </AddComment>
                </Tooltip>
              </ButtonContainer>
            </SendArea>
          </>
        )}
      </CommentListContainer>
      <InfoModal
        id='file-too-large-modal'
        okButtonTextId='okText'
        textId='media.size.too.large.text'
        titleId='media.size.too.large.title'
        open={openInfoModal}
        setOpen={setOpenInfoModal}
        textParams={{
          name: fileTooLarge?.name,
          fileSize: bytesToMegabytes(fileTooLarge?.size ?? 0),
          maxFileSize: bytesToMegabytes(MAX_FILE_SIZE)
        }}
      />
    </>
  )
}

const CriticalButton = styled(CommentsButtonStyle)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`

export default StepComments
