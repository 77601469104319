import { createSlice } from '@reduxjs/toolkit'

export const socketReadoutSlice = createSlice({
  name: 'socketReadout',
  initialState: {
    isReadoutSubscribed: false,
    isLiveData: false
  },
  reducers: {
    setIsReadoutSubscribed: (state, action) => {
      state.isReadoutSubscribed = action.payload
    },
    setIsLiveData: (state, action) => {
      state.isLiveData = action.payload
    }
  }
})

export const { setIsReadoutSubscribed, setIsLiveData } =
  socketReadoutSlice.actions

export default socketReadoutSlice.reducer
