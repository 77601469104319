import React from 'react'
import styled from 'styled-components'

import MediaUploadingContainer from '../../../containers/media-uploading'
import UploadProgress from '../../../containers/upload-progress'
import { COMMENT_TO_PROCEDURE_TYPES } from '../../../utils/constants/comment'
import AttachmentFile from '../../files/attachment'
import Translation from '../../translations'

const CommentContent = ({
  item,
  commentsMedia,
  mediaUploading,
  openModalMedia,
  deleteMediaFromComment,
  executionId
}) => {
  const getCommentTypeText = (item) => {
    const type = COMMENT_TO_PROCEDURE_TYPES[item.type]

    return type?.name ?? ''
  }

  return (
    <>
      <CommentText>{item.comment}</CommentText>
      <ItalicTitle>
        <Translation id={getCommentTypeText(item)} />
      </ItalicTitle>

      {item?.mediaIds?.map((id, index) => {
        const media = commentsMedia?.find((media) => media.id === id)
        return (
          <AttachmentFile
            attachment={media}
            preview={true}
            key={index}
            openModalMedia={openModalMedia}
            deleteMediaFromComment={(mediaId) =>
              deleteMediaFromComment(item.id, mediaId, item.stepId)
            }
          />
        )
      })}
      <MediaUploadingContainer>
        {mediaUploading
          .filter(
            (media) =>
              media.executionId === executionId &&
              (media.progress < 100 || media.error) &&
              media.commentId === item.id
          )
          .map((media, index) => {
            return (
              <UploadProgress key={`${index}-media-uploading`} media={media} />
            )
          })}
      </MediaUploadingContainer>
    </>
  )
}

export default CommentContent

const CommentText = styled.p`
  margin-bottom: 15px !important;
  font-size: medium;
`

const ItalicTitle = styled.p`
  margin-top: 2px;
  margin-left: 15px;
  text-align: left;
  font-style: italic;
  font-size: small;
`
