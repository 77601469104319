import React from 'react'
import { useHistory } from 'react-router-dom'

import BlankButton from '../../../components/buttons/blank-button'

const LinkButton = React.forwardRef(({ to, onClick, ...props }, ref) => {
  const history = useHistory()

  return (
    <BlankButton
      {...props}
      onClick={(event) => {
        onClick && onClick(event)
        history.push(to)
      }}
      ref={ref}
    />
  )
})

export default LinkButton
