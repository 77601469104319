export const extractHtmlText = (html) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const nodes = Array.from(doc.querySelectorAll('*'))

  return nodes
    .map((node) => node.textContent.trim().toLowerCase())
    .filter(Boolean)
    .join('\n')
}

export const searchHtmlText = (items, search) => {
  const query = search.toLowerCase()

  return items.filter((item) => item.text.includes(query))
}
