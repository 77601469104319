import React from 'react'
import styled from 'styled-components'

import DocumentItem from './item'

const Documents = ({ files }) => {
  if (!files) {
    return null
  }

  return (
    <div>
      {files &&
        files.map((f, rowIndex) => {
          return <FileItem key={f.id} file={f} rowIndex={rowIndex} />
        })}
    </div>
  )
}

const FileItem = styled(DocumentItem)`
  cursor: pointer;
`

export default Documents
