import { Dropdown } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

import BurgerMenu from '../../components/icons/burger-menu'
import { IconContainer } from '../../containers/icon'
import { getIsLiveData } from '../../store/socket/readout/selectors'
import StepMenuButton from '../procedure/steps/menu/button'
import Translation from '../translations'

const ReadoutActionsMenu = ({ setEditReadout, setLockReadout }) => {
  const isLiveData = useSelector(getIsLiveData)
  const items = [
    {
      key: 'readout-edit-mode',
      label: <Translation id='readout.menu.editMode.text' />
    },
    {
      key: 'readout-blockValue-mode',
      label: <Translation id='readout.menu.lockValueMode.text' />,
      disabled: !isLiveData
    }
  ]

  const onClick = ({ key }) => {
    if (key === 'readout-edit-mode') setEditReadout(true)
    else if (key === 'readout-blockValue-mode') setLockReadout(true)
  }

  return (
    <Dropdown menu={{ items, onClick }}>
      <StepMenuButton>
        <IconContainer icon={BurgerMenu} />
      </StepMenuButton>
    </Dropdown>
  )
}

export default ReadoutActionsMenu
