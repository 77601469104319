import { useState } from 'react'

export default function useDeletePopover({ elementId, handleDelete }) {
  const [deletePopover, setDeletePopover] = useState(false)

  const openDeletePopover = () => setDeletePopover(true)
  const closeDeletePopover = () => setDeletePopover(false)
  const handleDeleteElement = () => handleDelete(elementId)

  return {
    deletePopover,
    openDeletePopover,
    closeDeletePopover,
    handleDeleteElement
  }
}
