import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { setStepAnnotationAction } from '../../store/executionSteps/actions'
import { setIsWorking } from '../../store/userInterface/slice'

export default function useAnnotation({
  annotation,
  stepId,
  isExecutionShared,
  isCurrentStep,
  isExecutionRunning
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isAnnotationVisible, setIsAnnotationVisible] = useState(!!annotation)
  const dispatch = useDispatch()

  const annotationRef = useRef(null)

  useEffect(() => {
    if (isAnnotationVisible && !isExecutionShared) annotationRef.current.focus()
  }, [isAnnotationVisible, isExecutionShared])

  useEffect(() => {
    if (!isCurrentStep) setIsExpanded(false)
  }, [isCurrentStep])

  async function onChangeAnnotation(text) {
    setIsExpanded(false)
    if (!text) setIsAnnotationVisible(false)
    if (!isCurrentStep || !isExecutionRunning) return
    dispatch(setIsWorking(true))
    dispatch(setStepAnnotationAction({ stepId, annotation: text }))
  }

  return {
    isExpanded,
    isAnnotationVisible,
    annotationRef,
    setIsAnnotationVisible,
    setIsExpanded,
    onChangeAnnotation
  }
}
