import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'

const tableName = TABLE_NAMES.PLACEKEEPING_HISTORY

/**
 * Reads the placekeeping history from the Local DB for a specific execution
 * @param {String} executionId
 * @returns an array storing the execution history steps.
 */
export async function readPlacekeepingHistory(executionId) {
  const db = getDatabase()

  return db[tableName].where('executionId').equals(executionId).toArray()
}
