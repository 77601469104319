import { getDatabase } from '../..'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { executionChildrenSyncQuery } from '../../common/sync'

const tableName = TABLE_NAMES.EXECUTION_COMPONENTS

export const executionComponentSyncQuery = async () => {
  return executionChildrenSyncQuery(tableName)
}

export async function readExecutionComponents(executionId) {
  const db = getDatabase()

  const executionComponents = await db[tableName]
    .where({
      executionId
    })
    .toArray()

  return executionComponents
}
