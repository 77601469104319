import { useCallback, useMemo, useState } from 'react'

import { store } from '../store'

function useMediaUploadingModal() {
  const [open, setOpen] = useState(false)
  const mediaUploading = store.getState().mediaUploading

  const finished = useMemo(
    () =>
      mediaUploading.uploading.filter((item) => item.completed && !item.error),
    [mediaUploading]
  )
  const withError = useMemo(
    () => mediaUploading.uploading.filter((item) => item.error),
    [mediaUploading]
  )
  const allUploadFinished = useMemo(
    () =>
      finished.length + withError.length === mediaUploading.uploading.length,
    [finished, withError, mediaUploading]
  )

  const shouldOpenModal = useCallback(() => {
    if (!allUploadFinished) {
      setOpen(true)
      return true
    }
    return false
  }, [allUploadFinished])

  return [open, setOpen, shouldOpenModal]
}

export default useMediaUploadingModal
