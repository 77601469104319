import React from 'react'
import styled from 'styled-components'

const ConditionalStep = ({ step, closeDrawer, onSelectStep }) => {
  const onScrollToStep = () => {
    closeDrawer()
    onSelectStep([step.stepId])
  }

  return (
    <Container onClick={onScrollToStep}>
      <Text>
        <StepIndex>{step.info.stepIndex}</StepIndex> {step.info.title}
      </Text>
    </Container>
  )
}

const Container = styled.article`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
  color: #333;

  cursor: pointer;

  :hover {
    color: #6f5b82;
  }
`

const StepIndex = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const Text = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export default ConditionalStep
