import { useLiveQuery } from 'dexie-react-hooks'

import { getDatabase } from '../localdb'

const DEFAULT_VALUE = 'LOADING'

export const useDexieTable = ({ tableName, findFunction, disabled }) => {
  const db = getDatabase()

  const result = useLiveQuery(
    async () => {
      if (!db || disabled) {
        return DEFAULT_VALUE
      }

      const table = db.table(tableName)

      const result = await (findFunction
        ? findFunction(table)
        : table.toArray())

      return result
    },
    [db, findFunction, tableName, disabled],
    DEFAULT_VALUE
  )

  return [
    result === DEFAULT_VALUE ? null : result,
    !disabled && result === DEFAULT_VALUE
  ]
}
