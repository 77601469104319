import moment from 'moment'

import { getTranslation } from '../../../../i18n/getTranslation'
import {
  getApproved,
  getDate,
  getExecutionGoal,
  getFinished,
  getInternalCode,
  getMergedSection,
  getProcedureInfo,
  getReviewed
} from '../get-info'

export function buildHeader(procedure) {
  return `

      <table class="header"  width="80%" align="center" border="0" style="font-size: 8px">
        <tr>
          <td align="left">
            ${procedure.serie.name} ${procedure.unit}
          </td>
          <td align="center">
            ${procedure.key}   Rev.${procedure.rev}
          </td>
          <td align="right">
              ${moment(new Date()).format(getTranslation('dateFormatShort'))}
            ${moment(new Date()).format(getTranslation('timeFormat'))}
          </td>
        </tr>
      </table>
`
}

export function buildFooter(procedure, execution) {
  return `
      <table class="footer" width="80%" align="center" border="0" cellspacing="0" cellpadding="0" style="font-size: 8px">
        <tr>
          <td align="left">
          ${procedure.key}_REV${procedure.rev}-${moment(
    execution?.timestampable?.createdAt
  ).format(getTranslation('executionCodeDateTimeFormat'))} 
          </td>
        
          <td align="right">
          <span class='pageNumber'></span>/<span class='totalPages'></span>
          </td>
        </tr>
      </table>
`
}

// Add the "Execution information" page to the document
export function buildCoverPage(procedure, execution) {
  return `
    <div id="execution-info">
      <div class="MainTitle"> ${getTranslation('executionInformation')} </div>
      
      <div class="Title">${getTranslation('procedure')}: </div>
      <div class="Info">${getProcedureInfo(procedure)}</div>
      <div class="SectionSeparator"></div>

      <div class="Title">${getTranslation('execution.plant.unit')}: </div>
      <div class="Info">${
        execution.executedFor
          ? execution.executedFor
          : getTranslation('fieldNotSet')
      }</div>
      
      <div class="SectionSeparator"></div>
    
      <div class="Title">${getTranslation('executionDate')}:</div>
      <div class="Info">
        <span class="Info"> ${getTranslation('start')}:</span>
        <span> ${getDate(
          execution?.createdAt,
          getTranslation('dateTimeFormatShort')
        )} 
        </span>
      </div>
        
      <div class="Info">
        <span class="Info">${getTranslation('final')}:</span>
        <span> ${getDate(
          execution?.finishedAt,
          getTranslation('dateTimeFormatShort')
        )}
        </span>
      </div>
      <div class="SectionSeparator"></div>
      
      <div class="Title">${getTranslation('executionCode')}:</div>
      <div class="Info"> ${getInternalCode(procedure, execution)}</div>
      <div class="SectionSeparator"></div>
                  
      <div class="Title"> ${getTranslation('internalCode')}:</div>          
      <div class="Info"> ${execution?.id} </div>
      <div class="SectionSeparator"></div>
            
      <div class="Title">${getTranslation('executionGoal')}: </div>          
      <pre class="Comments"> ${getExecutionGoal(execution)} </pre>            
      <div class="SectionSeparator"></div>

      ${getMergedSection(execution)}
                  
      <div class="Title"> ${getTranslation('executionStatus')}:</div>
      <div class="StatusInfo"> ${getTranslation(execution?.status)} </div>
      <div class="SectionSeparator"></div>
                    
      <hr>
                
      ${getFinished(execution)}
      <hr>

      ${getReviewed(execution)}
          
      <hr>
            
      ${getApproved(execution)}
          
      <hr>

    </div>
   `
}
