import { isEqual } from 'lodash'
import React, { useCallback, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import StepContext from '../../../../context/step'
import useExecutionDrawer from '../../../../hooks/use-execution-drawer'
import { changeComponentValue } from '../../../../store/executionComponents/actions'
import { getExecutionComponent } from '../../../../store/executionComponents/selectors'
import {
  setCurrentStepDrawerComments,
  setIsWorking
} from '../../../../store/userInterface/slice'
import { DRAWERS } from '../../../../utils/constants/drawer'
import { EXECUTION_COMPONENT_TYPES } from '../../../../utils/constants/execution/components'
import { checkComponentMeetsCriteria } from '../../../../utils/helpers/components-criteria'
import { createPortal } from '../../../../utils/helpers/dom-handlers'
import { treeFindById } from '../../../../utils/helpers/steps'
import { erro } from '../../../../utils/logger'
import Checkbox from '../checkbox'
import DateInput from '../date-input'
import DropDown from '../dropdown'
import FileReference from '../file-reference'
import Formula from '../formula'
import Input from '../input'
import Jump from '../jump'
import ProcedureReference from '../procedure-reference'
import RadioCellGroup from '../radio-cell-group'
import RangeInput from '../range-input'
import ReadoutRTNumerical from '../readout-rt-numerical'
import ReadoutRTRecorder from '../readout-rt-recorder'
import Signature from '../signature'
import TextArea from '../textarea'
import TimeInput from '../time-input'

const ProcedureComponentPortal = styled(
  ({
    component: c,
    disabled,
    anySignatureIsFinal,
    procedure,
    stepId,
    className,
    onSelectStep
  }) => {
    const dispatch = useDispatch()

    const { isReadoutEditMode, isReadoutLockMode, historicalModeStep } =
      useContext(StepContext)

    const executionComponent = useSelector((state) =>
      getExecutionComponent(state, c.procedureComponentId)
    )
    const {
      openDrawer,
      setIsNonCompliantCommentFn,
      setCurrentComponentTypeFn
    } = useExecutionDrawer()

    const step = useMemo(
      () => treeFindById(procedure.index.children, stepId),
      [procedure.index.children, stepId]
    )
    const componentType = c?.type
    const procedureComponentId = c?.procedureComponentId

    const onComponentChange = useCallback(
      ({ id, value, additionalValues, status }) => {
        const doesNotMeet = checkComponentMeetsCriteria(
          componentType,
          procedureComponentId,
          value,
          procedure
        )
        dispatch(setIsWorking(true))
        if (
          value === 'N/A' ||
          status === 'formula-not-valid' ||
          value?.status === 'N_A' ||
          doesNotMeet
        ) {
          openDrawer(DRAWERS.STEPS_COMMENTS)
          setIsNonCompliantCommentFn(true)
          dispatch(setCurrentStepDrawerComments(step))
          setCurrentComponentTypeFn(componentType)
        }
        dispatch(
          changeComponentValue({
            id,
            stepId,
            value,
            additionalValues,
            type: componentType
          })
        )
      },
      [
        dispatch,
        stepId,
        componentType,
        procedureComponentId,
        procedure,
        step,
        openDrawer,
        setIsNonCompliantCommentFn,
        setCurrentComponentTypeFn
      ]
    )

    const shouldDisableComponent = useMemo(() => {
      // Lo desactivamos si el prop lo pide
      return (
        disabled ||
        // O si todas las firmas del paso son finales
        (anySignatureIsFinal &&
          // y el componente no es firma
          c.type !== EXECUTION_COMPONENT_TYPES.SIGNATURE)
      )
    }, [disabled, anySignatureIsFinal, c.type])

    return (
      <GenericComponent
        component={c}
        executionComponent={executionComponent}
        disabled={shouldDisableComponent}
        onComponentChange={onComponentChange}
        stepId={stepId}
        procedure={procedure}
        className={className}
        onSelectStep={onSelectStep}
        isReadoutEditMode={isReadoutEditMode}
        isReadoutLockMode={isReadoutLockMode}
        isHistoricalMode={historicalModeStep === stepId}
      />
    )
  }
)`
  border: 2px solid
    ${({ highlighted }) => (highlighted ? '#FFA300 !important' : 'transparent')};
`

const GenericComponent = React.memo(
  (props) => {
    const cType = props.component.type

    const components = {
      [EXECUTION_COMPONENT_TYPES.SIGNATURE]: Signature,
      [EXECUTION_COMPONENT_TYPES.RECORDER]: Input,
      [EXECUTION_COMPONENT_TYPES.NUMERICAL]: RangeInput,
      [EXECUTION_COMPONENT_TYPES.MULTICELL]: RadioCellGroup,
      [EXECUTION_COMPONENT_TYPES.OPTION_LIST]: DropDown,
      [EXECUTION_COMPONENT_TYPES.FORMULA]: Formula,
      [EXECUTION_COMPONENT_TYPES.JUMP]: Jump,
      [EXECUTION_COMPONENT_TYPES.REFERENCE]: ProcedureReference,
      [EXECUTION_COMPONENT_TYPES.FILE_REFERENCE]: FileReference,
      [EXECUTION_COMPONENT_TYPES.CHECKBOX]: Checkbox,
      [EXECUTION_COMPONENT_TYPES.DATE]: DateInput,
      [EXECUTION_COMPONENT_TYPES.TIME]: TimeInput,
      [EXECUTION_COMPONENT_TYPES.TEXTAREA]: TextArea,
      [EXECUTION_COMPONENT_TYPES.READOUT_RT_RECORDER]: ReadoutRTRecorder,
      [EXECUTION_COMPONENT_TYPES.READOUT_RT_NUMERICAL]: ReadoutRTNumerical
    }

    const Component = components[cType]

    if (!Component) {
      erro('Component type not supported: ' + cType)
      return null
    }

    const compElement = <Component {...props} />

    return createPortal(compElement, props.component.id)
  },
  (oldProps, newProps) => {
    return isEqual(oldProps, newProps)
  }
)

export default ProcedureComponentPortal
