import React from 'react'

import Modal from '../../views/modal'
import Translation from '../../views/translations'


export const ConfirmSkipAndDeleteModal = ({ visible, setVisible, onOk }) => {
  return (
    <Modal
      okButtonText={<Translation id='yesDelete' />}
      cancelButtonText={<Translation id='cancel' />}
      cancellable={true}
      onOk={onOk}
      onCancel={() => setVisible(false)}
      visible={visible}
      setVisible={setVisible}
      title={<Translation id='previousDataLostTitle' />}
    >
      <p>
        <Translation id='previousDataLostWarn' />
      </p>
    </Modal>
  )
}
