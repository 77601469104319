import { useMemo } from 'react'

export default function useReadoutRecorder({ isPoor, value }) {
  const error = useMemo(() => {
    if (value !== '') {
      if (isPoor) {
        return { id: 'signalQualityPoor' }
      }
    }
  }, [isPoor, value])

  return {
    error
  }
}
