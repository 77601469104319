import { Input, Button, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useGlobal } from 'reactn'
import styled from 'styled-components'

import Caret from '../../../../components/icons/caret'
import CloseIcon from '../../../../components/icons/close-icon'
import Search from '../../../../components/icons/search'
import { searchHtmlText } from '../../../../utils/helpers/html'
import Translation from '../../../translations'

const SearchBox = ({ stepsTexts, onSelectStep, setVisible }) => {
  const [searchText, setSearchText] = useState('')
  const [results, setResults] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [, setSearchHighlight] = useGlobal('searchHighlight')

  const handleSearch = () => {
    if (searchText?.length >= 3) {
      highlightAll()
    }
  }

  function highlightAll() {
    const searchResults = searchHtmlText(stepsTexts, searchText)
    if (searchResults.length) {
      setSearchHighlight(searchText)
      moveSearch(searchResults, 0)
    } else {
      setCurrentIndex(0)
      setSearchHighlight(undefined)
    }

    setResults(searchResults)
  }

  function moveSearch(searchResults, index) {
    const nextIndex = index > 0 ? Math.min(index, searchResults.length - 1) : 0
    const result = searchResults[nextIndex]

    if (result) {
      onSelectStep([result.stepId])
    }

    setCurrentIndex(nextIndex)
  }

  const handleChange = (text) => {
    setSearchText(text.target.value)
  }

  const handleCancel = () => {
    setSearchText('')
    setResults([])
    setCurrentIndex(0)
    setVisible(false)
    setSearchHighlight(undefined)
  }

  return (
    <SearchBoxContainer id='searchBox-container'>
      <div>
        <Tooltip
          trigger={['focus']}
          title={
            searchText.length < 3 ? (
              <Translation id='minimumThreeCharacters' />
            ) : (
              ''
            )
          }
          placement='topRight'
        >
          <SearchBoxInput
            autoFocus
            onChange={handleChange}
            onPressEnter={handleSearch}
          />
        </Tooltip>
      </div>
      <StatisticContainer>
        {results.length > 0 ? `${currentIndex + 1}/${results.length}` : '0/0'}
      </StatisticContainer>
      <div>
        <SearchPanelButton
          type='primary'
          icon={<Search size={12} color={'white'} />}
          onClick={() => handleSearch()}
        />
        <SearchPanelButton
          type='primary'
          icon={<Caret rotation={270} size={12} color={'white'} />}
          onClick={() => moveSearch(results, currentIndex + 1)}
        />
        <SearchPanelButton
          type='primary'
          icon={<Caret rotation={90} size={12} color={'white'} />}
          onClick={() => moveSearch(results, currentIndex - 1)}
        />
        <SearchPanelButton
          icon={<CloseIcon size={12} />}
          onClick={handleCancel}
        />
      </div>
    </SearchBoxContainer>
  )
}

const SearchBoxContainer = styled.div`
  border: 1px solid #000000;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.06),
    0px 5px 9px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 5px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 5px;
  z-index: 15;
  width: 425px;
  max-width: 425px;
  left: 50%;
  transform: translate(-50%);
  top: 120px;
`
const StatisticContainer = styled.div`
  width: 70px;
  text-align: center;
  font-size: 12px;
`

const SearchPanelButton = styled(Button)`
  margin-right: 5px;
`

const SearchBoxInput = styled(Input)`
  margin-right: 5px;
`

export default SearchBox
