import { getDatabase } from '../..'
import { STATUS_CODES } from '../../../utils/constants/http'
import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { info } from '../../../utils/logger'
import { getKeys } from '../key'

export async function _readLocalDbData({ tableName, ids, indexesOnly }) {
  const db = getDatabase()
  const table = db[tableName]
  let data = null

  // Esto sirve para sacar solo los índices del procedimiento
  // y evitar llenar la memoria con el html de todos los procedimientos.
  // Solución temporal a un problema de rendimiento que requiere
  // refactorizar toda la base de datos local.
  if (indexesOnly && tableName === TABLE_NAMES.PROCEDURES) {
    data = await getKeys(table, [
      'id',
      'unit',
      'serieName',
      'key',
      'rev',
      'title',
      'buildNumber',
      'status'
    ])
  } else {
    data = await (ids ? table.bulkGet(ids) : table.toArray())
  }

  info('Read from DB:', tableName, data)

  return { status: STATUS_CODES.OK, data }
}
