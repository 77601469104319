import { useSelector } from 'react-redux'
import useSWR from 'swr'

import { getConnectionStatus } from '../store/userInterface/selectors'
import { CONNECTION_STATUS } from '../utils/constants/connection'
import { convertAntdToApiSorterDirection } from '../utils/helpers/strings'

export const useRemoteData = ({ url, disabled, ...params } = {}) => {
  const connectionStatus = useSelector(getConnectionStatus)

  // Se desactiva el hook si no hay conexión
  disabled = connectionStatus !== CONNECTION_STATUS.ONLINE || disabled

  // Transformamos los parámetros de ordenación antd a la API
  if (params.orderDirection) {
    params.orderDirection = convertAntdToApiSorterDirection(
      params.orderDirection || 'ascend'
    )
  }

  return useSWR(disabled ? null : [url, params])
}
