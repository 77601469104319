import { axiosInstance } from '../../../..'
import { getTranslation } from '../../../../../i18n/getTranslation'
import { notificationMessage } from '../../../../../utils/helpers/notification-message'

export async function printReport(html) {
  const response = await axiosInstance.post(
    '/executions/print',
    {
      html
    },
    { responseType: 'blob' }
  )

  return response.data
}

export async function generatePDFReport(execution) {
  return axiosInstance
    .post(`/executions/${execution.id}/toPDF`, {
      approvedAt: execution.approvedAt
    })
    .catch((error) => {
      let description = error.message
      switch (error.response.status) {
        case 409: {
          description = getTranslation('copyToCustomFolderFailed')
          break
        }
        case 500: {
          description = getTranslation('exportToPDFFailed')
          break
        }
      }

      notificationMessage({
        type: 'error',
        message: getTranslation('reportGeneration'),
        description: description
      })
    })
}

export async function uploadFinalReport(execution, files) {
  return axiosInstance.post(`/executions/${execution.id}/report`, files)
}
