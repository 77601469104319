import { TABLE_NAMES } from '../../../utils/constants/localdb'
import { _writeLocalDbDataNoCatch } from '../../common/write'

const tableName = TABLE_NAMES.UNITS

export const updateLocalUnit = async (unit) =>
  _writeLocalDbDataNoCatch({
    tableName,
    action: 'PUT',
    data: [unit]
  })
