import React, { useState, useRef } from 'react'

import { deleteDeviceProcedures } from '../../../../application/device/delete-procedures'
import { exportProcedures } from '../../../../application/procedure'
import Download from '../../../../components/icons/download'
import StatusChange from '../../../../components/icons/status-change'
import { getTranslation } from '../../../../i18n/getTranslation'
import { readProcedure } from '../../../../localdb/procedures/read'
import { getProcedureExecutions } from '../../../../services/execution/http/get'
import { deleleteProcedure } from '../../../../services/procedures/components/http/delete'
import { getReferencedProcedureKeys } from '../../../../services/procedures/components/http/get'
import { requestProcedures } from '../../../../services/procedures/http/get'
import { importProcedures } from '../../../../services/procedures/http/post'
import { changeProceduresStatus } from '../../../../services/procedures/http/put'
import { deleteExecutions } from '../../../../store/execution/localDb'
import { deleteProcedures } from '../../../../store/procedure/localDb'
import { PROCEDURE_STATUS } from '../../../../utils/constants/procedure'
import { PERMISSION_TYPES } from '../../../../utils/constants/roles'
import { confirm } from '../../../../utils/helpers/dialogs'
import { notificationMessage } from '../../../../utils/helpers/notification-message'
import {
  validationExactSelection,
  validationMinimumSelection,
  validationStatusNot,
  validationUserHasPermission
} from '../../../../utils/helpers/validations/table-footer'
import ChangeStatus from '../../../../views/change-status'
import DeleteIcon from '../../../../views/icons/delete'
import ChangeStatusModal from '../../../../views/modal/confirm-modal'
import TableFooter from '../../../../views/tables/footer'
import Translation from '../../../../views/translations'

import { ReferencedProceduresDrawer } from './ReferencedProceduresDrawer'

const deleteProcedure = async ({ selection, onChange }) => {
  const [procedure] = selection
  if (
    ![
      PROCEDURE_STATUS.DRAFT,
      PROCEDURE_STATUS.REVIEW,
      PROCEDURE_STATUS.APPROVED
    ].some((status) => status === procedure.status)
  ) {
    notificationMessage({
      type: 'error',
      message: 'Error',
      description: getTranslation('deleteProcedureStatusError')
    })
    return
  }

  const { data: procedureExecutionsData } = await getProcedureExecutions(
    procedure.id
  )
  const procedureExecutions = procedureExecutionsData.items || []

  const result = await confirm({
    title: (
      <Translation
        id={
          procedureExecutions.length
            ? 'deleteProcedureWithExecutionsAdvice'
            : 'deleteProcedureAdvice'
        }
      />
    )
  })
  if (result) {
    await deleteProcedureAndExecutions(procedure, procedureExecutions)
    onChange()
  }
}

const errorTranslations = {
  PROCEDURE_ALREADY_EXISTS: 'procedureAlreadyExists',
  FILE_ALREADY_EXISTS: 'fileAlreadyExists'
}

async function onImportProcedure(files, setImportLoading, onChange) {
  let formData = new FormData()
  formData.append('file', files[0])

  try {
    setImportLoading(true)
    await importProcedures(formData).finally(() => setImportLoading(false))

    notificationMessage({
      description: getTranslation('importSuccess')
    })

    onChange()
  } catch (e) {
    if (e.response?.status === 409) {
      const [errorMessage, elementId] = e.response.data.message.split(';')
      notificationMessage({
        type: 'error',
        description: getTranslation(errorTranslations[errorMessage], {
          params: {
            id: elementId
          }
        })
      })
    } else {
      notificationMessage({
        type: 'error',
        description: getTranslation('unknownImportError')
      })
    }
  }
}

async function deleteProcedureAndExecutions(procedure, executions) {
  try {
    const procedureOnDevice = await readProcedure(procedure.id)
    if (procedureOnDevice) {
      await deleteDeviceProcedures([procedure])
      await deleteProcedures([procedure])
    }
    await deleteExecutions(executions.map((e) => e.id))
    await deleleteProcedure(procedure)
    notificationMessage({
      description: getTranslation('deleteProcedureSuccess')
    })
  } catch (e) {
    if (e.response?.data?.errors?.length) {
      notificationMessage({
        type: 'error',
        message: 'Error',
        description: `${getTranslation(
          'deleteProcedureConflictDeviceError'
        )}\n${e.response?.data?.errors
          .map((err) => err?.device?.name)
          .join(', ')}.`
      })
    } else {
      notificationMessage({
        type: 'error',
        message: 'Error',
        description: getTranslation('deleteProcedureError')
      })
    }
    throw e
  }
}

export const Footer = ({ selection, procedures, onChange }) => {
  const [referencedDrawerVisible, setReferencedDrawerVisible] = useState(false)
  const [importLoading, setImportLoading] = useState(false)
  const [exportSaving, setExportSaving] = useState(false)
  const [changeStatusModalVisible, setChangeStatusModalVisible] =
    useState(false)
  const [newStatus, setNewStatus] = useState(null)
  const [errorSameKeysToPublished, setErrorSameKeysToPublished] =
    useState(false)
  const [referencedProcedures, setReferencedProcedures] = useState([])
  const fileInput = useRef(null)

  const exportProcedure = async ({ selection }) => {
    // Obtener los procedimientos referenciados de los procedimientos seleccionados
    const referencedProcedureKeys = [
      ...new Set(
        (
          await Promise.all(
            selection.map(async (procedure) => {
              return getReferencedProcedureKeys(procedure.id)
            })
          )
        )
          .flat()
          // Filtrar los procedimientos que ya están seleccionados
          .filter((p) => !selection.find((sp) => sp.id === p))
      )
    ]

    const { data: proceduresRes } = await requestProcedures({
      keys: referencedProcedureKeys,
      publishedVersionOnly: true
    })
    // Solo pedir los procedimientos que existen de todos los referenciados
    const proceduresExistsKeys = proceduresRes.items || []

    const referencedProcedures = referencedProcedureKeys
      .filter((p) => proceduresExistsKeys.some((elem) => elem.key === p))
      .map((key) => {
        return proceduresExistsKeys.find((p) => p.key === key)
      })

    if (referencedProcedures.length) {
      setReferencedProcedures(referencedProcedures)
      setReferencedDrawerVisible(true)
    } else {
      await exportProcedures(selection.map((p) => p.id))
    }
  }

  const onClickChangeStatus = () => {
    setChangeStatusModalVisible(true)
    setNewStatus(null)
    setErrorSameKeysToPublished(false)
  }

  const onConfirmChangeStatus = async () => {
    if (newStatus) {
      const procedureIds = selection.map((p) => p.id)

      if (newStatus === PROCEDURE_STATUS.PUBLISHED) {
        const proceduresNotSelected = procedures.filter(
          (p) => !procedureIds.includes(p.id)
        )
        const keysSelected = selection.map(({ key }) => key)

        const numberOfKeys = {}
        selection.forEach(({ key }) => {
          numberOfKeys[key] = (numberOfKeys[key] || 0) + 1
        })

        const conflictAlreadyPublished =
          proceduresNotSelected.filter(
            ({ key, status }) =>
              keysSelected.includes(key) &&
              status === PROCEDURE_STATUS.PUBLISHED
          ).length > 0

        if (conflictAlreadyPublished) {
          setErrorSameKeysToPublished(true)
          return
        }

        const someKeySelectedMoreThanOnce = Object.values(numberOfKeys).some(
          (key) => key > 1
        )

        if (someKeySelectedMoreThanOnce) {
          setErrorSameKeysToPublished(true)
          return
        }
      }

      return changeProceduresStatus(procedureIds, newStatus)
        .then(() => {
          setChangeStatusModalVisible(false)
          setNewStatus(null)
          onChange()
        })
        .catch(() => {
          notificationMessage({
            type: 'error',
            message: getTranslation('error.change.procedures.status.title'),
            description: getTranslation('error.change.procedures.status.text')
          })
        })
    }
  }

  const buttons = [
    {
      id: 'export',
      translationId: 'export',
      icon: <Download rotation={180} />,
      loading: exportSaving,
      onClick: exportProcedure,
      validations: [
        validationUserHasPermission(PERMISSION_TYPES.PROCEDURE_MANAGE_EXPORT),
        validationMinimumSelection(1)
      ]
    },
    {
      id: 'import',
      dataTestStatus: importLoading,
      translationId: 'import',
      icon: <Download />,
      loading: importLoading,
      onClick: () => fileInput.current.click(),
      validations: [
        validationUserHasPermission(PERMISSION_TYPES.PROCEDURE_MANAGE_IMPORT),
        validationExactSelection(0)
      ]
    },
    {
      id: 'delete',
      translationId: 'delete',
      icon: <DeleteIcon />,
      onClick: deleteProcedure,
      validations: [
        validationUserHasPermission(PERMISSION_TYPES.PROCEDURE_MANAGE_DELETE),
        validationExactSelection(1)
      ]
    },
    {
      id: 'status',
      translationId: 'changeStatus',
      icon: <StatusChange />,
      onClick: onClickChangeStatus,
      validations: [
        validationUserHasPermission(
          PERMISSION_TYPES.PROCEDURE_MANAGE_CHANGE_STATUS
        ),
        validationMinimumSelection(1),
        validationStatusNot(PROCEDURE_STATUS.DEPRECATED, false)
      ]
    }
  ]

  return (
    <>
      <TableFooter buttons={buttons} parameters={{ selection, onChange }} />

      <input
        hidden
        type='file'
        ref={fileInput}
        onChange={(e) => {
          onImportProcedure(e.target.files, setImportLoading, onChange)
          e.target.value = ''
        }}
      />
      <ReferencedProceduresDrawer
        visible={referencedDrawerVisible}
        setVisible={setReferencedDrawerVisible}
        selectedProcedures={selection}
        referencedProcedures={referencedProcedures}
        setExportSaving={setExportSaving}
        exportSaving={exportSaving}
      />
      <ChangeStatusModal
        id='change-status-procedures'
        cancelButtonTextId='cancel'
        okButtonTextId='okText'
        disableOkButton={!newStatus}
        onOk={onConfirmChangeStatus}
        onCancel={() => setChangeStatusModalVisible(false)}
        open={changeStatusModalVisible}
        setOpen={setChangeStatusModalVisible}
        titleId={'changeStatus'}
      >
        <ChangeStatus
          selectedProcedures={selection}
          errorSameKeysToPublished={errorSameKeysToPublished}
          newStatus={newStatus}
          setNewStatus={setNewStatus}
        />
      </ChangeStatusModal>
    </>
  )
}
