import React from 'react'

const Pause = ({ size = '24', color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.75 3.75H8.25C9.078 3.75 9.75 4.422 9.75 5.25V18.75C9.75 19.578 9.078 20.25 8.25 20.25H6.75C5.922 20.25 5.25 19.578 5.25 18.75V5.25C5.25 4.422 5.922 3.75 6.75 3.75Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.75 3.75H17.25C18.078 3.75 18.75 4.422 18.75 5.25V18.75C18.75 19.578 18.078 20.25 17.25 20.25H15.75C14.922 20.25 14.25 19.578 14.25 18.75V5.25C14.25 4.422 14.922 3.75 15.75 3.75Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Pause
